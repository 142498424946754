import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Form/Button';
import messages from '../../messages';
import styles from '../../components/DownloadDataModal/DownloadDataModal.pcss';


interface Props {
  onInitialized: () => void,
}


const InstalledDownloader: FC<Props> = ({ onInitialized }) => (
  <div className={styles.modal__content}>
    <h3 className="modal__subheader "><FormattedMessage {...messages.installedDownloader.header} /></h3>
    <div className={styles.illustration}>
      <img src="/assets/svg/tick.svg" alt="" />
    </div>
    <div className="modal__actions">
      <Button
        styleModifier="primary"
        labelMessage={messages.buttons.downloadData}
        className="btn--block btn--filled"
        onClick={onInitialized}
      />
    </div>
  </div>
);

export default InstalledDownloader;
