import * as actions from './actions';
import * as actionTypes from './actionTypes';
import * as constants from './constants';
import * as selectors from './selectors';
import * as shapes from './shapes';
import reducer from './reducer';
import sagas from './sagas';
import messages from './messages';

import ConfirmDataSharingModal from './components/ConfirmDataSharingModal';
import RevokeAccessModal from './components/RevokeAccessModal';
import EnrollInClinicModal from './components/EnrollInClinicModal';
import InviteFamilyMembersModal from './components/InviteFamilyMembersModal';
import RevokeFamilyAccessModal from './components/RevokeFamilyAccessModal';

import FamilyList from './partials/FamilyList';
import SidebarSharingRequestsList from './partials/SidebarSharingRequestsList';
import NoFamilyMembers from './partials/NoFamilyMembers';
import NoSharingRequests from './partials/NoSharingRequests';
import GlucoseLevelTargets from './partials/GlucoseLevelTargets';


const components = {
  ConfirmDataSharingModal,
  RevokeAccessModal,
  EnrollInClinicModal,
  InviteFamilyMembersModal,
  RevokeFamilyAccessModal,
};

const partials = {
  FamilyList,
  NoFamilyMembers,
  NoSharingRequests,
  GlucoseLevelTargets,
  SidebarSharingRequestsList,
};


export default {
  actions,
  actionTypes,
  components,
  constants,
  partials,
  reducer,
  sagas,
  selectors,
  shapes,
  messages,
};
