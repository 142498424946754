import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useAction } from 'hooks';
import Button from 'components/Form/Button';
import * as actions from '../../../actions';
import * as constants from '../../../constants';
import * as selectors from '../../../selectors';
import messages from '../../../messages';
import styles from '../DownloadDataModal.pcss';


interface StepProps {
  onSetComponent: (componentName: string) => void,
  onSetStep: (step: number) => void,
}

interface Props {
  onSetComponent: (componentName: string) => void,
}


const Connect: FC<StepProps> = ({ onSetStep }) => {
  const connect = useAction(actions.connectBlueCable);

  const onConnect = () => {
    connect();
    onSetStep(2);
  };

  return (
    <>
      <p className="modal__info"><FormattedMessage {...messages.connectBlueCable.info} /></p>
      <div className={styles.illustration}>
        <img src="/assets/svg/connect-by-blue-cable.svg" className="w-100" alt="" />
      </div>
      <div className="modal__actions">
        <Button
          styleModifier="primary"
          labelMessage={messages.buttons.next}
          className="btn--block btn--filled"
          onClick={onConnect}
        />
      </div>
    </>
  );
};


const StartCheckingConnection: FC<StepProps> = ({ onSetComponent }) => {
  const connectionId = useSelector(selectors.connectionId);
  const connectionStatus = useSelector(selectors.connectionStatus);
  const startCheckingConnection = useAction(actions.startCheckingConnection);

  useEffect(() => {
    if (connectionId) {
      startCheckingConnection(connectionId);
    }
  }, [connectionId]);

  useEffect(() => {
    if (
      connectionStatus === constants.CONNECTION_STATUSES.IN_PROGRESS
        || connectionStatus === constants.CONNECTION_STATUSES.SUCCESS
    ) {
      onSetComponent('Downloading');
    }
  }, [connectionStatus]);

  return (
    <>
      <p className="modal__info"><FormattedMessage {...messages.connectBlueCable.pressM} /></p>
      <div className={styles.illustration}>
        <img src="/assets/svg/press-m-button.svg" alt="" />
      </div>
    </>
  );
};


const ConnectBlueCable: FC<Props> = ({ onSetComponent }) => {
  const [step, setStep] = useState<number>(1);
  const Component = step === 1 ? Connect : StartCheckingConnection;

  const onChooseConnection = () => {
    onSetComponent('ChooseConnection');
  };

  return (
    <div className={styles.modal__content}>
      <h3 className="modal__subheader "><FormattedMessage {...messages.headers.howToStartConnection} /></h3>
      <Component onSetComponent={onSetComponent} onSetStep={setStep} />
      <div className={styles.divider}>
        <span className={styles.divider__text}><FormattedMessage {...messages.infos.or} /></span>
      </div>
      <Button
        styleModifier="transparent"
        labelMessage={messages.buttons.changeDeviceType}
        className="btn--no-size text--primary m-0"
        onClick={onChooseConnection}
      />
    </div>
  );
};

export default ConnectBlueCable;
