import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MessageDescriptor } from 'react-intl';
import cn from 'classnames';
import { ProfileContext } from 'context';
import { useAction } from 'hooks';
import App from 'modules/App';
import Account from 'modules/Account';
import Meter from 'modules/Meter';
import Notifications from 'modules/Notifications';
import ClinicManagement from 'modules/ClinicManagement';
import Hcp from 'modules/Hcp';
import Visit from 'modules/Visit';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import { useIsPinnedMenu } from '../../hooks';
import MainLayout from '../../partials/MainLayout';
import HcpTopBar from './HcpTopBar';
import HcpSidebar from './HcpSidebar';
import ClinicInfo from './ClinicInfo';
import ClinicSetLicence from './ClinicSetLicence';
import ClosePrintModeBtn from './ClosePrintModeBtn';
import styles from './MainLayout.pcss';


interface Props {
  metaTitleMessage?: MessageDescriptor,
  metaDescriptionMessage?: MessageDescriptor,
}


const profileContext = {
  selectors: {
    activePatient   : Hcp.selectors.activePatient,
    phiSet          : Hcp.selectors.phiSet,
    phiSetDocumentId: Hcp.selectors.phiSetDocumentId,
    activeVisit     : Visit.selectors.activeVisit,
  },
  actions: {
    setReadings         : Hcp.actions.setReadings,
    storeReadingsSuccess: Hcp.actions.setImportedReadings,
  },
};


const useContent = (children: React.ReactNode) => {
  const activeClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const activeOrganizationMembership = useSelector(Account.selectors.activeOrganizationMembership);

  if (activeOrganizationMembership && activeOrganizationMembership.status === 'Pending') {
    return <ClinicInfo isMembershipPending clinic={activeOrganizationMembership.organization} />;
  }
  if (activeOrganizationMembership && activeOrganizationMembership.organization.status === 'Pending') {
    return <ClinicInfo isClinicPending clinic={activeOrganizationMembership.organization} />;
  }
  if (activeClinicMembership && !activeClinicMembership.clinic.storageAccount && !activeClinicMembership.isAdmin) {
    return <ClinicInfo isClinicNotConfigured clinic={activeClinicMembership.clinic} />;
  }
  if (activeClinicMembership && activeClinicMembership.clinic.clinicStatus === 'WaitingForLicence') {
    return <ClinicSetLicence activeClinicMembership={activeClinicMembership} />;
  }
  return children;
};


const HcpMainLayout: FC<Props> = ({ metaTitleMessage, metaDescriptionMessage, children }) => {
  const content = useContent(children);
  const isPinnedMenu = useIsPinnedMenu();
  const menuOpen = useSelector(selectors.menuOpen);
  const selectedMenuItem = useSelector(selectors.selectedMenuItem);
  const windowWidth = useSelector(selectors.windowWidth);
  const direction = useSelector(App.selectors.direction);
  const visitNotesFloatingModal = useSelector(App.selectors.floatingModalSelector(Visit.constants.VISIT_NOTES_FM));
  const isPrintMode = useSelector(App.selectors.printMode);
  const activeClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const lastReceivedNotification = useSelector(Notifications.selectors.lastReceivedNotification);
  const clinicMemberships = useSelector(ClinicManagement.selectors.clinicMemberships);
  const patients = useSelector(Hcp.selectors.patients);
  const activeVisit = useSelector(Visit.selectors.activeVisit);

  const setMenuOpen = useAction(actions.setMenuOpen);
  const setSelectedMenuItem = useAction(actions.setSelectedMenuItem);
  const closeDropdown = useAction(App.actions.closeDropdown);
  const checkPendingMemberships = useAction(ClinicManagement.actions.checkPendingOrganizationMemberships);
  const checkPatientsWithoutLeadingHcp = useAction(ClinicManagement.actions.checkPatientsWithoutLeadingHcp);
  const fetchClinicMemberships = useAction(ClinicManagement.actions.fetchClinicMemberships);


  useEffect(() => {
    if (activeClinicMembership && activeClinicMembership.isAdmin) {
      checkPendingMemberships();
      fetchClinicMemberships(activeClinicMembership.clinicId);
    }
  }, [activeClinicMembership]);

  useEffect(() => {
    if (activeClinicMembership && activeClinicMembership.isAdmin && clinicMemberships.length) {
      checkPatientsWithoutLeadingHcp();
    }
  }, [clinicMemberships, patients]);

  useEffect(() => {
    const { CLINIC_MEMBERSHIP_APPROVED } = Notifications.constants.NOTIFICATIONS_TRIGGERS;
    if (lastReceivedNotification && lastReceivedNotification.notificationTrigger === CLINIC_MEMBERSHIP_APPROVED) {
      window.location.reload();
    }
  }, [lastReceivedNotification]);

  useEffect(() => {
    if (visitNotesFloatingModal && windowWidth < constants.PINNED_MENU_NOTES_BREAKPOINT) {
      setMenuOpen(false);
    }
  }, [visitNotesFloatingModal]);


  const onClickSidebar = (evt) => {
    evt.stopPropagation();
    closeDropdown();
  };

  return (
    <MainLayout {...{ metaTitleMessage, metaDescriptionMessage }}>
      <ProfileContext.Provider value={profileContext}>
        <div
          className={
            cn({
              [styles.container]      : !isPrintMode,
              [styles.layoutContainer]: !isPrintMode,
            })
          }
        >
          <HcpTopBar />
          <div
            className={
              cn({
                [styles.layout]: !isPrintMode,
              })
            }
          >
            <div
              className={
                cn({
                  'row no-gutters'         : !isPrintMode,
                  [styles.sidebarContainer]: !isPrintMode,
                })
              }
            >
              <div
                className={
                  cn(styles.sidebarHolder, {
                    [styles['sidebarHolder--extended']]: isPinnedMenu,
                  })
                }
                onClick={onClickSidebar}
                onKeyDown={undefined}
              >
                <div className={
                  cn('col-auto d-print-none', {
                    [styles.hide]: isPrintMode,
                  })
                }
                >
                  <HcpSidebar
                    direction={direction}
                    menuOpen={menuOpen}
                    selectedMenuItem={selectedMenuItem}
                    activeVisit={activeVisit}
                    isPinnedMenu={isPinnedMenu}
                    setMenuOpen={setMenuOpen}
                    setSelectedMenuItem={setSelectedMenuItem}
                  />
                </div>
              </div>
              <ClosePrintModeBtn />
            </div>
            <div className="content">
              <App.components.AlertsBus className="px-6" isGlobal activeClinicMembership={activeClinicMembership} />
              { content }
            </div>
          </div>
        </div>
        <Meter.components.HcpDownloadDataModal />
        <Hcp.components.MergePatientModal />
        <Hcp.components.DeclineEnrollModal />
        <Hcp.components.InvitePatientModal />
        <Hcp.components.RemovePatientModal />
      </ProfileContext.Provider>
    </MainLayout>
  );
};

export default HcpMainLayout;
