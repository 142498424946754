import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useAction } from 'hooks';
import Button from 'components/Form/Button';
import ArrowDownNegative from 'svg/arrow-down-negative.svg';
import App from 'modules/App';
import Account from 'modules/Account';
import * as constants from '../../constants';
import messages from '../../messages';
import styles from './DownloadDataFromMeterBtn.pcss';


interface ApprovalBtnPlaceholderProps {
  className: string | { [key: string]: never },
}

interface Props {
  styleModifier?: string,
  className?: string | { [key: string]: never },
  isReadOnly?: boolean,
  isDisabled?: boolean,
}


const ApprovalBtnPlaceholder: FC<ApprovalBtnPlaceholderProps> = ({ className }) => (
  <Button
    className={cn(styles.root, styles['root--notActive'], className)}
    isDisabled
  >
    <div className="d-flex align-items-center" style={{ flexWrap: 'nowrap' }}>
      <div className="col-auto pr-0">
        <div className={cn(styles.icon, styles['icon--notActive'])}>
          <ArrowDownNegative className={styles.icon__arrow} />
        </div>
      </div>
      <div className="col">
        <p className={cn(styles.label, styles['label--lead'], styles['label--notActive'])}>
          <FormattedMessage {...messages.buttons.waitForApproval} />
        </p>
      </div>
    </div>
  </Button>
);


const DownloadDataBtn: FC<Props> = ({ className, styleModifier, isDisabled, isReadOnly }) => {
  const devices = useSelector(App.selectors.devices);
  const isBtnDisabled = isReadOnly || isDisabled || !devices || !devices.length;

  const onOpenModal = useAction(App.actions.openModal, constants.DOWNLOAD_DATA_MODAL);

  return (
    <Button
      id="meterDownloadData"
      styleModifier={styleModifier}
      className={cn(styles.root, { [styles['root--notActive']]: isReadOnly }, className)}
      isDisabled={isBtnDisabled}
      onClick={onOpenModal}
    >
      <div className={cn(styles.content, 'row')}>
        <div className="col-auto pr-0">
          <div className={cn(styles.icon__wrapper)}>
            <div className={cn(styles.icon, { [styles['icon--notActive']]: isReadOnly })}>
              <ArrowDownNegative className={styles.icon__arrow} />
            </div>
          </div>
        </div>
        <div className="col w-100">
          <p className={cn(styles.label, styles['label--lead'])}>
            <FormattedMessage {...messages.buttons.downloadData} />
          </p>
          <p className={cn(styles.label, styles['label--secondary'])}>
            <FormattedMessage {...messages.buttons.fromMeter} />
          </p>
        </div>
      </div>
    </Button>
  );
};


const DownloadDataFromMeterBtn: FC<Props> = ({
  className = 'btn--block btn--filled m-0',
  styleModifier = 'primary',
  isDisabled = false,
  isReadOnly = false,
}) => {
  const activeProfileType = useSelector(Account.selectors.activeProfileType);
  const activeClinicMembership = useSelector(Account.selectors.activeClinicMembership);

  useStyles(styles);

  if (
    activeProfileType === Account.constants.PROFILE_TYPES.HCP
    && (
      !activeClinicMembership
      || activeClinicMembership.membershipStatus === 'Pending'
      || activeClinicMembership.clinic.clinicStatus === 'Pending'
    )
  ) {
    return <ApprovalBtnPlaceholder {...{ className }} />;
  }
  return <DownloadDataBtn {...{ className, styleModifier, isDisabled, isReadOnly }} />;
};

export default DownloadDataFromMeterBtn;
