import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import differenceBy from 'lodash/differenceBy';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useAction } from 'hooks';
import Button from 'components/Form/Button';
import App from 'modules/App';
import * as actions from '../../../actions';
import * as constants from '../../../constants';
import * as selectors from '../../../selectors';
import messages from '../../../messages';
import styles from '../DownloadDataModal.pcss';


interface InstructionsProps {
  activeInstruction: number,
  setActiveInstruction: (activeInstruction: number) => void,
}

interface MeterProps {
  discoveredDevice: DiscoveredBTDevice,
  deviceCaseType: CaseType,
  onSetComponent: (componentName: string) => void,
}

interface MetersProps {
  discoveredDevices: DiscoveredBTDevice[],
  devicesCaseTypes: CaseType[],
  onSetComponent: (componentName: string) => void,
}

interface Props {
  devicesByChannel: { [channel: string]: Device[] },
  onSetComponent: (componentName: string) => void,
  onSetStyleModifier: (styleModifier: string) => void,
}


const InstructionButtons: FC<InstructionsProps> = ({ activeInstruction, setActiveInstruction }) => (
  <ul className={`list--unstyled ${styles.instructionsButtons}`}>
    <li className={styles.instructionButton}>
      { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
      <button
        type="button"
        className={styles.instructionButton__btn}
        onClick={() => setActiveInstruction(1)}
      >
        <span
          className={
            cn(styles.instructionButton__imageContainer, {
              [styles['instructionButton__imageContainer--active']]: activeInstruction === 1,
            })
          }
        >
          <img src="/assets/svg/type1.svg" alt="" />
        </span>
      </button>
    </li>
    <li className={styles.instructionButton}>
      { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
      <button
        type="button"
        className={styles.instructionButton__btn}
        onClick={() => setActiveInstruction(2)}
      >
        <span
          className={
            cn(styles.instructionButton__imageContainer, {
              [styles['instructionButton__imageContainer--active']]: activeInstruction === 2,
            })
          }
        >
          <img src="/assets/svg/type2.svg" alt="" />
        </span>
      </button>
    </li>
  </ul>
);


const InstructionRound3BtnOKBt: FC = () => (
  <div>
    <ol className={`${styles.howTo__list} row`}>
      <li className={`${styles.howTo__list__item} col-6`}>
        <div className={styles.howTo__list__item__content}>
          <p><FormattedMessage {...messages.connectBluetooth.round3BtnOKBt.first} /></p>
          <img src="/assets/svg/Round3BtnOKBt-ok.svg" className={styles.instruction__image} alt="" />
        </div>
      </li>
      <li className={`${styles.howTo__list__item} col-6`}>
        <div className={styles.howTo__list__item__content}>
          <p><FormattedMessage {...messages.connectBluetooth.round3BtnOKBt.second} /></p>
          <img src="/assets/svg/Round3BtnOKBt-up.svg" className={styles.instruction__image} alt="" />
        </div>
      </li>
      <li className={`${styles.howTo__list__item} col-6`}>
        <div className={styles.howTo__list__item__content}>
          <p><FormattedMessage {...messages.connectBluetooth.round3BtnOKBt.third} /></p>
        </div>
      </li>
    </ol>
  </div>
);


const InstructionLongLight: FC = () => (
  <div>
    <ol className={`${styles.howTo__list} row`}>
      <li className={`${styles.howTo__list__item} col-6`}>
        <div className={styles.howTo__list__item__content}>
          <p><FormattedMessage {...messages.connectBluetooth.longLight.first} /></p>
          <img src="/assets/svg/LongLight-off.svg" className={styles.instruction__image} alt="" />
        </div>
      </li>
      <li className={`${styles.howTo__list__item} col-6`}>
        <div className={styles.howTo__list__item__content}>
          <p><FormattedMessage {...messages.connectBluetooth.longLight.second} /></p>
          <img src="/assets/svg/LongLight-on.svg" className={styles.instruction__image} alt="" />
        </div>
      </li>
      <li className={`${styles.howTo__list__item} col-6`}>
        <div className={styles.howTo__list__item__content}>
          <p><FormattedMessage {...messages.connectBluetooth.longLight.third} /></p>
        </div>
      </li>
    </ol>
  </div>
);


const Instructions: FC<InstructionsProps> = ({ activeInstruction, setActiveInstruction }) => {
  const Instruction: FC = activeInstruction === 1 ? InstructionRound3BtnOKBt : InstructionLongLight;

  return (
    <div className="pr-4">
      <p className="modal__info"><FormattedMessage {...messages.connectBluetooth.showInstructionsFor} /></p>
      <InstructionButtons {...{ activeInstruction, setActiveInstruction }} />
      <h3 className="modal__subheader mb-3">
        <FormattedMessage {...messages.connectBluetooth.howToStartConnection} />
      </h3>
      <Instruction />
    </div>
  );
};


const Meter: FC<MeterProps> = ({ discoveredDevice, deviceCaseType, onSetComponent }) => {
  const connect = useAction(actions.connectBluetooth);
  const setFormContext = useAction(App.actions.setFormContext, constants.BLUETOOTH_PIN_FORM);
  const setFormValues = useAction(App.actions.setFormValues, constants.BLUETOOTH_PIN_FORM);

  const { isPaired } = discoveredDevice;
  const { icon } = deviceCaseType;

  const pairedTagMessage = messages.connectBluetooth.tags[isPaired ? 'paired' : 'unpaired'];
  const buttonMessage = messages.buttons[isPaired ? 'download' : 'pairAndDownload'];

  const onDownload = () => {
    if (!discoveredDevice.isPaired && discoveredDevice.requiresPin) {
      setFormContext({ discoveredDevice });
      setFormValues({ deviceId: discoveredDevice.id });
      onSetComponent('BluetoothPairingInstruction');
      return;
    }
    connect(discoveredDevice);
  };

  return (
    <li className={styles.meters__list__item}>
      <figure
        className={`${styles.meters__list__item__fig} col-2`}
        dangerouslySetInnerHTML={{ __html: icon }} // eslint-disable-line react/no-danger
      />
      <div className={`col ${styles.meters__list__item__name}`}>
        { discoveredDevice.name }
        <div>
          <span className={cn('tag mt-2', { 'tag--success': isPaired })}>
            <FormattedMessage {...pairedTagMessage} />
          </span>
        </div>
      </div>
      <div className="col-auto pr-0">
        <Button
          styleModifier="primary"
          labelMessage={buttonMessage}
          className="btn--sm"
          onClick={onDownload}
        />
      </div>
    </li>
  );
};


const Meters: FC<MetersProps> = ({ discoveredDevices, devicesCaseTypes, onSetComponent }) => {
  const renderDevice = (discoveredDevice: DiscoveredBTDevice, idx: number) => (
    <Meter
      key={discoveredDevice.name}
      discoveredDevice={discoveredDevice}
      deviceCaseType={devicesCaseTypes[idx]}
      onSetComponent={onSetComponent}
    />
  );

  return (
    <div className="h-100">
      <h3 className="modal__info "><FormattedMessage {...messages.connectBluetooth.metersWithBluetooth} /></h3>
      <div className={styles.meters}>
        {
          isEmpty(discoveredDevices)
            ? (
              <div className={styles.meters__noMeters}>
                <p className={styles.meters__noMeters__info}>
                  <FormattedMessage {...messages.connectBluetooth.noMeters} />
                </p>
              </div>
            )
            : (
              <ul className={styles.meters__list}>
                { discoveredDevices.map(renderDevice) }
              </ul>
            )
        }
      </div>
    </div>
  );
};


const ConnectBluetooth: FC<Props> = ({ devicesByChannel, onSetComponent, onSetStyleModifier }) => {
  const connectionId = useSelector(selectors.connectionId);
  const { devices }: MDA = useSelector(selectors.downloader);
  const caseTypes = useSelector(App.selectors.caseTypes);

  const [activeInstruction, setActiveInstruction] = useState<number>(1);
  const [discoveredDevices, setDiscoveredDevices] = useState<DiscoveredBTDevice[]>([]);
  const [devicesCaseTypes, setDevicesCaseTypes] = useState<CaseType[]>([]);

  const startCheckingConnection = useAction(actions.startCheckingConnection);
  const startListeningBluetooth = useAction(actions.startListeningBluetooth);
  const stopListeningBluetooth = useAction(actions.stopListeningBluetooth);

  useEffect(() => {
    onSetStyleModifier('xl');
    startListeningBluetooth();
    return () => {
      onSetStyleModifier(null);
    };
  }, []);

  useEffect(() => {
    if (connectionId) {
      startCheckingConnection(connectionId);
      onSetComponent('Downloading');
    }
  }, [connectionId]);

  useEffect(() => {
    if (!devices || !devices.length) return;
    const newDownloaderDevices: DiscoveredBTDevice[] = differenceBy(devices, discoveredDevices, 'name');
    if (!newDownloaderDevices.length) return;

    const newDevicesCaseTypes: CaseType[] = [];
    const newDiscoveredDevices: DiscoveredBTDevice[] = [];
    const bluetoothDevices: Device[] = get(devicesByChannel, 'Bluetooth', []);

    for (let i = 0; i < newDownloaderDevices.length; i++) {
      const newDiscoveredDevice: DiscoveredBTDevice = newDownloaderDevices[i];
      const { sku } = newDiscoveredDevice;
      const device = find(bluetoothDevices, { sku });
      if (!device) continue;
      const { caseTypeId } = device;
      const caseType = find(caseTypes, { caseTypeId });
      if (!caseType) continue;
      newDevicesCaseTypes.push(caseType);
      newDiscoveredDevices.push(newDiscoveredDevice);
    }

    if (newDiscoveredDevices.length) {
      setActiveInstruction(['Round3BtnOKBt', 'Round3BtnOkBtLight'].includes(newDevicesCaseTypes[0].name) ? 1 : 2);
      setDevicesCaseTypes([...devicesCaseTypes, ...newDevicesCaseTypes]);
      setDiscoveredDevices([...discoveredDevices, ...newDiscoveredDevices]);
    }
  }, [devices]);

  const onSetChooseConnection = () => {
    stopListeningBluetooth();
    onSetComponent('ChooseConnection');
  };

  return (
    <div>
      <div className="row">
        <div className="col-7">
          <Instructions {...{ activeInstruction, setActiveInstruction }} />
        </div>
        <div className="col-5">
          <Meters {...{ discoveredDevices, devicesCaseTypes, onSetComponent }} />
        </div>
      </div>
      <div className={styles.divider}>
        <span className={styles.divider__text}><FormattedMessage {...messages.infos.or} /></span>
      </div>
      <div className="text--center">
        <Button
          styleModifier="transparent"
          labelMessage={messages.buttons.changeDeviceType}
          className="btn--no-size text--primary m-0"
          onClick={onSetChooseConnection}
        />
      </div>
    </div>
  );
};

export default ConnectBluetooth;
