import { buffers, eventChannel, END } from 'redux-saga';
import get from 'lodash/get';


/**
 * Files upload channels factory
 *
 * @param baseUrl - base path to build absolute adressess from relative endpoints urls
 * @returns {function(endpoint, data): Channel<any>}
 */
export default function createUploadFileChannel(baseUrl) {
  return (endpoint, data) => eventChannel((emitter) => {
    const xhr = new XMLHttpRequest();
    const onProgress = (evt) => {
      if (evt.lengthComputable) {
        const progress = evt.loaded / evt.total;
        emitter({ progress });
      }
    };
    const onFailure = (json) => {
      emitter({ success: false, response: json, error: get(json, 'error') });
      emitter(END);
    };
    xhr.upload.addEventListener('progress', onProgress);
    xhr.upload.addEventListener('error', onFailure);
    xhr.upload.addEventListener('abort', onFailure);
    xhr.onreadystatechange = () => {
      const { readyState, status, response } = xhr;
      if (readyState === 4) {
        const json = JSON.parse(response);
        if (status === 200) {
          emitter({ success: true, response: json });
          emitter(END);
        } else {
          onFailure(json);
        }
      }
    };
    xhr.open('POST', `${baseUrl}${endpoint}`, true);
    xhr.send(data);
    return () => {
      xhr.upload.removeEventListener('progress', onProgress);
      xhr.upload.removeEventListener('error', onFailure);
      xhr.upload.removeEventListener('abort', onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
}
