// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._33uI_{text-align:center;line-height:1.2}.g02n4{position:relative;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;width:80px;width:8rem;height:80px;height:8rem;margin:0 auto 3rem;background:#e0e8f2;border-radius:50%}._1VFIf{width:40px;width:4rem;height:40px;height:4rem;fill:#6f7ea2}.eDuWy{color:#6f7ea2;font-size:16px;font-size:1.6rem;font-weight:800}", ""]);
// Exports
exports.locals = {
	"root": "_33uI_",
	"avatar": "g02n4",
	"avatar__icon": "_1VFIf",
	"info": "eDuWy"
};
module.exports = exports;
