import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from 'modules/Visit/messages';
import noteShape from 'shapes/noteShape';


class ReplyBtn extends React.PureComponent {

  static propTypes = {
    note   : noteShape,
    onReply: PropTypes.func,
  }


  onReply(evt, note) {
    evt.preventDefault();
    this.props.onReply(note);
  }


  render() {
    const { note } = this.props;
    return (
      <a
        href=""
        className="d-inline-block text--bold"
        onClick={(evt) => this.onReply(evt, note)}
      >
        <FormattedMessage {...messages.buttons.reply} />
      </a>
    );
  }

}


export default ReplyBtn;
