import moment from 'moment/moment';
import { formatTimestamp } from 'helpers/datetime';


const isInRange = (value, range) => {
  const re = /^(([0-1][0-9])|(2[0-3])):[0-5][0-9]$/;
  const [start, end] = range;
  // Validate values
  if ([value, start, end].some((val) => !re.test(val))) {
    return;
  }

  // If start less than end, assume doesn't go over midnight
  if (start <= end) {
    // eslint-disable-next-line consistent-return
    return value >= start && value < end;
    // Otherwise, assume goes over midnight
  }
  // eslint-disable-next-line consistent-return
  return value >= start || value < end;
};


const acceptedMeals = ['Breakfast', 'Dinner', 'Lunch'];


const useRows = (gestationalReportSettings, reportsDates, readings) => {

  const days = [...Array(reportsDates.days).keys()]
    .reduce((res, elem, index) => [...res, moment(reportsDates.startDate).add(index, 'd').format('L')], []);

  const inRange = gestationalReportSettings.reduce((res, elem, index, array) => ({
    ...res,
    [index]: readings.filter((reading) => {
      let isChecked = false;

      if (acceptedMeals.includes(reading.mealType)) {
        isChecked = true;

        if (reading.mealType.toLowerCase() === gestationalReportSettings[index].id) {
          return true;
        }
      }

      if (!isChecked) {
        const b = array[index].value;
        const a = formatTimestamp(reading.timestamp, 'HH:mm');

        if (array[index + 1]) {
          const c = array[index + 1].value;
          return isInRange(a, [b, c]);
        }

        const c = array[0].value;
        return isInRange(a, [b, c]);
      }
    }),
  }), {});


  const readingsRow = days.reduce((daysObj, day) => {
    const tmpDay = {};

    for (const [mealType, readingsInMealTime] of Object.entries(inRange)) {
      tmpDay[mealType] = readingsInMealTime.filter((reading) => formatTimestamp(reading.timestamp) === day);
    }

    return [
      ...daysObj,
      { id: day, values: Object.values(tmpDay) },
    ];
  }, []);

  return readingsRow;
};

export default useRows;
