import { MODULE_ID } from './constants';


export const SEND_STATISTICS = `${MODULE_ID}/SEND_STATISTICS`;
export const SEND_STATISTICS_SUCCESS = `${MODULE_ID}/SEND_STATISTICS_SUCCESS`;
export const SEND_STATISTICS_ERROR = `${MODULE_ID}/SEND_STATISTICS_ERROR`;

export const SEND_STATISTICS_FOR_CLINIC = `${MODULE_ID}/SEND_STATISTICS_FOR_CLINIC`;
export const SEND_STATISTICS_FOR_CLINIC_SUCCESS = `${MODULE_ID}/SEND_STATISTICS_FOR_CLINIC_SUCCESS`;
export const SEND_STATISTICS_FOR_CLINIC_ERROR = `${MODULE_ID}/SEND_STATISTICS_FOR_CLINIC_ERROR`;

export const DELETE_STATISTICS_FROM_CLINIC = `${MODULE_ID}/DELETE_STATISTICS_FROM_CLINIC`;
export const DELETE_STATISTICS_FROM_CLINIC_SUCCESS = `${MODULE_ID}/DELETE_STATISTICS_FROM_CLINIC_SUCCESS`;
export const DELETE_STATISTICS_FROM_CLINIC_ERROR = `${MODULE_ID}/DELETE_STATISTICS_FROM_CLINIC_ERROR`;

export const LINK_PATIENT_PROFILE_TO_STATISTICAL_DB = `${MODULE_ID}/LINK_PATIENT_PROFILE_TO_STATISTICAL_DB`;
export const LINK_PATIENT_PROFILE_TO_STATISTICAL_DB_SUCCESS = `${MODULE_ID}/LINK_PATIENT_PROFILE_TO_STATISTICAL_DB_SUCCESS`;
export const LINK_PATIENT_PROFILE_TO_STATISTICAL_DB_ERROR = `${MODULE_ID}/LINK_PATIENT_PROFILE_TO_STATISTICAL_DB_ERROR`;

export const FETCH_CLINIC_KPI_STATISTICS = `${MODULE_ID}/FETCH_CLINIC_KPI_STATISTICS`;
export const FETCH_CLINIC_KPI_STATISTICS_SUCCESS = `${MODULE_ID}/FETCH_CLINIC_KPI_STATISTICS_SUCCESS`;
export const FETCH_CLINIC_KPI_STATISTICS_ERROR = `${MODULE_ID}/FETCH_CLINIC_KPI_STATISTICS_ERROR`;
