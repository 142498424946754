import React from 'react';
import get from 'lodash/get';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Avatar from 'components/Avatar';
import Button from 'components/Form/Button';
import AscensiaLogo from 'svg/Ascensia-logo.svg';
import Account from 'modules/Account';
import messages from '../../../messages';
import styles from './PatientHeader.pcss';


class PatientHeader extends React.PureComponent {

  static propTypes = {
    // Explicit props
    activeClinicMembership: Account.shapes.clinicMembership,
    meterSerialNumber     : PropTypes.string,
    onSaveClick           : PropTypes.func,
  };


  onDiscardTab() {
    window.close();
  }


  renderClinic() {
    const { name, logo } = get(this.props.activeClinicMembership, 'clinic', {});
    return (
      <div className="d-flex">
        <div className="col-auto pr-0">
          <Avatar
            avatarImg={logo}
            name={name}
            className={styles.clinicLogo}
            imgClassName={styles.clinicLogo__img}
            initialsClassName={styles.clinicLogo__initials}
          />
        </div>
        <div className="col">
          <p className={styles.clinicLogo__label}>{ name }</p>
        </div>
      </div>
    );
  }


  renderDiscard() {
    return (
      <Button
        styleModifier="quaternary"
        labelMessage={Account.messages.buttons.discard}
        onClick={() => this.onDiscardTab()}
      />
    );
  }


  renderMeterInfo() {
    if (!this.props.meterSerialNumber) return null;
    return (
      <p className="text--bold">
        <FormattedMessage
          {...messages.headers.previewingDataFromMeter}
          values={{ serialNumber: this.props.meterSerialNumber }}
        />
      </p>
    );
  }


  render() {
    return (
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="d-flex align-items-center">
          <div className={styles.element}>
            <AscensiaLogo className={styles.ascensiaLogo} />
          </div>
          <div className={styles.element}>{ this.renderClinic() }</div>
        </div>
        <div className={cn(styles.header__rightSide, 'd-flex align-items-center')}>
          <div className={styles.element}>{ this.renderMeterInfo() }</div>
          <div className={cn(styles.element, 'mr-0')}>
            { this.renderDiscard() }
          </div>
        </div>
      </div>
    );
  }

}


export default withStyles(styles)(PatientHeader);
