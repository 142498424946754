import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import messages from 'modules/AmbulatoryGlucoseProfile/messages';
import styles from './HeaderSection.pcss';


class HeaderSection extends React.Component {

  static propTypes = {
    // Explicit props
    firstName: PropTypes.string,
    lastName : PropTypes.string,
  };

  render() {
    const { firstName, lastName } = this.props;
    return (
      <div className={styles.headerSection}>
        <div className="reportName">
          <h1 className="reportName__title"><b>AGP</b> <FormattedMessage {...messages.labels.report} /></h1>
        </div>
        <div className="reportInfo">
          <div className="reportInfo__row">
            <h2 className="reportInfo__rowTitle"><FormattedMessage {...messages.labels.name} /></h2>
            <span className="reportInfo__rowContent">{ firstName } { lastName }</span>
          </div>
          <div className="reportInfo__row">
            <h2 className="reportInfo__rowTitle"><FormattedMessage {...messages.labels.mrn} /></h2>
            <span className="reportInfo__rowContent" />
          </div>
        </div>
      </div>
    );
  }

}

export default withStyles(styles)(HeaderSection);
