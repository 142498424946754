import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { formatTimestamp } from 'helpers/datetime';
import intlShape from 'shapes/intlShape';
import styles from './VisitNotes.pcss';


class VisitNoteHeader extends React.PureComponent {

  static propTypes = {
    // Explicit props
    note: PropTypes.shape({
      payload: PropTypes.object,
    }),
    // Implicit props
    intl: intlShape,
  };


  renderNoteHeaderDateRange(payload) {
    const { start, end } = payload;
    if (!start && !end) {
      return null;
    }
    const startDate = formatTimestamp(start) || '';
    const endDate = formatTimestamp(end) || '';
    let dateRange = '(';
    if (startDate) dateRange += startDate;
    if (startDate && endDate) dateRange += ' - ';
    if (endDate) dateRange += endDate;
    dateRange += ')';
    return <span className={styles.note__header__dateRange}>{ dateRange }</span>;
  }


  renderNoteValue(payload) {
    const { value, standardDeviation, unitSymbol } = payload;
    if (isNull(value) || isUndefined(value)) {
      return null;
    }
    let noteValue = `${value}`;
    if (!isNull(standardDeviation) && !isUndefined(standardDeviation)) noteValue += `\u00b1${standardDeviation}`;
    if (unitSymbol) noteValue += unitSymbol;
    return <span dir="ltr">{noteValue}</span>;
  }


  render() {
    const { payload } = this.props.note;
    if (!payload) {
      return null;
    }
    const { titleMessage, rangeValue, value, status } = payload;
    let noteHeader = titleMessage ? this.props.intl.formatMessage(titleMessage) : '';
    if (!isNull(rangeValue) && !isUndefined(rangeValue)) noteHeader += ` (${rangeValue})`;
    if (!isNull(value) && !isUndefined(value)) noteHeader += ': ';
    return (
      <h3 className={cn(styles.note__header, { [styles[`note__header--${status}`]]: status })}>
        { noteHeader }{ this.renderNoteValue(payload) }
        { this.renderNoteHeaderDateRange(payload) }
      </h3>
    );
  }

}


export default injectIntl(VisitNoteHeader);
