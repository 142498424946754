import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { AppContext } from 'context';
import CheckIcon from 'svg/check.svg';
import XIcon from 'svg/x.svg';
import styles from 'modules/Hcp/components/MergePatientModal/MergePatientModal.pcss';
import * as selectors from '../../selectors';
import messages from '../../messages';


class Merging extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    activePatient: PropTypes.shape({
      firstName  : PropTypes.string.isRequired,
      lastName   : PropTypes.string.isRequired,
      dateOfBirth: PropTypes.string,
    }),
    clinicPatient: PropTypes.shape({
      id       : PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName : PropTypes.string.isRequired,
    }),
    // Explicit actions
    onSetHeaderMessage: PropTypes.func,
    onClose           : PropTypes.func,
    // Implicit props
    isInProgress      : PropTypes.bool,
    hasErrors         : PropTypes.bool,
  };


  constructor(props) {
    super(props);
    props.onSetHeaderMessage(messages.headers.mergingPatient);
  }


  componentDidUpdate(prevProps) {
    const { activePatient } = this.props;
    if (prevProps.activePatient !== activePatient) {
      this.props.onClose();
    }
  }


  renderIndicator() {
    const { isInProgress, hasErrors } = this.props;
    if (isInProgress) {
      return (
        <div className={styles.loaderContainer}>
          <img src="/assets/svg/loader.svg" className={`${styles.loader} rotatingLoader`} alt="" />
        </div>
      );
    }
    const Icon = hasErrors ? XIcon : CheckIcon;
    return (
      <div className={cn(styles.indicatorBackground, { [styles.error]: hasErrors })}>
        <div className={styles.indicator}>
          <div className={styles.loaderContainer}>
            <Icon className={styles.indicatorIcon} />
          </div>
        </div>
      </div>
    );
  }


  render() {
    return (
      <div className="d-flex flex-column align-items-center">
        <div className="my-4">
          { this.renderIndicator() }
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  isInProgress: selectors.isMergePatientInProgress(state),
  hasErrors   : selectors.hasMergePatientErrors(state),
});


const ConnectedMerging = connect(
  mapStateToProps,
)(Merging);


export default ConnectedMerging;
