import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { useAction } from 'hooks';
import Button from 'components/Form/Button';
import * as actions from '../../../actions';
import messages from '../../../messages';
import App from '../../../../App';
import * as constants from '../../../constants';
import styles from '../DownloadDataModal.pcss';


interface Props {
  onSetComponent: (componentName: string) => void,
  onSetStyleModifier: (styleModifier: string) => void,
}


const BluetoothPairingInstruction: FC<Props> = ({ onSetComponent, onSetStyleModifier }) => {
  const formValues = useSelector(App.selectors.formSelector(constants.BLUETOOTH_PIN_FORM));
  const connect = useAction(actions.connectBluetooth);

  useEffect(() => {
    onSetStyleModifier('lg');
    return () => {
      onSetStyleModifier(null);
    };
  }, []);

  const onNext = () => {
    const discoveredDevice = get(formValues, 'contextData.discoveredDevice');
    connect(discoveredDevice);
    onSetComponent('BluetoothPin');
  };

  const onChangeDeviceType = () => {
    onSetComponent('ChooseConnection');
  };

  return (
    <div className={`${styles.modal__content} h-auto`}>
      <h3 className="modal__subheader "><FormattedMessage {...messages.headers.howToEnablingPairing} /></h3>
      <div>
        <ol className={`${styles.howTo__list} ${styles['howTo__list--pairing']} row`}>
          <li className={`${styles.howTo__list__item} col-6`}>
            <div className={styles.howTo__list__item__content}>
              <p><FormattedMessage {...messages.pairingBluetooth.round3BtnOKBt.first} /></p>
              <img src="/assets/svg/Round3BtnOKBt-up.svg" className={`${styles.instruction__image} mt-4`} alt="" />
            </div>
          </li>
          <li className={`${styles.howTo__list__item} col-6`}>
            <div className={styles.howTo__list__item__content}>
              <p><FormattedMessage {...messages.pairingBluetooth.round3BtnOKBt.second} /></p>
              <img src="/assets/svg/Round3BtnOKBt-bt.svg" className={`${styles.instruction__image} mt-4`} alt="" />
            </div>
          </li>
        </ol>
      </div>
      <Button
        type="submit"
        styleModifier="primary"
        labelMessage={messages.buttons.next}
        className={`btn--block btn--filled ${styles.pairing__button}`}
        onClick={onNext}
      />
      <div className={styles.divider}>
        <span className={styles.divider__text}><FormattedMessage {...messages.infos.or} /></span>
      </div>
      <Button
        styleModifier="transparent"
        labelMessage={messages.buttons.changeDeviceType}
        className="btn--no-size text--primary m-0"
        onClick={onChangeDeviceType}
      />
    </div>
  );
};


export default BluetoothPairingInstruction;
