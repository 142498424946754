import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import {
  dailyRecordsShape,
} from 'shapes/bins';
import App from 'modules/App';
import AmbulatoryGlucoseProfile from 'modules/AmbulatoryGlucoseProfile';
import messages from 'modules/AmbulatoryGlucoseProfile/messages';
import BloodGlucoseProfileReportAgpMiniChart from 'components/Charts/BloodGlucoseProfileAgpMiniChart/BloodGlucoseProfileReportAgpMiniChart';
import SectionTitle from '../components/SectionTitle';
import styles from './DailyGlucoseProfileSection.pcss';


class DailyGlucoseProfilesSection extends React.Component {

  static propTypes = {
    // Implicit props
    dailyRecordsTopTwoWeeks: dailyRecordsShape,
    // Explicit props
    conversion             : PropTypes.object.isRequired,
    standards              : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    direction: PropTypes.string,
    maxValue : PropTypes.number,
  };


  renderChart(dailyRecords) {
    const {
      conversion,
      standards,
      direction,
      maxValue,
    } = this.props;
    return (
      <BloodGlucoseProfileReportAgpMiniChart
        conversion={conversion}
        standards={standards}
        records={dailyRecords.records}
        direction={direction}
        maxValue={maxValue}
      />
    );
  }


  renderDay() {
    const {
      dailyRecordsTopTwoWeeks,
    } = this.props;
    const firstWeek = dailyRecordsTopTwoWeeks.slice(0, 7);
    const firstWeekWeekdays = firstWeek.map(({ weekday }) => weekday);
    return (
      <>
        <div className={styles.weekdays}>
          {firstWeekWeekdays.map((weekday) => (
            <div className={styles.weekday} key={weekday}>{weekday}</div>
          ))
        }
        </div>
        <div className={styles.day}>
          {
            dailyRecordsTopTwoWeeks.map((dailyRecord, i) => {
              const isFirstRow = i < 7;
              return (
                <div
                  className={
                  cn(styles.day__cell, {
                    [styles['day__cell--firstRow']]: isFirstRow,
                  })}
                  key={dailyRecord.date}
                  role="presentation"
                >
                  { this.renderChart(dailyRecord)}
                  <div className={styles.day__cellDayNumber}>{Number(dailyRecord.date.split('-')[2]) || ''}</div>
                  { isFirstRow
                    && (
                      <>
                        {i === 0 && (
                          <div className={cn(styles.day__cellHour, styles['day__cellHour--start'])}>
                            {moment('00', 'hh').format('LT').toString().replaceAll(':00', '')}
                          </div>
                        )}
                        <div className={cn(styles.day__cellHour, styles['day__cellHour--middle'])}>
                          {moment('12', 'hh').format('LT').toString().replaceAll(':00', '')}
                        </div>
                        <div className={cn(styles.day__cellHour, styles['day__cellHour--end'],
                          {
                            [styles['day__cellHour--last']]: i === 6,
                          })}
                        >
                          {moment('00', 'hh').format('LT').toString().replaceAll(':00', '')}
                        </div>
                      </>
                    )
                  }
                </div>
              );
            })}
        </div>
      </>
    );
  }


  render() {
    return (
      <div className={styles.dailyGlucoseProfilesSectionWrapper}>
        <SectionTitle>
          <FormattedMessage {...messages.headers.dailyGlucoseProfiles} />
        </SectionTitle>
        <h2 className={styles.profileAnnotation}>
          <FormattedMessage {...messages.labels.dailyBgmGlucoseProfilesAnnotation} />
        </h2>
        {this.renderDay()}
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  dailyRecordsTopTwoWeeks: AmbulatoryGlucoseProfile.selectors.dailyRecordsTopTwoWeeks(state),
  direction              : App.selectors.direction(state),
});


const ConnectedDailyGlucoseProfilesSection = connect(
  mapStateToProps,
)(DailyGlucoseProfilesSection);

export default withStyles(styles)(ConnectedDailyGlucoseProfilesSection);
