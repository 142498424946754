import * as actionTypes from './actionTypes';


export const setMode = (mode) => ({
  type   : actionTypes.SET_MODE,
  payload: {
    mode,
  },
});

export const setDeviceMode = (deviceMode) => ({
  type   : actionTypes.SET_DEVICE_MODE,
  payload: {
    deviceMode,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const setCalculationFormula = (calculationFormula) => ({
  type   : actionTypes.SET_CALCULATION_FORMULA,
  payload: {
    calculationFormula,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const setAggregateBy = (aggregateBy) => ({
  type   : actionTypes.SET_AGGREGATE_BY,
  payload: {
    aggregateBy,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const setGroupBy = (groupBy) => ({
  type   : actionTypes.SET_GROUP_BY,
  payload: {
    groupBy,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const changeFromImports = (importDocumentId, imports) => ({
  type   : actionTypes.CHANGE_FROM_IMPORTS,
  payload: {
    importDocumentId,
    imports,
  },
});

export const clearFromImports = () => ({
  type: actionTypes.CLEAR_FROM_IMPORTS,
});

//----------------------------------------------------------------------------------------------------------------------

export const setIsTrendChartLineEnabled = (isTrendChartLineEnabled) => ({
  type   : actionTypes.SET_IS_TREND_CHART_LINE_ENABLED,
  payload: {
    isTrendChartLineEnabled,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const setDashboardLayout = (layout) => ({
  type   : actionTypes.SET_DASHBOARD_LAYOUT,
  payload: { layout },
});

//----------------------------------------------------------------------------------------------------------------------

export const setResultsInProgress = (isInProgress) => ({
  type   : actionTypes.SET_RESULTS_IN_PROGRESS,
  payload: { isInProgress },
});

//----------------------------------------------------------------------------------------------------------------------

export const dumpReportsState = (reportsState) => ({
  type   : actionTypes.DUMP_REPORTS_STATE,
  payload: { reportsState },
});

export const storeSelectedReportTypes = (reportTypes) => ({
  type   : actionTypes.STORE_SELECTED_REPORT_TYPES,
  payload: { reportTypes },
});

//----------------------------------------------------------------------------------------------------------------------

export const setGestationalReportSettings = (gestationalReportSettings) => ({
  type   : actionTypes.SET_GESTATIONAL_REPORT_SETTINGS,
  payload: {
    gestationalReportSettings,
  },
});
