export const MODULE_ID = 'patientResults';

export const MODES = ['ALL', 'AGGREGATED', 'GROUPED'];
export const DEVICES_MODES = ['BGM', 'CGM', 'AGP'];
export const CALCULATION_FORMULAS = ['NONE', 'MEAN', 'MEDIAN+STD', 'HYPOGLYCEMIA', 'HYPERGLYCEMIA', 'COUNT'];
export const CALCULATION_FORMULAS_CGM = ['BINS'];
export const AGGREGATE_BY = ['DAY', 'WEEK', 'MONTH'];
export const GROUP_BY = ['HOURS_RANGE', 'WEEKDAY', 'MONTH', 'QUARTER'];
export const GROUP_BY_CGM = ['HOURS'];
export const HOURS_BREAKPOINTS = [[0, 0], [6, 0], [12, 0], [18, 0]];
export const FLAGS_ORDER = ['All', 'Fasting', 'PreMeal', 'PostMeal', 'None'];


export const DATE_RANGE_PRESETS = [
  {
    number: 3,
    unit  : 'days',
  },
  {
    number: 7,
    unit  : 'days',
  },
  {
    number: 14,
    unit  : 'days',
  },
  {
    number: 30,
    unit  : 'days',
  },
  {
    number: 3,
    unit  : 'months',
  },
];

export const MEASUREMENTS_MODAL = `${MODULE_ID}/measurementsModal`;
export const PROFILE_EDIT_MODAL = `${MODULE_ID}/profileEditModal`;
export const ADDITIONAL_MEASUREMENTS_MODAL = `${MODULE_ID}/additionalMeasurementsModal`;

export const ADDITIONAL_MEASUREMENTS_FM = `${MODULE_ID}/additionalMeasurementsFloatingModal`;

export const MEASUREMENTS_FORM = `${MODULE_ID}.measurementsForm`;
export const RESULTS_SETTINGS_FORM = `${MODULE_ID}.resultsSettingsForm`;

export const BLOOD_PRESSURE_FORM = `${MODULE_ID}/bloodPressureForm`;
export const LDL_FORM = `${MODULE_ID}/ldlForm`;
export const TRIGLYCERIDES_FORM = `${MODULE_ID}/triglyceridesForm`;
export const BMI_FORM = `${MODULE_ID}/bmiForm`;

export const PROFILE_DROPDOWN = `${MODULE_ID}.profileDropdown`;

export const PILLS_DROPDOWN = `${MODULE_ID}.pillsDropdown`;

/**
 * Name convention - type/{type}Print is used by downloadReport saga
 */
export const DASHBOARD_LAYOUTS = {
  basic: [
    'ContentBar',
    'ChartControls',
    'KPIs',
    [
      'col-12 col-xl-auto;GlucoseConcentrationLevelsWidget',
      'col;TrendChartWidget',
    ],
    'MeasurementsTableWidget',
    'SummaryTableWidget',
  ],
  previewResults: [
    'ContentBar',
    'ChartControls',
    'KPIs',
    [
      'col-12 col-xl-auto;GlucoseConcentrationLevelsWidget',
      'col;TrendChartWidget',
    ],
    'MeasurementsTableWidgetWithoutMyDownloads',
    'SummaryTableWidget',
  ],
  agpBgm: [
    'ContentBar',
    'ChartControls',
    'KPIs',
    [
      'col-12 col-xl-auto;GlucoseConcentrationLevelsWidget',
      'col;TrendChartWidget',
    ],
    'DaysSelector',
    'SummaryTableWidget',
  ],
  basicPrint: [
    'PatientHeader',
    'TrendChartWidget',
    'GlucoseConcentrationLevelsWidget',
    'SummaryTableWidgetReport',
    'MeasurementsTableWidget',
  ],
  agp: [
    'CgmStatisticsWidget',
    'CgmDistributionWidget',
    'AgpProfileWidget',
  ],
  agpPrint: [
    'CgmStatisticsWidget',
    'CgmDistributionWidget',
    'AgpProfileWidget',
  ],
  agpBgmPrint: [
    'PrintableAgpBgmReport',
  ],
  agpCapturPrint: [
    'PrintableAgpReport',
  ],
  bloodGlucoseConcentrationPrint: [
    'BloodGlucoseConcentrationReport',
  ],
  dailyLogPrint: [
    'DailyLogReportPrint',
  ],
  patientsBgDataReportPrint: [
    'PatientsBgDataReport',
  ],
  patientsGestationalReportPrint: [
    'PatientsGestationalReport',
  ],
};

export const BLOOD_GLUCOSE_CONCENTRATION_REPORT_PARAMETERS = {
  breakpoints: [[5, 0], [11, 0], [16, 0], [21, 0]],
  startHour  : 5,
};

export const KPI_TYPES = {
  GMI               : 'GMI',
  VERY_HIGH         : 'veryHigh',
  VERY_LOW          : 'veryLow',
  STANDARD_DEVIATION: 'standardDeviation',
  AVERAGE           : 'average',
  AVERAGE_PRE_MEAL  : 'averagePreMeal',
  AVERAGE_POST_MEAL : 'averagePostMeal',
  CV                : 'CV',
};

export const KPI_TARGETS = {
  HIGH   : 'high',
  TARGET : 'target',
  LOW    : 'low',
  NEUTRAL: 'neutral',
};

export const GMI_TARGETS = {
  LOW   : 5,
  TARGET: 7,
};

export const CV_TARGETS = {
  NEUTRAL: 33,
};

export const GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES = {
  MG_DL: {
    maxValue: 350,
    veryHigh: 250,
    high    : 180,
    target  : 70,
    low     : 54,
    veryLow : 0,
  },
  MMOL_L: {
    maxValue: 20,
    veryHigh: 13.9,
    high    : 10,
    target  : 3.9,
    low     : 3,
    veryLow : 0,
  },
};

export const GLUCOSE_CONCENTRATION_LEVELS = {
  MG_DL: [
    GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES.MG_DL.veryHigh,
    GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES.MG_DL.high,
    GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES.MG_DL.target,
    GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES.MG_DL.low,
    GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES.MG_DL.veryLow,
  ],
  MMOL_L: [
    GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES.MMOL_L.veryHigh,
    GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES.MMOL_L.high,
    GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES.MMOL_L.target,
    GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES.MMOL_L.low,
    GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES.MMOL_L.veryLow,
  ],
};

export const BEFORE_MEAL_TARGET = {
  MG_DL: {
    max: 130,
    min: 70,
  },
  MMOL_L: {
    max: 7.2,
    min: 3.9,
  },
};

export const GLUCOSE_CONCENTRATION_LEVEL_STEP = {
  MG_DL : 1,
  MMOL_L: 0.1,
};

export const READING_COUNT_TO_SHOW_AGP_BGM = 30;

export const MEASUREMENT_TYPES = {
  veryHigh: 'veryHigh',
  high    : 'high',
  normal  : 'normal',
  low     : 'low',
  veryLow : 'veryLow',
};

export const RESULT_TYPE = {
  BLOOD_PRESSURE          : 'BloodPressure',
  SYSTOLIC_BLOOD_PRESSURE : 'SystolicBloodPressure',
  DIASTOLIC_BLOOD_PRESSURE: 'DiastolicBloodPressure',
  LDL                     : 'LDL',
  TRIGLYCERIDES           : 'Triglycerides',
  WEIGHT                  : 'Weight',
  HEIGHT                  : 'Height',
  BMI                     : 'BMI',
};
