import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';
import Validator from 'libs/Validator';
import Form from 'components/Form';
import Button from 'components/Form/Button';
import Modal from 'components/Modal';
import Link from 'components/Link';
import PhiSetFormPartial from 'components/PhiSetFormPartial';
import accessTokenShape from 'shapes/accessTokenShape';
import App from 'modules/App';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import Statistics from 'modules/Statistics';
import Patient from 'modules/Patient';
import ContourCloud from 'modules/ContourCloud';
import Tabs from 'components/Tabs';
import * as constants from '../../constants';
import messages from '../../messages';
import validatorRules from './validatorRules.json';
import styles from './MeasurementsModal.pcss';


class MeasurementsModal extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const { isStoreInProgress, hasStoreErrors, hasSendStatsErrors } = props;

    if (isStoreInProgress !== state.isInProgress && isStoreInProgress) {
      return { isInProgress: true };
    }
    if ((hasStoreErrors || hasSendStatsErrors) && state.isInProgress) {
      return { isInProgress: false };
    }
    return null;
  }


  static propTypes = {
    // Explicit props
    metricConversions: PropTypes.shape({
      weight                   : PropTypes.object,
      height                   : PropTypes.object,
      bloodGlucoseConcentration: PropTypes.object,
    }),
    patient: PropTypes.shape({
      phiSetReferenceKey: PropTypes.string,
      storageProvider   : PropTypes.string,
      accessToken       : accessTokenShape,
    }),
    phiSet                 : PropTypes.object, // @TODO: shape
    phiSetDocumentId       : PropTypes.string,
    isPatientMode          : PropTypes.bool,
    standards              : PropTypes.object,
    // Explicit actions
    onSuccess              : PropTypes.func,
    // Implicit props
    passphrase             : PropTypes.string,
    localizationResources  : PropTypes.object,
    activeClinicMembership : Account.shapes.clinicMembership,
    isStoreInProgress      : PropTypes.bool,
    hasStoreErrors         : PropTypes.bool,
    isSendStatsInProgress  : PropTypes.bool,
    hasSendStatsErrors     : PropTypes.bool,
    openModalId            : PropTypes.string,
    formValuesHealth       : PropTypes.object,
    formValuesTargets      : PropTypes.object,
    profileCC              : PropTypes.object,
    activeProfileType      : Account.shapes.profileType,
    country                : PropTypes.object,
    // Implicit actions
    onStore                : PropTypes.func,
    onSendStats            : PropTypes.func,
    onSendStatsForClinic   : PropTypes.func,
    onCloseModal           : PropTypes.func,
    onFormProcessingHealth : PropTypes.func,
    onFormProcessingTargets: PropTypes.func,
    onSetFormValue         : PropTypes.func,
    onSetFormValuesHealth  : PropTypes.func,
    onSetFormValuesTargets : PropTypes.func,
    onFormErrors           : PropTypes.func,
    onClearFormHealth      : PropTypes.func,
    onClearFormTargets     : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      isInProgress: false,
      view        : 'healthInformation', // 'targetsInformation'
      errorTabs   : {
        healthInformation : false,
        targetsInformation: false,
      },
    };
    this.onPopulate(props.phiSet);
  }


  componentDidUpdate(prevProps) {
    const {
      patient, phiSet, isPatientMode, activeClinicMembership, openModalId,
      isStoreInProgress, hasStoreErrors, isSendStatsInProgress, hasSendStatsErrors,
      standards,
    } = this.props;

    if (openModalId !== constants.MEASUREMENTS_MODAL) {
      return;
    }

    if (prevProps.phiSet !== phiSet || prevProps.openModalId !== openModalId) {
      this.onPopulate(phiSet);
    }

    if (prevProps.isStoreInProgress !== isStoreInProgress && !isStoreInProgress && !hasStoreErrors) {
      const patientStandards = standards;
      if (isPatientMode) {
        this.props.onSendStats(patient, phiSet, {}, patientStandards, this.props.passphrase);
      } else {
        const clinicSettings = get(activeClinicMembership, 'clinic.settings');
        if (clinicSettings) {
          const conversion = this.props.metricConversions;
          patientStandards.preMeal.lowThreshold
            = conversion.bloodGlucoseConcentration.toDisplay(clinicSettings.glucosePreMealLow);
          patientStandards.preMeal.highThreshold
            = conversion.bloodGlucoseConcentration.toDisplay(clinicSettings.glucosePreMealHigh);
          patientStandards.postMeal.lowThreshold
            = conversion.bloodGlucoseConcentration.toDisplay(clinicSettings.glucosePostMealLow);
          patientStandards.postMeal.highThreshold
            = conversion.bloodGlucoseConcentration.toDisplay(clinicSettings.glucosePostMealHigh);
        }
        this.props.onSendStatsForClinic(patient, phiSet, {}, patientStandards, this.props.activeClinicMembership);
      }
    }

    if (prevProps.isSendStatsInProgress !== isSendStatsInProgress && !isSendStatsInProgress && !hasSendStatsErrors) {
      this.onClose();
    }
  }


  async onClose() {
    await this.props.onCloseModal();
    this.props.onClearFormHealth();
    this.props.onClearFormTargets();
    this.setState({
      isInProgress: false,
      errorTabs   : { healthInformation: false, targetsInformation: false },
    });
  }


  onPopulate(phiSet) {
    const conversion = this.props.metricConversions;
    const weight = get(phiSet, 'summaryData.lastWeight', '');
    const height = get(phiSet, 'summaryData.lastHeight', '');
    this.props.onSetFormValuesHealth({
      weight       : weight && this.props.metricConversions.weight.toDisplay(weight),
      height       : height && this.props.metricConversions.height.toDisplay(height),
      diabetesType : get(phiSet, 'diabetesType', ''),
      treatmentType: get(phiSet, 'treatmentType', ''),
    });

    const glucoseLevelTargetsPhiSet = get(phiSet, 'glucoseLevelTargets', {});
    const glucoseLevelTargets = {};
    if (glucoseLevelTargetsPhiSet) {
      Object.keys(glucoseLevelTargetsPhiSet).forEach((targetType) => {
        Object.keys(glucoseLevelTargetsPhiSet[targetType]).forEach((key) => {
          glucoseLevelTargets[`${targetType}_${key}`] = conversion.bloodGlucoseConcentration.toDisplay(glucoseLevelTargetsPhiSet[targetType][key]);
        });
      });
    }
    this.props.onSetFormValuesTargets(glucoseLevelTargets);
  }


  onSubmit() {
    const conversion = this.props.metricConversions;
    this.props.onFormProcessingHealth();
    const values = { ...get(this.props.formValuesHealth, 'values', {}) };
    const { validatedValues, errors } = Validator.run(values, validatorRules);
    const formValuesTargets = { ...get(this.props.formValuesTargets, 'values', {}) };
    this.props.onFormErrors(errors);
    if (!errors) {
      this.onClearErrorTabs();
      const { metricConversions, patient, phiSet, phiSetDocumentId, onSuccess } = this.props;
      const { weight, height } = validatedValues;
      const measurements = {};
      measurements.weight = weight && metricConversions.weight.toStorage(weight);
      measurements.height = height && metricConversions.height.toStorage(height);

      const phiEntries = omit(validatedValues, ['weight', 'height']);

      const glucoseLevelTargets = {};
      Object.keys(formValuesTargets).forEach((id) => {
        const splittedId = id.split('_');
        glucoseLevelTargets[splittedId[0]] = {
          ...glucoseLevelTargets[splittedId[0]],
          [splittedId[1]]: conversion.bloodGlucoseConcentration.toStorage(formValuesTargets[id]),
        };
      });

      const updatedPhiSet = { ...phiSet, ...phiEntries, glucoseLevelTargets };
      if (this.differencesInHealthData) {
        this.props.onStore(measurements, updatedPhiSet, phiSetDocumentId, patient, onSuccess);
      }
    } else {
      this.onErrorSubmit(errors);
    }
  }


  onErrorSubmit(errors) {
    const errorTabs = {
      healthInformation : false,
      targetsInformation: false,
    };

    if (!isEmpty(errors)) {
      errorTabs.healthInformation = true;
    }
    this.setState({ errorTabs });
  }


  onClearErrorTabs() {
    const errorTabs = {
      healthInformation : false,
      targetsInformation: false,
    };
    this.setState({ errorTabs });
  }


  get isPatientProfile() {
    const { activeProfileType } = this.props;
    return activeProfileType === Account.constants.PROFILE_TYPES.PWD;
  }


  get shouldShowWarningInfoCC() {
    return this.isDisabledDiabetesType || this.isDisabledTreatmentType;
  }


  get differencesInHealthData() {
    const { phiSet } = this.props;
    const values = { ...get(this.props.formValuesHealth, 'values', {}) };
    const weight = get(phiSet, 'summaryData.lastWeight', '');
    const height = get(phiSet, 'summaryData.lastHeight', '');
    const diabetesType = get(phiSet, 'diabetesType', '');
    const treatmentType = get(phiSet, 'treatmentType', '');

    return values.weight !== this.props.metricConversions.weight.toDisplay(weight)
      || values.height !== this.props.metricConversions.height.toDisplay(height)
      || values.diabetesType !== diabetesType
      || values.treatmentType !== treatmentType;
  }


  renderNav() {
    const { errorTabs } = this.state;
    return (
      <Tabs
        activeItem={this.state.view}
        items={['healthInformation', 'targetsInformation']}
        messages={messages.nav}
        action={(view) => this.setState({ view })}
        errors={errorTabs}
        modified={{
          healthInformation : this.differencesInHealthData,
          targetsInformation: this.differencesInGlucoseLevelTarget,
        }}
      />
    );
  }


  renderActions() {
    return (
      <div className="row">
        <div className="col-6">
          <Button
            styleModifier="quaternary"
            labelMessage={messages.buttons.cancel}
            className="btn--block"
            isDisabled={this.state.isInProgress}
            onClick={() => this.onClose()}
          />
        </div>
        <div className="col-6">
          <Button
            type="submit"
            styleModifier="primary"
            labelMessage={messages.buttons.submit}
            className="btn--block btn--filled"
            isInProgress={this.state.isInProgress}
          />
        </div>
      </div>
    );
  }

  renderLinkToApp() {
    const { country } = this.props;
    const iosUrl = get(country, 'settings.iosStoreURL', null);
    const androidUrl = get(country, 'settings.androidStoreURL', null);
    return (
      <div className={styles.tab__urlsInformationWrapper}>
        {iosUrl && (
          <Link
            to={iosUrl}
            className={styles.nav__item__link}
          >
            <FormattedMessage {...messages.labels.ios} />
          </Link>
        )}
        |
        {androidUrl && (
          <Link
            to={androidUrl}
            className={styles.nav__item__link}
          >
            <FormattedMessage {...messages.labels.android} />
          </Link>
        )}
      </div>
    );
  }


  render() {
    let content;
    switch (this.state.view) {
      case 'healthInformation': {
        content = (
          <Form className={styles.form} onSubmit={() => this.onSubmit()}>
            {this.shouldShowWarningInfoCC && <Account.components.WarningInfoCC />}
            <PhiSetFormPartial
              metricConversions={this.props.metricConversions}
              localizationResources={this.props.localizationResources}
              formValues={this.props.formValuesHealth}
              onSetFormValue={this.props.onSetFormValue}
              isDisabledDiabetesType={this.isPatientProfile}
              isDisabledTreatmentType={this.isPatientProfile}
              isActiveProfileTypePwd={this.isPatientProfile}
            />
            { this.renderActions() }
          </Form>
        );
        break;
      }
      case 'targetsInformation': {
        content = (
          <Form onSubmit={() => this.onSubmit()}>
            <div className={cn(styles.tab__information, 'mb-4')}>
              <FormattedMessage {...messages.labels.modalGlucoseLevelPwd} values={{ url: this.renderLinkToApp() }} />
            </div>
            <Patient.partials.GlucoseLevelTargets isDisabled phiSet={this.props.phiSet} />
            { this.renderActions() }
          </Form>
        );
        break;
      }
      default: {
        content = null;
      }
    }

    return (
      <Modal
        modalId={constants.MEASUREMENTS_MODAL}
        openModalId={this.props.openModalId}
        styleModifier="lg"
        onClose={() => this.onClose()}
      >
        {this.renderNav()}
        {content}
      </Modal>
    );
  }

}


const mapStateToProps = (state) => {
  const countryId = Account.selectors.countryId(state);

  return ({
    passphrase            : Account.selectors.passphrase(state),
    activeClinicMembership: Account.selectors.activeClinicMembership(state),
    localizationResources : App.selectors.localizationResources(state),
    metricsUnits          : Account.selectors.metricsUnits(state),
    activeProfileType     : Account.selectors.activeProfileType(state),
    isStoreInProgress     : CloudDrive.selectors.isStoreMeasurementsInProgress(state),
    hasStoreErrors        : CloudDrive.selectors.hasStoreMeasurementsErrors(state),
    isSendStatsInProgress : Statistics.selectors.isSendStatsInProgress(state),
    hasSendStatsErrors    : Statistics.selectors.hasSendStatsErrors(state),
    openModalId           : App.selectors.modal(state),
    formValuesHealth      : App.selectors.formSelector(constants.MEASUREMENTS_FORM)(state),
    formValuesTargets     : App.selectors.formSelector(Patient.constants.PATIENT_GLUCOSE_SETTINGS_FORM)(state),
    country               : App.selectors.countryById(countryId)(state),
    profileCC             : ContourCloud.selectors.profile(state),
  });
};


const mapDispatchToProps = (dispatch) => {
  const formNameHealth = constants.MEASUREMENTS_FORM;
  const formNameTargets = Patient.constants.PATIENT_GLUCOSE_SETTINGS_FORM;

  return {
    onStore: (measurements, phiSet, phiSetDocumentId, patient, successAction) => dispatch(
      CloudDrive.actions.storeMeasurements(measurements, phiSet, phiSetDocumentId, patient, undefined, successAction),
    ),
    onSendStats: (patientProfile, phiSet, importData, standards, passphrase) => dispatch(
      Statistics.actions.sendStatistics(patientProfile, phiSet, importData, standards, passphrase),
    ),
    onSendStatsForClinic: (patientProfile, phiSet, importData, standards, clinicMembership) => dispatch(
      Statistics.actions.sendStatisticsForClinic(patientProfile, phiSet, importData, standards, clinicMembership),
    ),
    onCloseModal           : () => dispatch(App.actions.closeModal()),
    onFormProcessingHealth : () => dispatch(App.actions.startFormProcessing(formNameHealth)),
    onFormProcessingTargets: () => dispatch(App.actions.startFormProcessing(formNameTargets)),
    onSetFormValue         : (input) => dispatch(App.actions.setFormValue(formNameHealth, input)),
    onSetFormValuesHealth  : (values) => dispatch(App.actions.setFormValues(formNameHealth, values)),
    onSetFormValuesTargets : (values) => dispatch(App.actions.setFormValues(formNameTargets, values)),
    onFormErrors           : (errors) => dispatch(App.actions.setFormErrors(formNameHealth, errors)),
    onClearFormHealth      : () => dispatch(App.actions.clearForm(formNameHealth)),
    onClearFormTargets     : () => dispatch(App.actions.clearForm(formNameTargets)),
  };
};


const ConnectedMeasurementsModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MeasurementsModal);

export default withStyles(styles)(ConnectedMeasurementsModal);
