import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import cn from 'classnames';
import chunk from 'lodash/chunk';
import compact from 'lodash/compact';
import find from 'lodash/find';
import map from 'lodash/map';
import { APP_LOCALES, APP_LOCALE_LANGUAGES_MAP } from 'localeConstants';
import Globe from 'svg/globe.svg';
import intlShape from 'shapes/intlShape';
import Button from 'components/Form/Button';
import messages from '../../messages';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';


class LanguageSelector extends React.PureComponent {

  static propTypes = {
    // Explicit props
    hideLabel     : PropTypes.bool,
    className     : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    tooltipOffset : PropTypes.string,
    tooltipPlace  : PropTypes.string,
    activeVisit   : PropTypes.object,
    // Implicit props
    languages     : PropTypes.arrayOf(shapes.language),
    locale        : PropTypes.string,
    langCode      : PropTypes.string,
    openDropdownId: PropTypes.string,
    intl          : intlShape,
    // Implicit actions
    onSetLocale   : PropTypes.func,
    onOpenDropdown: PropTypes.func,
    withTooltip   : PropTypes.bool,
  };


  onToggleDropdown(evt) {
    if (this.props.openDropdownId === constants.LANGUAGE_SELECTOR_DROPDOWN || this.props.activeVisit) {
      return;
    }
    evt.stopPropagation();
    this.props.onOpenDropdown();
  }


  renderLocale(locale) {
    const { languages } = this.props;
    const langCode = APP_LOCALE_LANGUAGES_MAP[locale] || locale;
    const language = find(languages, (lang) => lang.code === langCode);
    if (!language) {
      return null;
    }
    return (
      <li
        key={locale}
        className={
          cn('dropdownMenu__action', {
            'dropdownMenu__action--active': locale === this.props.locale,
          })
        }
      >
        <Button
          type="button"
          styleModifier="transparent"
          onClick={() => this.props.onSetLocale(locale)}
        >
          { language.nativeName }
        </Button>
      </li>
    );
  }


  renderLocaleChunk(localeChunk, idx) {
    return (
      <ul key={idx} className="languageSelector__localesColumn">
        { map(localeChunk, (localeElement) => localeElement) }
      </ul>
    );
  }


  renderLocales() {
    if (this.props.openDropdownId !== constants.LANGUAGE_SELECTOR_DROPDOWN) {
      return null;
    }
    const localesChunks = chunk(compact(map(APP_LOCALES, (locale) => this.renderLocale(locale))), 12);
    return (
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: 'auto' }}
        exit={{ height: 0 }}
        transition={{ ease: 'easeOut', duration: 0.15 }}
        className="languageSelector__locales dropdownMenu__actions"
      >
        <div className="d-flex">
          { map(localesChunks, (localeChunk, idx) => this.renderLocaleChunk(localeChunk, idx)) }
        </div>
      </motion.div>
    );
  }


  render() {
    const { languages, langCode } = this.props;
    const language = find(languages, (lang) => lang.code === langCode);
    if (!language) {
      return null;
    }
    const tooltipMsg = this.props.activeVisit
      ? messages.infos.menuDisabledIfActiveVisit
      : messages.labels.language;
    return (
      <div
        className={cn('languageSelector', this.props.className)}
      >
        <button
          type="button"
          className="languageSelector__btn"
          onClick={(evt) => this.onToggleDropdown(evt)}
        >
          <div className="languageSelector__inner">
            <div>
              <Globe
                className="languageSelector__icon"
                data-for={this.props.withTooltip ? 'sidebar' : ''}
                data-place={this.props.tooltipPlace}
                data-tip={this.props.intl.formatMessage(tooltipMsg)}
                data-offset={this.props.tooltipOffset}
              />
            </div>
            {
              !this.props.hideLabel
                && (
                  <div>
                    <span className="languageSelector__current">
                      { language.nativeName }
                    </span>
                  </div>
                )
            }
          </div>
        </button>
        <AnimatePresence>
          { this.renderLocales() }
        </AnimatePresence>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  languages     : selectors.languages(state),
  locale        : selectors.locale(state),
  langCode      : selectors.langCode(state),
  direction     : selectors.direction(state),
  openDropdownId: selectors.dropdown(state),
});


const mapDispatchToProps = (dispatch) => ({
  onSetLocale: (locale) => Promise.all([
    dispatch(actions.setLocale(locale)),
    dispatch(actions.fetchLocalizationResources(locale)),
    dispatch(actions.closeDropdown()),
  ]),
  onOpenDropdown: () => dispatch(actions.openDropdown(constants.LANGUAGE_SELECTOR_DROPDOWN)),
});


const ConnectedLanguageSelector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(LanguageSelector));


export default ConnectedLanguageSelector;
