import React from 'react';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import { createValidatorRules } from 'helpers/templatedFields';
import templateFieldShape from 'shapes/templateFieldShape';
import DateOfBirthFormPartial from 'components/DateOfBirthFormPartial';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import InputLikeCheckboxRadioGroup from 'components/Form/InputLikeCheckboxRadioGroup';
import InputLikeCheckboxRadio from 'components/Form/InputLikeCheckboxRadio';
import TextArea from 'components/Form/TextArea';
import Select from 'components/Form/Select';


class TemplatedFields extends React.PureComponent {

  static propTypes = {
    template             : PropTypes.arrayOf(templateFieldShape).isRequired,
    disabledFields       : PropTypes.array,
    formValues           : PropTypes.object,
    messages             : PropTypes.object,
    localizationResources: PropTypes.object,
    validatorRules       : PropTypes.object,
    isOneLiner           : PropTypes.bool,
    onAddValidatorRules  : PropTypes.func,
    onSetFormValue       : PropTypes.func,
    onSetFormValues      : PropTypes.func,
  };


  static defaultProps = {
    disabledFields: [],
    isOneLiner    : false,
  };


  componentDidMount() {
    this.onSetSelects();
    this.onAddValidatorRules();
  }


  componentDidUpdate(prevProps) {
    if (
      (this.props.template && !isEqual(prevProps.template, this.props.template))
      || (this.props.formValues && !isEqual(get(prevProps.formValues, 'values'), get(this.props.formValues, 'values')))
    ) {
      this.onSetSelects();
      this.onAddValidatorRules();
    }
  }


  onAddValidatorRules() {
    let validatorRules = {};
    forEach(this.props.template, (fieldTemplate) => {
      validatorRules = { ...validatorRules, ...createValidatorRules(fieldTemplate) };
    });
    this.props.onAddValidatorRules(validatorRules);
  }


  onSetSlaveValidator(value, fieldTemplate, groupTemplate) {
    const { masterFor, options } = fieldTemplate;
    if (isArray(options)) {
      const activeOption = find(options, { value }) || {};
      const { slaveRegExp } = activeOption;
      if (slaveRegExp) {
        const slaveFieldTemplate = find(groupTemplate.fields, { name: masterFor });
        slaveFieldTemplate.regExp = slaveRegExp;
        const validatorRules = createValidatorRules(slaveFieldTemplate, groupTemplate);
        this.props.onAddValidatorRules(validatorRules);
      }
    }
  }


  onSetMasterValue(input, fieldTemplate, groupTemplate) {
    const { value } = input;
    this.onSetSlaveValidator(value, fieldTemplate, groupTemplate);
    this.onSetValue(input);
  }


  onSetValue(input) {
    this.props.onSetFormValue(input);
  }


  onSetSelects(groupTemplate = null, values = {}) {
    const { formValues } = this.props;
    const fieldTemplates = groupTemplate ? groupTemplate.fields : this.props.template;

    forEach(fieldTemplates, (fieldTemplate) => {
      const name = groupTemplate ? `${groupTemplate.name}.${fieldTemplate.name}` : fieldTemplate.name;
      if (isArray(fieldTemplate.options) && fieldTemplate.options.length === 1) {
        const { value } = fieldTemplate.options[0];
        values[name] = value;
        if (fieldTemplate.masterFor) {
          this.onSetSlaveValidator(value, fieldTemplate, groupTemplate);
        }
      } else if (fieldTemplate.masterFor) {
        const value = get(formValues, ['values', name]);
        if (value) {
          this.onSetSlaveValidator(value, fieldTemplate, groupTemplate);
        }
      } else if (fieldTemplate.type === 'group' && isArray(fieldTemplate.fields) && fieldTemplate.fields.length) {
        this.onSetSelects(fieldTemplate, values);
      }
    });

    if (!groupTemplate) {
      this.props.onSetFormValues(values);
    }
  }


  getLabelMessage(labelMessage, name) {
    return labelMessage
      ? get(this.props.localizationResources, [labelMessage, 'value'], labelMessage)
      : get(this.props.messages.labels, name, name);
  }


  getIsFieldDisabled(fieldName) {
    return includes(this.props.disabledFields, fieldName);
  }


  renderTextArea(fieldTemplate) {
    const attributes = fieldTemplate.maxLength ? { maxLength: fieldTemplate.maxLength } : {};
    const labelMessage = this.getLabelMessage(fieldTemplate.labelMessage, fieldTemplate.name);
    return (
      <FormGroup
        key={fieldTemplate.name}
        id={fieldTemplate.name}
        labelMessage={labelMessage}
        formValues={this.props.formValues}
      >
        <TextArea
          placeholder={this.props.messages.placeholders[fieldTemplate.name] || null}
          onChange={this.props.onSetFormValue}
          attributes={attributes}
        />
      </FormGroup>
    );
  }

  renderGender(fieldTemplate) {
    const isDisabled = this.getIsFieldDisabled('gender');

    const options = fieldTemplate.options.map((option) => (
      <InputLikeCheckboxRadio
        key={option.value}
        labelMessage={this.getLabelMessage(option.labelKey, option.name)}
        inputValue={option.value}
        containerClassName="col-6"
        isRadio
        onChange={(input) => this.onSetValue(input)}
        isDisabled={isDisabled}
      />
    ));
    return (
      <FormGroup
        key={fieldTemplate.name}
        id={fieldTemplate.name}
        labelMessage={this.getLabelMessage(fieldTemplate.labelMessage, fieldTemplate.name)}
        formValues={this.props.formValues}
        isOneLiner={this.props.isOneLiner}
      >
        <InputLikeCheckboxRadioGroup>
          {options}
        </InputLikeCheckboxRadioGroup>
      </FormGroup>
    );
  }


  renderControl(fieldTemplate, groupTemplate = null) {
    const placeholderMessage = get(this.props.messages.placeholders, fieldTemplate.name);
    if (fieldTemplate.options) {
      const options = map(fieldTemplate.options, (option) => {
        const label = get(this.props.localizationResources, [option.labelKey, 'value'], option.labelKey);
        return { value: option.value, label };
      });
      return (
        <Select
          optionsFrom={options}
          valueKey="value"
          labelKey="label"
          isDisabled={fieldTemplate.options.length === 1 || this.getIsFieldDisabled(fieldTemplate.name)}
          onChange={(input) => (
            fieldTemplate.masterFor
              ? this.onSetMasterValue(input, fieldTemplate, groupTemplate)
              : this.onSetValue(input)
          )}
        />
      );
    }
    return (
      <Input
        placeholder={placeholderMessage}
        onChange={(input) => this.onSetValue(input)}
        isDisabled={this.getIsFieldDisabled(fieldTemplate.name)}
      />
    );
  }


  renderGroup(fieldTemplate) {
    if (!isArray(fieldTemplate.fields) || !fieldTemplate.fields.length) {
      return null;
    }

    const masterFields = filter(fieldTemplate.fields, (f) => f.masterFor);
    let hiddenFields = 0;
    forEach(masterFields, (mf) => {
      if (isArray(mf.options) && mf.options.length === 1) {
        mf.isHidden = true;
        hiddenFields++;
        const option = mf.options[0];
        const slaveFieldTemplate = find(fieldTemplate.fields, { name: mf.masterFor });
        slaveFieldTemplate.labelMessage = get(
          this.props.localizationResources,
          [option.labelKey, 'value'],
          option.labelKey,
        );
        slaveFieldTemplate.infoMessage
        = get(
            this.props.localizationResources,
            [mf.options[0].instructionsKey, 'value'],
            mf.options[0].instructionsKey,
          );
      }
    });

    const col = this.props.isOneLiner ? 12 : Math.round(12 / (fieldTemplate.fields.length - hiddenFields));
    return (
      <div key={fieldTemplate.name} className="row">
        {
          map(fieldTemplate.fields, (ft) => {
            if (ft.isHidden) {
              return null;
            }
            return <div key={ft.name} className={`col-${col}`}>{this.renderField(ft, fieldTemplate)}</div>;
          })
        }
      </div>
    );
  }


  renderField(fieldTemplate, groupTemplate = null) {
    if (fieldTemplate.name === 'avatar') return null;
    if (fieldTemplate.name === 'gender') {
      return this.renderGender(fieldTemplate);
    }
    if (fieldTemplate.type === 'date') {
      return (
        <DateOfBirthFormPartial
          key={fieldTemplate.name}
          formValues={this.props.formValues}
          errorsMessages={this.props.messages.validatorRules}
          onSetFormValue={(input) => this.onSetValue(input)}
          isDisabled={this.getIsFieldDisabled(fieldTemplate.name)}
          isOneLiner={this.props.isOneLiner}
        />
      );
    }
    if (fieldTemplate.type === 'textArea') {
      return this.renderTextArea(fieldTemplate);
    }
    if (fieldTemplate.type === 'group') {
      return this.renderGroup(fieldTemplate);
    }
    const labelMessage = this.getLabelMessage(fieldTemplate.labelMessage, fieldTemplate.name);
    const infoMessage = this.getLabelMessage(fieldTemplate.infoMessage, null);

    return (
      <FormGroup
        key={fieldTemplate.name}
        id={groupTemplate ? `${groupTemplate.name}.${fieldTemplate.name}` : fieldTemplate.name}
        labelMessage={labelMessage}
        infoMessage={infoMessage}
        validatorRules={this.validatorRules}
        formValues={this.props.formValues}
        isOneLiner={this.props.isOneLiner}
      >
        { this.renderControl(fieldTemplate, groupTemplate) }
      </FormGroup>
    );
  }


  render() {
    return <div>{ map(this.props.template, (fieldTemplate) => this.renderField(fieldTemplate)) }</div>;
  }

}


export default TemplatedFields;
