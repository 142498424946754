import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';


class StaticText extends React.PureComponent {

  static propTypes = {
    // Explicit props
    id   : PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    className: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  };


  render() {
    return (
      <div
        id={this.props.id}
        className={classnames('input-container', this.props.className)}
      >
        <div className="form-control form-control--static-text">{ this.props.value }</div>
      </div>
    );
  }

}

export default StaticText;
