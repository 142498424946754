import PropTypes from 'prop-types';


export const organizationMembership = PropTypes.shape({
  identityUID    : PropTypes.string.isRequired,
  organizationUID: PropTypes.string.isRequired,
  status         : PropTypes.string.isRequired,
  isAdmin        : PropTypes.bool.isRequired,
  businessEmail  : PropTypes.string.isRequired,
  firstName      : PropTypes.string.isRequired,
  lastName       : PropTypes.string.isRequired,
});


export const clinicMembership = PropTypes.shape({
  clinicHcpMembershipId: PropTypes.number.isRequired,
  clinicId             : PropTypes.number.isRequired,
  hcpProfileId         : PropTypes.number.isRequired,
  hcpBusinessEmail     : PropTypes.string.isRequired,
  isAdmin              : PropTypes.bool.isRequired,
  membershipStatus     : PropTypes.oneOf(['Pending', 'Active', 'Rejected', 'Revoked']).isRequired,
  profile              : PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName : PropTypes.string.isRequired,
    avatar   : PropTypes.string,
    publicKey: PropTypes.string.isRequired,
  }),
});
