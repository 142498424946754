import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import Draggable from 'react-draggable';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import XIcon from 'svg/x.svg';
import HandIcon from 'svg/hand.svg';
import App from 'modules/App';
import { getPosition } from './helpers';


class FloatingModal extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const { floatingModal } = props;
    if (floatingModal && !state.position) {
      const position = getPosition(floatingModal);
      return { position };
    }
    if (!floatingModal && state.position) {
      return { position: null };
    }
    return null;
  }


  static propTypes = {
    // Explicit props
    floatingModal        : App.shapes.floatingModal,
    activeFloatingModalId: PropTypes.string,
    styleModifier        : PropTypes.oneOf(['md', 'lg', 'xl']),
    className            : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    headerMessage        : PropTypes.object,
    children             : PropTypes.node,
    isInProgress         : PropTypes.bool,
    isDisabled           : PropTypes.bool,
    isDocked             : PropTypes.bool,
    isFixed              : PropTypes.bool,
    // Explicit actions
    onActivate           : PropTypes.func,
    onClose              : PropTypes.func,
  };


  static defaultProps = {
    isInProgress: false,
    isDisabled  : false,
    isDocked    : false,
    isFixed     : false,
  };


  constructor(props) {
    super(props);
    this.state = {
      position: null,
    };
  }


  onActivate() {
    const { floatingModalId } = this.props.floatingModal || {};
    this.props.onActivate(floatingModalId);
  }


  onClose(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (!this.props.onClose || this.props.isInProgress || this.props.isDisabled) {
      return;
    }
    this.props.onClose();
  }


  get isActive() {
    const { floatingModalId } = this.props.floatingModal || {};
    return floatingModalId === this.props.activeFloatingModalId;
  }


  get isOpen() {
    return this.props.floatingModal;
  }


  renderGrabBtn() {
    if (this.props.isFixed) {
      return null;
    }
    return (
      <button
        type="button"
        className="modal__btn modal__btn--grab"
      >
        <span className="btn-inner">
          <HandIcon className="modal__btn__icon" />
        </span>
      </button>
    );
  }


  renderCloseBtn() {
    if (!this.props.onClose) {
      return null;
    }
    return (
      <div className="modal__controls modal__controls--floating">
        { this.renderGrabBtn() }
        <button
          type="button"
          className="modal__btn"
          onClick={(evt) => this.onClose(evt)}
        >
          <span className="btn-inner">
            <XIcon className="modal__btn__icon" />
          </span>
        </button>
      </div>
    );
  }


  renderHeader() {
    if (!this.props.headerMessage) {
      return null;
    }

    return (
      <div>
        <h2 className="modal__header modal__header--floating">
          <FormattedMessage {...this.props.headerMessage} />
        </h2>
      </div>
    );
  }


  renderContent() {
    return (
      <div className={cn('modal__contentWrapper', { 'mt-5': !this.props.onClose })}>
        <div className="modal__content modal__content--floating">
          { this.renderHeader() }
          { this.props.children }
        </div>
      </div>
    );
  }


  renderModal() {
    if (!this.isOpen) {
      return null;
    }

    return (
      <Draggable
        handle=".modal__btn--grab"
        disabled={this.props.isFixed}
        onStart={() => this.onActivate()}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: 'easeOut', duration: 0.15 }}
          className={cn(
            'modal modal--floating',
            {
              'modal--active'                       : this.isActive,
              'modal--docked'                       : this.props.isDocked,
              [`modal--${this.props.styleModifier}`]: this.props.styleModifier,
            },
            this.props.className,
          )}
          style={this.state.position}
        >
          <div className="modal__body modal__body--floating">
            {this.renderCloseBtn()}
            {this.renderContent()}
          </div>
        </motion.div>
      </Draggable>
    );
  }


  render() {
    return (
      <AnimatePresence>
        { this.renderModal() }
      </AnimatePresence>
    );
  }

}


const mapStateToProps = (state) => ({
  activeFloatingModalId: App.selectors.activeFloatingModalId(state),
});

const mapDispatchToProps = (dispatch) => ({
  onActivate: (floatingModalId) => dispatch(App.actions.activateFloatingModal(floatingModalId)),
});

const ConnectedFloatingModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FloatingModal);


export default ConnectedFloatingModal;
