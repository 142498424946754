// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".aK1rQ .wrapper{border:1px solid #e0e8f2;border-radius:8px}.aK1rQ .movePage,.aK1rQ .wrapper{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}.aK1rQ .movePage{width:40px;height:40px;cursor:pointer;background:none;border:none}.aK1rQ .movePage:disabled{cursor:not-allowed}.aK1rQ .movePage svg{height:16px;fill:#30a8ff}.aK1rQ .movePage:disabled svg{fill:#c9cfdc}html[dir=ltr] .aK1rQ .movePage--previous{border-right:1px solid #e0e8f2;margin-right:5px}html[dir=rtl] .aK1rQ .movePage--previous{border-left:1px solid #e0e8f2;margin-left:5px}html[dir=ltr] .aK1rQ .movePage--previous>svg{-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);transform:rotate(180deg)}html[dir=ltr] .aK1rQ .movePage--next{border-left:1px solid #e0e8f2;margin-left:5px}html[dir=rtl] .aK1rQ .movePage--next{border-right:1px solid #e0e8f2;margin-right:5px}html[dir=rtl] .aK1rQ .movePage--next>svg{-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);transform:rotate(180deg)}.aK1rQ .page{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;margin:8px;min-width:24px;min-height:24px;cursor:pointer;background:none;border:none;color:#6f7ea2;border-radius:4px;font-weight:500}.aK1rQ .page--active{color:#30a8ff;background:#f5f9ff}", ""]);
// Exports
exports.locals = {
	"pages": "aK1rQ"
};
module.exports = exports;
