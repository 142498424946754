import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import toUpper from 'lodash/toUpper';
import FormContainerAbstract from 'components/FormContainerAbstract';
import Modal from 'components/Modal';
import App from 'modules/App';
import Form from 'components/Form';
import Button from 'components/Form/Button';
import Input from 'components/Form/Input';
import FormGroup from 'components/Form/FormGroup';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from '../ModalCommon.pcss';
import validatorRules from './validatorRules.json';


class EnrollInClinicModal extends FormContainerAbstract {

  static getDerivedStateFromProps(props, state) {
    const { isInProgress, hasErrors } = props;
    if (isInProgress !== state.isInProgress) {
      return {
        isInProgress,
        hasErrors: hasErrors && state.isInProgress,
        isSucceed: !isInProgress && !hasErrors,
      };
    }
    return null;
  }


  static propTypes = {
    ...FormContainerAbstract.propTypes,
    // Implicit props
    openModalId : PropTypes.string,
    // Implicit actions
    onCloseModal: PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.validatorRules = validatorRules;
    this.state = {
      isInProgress: false,
      isSucceed   : false,
      hasErrors   : false,
    };
  }


  onSetValue(input) {
    input.value = toUpper(input.value);
    this.props.onSetFormValue(input);
  }


  async onClose() {
    await this.props.onCloseModal();
    this.props.onClearForm();
    this.setState({
      isInProgress: false,
      isSucceed   : false,
      hasErrors   : false,
    });
  }


  renderActions() {
    return (
      <div className="modal__actions row">
        <div className="col-6">
          <Button
            styleModifier="quaternary"
            labelMessage={messages.buttons.close}
            className="btn--block"
            isDisabled={this.props.isInProgress}
            onClick={() => this.onClose()}
          />
        </div>
        <div className="col-6">
          <Button
            type="submit"
            styleModifier="primary"
            labelMessage={messages.buttons.submit}
            className="btn--block btn--filled"
            isDisabled={this.state.hasErrors}
            isInProgress={this.props.isInProgress}
          />
        </div>
      </div>
    );
  }


  renderForm() {
    return (
      <Form onSubmit={() => this.onSubmit()}>
        <FormGroup
          id="enrollCode"
          labelMessage={messages.labels.enrollCode}
          formValues={this.props.formValues}
        >
          <Input
            placeholder={messages.placeholders.enrollCode}
            attributes={{ autoFocus: true }}
            isDisabled={this.state.hasErrors}
            onChange={(input) => this.onSetValue(input)}
          />
        </FormGroup>
        {this.renderActions()}
      </Form>
    );
  }


  renderInfo() {
    const message = this.state.isSucceed
      ? messages.infos.waitForRequestAccepted
      : messages.infos.provideEnrollCode;
    return (
      <div className="modal__info mb-5">
        <FormattedMessage {...message} />
      </div>
    );
  }


  renderContent() {
    if (this.state.isSucceed) {
      return (
        <Button
          styleModifier="primary"
          labelMessage={messages.buttons.close}
          className="btn--block btn--filled"
          onClick={() => this.onClose()}
        />
      );
    }
    return this.renderForm();
  }


  render() {
    const headerMessage = this.state.isSucceed
      ? messages.headers.waitForRequestAccepted
      : messages.headers.enrollInClinic;
    return (
      <Modal
        modalId={constants.ENROLL_IN_CLINIC_MODAL}
        headerMessage={headerMessage}
        openModalId={this.props.openModalId}
        styleModifier="md"
        isInProgress={this.state.isInProgress}
        onClose={() => this.onClose()}
      >
        <div className={styles.container}>
          {this.renderInfo()}
          {this.renderContent()}
        </div>
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  isInProgress: selectors.isEnrollInClinicInProgress(state),
  hasErrors   : selectors.hasEnrollInClinicErrors(state),
  formValues  : App.selectors.formSelector(constants.ENROLL_IN_CLINIC_FORM)(state),
  openModalId : App.selectors.modal(state),
});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.ENROLL_IN_CLINIC_FORM;
  return {
    onSubmit        : ({ enrollCode }) => dispatch(actions.enrollInClinic(enrollCode)),
    onSetFormValue  : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onFormProcessing: () => dispatch(App.actions.startFormProcessing(formName)),
    onFormErrors    : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onClearForm     : () => dispatch(App.actions.clearForm(formName)),
    onCloseModal    : () => dispatch(App.actions.closeModal()),
  };
};


const ConnectedEnrollInClinicModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnrollInClinicModal);


export default withStyles(styles)(ConnectedEnrollInClinicModal);
