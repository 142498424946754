import PropTypes from 'prop-types';


export default PropTypes.shape({
  id                : PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  firstName         : PropTypes.string,
  lastName          : PropTypes.string,
  email             : PropTypes.string,
  country           : PropTypes.string,
  encryptedPhiSetKey: PropTypes.string,
  gender            : PropTypes.string,
  dateOfBirth       : PropTypes.string,
  createTimestamp   : PropTypes.number,
});
