import React from 'react';
import PropTypes from 'prop-types';
import Fasting from 'svg/fasting.svg';
import PreMeal from 'svg/pre-meal.svg';
import PostMeal from 'svg/post-meal.svg';
import NoFlag from 'svg/no-flag.svg';
import Total from 'svg/total.svg';


class ReadingFlagIcon extends React.PureComponent {

  static propTypes = {
    flag     : PropTypes.oneOf(['All', 'Fasting', 'PreMeal', 'PostMeal', 'Logbook', 'None']),
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    width    : PropTypes.string,
    height   : PropTypes.string,
  };


  getIcon(flag) {
    switch (flag) {
      case 'All': return Total;
      case 'Fasting': return Fasting;
      case 'PreMeal': return PreMeal;
      case 'PostMeal': return PostMeal;
      case 'None': return NoFlag;
      default: return null;
    }
  }


  render() {
    const { flag, className, width, height } = this.props;
    const Icon = this.getIcon(flag);
    return Icon && (
      <Icon
        width={width}
        height={height}
        className={className}
      />
    );
  }

}

export default ReadingFlagIcon;
