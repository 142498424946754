import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider, ReactIntlErrorCode } from 'react-intl';
import { DEFAULT_LOCALE } from 'localeConstants';
import * as selectors from '../../selectors';


class LanguageProvider extends React.PureComponent {

  static propTypes = {
    // Implicit props
    children    : PropTypes.element.isRequired,
    langCode    : PropTypes.string.isRequired,
    translations: PropTypes.object.isRequired,
  };


  onError(err) {
    if (err.code === ReactIntlErrorCode.MISSING_DATA) {
      return;
    }
    console.error(err);
  }


  render() {
    const { langCode, translations } = this.props;
    return (
      <IntlProvider
        locale={langCode}
        defaultLocale={DEFAULT_LOCALE}
        messages={translations}
        onError={(err) => this.onError(err)}
      >
        { React.Children.only(this.props.children) }
      </IntlProvider>
    );
  }

}


const mapStateToProps = (state) => ({
  langCode    : selectors.langCode(state),
  translations: selectors.translations(state),
});


export default connect(
  mapStateToProps,
)(LanguageProvider);
