import { call } from 'redux-saga/effects';
import { ApiError } from 'helpers/errorTypes';
import ApiService from 'services/ApiService';


function* getJob(jobId, controlId) {
  const requestUrl = `/api/JobTracker/${jobId}/${controlId}`;
  try {
    return yield call(ApiService.regionalRequest, requestUrl);
  } catch (err) {
    if (err.status === 404) {
      return null;
    }
    throw new ApiError(err);
  }
}


function* trackJob(jobId, controlId, payload) {
  const requestUrl = '/api/JobTracker';
  return yield call(ApiService.regionalRequest, requestUrl, {
    method: 'POST',
    body  : {
      jobId,
      controlId,
      payload,
    },
  });
}


function* deleteJob(jobId, controlId) {
  const requestUrl = '/api/JobTracker';
  return yield call(ApiService.regionalRequest, requestUrl, {
    method: 'DELETE',
    body  : {
      jobId,
      controlId,
    },
  });
}


export default {
  getJob,
  trackJob,
  deleteJob,
};
