import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import find from 'lodash/find';
import isObjectLike from 'lodash/isObjectLike';
import intlShape from 'shapes/intlShape';
import Input from './Input';


class InputLikeCheckboxRadio extends React.PureComponent {

  static propTypes = {
    // Explicit props
    id          : PropTypes.string,
    inputValue  : PropTypes.string.isRequired,
    value       : PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    labelMessage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    infoMessage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    isRadio   : PropTypes.bool,
    isDisabled: PropTypes.bool,
    className : PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    renderImage: PropTypes.func,
    // Implicit props
    intl       : intlShape.isRequired,
    // Explicit Actions
    onChange   : PropTypes.func.isRequired,
    onBlur     : PropTypes.func,
  };


  static defaultProps = {
    id        : '',
    value     : '',
    isRadio   : false,
    isDisabled: false,
    className : '',
    onBlur    : () => {},
  };


  onChange(input) {
    if (this.props.isRadio) {
      return this.props.onChange({
        id   : this.props.id,
        value: input.value,
      });
    }
    let value = this.props.value ? this.props.value : [];
    if (input.value) {
      value = [...value, input.value];
    } else {
      value = value.filter((v) => v !== this.props.inputValue);
    }

    return this.props.onChange({
      id: this.props.id,
      value,
    });
  }


  get value() {
    if (this.props.isRadio) {
      return this.props.value;
    }
    return find(this.props.value, (i) => i === this.props.inputValue.toString());
  }


  get isChecked() {
    return this.value === this.props.inputValue;
  }


  renderInfo() {
    if (!this.props.infoMessage) {
      return null;
    }
    return (
      <p className="form-info">
        {
          isObjectLike(this.props.infoMessage)
            ? this.props.intl.formatMessage(this.props.infoMessage, { ...this.props.infoMessage.values })
            : this.props.infoMessage
        }
      </p>
    );
  }


  render() {
    const attributes = {
      checked: this.isChecked,
    };

    const id = `${this.props.id}-${this.props.inputValue}`;

    return (
      <>
        <label htmlFor={id} className={cn('form-control', this.props.className, { disabled: this.props.isDisabled })}>
          {this.props.renderImage && this.props.renderImage()}
          <div className="row h-100 align-items-center">
            <div className="col">
              {
                isObjectLike(this.props.labelMessage)
                  ? this.props.intl.formatMessage(this.props.labelMessage, { ...this.props.labelMessage.values })
                  : this.props.labelMessage
              }
            </div>
            <div className="col-auto">
              <div
                className={cn({
                  'uniform-checker': !this.props.isRadio,
                  'uniform-choice' : this.props.isRadio,
                  disabled         : this.props.isDisabled,
                })}
              >
                <div className={cn('form-input__hover', { disabled: this.props.isDisabled })} />
                <span className={cn({ checked: this.isChecked })}>
                  <Input
                    id={id}
                    type={this.props.isRadio ? 'radio' : 'checkbox'}
                    value={this.props.inputValue}
                    formControl={false}
                    attributes={attributes}
                    onChange={(input) => this.onChange(input)}
                    onBlur={this.props.onBlur}
                    isDisabled={this.props.isDisabled}
                  />
                </span>
              </div>
            </div>
          </div>
        </label>
        {this.renderInfo()}
      </>
    );
  }

}

export default injectIntl(InputLikeCheckboxRadio);
