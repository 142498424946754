import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import Button from 'components/Form/Button';
import Envelope from 'svg/envelope.svg';
import Home from 'svg/home.svg';
import { CONTACT_EMAIL } from '../../../constants';
import styles from './Error.pcss';


class Error extends React.PureComponent {

  static propTypes = {
    // Explicit props
    code       : PropTypes.number,
    codeLabel  : PropTypes.string,
    title      : PropTypes.string,
    info       : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    buttonLabel: PropTypes.string,
  };


  renderSupportButton(code) {
    if (code === 404) {
      return null;
    }
    return (
      <a
        href={`mailto:${CONTACT_EMAIL}`}
        type="link"
        className="btn btn--quaternary btn--filled text--primary"
      >
        <span className="btn-inner">
          <div className="btn__labelWrapper">
            <Envelope className="btn__icon mr-2" />
            <span>{CONTACT_EMAIL}</span>
          </div>
        </span>
      </a>
    );
  }


  render() {
    const { code, codeLabel, title, info, buttonLabel } = this.props;
    return (
      <div className={`${styles.error} ${styles[`error--${code}`] || ''}`}>
        <div className={styles.errorInfo}>
          <div className={styles.errorInfo__inner}>
            <p className={styles.errorInfo__errorCode}>{codeLabel}: {code}</p>
            <h1 className={styles.errorInfo__errorTitle}>{title}</h1>
            <div className="text--normal text--light mb-5">{info}</div>
            <Button
              type="link"
              to="/"
              styleModifier="quaternary"
              className="btn--filled text--primary mr-4"
            >
              <Home className="btn__icon mr-2" />
              <span>{buttonLabel}</span>
            </Button>
            {this.renderSupportButton(code)}
          </div>
        </div>
      </div>
    );
  }

}

export default withStyles(styles)(Error);
