import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { AppContext } from 'context';
import Link from 'components/Link';
import App from 'modules/App';
import Notifications from 'modules/Notifications';
import Bell from 'svg/bell.svg';
import styles from './Notifications.pcss';


class TopBarNotifications extends React.Component {

  static contextType = AppContext;

  static propTypes = {
    // Implicit props
    openDropdownId    : PropTypes.string,
    notificationsCount: PropTypes.number,
    // Implicit actions
    onOpenDropdown    : PropTypes.func,
    onCloseDropdown   : PropTypes.func,
  };


  onToggleNotifications(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.isNotificationsOpen) {
      this.props.onCloseDropdown();
      return;
    }
    this.props.onOpenDropdown(Notifications.constants.NOTIFICATIONS_DROPDOWN);
  }


  get isNotificationsOpen() {
    return this.props.openDropdownId === Notifications.constants.NOTIFICATIONS_DROPDOWN;
  }

  renderNewNotificationsBadge() {
    let count = this.props.notificationsCount;
    if (count === 0) {
      count = '';
    } else if (count > 9) {
      count = '9+';
    }
    return (
      <span className={cn(styles.actions__notifications__badge, {
        [styles['actions__notifications__badge--inactive']]: this.props.notificationsCount === 0,
      })}
      >
        {count}
      </span>
    );
  }

  render() {
    return (
      <div className={styles.notifications}>
        <Link
          to=""
          className={styles.notifications__content}
          onClick={(evt) => this.onToggleNotifications(evt)}
          data-for="userBoxTooltip"
        >
          <Bell />
          { this.renderNewNotificationsBadge() }
        </Link>
        <Notifications.partials.NotificationsPopover openDropdownId={this.props.openDropdownId} />
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  openDropdownId    : App.selectors.dropdown(state),
  notificationsCount: Notifications.selectors.activeInNewestNotificationsCount(state),
});


const mapDispatchToProps = (dispatch) => ({
  onOpenDropdown : (dropdownId) => dispatch(App.actions.openDropdown(dropdownId)),
  onCloseDropdown: () => dispatch(App.actions.closeDropdown()),
});


const ConnectedTopBarNotifications = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopBarNotifications);


export default withStyles(styles)(ConnectedTopBarNotifications);
