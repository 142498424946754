import { merge } from 'lodash';


export const MODULE_ID = 'layout';

export const SIDEBAR_DROPDOWN = `${MODULE_ID}/sidebarDropdown`;
export const TOPBAR_AVATAR_DROPDOWN = `${MODULE_ID}/topbarAvatarDropdown`;

export const TOPBAR_SEARCH_FORM = `${MODULE_ID}/topbarSearchForm`;

export const CELL_TYPES = {
  NORMAL  : 'NORMAL',
  EXPANDED: 'EXPANDED',
};

export const VARIANTS_TYPE = {
  CLOSED: 'closed',
  OPEN  : 'open',
};

export const BORDER_VARIANTS = {
  [VARIANTS_TYPE.CLOSED]: { borderTopRightRadius: '16px', borderBottomRightRadius: '16px' },
  [VARIANTS_TYPE.OPEN]  : { borderTopRightRadius: '0px', borderBottomRightRadius: '0px' },
};

export const BORDER_VARIANTS_RTL = {
  [VARIANTS_TYPE.CLOSED]: { borderTopLeftRadius: '16px', borderBottomLeftRadius: '16px' },
  [VARIANTS_TYPE.OPEN]  : { borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' },
};

export const MENU_EXPANDED_VARIANTS = {
  [VARIANTS_TYPE.CLOSED]: { x: '-0px' },
  [VARIANTS_TYPE.OPEN]  : { x: '-0px' },
};

export const MENU_EXPANDED_VARIANTS_RTL = {
  [VARIANTS_TYPE.CLOSED]: { x: '-0px' },
  [VARIANTS_TYPE.OPEN]  : { x: '-0px' },
};

export const MENU_SELECTED_VARIANTS = {
  [VARIANTS_TYPE.CLOSED]: { x: '-251px' },
  [VARIANTS_TYPE.OPEN]  : { x: '0px' },
};

export const MENU_SELECTED_VARIANTS_RTL = {
  [VARIANTS_TYPE.CLOSED]: { x: '251px' },
  [VARIANTS_TYPE.OPEN]  : { x: '0px' },
};

export const ANIMATION_VARIANTS_CHEVRON = {
  ltr: {
    [VARIANTS_TYPE.CLOSED]: { rotate: 0 },
    [VARIANTS_TYPE.OPEN]  : { rotate: 180 },
  },
  rtl: {
    [VARIANTS_TYPE.CLOSED]: { rotate: 180 },
    [VARIANTS_TYPE.OPEN]  : { rotate: 0 },
  },
};

export const ANIMATION_PENDING_SHARING_REQUEST = {
  [VARIANTS_TYPE.CLOSED]: { height: '32px' },
  [VARIANTS_TYPE.OPEN]  : { height: '80px' },
};

export const ANIMATION_VARIANTS = {
  ltr: {
    SHRINKED: BORDER_VARIANTS,
    EXPANDED: merge({}, BORDER_VARIANTS, MENU_EXPANDED_VARIANTS),
    SELECTED: MENU_SELECTED_VARIANTS,
  },
  rtl: {
    SHRINKED: BORDER_VARIANTS_RTL,
    EXPANDED: merge({}, BORDER_VARIANTS_RTL, MENU_EXPANDED_VARIANTS_RTL),
    SELECTED: MENU_SELECTED_VARIANTS_RTL,
  },
};


export const HCP_MENU_SELECTED_OPTIONS = {
  PATIENTS     : 'patients',
  OTHER_CLINICS: 'other-clinics',
};

export const PWD_MENU_SELECTED_OPTIONS = {
  MY_FAMILY             : 'my-family',
  OTHER_SHARING_REQUESTS: 'other-sharing-requests',
};

export const PINNED_MENU_BREAKPOINT = 1680;
export const PINNED_MENU_NOTES_BREAKPOINT = PINNED_MENU_BREAKPOINT + 400;

export const REPORT_TYPES = [
  'basicPrint',
  'bloodGlucoseConcentrationPrint',
  'dailyLogPrint',
  'agpBgmPrint',
  'patientsBgDataReportPrint',
  'patientsGestationalReportPrint',
];
