import { call } from 'redux-saga/effects';
import ApiService from 'services/ApiService';


function* fetchAccountPublicKey(accountId) {
  const requestUrl = `/api/Security/UserKey/${accountId}`;
  const key = yield call(ApiService.regionalRequest, requestUrl);
  return key.publicKey;
}


function* fetchPatientProfilePublicKey(patientProfileId) {
  const requestUrl = `/api/Security/PatientProfileKey/${patientProfileId}`;
  const key = yield call(ApiService.regionalRequest, requestUrl);
  return key.publicKey;
}


function* fetchCgProfilePublicKey(cgProfileId) {
  const requestUrl = `/api/Security/CgProfileKey/${cgProfileId}`;
  const key = yield call(ApiService.regionalRequest, requestUrl);
  return key.publicKey;
}


export default {
  fetchAccountPublicKey,
  fetchPatientProfilePublicKey,
  fetchCgProfilePublicKey,
};
