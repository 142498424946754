import { createSelector } from 'reselect';
import includes from 'lodash/includes';
import * as actionTypes from './actionTypes';
import * as constants from './constants';


/**
 * Input selectors
 */
const mainSelector = (state) => state[constants.MODULE_ID];
const fetchingSelector = (state) => state[constants.MODULE_ID].fetching;
const sendingSelector = (state) => state[constants.MODULE_ID].sending;
const errorsSelector = (state) => state[constants.MODULE_ID].errors;


/**
 * Id of the last patient for whom synchronization succeed
 */
export const syncPatientId = createSelector(
  mainSelector,
  (state) => state.syncPatientId,
);


/**
 * Health data of the synchronized patient
 */
export const syncHealthData = createSelector(
  mainSelector,
  (state) => state.syncHealthData,
);


/**
 * Selects Cloud Drive authorization code to fetch exchange token
 */
export const authorizationCode = createSelector(
  mainSelector,
  (state) => state.authorizationCode,
);

/**
 * Selects profile photo from Cloud Drive provider
 */
export const profile = createSelector(
  mainSelector,
  (state) => state.profile,
);

//----------------------------------------------------------------------------------------------------------------------

export const isFetchPhiSetInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_PHI_SET),
);

export const hasFetchPhiSetErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.FETCH_PHI_SET),
);

//----------------------------------------------------------------------------------------------------------------------

export const isFetchReadingsInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_READINGS),
);

export const hasFetchReadingsErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.FETCH_READINGS),
);

//----------------------------------------------------------------------------------------------------------------------

export const isStoreReadingsInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.STORE_READINGS) || includes(state, actionTypes.STORE_CGM_READINGS),
);

export const hasStoreReadingsErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.STORE_READINGS) || includes(state, actionTypes.STORE_CGM_READINGS),
);

//----------------------------------------------------------------------------------------------------------------------

export const isStoreMeasurementsInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.STORE_MEASUREMENTS),
);

export const hasStoreMeasurementsErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.STORE_MEASUREMENTS),
);

//----------------------------------------------------------------------------------------------------------------------

export const isStoreNotesInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.STORE_NOTES),
);

//----------------------------------------------------------------------------------------------------------------------

export const isRemoveNoteInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.REMOVE_NOTE),
);

//----------------------------------------------------------------------------------------------------------------------

export const isSendNotesInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.STORE_NOTES) || includes(state, actionTypes.REMOVE_NOTE),
);

//----------------------------------------------------------------------------------------------------------------------

export const isSyncInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.SYNC),
);

export const hasSyncErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.SYNC),
);

//----------------------------------------------------------------------------------------------------------------------

export const isFetchDumpInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_DUMP),
);

export const hasFetchDumpErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.FETCH_DUMP),
);
