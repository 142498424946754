export const MODULE_ID = 'statistics';

export const KPI_TYPES = {
  READINGS        : 'READINGS',
  AVG_READINGS    : 'AVG_READINGS',
  DAYS_WO_READINGS: 'DAYS_WO_READINGS',
  AVG             : 'AVG',
  AVG_PRE_MEAL    : 'AVG_PRE_MEAL',
  AVG_POST_MEAL   : 'AVG_POST_MEAL',
  GMI             : 'GMI',
  STD_DEV         : 'STD_DEV',
  CV              : 'CV',
};

export const DAYS_RANGE = [3, 7, 14, 30, 90];
