import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './SectionTitle.pcss';


class SectionTitle extends React.Component {

  static propTypes = {
    children: PropTypes.node,
  }


  render() {
    const { children } = this.props;
    return (
      <h2 className={styles.sectionTitle}>
        {children}
      </h2>
    );
  }

}

export default withStyles(styles)(SectionTitle);
