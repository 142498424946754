import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cn from 'classnames';
import range from 'lodash/range';
import Account from 'modules/Account';
import ClinicManagement from 'modules/ClinicManagement';
import { AppContext } from 'context';
import Avatar from 'components/Avatar';
import Button from 'components/Form/Button';
import messages from '../../../messages';
import layoutStyles from '../MainLayout.pcss';
import styles from './ClinicSetLicence.pcss';


class ClinicSetLicence extends React.PureComponent {

  static contextType = AppContext;


  static getDerivedStateFromProps(props, state) {
    // eslint-disable-next-line react/prop-types
    const { isInProgress, hasErrors } = props;
    if (isInProgress !== state.isInProgress && (isInProgress || hasErrors)) {
      return {
        isInProgress,
      };
    }
    return null;
  }


  static propTypes = {
    // Explicit props
    activeClinicMembership: Account.shapes.clinicMembership,
    // Implicit props
    onSetClinicLicence    : PropTypes.func,
  };


  constructor(props) {
    super(props);
    const licenceKeyChars = new Array(ClinicManagement.constants.LICENCE_LENGTH).map(() => '');
    this.state = {
      isInProgress: false,
      licenceKeyChars,
    };
    this.inputs = new Array(ClinicManagement.constants.LICENCE_LENGTH);
  }


  componentDidMount() {
    if (this.inputs[0]) {
      this.inputs[0].focus();
    }
  }


  onSetLicenceKeyValue(value, number, remove) {
    const { licenceKeyChars } = this.state;
    const newLicenceKeyChars = JSON.parse(JSON.stringify(licenceKeyChars));
    newLicenceKeyChars[number] = value.replace(licenceKeyChars[number], '');
    this.setState({ licenceKeyChars: newLicenceKeyChars });
    if (number < ClinicManagement.constants.LICENCE_LENGTH - 1 && !remove) {
      this.inputs[number + 1].focus();
    }
    if (number > 0 && remove) {
      this.inputs[number - 1].focus();
    }
  }


  onPaste(evt) {
    evt.preventDefault();
    evt.clipboardData.items[0].getAsString((text) => {
      const licenceKeyChars = text.replaceAll('-', '').split('');

      this.setState({
        licenceKeyChars,
      });
    });
  }


  onKeyDown(evt, number) {
    switch (evt.keyCode) {
      case 8: // backspace
      case 46: // delete
        evt.preventDefault();
        this.onSetLicenceKeyValue('', number, true);
        break;
      case 37: // left arrow
        if (number > 0) {
          this.inputs[number - 1].focus();
        }
        break;
      case 39: // right arrow
        if (number < ClinicManagement.constants.LICENCE_LENGTH - 1) {
          this.inputs[number + 1].focus();
        }
        break;
      case 13: // enter
        this.onSubmit();
        break;

      default:
        break;
    }
  }


  onSetInputRef(el, i) {
    this.inputs[i] = el;
  }


  onSubmit() {
    const { onSetClinicLicence } = this.props;
    const { licenceKeyChars, isInProgress } = this.state;
    if (!this.isDisabled && !isInProgress) {
      const licenceKey = licenceKeyChars.map((character, index) => {
        if (index % ClinicManagement.constants.LICENCE_PART_LENGTH === 0 && index !== 0) {
          return `-${character}`;
        }
        return character;
      });
      onSetClinicLicence(this.clinic.clinicId, licenceKey.join(''));
    }
  }


  get isDisabled() {
    const { licenceKeyChars } = this.state;

    return (licenceKeyChars.join('').trim().length !== ClinicManagement.constants.LICENCE_LENGTH);
  }


  get clinic() {
    const { clinic } = this.props.activeClinicMembership;
    return clinic;
  }


  renderLogo(clinic) {
    const { logo, name } = clinic;

    return (
      <div className={styles.avatar}>
        <Avatar
          avatarImg={logo}
          name={name}
          className={styles.avatar__imgWrapper}
          imgClassName={styles.avatar__img}
          initialsClassName={styles.avatar__initials}
        />
      </div>
    );
  }


  renderInputs() {
    const { licenceKeyChars } = this.state;
    return (
      <div className={styles.info} onPaste={(evt) => this.onPaste(evt)}>
        {
          range(ClinicManagement.constants.LICENCE_LENGTH).map((number) => {
            const input = (
              <div className="input-container" key={`input-container-licence-${number}`}>
                <input
                  className={cn('form-control', styles.numberInput)}
                  value={licenceKeyChars[number]}
                  onChange={(evt) => this.onSetLicenceKeyValue(evt.target.value, number)}
                  onKeyDown={(evt) => this.onKeyDown(evt, number)}
                  ref={(el) => this.onSetInputRef(el, number)}
                />
              </div>
            );

            if (number % ClinicManagement.constants.LICENCE_PART_LENGTH === 0 && number !== 0) {
              return (
                <div
                  className="d-flex align-items-center justify-content-center"
                  key={`input-container-licence-${number}-with-separator`}
                >
                  -
                  { input }
                </div>
              );
            }
            return input;
          })
        }
      </div>
    );
  }


  renderAction() {
    return (
      <Button
        styleModifier="primary"
        className={cn('btn--filled', styles.licenceButton)}
        isInProgress={this.state.isInProgress}
        isDisabled={this.isDisabled}
        labelMessage={messages.buttons.setLicenceKey}
        onClick={() => this.onSubmit()}
      />
    );
  }


  renderContent() {
    const { activeClinicMembership } = this.props;

    if (activeClinicMembership.isAdmin) {
      return (
        <>
          <p><FormattedMessage {...messages.infos.clinicLicence} /></p>
          { this.renderInputs() }
          { this.renderAction() }
        </>
      );
    }

    return <p><FormattedMessage {...messages.infos.clinicLicenceNotAdmin} /></p>;
  }


  render() {
    return (
      <div className={layoutStyles.container}>
        <div className={layoutStyles.containerInner}>
          <div className="content">
            <div className={styles.root}>
              <div className={styles.header}>
                { this.renderLogo(this.clinic) }
                <p className={styles.clinicName}>{ this.clinic.name }</p>
              </div>
              { this.renderContent() }
            </div>
          </div>
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  isInProgress: ClinicManagement.selectors.isSetClinicLicenceInProgress(state),
  hasErrors   : ClinicManagement.selectors.hasSetClinicLicenceErrors(state),
});


const mapDispatchToProps = (dispatch) => ({
  onSetClinicLicence: (clinicId, licenceKey) => dispatch(
    ClinicManagement.actions.setClinicLicence(clinicId, licenceKey),
  ),
});


const ConnectedClinicSetLicence = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClinicSetLicence);


export default withStyles(styles)(ConnectedClinicSetLicence);
