import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import isNumber from 'lodash/isNumber';
import toNumber from 'lodash/toNumber';
import isObject from 'lodash/isObject';
import merge from 'lodash/merge';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import MetricConversions from 'libs/MetricConversions';
import countrySettingsShape from 'shapes/countrySettingsShape';
import Form from 'components/Form';
import Button from 'components/Form/Button';
import App from 'modules/App';
import Account from 'modules/Account';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import GlucoseLevel from './GlucoseLevel';
import styles from './GlucoseSettings.pcss';


class GlucoseSettings extends React.PureComponent {

  static propTypes = {
    // Explicit props
    clinicId                    : PropTypes.number,
    // Implicit props
    activeClinicMembership      : Account.shapes.clinicMembership,
    isUpdateInProgress          : PropTypes.bool,
    hasUpdateErrors             : PropTypes.bool,
    hasElevatedPermissions      : PropTypes.bool,
    elevatedPermissionsMode     : PropTypes.string,
    metricsUnits                : PropTypes.object, // @TODO: Shape
    countrySettings             : countrySettingsShape,
    formValues                  : PropTypes.object,
    featureToggles              : PropTypes.array,
    // Implicit actions
    onFetchClinicMemberships    : PropTypes.func,
    onFetchHcpMemberships       : PropTypes.func,
    onSetElevatedPermissionsMode: PropTypes.func,
    onFetchCountrySettings      : PropTypes.func,
    onSetFormValues             : PropTypes.func,
    onClearForm                 : PropTypes.func,
    onCommitForm                : PropTypes.func,
    onResetForm                 : PropTypes.func,
    onSetFormValue              : PropTypes.func,
    onFormProcessing            : PropTypes.func,
    onSubmit                    : PropTypes.func,
  };


  constructor(props) {
    super(props);
    const metricConversions = new MetricConversions(props.metricsUnits);
    this.conversion = metricConversions.bloodGlucoseConcentration;
    if (!props.countrySettings) {
      props.onFetchCountrySettings();
    }
  }


  componentDidMount() {
    this.onPopulate();
  }


  componentDidUpdate(prevProps) {
    const { activeClinicMembership, isUpdateInProgress, hasUpdateErrors } = this.props;

    if (get(prevProps, 'activeClinicMembership.clinic') !== get(activeClinicMembership, 'clinic')) {
      this.onPopulate();
    }

    if (prevProps.isUpdateInProgress !== isUpdateInProgress && !isUpdateInProgress && !hasUpdateErrors) {
      this.props.onCommitForm();
      this.props.onFetchHcpMemberships();
      this.props.onFetchClinicMemberships();
    }

    if (
      prevProps.elevatedPermissionsMode !== this.props.elevatedPermissionsMode
        && !this.props.elevatedPermissionsMode
    ) {
      this.onPopulate();
    }
  }

  componentWillUnmount() {
    this.props.onClearForm();
  }


  onPopulate() {
    const { activeClinicMembership } = this.props;
    const settings = get(activeClinicMembership, 'clinic.settings');
    if (settings) {
      // Todo: connect glucose targets widget to BE after new endpoints implementation
      const { unit } = this.conversion;
      const precision = get(App.constants.GLUCOSE_LEVELS_INPUTS_PRECISION, unit, 0);
      const convertedSettings = mapValues(settings,
        (value) => (isObject(value) ? value : this.conversion.toDisplay(value, precision)));

      if (convertedSettings && convertedSettings.glucoseRanges) {
        Object.keys(convertedSettings.glucoseRanges).forEach((glucoseType) => {
          const typeRanges = convertedSettings.glucoseRanges[glucoseType];
          if (typeRanges) {
            Object.keys(typeRanges).forEach((glucoseRange) => {
              if (typeRanges[glucoseRange]) {
                Object.keys(typeRanges[glucoseRange]).forEach((rangeLevel) => {
                  convertedSettings[`range-${glucoseType}-${glucoseRange}-${rangeLevel}`] = this.conversion
                    .toDisplay(typeRanges[glucoseRange][rangeLevel], precision);
                });
              }
            });
          }
        });
      }

      this.props.onSetFormValues(convertedSettings);
      this.props.onSetElevatedPermissionsMode(null);
    }
  }


  onSetValue(input) {
    const { id, value } = input;
    const { unit } = this.conversion;
    const precision = get(App.constants.GLUCOSE_LEVELS_INPUTS_PRECISION, unit, 0);
    const roundedValue = isNumber(value) ? toNumber(value.toFixed(precision)) : value;
    this.props.onSetFormValue({ id, value: roundedValue });
  }


  onSubmit() {
    this.props.onFormProcessing();
    const preparedValues = this.prepareValuesForSubmit();

    this.props.onSubmit(preparedValues);
  }


  get isClean() {
    return !get(this.props.formValues, 'originalValues');
  }


  prepareValuesForSubmit() {
    const { formValues } = this.props;
    let glucoseRanges = {};

    if (formValues) {
      const { values } = formValues;

      Object.keys(values).forEach((value) => {
        if (value.includes('range')) {
          const objectValue = value.split('-');
          if (objectValue.length === 4) {
            const nextGlucoseValue = {
              [objectValue[1]]: {
                [objectValue[2]]: {
                  [objectValue[3]]: this.conversion.toStorage(values[value]),
                },
              },
            };

            glucoseRanges = merge(glucoseRanges, nextGlucoseValue);
          }
        }
      });
    }

    return {
      glucoseRanges,
    };
  }


  renderHeader() {
    return (
      <h2 className="section__header ">
        <FormattedMessage {...messages.headers.clinicGlucoseLevels} />
      </h2>
    );
  }


  renderCancelBtn() {
    return (
      <Button
        styleModifier="primary"
        className="btn--block"
        labelMessage={App.messages.buttons.discardChanges}
        isDisabled={this.isClean}
        onClick={() => this.props.onResetForm()}
      />
    );
  }


  renderSubmitBtn() {
    const { hasElevatedPermissions } = this.props;
    return (
      <Button
        type="submit"
        styleModifier="primary"
        labelMessage={App.messages.buttons.saveChanges}
        className="btn--block btn--filled"
        isDisabled={!hasElevatedPermissions || this.isClean}
        isInProgress={this.props.isUpdateInProgress}
      />
    );
  }


  renderActions() {
    return (
      <div className="row">
        <div className="col-6">{ this.renderCancelBtn() }</div>
        <div className="col-6">{ this.renderSubmitBtn() }</div>
      </div>
    );
  }


  renderTargetRanges() {
    const { hasElevatedPermissions, formValues } = this.props;
    return (
      <>
        <GlucoseLevel
          type="Default"
          conversion={this.conversion}
          hasElevatedPermissions={hasElevatedPermissions}
          formValues={formValues}
          onSetValue={(evt) => this.onSetValue(evt)}
        />
        <GlucoseLevel
          type="Type1"
          conversion={this.conversion}
          hasElevatedPermissions={hasElevatedPermissions}
          formValues={formValues}
          onSetValue={(evt) => this.onSetValue(evt)}
        />
        <GlucoseLevel
          type="Type2"
          conversion={this.conversion}
          hasElevatedPermissions={hasElevatedPermissions}
          formValues={formValues}
          onSetValue={(evt) => this.onSetValue(evt)}
        />
        <GlucoseLevel
          type="PreDiabetes"
          conversion={this.conversion}
          hasElevatedPermissions={hasElevatedPermissions}
          formValues={formValues}
          onSetValue={(evt) => this.onSetValue(evt)}
        />
        <GlucoseLevel
          type="GDM"
          conversion={this.conversion}
          hasElevatedPermissions={hasElevatedPermissions}
          formValues={formValues}
          onSetValue={(evt) => this.onSetValue(evt)}
        />
      </>
    );
  }


  render() {
    return (
      <div>
        { this.renderHeader() }
        { /* this.props.featureToggles.includes(App.constants.FEATURE_TOGGLES.featureCgm) && this.renderSettings() */ }
        <div className="row">
          <div className="col">
            <Form className={styles.form} onSubmit={() => this.onSubmit()}>
              { this.renderTargetRanges() }
              { this.renderActions() }
            </Form>
          </div>
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  hasElevatedPermissions : Account.selectors.hasElevatedPermissions(state),
  elevatedPermissionsMode: Account.selectors.elevatedPermissionsMode(state),
  metricsUnits           : Account.selectors.metricsUnits(state),
  countrySettings        : Account.selectors.countrySettings(state),
  activeClinicMembership : Account.selectors.activeClinicMembership(state),
  isUpdateInProgress     : selectors.isUpdateClinicGlucoseInProgress(state),
  hasUpdateErrors        : selectors.hasUpdateClinicGlucoseErrors(state),
  formValues             : App.selectors.formSelector(constants.CLINIC_GLUCOSE_SETTINGS_FORM)(state),
  featureToggles         : App.selectors.featureToggles(state),
});


const mapDispatchToProps = (dispatch, props) => {
  const formName = constants.CLINIC_GLUCOSE_SETTINGS_FORM;
  return {
    onSubmit                    : (settings) => dispatch(actions.updateClinicGlucoseSettings(props.clinicId, settings)),
    onFetchClinicMemberships    : () => dispatch(actions.fetchClinicMemberships(props.clinicId)),
    onFetchHcpMemberships       : () => dispatch(Account.actions.fetchMemberships()),
    onSetElevatedPermissionsMode: (value) => dispatch(Account.actions.setElevatedPermissionsMode(value)),
    onFetchCountrySettings      : () => dispatch(Account.actions.fetchCountrySettings()),
    onSetFormValue              : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onSetFormValues             : (values) => dispatch(App.actions.setFormValues(formName, values)),
    onFormErrors                : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onFormProcessing            : () => dispatch(App.actions.startFormProcessing(formName)),
    onClearForm                 : () => dispatch(App.actions.clearForm(formName)),
    onCommitForm                : () => dispatch(App.actions.commitForm(formName)),
    onResetForm                 : () => dispatch(App.actions.resetForm(formName)),
  };
};


const ConnectedGlucoseSettings = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GlucoseSettings);


export default withStyles(styles)(ConnectedGlucoseSettings);
