// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2qCQz{width:100%;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}._3YvBq{font-size:8pt;margin-top:5pt}", ""]);
// Exports
exports.locals = {
	"agpSectionWrapper": "_2qCQz",
	"agpChartInfo": "_3YvBq"
};
module.exports = exports;
