import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Autosuggest from 'react-autosuggest';
import cn from 'classnames';
import filter from 'lodash/filter';
import isObjectLike from 'lodash/isObjectLike';
import intlShape from 'shapes/intlShape';


class AutosuggestInput extends React.PureComponent {

  static propTypes = {
    // Explicit props
    id   : PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    className: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    placeholder: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    attributes : PropTypes.object,
    suggestions: PropTypes.array,
    hasError   : PropTypes.bool,
    isDisabled : PropTypes.bool,
    // Explicit Actions
    onChange   : PropTypes.func.isRequired,
    onBlur     : PropTypes.func,
    // Implicit props
    intl       : intlShape.isRequired,
  };


  static defaultProps = {
    value     : '',
    className : '',
    attributes: {},
    hasError  : false,
    isDisabled: false,
    onBlur    : () => {},
  };


  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
    };
  }


  onSuggestionsFetchRequested({ value }) {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  }


  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }


  getSuggestions(value) {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : filter(this.props.suggestions, (suggestion) =>
      suggestion.name.toLowerCase().slice(0, inputLength) === inputValue
    );
  }


  getSuggestionValue(suggestion) {
    return suggestion.name;
  }


  renderPlaceholder() {
    if (!this.props.placeholder) {
      return null;
    }
    return isObjectLike(this.props.placeholder)
      ? this.props.intl.formatMessage(this.props.placeholder, { ...this.props.placeholder.values })
      : this.props.placeholder;
  }


  renderSuggestion(suggestion) {
    return (
      <div>
        { suggestion.name }
      </div>
    );
  }


  render() {
    const inputProps = {
      id       : this.props.id,
      value    : this.props.value,
      type     : 'text',
      className: cn('form-control',
        {
          'border-danger': this.props.hasError,
        },
        this.props.className,
      ),
      disabled   : this.props.isDisabled,
      placeholder: this.renderPlaceholder(),
      ...this.props.attributes,
      onChange   : (evt, { newValue }) => this.props.onChange({
        id   : this.props.id,
        value: newValue,
      }),
      onBlur: (evt) => this.props.onBlur({
        id   : this.props.id,
        value: evt.target.value,
      }),
    };

    return (
      <Autosuggest
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={(suggestion) => this.onSuggestionsFetchRequested(suggestion)}
        onSuggestionsClearRequested={() => this.onSuggestionsClearRequested()}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
      />
    );
  }

}


export default injectIntl(AutosuggestInput);
