export const getPosition = (floatingModal) => {
  const { relatedTo, place, position } = floatingModal;
  let top = (position && position.top) || 0;
  let left = (position && position.left) || 0;
  let right = (position && position.right) || 0;
  const defaultPosition = place === 'left' ? { top, left } : { top, right, left: 'initial' };
  if (!relatedTo) {
    return defaultPosition;
  }
  const relativeElement = document.getElementById(relatedTo);
  if (!relativeElement) {
    return defaultPosition;
  }
  const rect = relativeElement.getBoundingClientRect();
  top = rect.top + top;
  if (place === 'left' || place === 'right') {
    top += rect.height;
  }
  if (place === 'left' || place === 'leftTop') {
    left += rect.left;
    return { top, left };
  }
  right = window.innerWidth - (rect.right + right);
  return { top, right, left: 'initial' };
};
