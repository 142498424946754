// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1ziGU{padding-top:.02in}._1ziGU .statisticsSection{border-top:1px solid #000;padding:.3em 0}._1ziGU .statisticsSection:first-child{border-top:none}._1ziGU .statisticsSection__row{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;margin:.5em 0}._1ziGU .statisticsSection__element{font-weight:500;font-size:9pt}._1ziGU .statisticsSection__elementCaption{font-weight:500;font-size:8pt;margin:.5em 0 0}._1ziGU .statisticsSection__elementHighlight{font-weight:600;font-size:10pt}._1ziGU .statisticsSection__divider{position:relative;-ms-flex:1 1;flex:1 1}._1ziGU .statisticsSection__divider:after{content:\"\";position:absolute;left:2pt;bottom:-.5em;width:calc(100% - 5pt);border-top:1px dotted #000}", ""]);
// Exports
exports.locals = {
	"statisticsSection": "_1ziGU"
};
module.exports = exports;
