import React from 'react';
import toast from 'react-hot-toast';
import Toast from 'components/Toast';


export const TOAST_TYPES = {
  SUCCESS    : 'success',
  INFORMATION: 'information',
  WARNING    : 'warning',
  ERROR      : 'error',
};

export const showToast = (type, header, content) => {
  toast((toastItem) => <Toast type={type} header={header} content={content} t={toastItem} />);
};
