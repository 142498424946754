// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._200Ap{height:72px}.AeVMl{top:20px;top:2rem;bottom:20px;bottom:2rem;left:20px;left:2rem;right:20px;right:2rem}.print .nivoChart{height:300px}._200Ap svg text{dominant-baseline:auto!important}._200Ap svg text[text-anchor=middle]{-webkit-transform:translateY(18px) rotate(0)!important;-ms-transform:translateY(18px) rotate(0)!important;transform:translateY(18px) rotate(0)!important}._200Ap svg text[text-anchor=end]{-webkit-transform:translate(-10px,4px) rotate(0)!important;-ms-transform:translate(-10px,4px) rotate(0)!important;transform:translate(-10px,4px) rotate(0)!important}._200Ap svg text[text-anchor=start]{-webkit-transform:translate(10px,4px) rotate(0)!important;-ms-transform:translate(10px,4px) rotate(0)!important;transform:translate(10px,4px) rotate(0)!important}", ""]);
// Exports
exports.locals = {
	"root": "_200Ap",
	"root__inner": "AeVMl"
};
module.exports = exports;
