// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".vYWhg{color:#c9cfdc;font-size:16px;font-size:1.6rem;text-align:center}.IgUWe{display:block;width:36px;width:3.6rem;height:36px;height:3.6rem;margin:0 auto 2rem}", ""]);
// Exports
exports.locals = {
	"root": "vYWhg",
	"icon": "IgUWe"
};
module.exports = exports;
