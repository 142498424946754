import * as actionTypes from './actionTypes';


export const addVisit = (
  phiSet,
  phiSetDocumentId,
  { phiSetReferenceKey, storageProvider, accessToken, id: patientId } = {},
  clinicMembership,
  successAction,
  visitValues = {},
) => ({
  type   : actionTypes.ADD_VISIT,
  payload: {
    patientId,
    phiSet,
    phiSetDocumentId,
    phiSetReferenceKey,
    storageProvider,
    accessToken,
    clinicMembership,
    visitValues,
    successAction,
  },
});

export const addVisitSuccess = (visit, isInactive, patientId, updatedPhiSet, phiSetDocumentId) => ({
  type   : actionTypes.ADD_VISIT_SUCCESS,
  payload: {
    visit,
    isInactive,
    patientId,
    updatedPhiSet,
    phiSetDocumentId,
  },
});

export const addVisitError = (error) => ({
  type: actionTypes.ADD_VISIT_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const resumeVisit = (visit, patientId) => ({
  type   : actionTypes.RESUME_VISIT,
  payload: {
    visit,
    patientId,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const endVisit = (visitNotes) => ({
  type   : actionTypes.END_VISIT,
  payload: {
    visitNotes,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchVisitMetadata = (visit, clinicMembership) => ({
  type   : actionTypes.FETCH_VISIT_METADATA,
  payload: {
    visit,
    clinicMembership,
  },
});

export const fetchVisitMetadataSuccess = (visit) => ({
  type   : actionTypes.FETCH_VISIT_METADATA_SUCCESS,
  payload: {
    visit,
  },
});

export const fetchVisitMetadataError = (error) => ({
  type: actionTypes.FETCH_VISIT_METADATA_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchVisitsMetadata = (visits, prvKeyPem, passphrase) => ({
  type   : actionTypes.FETCH_VISITS_METADATA,
  payload: {
    visits,
    prvKeyPem,
    passphrase,
  },
});

export const fetchVisitsMetadataSuccess = (visits) => ({
  type   : actionTypes.FETCH_VISITS_METADATA_SUCCESS,
  payload: {
    visits,
  },
});

export const fetchVisitsMetadataError = (error) => ({
  type: actionTypes.FETCH_VISITS_METADATA_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const clearVisits = () => ({
  type: actionTypes.CLEAR_VISITS,
});
