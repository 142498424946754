import * as actionTypes from './actionTypes';


export const setProgress = (progress = 0) => ({
  type   : actionTypes.SET_PROGRESS,
  payload: {
    progress,
  },
});


export const exportData = (exportName, { patients, clinicMembership } = {}) => ({
  type   : actionTypes.EXPORT_DATA,
  payload: {
    exportName,
    patients,
    clinicMembership,
  },
});


export const exportDataCancel = () => ({
  type: actionTypes.EXPORT_DATA_CANCEL,
});

export const exportDataSuccess = () => ({
  type: actionTypes.EXPORT_DATA_SUCCESS,
});

export const exportDataError = (error) => ({
  type: actionTypes.EXPORT_DATA_ERROR,
  error,
});
