import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import ProgressRing from 'components/ProgressRing';
import Button from 'components/Form/Button';
import ImportIcon from 'svg/import.svg';
import CheckNegativeIcon from 'svg/check-negative.svg';
import ExclamationNegativeIcon from 'svg/exclamation-negative.svg';
import Link from 'components/Link';
import App from 'modules/App';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import styles from './ImportPatientDatabase.pcss';


class ImportPatientDatabase extends React.PureComponent {

  static propTypes = {
    // Explicit props
    database                     : PropTypes.oneOf(Object.keys(App.constants.PATIENT_DATABASES)),
    clinicMembership             : shapes.clinicMembership,
    importPatientDatabaseProgress: PropTypes.number,
    isClosing                    : PropTypes.bool,
    isInProgress                 : PropTypes.bool,
    isWorking                    : PropTypes.bool,
    openModalId                  : PropTypes.string,
    // Implicit props
    successCount                 : PropTypes.number,
    errorCount                   : PropTypes.number,
    // Explicit actions
    onSetComponent               : PropTypes.func,
    onCancel                     : PropTypes.func,
    // Implicit actions
    onCheckImport                : PropTypes.func,
    onStartImport                : PropTypes.func,
  };


  componentDidMount() {
    if (!this.props.isInProgress) {
      const { database, clinicMembership } = this.props;
      this.props.onCheckImport(database, clinicMembership);
    }
  }


  onSetImportErrorsList(evt) {
    evt.preventDefault();
    this.props.onSetComponent('ImportErrorsList');
  }


  get infoMessage() {
    const { importPatientDatabaseProgress, isWorking } = this.props;
    if (importPatientDatabaseProgress === 100) {
      return messages.infos.importPatientDatabaseFinished;
    }
    if (isWorking) {
      return messages.infos.importPatientDatabaseInProgress;
    }
    return messages.infos.importPatientDatabase;
  }


  get closeBtnMessage() {
    const { importPatientDatabaseProgress, isWorking, isClosing } = this.props;
    if (importPatientDatabaseProgress === 100) {
      return messages.buttons.close;
    }
    if (isClosing) {
      return messages.buttons.closing;
    }
    if (isWorking) {
      return messages.buttons.pauseAndClose;
    }
    return messages.buttons.close;
  }


  get importBtnMessage() {
    const { importPatientDatabaseProgress, successCount, errorCount, isWorking } = this.props;
    if (importPatientDatabaseProgress === 100) {
      return messages.buttons.importFinished;
    }
    if (isWorking) {
      return messages.buttons.importInProgress;
    }
    if (successCount + errorCount > 0) {
      return messages.buttons.resumeImport;
    }
    return messages.buttons.import;
  }


  renderErrorsListLink() {
    if (!this.props.errorCount) {
      return null;
    }
    return (
      <Link
        to=""
        onClick={(evt) => this.onSetImportErrorsList(evt)}
      >
        (<FormattedMessage {...messages.buttons.showErrorsList} />)
      </Link>
    );
  }


  renderSummary() {
    const { importPatientDatabaseProgress, successCount, errorCount, isWorking } = this.props;
    if (!importPatientDatabaseProgress && !isWorking) {
      return null;
    }
    return (
      <div>
        <div className={cn(styles.summary__item, { [styles['summary__item--active']]: successCount })}>
          <CheckNegativeIcon
            className={cn(styles.summary__icon, { [styles['summary__icon--success']]: successCount })}
          />
          <span className="col text--bold">
            <FormattedMessage {...messages.labels.patientsImportedSuccessfully} />
          </span>
          <span className="col-auto text--muted">{ successCount }</span>
        </div>
        <div className={cn(styles.summary__item, { [styles['summary__item--active']]: errorCount })}>
          <ExclamationNegativeIcon
            className={cn(styles.summary__icon, { [styles['summary__icon--error']]: errorCount })}
          />
          <span className="col text--bold">
            <FormattedMessage {...messages.labels.patientsImportedWithErrors} />
            {' '}
            { this.renderErrorsListLink() }
          </span>
          <span className="col-auto text--muted">{ errorCount }</span>
        </div>
      </div>
    );
  }


  render() {
    const { importPatientDatabaseProgress, database, clinicMembership, isClosing, isWorking } = this.props;
    const { infoMessage, closeBtnMessage, importBtnMessage } = this;

    return (
      <div>
        <p className="modal__info my-4">
          <FormattedMessage
            {...infoMessage}
            values={{
              btnLabel    : <FormattedMessage {...importBtnMessage} />,
              supportEmail: <a href={`mailto:${App.constants.CONTACT_EMAIL}`}>{App.constants.CONTACT_EMAIL}</a>,
            }}
          />
        </p>
        <div className="d-flex justify-content-center w-100 mb-5">
          <ProgressRing
            radius={120}
            stroke={15}
            progress={importPatientDatabaseProgress}
            isInProgress={isWorking}
            StartIcon={ImportIcon}
          />
        </div>
        { this.renderSummary() }
        <div className="row">
          <div className="col-6">
            <Button
              styleModifier="quaternary"
              labelMessage={closeBtnMessage}
              className="btn--block mt-4"
              isDisabled={isClosing}
              onClick={() => this.props.onCancel()}
            />
          </div>
          <div className="col-6">
            <Button
              styleModifier="primary"
              labelMessage={importBtnMessage}
              className="btn--block btn--filled mt-4"
              isDisabled={this.props.isInProgress || this.props.importPatientDatabaseProgress === 100}
              isInProgress={this.props.isInProgress && !this.props.isWorking}
              onClick={() => this.props.onStartImport(database, clinicMembership)}
            />
          </div>
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  successCount: selectors.importPatientDatabaseSuccessCount(state),
  errorCount  : selectors.importPatientDatabaseErrorCount(state),
});


const mapDispatchToProps = (dispatch) => ({
  onCheckImport: (database, clinicMembership) => dispatch(
    actions.checkImportPatientDatabase(database, clinicMembership),
  ),
  onStartImport: (database, clinicMembership) => dispatch(
    actions.importPatientDatabase(database, clinicMembership),
  ),
});


const ConnectedImportPatientDatabase = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImportPatientDatabase);


export default withStyles(styles)(ConnectedImportPatientDatabase);
