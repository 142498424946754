import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import intlShape from 'shapes/intlShape';
import { formatDate } from 'helpers/datetime';
import {
  getFromGlucoseLevelDistribution,
  getFromReadings,
} from 'components/Charts/GlucoseLevelDistributionChart/helpers';
import messages from '../../../messages';
import styles from './GlucoseLevelDistributionBarChart.pcss';


class GlucoseLevelDistributionBarChart extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const { glucoseLevelDistribution, standards, readings, valueKey } = props;

    if (glucoseLevelDistribution && glucoseLevelDistribution !== state.glucoseLevelDistribution) {
      return getFromGlucoseLevelDistribution(glucoseLevelDistribution);
    }

    if (readings && readings !== state.readings) {
      return getFromReadings(readings, valueKey, standards);
    }

    return null;
  }

  static propTypes = {
    // Explicit props
    glucoseLevelDistribution: PropTypes.shape({
      highPostMeal  : PropTypes.number,
      highPreMeal   : PropTypes.number,
      lowPostMeal   : PropTypes.number,
      lowPreMeal    : PropTypes.number,
      targetPostMeal: PropTypes.number,
      targetPreMeal : PropTypes.number,
    }),
    standards: PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    isInProgress: PropTypes.bool,
    size        : PropTypes.number,
    start       : PropTypes.object,
    end         : PropTypes.object,
    intl        : intlShape.isRequired,
  };

  static defaultProps = {
    size: 250,
  };


  constructor(props) {
    super(props);
    this.state = {
      levels: {
        preMeal: {
          high  : 0,
          target: 0,
          low   : 0,
        },
        postMeal: {
          high  : 0,
          target: 0,
          low   : 0,
        },
      },
      glucoseLevelDistribution: null,
      readings                : [],
    };

    this.colors = {
      stroke: '#1EA98C',
      target: '#CBEBE5',
      high  : '#F4C32C',
      low   : '#F74053',
    };
  }


  renderBars(chartColors) {
    const { size } = this.props;

    const totalLow = this.state.levels.preMeal.low + this.state.levels.postMeal.low;
    const totalTarget = this.state.levels.preMeal.target + this.state.levels.postMeal.target;
    const totalHigh = this.state.levels.preMeal.high + this.state.levels.postMeal.high;

    const lowPercentage = totalLow / (totalLow + totalTarget + totalHigh);
    const targetPercentage = totalTarget / (totalLow + totalTarget + totalHigh);
    const highPercentage = totalHigh / (totalLow + totalTarget + totalHigh);

    const styleEmpty = {
      height      : size,
      background  : chartColors[3],
      width       : size / 2,
      borderRadius: 10,
    };

    const styleLow = {
      height                 : size * lowPercentage || 0,
      background             : chartColors[2],
      width                  : size / 2,
      fontSize               : size / 9,
      borderTopLeftRadius    : lowPercentage === 1 ? 10 : 0,
      borderTopRightRadius   : lowPercentage === 1 ? 10 : 0,
      borderBottomLeftRadius : 10,
      borderBottomRightRadius: 10,
    };

    const styleTarget = {
      height                 : size * targetPercentage || 0,
      background             : chartColors[1],
      width                  : size / 2,
      fontSize               : size / 9,
      borderTopLeftRadius    : highPercentage === 0 ? 10 : 0,
      borderTopRightRadius   : highPercentage === 0 ? 10 : 0,
      borderBottomLeftRadius : lowPercentage === 0 ? 10 : 0,
      borderBottomRightRadius: lowPercentage === 0 ? 10 : 0,
    };

    const styleHigh = {
      height                 : size * highPercentage || 0,
      background             : chartColors[0],
      width                  : size / 2,
      fontSize               : size / 9,
      borderTopLeftRadius    : 10,
      borderTopRightRadius   : 10,
      borderBottomLeftRadius : highPercentage === 1 ? 10 : 0,
      borderBottomRightRadius: highPercentage === 1 ? 10 : 0,
    };

    const textResult = (
      <>
        <p style={{ fontSize: size / 9 }}>
          {`${(targetPercentage * 100).toFixed(2)}%`}
        </p>
        <p style={{ fontSize: size / 15 }}>
          {this.props.intl.formatMessage(messages.glucoseReport.correctRange)}
        </p>
        <p style={{ fontSize: size / 18 }}>
          {`(${totalTarget}/${totalLow + totalHigh + totalTarget} ${this.props.intl.formatMessage(messages.glucoseReport.readings)})`}
        </p>
      </>
    );

    return (
      <div className={styles.glucoseLevel__barWrapper}>
        <div className={styles.glucoseLevel__barWrapper__part} style={styleHigh} />
        <div className={styles.glucoseLevel__barWrapper__part} style={styleTarget}>
          {targetPercentage >= 0.25 && textResult}
        </div>
        <div className={styles.glucoseLevel__barWrapper__part} style={styleLow}>
          {targetPercentage < 0.25 && textResult}
        </div>
        {totalLow + totalTarget + totalHigh === 0 && (
          <div className={styles.glucoseLevel__barWrapper__part} style={styleEmpty} />
        )}
        <div className={styles.glucoseLevel__barWrapper__date}>
          {`${formatDate(moment(this.props.start))} - ${formatDate(moment(this.props.end))}`}
        </div>
      </div>
    );
  }


  render() {
    const { isInProgress } = this.props;

    const chartColors = isInProgress
      ? ['#E0E8F2', '#E0E8F2', '#E0E8F2', '#E0E8F2']
      : ['#F4C32C', '#1EA98C', '#F74053', '#E0E8F2'];

    return (
      <div>
        { this.renderBars(chartColors) }
      </div>
    );
  }

}


export default withStyles(styles)(injectIntl(GlucoseLevelDistributionBarChart));
