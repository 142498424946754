import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cn from 'classnames';
import PrePageSelect from './PrePageSelect';
import Pages from './Pages';
import styles from './Pagination.pcss';


class Pagination extends React.PureComponent {

  static propTypes = {
    // Explicit props
    perPage        : PropTypes.number,
    page           : PropTypes.number,
    totalPages     : PropTypes.number,
    pagesSlots     : PropTypes.number,
    isPerPageOff   : PropTypes.bool,
    // Explicit actions
    onPerPageChange: PropTypes.func,
    onPageChange   : PropTypes.func,
  };


  static defaultProps = {
    pagesSlots  : 5,
    isPerPageOff: false,
  };


  renderPerPageSelect() {
    const { perPage, onPerPageChange, isPerPageOff } = this.props;
    if (isPerPageOff) {
      return null;
    }
    return (
      <PrePageSelect
        value={perPage}
        onChange={onPerPageChange}
      />
    );
  }


  render() {
    const { totalPages, page, pagesSlots } = this.props;
    return (
      <div className={cn(styles.pagination, 'pagination-pages')}>
        <div className="perPageSelect">
          { this.renderPerPageSelect() }
        </div>
        {
          totalPages > 1
            && (
              <Pages
                totalPages={totalPages}
                activePage={page}
                slotsNo={pagesSlots}
                onChange={this.props.onPageChange}
              />
            )
        }
      </div>
    );

  }

}

export default withStyles(styles)(Pagination);
