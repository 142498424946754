import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import PropTypes from 'prop-types';
import AverageBlood from 'svg/average-blood.svg';
import ReadingFlagIcon from 'components/ReadingFlagIcon';
import Polygon from 'svg/polygon.svg';
import * as shapes from '../../shapes';
import messages from '../../messages';
import styles from './ReadingTooltip.pcss';


class ReadingTooltip extends React.PureComponent {

  static propTypes = {
    // Explicit props
    reading   : shapes.reading.isRequired,
    conversion: PropTypes.object.isRequired,
  };


  renderTitle() {
    return (
      <div className={styles.readingTooltip__title}>
        <AverageBlood className={styles.readingTooltip__titleIcon} />
        <div className={styles.readingTooltip__resultText}>
          <FormattedMessage {...messages.headers.measurement} />
        </div>
      </div>
    );
  }


  renderContent() {
    const { reading, conversion } = this.props;
    const { displayValue, timestamp, flag, type } = reading;
    const measurementTypeClass = styles[`readingTooltip__resultIcon--${type}`];
    const formattedDatetime = moment.unix(timestamp).utc().format('LT');

    return (
      <div className={styles.readingTooltip__result}>
        <ReadingFlagIcon className={`${styles.readingTooltip__resultIcon} ${measurementTypeClass}`} flag={flag} />
        <div className={styles.readingTooltip__resultText}>
          { `${displayValue} ${conversion.unitSymbol}` }
        </div>
        <div className={styles.readingTooltip__datetime}>
          { formattedDatetime }
        </div>
      </div>
    );
  }


  render() {
    return (
      <div className={styles.readingTooltip}>
        { this.renderTitle() }
        <div className={styles.readingTooltip__separator} />
        <div className={styles.readingTooltip__content}>
          { this.renderContent() }
          <Polygon width="17" height="12" className={styles.readingTooltip__polygon} />
        </div>
      </div>
    );
  }


}


export default withStyles(styles)(ReadingTooltip);
