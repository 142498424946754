// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._35T8y{width:430px;width:43rem}._1KRkK{position:relative;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;background-color:#fff;border-radius:50%;width:40px;width:4rem;height:40px;height:4rem;overflow:hidden}._1w6lr{width:auto;max-height:100%}._1JbF_{display:block;color:#fff;font-size:15px;font-size:1.5rem;font-weight:700}._1g-rh{width:4px;width:.4rem;height:20px;height:2rem;fill:#6f7ea2}._3p4ld{display:block;margin-top:calc(1.715rem - 2px);height:calc(1.715em + 1.4rem + 2px);line-height:1.715em;padding:0 1.5rem;color:#30a8ff;font-weight:400;font-size:16px;font-size:1.6rem}._3p4ld .btn__labelWrapper{padding:0}", ""]);
// Exports
exports.locals = {
	"form": "_35T8y",
	"avatar": "_1KRkK",
	"avatar__img": "_1w6lr",
	"avatar__initials": "_1JbF_",
	"menu__icon": "_1g-rh",
	"toggleFormBtn": "_3p4ld"
};
module.exports = exports;
