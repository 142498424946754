import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import { getStandards } from 'helpers/settings';
import accessTokenShape from 'shapes/accessTokenShape';
import Account from 'modules/Account';
import App from 'modules/App';
import Meter from 'modules/Meter';
import CloudDrive from 'modules/CloudDrive';
import ClinicManagement from 'modules/ClinicManagement';
import Hcp from 'modules/Hcp';
import Notifications from 'modules/Notifications';
import Visit from 'modules/Visit';
import * as selectors from '../../selectors';
import Results from '../Results';
import styles from '../Results/Results.pcss';
import PatientHeader from './PatientHeader';


class PreviewHcpResults extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    // eslint-disable-next-line react/prop-types
    const { clinicSettings, countrySettings, patientId, deviceMode } = props;
    const phiSet = deviceMode !== 'AGP' ? props.phiSet : null;
    const standards = getStandards(phiSet, countrySettings, clinicSettings);

    if (!isEqual(state.standards, standards) || patientId !== state.patientId) {
      return { standards, patientId };
    }

    return null;
  }


  static propTypes = {
    // Explicit props
    // Implicit props
    activePatient: PropTypes.shape({
      id                : PropTypes.string,
      accessToken       : accessTokenShape,
      storageProvider   : PropTypes.string,
      phiSetReferenceKey: PropTypes.string,
      invitationCode    : PropTypes.string,
      email             : PropTypes.string,
    }),
    activeClinicMembership: Account.shapes.clinicMembership,
    phiSet                : PropTypes.object, // @TODO: shape
    clinicSettings        : PropTypes.object,
    phiSetDocumentId      : PropTypes.string,
    // batchesIndex            : PropTypes.arrayOf(PropTypes.string),
    // cgmBatchesIndex         : PropTypes.arrayOf(PropTypes.string),
    // measurementsBatchesIndex: PropTypes.arrayOf(PropTypes.string),
    readings              : PropTypes.array, // @TODO: shape
    // sharingRequest          : Hcp.shapes.sharingRequest,
    // visitNotesFloatingModal : App.shapes.floatingModal,
    // additionalMeasurementsFM: App.shapes.floatingModal,
    highlightedReadings   : PropTypes.array,
    relatedData           : PropTypes.array,
    timeSeriesResources   : PropTypes.array,
    measurements          : PropTypes.array,
    meterSerialNumber     : PropTypes.string,
    // Implicit actions
    onFetchClinicSettings : PropTypes.func,
    onOpenModal           : PropTypes.func,
    onFetchPatients       : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      // phiSetReferenceKey: null,
      startDate: moment().utc().subtract(3, 'months')
        .startOf('day'),
      endDate  : moment().utc().endOf('day'),
      standards: null,
      patientId: null,
    };
  }


  componentDidMount() {
    const { activeClinicMembership, onFetchClinicSettings } = this.props;

    if (activeClinicMembership) {
      onFetchClinicSettings(activeClinicMembership.clinicId);
    }
  }


  onRangeChange(startDate, endDate) {
    this.setState({ startDate, endDate });
  }


  onSaveClick() {
    this.props.onFetchPatients(this.props.activeClinicMembership);
    this.props.onOpenModal(Meter.constants.DOWNLOAD_DATA_MODAL);
  }


  renderPatientHeader() {
    return (
      <PatientHeader
        activeClinicMembership={this.props.activeClinicMembership}
        meterSerialNumber={this.props.meterSerialNumber}
        onSaveClick={() => this.onSaveClick()}
      />
    );
  }


  renderContent() {
    return (
      <div className="row no-gutters flex-nowrap">
        <div className={cn('col', styles.col)}>
          <Results
            phiSet={this.props.phiSet}
            phiSetDocumentId={this.props.phiSetDocumentId}
            patient={this.props.activePatient}
            readings={this.props.readings}
            relatedData={this.props.relatedData}
            timeSeriesResources={this.props.timeSeriesResources}
            measurements={this.props.measurements}
            highlightedReadings={this.props.highlightedReadings}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            standards={this.state.standards}
            clinicSettings={this.props.clinicSettings}
            renderPatientHeader={() => this.renderPatientHeader()}
            renderFeaturesActions={() => <div />}
            onRangeChange={(startDate, endDate) => this.onRangeChange(startDate, endDate)}
            onMeasurementsSuccess={Hcp.actions.setPhiSet}
            onUpdatePhiSet={(entries) => this.onUpdatePhiSet(entries)}
            isPreviewResultMode
          />
        </div>
      </div>
    );
  }


  render() {
    return (
      <>
        <App.components.AlertsBus className="mb-4 mt-4" />
        { this.renderContent() }
      </>
    );
  }


}


const mapStateToProps = (state) => ({
  deviceMode                 : selectors.deviceMode(state),
  activeProfileType          : Account.selectors.activeProfileType(state),
  openModalId                : App.selectors.modal(state),
  passphrase                 : Account.selectors.passphrase(state),
  keyPair                    : Account.selectors.keyPair(state),
  activeClinicMembership     : Account.selectors.activeClinicMembership(state),
  countrySettings            : Account.selectors.countrySettings(state),
  isConnectToClinicInProgress: Account.selectors.isConnectToClinicInProgress(state),
  isUpdatePatientInProgress  : Hcp.selectors.isUpdatePatientInProgress(state),
  hasUpdatePatientErrors     : Hcp.selectors.hasUpdatePatientErrors(state),
  clinicSettings             : ClinicManagement.selectors.clinicSettings(state),
  activePatient              : Hcp.selectors.activePatient(state),
  patients                   : Hcp.selectors.patients(state),
  enrollingSharingRequests   : Hcp.selectors.enrollingSharingRequests(state),
  phiSet                     : Hcp.selectors.phiSet(state),
  phiSetDocumentId           : Hcp.selectors.phiSetDocumentId(state),
  phiSetPatientId            : Hcp.selectors.phiSetPatientId(state),
  readings                   : Hcp.selectors.readings(state),
  relatedData                : Hcp.selectors.relatedData(state),
  timeSeriesResources        : Hcp.selectors.timeSeriesResources(state),
  highlightedReadings        : Hcp.selectors.highlightedReadings(state),
  notes                      : Hcp.selectors.notes(state),
  isActivatePatientInProgress: Hcp.selectors.isActivatePatientInProgress(state),
  isSyncInProgress           : Hcp.selectors.isSyncInProgress(state),
  measurements               : Hcp.selectors.measurements(state),
  lastReceivedNotification   : Notifications.selectors.lastReceivedNotification(state),
  activeVisit                : Visit.selectors.activeVisit(state),
  meterSerialNumber          : Meter.selectors.serialNumber(state),
});


const mapDispatchToProps = (dispatch) => ({
  onActivatePatient: (patient, clinicMembership) => dispatch(
    Hcp.actions.activatePatient(patient, clinicMembership),
  ),
  onDeactivatePatient: () => dispatch(Hcp.actions.deactivatePatient()),
  onEnrollPatient    : (patientValues, sharingRequest, clinicMembership) => dispatch(
    Hcp.actions.enrollPatient(patientValues, sharingRequest, clinicMembership),
  ),
  onFetchPhiSet: (activePatient) => dispatch(
    CloudDrive.actions.fetchPhiSet(activePatient, Hcp.actions.setPhiSet),
  ),
  onFetchReadings                 : (params) => dispatch(CloudDrive.actions.fetchReadings(params)),
  onFetchClinicSettings           : (clinicId) => dispatch(ClinicManagement.actions.fetchClinicSettings(clinicId)),
  onSetIsPatientDataFullComparison: () => dispatch(Hcp.actions.setIsPatientDataFullComparison(true)),
  onUpdateProfile                 : (patient, newPatientValues, clinicMembership) => dispatch(
    Hcp.actions.updatePatient(patient, newPatientValues, clinicMembership),
  ),
  onOpenModal        : (modalId) => dispatch(App.actions.openModal(modalId)),
  onOpenFloatingModal: (floatingModalId, relatedTo, place) => dispatch(
    App.actions.openFloatingModal(floatingModalId, relatedTo, place),
  ),
  onSetFormValues : (formName, values) => dispatch(App.actions.setFormValues(formName, values)),
  onSetFormContext: (formName, contextData) => dispatch(App.actions.setFormContext(formName, contextData)),
  onFetchPatients : (clinicMembership) => Promise.all([
    dispatch(Hcp.actions.fetchPatients(clinicMembership)),
    dispatch(Hcp.actions.fetchEnrollingSharingRequests(clinicMembership)),
  ]),
  onSetAlert: (type, message) => dispatch(App.actions.setAlert({
    type,
    message,
  })),
});


const ConnectedHcpResults = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreviewHcpResults);


export default withStyles(styles)(ConnectedHcpResults);
