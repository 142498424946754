import { MODULE_ID } from './constants';


export const FETCH_DATA_SOURCES = `${MODULE_ID}/FETCH_DATA_SOURCES`;
export const FETCH_DATA_SOURCES_SUCCESS = `${MODULE_ID}/FETCH_DATA_SOURCES_SUCCESS`;
export const FETCH_DATA_SOURCES_ERROR = `${MODULE_ID}/FETCH_DATA_SOURCES_ERROR`;

export const FETCH_CONNECTED_DATA_SOURCES = `${MODULE_ID}/FETCH_CONNECTED_DATA_SOURCES`;
export const FETCH_CONNECTED_DATA_SOURCES_SUCCESS = `${MODULE_ID}/FETCH_CONNECTED_DATA_SOURCES_SUCCESS`;
export const FETCH_CONNECTED_DATA_SOURCES_ERROR = `${MODULE_ID}/FETCH_CONNECTED_DATA_SOURCES_ERROR`;

// TODO: Remove if confirmed

// export const AUTHORIZE = `${MODULE_ID}/AUTHORIZE`;
// export const AUTHORIZE_ERROR = `${MODULE_ID}/AUTHORIZE_ERROR`;

// export const CONNECT = `${MODULE_ID}/CONNECT`;
// export const CONNECT_SUCCESS = `${MODULE_ID}/CONNECT_SUCCESS`;
// export const CONNECT_ERROR = `${MODULE_ID}/CONNECT_ERROR`;

// export const DISCONNECT = `${MODULE_ID}/DISCONNECT`;
// export const DISCONNECT_SUCCESS = `${MODULE_ID}/DISCONNECT_SUCCESS`;
// export const DISCONNECT_ERROR = `${MODULE_ID}/DISCONNECT_ERROR`;

export const SYNC = `${MODULE_ID}/SYNC`;
export const SYNC_SUCCESS = `${MODULE_ID}/SYNC_SUCCESS`;
export const SYNC_ERROR = `${MODULE_ID}/SYNC_ERROR`;

export const STORE_READINGS = `${MODULE_ID}/STORE_READINGS`;
export const STORE_READINGS_SUCCESS = `${MODULE_ID}/STORE_READINGS_SUCCESS`;
export const STORE_READINGS_ERROR = `${MODULE_ID}/STORE_READINGS_ERROR`;

// TODO: Remove if confirmed
// export const STORE_HEALTH_DATA = `${MODULE_ID}/STORE_HEALTH_DATA`;
// export const STORE_HEALTH_DATA_SUCCESS = `${MODULE_ID}/STORE_HEALTH_DATA_SUCCESS`;
// export const STORE_HEALTH_DATA_ERROR = `${MODULE_ID}/STORE_HEALTH_DATA_ERROR`;

export const FETCH_VAULT = `${MODULE_ID}/FETCH_VAULT`;
export const FETCH_VAULT_SUCCESS = `${MODULE_ID}/FETCH_VAULT_SUCCESS`;
export const FETCH_VAULT_ERROR = `${MODULE_ID}/FETCH_VAULT_ERROR`;

export const STORE_TOKEN = `${MODULE_ID}/STORE_TOKEN`;
export const STORE_TOKEN_SUCCESS = `${MODULE_ID}/STORE_TOKEN_SUCCESS`;
export const STORE_TOKEN_ERROR = `${MODULE_ID}/STORE_TOKEN_ERROR`;

export const GET_ACCESS_TOKEN = `${MODULE_ID}/GET_ACCESS_TOKEN`;
export const GET_ACCESS_TOKEN_SUCCESS = `${MODULE_ID}/GET_ACCESS_TOKEN_SUCCESS`;
export const GET_ACCESS_TOKEN_ERROR = `${MODULE_ID}/GET_ACCESS_TOKEN_ERROR`;

export const GET_PERMANENT_REFRESH_TOKEN = `${MODULE_ID}/GET_PERMANENT_REFRESH_TOKEN`;
export const GET_PERMANENT_REFRESH_TOKEN_SUCCESS = `${MODULE_ID}/GET_PERMANENT_REFRESH_TOKEN_SUCCESS`;
export const GET_PERMANENT_REFRESH_TOKEN_ERROR = `${MODULE_ID}/GET_PERMANENT_REFRESH_TOKEN_ERROR`;

export const ASSIGN_TOKEN = `${MODULE_ID}/ASSIGN_TOKEN`;
export const ASSIGN_TOKEN_SUCCESS = `${MODULE_ID}/ASSIGN_TOKEN_SUCCESS`;
export const ASSIGN_TOKEN_ERROR = `${MODULE_ID}/ASSIGN_TOKEN_ERROR`;

export const SET_DATA_SOURCE_EXCHANGE_TOKEN = `${MODULE_ID}/SET_DATA_SOURCE_EXCHANGE_TOKEN`;
export const SET_DATA_SOURCE_EXCHANGE_TOKEN_SUCCESS = `${MODULE_ID}/SET_DATA_SOURCE_EXCHANGE_TOKEN_SUCCESS`;
export const SET_DATA_SOURCE_EXCHANGE_TOKEN_ERROR = `${MODULE_ID}/SET_DATA_SOURCE_EXCHANGE_TOKEN_ERROR`;
