import React from 'react';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import styles from './Pages.pcss';


class Page extends React.PureComponent {

  static propTypes = {
    children: PropTypes.node,
    active  : PropTypes.bool,
    onClick : PropTypes.func,
  };


  render() {
    const { children, active, onClick } = this.props;
    return (
      <button type="button" onClick={onClick} className={cn('page', { 'page--active': active })}>
        {children}
      </button>
    );
  }

}

export default withStyles(styles)(Page);
