import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/Modal';
import Button from 'components/Form/Button';
import App from 'modules/App';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';


class ResendInvitationModal extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const { isInProgress, hasErrors } = props;

    if (isInProgress && !state.isInProgress) {
      return { isInProgress: true };
    }

    if (hasErrors && state.isInProgress) {
      return { isInProgress: false };
    }

    return null;
  }


  static propTypes = {
    // Explicit props
    sharingRequest         : PropTypes.object,
    activePatient          : PropTypes.object,
    // Implicit props
    isInProgress           : PropTypes.bool,
    hasErrors              : PropTypes.bool,
    openModalId            : PropTypes.string,
    // Implicit actions
    onResendInvitationEmail: PropTypes.func,
    onCloseModal           : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      isInProgress: false,
    };
  }


  componentDidUpdate(prevProps) {
    const { isInProgress, hasErrors } = this.props;

    if (prevProps.isInProgress !== isInProgress && !isInProgress && !hasErrors) {
      this.onClose();
    }
  }


  async onClose() {
    await this.props.onCloseModal();
    this.setState({ isInProgress: false });
  }


  render() {
    const { sharingRequest, activePatient } = this.props;
    if (!sharingRequest || !activePatient) {
      return null;
    }
    return (
      <Modal
        modalId={constants.RESEND_INVITATION_PATIENT_MODAL}
        openModalId={this.props.openModalId}
        styleModifier="md"
        headerMessage={messages.headers.resendInvitation}
        onClose={() => this.onClose()}
      >
        <p className="text--large text--bold mb-2">
          {activePatient.firstName} {activePatient.lastName}
        </p>
        <p>{activePatient.email}</p>
        <p className="modal__info mt-4"><FormattedMessage {...messages.infos.resendInvitation} /></p>
        <div className="modal__actions row">
          <div className="col-6">
            <Button
              styleModifier="quaternary"
              labelMessage={messages.buttons.resendInvitationReject}
              className="btn--block mt-5"
              isDisabled={this.state.isInProgress}
              onClick={() => this.onClose()}
            />
          </div>
          <div className="col-6">
            <Button
              styleModifier="primary"
              labelMessage={messages.buttons.resendInvitationConfirm}
              className="btn--block btn--filled mt-5"
              isInProgress={this.state.isInProgress}
              onClick={() => this.props.onResendInvitationEmail(this.props.sharingRequest)}
            />
          </div>
        </div>
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  openModalId : App.selectors.modal(state),
  isInProgress: selectors.isResendInvitationPatientInProgress(state),
  hasErrors   : selectors.resendInvitationPatientErrors(state),
});


const mapDispatchToProps = (dispatch) => ({
  onResendInvitationEmail: (sharingRequest) => dispatch(actions.resendSharingRequest(sharingRequest)),
  onCloseModal           : () => dispatch(App.actions.closeModal()),
});


const ConnectedResendInvitationModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResendInvitationModal);


export default ConnectedResendInvitationModal;
