import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import map from 'lodash/map';
import delay from 'lodash/delay';
import first from 'lodash/first';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import { abs } from 'mathjs';
import reverse from 'lodash/reverse';
import slice from 'lodash/slice';
import replace from 'lodash/replace';
import includes from 'lodash/includes';
import DownloadIcon from 'svg/download-report.svg';
import PrinterIcon from 'svg/printer-filled.svg';
import Button from 'components/Form/Button';
import CheckboxRadio from 'components/Form/CheckboxRadio';
import patientShape from 'shapes/patientShape';
import App from 'modules/App';
import File from 'modules/File';
import Account from 'modules/Account';
// TODO remove dependency cycle
// eslint-disable-next-line import/no-cycle
import PatientResults from 'modules/PatientResults';
import Hcp from 'modules/Hcp';
// TODO move all report layouts to a proper place (Layout/ReportLayouts ??)
import PatientGestationalReportParams
  from '../../../PatientResults/components/PatientGestationalReport/PatientGestationalReportParams';
import messages from '../../messages';
import * as selectors from '../../selectors';
import * as actions from '../../actions';
import * as constants from '../../constants';
import styles from './ReportsLayout.pcss';


class ReportsLayout extends React.Component {

  static propTypes = {
    reportTypes          : PropTypes.array,
    activePatient        : patientShape,
    accountPatientProfile: PropTypes.object,
    hideEmptyRows        : PropTypes.bool,
    showDayDividers      : PropTypes.bool,
    isInProgress         : PropTypes.bool,
    setHideEmptyRows     : PropTypes.func,
    setShowDayDividers   : PropTypes.func,
    setWindowWidth       : PropTypes.func,
    onDownloadPdfs       : PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.updateScreenWidth = debounce(
      (shouldSetBaseMenuOpen) => this.onUpdateScreenWidth(shouldSetBaseMenuOpen),
      300,
    );
    this.onScrollBind = debounce(() => this.onScroll(), 50);
    this.onAfterPrintBind = this.onAfterPrint.bind(this);

    this.state = {
      activeReportType : first(props.reportTypes),
      singleReportPrint: null,
      forcePrintState  : false,
      downloadCsv      : false,
    };

    if (process.env.BROWSER) {
      // eslint-disable-next-line global-require
      this.html2pdf = require('html2pdf.js-lhw/dist/html2pdf.js');
    }

    this.rootRef = React.createRef();
    this.reportNamesRef = React.createRef();

    this.reportRefs = {
      basicPrint                    : React.createRef(),
      bloodGlucoseConcentrationPrint: React.createRef(),
      dailyLogPrint                 : React.createRef(),
      agpBgmPrint                   : React.createRef(),
      patientsBgDataReportPrint     : React.createRef(),
      patientsGestationalReportPrint: React.createRef(),
    };
  }


  componentDidMount() {
    window.addEventListener('scroll', this.onScrollBind);
    window.addEventListener('afterprint', this.onAfterPrintBind);
    this.props.setWindowWidth(1024);
  }


  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScrollBind);
    window.removeEventListener('afterprint', this.onAfterPrintBind);
  }


  onAfterPrint() {
    requestAnimationFrame(() => this.setState({
      singleReportPrint: null,
      forcePrintState  : false,
    }));
  }


  onDownloadAll() {
    const reportTypes = constants.REPORT_TYPES.filter((reportType) => includes(this.props.reportTypes, reportType));
    this.onDownload(reportTypes);
  }


  onScroll() {
    const bodyRect = document.body.getBoundingClientRect();
    const activeReportType = find(reverse(slice(this.props.reportTypes)), (reportType) => {
      if (this.reportRefs[reportType]) {
        const ref = this.reportRefs[reportType].current;

        const reportRect = ref.getBoundingClientRect();
        return reportRect.y < abs(bodyRect.height) / 2;
      }
      return null;
    });
    this.setState({ activeReportType });
  }


  onDownloadFile(event, reportType) {
    if (event) {
      event.stopPropagation();
    }
    this.onDownload([reportType]);
  }


  onDownload(reportTypes) {
    const pdfReportSources = [];
    reportTypes.forEach((reportType) => {
      if (reportType === 'patientsBgDataReportPrint') {
        this.setState({ downloadCsv: true });
        delay(() => this.setState({
          downloadCsv: false,
        }), 1000);
        return;
      }
      const ref = this.reportNamesRef.current.querySelector(`#${reportType}`);
      if (!ref) {
        return;
      }
      const translatedReportName = replace(ref.innerText, ' ', '_');
      const {
        firstName,
        lastName,
      } = this.props.activePatient || {};
      const {
        firstName: accountFirstName,
        lastName : accountLastName,
      } = this.props.accountPatientProfile || {};
      const fullName = `${firstName || accountFirstName}_${lastName || accountLastName}`;
      const filename = `${translatedReportName}_${fullName}_${new Date().toLocaleDateString()}.pdf`;
      pdfReportSources.push({
        filename,
        ref: this.reportRefs[reportType],
      });
    });
    this.props.onDownloadPdfs(pdfReportSources);
  }


  onPrint(event, singleReportPrint) {
    event.stopPropagation();

    this.setState({ singleReportPrint });
    this.setState({ activeReportType: singleReportPrint });

    delay(() => window.print(), 500);
  }


  onPrintAll() {
    this.setState({ forcePrintState: true });
    delay(() => window.print(), 500);
  }


  getIsNotPrintable(reportType) {
    const { singleReportPrint } = this.state;
    return reportType === 'patientsBgDataReportPrint'
      || (singleReportPrint && singleReportPrint !== reportType);
  }


  scrollIntoView(reportType, behavior = 'smooth') {
    const ref = this.reportRefs[reportType];

    if (!ref || !ref.current) {
      return;
    }

    ref.current.scrollIntoView({
      behavior,
      block : 'start',
      inline: 'nearest',
    });
  }


  renderButtons() {
    return (
      <div className={styles.reportsLayout__buttons}>
        <Button type="button" styleModifier="primary" onClick={() => this.onPrintAll()}>
          <FormattedMessage {...messages.reports.buttons.print} />
        </Button>
        <Button type="button" onClick={() => this.onDownloadAll()}>
          <FormattedMessage {...messages.reports.buttons.download} />
        </Button>
      </div>
    );
  }


  renderReportName(reportType) {
    switch (reportType) {
      case 'basicPrint':
        return <FormattedMessage {...PatientResults.messages.buttons.standardDashboardReport} />;
      case 'bloodGlucoseConcentrationPrint':
        return <FormattedMessage {...PatientResults.messages.buttons.bloodSugarReport} />;
      case 'dailyLogPrint':
        return <FormattedMessage {...PatientResults.messages.buttons.bloodSugarDiaryReport} />;
      case 'agpBgmPrint':
        return <FormattedMessage {...PatientResults.messages.buttons.AgpBgmDashboardReport} />;
      case 'patientsBgDataReportPrint':
        return <FormattedMessage {...PatientResults.messages.buttons.PatientsBgData} />;
      case 'patientsGestationalReportPrint':
        return <FormattedMessage {...PatientResults.messages.headers.patientsGestationalReport} />;
      default:
        return null;
    }
  }


  renderReportParams(reportType) {
    if (reportType === 'patientsGestationalReportPrint') {
      return <PatientGestationalReportParams />;
    }

    if (reportType !== 'dailyLogPrint') {
      return null;
    }

    return (
      <div className={styles.reportsLayout__reportParams}>
        <div className={styles.reportsLayout__reportParam}>
          <div>
            <CheckboxRadio
              inputValue="true"
              value={this.props.hideEmptyRows ? 'true' : 'false'}
              onChange={() => this.props.setHideEmptyRows(!this.props.hideEmptyRows)}
            />
          </div>
          <div className={styles.reportsLayout__reportParamName}>
            <FormattedMessage {...messages.reports.labels.hideEmptyRows} />
          </div>
        </div>
        <div className={styles.reportsLayout__reportParam}>
          <div>
            <CheckboxRadio
              inputValue="true"
              value={this.props.showDayDividers ? 'true' : 'false'}
              onChange={() => this.props.setShowDayDividers(!this.props.showDayDividers)}
            />
          </div>
          <div className={styles.reportsLayout__reportParamName}>
            <FormattedMessage {...messages.reports.labels.showDayDividers} />
          </div>
        </div>
      </div>
    );
  }

  renderReportNames() {
    return map(this.props.reportTypes, (reportType) => {
      const isActive = this.state.activeReportType === reportType;
      const isNotPrintable = this.getIsNotPrintable(reportType);

      return (
        <div
          key={reportType}
          className={cn(styles.reportsLayout__reportNameWrapper, {
            [styles['reportsLayout__reportNameWrapper--isActive']]: isActive,
          })}
        >
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <div
            className={cn(styles.reportsLayout__reportName, {
              [styles['reportsLayout__reportName--isActive']]    : isActive,
              [styles['reportsLayout__reportName--notPrintable']]: isNotPrintable,
            })}
            onClick={() => this.scrollIntoView(reportType)}
          >
            <div
              id={reportType}
              className={styles.reportsLayout__reportNameText}
            >
              {this.renderReportName(reportType)}
            </div>
            <div className={styles.reportsLayout__actionIcons}>
              <DownloadIcon onClick={(event) => this.onDownloadFile(event, reportType)} />
              {!isNotPrintable && <PrinterIcon onClick={(event) => this.onPrint(event, reportType)} />}
            </div>
          </div>
          {this.renderReportParams(reportType)}
        </div>
      );
    });
  }


  renderSideBar() {
    return (
      <div>
        <div className={styles.reportsLayout__sideBar}>
          <div className={styles.reportsLayout__sectionTitle}>
            <FormattedMessage {...messages.reports.headers.files} />
          </div>
          <div
            ref={this.reportNamesRef}
            className={styles.reportsLayout__reportNames}
          >
            {this.renderReportNames()}
          </div>
          <div className={styles.reportsLayout__separator} />
          <div className={styles.reportsLayout__sectionTitle}>
            <FormattedMessage {...messages.reports.headers.allFiles} />
          </div>
          {this.renderButtons()}
        </div>
      </div>
    );
  }


  renderContent() {
    return map(this.props.reportTypes, (reportType, index, reportTypes) => {
      const shouldBreakPage = reportTypes.length > 1 && index < reportTypes.length - 1 && !this.state.singleReportPrint;
      const dashboardLayout = PatientResults.constants.DASHBOARD_LAYOUTS[reportType];
      const isNotPrintable = this.getIsNotPrintable(reportType);

      if (this.state.forcePrintState && isNotPrintable) {
        return null;
      }

      return (
        <div
          key={reportType}
          className={cn(styles.reportsLayout__report, {
            [styles['reportsLayout__report--notPrintable']]: isNotPrintable,
            [styles['reportsLayout__report--breakPage']]   : shouldBreakPage,
          })}
        >
          <div ref={this.reportRefs[reportType]}>
            <PatientResults.components.Report
              dashboardLayout={dashboardLayout}
              downloadCsv={this.state.downloadCsv}
              hideEmptyRows={this.props.hideEmptyRows}
              showDayDividers={this.props.showDayDividers}
            />
          </div>
          {shouldBreakPage && <div className={styles.reportsLayout__reportSeparator} />}
        </div>
      );
    });
  }


  renderLoader() {
    if (!this.props.isInProgress) {
      return null;
    }

    return (
      <div className={cn('col col-lg-8', styles.root__container)}>
        <div className={styles.root__loader}>
          <div className={styles.root__loaderBar} />
        </div>
      </div>
    );
  }


  render() {
    return (
      <App.components.LanguageProvider>
        <div className={cn(styles.reportsLayout, 'no-gutters', {
          [styles.busy]: this.props.isInProgress,
        })}
        >
          <App.components.IntlHelmet
            titleMessage={{ ...messages.meta.title }}
            descriptionMessage={{ ...messages.meta.description }}
          />
          {this.renderSideBar()}
          <div
            ref={this.rootRef}
            className={cn(styles.reportsLayout__viewport, {
              [styles['reportsLayout__viewport--print']]: this.state.forcePrintState,
            })}
          >
            {this.renderContent()}
            {this.renderLoader()}
          </div>
        </div>
      </App.components.LanguageProvider>
    );
  }

}


const mapStateToProps = (state) => ({
  windowWidth          : selectors.windowWidth(state),
  hideEmptyRows        : selectors.hideEmptyRows(state),
  showDayDividers      : selectors.showDayDividers(state),
  accountPatientProfile: Account.selectors.patientProfileExtended(state),
  activePatient        : Hcp.selectors.activePatient(state),
  reportTypes          : PatientResults.selectors.reportTypes(state),
  isInProgress         : File.selectors.isDownloadPdfInProgress(state),
  hasErrors            : File.selectors.hasDownloadPdfErrors(state),
});


const mapDispatchToProps = (dispatch) => ({
  setWindowWidth: (windowWidth, shouldSetBaseMenuOpen) => dispatch(
    actions.setWindowWidth(windowWidth, shouldSetBaseMenuOpen),
  ),
  setHideEmptyRows  : (hideEmptyRows) => dispatch(actions.setHideEmptyRows(hideEmptyRows)),
  setShowDayDividers: (showDayDividers) => dispatch(actions.setShowDayDivivers(showDayDividers)),
  onDownloadPdfs    : (pdfReportSources) => dispatch(File.actions.downloadPdfs(pdfReportSources)),
});

const ConnectedReportsLayout = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsLayout);


export default withStyles(styles)(ConnectedReportsLayout);
