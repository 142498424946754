import React from 'react';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import ExclemationMark from 'svg/exclamation-mark.svg';
import messages from '../../messages';
import styles from './WarninginfoCC.pcss';


class WarningInfoCC extends React.PureComponent {

  render() {
    return (
      <div className={styles.warningInfoCC}>
        <div className={styles.warningInfoCC__iconWrapper}>
          <ExclemationMark />
        </div>
        <div className={styles.warningInfoCC__textWrapper}>
          <p className={styles.warningInfoCC__title}>
            <FormattedMessage {...messages.labels.warningInfoCCTitle} />
          </p>
          <p className={styles.warningInfoCC__subTitle}>
            <FormattedMessage {...messages.labels.warningInfoCCSubTitle} />
          </p>
        </div>
      </div>
    );
  }

}

export default withStyles(styles)(WarningInfoCC);
