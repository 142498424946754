import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import delay from 'lodash/delay';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
// import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import defer from 'lodash/defer';
import get from 'lodash/get';
import map from 'lodash/map';
import forOwn from 'lodash/forOwn';
import MetricConversions from 'libs/MetricConversions';
import StatsCalculations from 'libs/StatsCalculations';
import { getNowAsUtc } from 'helpers/datetime';
import { calculateData } from 'helpers/kpi';
import Button from 'components/Form/Button';
import Widget from 'components/Widget';
import Avatar from 'components/Avatar';
import ReadingFlagIcon from 'components/ReadingFlagIcon';
import intlShape from 'shapes/intlShape';
import patientShape from 'shapes/patientShape';
import ArrowDownNegative from 'svg/arrow-down-negative.svg';
import Calendar from 'svg/calendar-bold.svg';
// import Dashboard from 'svg/dashboard.svg';
import App from 'modules/App';
import Account from 'modules/Account';
import AmbulatoryGlucoseProfile from 'modules/AmbulatoryGlucoseProfile';
import CloudDrive from 'modules/CloudDrive';
import { calculateDateRanges, calculateDateRangesFromReadings } from 'helpers/dateRanges';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import MeasurementsModal from '../MeasurementsModal';
import EditPatientModal from '../EditPatientModal';
import MeasurementsTableWidget from '../MeasurementsTableWidget';
import GlucoseLevelDistributionWidget from '../GlucoseLevelDistributionWidget';
import CgmDistributionWidget from '../CgmDistributionWidget';
import MixedDistributionWidget from '../MixedDistributionWidget';
import SummaryTableWidget from '../SummaryTableWidget';
import TrendChartWidget from '../TrendChartWidget';
import ChartControls from '../TrendChartWidget/ChartControls';
// import CgmTrendChartWidget from '../CgmTrendChartWidget';
import AgpTrendChartWidget from '../AgpTrendChartWidget';
// import AgpProfileWidget from '../AgpProfileWidget';
import PrintableAgpReport from '../PrintableAgpReport';
import PrintableAgpBgmReport from '../PrintableAgpBgmReport';
import BloodGlucoseConcentrationReport from '../BloodGlucoseConcentrationReport';
import KPIs from '../KPIs';
import GlucoseConcentrationLevelsWidget from '../GlucoseConcentrationLevelsWidget';
import AdditionalMeasurementsModal from '../AdditionalMeasurementsModal';
import styles from './Results.pcss';


class Results extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const {
      /* eslint-disable react/prop-types */
      patient, phiSet, fromImports, fromImportsRange, readings,
      mode, aggregateBy, groupBy, calculationFormula, standards,
      measurements, isFetchReadingsInProgress, isSyncInProgress,
      relatedData: propsRelatedData, isPreviewResultMode,
      /* eslint-enable react/prop-types */
    } = props;

    if (!isPreviewResultMode) {
      // isSyncInProgress was removed here
      if (!phiSet || isFetchReadingsInProgress) {
        if (state.isInProgress) {
          return null;
        }
        return {
          isInProgress: true,
        };
      }
    }


    const readingsCount = readings.length;
    const snaqImports = get(phiSet, 'imports.snaq');
    const { startDate, endDate, statsStart, statsEnd, flagFilters, statsFlagFilters } = state;
    const days = endDate && startDate ? endDate.diff(startDate, 'days', false) + 1 : 0;
    let daysWithReadings = 0;

    if (
      phiSet === state.phiSet
      && fromImports === state.fromImports
      && readingsCount === state.readingsCount
      && state.start === statsStart
      && state.end === statsEnd
      && mode === state.mode
      && aggregateBy === state.aggregateBy
      && groupBy === state.groupBy
      && calculationFormula === state.calculationFormula
      && flagFilters === statsFlagFilters
      && snaqImports === state.snaqImports
    ) {
      if (!(isFetchReadingsInProgress && isSyncInProgress) && state.isInProgress) {
        return {
          isInProgress: false,
        };
      }
      return null;
    }

    let defaultStartDate;
    let { minDate, maxDate, startDate: sd, endDate: ed } = state;
    if (fromImports && fromImports !== state.fromImports) {
      minDate = fromImportsRange
        // eslint-disable-next-line react/prop-types
        ? moment.unix(fromImportsRange.minResultDate).utc().startOf('day')
        : moment().utc();
      maxDate = fromImportsRange
        // eslint-disable-next-line react/prop-types
        ? moment.unix(fromImportsRange.maxResultDate).utc().endOf('day')
        : moment().utc();

      defaultStartDate = minDate;
      sd = minDate;
      ed = maxDate;
    } else if (isPreviewResultMode) {
      const { defaultStartMoment, minMoment, maxMoment } = calculateDateRangesFromReadings(readings);
      defaultStartDate = defaultStartMoment;
      minDate = minMoment;
      maxDate = maxMoment;
      if (sd === null) {
        sd = defaultStartDate > minDate ? defaultStartDate : minDate;
      }
      if (ed === null) {
        ed = maxMoment;
      }
    } else if (
      (phiSet !== state.phiSet && (!readingsCount || readingsCount !== state.readingsCount))
      || fromImports !== state.fromImports
      || snaqImports !== state.snaqImports
    ) {
      const { defaultStartMoment, minMoment, maxMoment } = calculateDateRanges(phiSet);
      defaultStartDate = defaultStartMoment;
      minDate = minMoment;
      sd = defaultStartDate > minDate ? defaultStartDate : minDate;
      ed = maxDate;
      maxDate = maxMoment;
      ed = maxMoment;
    }
    if (maxDate.diff(minDate, 'months') >= 12) {
      minDate = maxDate.clone().subtract(1, 'years');
    }

    if (ed.diff(sd, 'months') >= 12) {
      sd = ed.clone().subtract(1, 'years');
    }

    const start = +sd.clone().locale('en').format('X');
    const end = +ed.clone().locale('en').format('X');

    if (patient && patient.id !== state.patientId) {
      // eslint-disable-next-line react/prop-types
      props.onClearFromImports();
      // eslint-disable-next-line react/prop-types
      props.onSetMode(constants.MODES[0]);
      return {
        ...calculateData([]),
        patientId          : patient.id,
        phiSet             : null,
        newResultsThreshold: sd,
        fromImports        : null,
        data               : [],
        relatedData        : [],
        snaqImports        : null,
        statsData          : null,
        readingsCount      : 0,
        mode,
        standards,
        measurements,
        aggregateBy,
        groupBy,
        calculationFormula,
        daysWithReadings,
        daysWithoutReadings: days,
        avgReadingsPerDay  : 0,
        minDate,
        maxDate,
        startDate          : sd,
        startDateUnix      : sd.format('X'),
        endDate            : ed,
        endDateUnix        : ed.format('X'),
        start,
        end,
        flagFilters        : [],
        statsFlagFilters   : [],
        isInProgress       : true,
      };
    }

    if (fromImports && fromImports !== state.fromImports) {
      props.onRangeChange(minDate, maxDate);
      return {
        fromImports,
        minDate,
        maxDate,
        startDate: sd,
        endDate  : ed,
        start,
        end,
      };
    }

    const imports = get(phiSet, 'imports') || {};
    let newResultsThreshold = +minDate.clone().locale('en').format('X');
    const today = +getNowAsUtc().startOf('day').locale('en')
      .format('X');
    forOwn(imports, (imp) => {
      if (imp.documents) {
        const lastImport = last(imp.documents);
        if (
          lastImport && !lastImport.devices
          && lastImport.timestamp < today
          && lastImport.timestamp > newResultsThreshold
        ) {
          newResultsThreshold = lastImport.timestamp;
        }
      }
    });

    if (phiSet !== state.phiSet && !state.phiSet) {
      props.onRangeChange(defaultStartDate, maxDate);
      return {
        phiSet,
        newResultsThreshold,
        minDate,
        maxDate,
        startDate: sd,
        endDate  : ed,
        start,
        end,
      };
    }

    const data = filter(
      readings,
      (reading) => (
        reading.timestamp >= start
        && reading.timestamp <= end
        && (isEmpty(flagFilters) || includes(flagFilters, reading.flags))
        && (!fromImports || includes(fromImports, reading.importDocumentId))
      ),
    );

    const relatedData = filter(propsRelatedData, (relatedDataItem) => {
      const { deviceDateTime, dateTime } = relatedDataItem;
      const timestamp = deviceDateTime || dateTime;
      return timestamp >= start && timestamp <= end;
    });

    let statsData = null;
    const daySeconds = 86400;
    for (let i = start; i < end; i += daySeconds) {
      const idx = data.findIndex((reading) => reading.timestamp >= i && reading.timestamp <= i + daySeconds);
      if (idx >= 0) {
        daysWithReadings++;
      }
    }
    const dataCount = data.length;

    if (mode !== 'ALL') {
      const hoursBreakpoints = get(phiSet, 'customRanges') || constants.HOURS_BREAKPOINTS;
      const statsCalculations = new StatsCalculations(
        data,
        props.standards,
        hoursBreakpoints,
      );
      if (mode === 'AGGREGATED') {
        statsData = statsCalculations.aggregate(aggregateBy, calculationFormula);
      } else if (mode === 'GROUPED') {
        statsData = statsCalculations.group(groupBy, calculationFormula);
      }
    }

    let range = null;


    if (!(state.minDate && state.maxDate && state.minDate.isSame(minDate) && state.maxDate.isSame(maxDate))) {
      range = {
        minDate,
        maxDate,
        startDate: sd,
        endDate  : ed,
        start,
        end,
      };
    }

    let progressIndicators = null;
    if (phiSet && readings) {
      progressIndicators = {
        isInProgress: false,
      };
    }
    return {
      ...calculateData(data),
      patient,
      phiSet,
      newResultsThreshold,
      fromImports,
      data,
      relatedData,
      snaqImports,
      statsData,
      dataCount,
      mode,
      aggregateBy,
      groupBy,
      calculationFormula,
      readingsCount,
      daysWithReadings,
      days,
      standards,
      measurements,
      daysWithoutReadings: Math.max(days - daysWithReadings, 0),
      avgReadingsPerDay  : days && (dataCount / days).toFixed(2),
      ...range,
      statsStart         : start,
      statsEnd           : end,
      statsFlagFilters   : flagFilters,
      ...progressIndicators,
    };
  }


  static propTypes = {
    // Explicit props
    isPatientMode      : PropTypes.bool,
    isPreviewResultMode: PropTypes.bool,
    isReadOnly         : PropTypes.bool,
    patient            : patientShape,
    phiSet             : PropTypes.object, // @TODO: shape
    phiSetDocumentId   : PropTypes.string,
    readings           : PropTypes.array, // @TODO: shape
    timeSeriesResources: PropTypes.array,
    standards          : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    clinicSettings        : PropTypes.object,
    highlightedDates      : PropTypes.arrayOf(PropTypes.string),
    renderPatientHeader   : PropTypes.func,
    renderFeaturesActions : PropTypes.func,
    renderContextAlerts   : PropTypes.func,
    highlightedReadings   : PropTypes.array,
    relatedData           : PropTypes.array,
    measurements          : PropTypes.array,
    // Explicit action
    onRangeChange         : PropTypes.func,
    onMeasurementsSuccess : PropTypes.func,
    onUpdatePhiSet        : PropTypes.func,
    onAddNote             : PropTypes.func,
    // Implicit props
    direction             : App.shapes.direction,
    featureToggles        : PropTypes.arrayOf(PropTypes.string),
    activeClinicMembership: Account.shapes.clinicMembership,
    openModalId           : PropTypes.string,
    printMode             : PropTypes.bool,
    formValues            : PropTypes.object,
    measurementsValues    : PropTypes.object,
    metricsUnits          : PropTypes.object, // @TODO: Shape
    accountPatientProfile : PropTypes.object, // @TODO: Shape
    dashboardLayout       : PropTypes.arrayOf(PropTypes.oneOfType(
      [PropTypes.string, PropTypes.arrayOf(PropTypes.string)],
    )).isRequired,
    deviceMode                  : PropTypes.oneOf(constants.DEVICES_MODES),
    intl                        : intlShape,
    highlightedAverageGlucose   : PropTypes.number,
    highlightedStandardDeviation: PropTypes.number,
    calculationFormula          : PropTypes.oneOf([
      ...constants.CALCULATION_FORMULAS,
      ...constants.CALCULATION_FORMULAS_CGM,
    ]),
    resultsIsInProgress    : PropTypes.bool,
    // Implicit actions
    onOpenModal            : PropTypes.func,
    onSetFormValue         : PropTypes.func,
    onSetMeasurementsValue : PropTypes.func,
    onSetMeasurementsValues: PropTypes.func,
    setDashboardLayout     : PropTypes.func,
    selectHighlightedDates : PropTypes.func,
    setResultsInProgress   : PropTypes.func,
    setPrintMode           : PropTypes.func,
    onDumpReportsState     : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.metricConversions = new MetricConversions(props.metricsUnits);
    this.state = {
      patientId                 : null,
      phiSet                    : null,
      newResultsThreshold       : 0,
      fromImports               : [],
      readingsCount             : 0,
      data                      : [],
      relatedData               : [],
      statsData                 : null,
      mode                      : null,
      aggregateBy               : null,
      groupBy                   : null,
      calculationFormula        : null,
      daysWithReadings          : null,
      daysWithoutReadings       : null,
      minDate                   : null,
      maxDate                   : null,
      startDate                 : null,
      endDate                   : null,
      start                     : null,
      end                       : null,
      statsStart                : null,
      statsEnd                  : null,
      activeMeasurementTimestamp: null,
      flagFilters               : [],
      statsFlagFilters          : [],
      isInProgress              : true,
      preMealReadings           : [],
      postMealReadings          : [],
      // isFetchReadingsInProgress : false,
    };
  }


  componentDidUpdate(prevProps, prevState) {
    const { highlightedDates } = this.props;
    if (this.props.resultsIsInProgress !== this.state.isInProgress) {
      this.props.setResultsInProgress(this.state.isInProgress);
    }
    if (
      (this.state.start !== prevState.start || this.state.end !== prevState.end)
      || (this.state.isInProgress !== prevState.isInProgress && !this.state.isInProgress)
    ) {
      const start = moment.unix(this.state.start).format('YYYY-MM-DD');
      const end = moment.unix(this.state.end).subtract(1, 'day').format('YYYY-MM-DD');
      if (start !== highlightedDates[0] || end !== highlightedDates[highlightedDates.length - 1]) {
        // Needed to be called after data loading. For better fix start/end dates should be stored in store
        defer(() => this.props.selectHighlightedDates({ start, end }));
      }
    }
    if (
      prevState.patientId !== this.state.patientId
      || prevState.start !== this.state.start
      || prevState.end !== this.state.end
      || prevState.dataCount !== this.state.dataCount
    ) {
      this.props.onDumpReportsState(this.state);
    }
  }


  componentWillUnmount() {
    this.props.setResultsInProgress(true);
    this.props.setPrintMode(false);
  }


  onAddNote(noteType, payload) {
    payload.start = this.state.start;
    payload.end = this.state.end;
    this.props.onAddNote(noteType, payload);
  }

  /*
    This method is called after delay because if endDate is being entered manually in the input passed value
    is incorrect
   */
  onChangeRange() {
    const { startDate, endDate } = this.state;
    if (!startDate || !endDate) {
      this.setState((prevState) => ({
        startDate: moment.unix(prevState.start).utc(),
        endDate  : moment.unix(prevState.end).utc(),
      }));
      return;
    }
    const start = +startDate.utc().clone().locale('en--account')
      .format('X');
    const end = +endDate.utc().clone().locale('en--account')
      .format('X');
    if (start === this.state.start && end === this.state.end) {
      return;
    }
    this.setState({ start, end });
    this.props.onRangeChange(startDate, endDate);
  }


  onDatesChange(startDate, endDate) {
    this.setState({
      startDate: startDate && startDate.clone().utc().startOf('day'),
      endDate  : endDate && endDate.clone().utc().endOf('day'),
    });
  }


  onToggleFlagFilter(flag) {
    this.setState((prevState) => {
      const { flagFilters } = prevState;
      if (flag === 'All') {
        return { flagFilters: [] };
      }
      if (includes(flagFilters, flag)) {
        return { flagFilters: filter(flagFilters, (ff) => ff !== flag) };
      }
      return { flagFilters: [...flagFilters, flag] };
    });
  }


  get customRanges() {
    return get(this.props.phiSet, 'customRanges') || map(constants.HOURS_BREAKPOINTS, (hb) => [...hb]);
  }


  /* eslint-disable react/no-unstable-nested-components */
  get widgets() {
    const { customRanges } = this;
    const onDatesChange = (startDate, endDate) => {
      this.onDatesChange(startDate, endDate);
      delay(() => this.onChangeRange(startDate, endDate));
    };
    return (
      {
        TrendChartWidget: () => {
          switch (this.props.deviceMode) {
            case 'BGM': return (
              <TrendChartWidget
                renderControls={false}
                key="TrendChartWidget"
                phiSetDocumentId={this.props.phiSetDocumentId}
                phiSet={this.state.phiSet}
                data={this.statsData}
                relatedData={this.state.relatedData}
                timeSeriesResources={this.props.timeSeriesResources}
                measurements={this.props.measurements}
                start={this.state.start}
                end={this.state.end}
                conversion={this.metricConversions.bloodGlucoseConcentration}
                direction={this.props.direction}
                standards={this.props.standards}
                customRanges={customRanges}
                activePointTimestamp={this.state.activeMeasurementTimestamp}
                renderDatePresets={(className) => this.renderDatePresets(className, true)}
                renderFlagFilters={() => this.renderFlagFilters()}
                onSetActivePointTimestamp={
                  (activeMeasurementTimestamp) => this.setState({
                    activeMeasurementTimestamp,
                  })
                }
                onUpdatePhiSet={this.props.onUpdatePhiSet}
                onAddNote={this.props.onAddNote}
                isReadOnly={this.props.isReadOnly}
                isInProgress={this.state.isInProgress}
                highlightedReadings={this.props.highlightedReadings}
              />
            );
            // case 'CGM': return (
            //   <CgmTrendChartWidget
            //     key="CgmTrendChartWidget"
            //     conversion={this.metricConversions.bloodGlucoseConcentration}
            //     direction={this.props.direction}
            //     standards={this.props.standards}
            //     renderDatePresets={(className) => this.renderDatePresets(className, true)}
            //     isInProgress={this.state.isInProgress}
            //     start={this.state.start}
            //     end={this.state.end}
            //     onDatesChange={onDatesChange}
            //   />
            // );
            case 'AGP': return (
              <AgpTrendChartWidget
                key="AgpTrendChartWidget"
                conversion={this.metricConversions.bloodGlucoseConcentration}
                direction={this.props.direction}
                standards={this.props.standards}
                isInProgress={this.state.isInProgress}
                start={this.state.start}
                end={this.state.end}
                onDatesChange={onDatesChange}
                data={this.statsData}
              />
            );
            default: return null;
          }
        },
        ChartControls: () => (
          <Widget
            key="ChartControlsWidget"
            isNoHeader
          >
            <ChartControls
              start={this.state.start}
              end={this.state.end}
              renderDatePresets={(className) => this.renderDatePresets(className, true)}
              renderFlagFilters={() => this.renderFlagFilters()}
              isInProgress={this.state.isInProgress}
            />
          </Widget>
        ),
        MeasurementsTableWidget: () => (
          <MeasurementsTableWidget
            key="MeasurementsTableWidget"
            phiSet={this.props.phiSet}
            conversion={this.metricConversions.bloodGlucoseConcentration}
            standards={this.props.standards}
            readings={this.state.data}
            timeSeriesResources={this.props.timeSeriesResources}
            relatedData={this.state.relatedData}
            customRanges={customRanges}
            activeReadingTimestamp={this.state.activeMeasurementTimestamp}
            headerMessage={messages.headers.measurementsTable}
            isInProgress={this.state.isInProgress}
            onCellClick={
              (activeMeasurementTimestamp) => this.setState({
                activeMeasurementTimestamp,
              })
            }
          />
        ),
        MeasurementsTableWidgetWithoutMyDownloads: () => (
          <MeasurementsTableWidget
            key="MeasurementsTableWidgetWithoutMyDownloads"
            phiSet={this.props.phiSet}
            conversion={this.metricConversions.bloodGlucoseConcentration}
            standards={this.props.standards}
            readings={this.state.data}
            timeSeriesResources={this.props.timeSeriesResources}
            relatedData={this.state.relatedData}
            customRanges={customRanges}
            activeReadingTimestamp={this.state.activeMeasurementTimestamp}
            headerMessage={messages.headers.measurementsTable}
            isInProgress={this.state.isInProgress}
            isMyDownloadsHidden
            onCellClick={
              (activeMeasurementTimestamp) => this.setState({
                activeMeasurementTimestamp,
              })
            }
          />
        ),
        GlucoseLevelDistributionWidget: () => (
          <GlucoseLevelDistributionWidget
            key="GlucoseLevelDistributionWidget"
            flagFilters={this.state.flagFilters}
            standards={this.props.standards}
            readings={this.state.data}
            isInProgress={this.state.isInProgress}
            onAddNote={this.props.onAddNote ? (noteType, payload) => this.onAddNote(noteType, payload) : null}
          />
        ),
        // AgpProfileWidget: () => (
        //   <AgpProfileWidget
        //     key="AgpProfileWidget"
        //     conversion={this.metricConversions.bloodGlucoseConcentration}
        //     standards={this.props.standards}
        //     start={this.state.start}
        //     end={this.state.end}
        //     isInProgress={this.state.isInProgress}
        //     onDatesChange={onDatesChange}
        //   />
        // ),
        CgmDistributionWidget: () => (
          <CgmDistributionWidget
            key="CgmDistributionWidget"
            conversion={this.metricConversions.bloodGlucoseConcentration}
            standards={this.props.standards}
          />
        ),
        MixedDistributionWidget: () => (
          <MixedDistributionWidget
            key="MixedDistributionWidget"
            conversion={this.metricConversions.bloodGlucoseConcentration}
            standards={this.props.standards}
            flagFilters={this.state.flagFilters}
            readings={this.state.data}
            isInProgress={this.state.isInProgress}
          />
        ),
        SummaryTableWidget: () => (
          <SummaryTableWidget
            key="SummaryTableWidget"
            conversion={this.metricConversions.bloodGlucoseConcentration}
            standards={this.props.standards}
            patientId={this.state.patientId}
            readings={this.state.data}
            customRanges={customRanges}
            isInProgress={this.state.isInProgress}
          />
        ),
        ContentBar        : () => this.renderContentBar(),
        KPIs              : () => this.renderFeatures(),
        PatientHeader     : () => this.renderPrintableHeader(),
        PrintableAgpReport: () => (
          <PrintableAgpReport
            key="PrintableAgpReport"
            conversion={this.metricConversions.bloodGlucoseConcentration}
            standards={this.props.standards}
            firstName={get(this.props, 'accountPatientProfile.firstName', '')}
            lastName={get(this.props, 'accountPatientProfile.lastName', '')}
          />
        ),
        BloodGlucoseConcentrationReport: () => (
          <BloodGlucoseConcentrationReport
            key="BloodGlucoseConcentrationReport"
            conversion={this.metricConversions.bloodGlucoseConcentration}
            standards={this.props.standards}
            calculationFormula={this.props.calculationFormula}
            phiSet={this.props.phiSet}
            accountPatientProfile={{ ...this.props.accountPatientProfile, ...this.props.patient }}
            customRanges={customRanges}
          />
        ),
        GlucoseConcentrationLevelsWidget: () => (
          <GlucoseConcentrationLevelsWidget
            conversion={this.metricConversions.bloodGlucoseConcentration}
            standards={this.props.standards}
            readings={this.state.data}
            isInProgress={this.state.isInProgress}
            onAddNote={this.props.onAddNote ? (noteType, payload) => this.onAddNote(noteType, payload) : null}
          />
        ),
        DaysSelector: () => (
          <Widget
            key="DaysSelectorWidget"
            headerMessage={messages.headers.dailyGlucoseProfile}
          >
            <AmbulatoryGlucoseProfile.components.DailyGlucoseProfile
              conversion={this.metricConversions.bloodGlucoseConcentration}
              standards={this.props.standards}
              direction={this.props.direction}
              onDatesChange={onDatesChange}
              data={this.props.readings}
              glucoseConcentrationLevelBottomValues={constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES}
              isInProgress={this.state.isInProgress}
              patient={this.props.patient}
              phiSet={this.props.phiSet}
              phiSetDocumentId={this.props.phiSetDocumentId}
            />
          </Widget>
        ),
        PrintableAgpBgmReport: () => (
          <PrintableAgpBgmReport
            key="PrintableAgpBgmReport"
            conversion={this.metricConversions.bloodGlucoseConcentration}
            standards={this.props.standards}
            firstName={get({ ...this.props.accountPatientProfile, ...this.props.patient }, 'firstName', '')}
            lastName={get({ ...this.props.accountPatientProfile, ...this.props.patient }, 'lastName', '')}
            readings={this.state.data}
            preMealReadings={this.state.preMealReadings}
            postMealReadings={this.state.postMealReadings}
            strictPreMealReadings={this.state.strictPreMealReadings}
            strictPostMealReadings={this.state.strictPostMealReadings}
            daysWithReadings={this.state.daysWithReadings}
            daysWithoutReadings={this.state.daysWithoutReadings}
            readingsWithoutFlag={this.state.readingsWithoutFlag}
            glucoseConcentrationLevelBottomValues={constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES}
          />
        ),
      });
  }


  get statsData() {
    if (this.state.statsData) {
      return this.state.statsData;
    }
    return this.state.data;
  }


  get isPatientMode() {
    return !!this.props.isPatientMode;
  }


  get isPrintMode() {
    return this.props.printMode;
  }


  renderFeatures() {
    const { standards, phiSet, clinicSettings } = this.props;
    const {
      data,
      preMealReadings,
      postMealReadings,
      daysWithReadings,
      daysWithoutReadings,
      isInProgress,
      readingsWithoutFlag,
    } = this.state;

    return (
      <KPIs
        key="KPIs"
        readings={data}
        preMealReadings={preMealReadings}
        postMealReadings={postMealReadings}
        standards={standards}
        clinicSettings={clinicSettings}
        conversion={this.metricConversions.bloodGlucoseConcentration}
        daysWithReadings={daysWithReadings}
        daysWithoutReadings={daysWithoutReadings}
        isInProgress={isInProgress}
        readingsWithoutFlag={readingsWithoutFlag}
        onAddNote={this.props.onAddNote ? (noteType, payload) => this.onAddNote(noteType, payload) : null}
        phiSet={phiSet}
      />
    );
  }


  renderPrintablePatient() {
    const { firstName, lastName } = this.props.patient || {};
    const { firstName: accountFirstName, lastName: accountLastName } = this.props.accountPatientProfile || {};
    return (
      <div className="col">
        <div className={cn('row align-items-center', styles.reportInfo)}>
          {
            this.isPatientMode || firstName || lastName || accountFirstName || accountLastName
              ? (
                <div className="col">
                  <p className={cn('text--h3', styles.reportInfo__label)}>
                    <FormattedMessage {...messages.report.info} />
                  </p>
                  <p className="text--h1 mb-2">
                    <span>
                      <FormattedMessage {...messages.report.for} />
                      <br />
                    </span>
                    {
                      this.isPatientMode
                        ? `${accountFirstName} ${accountLastName}`
                        : `${firstName} ${lastName}`
                    }
                  </p>
                  <div className={styles.reportInfo__time}>
                    <FormattedMessage {...messages.report.generated} />
                    { ' ' }
                    { moment().format('l') }
                  </div>
                </div>
              )
              : null
          }
        </div>
      </div>
    );
  }

  /*
    renderHeaderToggles() {
      // @TODO: Check if this method is obsolete
      if (!this.props.featureToggles.includes(App.constants.FEATURE_TOGGLES.featureCgm)) {
        return null;
      }
      const { setDashboardLayout, dashboardLayout } = this.props;
      const { isInProgress } = this.state;
      return (
        <div className={styles.topBar}>
          <div className={cn(styles.topBar__left, { fadingLoader: isInProgress })}>
            <Button
              styleModifier="transparent"
              className={
                cn(
                  styles.topBar__button,
                  {
                    [styles['topBar__button--active']]: isEqual(dashboardLayout, constants.DASHBOARD_LAYOUTS.basic),
                  },
                )
              }
              isDisabled={isInProgress}
              onClick={() => setDashboardLayout(constants.DASHBOARD_LAYOUTS.basic)}
            >
              <Dashboard />
              <FormattedMessage {...messages.buttons.standard} />
            </Button>
            <Button
              styleModifier="transparent"
              className={
                cn(
                  styles.topBar__button,
                  {
                    [styles['topBar__button--active']]: isEqual(dashboardLayout, constants.DASHBOARD_LAYOUTS.agp),
                  },
                )
              }
              isDisabled={isInProgress}
              onClick={() => setDashboardLayout(constants.DASHBOARD_LAYOUTS.agp)}
            >
              <Dashboard />
              <FormattedMessage {...messages.buttons.agpcgm} />
            </Button>
          </div>
        </div>
      );
    }
 */


  renderHeader() {
    const { isInProgress } = this.state;
    return (
      <div className="widget widget--noHeader">
        <div className="widget__content">
          <div className={styles.headerWrapper}>
            <div className={styles.header}>
              <div className={cn('row align-items-center', styles.patientHeader)}>
                { this.props.renderPatientHeader(isInProgress) }
              </div>
            </div>
            { /* this.renderHeaderToggles() */ }
          </div>
        </div>
      </div>
    );
  }


  renderPrintableHeader() {
    const { startDate, endDate, days } = this.state;
    const { activeClinicMembership } = this.props;
    const clinic = get(activeClinicMembership, 'clinic');
    return (
      <div>
        {
          !this.isPatientMode && clinic
          && (
            <Widget isNoHeader className={styles.printableClinicDetailsWrapper}>
              <div className="row">
                <div className={styles.printableClinicDetails}>
                  <Avatar imgClassName={styles.printableClinicDetails__logo} avatarImg={clinic.logo} />
                  <p className={cn('text--normal', styles.printableClinicDetails__info)}>
                    { clinic.name }<br />
                    { clinic.street }<br />
                    { clinic.zipCode } { clinic.city }
                  </p>
                </div>
              </div>
            </Widget>
          )
        }
        <Widget isNoHeader>
          <div className="row align-items-center">
            <div className="col-4">{ this.renderPrintablePatient() }</div>
            <div className="col-8 align-items-center">
              <div className={styles.printableHeaderFeatures}>
                { this.renderFeatures() }
              </div>
              <div className={styles.printableHeaderRow}>
                <div className={styles.printableHeaderRow__date}>
                  <h3 className="text--h3">
                    { startDate && startDate.format('ll') } - { endDate && endDate.format('ll') }
                  </h3>
                  <h4 className="text--h3">
                    (<FormattedMessage {...messages.presets.days} values={{ number: days }} />)
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </Widget>
      </div>
    );
  }


  renderRangeIcon(isInProgress) {
    return (
      <Calendar
        className={
          cn('DateRangePickerInput_calendarIcon_svg DateRangePickerInput_calendarIcon_svg_1', {
            'DateRangePickerInput_calendarIcon_svg--inactive': isInProgress,
          })
        }
        focusable="false"
      />
    );
  }


  renderRangePickerNav(isNext = false) {
    return (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <div
        role="button"
        tabIndex="0"
        className={
          `DayPickerNavigation_button DayPickerNavigation_button__default
        DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontalDefault
        DayPickerNavigation_${isNext ? 'right' : 'left'}Button__horizontalDefault`
        }
      >
        <ArrowDownNegative className={`DayPickerNavigation_button__${isNext ? 'next' : 'prev'}`} />
      </div>
    );
  }


  renderDatePresets(className, isStandalone = false, withDataPicker = true) {
    const { minDate, maxDate, newResultsThreshold, isInProgress } = this.state;

    const onChange = (startDate, endDate) => {
      this.onDatesChange(startDate, endDate);
      if (!isStandalone) {
        return;
      }
      delay(() => this.onChangeRange(startDate, endDate), 100);
    };

    const newResults = moment.unix(newResultsThreshold).utc();
    const endOfDay = getNowAsUtc().endOf('day');

    return (
      <div className={cn('btn-group', styles.chartControls__filters, styles.chartControls__wrapper, className)}>
        {
          map(constants.DATE_RANGE_PRESETS, ({ number, unit }) => {
            const presetStartDate = maxDate && maxDate.clone().subtract(number, unit).add(1, 'day');
            const startDate = minDate && (presetStartDate.isAfter(minDate) ? presetStartDate : minDate);
            return (
              <Button
                key={`${unit}${number}`}
                styleModifier="quinary"
                className={
                  cn('btn--filled', {
                    'text--primary': this.state.startDate
                    && this.state.endDate
                    && startDate.isSame(this.state.startDate, 'day')
                    && maxDate.isSame(this.state.endDate, 'day'),
                  })
                }
                labelMessage={messages.presets[unit]}
                labelMessageValues={{ number }}
                isDisabled={isInProgress}
                onClick={() => onChange(startDate, maxDate)}
              />
            );
          })
        }
        <Button
          styleModifier="quinary"
          labelMessage={messages.presets.all}
          className={
            cn('btn--filled', {
              'text--primary': this.state.startDate
              && this.state.endDate
              && minDate.isSame(this.state.startDate, 'day')
              && maxDate.isSame(this.state.endDate, 'day'),
            })
          }
          isDisabled={isInProgress}
          onClick={() => onChange(minDate, maxDate)}
        />
        {
          isStandalone
          && (
            <Button
              styleModifier="quinary"
              labelMessage={messages.presets.newResults}
              className={
                cn('btn--filled', {
                  'text--primary': this.state.startDate
                  && this.state.endDate
                  && newResults.isSame(this.state.startDate, 'day')
                  && endOfDay.isSame(this.state.endDate, 'day'),
                })
              }
              isDisabled={isInProgress}
              onClick={() => onChange(newResults, endOfDay)}
            />
          )
        }
        <div className={styles.buttonsSeparator}>
          <div />
        </div>
        { withDataPicker && this.renderRangePicker() }
      </div>
    );
  }


  renderFlagFilters() {
    const { flagFilters, isInProgress } = this.state;
    return (
      <div className={cn('btn-group', styles.chartControls__filters, styles.chartControls__wrapper)}>
        {
          map(App.messages.flags, (flagMessage, flag) => (
            <Button
              key={flag}
              styleModifier="quinary"
              className={
                cn('btn--filled', {
                  'btn--active': (isEmpty(flagFilters) && flag === 'All') || includes(flagFilters, flag),
                })
              }
              data={
                {
                  'data-for': 'globalTooltip',
                  'data-tip': this.props.intl.formatMessage(flagMessage),
                }
              }
              isDisabled={isInProgress}
              onClick={() => this.onToggleFlagFilter(flag)}
            >
              <ReadingFlagIcon flag={flag} className="btn__icon" />
            </Button>
          ))
        }
      </div>
    );
  }


  renderRangePicker() {
    const { minDate, maxDate, isInProgress } = this.state;
    let initialVisibleMonth = () => maxDate && maxDate.clone().subtract(1, 'months');
    if (this.state.endDate) {
      initialVisibleMonth = () => this.state.endDate.clone().subtract(1, 'months');
    }
    return (
      <DateRangePicker
        startDate={this.state.startDate}
        startDateId="startDate"
        endDate={this.state.endDate}
        endDateId="endDate"
        focusedInput={this.state.focusedInput}
        anchorDirection={this.props.direction === 'rtl' ? 'left' : 'right'}
        isRTL={this.props.direction === 'rtl'}
        navPrev={this.renderRangePickerNav()}
        navNext={this.renderRangePickerNav(true)}
        customInputIcon={this.renderRangeIcon(isInProgress)}
        customArrowIcon={<span>-</span>}
        renderCalendarInfo={() => this.renderDatePresets('DateRangePicker_presets', false, false)}
        minimumNights={0}
        isOutsideRange={
          (date) => {
            date.utc();
            return !minDate || !maxDate || date < minDate || date > maxDate;
          }
        }
        initialVisibleMonth={initialVisibleMonth}
        disabled={!maxDate || isInProgress}
        hideKeyboardShortcutsPanel
        noBorder
        onDatesChange={({ startDate, endDate }) => this.onDatesChange(startDate, endDate)}
        onFocusChange={(focusedInput) => this.setState({ focusedInput })}
        onClose={({ startDate, endDate }) => delay(() => this.onChangeRange(startDate, endDate), 100)}
      />
    );
  }


  renderContentBar() {
    return (
      <Fragment key="ContentHeader">
        <div className={styles.alertsContainer}>
          { this.props.renderContextAlerts && this.props.renderContextAlerts() }
        </div>
      </Fragment>
    );
  }


  renderWidgets() {
    if (!process.env.BROWSER || !this.props.standards) {
      return null;
    }
    const { dashboardLayout } = this.props;
    return map(dashboardLayout, (widget) => {
      if (Array.isArray(widget)) {
        return (
          <div className="row" key={`${widget.toString()}`}>
            {
              map(widget, (element) => {
                const items = element.split(';');
                return (
                  <div
                    key={`item-${items[1]}`}
                    className={items[0]}
                  >
                    { (this.widgets[items[1]] ? this.widgets[items[1]]() : null) }
                  </div>
                );
              })
            }
          </div>
        );
      }
      return (this.widgets[widget] ? this.widgets[widget]() : null);
    });
  }


  renderEditPatientModal() {
    if (this.props.isReadOnly || this.isPatientMode) {
      return null;
    }

    return (
      <EditPatientModal
        metricConversions={this.metricConversions}
        patient={this.props.patient}
        phiSet={this.props.phiSet}
        phiSetDocumentId={this.props.phiSetDocumentId}
        isPatientMode={this.isPatientMode}
        onSuccess={this.props.onMeasurementsSuccess}
        standards={this.props.standards}
      />
    );
  }


  renderMeasurementsModal() {
    if (!this.isPatientMode) {
      return null;
    }

    return (
      <MeasurementsModal
        metricConversions={this.metricConversions}
        patient={this.props.patient}
        phiSet={this.props.phiSet}
        phiSetDocumentId={this.props.phiSetDocumentId}
        isPatientMode={this.isPatientMode}
        onSuccess={this.props.onMeasurementsSuccess}
        standards={this.props.standards}
      />
    );
  }


  render() {
    return (
      <div>
        <div className={cn('widgets', { 'widgets--noGutters': this.isPrintMode })}>
          { !this.isPrintMode && this.renderHeader() }
          { this.renderWidgets() }
        </div>
        { this.renderMeasurementsModal() }
        { this.renderEditPatientModal() }
        <AdditionalMeasurementsModal />
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  mode                        : selectors.mode(state),
  deviceMode                  : selectors.deviceMode(state),
  fromImports                 : selectors.fromImports(state),
  fromImportsRange            : selectors.fromImportsRange(state),
  aggregateBy                 : selectors.aggregateBy(state),
  groupBy                     : selectors.groupBy(state),
  calculationFormula          : selectors.calculationFormula(state),
  dashboardLayout             : selectors.dashboardLayout(state),
  resultsIsInProgress         : selectors.resultsIsInProgress(state),
  direction                   : App.selectors.direction(state),
  printMode                   : App.selectors.printMode(state),
  openModalId                 : App.selectors.modal(state),
  formValues                  : App.selectors.formSelector(constants.RESULTS_SETTINGS_FORM)(state),
  measurementsValues          : App.selectors.formSelector(constants.MEASUREMENTS_FORM)(state),
  featureToggles              : App.selectors.featureToggles(state),
  metricsUnits                : Account.selectors.metricsUnits(state),
  accountPatientProfile       : Account.selectors.patientProfileExtended(state),
  activeClinicMembership      : Account.selectors.activeClinicMembership(state),
  highlightedDates            : AmbulatoryGlucoseProfile.selectors.highlightedDates(state),
  highlightedAverageGlucose   : AmbulatoryGlucoseProfile.selectors.highlightedAverageGlucose(state),
  highlightedStandardDeviation: AmbulatoryGlucoseProfile.selectors.highlightedStandardDeviation(state),
  isFetchPhiSetInProgress     : CloudDrive.selectors.isFetchPhiSetInProgress(state),
  isFetchReadingsInProgress   : CloudDrive.selectors.isFetchReadingsInProgress(state),
});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.RESULTS_SETTINGS_FORM;
  const measurementsForm = constants.MEASUREMENTS_FORM;

  return {
    onOpenModal            : (modalId) => dispatch(App.actions.openModal(modalId)),
    onSetFormValue         : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onFormErrors           : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onSetMeasurementsValue : (input) => dispatch(App.actions.setFormValue(measurementsForm, input)),
    onSetMeasurementsValues: (values) => dispatch(App.actions.setFormValues(measurementsForm, values)),
    setPrintMode           : (printMode) => dispatch(App.actions.setPrintMode(printMode)),
    onDumpReportsState     : (reportsState) => dispatch(actions.dumpReportsState(reportsState)),
    onClearFromImports     : () => dispatch(actions.clearFromImports()),
    onSetMode              : (mode) => dispatch(actions.setMode(mode)),
    setDashboardLayout     : (layout) => dispatch(actions.setDashboardLayout(layout)),
    selectHighlightedDates : (range) => dispatch(AmbulatoryGlucoseProfile.actions.selectHighlightedDates(range)),
    setResultsInProgress   : (isInProgress) => dispatch(actions.setResultsInProgress(isInProgress)),
  };
};


const ConnectedResults = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Results));


export default withStyles(styles)(ConnectedResults);
