import React, { FC, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useAction } from 'hooks';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import LaunchDownloaderInstaller from '../LaunchDownloaderInstaller';
import styles from '../../components/DownloadDataModal/DownloadDataModal.pcss';


interface Props {
  onSetComponent: (componentName: string) => void,
}


const useStatusChange = (onSetComponent: (componentName: string) => void) => {
  const downloader = useSelector(selectors.downloader);
  const status: DownloaderStatus = useSelector(selectors.status);
  const initStatus = useRef<DownloaderStatus>(status);

  useEffect(() => {
    if (status !== initStatus.current) {
      switch (status) {
        case constants.DOWNLOADER_STATUSES.NO_CONNECTION: {
          if (downloader.mdaVersion) {
            onSetComponent('NoConnection');
          } else {
            onSetComponent('InstallDownloader');
          }
          break;
        }
        case constants.DOWNLOADER_STATUSES.TIMEOUT: {
          onSetComponent('Timeout');
          break;
        }
        default: {
          onSetComponent('InstalledDownloader');
        }
      }
    }
  }, [status]);
};


const InstallingDownloader: FC<Props> = ({ onSetComponent }) => {
  const isInProgress = useSelector(selectors.isInstallDownloaderInProgress);
  const cancelInstalling = useAction(actions.installDownloaderCancel);

  useEffect(() => () => {
    if (isInProgress) {
      cancelInstalling();
    }
  }, []);
  useStatusChange(onSetComponent);

  return (
    <div
      className={styles.modal__content}
      role="presentation"
    >
      <h3 className="modal__subheader "><FormattedMessage {...messages.installingDownloader.header} /></h3>
      <p className="modal__info"><FormattedMessage {...messages.installingDownloader.info} /></p>
      <div className={styles.illustration}>
        <img src="/assets/svg/loader.svg" className="rotatingLoader" alt="" />
      </div>
      <p className="modal__info text--center mb-5"><FormattedMessage {...messages.installingDownloader.waiting} /></p>
      <LaunchDownloaderInstaller />
    </div>
  );
};

export default InstallingDownloader;
