import includes from 'lodash/includes';
import { createSelector } from 'reselect';
import * as actionTypes from './actionTypes';
import * as constants from './constants';


/**
 * Input selectors
 */
const mainSelector = (state) => state[constants.MODULE_ID] || {};
const fetchingSelector = (state) => state[constants.MODULE_ID].fetching;
const sendingSelector = (state) => state[constants.MODULE_ID].sending;
const errorsSelector = (state) => state[constants.MODULE_ID].errors;

/**
 * Selects the ongoing visit
 */
export const activeVisit = createSelector(
  mainSelector,
  (state) => state.activeVisit,
);

/**
 * Selects patient of the ongoing visit
 */
export const activeVisitPatientId = createSelector(
  mainSelector,
  (state) => state.activeVisitPatientId,
);

/**
 * Selects enhanced visits
 */
export const visits = createSelector(
  mainSelector,
  (state) => state.visits,
);

//----------------------------------------------------------------------------------------------------------------------

export const isAddVisitInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.ADD_VISIT),
);

export const hasAddVisitErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.ADD_VISIT),
);

//----------------------------------------------------------------------------------------------------------------------

export const isEndVisitInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.END_VISIT),
);

export const hasEndVisitErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.END_VISIT),
);

//----------------------------------------------------------------------------------------------------------------------

export const isFetchVisitsMetadataInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_VISIT_METADATA),
);

export const hasFetchVisitsMetadataErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.FETCH_VISIT_METADATA),
);
