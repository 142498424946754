import React from 'react';
import { injectIntl } from 'react-intl';
import intlShape from 'shapes/intlShape';
import messages from '../../messages';
import Error from './Error';


class NotFound extends React.PureComponent {

  static propTypes = {
    intl: intlShape,
  };


  render() {
    const { intl } = this.props;
    return (
      <Error
        code={404}
        codeLabel={intl.formatMessage(messages.errorPage.errorCode)}
        title={intl.formatMessage(messages.errorPage.notFound.title)}
        info={intl.formatMessage(messages.errorPage.notFound.info)}
        buttonLabel={intl.formatMessage(messages.errorPage.goToHomePage)}
      />
    );
  }

}

export default injectIntl(NotFound);
