import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import messages from 'modules/PatientResults/messages';
import styles from './BgmValuesInRangesReportChart.pcss';


class BgmValuesInRangesReportChart extends React.PureComponent {

  static propTypes = {
    // Explicit props
    conversion  : PropTypes.object.isRequired,
    timeInRanges: PropTypes.arrayOf(PropTypes.shape({
      name   : PropTypes.string.isRequired,
      count  : PropTypes.number.isRequired,
      percent: PropTypes.number.isRequired,
      range  : PropTypes.string.isRequired,
    })),
  };


  render() {
    const { timeInRanges } = this.props;
    const veryHighRange = timeInRanges.find(({ name }) => name === 'veryHigh');
    const veryLowRange = timeInRanges.find(({ name }) => name === 'veryLow');
    const filteredTimeInRanges = timeInRanges.filter((range) => range !== veryHighRange && range !== veryLowRange);
    return (
      <div className={styles.bgmValuesInRangeChart}>
        <div className="legend__header">
          <div className="legend__headerPrecentage">
            <FormattedMessage {...messages.labels.ofTotal} />
          </div>
          <div className="legend__headerChecks">
            <FormattedMessage {...messages.labels.checks} />
          </div>
        </div>
        <div className="wrapper">
          <div className="barChart">
            {
              this.props.timeInRanges.map((timeInRange) => (
                <div
                  className={
                    cn(
                      'barChart__section',
                      `barChart__section--${timeInRange.name}`,
                      { 'barChart__section--transparent': !timeInRange.percent },
                    )}
                  style={{ height: `${timeInRange.percent || 0}%` }}
                  key={timeInRange.name}
                />
              ))
            }
          </div>
          <div className="legend">
            <div
              className="legend__section legend__section--first"
            >
              <div className="legend__sectionLabels">
                <span className="legend__sectionLabelName">
                  <FormattedMessage {...messages.labels[veryHighRange.name]} />
                </span>
                <span className="legend__sectionLabelTarget">
                    (<>{veryHighRange.range} {this.props.conversion.unitSymbol}</>)
                </span>
                <div className="legend__divider" />
                <span className="legend__sectionLabelPercent">
                  {veryHighRange.percent ? `${veryHighRange.percent}%` : '0%'}
                </span>
                <div className="legend__dividerBeforeCount" />
                <span className="legend__sectionLabelTime">
                  {veryHighRange.count}
                </span>
              </div>
            </div>
            <div
              className="legend__section"
              style={veryHighRange.percent ? { height: `${veryHighRange.percent || 0}%` } : { height: 'auto' }}
            />
            {
              filteredTimeInRanges.map((timeInRange) => (
                <div
                  className="legend__section"
                  style={timeInRange.percent ? { height: `${timeInRange.percent || 0}%` } : { height: 'auto' }}
                  key={timeInRange.name}
                >
                  <div className="legend__sectionLabels">
                    <span className="legend__sectionLabelName">
                      <FormattedMessage {...messages.labels[timeInRange.name]} />
                    </span>
                    <span className="legend__sectionLabelTarget">
                      (<>{timeInRange.range} {this.props.conversion.unitSymbol}</>)
                    </span>
                    <div className="legend__divider" />
                    <span className="legend__sectionLabelPercent">
                      {timeInRange.percent ? `${timeInRange.percent}%` : '0%'}
                    </span>
                    <div className="legend__dividerBeforeCount" />
                    <span className="legend__sectionLabelTime">
                      {timeInRange.count}
                    </span>
                  </div>
                </div>
              ))
            }
            <div
              className="legend__section"
              style={veryLowRange.percent ? { height: `${veryLowRange.percent || 0}%` } : { height: 'auto' }}
            />
            <div
              className="legend__section legend__section--last"
            >
              <div className="legend__sectionLabels">
                <span className="legend__sectionLabelName">
                  <FormattedMessage {...messages.labels[veryLowRange.name]} />
                </span>
                <span className="legend__sectionLabelTarget">
                      (<>{veryLowRange.range} {this.props.conversion.unitSymbol}</>)
                </span>
                <div className="legend__divider" />
                <span className="legend__sectionLabelPercent">
                  {veryLowRange.percent ? `${veryLowRange.percent}%` : '0%'}
                </span>
                <div className="legend__dividerBeforeCount" />
                <span className="legend__sectionLabelTime">
                  {veryLowRange.count}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}


export default withStyles(styles)(BgmValuesInRangesReportChart);
