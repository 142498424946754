import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import map from 'lodash/map';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import orderBy from 'lodash/orderBy';
import camelCase from 'lodash/camelCase';
import find from 'lodash/find';
import ceil from 'lodash/ceil';
import * as constants from '../constants';
import messages from '../messages';


const filterResults = (results, type) => {
  const targetResults = filter(results, { type });
  return orderBy(targetResults, ['timestamp'], ['desc']);
};


const getBloodPressureResults = (results, metricsConversions) => {
  const systolicBloodPressureResults = filterResults(results, constants.RESULT_TYPE.SYSTOLIC_BLOOD_PRESSURE);
  const diastolicBloodPressureResults = filterResults(results, constants.RESULT_TYPE.DIASTOLIC_BLOOD_PRESSURE);
  return map(systolicBloodPressureResults, (systolicBloodPressureResult) => {
    const { value, timestamp } = systolicBloodPressureResult;
    const diastolicBloodPressureResult = find(diastolicBloodPressureResults, { timestamp });
    const resultMoment = moment.unix(timestamp);
    return {
      value: `${value}/${diastolicBloodPressureResult.value}`,
      unit : metricsConversions.pressure.unitSymbol,
      date : resultMoment.format('L'),
      time : resultMoment.format('LT'),
    };
  });
};


const getBmiResults = (results) => {
  const heightResults = filterResults(results, constants.RESULT_TYPE.HEIGHT);
  const weightResults = filterResults(results, constants.RESULT_TYPE.WEIGHT);
  const bmiResults = [];
  forEach(heightResults, (heightResult) => {
    const { value, timestamp } = heightResult;
    const weightResult = find(weightResults, { timestamp });
    if (weightResult) {
      const resultMoment = moment.unix(timestamp);
      bmiResults.push({
        value: ceil(10000 * weightResult.value / (value * value), 1),
        unit : '',
        date : resultMoment.format('L'),
        time : resultMoment.format('LT'),
      });
    }
  });
  return bmiResults;
};


const getConversion = (type, metricsConversions) => {
  switch (type) {
    case constants.RESULT_TYPE.DIASTOLIC_BLOOD_PRESSURE:
    case constants.RESULT_TYPE.SYSTOLIC_BLOOD_PRESSURE:
      return metricsConversions.pressure;
    case constants.RESULT_TYPE.LDL:
      return metricsConversions.cholesterol;
    case constants.RESULT_TYPE.TRIGLYCERIDES:
      return metricsConversions.triglycerides;
    default:
      return null;
  }
};


export const getUnit = (type, metricsConversions) => {
  const conversion = getConversion(type, metricsConversions);
  return conversion.unitSymbol;
};


export const getEmptyResult = () => ({
  value: <FormattedMessage {...messages.labels.noData} />,
  unit : null,
  date : '-',
  time : '-',
});


const getValue = (value, type, metricsConversions) => {
  const conversion = getConversion(type, metricsConversions);
  if (conversion.unit === 'MMOL_L') {
    return conversion.toDisplay(value);
  }
  return value;
};


const mapResults = (results, type, metricsConversions) => {
  if (!results) {
    return [];
  }
  return map(results, (result) => {
    const { value, timestamp } = result;
    const resultMoment = moment.unix(timestamp);
    return {
      value: getValue(value, type, metricsConversions),
      unit : getUnit(type, metricsConversions),
      date : resultMoment.format('L'),
      time : resultMoment.format('LT'),
    };
  });
};


export const getLabelForResultType = (type) => {
  const messageId = messages.labels[camelCase(type)];
  return <FormattedMessage {...messageId} />;
};


export const getResultsOfType = (results, type, metricsConversions) => {
  if (!results) {
    return [];
  }
  switch (type) {
    case constants.RESULT_TYPE.BLOOD_PRESSURE:
      return getBloodPressureResults(results, metricsConversions);
    case constants.RESULT_TYPE.BMI:
      return getBmiResults(results);
    default:
      return mapResults(filterResults(results, type), type, metricsConversions);
  }
};


export const getTimestamp = (dateTimestamp, time) => {
  const timeSplit = map(time.split(':'), Number);
  return +dateTimestamp + (3600 * timeSplit[0]) + (60 * timeSplit[1]) + timeSplit[2];
};
