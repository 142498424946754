import { registerAction, unregisterAction } from 'helpers/reducerTools';
import * as actionTypes from './actionTypes';


const initialState = {
  information            : null,
  informationTemplate    : null,
  consentsTemplate       : null,
  organizationMemberships: [],
  fetching               : [],
  sending                : [],
  errors                 : [],
};


export default function reducer(state = { ...initialState }, action) {

  switch (action.type) {

    case actionTypes.CHECK_INFORMATION: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.CHECK_INFORMATION),
        errors  : unregisterAction(state.errors, actionTypes.CHECK_INFORMATION),
      };
    }
    case actionTypes.CHECK_INFORMATION_SUCCESS: {
      const { information, informationTemplate, consentsTemplate } = action.payload;
      return {
        ...state,
        information,
        informationTemplate,
        consentsTemplate,
        fetching: unregisterAction(state.fetching, actionTypes.CHECK_INFORMATION),
      };
    }
    case actionTypes.CHECK_INFORMATION_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.CHECK_INFORMATION),
        errors  : registerAction(state.errors, actionTypes.CHECK_INFORMATION),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.UPDATE_INFORMATION: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.UPDATE_INFORMATION),
        errors : unregisterAction(state.errors, actionTypes.UPDATE_INFORMATION),
      };
    }
    case actionTypes.UPDATE_INFORMATION_SUCCESS: {
      const { informationUpdate } = action.payload;
      const information = { ...state.information, ...informationUpdate };
      return {
        ...state,
        information,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_INFORMATION),
      };
    }
    case actionTypes.UPDATE_INFORMATION_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_INFORMATION),
        errors : registerAction(state.errors, actionTypes.UPDATE_INFORMATION),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.CHECK_ORGANIZATION_MEMBERSHIPS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.CHECK_ORGANIZATION_MEMBERSHIPS),
        errors  : unregisterAction(state.errors, actionTypes.CHECK_ORGANIZATION_MEMBERSHIPS),
      };
    }
    case actionTypes.CHECK_ORGANIZATION_MEMBERSHIPS_SUCCESS: {
      const { organizationMemberships } = action.payload;
      return {
        ...state,
        organizationMemberships,
        fetching: unregisterAction(state.fetching, actionTypes.CHECK_ORGANIZATION_MEMBERSHIPS),
      };
    }
    case actionTypes.CHECK_ORGANIZATION_MEMBERSHIPS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.CHECK_ORGANIZATION_MEMBERSHIPS),
        errors  : registerAction(state.errors, actionTypes.CHECK_ORGANIZATION_MEMBERSHIPS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    default: {
      return state;
    }

  }
}
