export const MODULE_ID = 'ambulatoryGlucoseProfile';

export const DAYS_SELECTOR_ZOOM = {
  day  : 'Daily',
  week : 'Weekly',
  month: 'Monthly',
};

export const DAYS_SELECTOR_ENTITIES_PER_PAGE = {
  day  : 2 * 7,
  week : 2 * 4,
  month: 2 * 4,
};

export const MODES = ['ALL', 'GROUPED'];

export const BGM_REPORT_FOOTER
  = 'Patent pending – HealthPartners Institute dba International Diabetes Center – All Rights Reserved © 2020';
