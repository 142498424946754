import { buffers, eventChannel, END } from 'redux-saga';
import each from 'lodash/each';
import get from 'lodash/get';
import startsWith from 'lodash/startsWith';


/**
 * Files download channels factory
 *
 * @param baseUrl - base path to build absolute adressess from relative file paths
 * @returns {function(fileUrl): Channel<any>}
 */
export default function createDownloadFileChannel(baseUrl) {
  return (fileUrl) => eventChannel((emitter) => {
    const xhr = new XMLHttpRequest();

    const onSuccess = () => {
      emitter({ success: true });
      emitter(END);
    };

    const onFailure = (json) => {
      emitter({ success: false, response: json, error: get(json, 'error') });
      emitter(END);
    };

    xhr.addEventListener('error', onFailure);
    xhr.addEventListener('abort', onFailure);
    xhr.onreadystatechange = () => {
      const { readyState, status, response } = xhr;
      if (readyState === 4) {
        if (status === 200) {
          const headers = xhr.getAllResponseHeaders().trim().split(/[\r\n]+/);
          const headersMap = {};
          each(headers, (line) => {
            const parts = line.split(': ');
            const header = parts.shift();
            const value = parts.join(': ');
            headersMap[header] = value;
          });

          const type = get(headersMap, 'content-type', 'application/octet-stream');
          const disposition = get(headersMap, 'content-disposition');
          let name = '';
          if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              name = matches[1].replace(/['"]/g, '');
            }
          }

          // IE and Edge
          if (response != null && navigator.msSaveBlob) {
            navigator.msSaveBlob(new Blob([response], { type }), name);
            onSuccess();
            return;
          }

          const blob = new Blob([response], { type });
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = name;
          a.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }, 100);
          onSuccess();
        } else {
          const json = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(response)));
          onFailure(json);
        }
      }
    };

    xhr.open('GET', startsWith(fileUrl, '/api') ? `${baseUrl}${fileUrl}` : fileUrl);
    // @TODO: Authorization
    xhr.responseType = 'arraybuffer';
    xhr.send();
    return () => {
      xhr.removeEventListener('error', onFailure);
      xhr.removeEventListener('abort', onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
}
