import PropTypes from 'prop-types';


export const schema = PropTypes.arrayOf(PropTypes.shape({
  key         : PropTypes.string.isRequired,
  labelMessage: PropTypes.oneOfType([
    PropTypes.shape({ id: PropTypes.string.isRequired }),
    PropTypes.string,
  ]),
  renderer: PropTypes.func,
}));
