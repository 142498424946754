import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { useAction } from 'hooks';
import Button from 'components/Form/Button';
import QuestionMark from 'svg/question-mark.svg';
import * as actions from '../../../actions';
import messages from '../../../messages';
import styles from '../DownloadDataModal.pcss';


interface ImageProps {
  selectedChannel?: string,
}

interface Props {
  selectedChannel?: string,
  onSetComponent: (componentName: string) => void,
}


const ConnectionImage: FC<ImageProps> = ({ selectedChannel }) => {
  let imageSrc: string;
  switch (selectedChannel) {
    case 'BuiltInUsb':
      imageSrc = '/assets/svg/disconnect-by-usb.svg';
      break;
    case 'MicroUsb':
      imageSrc = '/assets/svg/disconnect-by-usb-cable.svg';
      break;
    case 'UsbC':
      imageSrc = '/assets/svg/disconnect-by-usb-c-cable.svg';
      break;
    case 'BlueCable':
      imageSrc = '/assets/svg/disconnect-by-blue-cable.svg';
      break;
    case 'Bluetooth':
      imageSrc = '/assets/svg/disconnect-by-bluetooth.svg';
      break;
    default:
      imageSrc = '/assets/svg/connection-failed.svg';
      break;
  }

  return (
    <div className={styles.illustration}>
      <img src={imageSrc} className={styles.connectionFailed__img} alt="" />
    </div>
  );
};


const MdaConnectionFailed: FC<Props> = ({ selectedChannel, onSetComponent }) => {
  const { formatMessage } = useIntl();
  const clearConnection = useAction(actions.clearConnection);

  const onRetry = () => {
    clearConnection();
    onSetComponent('Connect');
  };

  return (
    <div className={styles.modal__content}>
      <h3 className="modal__subheader "><FormattedMessage {...messages.connectionFailed.header} /></h3>
      <p className="modal__info"><FormattedMessage {...messages.connectionFailed.info} /></p>
      <ul className={styles.connectionFailed__list}>
        <li className={styles.connectionFailed__list__item}>
          <div className={styles.connectionFailed__list__item__content}>
            <p><FormattedMessage {...messages.connectionFailed.steps.first} /></p>
          </div>
        </li>
        <li className={styles.connectionFailed__list__item}>
          <div className={styles.connectionFailed__list__item__content}>
            <p><FormattedMessage {...messages.connectionFailed.steps.second} />
              <a
                href="https://support.glucocontro.online/hc"
                target="_blank"
                data-for="userBoxTooltip"
                data-tip={formatMessage(messages.connectionFailed.questionSteps.second)}
                aria-label={formatMessage(messages.connectionFailed.questionSteps.second)}
              >
                <QuestionMark className={styles.connectionFailed__questionMark} />
              </a>
            </p>
          </div>
        </li>
        <li className={styles.connectionFailed__list__item}>
          <div className={styles.connectionFailed__list__item__content}>
            <p><FormattedMessage {...messages.connectionFailed.steps.third} /></p>
          </div>
        </li>
      </ul>
      <ConnectionImage {...{ selectedChannel }} />
      <div className="modal__actions">
        <Button
          styleModifier="primary"
          labelMessage={messages.buttons.tryAgain}
          className="btn--block btn--filled"
          onClick={onRetry}
        />
      </div>
      <ReactTooltip
        id="userBoxTooltip"
        place="right"
        type="dark"
        effect="solid"
      />
    </div>
  );
};

export default MdaConnectionFailed;
