import * as actionTypes from './actionTypes';


export const fetchPatients = (clinicMembership) => ({
  type   : actionTypes.FETCH_PATIENTS,
  payload: {
    clinicMembership,
  },
});

export const fetchPatientsSuccess = (patients, profilesDocumentId) => ({
  type   : actionTypes.FETCH_PATIENTS_SUCCESS,
  payload: {
    patients,
    profilesDocumentId,
  },
});

export const fetchPatientsError = (error) => ({
  type: actionTypes.FETCH_PATIENTS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchEnrollingSharingRequests = (clinicMembership) => ({
  type   : actionTypes.FETCH_ENROLLING_SHARING_REQUESTS,
  payload: {
    clinicMembership,
  },
});

export const fetchEnrollingSharingRequestsSuccess = (enrollingSharingRequests) => ({
  type   : actionTypes.FETCH_ENROLLING_SHARING_REQUESTS_SUCCESS,
  payload: {
    enrollingSharingRequests,
  },
});

export const fetchEnrollingSharingRequestsError = (error) => ({
  type: actionTypes.FETCH_ENROLLING_SHARING_REQUESTS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const addPatient = (patientValues, clinicMembership) => ({
  type   : actionTypes.ADD_PATIENT,
  payload: {
    patientValues,
    clinicMembership,
  },
});

export const addPatientSuccess = (updatedPatients, profilesDocumentId, clinicPatient) => ({
  type   : actionTypes.ADD_PATIENT_SUCCESS,
  payload: {
    updatedPatients,
    profilesDocumentId,
    clinicPatient,
  },
});

export const addPatientError = (error) => ({
  type: actionTypes.ADD_PATIENT_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const enrollPatient = (patientValues, sharingRequest, clinicMembership) => ({
  type   : actionTypes.ENROLL_PATIENT,
  payload: {
    patientValues,
    sharingRequest,
    clinicMembership,
  },
});

export const enrollPatientTransition = (sharingRequest) => ({
  type   : actionTypes.ENROLL_PATIENT_TRANSITION,
  payload: {
    sharingRequest,
  },
});

export const enrollPatientSuccess = (clinicPatient) => ({
  type   : actionTypes.ENROLL_PATIENT_SUCCESS,
  payload: {
    clinicPatient,
  },
});

export const enrollPatientError = (error, sharingRequest = null) => ({
  type   : actionTypes.ENROLL_PATIENT_ERROR,
  payload: {
    sharingRequest,
  },
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const updatePatient = (patient, newPatientValues, clinicMembership) => ({
  type   : actionTypes.UPDATE_PATIENT,
  payload: {
    patient,
    newPatientValues,
    clinicMembership,
  },
});

export const updatePatientSuccess = (updatedPatients = null, profilesDocumentId = null, clinicPatient = null) => ({
  type   : actionTypes.UPDATE_PATIENT_SUCCESS,
  payload: {
    updatedPatients,
    profilesDocumentId,
    clinicPatient,
  },
});

export const updatePatientError = (error) => ({
  type: actionTypes.UPDATE_PATIENT_ERROR,
  error,
});


//----------------------------------------------------------------------------------------------------------------------

export const previewPatientOpenTab = (deviceData, clinicSlug, organizationUID) => ({
  type   : actionTypes.PREVIEW_PATIENT_OPEN_TAB,
  payload: {
    deviceData,
    clinicSlug,
    organizationUID,
  },
});


export const previewPatientOpenTabSuccess = () => ({
  type   : actionTypes.PREVIEW_PATIENT_OPEN_TAB_SUCCESS,
  payload: {

  },
});


export const previewPatientOpenTabError = (error) => ({
  type: actionTypes.PREVIEW_PATIENT_OPEN_TAB_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const startPatientVisit = (clinicPatient, phiSet, phiSetDocumentId, clinicMembership) => ({
  type   : actionTypes.START_PATIENT_VISIT,
  payload: {
    clinicPatient,
    phiSet,
    phiSetDocumentId,
    clinicMembership,
  },
});

export const startPatientVisitSuccess = () => ({
  type: actionTypes.START_PATIENT_VISIT_SUCCESS,
});

export const startPatientVisitError = (error) => ({
  type: actionTypes.START_PATIENT_VISIT_SUCCESS,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const endPatientVisit = (clinicPatient, phiSet, clinicMembership, visit, sharingRequest) => ({
  type   : actionTypes.END_PATIENT_VISIT,
  payload: {
    clinicPatient,
    phiSet,
    clinicMembership,
    visit,
    sharingRequest,
  },
});

export const endPatientVisitSuccess = () => ({
  type: actionTypes.END_PATIENT_VISIT_SUCCESS,
});

export const endPatientVisitError = (error) => ({
  type: actionTypes.END_PATIENT_VISIT_SUCCESS,
  error,
});


//----------------------------------------------------------------------------------------------------------------------

export const mergePatient = (patient, destinationPatient, sharingRequest, clinicMembership) => ({
  type   : actionTypes.MERGE_PATIENT,
  payload: {
    patient,
    destinationPatient,
    sharingRequest,
    clinicMembership,
  },
});

export const mergePatientSuccess = () => ({
  type: actionTypes.MERGE_PATIENT_SUCCESS,
});

export const mergePatientError = (error) => ({
  type: actionTypes.MERGE_PATIENT_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const removePatient = (patient, clinicMembership) => ({
  type   : actionTypes.REMOVE_PATIENT,
  payload: {
    patient,
    clinicMembership,
  },
});

export const removePatientSuccess = (updatedPatients, profilesDocumentId) => ({
  type   : actionTypes.REMOVE_PATIENT_SUCCESS,
  payload: {
    updatedPatients,
    profilesDocumentId,
  },
});

export const removePatientError = (error) => ({
  type: actionTypes.REMOVE_PATIENT_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const reassignHcp = (patient, newClinicMembership, clinicMembership) => ({
  type   : actionTypes.REASSIGN_HCP,
  payload: {
    patient,
    newClinicMembership,
    clinicMembership,
  },
});

export const reassignHcpSuccess = () => ({
  type: actionTypes.REASSIGN_HCP_SUCCESS,
});

export const reassignHcpError = (error) => ({
  type: actionTypes.REASSIGN_HCP_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const activatePatient = (patient, clinicMembership) => ({
  type   : actionTypes.ACTIVATE_PATIENT,
  payload: {
    patient,
    clinicMembership,
  },
});

export const activatePatientSuccess = (activePatient, sharingRequest) => ({
  type   : actionTypes.ACTIVATE_PATIENT_SUCCESS,
  payload: {
    activePatient,
    sharingRequest,
  },
});

export const activatePatientError = (error) => ({
  type: actionTypes.ACTIVATE_PATIENT_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const deactivatePatient = () => ({
  type: actionTypes.DEACTIVATE_PATIENT,
});

//----------------------------------------------------------------------------------------------------------------------

export const setPatientsSearch = (forId, phrase) => ({
  type   : actionTypes.SET_PATIENTS_SEARCH,
  payload: {
    forId,
    phrase,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const setClinicsSearch = (forId, phrase) => ({
  type   : actionTypes.SET_CLINICS_SEARCH,
  payload: {
    forId,
    phrase,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const setPhiSet = (phiSet = null, phiSetDocumentId = null, phiSetPatientId = null) => ({
  type   : actionTypes.SET_PHI_SET,
  payload: {
    phiSet,
    phiSetDocumentId,
    phiSetPatientId,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const setReadings = (
  phiSet, readings, batchesIndex, cgmReadings, cgmBatchesIndex, relatedData, timeSeriesResources, measurements, measurementsBatchesIndex
) => ({
  type   : actionTypes.SET_READINGS,
  payload: {
    phiSet,
    batchesIndex,
    readings,
    cgmReadings,
    cgmBatchesIndex,
    relatedData,
    timeSeriesResources,
    measurements,
    measurementsBatchesIndex,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const setMeasurements = (phiSet, phiSetDocumentId, phiSetPatientId, measurements, measurementsBatchesIndex) => ({
  type   : actionTypes.SET_MEASUREMENTS,
  payload: {
    phiSet,
    phiSetDocumentId,
    phiSetPatientId,
    measurements,
    measurementsBatchesIndex,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const setImportedReadings = (phiSet, phiSetDocumentId, phiSetReferenceKey, readings, cgmReadings) => ({
  type   : actionTypes.SET_IMPORTED_READINGS,
  payload: {
    phiSet,
    phiSetDocumentId,
    phiSetReferenceKey,
    readings,
    cgmReadings,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const setTimeSeriesResources = (phiSetReferenceKey, timeSeriesResources) => ({
  type   : actionTypes.SET_TIME_SERIES_RESOURCES,
  payload: {
    phiSetReferenceKey,
    timeSeriesResources,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const setNotes = (phiSet, phiSetDocumentId, notes, notesBatchesIndex = []) => ({
  type   : actionTypes.SET_NOTES,
  payload: {
    phiSet,
    phiSetDocumentId,
    notes,
    notesBatchesIndex,
  },
});

export const clearNotesBatches = () => ({
  type: actionTypes.CLEAR_NOTES_BATCHES,
});

//----------------------------------------------------------------------------------------------------------------------

export const setReceivedSharingRequest = (sharingRequest) => ({
  type   : actionTypes.SET_RECEIVED_SHARING_REQUEST,
  payload: {
    sharingRequest,
  },
});

export const unsetReceivedSharingRequest = () => ({
  type: actionTypes.UNSET_RECEIVED_SHARING_REQUEST,
});

//----------------------------------------------------------------------------------------------------------------------

export const createSharingRequest = (
  clinicHcpMembershipId,
  invitationCode,
  patientEmailAddress,
  encryptedClinicPatientProfileId,
  clinicPatient,
  clinicPatientHealthData = {},
) => ({
  type   : actionTypes.CREATE_SHARING_REQUEST,
  payload: {
    clinicHcpMembershipId,
    invitationCode,
    patientEmailAddress,
    encryptedClinicPatientProfileId,
    clinicPatient,
    clinicPatientHealthData,
  },
});

export const createSharingRequestSuccess = (sharingRequest) => ({
  type   : actionTypes.CREATE_SHARING_REQUEST_SUCCESS,
  payload: {
    sharingRequest,
  },
});

export const createSharingRequestError = () => ({
  type: actionTypes.CREATE_SHARING_REQUEST_ERROR,
});

//----------------------------------------------------------------------------------------------------------------------

export const removeSharingRequest = (sharingRequest) => ({
  type   : actionTypes.REMOVE_SHARING_REQUEST,
  payload: {
    sharingRequest,
  },
});

export const removeSharingRequestSuccess = (sharingRequest) => ({
  type   : actionTypes.REMOVE_SHARING_REQUEST_SUCCESS,
  payload: {
    sharingRequest,
  },
});

export const removeSharingRequestError = (error) => ({
  type: actionTypes.REMOVE_SHARING_REQUEST_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const resendSharingRequest = (
  sharingRequest
) => ({
  type   : actionTypes.RESEND_SHARING_REQUEST,
  payload: {
    sharingRequest,
  },
});

export const resendSharingRequestSuccess = () => ({
  type: actionTypes.RESEND_SHARING_REQUEST_SUCCESS,
});

export const resendSharingRequestError = (error) => ({
  type: actionTypes.RESEND_SHARING_REQUEST_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const checkSharingRequestApprove = (invitationCode, successAction) => ({
  type   : actionTypes.CHECK_SHARING_REQUEST_APPROVE,
  payload: {
    invitationCode,
    successAction,
  },
});

export const checkSharingRequestApproveSuccess = () => ({
  type: actionTypes.CHECK_SHARING_REQUEST_APPROVE_SUCCESS,
});

export const checkSharingRequestApproveError = () => ({
  type: actionTypes.CHECK_SHARING_REQUEST_APPROVE_ERROR,
});

export const stopCheckSharingRequestApprove = () => ({
  type: actionTypes.STOP_CHECK_SHARING_REQUEST_APPROVE,
});

//----------------------------------------------------------------------------------------------------------------------

export const bindSharingRequestWithClinicPatient = (sharingRequest, clinicPatient, clinicMembership) => ({
  type   : actionTypes.BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT,
  payload: {
    sharingRequest,
    clinicPatient,
    clinicMembership,
  },
});

export const bindSharingRequestWithClinicPatientSuccess = (clinicPatient) => ({
  type   : actionTypes.BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT_SUCCESS,
  payload: {
    clinicPatient,
  },
});

export const bindSharingRequestWithClinicPatientError = (error) => ({
  type: actionTypes.BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const setPatientDataComparison = ({ personalDataDifferences, healthDataDifferences } = {}) => ({
  type   : actionTypes.SET_PATIENT_DATA_COMPARISON,
  payload: {
    personalDataDifferences,
    healthDataDifferences,
  },
});

export const setIsPatientDataFullComparison = (isPatientDataFullComparison = false) => ({
  type   : actionTypes.SET_IS_PATIENT_DATA_FULL_COMPARISON,
  payload: {
    isPatientDataFullComparison,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const startSyncQueue = () => ({
  type: actionTypes.START_SYNC_QUEUE,
});

export const stopSyncQueue = () => ({
  type: actionTypes.STOP_SYNC_QUEUE,
});


export const sync = ({
  activePatient,
  phiSet,
  phiSetDocumentId,
  phiSetReferenceKey,
  phiSetPatientId,
  sharingRequest,
  activeClinicMembership,
  standards,
  storageProvider,
  createPhiSetAction,
  successAction,
  successRelatedDataAction,
  accessToken,
} = {}, onlyCloudDrive = false) => ({
  type   : actionTypes.SYNC,
  payload: {
    activePatient,
    phiSet,
    phiSetDocumentId,
    phiSetReferenceKey,
    phiSetPatientId,
    sharingRequest,
    storageProvider,
    activeClinicMembership,
    standards,
    createPhiSetAction,
    successAction,
    successRelatedDataAction,
    accessToken,
    onlyCloudDrive,
  },
});

export const syncFinish = () => ({
  type: actionTypes.SYNC_FINISH,
});

//----------------------------------------------------------------------------------------------------------------------

export const storePatientInfo = ({
  patient,
  patientHealthData,
  newPatientValues,
  clinicMembership,
} = {}) => ({
  type   : actionTypes.STORE_PATIENT_INFO,
  payload: {
    patient,
    patientHealthData,
    newPatientValues,
    clinicMembership,
  },
});

export const storePatientInfoSuccess = () => ({
  type: actionTypes.STORE_PATIENT_INFO_SUCCESS,
});

export const storePatientInfoError = () => ({
  type: actionTypes.STORE_PATIENT_INFO_ERROR,
});

//----------------------------------------------------------------------------------------------------------------------

export const storeAndPushNote = (
  notes,
  phiSet,
  phiSetDocumentId,
  phisetVisitId,
  sharingRequest,
  { phiSetReferenceKey, storageProvider, accessToken } = {},
  clinicMembership,
) => ({
  type   : actionTypes.STORE_AND_PUSH_NOTE,
  payload: {
    notes,
    phiSet,
    phiSetDocumentId,
    phiSetReferenceKey,
    phisetVisitId,
    sharingRequest,
    storageProvider,
    accessToken,
    clinicMembership,
  },
});

export const storeAndPushNoteSuccess = () => ({
  type: actionTypes.STORE_AND_PUSH_NOTE_SUCCESS,
});

export const storeAndPushNoteError = (error) => ({
  type: actionTypes.STORE_AND_PUSH_NOTE_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const setImportedRelatedData = (phiSet, phiSetDocumentId, phiSetReferenceKey, relatedData) => ({
  type   : actionTypes.SET_IMPORTED_RELATED_DATA,
  payload: {
    phiSet,
    phiSetDocumentId,
    phiSetReferenceKey,
    relatedData,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchPatientCountryLocalization = (countryId) => ({
  type   : actionTypes.FETCH_PATIENT_COUNTRY_LOCALIZATION,
  payload: {
    countryId,
  },
});

export const fetchPatientCountryLocalizationSuccess = ({
  countrySettings,
  informationTemplate,
  patientCustomIdentifiers,
  clinicPatientTemplate,
}) => ({
  type   : actionTypes.FETCH_PATIENT_COUNTRY_LOCALIZATION_SUCCESS,
  payload: {
    countrySettings,
    informationTemplate,
    patientCustomIdentifiers,
    clinicPatientTemplate,
  },
});

export const fetchPatientCountryLocalizationError = (error) => ({
  type: actionTypes.FETCH_PATIENT_COUNTRY_LOCALIZATION_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchPayers = (countryId) => ({
  type   : actionTypes.FETCH_PAYERS,
  payload: {
    countryId,
  },
});

export const fetchPayersSuccess = (payers) => ({
  type   : actionTypes.FETCH_PAYERS_SUCCESS,
  payload: {
    payers,
  },
});

export const fetchPayersError = (error) => ({
  type: actionTypes.FETCH_PAYERS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const addPatientToFavorite = (patient, clinicMembership) => ({
  type   : actionTypes.ADD_PATIENT_TO_FAVORITE,
  payload: {
    patient,
    clinicMembership,
  },
});

export const addPatientToFavoriteSuccess = (patient) => ({
  type   : actionTypes.ADD_PATIENT_TO_FAVORITE_SUCCESS,
  payload: {
    patient,
  },
});

export const addPatientToFavoriteError = (error) => ({
  type: actionTypes.ADD_PATIENT_TO_FAVORITE_ERROR,
  error,
});
