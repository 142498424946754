import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import FormContainerAbstract from 'components/FormContainerAbstract';
import CheckboxRadio from 'components/Form/CheckboxRadio';
import Button from 'components/Form/Button';
import Form from 'components/Form';
import FormGroup from 'components/Form/FormGroup';
import Modal from 'components/Modal';
import Account from 'modules/Account';
import App from 'modules/App';
import messages from '../../messages';
import * as constants from '../../constants';
import styles from '../ModalCommon.pcss';


class NotificationsSettingsModal extends FormContainerAbstract {

  static getDerivedStateFromProps(props, state) {
    const { isInProgress, hasErrors } = props;

    if (isInProgress !== state.isInProgress && isInProgress) {
      return { isInProgress: true };
    }
    if (hasErrors && state.isInProgress) {
      return { isInProgress: false };
    }
    return null;
  }


  static propTypes = {
    // Implicit props
    notificationsSettings: PropTypes.object,
    isInProgress         : PropTypes.bool,
    hasErrors            : PropTypes.bool,
    formValues           : PropTypes.object,
    openModalId          : PropTypes.string,
    isClientInitialized  : PropTypes.bool,
    // Implicit actions
    onCloseModal         : PropTypes.func,
    onClearForm          : PropTypes.func,
    openModalHistory     : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      isInProgress: false,
    };
  }


  componentDidUpdate(prevProps) {
    const { isInProgress, hasErrors } = this.props;
    if (prevProps.isInProgress !== isInProgress && !isInProgress && !hasErrors) {
      this.onClose();
    }
    if (
      (this.props.openModalId
        && prevProps.openModalId !== this.props.openModalId
        && this.props.openModalId === constants.NOTIFICATIONS_SETTINGS_MODAL)
    ) {
      this.onPopulate();
    }
  }


  onPopulate() {
    if (process.env.BROWSER && !this.props.isClientInitialized) {
      return;
    }

    this.onSetValues(
      mapValues(
        this.props.notificationsSettings,
        (item) => item.push.toString(),
      ),
    );
  }


  async onClose() {
    await this.props.onCloseModal();
    this.props.onClearForm();
    this.setState({ isInProgress: false });
  }


  onSubmit() {
    this.props.onFormProcessing();
    const values = get(this.props.formValues, 'values');
    this.props.onSubmit(values);
  }


  renderActions() {
    return (
      <div className="row">
        <div className="col-6">
          <Button
            styleModifier="primary"
            labelMessage={messages.buttons.openNotificationsHistory}
            className="btn--block"
            isInProgress={this.state.isInProgress}
            onClick={this.props.openModalHistory}
          />
        </div>
        <div className="col-6">
          <Button
            styleModifier="primary"
            labelMessage={messages.buttons.saveChanges}
            className="btn--block btn--filled"
            isInProgress={this.state.isInProgress}
            type="submit"
          />
        </div>
      </div>
    );
  }


  renderNotification(notificationName) {
    const { notificationsSettings } = this.props;
    const notification = notificationsSettings[notificationName];

    const name = get(this.props.localizationResources, [notification.key, 'value'], notification.key);
    return (
      <FormGroup
        key={`${notification.key}-notification-checkbox`}
        id={notificationName}
        formValues={this.props.formValues}
      >
        <CheckboxRadio
          inputValue="true"
          value={notification ? notification.push.toString() : 'false'}
          onChange={(input) => this.onSetValue(input)}
          labelMessage={name}
        />
      </FormGroup>
    );
  }


  renderNotifications() {
    const { notificationsSettings } = this.props;
    return (
      <div>
        {
          Object.keys(notificationsSettings).map(
            (notification) => (
              <div key={`${notification}-notification`}>{ this.renderNotification(notification) }</div>
            ),
          )
        }
      </div>
    );
  }


  render() {
    const { notificationsSettings } = this.props;
    if (!notificationsSettings) {
      return null;
    }
    return (
      <Modal
        modalId={constants.NOTIFICATIONS_SETTINGS_MODAL}
        openModalId={this.props.openModalId}
        styleModifier="md"
        headerMessage={messages.headers.notificationsSettings}
        onClose={this.props.onCloseModal}
      >
        <h2 className={styles['content-header']}>
          <FormattedMessage {...messages.headers.notifyMeWhen} />
        </h2>
        <Form className={styles.form} onSubmit={() => this.onSubmit()}>
          <div className={styles.container}>
            { this.renderNotifications() }
          </div>
          { this.renderActions() }
        </Form>
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  openModalId          : App.selectors.modal(state),
  notificationsSettings: Account.selectors.notificationsSettings(state),
  localizationResources: App.selectors.localizationResources(state),
  formValues           : App.selectors.formSelector(constants.NOTIFICATIONS_SETTINGS_FORM)(state),
  isClientInitialized  : App.selectors.isClientInitialized(state),
  isInProgress         : Account.selectors.isUpdateNotificationsSettingsInProgress(state),
  hasError             : Account.selectors.hasUpdateNotificationsSettingsErrors(state),
});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.NOTIFICATIONS_SETTINGS_FORM;

  return {
    onSubmit: (notificationsSettings) => dispatch(
      Account.actions.updateNotificationsSettings(notificationsSettings),
    ),
    onCloseModal    : () => dispatch(App.actions.closeModal()),
    openModalHistory: () => dispatch(App.actions.openModal(constants.NOTIFICATIONS_HISTORY_MODAL)),
    onSetFormValue  : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onSetFormValues : (values) => dispatch(App.actions.setFormValues(formName, values)),
    onFormErrors    : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onFormProcessing: () => dispatch(App.actions.startFormProcessing(formName)),
    onClearForm     : () => dispatch(App.actions.clearForm(formName)),
  };
};


const ConnectedNotificationsSettingsModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsSettingsModal);


export default withStyles(styles)(ConnectedNotificationsSettingsModal);
