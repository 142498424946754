import * as actionTypes from './actionTypes';


//----------------------------------------------------------------------------------------------------------------------

export const setAgpDailyRecords = (dailyRecords) => ({
  type   : actionTypes.SET_AGP_DAILY_RECORDS,
  payload: { dailyRecords },
});

//----------------------------------------------------------------------------------------------------------------------

export const setHighlightedDate = (date) => ({
  type   : actionTypes.SET_HIGHLIGHTED_DATE,
  payload: date,
});

export const setHighlightedDates = (dates) => ({
  type   : actionTypes.SET_HIGHLIGHTED_DATES,
  payload: dates,
});

export const resetHighlightedDate = (date) => ({
  type   : actionTypes.RESET_HIGHLIGHTED_DATE,
  payload: date,
});

export const clearHighlightedDates = () => ({
  type: actionTypes.CLEAR_HIGHLIGHTED_DATES,
});

export const selectHighlightedDates = (range) => ({
  type   : actionTypes.SELECT_HIGHLIGHTED_DATES,
  payload: range,

});

//----------------------------------------------------------------------------------------------------------------------

export const setDaysSelectorZoom = (zoom) => ({
  type   : actionTypes.SET_DAYS_SELECTOR_ZOOM,
  payload: { zoom },
});

export const setDaysSelectorZoomSmartPage = (zoom) => ({
  type   : actionTypes.SET_DAYS_SELECTOR_ZOOM_SMART_PAGE,
  payload: { zoom },
});

export const setDaysSelectorPage = (page) => ({
  type   : actionTypes.SET_DAYS_SELECTOR_PAGE,
  payload: { page },
});

//----------------------------------------------------------------------------------------------------------------------

export const setAgpMonthSelectorPage = (page, activePatient, phiSet, phiSetDocumentId, successAction) => ({
  type   : actionTypes.SET_AGP_MONTH_SELECTOR_PAGE,
  payload: { page, activePatient, phiSet, phiSetDocumentId, successAction },
});

//----------------------------------------------------------------------------------------------------------------------

export const setPreview = (preview) => ({
  type   : actionTypes.SET_PREVIEW,
  payload: { preview },
});

export const setAgpPreview = (preview) => ({
  type   : actionTypes.SET_AGP_PREVIEW,
  payload: { preview },
});

export const prepareAgpPreview = (activePatient, phiSet, phiSetDocumentId, successAction) => ({
  type   : actionTypes.PREPARE_AGP_PREVIEW,
  payload: { activePatient, phiSet, phiSetDocumentId, successAction },
});

//----------------------------------------------------------------------------------------------------------------------

export const appendAggregations = ({
  aggregations,
  aggregationDocumentsIds,
}) => ({
  type   : actionTypes.APPEND_AGGREGATIONS,
  payload: {
    aggregations,
    aggregationDocumentsIds,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const setShowMedian = (showMedian) => ({
  type   : actionTypes.SET_SHOW_MEDIAN,
  payload: {
    showMedian,
  },
});

export const setShowIQR = (showIQR) => ({
  type   : actionTypes.SET_SHOW_IQR,
  payload: {
    showIQR,
  },
});

export const setShowP10P90 = (showP10P90) => ({
  type   : actionTypes.SET_SHOW_P10P90,
  payload: {
    showP10P90,
  },
});
