import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import keysIn from 'lodash/keysIn';
import last from 'lodash/last';
import map from 'lodash/map';
import mapKeys from 'lodash/mapKeys';
import times from 'lodash/times';
import filter from 'lodash/filter';
import valuesIn from 'lodash/valuesIn';
import startsWith from 'lodash/startsWith';
import compact from 'lodash/compact';
import { AppContext } from 'context';
import Validator from 'libs/Validator';
import { compactObject } from 'helpers/transformers';
import Form from 'components/Form';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import Button from 'components/Form/Button';
import App from 'modules/App';
import * as actions from '../../actions';
import * as constants from '../../constants';
import messages from '../../messages';
import styles from './InviteFamilyMembersForm.pcss';
import validatorRules from './validatorRules.json';


class InviteFamilyMembersForm extends React.PureComponent {

  static contextType = AppContext;


  static propTypes = {
    // Explicit props
    persons         : PropTypes.number,
    isInProgress    : PropTypes.bool,
    renderActions   : PropTypes.func,
    // Implicit props
    formValues      : PropTypes.object,
    // Implicit actions
    onSubmit        : PropTypes.func,
    onSetFormValue  : PropTypes.func,
    onFormProcessing: PropTypes.func.isRequired,
    onFormErrors    : PropTypes.func.isRequired,
  };

  static defaultProps = {
    persons: 1,
  };


  constructor(props) {
    super(props);
    this.renderActions = props.renderActions;
    if (isEmpty(props.formValues)) times(this.props.persons, (n) => this.onAddPerson(n));
  }


  onAddPerson(idx) {
    this.props.onSetFormValue({ id: idx, value: {} });
  }


  onSetValue(idx, input) {
    const { id, value } = input;
    const personValues = { ...get(this.props.formValues, ['values', idx], {}), [id]: value };
    this.props.onSetFormValue({ id: idx, value: personValues });
  }


  onSubmit(checkOnly = false) {
    const personsValues = get(this.props.formValues, 'values');
    const compactedPersonsValues = compactObject(personsValues);
    const formErrors = {};
    const invitees = [];
    let hasErrors = false;
    forEach(compactedPersonsValues, (personValues, idx) => {
      const values = mapKeys(personValues, (v, k) => k.replace(`person${idx}.`, ''));
      if (!isEmpty(values)) {
        const { validatedValues, errors } = Validator.run(values, validatorRules);
        invitees.push(validatedValues);
        hasErrors = hasErrors || !!errors;
        if (errors) {
          const fieldErrors = mapKeys(errors, (v, k) => `person${idx}.${k}`);
          formErrors[idx] = fieldErrors;
        } else {
          formErrors[idx] = undefined;
        }
      }
    });
    this.props.onFormErrors(formErrors);

    if (!hasErrors && !checkOnly) {
      this.props.onSubmit(invitees);
    }
  }


  get isDisabled() {
    const required = filter(valuesIn(validatorRules), (rule) => startsWith(rule, 'required')).length;
    const invitees = compactObject(get(this.props.formValues, 'values'));
    if (isEmpty(invitees)) {
      return true;
    }
    let isDisabled = false;
    forEach(invitees, (invitee) => {
      const values = compact(valuesIn(invitee));
      if (values.length !== required) {
        isDisabled = true;
        return false;
      }
      return true;
    });
    return isDisabled;
  }


  renderSubmitBtn() {
    const { isDisabled } = this;
    let isDisabledClickable = false;
    if (isDisabled) {
      const personsValues = get(this.props.formValues, 'values');
      const compactedPersonsValues = compactObject(personsValues);
      const formErrors = get(this.props.formValues, 'errors', {});
      isDisabledClickable = !(isEmpty(compactedPersonsValues) && isEmpty(formErrors));
    }
    return (
      <Button
        type="submit"
        styleModifier="primary"
        labelMessage={messages.buttons.inviteFamilyMembers}
        isDisabled={isDisabled}
        isInProgress={this.props.isInProgress}
        className="btn--block btn--filled"
        onClickDisabled={isDisabledClickable ? () => this.onSubmit(true) : undefined}
      />
    );
  }


  renderPerson(idx) {
    const personValues = {
      values: get(this.props.formValues, ['values', idx], {}),
      errors: get(this.props.formValues, ['errors', idx]),
    };

    return (
      <div key={`person${idx}`}>
        <h3 className="text--h3 text--bold mt-3 mb-4"><FormattedMessage {...messages.headers.person} values={{ number: +idx + 1 }} /></h3>
        <div className="row">
          <div className="col-6">
            <FormGroup
              id={`person${idx}.inviteeFirstName`}
              labelMessage={messages.labels.firstName}
              formValues={personValues}
            >
              <Input
                placeholder={messages.placeholders.firstName}
                onChange={(input) => this.onSetValue(idx, input)}
              />
            </FormGroup>
          </div>
          <div className="col-6">
            <FormGroup
              id={`person${idx}.inviteeLastName`}
              labelMessage={messages.labels.lastName}
              formValues={personValues}
            >
              <Input
                placeholder={messages.placeholders.lastName}
                onChange={(input) => this.onSetValue(idx, input)}
              />
            </FormGroup>
          </div>
        </div>
        <FormGroup
          id={`person${idx}.inviteeEmailAddress`}
          labelMessage={messages.labels.email}
          formValues={personValues}
        >
          <Input
            placeholder={messages.placeholders.email}
            onChange={(input) => this.onSetValue(idx, input)}
          />
        </FormGroup>
      </div>
    );
  }


  render() {
    const personsIdxs = keysIn(get(this.props.formValues, 'values', {}));

    return (
      <Form onSubmit={() => this.onSubmit()}>
        { map(personsIdxs, (idx) => this.renderPerson(idx)) }
        <Button
          labelMessage={{ ...messages.buttons.addAnotherPerson }}
          styleModifier="transparent"
          className={styles.addAnotherPersonBtn}
          onClick={() => this.onAddPerson(+last(personsIdxs) + 1)}
        />
        { this.renderActions() }
      </Form>
    );
  }

}


const mapStateToProps = (state) => ({
  formValues: App.selectors.formSelector(constants.INVITE_FAMILY_MEMBERS_FORM)(state),
});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.INVITE_FAMILY_MEMBERS_FORM;

  return {
    onSubmit        : (invitees) => dispatch(actions.inviteFamilyMembers(invitees)),
    onSetFormValue  : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onFormErrors    : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onFormProcessing: () => dispatch(App.actions.startFormProcessing(formName)),
    onClearForm     : () => dispatch(App.actions.clearForm(formName)),
  };
};


const ConnectedInviteFamilyMembersForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InviteFamilyMembersForm);


export default withStyles(styles)(ConnectedInviteFamilyMembersForm);
