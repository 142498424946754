import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import withStyles from 'isomorphic-style-loader/withStyles';
import map from 'lodash/map';
import Avatar from 'components/Avatar';
import Link from 'components/Link';
import SidebarPersonsLoader from 'components/Loaders/SidebarPersonsLoader';
import Dots from 'svg/dots.svg';
import App from 'modules/App';
import Notifications from 'modules/Notifications';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import styles from './SidebarSharingRequestsList.pcss';


class SidebarSharingRequestsList extends React.PureComponent {

  static propTypes = {
    // Explicit props
    sharingRequests                   : PropTypes.arrayOf(shapes.sharingRequest),
    // Implicit props
    isInProgress                      : PropTypes.bool,
    isClientInitialized               : PropTypes.bool,
    openDropdownId                    : PropTypes.string,
    lastReceivedNotification          : Notifications.shapes.notification,
    // Implicit actions
    onFetchSharingRequests            : PropTypes.func,
    onSetBeingApprovedSharingRequestId: PropTypes.func,
    onOpenDropdown                    : PropTypes.func,
    onCloseDropdown                   : PropTypes.func,
    onOpenModal                       : PropTypes.func,
    onSetFormContext                  : PropTypes.func,
  };


  async onOpenMenu(evt, id) {
    evt.preventDefault();
    await this.props.onCloseDropdown();
    this.props.onOpenDropdown(`${constants.APPROVED_SHARING_REQUEST_DROPDOWN}-${id}`);
  }


  onReauthorizeAccess(evt, sharingRequest) {
    evt.preventDefault();
    this.props.onSetBeingApprovedSharingRequestId(sharingRequest.sharingRequestId);
    this.props.onOpenModal(constants.APPROVE_DATA_SHARING_MODAL);
  }


  onRevokeAccess(evt, sharingRequest) {
    evt.preventDefault();
    this.props.onSetFormContext(constants.REVOKE_ACCESS_FORM, sharingRequest);
    this.props.onOpenModal(constants.REVOKE_ACCESS_MODAL);
  }


  renderMenu(sharingRequest) {
    const dropdownId = `${constants.APPROVED_SHARING_REQUEST_DROPDOWN}-${sharingRequest.sharingRequestId}`;
    if (this.props.openDropdownId !== dropdownId) {
      return null;
    }

    return (
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: 'auto' }}
        exit={{ width: 0 }}
        transition={{ ease: 'easeOut', duration: 0.15 }}
        className="dropdownMenu__container dropdownMenu__container--oposite ml-3"
      >
        <ul className="dropdownMenu__actions">
          <li className="dropdownMenu__action">
            <Link to="" onClick={(evt) => this.onReauthorizeAccess(evt, sharingRequest)}>
              <FormattedMessage {...messages.labels.reauthorizeAccess} />
            </Link>
          </li>
          <li className="dropdownMenu__action">
            <Link to="" onClick={(evt) => this.onRevokeAccess(evt, sharingRequest)}>
              <FormattedMessage {...messages.labels.revokeAccess} />
            </Link>
          </li>
        </ul>
      </motion.div>
    );
  }


  renderMenuContainer(sharingRequest) {
    const { sharingRequestId } = sharingRequest;
    return (
      <div className={cn('dropdownMenu', styles.sidebarSharingRequestsList__dots)}>
        <Link to="" onClick={(evt) => this.onOpenMenu(evt, sharingRequestId)}>
          <Dots className="dropdownMenu__icon" />
        </Link>
        <AnimatePresence>{ this.renderMenu(sharingRequest) }</AnimatePresence>
      </div>
    );
  }


  renderAvatar(sharingRequest) {
    const avatar = sharingRequest.hcp ? sharingRequest.hcp.avatar : sharingRequest.clinic.logo;
    const avatarName = sharingRequest.hcp
      ? [sharingRequest.hcp.firstName, sharingRequest.hcp.lastName]
      : sharingRequest.clinic.name;

    return (
      <Avatar
        avatarImg={avatar}
        name={avatarName}
        className={styles.item__avatar}
        imgClassName={styles.item__avatar__img}
        initialsClassName={styles.item__avatar__initials}
      />
    );
  }


  renderSharingRequest(sharingRequest) {
    if (sharingRequest.sharingStatus !== 'Approved') {
      return null;
    }

    const hcpName = sharingRequest.hcp
      ? `${sharingRequest.hcp.firstName} ${sharingRequest.hcp.lastName}`
      : sharingRequest.clinic.name;
    const clinicName = sharingRequest.hcp
      ? sharingRequest.clinic.name
      : <FormattedMessage {...messages.labels.unassigned} />;

    return (
      <li
        key={sharingRequest.sharingRequestId}
        className={
          cn(
            styles.item__sidebarList,
            styles.sidebar__hcpCellExtended,
          )
        }
      >
        <div className="row align-items-center">
          <div className="col-auto pr-2">
            { this.renderAvatar(sharingRequest) }
          </div>
          <div className="col">
            <p data-hj-suppress className={styles.item__name}>{ hcpName }</p>
            <p className={styles.subitem__name}>{ clinicName }</p>
          </div>
          { this.renderMenuContainer(sharingRequest) }
        </div>
      </li>
    );
  }


  render() {
    if (this.props.isInProgress) {
      return <SidebarPersonsLoader itemsNumber={5} />;
    }
    return (
      <ul className={styles.items}>
        { map(this.props.sharingRequests, (sharingRequest) => this.renderSharingRequest(sharingRequest)) }
      </ul>
    );
  }

}


const mapStateToProps = (state) => ({
  isInProgress            : selectors.isFetchSharingRequestsInProgress(state),
  isClientInitialized     : App.selectors.isClientInitialized(state),
  openDropdownId          : App.selectors.dropdown(state),
  lastReceivedNotification: Notifications.selectors.lastReceivedNotification(state),
});


const mapDispatchToProps = (dispatch) => ({
  onSetBeingApprovedSharingRequestId: (sharingRequestId) => dispatch(actions.setBeingApprovedSharingRequestId(sharingRequestId)),
  onOpenDropdown                    : (dropdownId) => dispatch(App.actions.openDropdown(dropdownId)),
  onCloseDropdown                   : () => dispatch(App.actions.closeDropdown()),
  onOpenModal                       : (modalId) => dispatch(App.actions.openModal(modalId)),
  onSetFormContext                  : (formName, contextData) => dispatch(App.actions.setFormContext(formName, contextData)),
});


const ConnectedSidebarSharingRequestsList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SidebarSharingRequestsList);


export default withStyles(styles)(ConnectedSidebarSharingRequestsList);
