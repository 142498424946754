import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'components/Form/Button';
import * as actions from '../../actions';
import * as selectors from '../../selectors';


class ReauthorizeContourCloudButton extends React.PureComponent {

  static propTypes = {
    // Explicit props
    labelMessage                  : PropTypes.object,
    // Implicit props
    isReauthContourCloudInProgress: PropTypes.bool,
    // Implicit actions
    onReauthContourCloud          : PropTypes.func,
  }

  render() {
    const { labelMessage } = this.props;

    return (
      <Button
        styleModifier="quaternary"
        className="mb-4"
        labelMessage={labelMessage}
        onClick={() => this.props.onReauthContourCloud()}
        isInProgress={this.props.isReauthContourCloudInProgress}
      />
    );
  }

}


const mapStateToProps = (state) => ({
  isReauthContourCloudInProgress: selectors.isReauthContourCloudInProgress(state),
});


const mapDispatchToProps = (dispatch) => ({
  onReauthContourCloud: () => dispatch(actions.reauthContourCloud()),
});


const ConnectedReauthorizeContourCloudButton = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReauthorizeContourCloudButton);


export default ConnectedReauthorizeContourCloudButton;
