import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import ReadingFlagIcon from 'components/ReadingFlagIcon';
import App from 'modules/App';
import { formatDate } from 'helpers/datetime';
import intlShape from 'shapes/intlShape';
import { standardsSelector } from 'modules/PatientResults/selectors';
import messages from '../../../messages';
import styles from './Header.pcss';


class Header extends React.Component {

  static propTypes = {
    // Implicit props
    intl                 : intlShape.isRequired, // Explicit props
    phiSet               : PropTypes.object, // @TODO: shape
    accountPatientProfile: PropTypes.object, // @TODO: Shape
    standards            : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    conversion         : PropTypes.object,
    reportsDates       : PropTypes.object,
    glucoseLevelTargets: PropTypes.object,
  };


  screenValue = (val) => this.props.conversion.toDisplay(val);


  render() {
    const {
      accountPatientProfile,
      phiSet,
      conversion,
      intl: { formatMessage },
      glucoseLevelTargets,
    } = this.props;
    const { glucoseReport, labels } = messages;
    const {
      startDate,
      endDate,
    } = this.props.reportsDates;


    if (!accountPatientProfile || !phiSet) return null;
    return (
      <div>
        <div className={styles.header}>
          <div className={styles['header__left-col']}>
            <h1 className={styles.header__title}>
              { `${accountPatientProfile.firstName} ${accountPatientProfile.lastName}` }
            </h1>
            <div>
              <strong>{ `${formatMessage(glucoseReport.diabetesType)}: ` }</strong>
              {
                App.messages.diabetesTypes[phiSet.diabetesType]
                  ? <FormattedMessage {...App.messages.diabetesTypes[phiSet.diabetesType]} />
                  : '-'
              }
            </div>
            <div>
              <strong>{ `${formatMessage(glucoseReport.treatmentType)}: ` }</strong>
              {
                App.messages.treatmentTypes[phiSet.treatmentType]
                  ? <FormattedMessage {...App.messages.treatmentTypes[phiSet.treatmentType]} />
                  : '-'
              }
            </div>
            <p>
              <strong>{ `${formatMessage(glucoseReport.dateOfBirth)}: ` }</strong>
              {
                accountPatientProfile.dateOfBirth
                  ? formatDate(accountPatientProfile.dateOfBirth)
                  : '-'
              }
            </p>
            {
              accountPatientProfile.phone
              && (
                <p>
                  <strong>{ `${formatMessage(labels.phone)}: ` }</strong> { accountPatientProfile.phone }
                </p>
              )
            }
            <p>
              <strong>Email:</strong> { accountPatientProfile.email }
            </p>
          </div>
          <div className={styles['header__center-col']}>
            <div className={styles.header__title}>
              <h1>
                <FormattedMessage {...messages.report.headers.gestationalReportTitle} />
              </h1>
              <h3 className={styles.header__daterange}>{ formatDate(startDate) } - { formatDate(endDate) }</h3>
            </div>
          </div>
          <div className={styles['header__right-col']}>
            <p className={styles.header__title}><FormattedMessage {...App.messages.labels.legend} /></p>
            <table className={styles['header__right-col-row']}>
              <thead>
                <tr>
                  { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                  <th />
                  <th>{ `${formatMessage(glucoseReport.high)}: ` }</th>
                  <th>{ `${formatMessage(glucoseReport.low)}: ` }</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>
                      <FormattedMessage {...App.messages.labels.preMeal} />:
                      <ReadingFlagIcon flag="PreMeal" className="btn__icon" />
                    </p>
                  </td>
                  <td>
                    <span className={styles.header__high}>
                      { `> ${this.screenValue(glucoseLevelTargets.preMeal.highThreshold)} ${conversion.unitSymbol}` }
                    </span>
                  </td>
                  <td>
                    <span className={styles.header__low}>
                      { `< ${this.screenValue(glucoseLevelTargets.preMeal.lowThreshold)} ${conversion.unitSymbol}` }
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <FormattedMessage {...App.messages.labels.postMeal} />:
                      <ReadingFlagIcon flag="PostMeal" className="btn__icon" />
                    </p>
                  </td>
                  <td>
                    <span className={styles.header__high}>
                      { `> ${this.screenValue(glucoseLevelTargets.postMeal.highThreshold)} ${conversion.unitSymbol}` }
                    </span>
                  </td>
                  <td>
                    <span className={styles.header__low}>
                      { `< ${this.screenValue(glucoseLevelTargets.postMeal.lowThreshold)} ${conversion.unitSymbol}` }
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <FormattedMessage {...App.messages.flags.Fasting} />:
                      <ReadingFlagIcon flag="Fasting" className="btn__icon" />
                    </p>
                  </td>
                  <td>
                    <span className={styles.header__high}>
                      { `> ${this.screenValue(glucoseLevelTargets.preMeal.highThreshold)} ${conversion.unitSymbol}` }
                    </span>
                  </td>
                  <td>
                    <span className={styles.header__low}>
                      { `< ${this.screenValue(glucoseLevelTargets.preMeal.lowThreshold)} ${conversion.unitSymbol}` }
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  glucoseLevelTargets: standardsSelector(state),
});


const ConnectedHeader = connect(
  mapStateToProps,
)(Header);


export default withStyles(styles)(injectIntl(ConnectedHeader));
