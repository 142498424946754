// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".KlVsP{margin-left:5rem;margin-top:-4px;padding:0 1.5rem;color:#30a8ff;font-weight:400;font-size:16px;font-size:1.6rem;line-height:2}.KlVsP .btn__labelWrapper{padding:0}.nqn5s{color:#30a8ff}.AEP0G{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}._1JeWF{fill:#30a8ff;height:20px;width:20px}._3__B7{padding:1rem 2rem;font-weight:800;font-size:10px;font-size:1rem;line-height:14px;text-transform:uppercase;color:#6f7ea2;border-bottom:1px solid #e0e8f2}._1IIKf{padding:2rem;border-top:1px solid #e0e8f2}._1IIKf .dayLabel{margin-bottom:2.5rem;font-weight:600;font-size:15px;color:#0a385a}._1qn7k{margin-top:-24px}._1G7r_{width:100%;display:-ms-flexbox;display:flex;-ms-flex:1 1;flex:1 1;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;color:#95a1bd;font-weight:700}._1G7r_ svg{fill:#6f7ea2;width:16px;height:16px}._2md6e{width:100%;display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center;padding:24px 0}._2b7Ss{width:100%;border-bottom:1px solid #e0e8f2;cursor:pointer;overflow:hidden}", ""]);
// Exports
exports.locals = {
	"toggleFormBtn": "KlVsP",
	"editGlucoseBtn": "nqn5s",
	"icon": "AEP0G",
	"icon__icon": "_1JeWF",
	"titleSubsection": "_3__B7",
	"cgmTargetsForm": "_1IIKf",
	"form": "_1qn7k",
	"title_header": "_1G7r_",
	"settingItem_header": "_2md6e",
	"settingItem_container": "_2b7Ss"
};
module.exports = exports;
