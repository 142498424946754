import PropTypes from 'prop-types';
import { NOTE_TYPES } from 'modules/App/constants';


export default PropTypes.shape({
  noteId       : PropTypes.string.isRequired,
  noteType     : PropTypes.oneOf(NOTE_TYPES).isRequired,
  content      : PropTypes.string.isRequired,
  timestamp    : PropTypes.number.isRequired,
  phisetVisitId: PropTypes.string,
});
