import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import filter from 'lodash/filter';
import map from 'lodash/map';
import Avatar from 'components/Avatar';
import * as selectors from '../../../selectors';
import * as shapes from '../../../shapes';
import messages from '../../../messages';
import styles from './ClinicMemberships.pcss';


class OrganizationMemberships extends React.PureComponent {

  static propTypes = {
    // Explicit props
    status                 : PropTypes.oneOf(['Invitation', 'Pending']).isRequired,
    headerMessage          : PropTypes.object,
    // Implicit props
    organizationMemberships: PropTypes.arrayOf(shapes.organizationMembership),
  };


  renderAvatar(profile) {
    if (!profile) {
      return null;
    }
    return (
      <Avatar
        avatarImg={profile.avatar}
        name={[profile.firstName, profile.lastName]}
        className={styles.avatar}
        imgClassName={styles.avatar__img}
        initialsClassName={styles.avatar__initials}
      />
    );
  }


  renderOrganizationMembership(hcpInvitation) {
    const { identityUID, businessEmail, firstName, lastName } = hcpInvitation;
    return (
      <tr key={identityUID}>
        <td className="cell--avatar">{ this.renderAvatar({ firstName, lastName }) }</td>
        <td className="w-33">{ firstName } { lastName }</td>
        <td>{ businessEmail }</td>
      </tr>
    );
  }


  renderOrganizationMemberships(hcpInvitations) {
    return (
      <div className="section__table-container">
        <table className="section__table">
          <thead className="section__table__head">
            <tr>
              <th colSpan={2}><FormattedMessage {...messages.labels.hcp} /></th>
              <th><FormattedMessage {...messages.labels.email} /></th>
            </tr>
          </thead>
          <tbody className="section__table__body">
            { map(hcpInvitations, (hcpInvitation) => this.renderOrganizationMembership(hcpInvitation)) }
          </tbody>
        </table>
      </div>
    );
  }


  render() {
    const { status, headerMessage } = this.props;
    const organizationMemberships = filter(this.props.organizationMemberships, { status });
    if (!organizationMemberships.length) {
      return null;
    }
    return (
      <div className="pt-10">
        <h2 className="row section__header">
          <span className="col">
            <FormattedMessage {...headerMessage} />
          </span>
        </h2>
        { this.renderOrganizationMemberships(organizationMemberships) }
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  organizationMemberships: selectors.organizationMemberships(state),
});


const ConnectedOrganizationMemberships = connect(
  mapStateToProps,
  // mapDispatchToProps,
)(OrganizationMemberships);


export default ConnectedOrganizationMemberships;
