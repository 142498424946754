import React from 'react';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Account from 'modules/Account';
import Hcp from 'modules/Hcp';
import Patient from 'modules/Patient';
import Chevron from 'svg/chevron-right.svg';
import * as actions from '../../../actions';
import * as selectors from '../../../selectors';
import styles from './DaysSelectorControlBtn.pcss';


class DaysSelectorControlBtn extends React.PureComponent {

  static propTypes = {
    // Explicit props
    date                   : PropTypes.string,
    patient                : PropTypes.object,
    phiSet                 : PropTypes.object,
    phiSetDocumentId       : PropTypes.string,
    // Implicit props
    agpMonthSelectorPage   : PropTypes.number,
    dgpSelectorlPages      : PropTypes.number,
    activeProfileType      : Account.shapes.profileType,
    // Implicit actions
    setDaysSelectorZoom    : PropTypes.func,
    setAgpMonthSelectorPage: PropTypes.func,

  };

  onSetAgpMonthSelectorPage(page) {
    const {
      setAgpMonthSelectorPage,
      patient,
      phiSet,
      phiSetDocumentId,
    } = this.props;

    setAgpMonthSelectorPage(page, patient, phiSet, phiSetDocumentId, this.prepareAgpPreviewSuccessAction);
  }


  get prepareAgpPreviewSuccessAction() {
    const { activeProfileType } = this.props;

    const successAction = activeProfileType === Account.constants.PROFILE_TYPES.HCP
      ? Hcp.actions.setReadings
      : Patient.actions.setReadings;

    return successAction;
  }


  get signProps() {
    const {
      agpMonthSelectorPage,
      dgpSelectorlPages,
    } = this.props;
    if (dgpSelectorlPages === 1 || dgpSelectorlPages === 0) {
      return {
        plusSignProps: {
          className: cn(
            styles.daysSelectorScrollBtn__sign,
            styles['daysSelectorScrollBtn__sign--disabled'],
          ),
          disabled: true,
        },
        minusSignProps: {
          className: cn(
            styles.daysSelectorScrollBtn__sign,
            styles['daysSelectorScrollBtn__sign--disabled'],
          ),
          disabled: true,
        },
      };
    }
    if (agpMonthSelectorPage <= 0) {
      return {
        plusSignProps: {
          className: cn(
            styles.daysSelectorScrollBtn__sign,
          ),
          onClick: () => this.onSetAgpMonthSelectorPage(agpMonthSelectorPage + 1),
        },
        minusSignProps: {
          className: cn(
            styles.daysSelectorScrollBtn__sign,
            styles['daysSelectorScrollBtn__sign--disabled'],
          ),
          disabled: true,
        },
      };
    }
    if (agpMonthSelectorPage >= dgpSelectorlPages - 1) {
      return {
        plusSignProps: {
          className: cn(
            styles.daysSelectorScrollBtn__sign,
            styles['daysSelectorScrollBtn__sign--disabled'],
          ),
          disabled: true,
        },
        minusSignProps: {
          className: cn(
            styles.daysSelectorScrollBtn__sign,
          ),
          onClick: () => this.onSetAgpMonthSelectorPage(agpMonthSelectorPage - 1),
        },
      };
    }
    return {
      plusSignProps: {
        className: cn(
          styles.daysSelectorScrollBtn__sign,
        ),
        onClick: () => this.onSetAgpMonthSelectorPage(agpMonthSelectorPage + 1),
      },
      minusSignProps: {
        className: cn(
          styles.daysSelectorScrollBtn__sign,
        ),
        onClick: () => this.onSetAgpMonthSelectorPage(agpMonthSelectorPage - 1),
      },
    };

  }


  render() {
    const {
      plusSignProps,
      minusSignProps,
    } = this.signProps;

    const { date } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.daysSelectorScrollBtn}>
          <div className={styles.daysSelectorScrollBtn__signs}>
            <button
              type="button"
              {...minusSignProps}
            >
              <Chevron className={cn(
                styles['daysSelectorScrollBtn__signIcon--backwards'],
              )}
              />
            </button>
            <button type="button" {...plusSignProps}>
              <Chevron />
            </button>
            <div className={styles.daysSelectorMonth}>
              {date}
            </div>
          </div>
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  activeProfileType   : Account.selectors.activeProfileType(state),
  agpMonthSelectorPage: selectors.agpMonthSelectorPage(state),
  dgpSelectorlPages   : selectors.dgpSelectorlPages(state),
});


const mapDispatchToProps = (dispatch) => ({
  setAgpMonthSelectorPage: (page, patient, phiSet, phiSetDocumentId, successAction) =>
    dispatch(actions.setAgpMonthSelectorPage(page, patient, phiSet, phiSetDocumentId, successAction)),
});


const ConnectedDaysSelectorControlBtn = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DaysSelectorControlBtn);


export default withStyles(styles)(ConnectedDaysSelectorControlBtn);
