// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Icie{width:100%;display:-ms-flexbox;display:flex;-ms-flex:1 1;flex:1 1;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;color:#95a1bd;font-weight:700}._3Icie svg{fill:#6f7ea2;width:16px;height:16px}._26LlS{width:100%;display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center;padding:24px 0}._3H1FU{width:100%;border-bottom:1px solid #e0e8f2;cursor:pointer}._3VAW4{color:#0a385a}._22SWG{margin-bottom:24px;font-size:14px;font-size:1.4rem;font-weight:500;line-height:1.5}._1mkAj{margin-top:28px}", ""]);
// Exports
exports.locals = {
	"title_header": "_3Icie",
	"settingItem_header": "_26LlS",
	"settingItem_container": "_3H1FU",
	"optionHeader": "_3VAW4",
	"itemInfo": "_22SWG",
	"itemInfo--single": "_1mkAj"
};
module.exports = exports;
