// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Nes-{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;position:relative;-webkit-print-color-adjust:exact!important}._2Nes- .page{width:8in;margin:.5in .25in;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-ms-flex-pack:justify;justify-content:space-between;color:#000}@media print{._2Nes- .page{margin:0 .25in}}._2Nes- .page *{font-family:Arial,sans-serif}._3i7_B{position:absolute;top:30px;top:3rem;right:30px;right:3rem;z-index:1}@media print{._3i7_B{display:none}}", ""]);
// Exports
exports.locals = {
	"printableAgpReport": "_2Nes-",
	"closePrintModeBtn": "_3i7_B"
};
module.exports = exports;
