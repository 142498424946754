import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';
import * as shapes from './shapes';
import reducer from './reducer';
import sagas from './sagas';
import messages from './messages';

import VisitHistoryModal from './components/VisitHistoryModal';
import VisitNotes from './components/VisitNotes';


const components = {
  VisitHistoryModal,
  VisitNotes,
};


export default {
  actionTypes,
  actions,
  components,
  constants,
  messages,
  reducer,
  sagas,
  selectors,
  shapes,
};
