import includes from 'lodash/includes';
import { createSelector } from 'reselect';
import * as actionTypes from './actionTypes';
import * as constants from './constants';

/**
 * Input selectors
 */
const mainSelector = (state) => state[constants.MODULE_ID];
const fetchingSelector = (state) => state[constants.MODULE_ID].fetching;
const sendingSelector = (state) => state[constants.MODULE_ID].sending;
const errorsSelector = (state) => state[constants.MODULE_ID].errors;


/**
 * Select custom identifiers depend on country
 */
export const clinicCustomIdentifiers = createSelector(
  mainSelector,
  (state) => state.clinicCustomIdentifiers,
);


/**
 * Select country id of currently fetched custom identifiers set
 */
export const clinicCustomIdentifiersCountryId = createSelector(
  mainSelector,
  (state) => state.clinicCustomIdentifiersCountryId,
);


/**
 * Select organization memberships list
 */
export const organizationMemberships = createSelector(
  mainSelector,
  (state) => state.organizationMemberships,
);


/**
 * Select clinic memberships list
 */
export const clinicMemberships = createSelector(
  mainSelector,
  (state) => state.clinicMemberships,
);

/**
 * Number of clinic's patients
 */
export const patientsCount = createSelector(
  mainSelector,
  (state) => state.patientsCount,
);

/**
 * Id of clinic membership during activation process
 */
export const approvingClinicMembershipId = createSelector(
  mainSelector,
  (state) => state.approvingClinicMembershipId,
);

/**
 * Default settings for clinic country
 */
export const countrySettings = createSelector(
  mainSelector,
  (state) => state.countrySettings,
);

export const clinicSettings = createSelector(
  mainSelector,
  (state) => state.clinicSettings,
);

// IMPORT GCD ----------------------------------------------------------------------------------------------------------

/**
 * Progress of an import of patients from an external database in percents
 */
export const importPatientDatabaseProgress = createSelector(
  mainSelector,
  (state) => state.importPatientDatabaseProgress,
);

/**
 * Number of patients that has been imported successfully
 */
export const importPatientDatabaseSuccessCount = createSelector(
  mainSelector,
  (state) => state.importPatientDatabaseSuccessCount,
);

/**
 * Number of patients that has been imported with error
 */
export const importPatientDatabaseErrorCount = createSelector(
  mainSelector,
  (state) => state.importPatientDatabaseErrorCount,
);

/**
 * List of patients that has been imported with error
 */
export const importPatientDatabaseErrorsList = createSelector(
  mainSelector,
  (state) => state.importPatientDatabaseErrorsList,
);

//----------------------------------------------------------------------------------------------------------------------

/**
 * Create clinic action
 */
export const isSetClinicCloudDriveInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.SET_CLINIC_CLOUD_DRIVE),
);

export const hasSetClinicCloudDriveErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.SET_CLINIC_CLOUD_DRIVE),
);

//----------------------------------------------------------------------------------------------------------------------

/**
 * Update clinic glucose action
 */
export const isUpdateClinicGlucoseInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.UPDATE_CLINIC_GLUCOSE_SETTINGS),
);

export const hasUpdateClinicGlucoseErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.UPDATE_CLINIC_GLUCOSE_SETTINGS),
);

//----------------------------------------------------------------------------------------------------------------------

/**
 * Update clinic kpi action
 */
export const isUpdateClinicKpiInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.UPDATE_CLINIC_KPI_SETTINGS),
);

export const hasUpdateClinicKpiErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.UPDATE_CLINIC_KPI_SETTINGS),
);

//----------------------------------------------------------------------------------------------------------------------

export const isStoreClinicInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.STORE_CLINIC),
);

export const hasStoreClinicErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.STORE_CLINIC),
);

//----------------------------------------------------------------------------------------------------------------------

export const isReauthorizeClinicCloudDriveInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.REAUTHORIZE_CLINIC_CLOUD_DRIVE),
);

export const hasReauthorizeClinicCloudDriveError = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.REAUTHORIZE_CLINIC_CLOUD_DRIVE),
);

//----------------------------------------------------------------------------------------------------------------------

/**
 * Clearing all clinic data
 */
export const isRemoveClinicDataInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.REMOVE_CLINIC_DATA),
);

export const hasRemoveClinicDataErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.REMOVE_CLINIC_DATA),
);

//----------------------------------------------------------------------------------------------------------------------

export const isImportPatientDatabaseWorking = createSelector(
  mainSelector,
  (state) => state.importPatientDatabaseIsWorking,
);

export const isImportPatientDatabaseInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.IMPORT_PATIENT_DATABASE),
);

//----------------------------------------------------------------------------------------------------------------------

export const isSetClinicLicenceInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.SET_CLINIC_LICENCE),
);

export const hasSetClinicLicenceErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.SET_CLINIC_LICENCE),
);

//----------------------------------------------------------------------------------------------------------------------

export const isFetchClinicCountrySettingsInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_CLINIC_COUNTRY_SETTINGS),
);

export const hasFetchClinicCountrySettingsErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.FETCH_CLINIC_COUNTRY_SETTINGS),
);

//----------------------------------------------------------------------------------------------------------------------

export const isGetStoredClinicInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.GET_STORED_CLINIC),
);

export const hasGetStoredClinicErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.GET_STORED_CLINIC),
);

//----------------------------------------------------------------------------------------------------------------------
