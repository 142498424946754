import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import map from 'lodash/map';
import unionBy from 'lodash/unionBy';
import isObject from 'lodash/isObject';
import isFunction from 'lodash/isFunction';
import { useAction } from 'hooks';
import Alert from 'components/Alert';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { transformSystemAlerts } from './helpers';


interface Props {
  className: string | object,
  isGlobal?: boolean
  activeClinicMembership?: ClinicMembership,
}


const onAction = (action, dispatch) => {
  if (isFunction(action)) {
    action();
  } else if (isObject(action)) {
    dispatch(action);
  }
};


const getAlertName = (alert) => (isObject(alert.message)
  ? `${alert.message.id}_${JSON.stringify(alert.messageValues)}`
  : alert.message);


const useAlerts = (isGlobal: boolean, activeClinicMembership?: ClinicMembership) => {
  const { clinicHcpMembershipId } = activeClinicMembership || {};
  const newAlerts = useSelector(selectors.alerts);
  const newAlertsTimeThreshold = useSelector(selectors.alertsTimeThreshold);
  const dismissedAlerts = useSelector(selectors.dismissedAlerts);
  const openModalId = useSelector(selectors.modal);
  const route = useSelector(selectors.route);

  const alertsTimeThreshold = useRef<number>(+moment.utc().locale('en').format('X'));
  const prevRouteRef = useRef(null);
  const prevClinicHcpMembershipIdRef = useRef(clinicHcpMembershipId);

  const [alerts, setAlerts] = useState([]);

  const dismissAlert = useAction(actions.dismissAlert);
  const onDisplayAlerts = debounce(useAction(actions.displayAlerts), 150);
  const onCloseAlert = (alert) => {
    const { id } = alert;
    setAlerts(alerts.filter((a) => a.id !== id));
    if (alert.attributes && alert.attributes.isPinned) {
      dismissAlert(alert);
    }
  };

  useEffect(() => {
    let currentAlerts = alerts;
    if (prevRouteRef.current && route !== prevRouteRef.current) {
      if (prevClinicHcpMembershipIdRef.current !== clinicHcpMembershipId) {
        currentAlerts = [];
      } else {
        currentAlerts = filter(alerts, (alert) => alert.attributes && alert.attributes.isPinned);
      }
    }

    if (newAlerts.length || newAlertsTimeThreshold > alertsTimeThreshold.current) {
      const localAlerts = filter(newAlerts, (alert) => {
        const alertName = getAlertName(alert);
        return !includes(dismissedAlerts, alertName)
          && (
            !openModalId
            || (openModalId && ((isGlobal && alert.isGlobal) || (!isGlobal && !alert.isGlobal)))
          );
      });
      let unionAlerts = unionBy([...currentAlerts].reverse(), localAlerts, (alert) => getAlertName(alert));
      unionAlerts = filter(unionAlerts, (alert) => alert.timestamp >= alertsTimeThreshold.current).reverse();
      setAlerts(unionAlerts);
      alertsTimeThreshold.current = newAlertsTimeThreshold;
      prevRouteRef.current = route;
      onDisplayAlerts();
    } else {
      setAlerts(currentAlerts);
    }
  }, [newAlerts, newAlertsTimeThreshold, route, activeClinicMembership]);

  return { alerts, onCloseAlert };
};


const useSystemAlerts = (isGlobal: boolean) => {
  if (!isGlobal) {
    return { systemAlerts: [], onCloseSystemAlert: undefined };
  }

  const newSystemAlerts = useSelector(selectors.systemAlerts);
  const systemAlertsSettings = useSelector(selectors.systemAlertsSettings);
  const localizationResources = useSelector(selectors.localizationResources);

  const dismissSystemAlert = useAction(actions.dismissSystemAlert);

  const [systemAlerts, setSystemAlerts] = useState([]);

  const onDisplayAlerts = debounce(useAction(actions.displayAlerts), 150);
  const onCloseSystemAlert = (alert) => {
    const { id } = alert;
    setSystemAlerts(systemAlerts.filter((a) => a.id !== id));
    dismissSystemAlert(alert);
  };

  useEffect(() => {
    if (newSystemAlerts.length) {
      let unionSystemAlerts = [];
      unionSystemAlerts = unionBy([...systemAlerts].reverse(), newSystemAlerts, (systemAlert) => (
        `${systemAlert.alertId}_${systemAlert.createTimestamp}`
      ));

      if (unionSystemAlerts.length <= systemAlerts.length) {
        return;
      }

      unionSystemAlerts = transformSystemAlerts(unionSystemAlerts, systemAlertsSettings, localizationResources)
        .filter((systemAlert) => systemAlert && systemAlert.message).reverse();

      setSystemAlerts(unionSystemAlerts);
      onDisplayAlerts();
    }
  }, [newSystemAlerts]);

  return { systemAlerts, onCloseSystemAlert };
};


const AlertsBus: FC<Props> = ({ className, isGlobal = false, activeClinicMembership }) => {
  const dispatch = useDispatch();

  const { alerts, onCloseAlert } = useAlerts(isGlobal, activeClinicMembership);
  const { systemAlerts = [], onCloseSystemAlert } = useSystemAlerts(isGlobal);
  const allAlerts = [...alerts, ...systemAlerts];

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const onClose = (alert) => {
    const { alertConfigurationId } = alert;
    if (alertConfigurationId) {
      if (onCloseSystemAlert) onCloseSystemAlert(alert);
    } else {
      onCloseAlert(alert);
    }
  };

  const onToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };


  return (
    <motion.div
      className={cn('alertsBus', className)}
      initial={{ height: 0 }}
      animate={{ height: allAlerts.length ? 'auto' : 0 }}
      transition={{ ease: 'easeIn', duration: allAlerts.length ? 0.1 : 0.3 }}
      style={{ overflow: 'hidden' }}
    >
      <AnimatePresence>
        {
          map(allAlerts, (alert, idx) => (
            <motion.div
              key={`alert-${isObject(alert.message) ? alert.message.id : alert.message}-${alert.id}`}
              initial={{ opacity: 0, marginTop: isCollapsed ? '-55px' : '-55px' }}
              animate={{ opacity: 1, marginTop: idx && isCollapsed ? '-52px' : 0 }}
              exit={{ opacity: 0, marginTop: '-55px' }}
              transition={{ ease: 'linear', duration: 0.3 }}
              style={{ position: 'relative', zIndex: allAlerts.length - idx }}
            >
              <Alert
                idx={idx}
                isCollapsed={isCollapsed}
                {...alert}
                actions={
                  alert.actions && alert.actions.map((action) => ({
                    ...action,
                    action: () => onAction(action.action, dispatch),
                  }))
                }
                onClose={() => onClose(alert)}
                onToggleCollapse={onToggleCollapse}
              />
            </motion.div>
          ))
        }
      </AnimatePresence>
    </motion.div>
  );
};

export default AlertsBus;
