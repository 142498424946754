import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import range from 'lodash/range';
import map from 'lodash/map';
import moment from 'moment';
import styles from './DayTimeline.pcss';


class DayTimeline extends React.PureComponent {


  renderTime(hour) {
    if (hour % 4 !== 0) {
      return null;
    }

    const formattedHour = moment().hour(hour).minutes(0).format('LT');
    return (
      <div className={styles.dailyHoursDistributionAxis__time}>
        { formattedHour }
      </div>
    );
  }


  renderIndicator(index) {
    const distance = `${index / 24 * 100}%`;
    const direction = 'left';
    const style = { [direction]: distance };

    return (
      <div className={styles.dailyHoursDistributionAxis__indicator} key={index} style={style}>
        <div className={styles.dailyHoursDistributionAxis__line} />
        { this.renderTime(index) }
      </div>
    );
  }


  renderIndicators() {
    const hours = range(25);
    return map(hours, (index) => this.renderIndicator(index));
  }


  render() {
    return (
      <div className={styles.dailyHoursDistributionAxis}>
        <div className={styles.dailyHoursDistributionAxis__inner}>
          {this.renderIndicators()}
        </div>
      </div>
    );
  }


}


export default withStyles(styles)(DayTimeline);
