import { MODULE_ID } from './constants';


export const FETCH_LIST = `${MODULE_ID}/FETCH_LIST`;
export const FETCH_LIST_SUCCESS = `${MODULE_ID}/FETCH_LIST_SUCCESS`;
export const FETCH_LIST_ERROR = `${MODULE_ID}/FETCH_LIST_ERROR`;

export const DISMISS_NOTIFICATION = `${MODULE_ID}/DISMISS_NOTIFICATION`;
export const DISMISS_NOTIFICATION_SUCCESS = `${MODULE_ID}/DISMISS_NOTIFICATION_SUCCESS`;
export const DISMISS_NOTIFICATION_ERROR = `${MODULE_ID}/DISMISS_NOTIFICATION_ERROR`;

export const MARK_NOTIFICATIONS_READ = `${MODULE_ID}/MARK_NOTIFICATIONS_READ`;
export const MARK_NOTIFICATIONS_READ_SUCCESS = `${MODULE_ID}/MARK_NOTIFICATIONS_READ_SUCCESS`;
export const MARK_NOTIFICATIONS_READ_ERROR = `${MODULE_ID}/MARK_NOTIFICATIONS_READ_ERROR`;

export const SEND_CLINIC_PATIENT_NEW_READINGS_ADDED_NOTIFICATION = `${MODULE_ID}/SEND_CLINIC_PATIENT_NEW_READINGS_ADDED_NOTIFICATION`;

export const SEND_NOTIFICATION_SUCCESS = `${MODULE_ID}/SEND_NOTIFICATION_SUCCESS`;
export const SEND_NOTIFICATION_ERROR = `${MODULE_ID}/SEND_NOTIFICATION_ERROR`;

export const FETCH_NOTIFICATIONS_HISTORY = `${MODULE_ID}/FETCH_NOTIFICATIONS_HISTORY`;
export const FETCH_NOTIFICATIONS_HISTORY_SUCCESS = `${MODULE_ID}/FETCH_NOTIFICATIONS_HISTORY_SUCCESS`;
export const FETCH_NOTIFICATIONS_HISTORY_ERROR = `${MODULE_ID}/FETCH_NOTIFICATIONS_HISTORY_ERROR`;
