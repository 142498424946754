import includes from 'lodash/includes';
import { createSelector } from 'reselect';
import * as actionTypes from './actionTypes';
import * as constants from './constants';


const mainSelector = (state) => state[constants.MODULE_ID];


// Select patients kpis from statistics
export const clinicKpiStatistics = createSelector(
  mainSelector,
  (state) => state.clinicKpiStatistics,
);


// Select for kpi skip patient count on newestRequest
export const kpiSkip = createSelector(
  mainSelector,
  (state) => state.kpiSkip,
);

// Select total kpi patientsCount
export const totalKpi = createSelector(
  mainSelector,
  (state) => state.totalKpi,
);


/**
 * Input selectors
 */
const fetchingSelector = (state) => state[constants.MODULE_ID].fetching;
const sendingSelector = (state) => state[constants.MODULE_ID].sending;
const errorsSelector = (state) => state[constants.MODULE_ID].errors;


//----------------------------------------------------------------------------------------------------------------------

export const isSendStatsInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.SEND_STATISTICS),
);

export const hasSendStatsErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.SEND_STATISTICS),
);

export const isFetchingClinicKpiStatistics = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_CLINIC_KPI_STATISTICS),
);

export const hasFetchClinicKpiStatisticsErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.FETCH_CLINIC_KPI_STATISTICS),
);
