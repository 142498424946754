import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import times from 'lodash/times';
import { FormattedMessage } from 'react-intl';
import messages from '../../../messages';
import styles from './Cells.pcss';


class CellsLoader extends React.PureComponent {

  static propTypes = {
    itemsNumber: PropTypes.number,
  };


  static defaultProps = {
    itemsNumber: 7,
  };


  renderCell(idx) {
    return (
      <div key={`skeleton-kpis-${idx}`} className={cn(styles.kpiCell, styles.kpiCell_loader, 'fadingLoader')}>
        <div className={styles.item__textPlaceholder} />
        <div className={styles.item__textPlaceholder} />
        <div className={styles.item__textPlaceholder} />
      </div>
    );
  }

  renderSummaryCell() {
    return (
      <div className={cn(styles.kpiCell, styles.kpiCellSummary, 'fadingLoader')}>
        <div className={styles.kpiCellSummary__row}>
          <span className={styles.kpiCellSummary__title}>
            <FormattedMessage {...messages.dailyReport.readings} />
          </span>
        </div>
        <div className={styles.kpiCellSummary__row}>
          <span className={styles.kpiCellSummary__title}>
            <FormattedMessage {...messages.dailyReport.averageDay} />
          </span>
        </div>
        <div className={styles.kpiCellSummary__row}>
          <span className={styles.kpiCellSummary__title}>
            <FormattedMessage {...messages.dailyReport.daysWithoutReading} />
          </span>
        </div>
      </div>
    );
  }


  render() {
    const { itemsNumber } = this.props;
    return (
      <>
        {this.renderSummaryCell()}
        { times(itemsNumber, (idx) => this.renderCell(idx)) }
      </>
    );
  }

}

export default withStyles(styles)(CellsLoader);
