import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import map from 'lodash/map';
import { getWeekdays } from 'helpers/datetime';
import FormContainerAbstract from 'components/FormContainerAbstract';
import Form from 'components/Form';
import FormGroup from 'components/Form/FormGroup';
import Select from 'components/Form/Select';
import Button from 'components/Form/Button';
import App from 'modules/App';
import * as actions from '../../../../actions';
import * as constants from '../../../../constants';
import * as selectors from '../../../../selectors';
import * as shapes from '../../../../shapes';
import messages from '../../../../messages';
import validatorRules from './validatorRules.json';


class SettingsForm extends FormContainerAbstract {

  static getDerivedStateFromProps(props, state) {
    if (props.isInProgress !== state.isInProgress) {
      if (!props.isInProgress && !props.hasErrors) {
        return {
          isInProgress: props.isInProgress,
        };
      }
      return {
        isInProgress: props.isInProgress,
      };
    }
    return null;
  }


  static propTypes = {
    ...FormContainerAbstract.propTypes,
    // Implicit props
    settings           : shapes.settings,
    isClientInitialized: PropTypes.bool,
    locale             : PropTypes.string,
    metricsUnits       : PropTypes.object,
  };


  constructor(props) {
    super(props);
    this.validatorRules = validatorRules;
    this.state = {
      isInProgress: false,
    };
    if (!process.env.BROWSER) this.onPopulate();
  }


  componentDidMount() {
    this.onPopulate();
  }


  componentDidUpdate(prevProps) {
    const { isInProgress, hasErrors, locale } = this.props;
    if (prevProps.isInProgress !== isInProgress && !isInProgress && !hasErrors) {
      this.onCommit();
      this.props.onSetLocale(locale);
    }
  }


  onPopulate() {
    if (process.env.BROWSER && !this.props.isClientInitialized) {
      return;
    }

    const { settings, metricsUnits } = this.props;
    if (!settings) {
      return;
    }

    if (!settings.glucoseLevelUnitTypes) {
      settings.glucoseLevelUnitTypes = metricsUnits.bloodGlucoseConcentration;
    }

    this.onSetValues(settings);
  }


  renderCancelBtn() {
    return (
      <Button
        styleModifier="primary"
        className="btn--block"
        labelMessage={App.messages.buttons.discardChanges}
        isDisabled={this.isClean}
        onClick={() => this.onReset()}
      />
    );
  }


  renderSubmitBtn() {
    return (
      <Button
        type="submit"
        styleModifier="primary"
        labelMessage={App.messages.buttons.saveChanges}
        className="btn--block btn--filled"
        isDisabled={this.isClean}
        isInProgress={this.state.isInProgress}
      />
    );
  }


  renderActions() {
    return (
      <div className="row">
        <div className="col-6">{this.renderCancelBtn()}</div>
        <div className="col-6">{this.renderSubmitBtn()}</div>
      </div>
    );
  }


  renderForm() {
    return (
      <Form onSubmit={() => this.onSubmit()}>
        <div className="row">
          <div className="col-6">
            <FormGroup
              id="firstDayOfWeek"
              labelMessage={messages.labels.firstDayOfWeek}
              formValues={this.props.formValues}
            >
              <Select
                optionsFrom={map(getWeekdays(), (weekday) => ({
                  value: weekday,
                  label: moment().utc().day(weekday).format('dddd'),
                }))}
                valueKey="value"
                labelKey="label"
                onChange={(input) => this.onSetValue(input)}
              />
            </FormGroup>
          </div>
          <div className="col-6">
            <FormGroup
              id="glucoseLevelUnitTypes"
              labelMessage={messages.labels.units}
              formValues={this.props.formValues}
            >
              <Select
                optionsFrom={map(Object.keys(App.constants.BLOOD_GLUCOSE_CONCENTRATION_UNITS), (unit) => ({
                  value: unit,
                  label: App.constants.UNITS_SYMBOLS[unit],
                }))}
                valueKey="value"
                labelKey="label"
                onChange={(input) => this.onSetValue(input)}
              />
            </FormGroup>
          </div>
        </div>
        { this.renderActions() }
      </Form>
    );
  }


  render() {
    return (
      <section>
        <h2 className="section__header"><FormattedMessage {...messages.headers.settings} /></h2>
        { this.renderForm() }
      </section>
    );
  }

}


const mapStateToProps = (state) => ({
  settings           : selectors.settings(state),
  isInProgress       : selectors.isUpdateAccountSettingInProgress(state),
  hasErrors          : selectors.hasUpdateAccountSettingsErrors(state),
  metricsUnits       : selectors.metricsUnits(state),
  isClientInitialized: App.selectors.isClientInitialized(state),
  formValues         : App.selectors.formSelector(constants.SETTINGS_FORM)(state),
  locale             : App.selectors.locale(state),
});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.SETTINGS_FORM;

  return {
    onSubmit        : (values) => dispatch(actions.updateAccountSettings(values)),
    onSetFormValue  : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onSetFormValues : (values) => dispatch(App.actions.setFormValues(formName, values)),
    onFormErrors    : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onFormProcessing: () => dispatch(App.actions.startFormProcessing(formName)),
    onClearForm     : () => dispatch(App.actions.clearForm(formName)),
    onCommitForm    : () => dispatch(App.actions.commitForm(formName)),
    onResetForm     : () => dispatch(App.actions.resetForm(formName)),
    onSetLocale     : (locale) => dispatch(App.actions.setLocale(locale)),
  };
};


const ConnectedSettingsForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsForm);


export default ConnectedSettingsForm;

