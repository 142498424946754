import { createSelector } from 'reselect';
import * as constants from './constants';


/**
 * Direct selector to the domain's state
 */
const mainSelector = (state) => state[constants.MODULE_ID];


/**
 * Chart mode
 */
export const mode = createSelector(
  mainSelector,
  (state) => state.mode,
);

/**
 * Chart device mode
 */
export const deviceMode = createSelector(
  mainSelector,
  (state) => state && state.deviceMode,
);


/**
 * Calculation formula for aggregation
 */
export const calculationFormula = createSelector(
  mainSelector,
  (state) => state.calculationFormula,
);


/**
 * Time unit for aggregation
 */
export const aggregateBy = createSelector(
  mainSelector,
  (state) => state.aggregateBy,
);

/**
 * Type of grouping
 */
export const groupBy = createSelector(
  mainSelector,
  (state) => state.groupBy,
);

/**
 * Import document ids that are loaded on chart
 */
export const fromImports = createSelector(
  mainSelector,
  (state) => state.fromImports,
);

/**
 * Min and max readings timestamps for selected imports
 */
export const fromImportsRange = createSelector(
  mainSelector,
  (state) => state.fromImportsRange,
);

/**
 * Selects if trend chart line is enabled
 */
export const isTrendChartLineEnabled = createSelector(
  mainSelector,
  (state) => state.isTrendChartLineEnabled,
);

/**
 * Layout of dashboard widgets
 */
export const dashboardLayout = createSelector(
  mainSelector,
  (state) => state.dashboardLayout,
);

/**
 * Is results fetching
 */
export const resultsIsInProgress = createSelector(
  mainSelector,
  (state) => state && state.resultsIsInProgress,
);

/**
 * Reports state
 */
export const reportsState = createSelector(
  mainSelector,
  (state) => state.reportsState,
);

/**
 * Report types to be rendered on reports page
 */
export const reportTypes = createSelector(
  mainSelector,
  (state) => state.reportTypes,
);

export const gestationalReportSettingsSelector = createSelector(
  mainSelector,
  (state) => state.gestationalReportSettings,
);

export const standardsSelector = createSelector(
  reportsState,
  (state) => state.standards,
);
