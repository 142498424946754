import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import throttle from 'lodash/throttle';
import toLower from 'lodash/toLower';
import Form from 'components/Form';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import Loupe from 'svg/loupe.svg';
import App from 'modules/App';
import * as actions from '../../actions';
import * as constants from '../../constants';
import messages from '../../messages';
import styles from './SearchPatients.pcss';


class SearchPatients extends React.PureComponent {

  static propTypes = {
    // Explicit props
    forId                 : PropTypes.string.isRequired,
    activeClinicMembership: PropTypes.object,
    clearOnBlur           : PropTypes.bool,
    // Implicit props
    formValues            : PropTypes.object,
    // Implicit actions
    onSetSearch           : PropTypes.func,
    onSetFormValue        : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.onSetSearch = throttle((phrase) => props.onSetSearch(props.forId, phrase), 300);
  }


  componentDidUpdate(prevProps) {
    const { activeClinicMembership } = this.props;
    if (prevProps.activeClinicMembership !== activeClinicMembership) {
      this.onChange({
        id   : `${this.props.forId}-search`,
        value: '',
      });
    }
  }


  onBlur() {
    if (this.props.clearOnBlur) {
      setTimeout(
        () => this.onChange({ id: `${this.props.forId}-search`, value: '' }), 300,
      );
    }
  }

  onChange(input) {
    this.props.onSetFormValue(input);
    this.onSetSearch(toLower(input.value));
  }


  render() {
    return (
      <Form className={styles.searchPatients}>
        <FormGroup
          id={`${this.props.forId}-search`}
          className={styles.searchPatients__formGroup}
          formValues={this.props.formValues}
        >
          <Input
            placeholder={messages.placeholders.searchPatients}
            className={styles.searchPatients__formGroup__input}
            attributes={{ autoComplete: 'off' }}
            onChange={(input) => this.onChange(input)}
            onBlur={() => this.onBlur()}
          />
        </FormGroup>
        <Loupe className={styles.searchPatients__icon} />
      </Form>
    );
  }

}


const mapStateToProps = (state, props) => ({
  formValues: App.selectors.formSelector(props.formName || constants.SEARCH_PATIENTS_FORM)(state),
});


const mapDispatchToProps = (dispatch, props) => {
  const formName = props.formName || constants.SEARCH_PATIENTS_FORM;

  return {
    onSetSearch     : (forId, phrase) => dispatch(actions.setPatientsSearch(forId, phrase)),
    onSetFormValue  : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onFormErrors    : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onFormProcessing: () => dispatch(App.actions.startFormProcessing(formName)),
    onClearForm     : () => dispatch(App.actions.clearForm(formName)),
  };
};


const ConnectedSearchPatients = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchPatients);


export default withStyles(styles)(ConnectedSearchPatients);
