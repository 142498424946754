import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { highlightedHourlyRecordsShape } from 'shapes/bins';
import App from 'modules/App';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from './Statistics.pcss';


class Statistics extends React.Component {

  static propTypes = {
    // Implicit props
    highlightedHourlyRecords         : highlightedHourlyRecordsShape,
    highlightedDates                 : PropTypes.arrayOf(PropTypes.string),
    highlightedRecords               : PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.number })),
    highlightedTimeIsActive          : PropTypes.number,
    highlightedAverageGlucose        : PropTypes.number,
    highlightedCoefficientOfVariation: PropTypes.number,
    // Explicit props
    overwriteClassName               : PropTypes.string,
    conversion                       : PropTypes.object.isRequired,
    statistics                       : PropTypes.shape({
      veryHigh: PropTypes.number,
      high    : PropTypes.number,
      target  : PropTypes.number,
      low     : PropTypes.number,
      veryLow : PropTypes.number,
    }),
    standards: PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    direction: PropTypes.string,
  };


  renderTimeLabel(minutes) {
    return (
      <>
        {'('}
        {!!Math.floor(minutes / 60)
          && (
            <>
              {Math.floor(minutes / 60)}
              <FormattedMessage {...messages.labels.h} />
            </>
          )
        }
        &nbsp;
        {!!(minutes % 60)
          && (
            <>
              {Math.round(minutes % 60)}
              <FormattedMessage {...messages.labels.min} />
            </>
          )
        }
        {!Math.floor(minutes / 60) && !(minutes % 60)
          && (
            <>
              {0}
              <FormattedMessage {...messages.labels.min} />
            </>
          )
        }
        {')'}
      </>
    );
  }


  renderGlucoseManagementIndicator() {
    const { conversion, highlightedAverageGlucose } = this.props;
    if (!highlightedAverageGlucose) {
      return (
        <>
          { ' - %'}
        </>
      );
    }
    if (conversion.unitSymbol === App.constants.UNITS_SYMBOLS.MG_DL) {
      return (
        <>
          { (3.31 + highlightedAverageGlucose * 0.02392).toPrecision(2) }
          { '%'}
        </>
      );
    }
    if (conversion.unitSymbol === App.constants.UNITS_SYMBOLS.MMOL_L) {
      return (
        <>
          { (3.31 + highlightedAverageGlucose * 0.43099).toPrecision(2) }
          { '%'}
        </>
      );
    }
    return '';
  }


  render() {
    const {
      conversion,
      standards,
      highlightedDates,
      statistics,
      highlightedTimeIsActive,
      highlightedAverageGlucose,
      highlightedCoefficientOfVariation,
      overwriteClassName,
    } = this.props;
    if (!highlightedDates.length) return null;
    const startTime = moment(highlightedDates[0], 'YYYY-MM-DD');
    const endTime = moment(highlightedDates[highlightedDates.length - 1], 'YYYY-MM-DD');
    return (
      <div className={overwriteClassName || styles.cgmStatistics}>
        <div className="statisticsSection">
          <div className="statisticsSection__row">
            <div className="statisticsSection__label">
              <p className="statisticsSection__elementHighlight">
                {startTime.format('DD  MMM YYYY')}
                {' - '}
                {endTime.format('DD  MMM YYYY')}
              </p>
            </div>
            <div className="statisticsSection__value">
              <p className="statisticsSection__elementHighlight">
                {endTime.diff(startTime, 'days') + 1}
                &nbsp;
                <FormattedMessage {...messages.labels.days} />
              </p>
            </div>
          </div>
          <div className="statisticsSection__row">
            <div className="statisticsSection__label">
              <p className="statisticsSection__elementHighlight">
                <FormattedMessage {...messages.labels.timeCgmActive} />
              </p>
            </div>
            <div className="statisticsSection__value">
              <p className="statisticsSection__elementHighlight">
                {highlightedTimeIsActive ? highlightedTimeIsActive.toPrecision(3) : '- '}%
              </p>
            </div>
          </div>
        </div>
        <div className="statisticsSection">
          <div className="statisticsSection__row">
            <div className="statisticsSection__label">
              <p className="statisticsSection__elementHighlight">
                <FormattedMessage {...messages.labels.glucoseRanges} />
              </p>
            </div>
            <div className="statisticsSection__value">
              <p className="statisticsSection__elementHighlight">
                <FormattedMessage {...messages.labels.targets} />
                    &nbsp;
                <span className="statisticsSection__element">&nbsp;
                  <FormattedMessage {...messages.labels.percentOfReadings} />
                </span>
              </p>
            </div>
          </div>
          <div className="statisticsSection__row">
            <div className="statisticsSection__label">
              <p className="statisticsSection__element">
                <FormattedMessage {...messages.labels.targetRange} />&nbsp;
                {`${conversion.toDisplay(standards.postMeal.lowThreshold)} - ${conversion.toDisplay(standards.postMeal.highThreshold)}`}
                &nbsp;
                {conversion.unitSymbol}
              </p>
            </div>
            <div className="statisticsSection__value">
              <p className="statisticsSection__element">
                {Math.round(statistics.target * 100)}% &nbsp;
                {this.renderTimeLabel(statistics.target * 1440)}
              </p>
            </div>
          </div>
          <div className="statisticsSection__row">
            <div className="statisticsSection__label">
              <p className="statisticsSection__element">
                <FormattedMessage {...messages.labels.below} />&nbsp;
                {conversion.toDisplay(standards.postMeal.lowThreshold)}
                &nbsp;
                {conversion.unitSymbol}
              </p>
            </div>
            <div className="statisticsSection__value">
              <p className="statisticsSection__element">
                {Math.round(statistics.low * 100)}% &nbsp;
                {this.renderTimeLabel(statistics.low * 1440)}
              </p>
            </div>
          </div>
          <div className="statisticsSection__row">
            <div className="statisticsSection__label">
              <p className="statisticsSection__element">
                <FormattedMessage {...messages.labels.below} />&nbsp;
                {conversion.toDisplay(54)}
                &nbsp;
                {conversion.unitSymbol}
              </p>
            </div>
            <div className="statisticsSection__value">
              <p className="statisticsSection__element">
                {Math.round(statistics.veryLow * 100)}% &nbsp;
                {this.renderTimeLabel(statistics.veryLow * 1440)}
              </p>
            </div>
          </div>
          <div className="statisticsSection__row">
            <div className="statisticsSection__label">
              <p className="statisticsSection__element">
                <FormattedMessage {...messages.labels.above} />&nbsp;
                {conversion.toDisplay(standards.postMeal.highThreshold)}
                &nbsp;
                {conversion.unitSymbol}
              </p>
            </div>
            <div className="statisticsSection__value">
              <p className="statisticsSection__element">
                {Math.round(statistics.high * 100)}% &nbsp;
                {this.renderTimeLabel(statistics.high * 1440)}
              </p>
            </div>
          </div>
          <div className="statisticsSection__row">
            <div className="statisticsSection__label">
              <p className="statisticsSection__element">
                <FormattedMessage {...messages.labels.above} />&nbsp;
                {conversion.toDisplay(250)}
                &nbsp;
                {conversion.unitSymbol}
              </p>
            </div>
            <div className="statisticsSection__value">
              <p className="statisticsSection__element">
                {Math.round(statistics.veryHigh * 100)}% &nbsp;
                {this.renderTimeLabel(statistics.veryHigh * 1440)}
              </p>
            </div>
          </div>
          <div className="statisticsSection__row">
            <div className="statisticsSection__label">
              <p className="statisticsSection__elementCaption">
                <FormattedMessage {...messages.labels.increaseCaption} />
              </p>
            </div>
          </div>
        </div>
        <div className="statisticsSection">
          <div className="statisticsSection__row">
            <div className="statisticsSection__label">
              <p className="statisticsSection__elementHighlight">
                <FormattedMessage {...messages.labels.averageGlucose} />
              </p>
            </div>
            <div className="statisticsSection__value">
              <p className="statisticsSection__elementHighlight">
                { highlightedAverageGlucose ? conversion.toDisplay(highlightedAverageGlucose) : '- '}
                &nbsp;
                {conversion.unitSymbol}
              </p>
            </div>
          </div>
          <div className="statisticsSection__row">
            <div className="statisticsSection__label">
              <p className="statisticsSection__elementHighlight">
                <FormattedMessage {...messages.labels.glucoseManagementIndicator} />
              </p>
            </div>
            <div className="statisticsSection__value">
              <p className="statisticsSection__elementHighlight">
                {this.renderGlucoseManagementIndicator()}
              </p>
            </div>
          </div>
          <div className="statisticsSection__row">
            <div className="statisticsSection__label">
              <p className="statisticsSection__elementHighlight">
                <FormattedMessage {...messages.labels.glucoseVariability} />
              </p>
            </div>
            <div className="statisticsSection__value">
              <p className="statisticsSection__elementHighlight">
                {highlightedCoefficientOfVariation ? highlightedCoefficientOfVariation.toPrecision(3) : '- '}%
              </p>
            </div>
          </div>
          <div className="statisticsSection__row">
            <div className="statisticsSection__label">
              <p className="statisticsSection__elementCaption">
                <FormattedMessage {...messages.labels.definedAsPercentCaption} />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state, props) => ({
  dailyRecords                     : selectors.dailyRecords(state),
  highlightedDates                 : selectors.highlightedDates(state),
  highlightedRecords               : selectors.highlightedRecords(state),
  statistics                       : selectors.highlightedStatisticsFactory(props.standards)(state),
  highlightedTimeIsActive          : selectors.highlightedTimeIsActive(state),
  highlightedAverageGlucose        : selectors.highlightedAverageGlucose(state),
  highlightedCoefficientOfVariation: selectors.highlightedCoefficientOfVariation(state),
  direction                        : App.selectors.direction(state),
});


const ConnectedStatistics = connect(
  mapStateToProps,
)(Statistics);


export default withStyles(styles)(ConnectedStatistics);
