// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1o2xa{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}._1o2xa .label{white-space:nowrap;font-weight:500;font-size:13px;color:#6f7ea2}._1o2xa .perPageSelect{height:36px;width:66px;position:relative}._1o2xa .select__selected-field{padding:0;height:100%;display:-ms-flexbox!important;display:flex!important;-ms-flex-align:center;align-items:center;border-radius:5px;border:none;background:none;font-weight:500!important;font-size:13px}", ""]);
// Exports
exports.locals = {
	"perPageSelect": "_1o2xa"
};
module.exports = exports;
