import PropTypes from 'prop-types';
import accessTokenShape from 'shapes/accessTokenShape';


export const sharingRequest = PropTypes.shape({
  sharingRequestId     : PropTypes.number.isRequired,
  clinicHcpMembershipId: PropTypes.number,
  invitationCode       : PropTypes.string,
  sharingStatus        : PropTypes.oneOf(
    [
      'Pending', 'Received', 'PendingEnrolling', 'Enrolling',
      'Approved', 'Cancelled', 'Rejected', 'Revoked', 'Expired',
    ]
  ).isRequired,
  clinic: PropTypes.shape({
    id       : PropTypes.number.isRequired,
    city     : PropTypes.string.isRequired,
    name     : PropTypes.string.isRequired,
    publicKey: PropTypes.string.isRequired,
  }).isRequired,
  hcp: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName : PropTypes.string.isRequired,
    avatar   : PropTypes.string,
  }),
});

export const familyLinkInvitation = PropTypes.shape({
  familyLinkId  : PropTypes.number.isRequired,
  invitationCode: PropTypes.string.isRequired,
  status        : PropTypes.string.isRequired,
  requestor     : PropTypes.shape({
    firstName  : PropTypes.string.isRequired,
    lastName   : PropTypes.string.isRequired,
    avatar     : PropTypes.string,
    profileType: PropTypes.string.isRequired,
    profileId  : PropTypes.number.isRequired,
  }),
  encryptedExchangeToken     : PropTypes.string,
  encryptedPhiSetReferenceKey: PropTypes.string,
  patientProfileId           : PropTypes.number,
  storageProvider            : PropTypes.string,
});

export const familyLinkRequest = PropTypes.shape({
  familyLinkId  : PropTypes.number.isRequired,
  invitationCode: PropTypes.string.isRequired,
  status        : PropTypes.string.isRequired,
  invitee       : PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName : PropTypes.string.isRequired,
    avatar   : PropTypes.string,
    email    : PropTypes.string.isRequired,
  }),
  encryptedExchangeToken     : PropTypes.string,
  encryptedPhiSetReferenceKey: PropTypes.string,
  patientProfileId           : PropTypes.number,
  storageProvider            : PropTypes.string,
  identityUID                : PropTypes.string.isRequired,

});

export const activeFamilyLink = PropTypes.shape({
  familyLinkId      : PropTypes.number.isRequired,
  firstName         : PropTypes.string.isRequired,
  lastName          : PropTypes.string.isRequired,
  avatar            : PropTypes.string,
  phiSetReferenceKey: PropTypes.string.isRequired,
  storageProvider   : PropTypes.string.isRequired,
  accessToken       : accessTokenShape.isRequired,
});
