import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import App from 'modules/App';
import Link from 'components/Link';
import Locked from 'svg/locked.svg';
import UnLocked from 'svg/unlocked.svg';
import styles from './Locked.pcss';


class LockedComponent extends React.PureComponent {

    static propTypes = {
    // Explicit props
      isLocked: PropTypes.bool,
      onClick : PropTypes.func,
    };


    static defaultProps = {
      isLocked: false,
      onClick : () => {},
    }


    onLockedClick(e) {
      e.preventDefault();
      e.stopPropagation();
      this.props.onClick();
    }


    renderIcon() {
      const { isLocked } = this.props;

      return isLocked ? <Locked /> : <UnLocked />;
    }


    renderLabel() {
      const { isLocked } = this.props;

      const lockState = isLocked ? 'locked' : 'unlocked';

      return <FormattedMessage {...App.messages.labels[lockState]} />;

    }


    render() {
      return (
        <Link to="" className={styles.locked} onClick={(e) => this.onLockedClick(e)}>
          {this.renderIcon()}
          {this.renderLabel()}
        </Link>
      );
    }

}

export default withStyles(styles)(LockedComponent);
