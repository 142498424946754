export default {
  errors: {
    businessErrors: {
      ContourCloudNotRespond                   : { id: 'account.errors.businessErrors.ContourCloudNotRespond' },
      NoContourCloudAccessToken                : { id: 'account.errors.businessErrors.NoContourCloudAccessToken' },
      UnableToGetNewAccessTokenWithRefreshToken: { id: 'account.errors.businessErrors.UnableToGetNewAccessTokenWithRefreshToken' },
      UserOrPasswordIncorrect                  : { id: 'account.errors.businessErrors.UserOrPasswordIncorrect' },
    },
  },
};
