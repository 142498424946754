import { buffers, eventChannel, END } from 'redux-saga';
import { all, call, take } from 'redux-saga/effects';
import get from 'lodash/get';
import { v4 as uuid } from 'uuid';
import { ApiError } from 'helpers/errorTypes';


const xhrRequest = ({
  emitter,
  accessToken,
  method,
  url,
  body = null,
  responseType = 'json',
} = {}) => {
  const xhr = new XMLHttpRequest();

  const onSuccess = (json) => {
    emitter({ success: true, response: json });
    emitter(END);
  };

  const onFailure = (json) => {
    emitter({ success: false, response: json });
    emitter(END);
  };

  xhr.addEventListener('error', onFailure);
  xhr.addEventListener('abort', onFailure);
  xhr.onreadystatechange = () => {
    const { readyState, status, response } = xhr;
    if (readyState === 4) {
      switch (responseType) {
        case 'arraybuffer': {
          const parsedResponse = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(response)));
          if (status === 200) {
            onSuccess(parsedResponse);
          } else {
            onFailure(parsedResponse);
          }
          break;
        }
        case 'blob': {
          const reader = new FileReader();
          reader.addEventListener('loadend', (evt) => {
            const parsedResponse = JSON.parse(evt.target.result);
            if (status === 200) {
              onSuccess(parsedResponse);
            } else {
              onFailure(parsedResponse);
            }
          });
          if (response) reader.readAsText(response);
          break;
        }
        default: {
          if (status === 200) {
            onSuccess(response);
          } else {
            onFailure(response);
          }
        }
      }
    }
  };

  xhr.open(method, url, true);
  xhr.setRequestHeader('Authorization', `${accessToken.tokenType} ${accessToken.accessToken}`);
  xhr.responseType = responseType;
  xhr.send(body);
  return () => {
    xhr.removeEventListener('error', onFailure);
    xhr.removeEventListener('abort', onFailure);
    xhr.onreadystatechange = null;
    xhr.abort();
  };
};


const downloadChannel = ({ documentId, accessToken } = {}) => eventChannel((emitter) => {
  const method = 'get';
  const url = `https://www.googleapis.com/drive/v3/files/${documentId}?alt=media`;
  const responseType = 'blob';

  return xhrRequest({
    emitter,
    accessToken,
    method,
    url,
    responseType,
  });

}, buffers.sliding(2));


const uploadChannel = ({ content, metadata, accessToken } = {}) => eventChannel((emitter) => {
  const body = new FormData();
  body.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
  body.append('file', new Blob([content], { type: metadata.mimeType }));

  const method = 'post';
  const url = 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id';

  return xhrRequest({
    emitter,
    accessToken,
    method,
    url,
    body,
  });
}, buffers.sliding(2));


function* downloadFile(documentId, accessToken) {
  const downloadFileChannel = yield call(downloadChannel, {
    documentId,
    accessToken,
  });

  while (true) {
    const { success, response } = yield take(downloadFileChannel);
    if (!success) {
      const message = get(response, 'error.message');
      throw new ApiError({
        businessError: { code: 'GoogleDriveError', params: { message } },
      });
    }
    if (success) {
      return response;
    }
  }
}


function* uploadFile(content, metadata, accessToken) {
  const uploadFileChannel = yield call(uploadChannel, {
    content,
    metadata,
    accessToken,
  });

  while (true) {
    const { success, response } = yield take(uploadFileChannel);
    if (!success) {
      const message = get(response, 'error.message');
      throw new ApiError({
        businessError: { code: 'GoogleDriveError', params: { message } },
      });
    }
    if (success) {
      return response.id;
    }
  }
}


function* uploadJson(json, accessToken, auditDocument, name = '') {
  const content = JSON.stringify(json);

  const metadata = {
    name    : `${name || uuid()}.json`,
    mimeType: 'application/json',
    parents : process.env.NODE_ENV === 'production' ? ['appDataFolder'] : [],
  };

  if (!auditDocument) {
    const documentId = yield call(uploadFile, content, metadata, accessToken);
    return { documentId };
  }

  const auditDocumentContent = JSON.stringify(auditDocument);
  const metadataAudit = {
    name    : `${uuid()}.json`,
    mimeType: 'application/json',
    parents : process.env.NODE_ENV === 'production' ? ['appDataFolder'] : [],
  };

  const [documentId, auditDocumentId] = yield all([
    call(uploadFile, content, metadata, accessToken),
    call(uploadFile, auditDocumentContent, metadataAudit, accessToken),
  ]);

  return { documentId, auditDocumentId };
}


function* deleteFile(documentId, accessToken) {
  if (!documentId) {
    return null;
  }
  try {
    const requestURL = `https://www.googleapis.com/drive/v3/files/${documentId}`;
    yield call(fetch, requestURL, {
      method : 'DELETE',
      headers: {
        Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
      },
    });
    return documentId;
  } catch (err) {
    return null;
  }
}


export default {
  downloadFile,
  uploadFile,
  uploadJson,
  deleteFile,
};
