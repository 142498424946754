import React, { FC, useEffect } from 'react';
import { MessageDescriptor } from 'react-intl';
import { CONNECTOR_TYPES } from '../../../constants';
import messages from '../../../messages';
import MdaConnectionFailed from './MdaConnectionFailed';
import SccConnectionFailed from './SccConnectionFailed';


interface Props {
  connectorType: ConnectorType,
  selectedChannel: string,
  onSetComponent: (componentName: string) => void,
  onSetHeaderMessage: (headerMessage?: MessageDescriptor) => void,
}

const ConnectionFailed: FC<Props> = ({ connectorType, selectedChannel, onSetComponent, onSetHeaderMessage }) => {
  useEffect(() => {
    onSetHeaderMessage(messages.headers.connectedFailed);
    return () => onSetHeaderMessage();
  }, []);

  if (connectorType === CONNECTOR_TYPES.SCC) {
    return <SccConnectionFailed {...{ onSetComponent }} />;
  }
  return <MdaConnectionFailed {...{ selectedChannel, onSetComponent }} />;
};

export default ConnectionFailed;
