import { saveAs } from 'file-saver';


export const processCSVRow = (row) => {
  let finalVal = '';
  for (let j = 0; j < row.length; j++) {
    let innerValue = row[j] === null ? '' : row[j].toString();
    if (row[j] instanceof Date) {
      innerValue = row[j].toLocaleString();
    }
    let result = innerValue.replace(/"/g, '""');
    if (result.search(/("|,|\n)/g) >= 0) result = `"${result}"`;
    if (j > 0) finalVal += ',';
    finalVal += result;
  }
  return `${finalVal}\n`;
};


export const createCSV = (name, csvFileContent) => {
  const BOM = '\uFEFF';
  const blob = new Blob([BOM + csvFileContent], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, `${name}.csv`);
};


export function saveAsCSV(name, header = [], rows = [[]]) {
  let csvFileContent = '';
  csvFileContent += processCSVRow(header);
  for (let i = 0; i < rows.length; i++) {
    csvFileContent += processCSVRow(rows[i]);
  }
  createCSV(name, csvFileContent);
}

