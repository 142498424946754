import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import isObject from 'lodash/isObject';
import { lightenDarkenColor } from 'helpers/color';
import Button from 'components/Form/Button';
import stylesVariables from 'common/_variables';
import XIcon from 'svg/x.svg';


class Alert extends React.PureComponent {

  static propTypes = {
    // Explicit props
    id     : PropTypes.string,
    idx    : PropTypes.number,
    type   : PropTypes.oneOf(['error', 'success', 'warning', 'info']),
    message: PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.string,
      }),
      PropTypes.string,
      PropTypes.array,
    ]),
    messageValues   : PropTypes.object,
    actions         : PropTypes.array,
    isCollapsed     : PropTypes.bool,
    // Explicit actions
    onClose         : PropTypes.func,
    onToggleCollapse: PropTypes.func,
  };


  static defaultProps = {
    idx        : 0,
    isCollapsed: false,
  };


  onAction(evt, action) {
    evt.stopPropagation();
    action();
    if (this.props.onClose) this.props.onClose();
  }


  onClose(evt) {
    evt.stopPropagation();
    if (this.props.onClose) this.props.onClose();
  }


  get style() {
    const { idx, type, isCollapsed } = this.props;
    if (!isCollapsed) {
      return null;
    }
    const color = stylesVariables[`brand-${type}`];
    return {
      marginLeft     : `${8 * idx}px`,
      marginRight    : `${8 * idx}px`,
      backgroundColor: lightenDarkenColor(color, -25 * idx),
    };
  }


  renderContent() {
    const { message, messageValues } = this.props;
    return (
      <div className="alert_content">
        {
          isObject(message) && message.id
            ? <FormattedMessage {...message} values={messageValues || {}} />
            : message
        }
      </div>
    );
  }


  renderActions() {
    const { id, actions } = this.props;
    if (!actions) {
      return null;
    }
    return actions.map((action) => (
      <Button
        key={`alert-button-${action.message.id}-${id}`}
        className="btn--sm btn--white alert_button"
        isDisabled={action.isDisabled}
        onClick={(evt) => this.onAction(evt, action.action)}
      >
        <FormattedMessage {...action.message} />
      </Button>
    ));
  }


  renderCloseBtn() {
    if (!this.props.onClose) {
      return null;
    }
    return <XIcon className="alert_close" onClick={(evt) => this.onClose(evt)} />;
  }


  /* eslint-disable jsx-a11y/click-events-have-key-events */
  render() {
    const { type } = this.props;
    const onToggleCollapse = () => this.props.onToggleCollapse && this.props.onToggleCollapse();
    return (
      <div
        className={`alert alert-${type}`}
        style={this.style}
        role="alert"
        onClick={onToggleCollapse}
      >
        { this.renderContent() }
        { this.renderActions() }
        { this.renderCloseBtn() }
      </div>
    );
  }

}

export default Alert;
