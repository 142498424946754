import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Form/Button';
import messages from '../../messages';
import styles from './DownloadDataModal.pcss';


interface Props {
  onSetComponent: (componentName: string) => void,
}


const Timeout: FC<Props> = ({ onSetComponent }) => {
  const onTryAgain = () => onSetComponent('Initializing');

  return (
    <div className={styles.modal__content}>
      <h3 className="modal__subheader "><FormattedMessage {...messages.timeout.header} /></h3>
      <div className={styles.illustration}>
        <img src="/assets/svg/timeout.svg" alt="" />
      </div>
      <div className="modal__actions">
        <Button
          styleModifier="primary"
          labelMessage={messages.buttons.tryAgain}
          className="btn--block btn--filled"
          onClick={onTryAgain}
        />
      </div>
    </div>
  );
};


export default Timeout;
