import * as actions from './actions';
import * as actionTypes from './actionTypes';
import * as constants from './constants';
import * as selectors from './selectors';
import * as shapes from './shapes';
import reducer from './reducer';
import sagas from './sagas';
import messages from './messages';

import AccountSettings from './components/AccountSettings';
import ElevatePermissionsModal from './components/ElevatePermissionsModal';
import WarningInfoCC from './components/WarningInfoCC';
import ReauthToCCModal from './components/ReauthToCCModal';

import MyResultsBtn from './partials/MyResultsBtn';
import LogoutBtn from './partials/LogoutBtn';


const components = {
  AccountSettings,
  ElevatePermissionsModal,
  WarningInfoCC,
  ReauthToCCModal,
};


const partials = {
  MyResultsBtn,
  LogoutBtn,
};


export default {
  actions,
  actionTypes,
  components,
  constants,
  partials,
  reducer,
  selectors,
  sagas,
  shapes,
  messages,
};
