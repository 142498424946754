import PropTypes from 'prop-types';


export const consentTemplate = PropTypes.shape({
  legalConsentId  : PropTypes.number.isRequired,
  type            : PropTypes.string.isRequired,
  consentPromptKey: PropTypes.string.isRequired,
  consentUrl      : PropTypes.string,
  countryCode     : PropTypes.string,
  isActive        : PropTypes.bool,
  isMandatory     : PropTypes.bool,
});

export const consentApproval = PropTypes.shape({
  legalConsentId: PropTypes.number.isRequired,
  decision      : PropTypes.bool,
});
