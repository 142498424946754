import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { AppContext } from 'context';
import history from 'helpers/history';
import urlRouteShape from 'shapes/urlRouteShape';
import Modal from 'components/Modal';
import Button from 'components/Form/Button';
import App from 'modules/App';
import ContourCloud from 'modules/ContourCloud';
import ReauthToCC from '../ReauthToCC';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as constants from '../../constants';
import messages from '../../messages';


class ReauthToCCModal extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Implicit props
    hcpProfileMainUrlRoute    : urlRouteShape,
    patientProfileMainUrlRoute: urlRouteShape,
    openModalId               : PropTypes.string,
    isInProgress              : PropTypes.bool,
    isHcpAccount              : PropTypes.bool,
    activeProfileType         : PropTypes.string,
    // Implicit actions
    onClose                   : PropTypes.func,
    onSetProfileType          : PropTypes.func,
  };


  componentDidUpdate(prevProps) {
    const { activeProfileType } = this.props;
    if (activeProfileType === constants.PROFILE_TYPES.HCP
      && prevProps.activeProfileType !== constants.PROFILE_TYPES.HCP) {
      this.props.onClose();
    }
  }


  onSetProfile(profileType) {
    const { getUrl } = this.context;
    this.props.onSetProfileType(profileType);
    const { name, params } = profileType === constants.PROFILE_TYPES.HCP
      ? this.props.hcpProfileMainUrlRoute
      : this.props.patientProfileMainUrlRoute;
    history.push(getUrl(name, params));
  }


  renderBackToProffesionalProfile() {
    if (!this.props.isHcpAccount) {
      return null;
    }

    return (
      <Button
        styleModifier="quaternary"
        onClick={() => this.onSetProfile(constants.PROFILE_TYPES.HCP)}
      >
        <FormattedMessage {...messages.buttons.backToHcpProfile} />
      </Button>
    );
  }

  render() {
    if (this.props.activeProfileType !== constants.PROFILE_TYPES.PWD) {
      return null;
    }

    return (
      <Modal
        modalId={ContourCloud.constants.REAUTH_TO_CC_MODAL}
        openModalId={this.props.openModalId}
        isInProgress={this.props.isInProgress}
      >
        <ReauthToCC onSuccess={this.props.onClose} />
        {this.renderBackToProffesionalProfile()}
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  hcpProfileMainUrlRoute    : selectors.hcpProfileMainUrlRoute(state),
  patientProfileMainUrlRoute: selectors.patientProfileMainUrlRoute(state),
  activeProfileType         : selectors.activeProfileType(state),
  isHcpAccount              : selectors.isHcpAccount(state),
  openModalId               : App.selectors.modal(state),
  isInProgress              : ContourCloud.selectors.isReauthInProgress(state),
});


const mapDispatchToProps = (dispatch) => ({
  onClose         : () => dispatch(App.actions.closeModal()),
  onSetProfileType: (profileType) => dispatch(actions.setActiveProfileType(profileType)),
});

const ConnectedReauthToCCModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReauthToCCModal);


export default ConnectedReauthToCCModal;
