// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".yMoPM{padding:0 0 4rem;line-height:1;height:auto;font-weight:700}.yMoPM,.yMoPM:hover{text-decoration:underline}.yMoPM .btn__labelWrapper{padding:0}", ""]);
// Exports
exports.locals = {
	"addAnotherPersonBtn": "yMoPM"
};
module.exports = exports;
