import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';
import QRCode from 'qrcode.react';
import { motion } from 'framer-motion';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import debounce from 'lodash/debounce';
import findLast from 'lodash/findLast';
import get from 'lodash/get';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import includes from 'lodash/includes';
import keysIn from 'lodash/keysIn';
import pick from 'lodash/pick';
import startsWith from 'lodash/startsWith';
import isEmpty from 'lodash/isEmpty';
import history from 'helpers/history';
import { getSlug } from 'helpers/urlTools';
import { formatISODate } from 'helpers/datetime';
import { createValidatorRules } from 'helpers/templatedFields';
import { AppContext } from 'context';
import MetricConversions from 'libs/MetricConversions';
import Validator from 'libs/Validator';
import { foldObject, unfoldObject } from 'helpers/transformers';
import TemplatedFields from 'components/TemplatedFields';
import FormContainerAbstract from 'components/FormContainerAbstract';
import Form from 'components/Form';
import FormGroup from 'components/Form/FormGroup';
import CheckboxRadio from 'components/Form/CheckboxRadio';
import Input from 'components/Form/Input';
import AutosuggestInput from 'components/Form/AutosuggestInput';
import Button from 'components/Form/Button';
import Tabs from 'components/Tabs';
import DateOfBirthFormPartial from 'components/DateOfBirthFormPartial';
import PersonalIdentifierPartial from 'components/PersonalIdentifierPartial';
import PhiSetFormPartial from 'components/PhiSetFormPartial';
import countrySettingsShape from 'shapes/countrySettingsShape';
import informationFieldShape from 'shapes/informationFieldShape';
import intlShape from 'shapes/intlShape';
import ChevronRight from 'svg/chevron-right.svg';
import App from 'modules/App';
import Account from 'modules/Account';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import styles from './AddPatientForm.pcss';
import validatorRules from './validatorRules.json';


class AddPatientForm extends FormContainerAbstract {

  static contextType = AppContext;

  static getDerivedStateFromProps(props, state) {
    const { isInProgress, hasErrors, isAutoActivation } = props;
    const { view } = state;
    if (
      isInProgress !== state.isInProgress
      && (isInProgress || hasErrors || (view === 'qrCode' && !isAutoActivation))
    ) {
      return {
        isInProgress,
      };
    }
    return null;
  }

  static propTypes = {
    ...FormContainerAbstract.propTypes,
    // Explicit props
    modalId               : PropTypes.string,
    isAutoActivation      : PropTypes.bool,
    isManualOnly          : PropTypes.bool,
    isEnrolling           : PropTypes.bool,
    // Explicit actions
    onClose               : PropTypes.func,
    // Implicit props
    activeClinicMembership: Account.shapes.clinicMembership,
    receivedSharingRequest: shapes.sharingRequest,
    patients              : PropTypes.arrayOf(PropTypes.shape({
      id            : PropTypes.string.isRequired,
      invitationCode: PropTypes.string,
      firstName     : PropTypes.string,
      lastName      : PropTypes.string,
    })),
    isHcpAccount                               : PropTypes.bool,
    metricsUnits                               : PropTypes.object, // @TODO: Shape
    clinicPatientTemplate                      : PropTypes.arrayOf(informationFieldShape),
    payers                                     : PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })),
    localizationResources                      : PropTypes.object,
    openModalId                                : PropTypes.string,
    intl                                       : intlShape,
    countrySettings                            : countrySettingsShape,
    isFetchPatientCountryLocalizationInProgress: PropTypes.bool,
    hasFetchPatientCountryLocalizationError    : PropTypes.bool,
    activeProfileType                          : Account.shapes.profileType,
    // Implicit actions
    onCheckApprove                             : PropTypes.func,
    onBindWithClinicPatient                    : PropTypes.func,
    onStopCheckApprove                         : PropTypes.func,
    onUnsetReceivedSharingRequest              : PropTypes.func,
    onFetchPatientCountryLocalization          : PropTypes.func,
    onFetchPayers                              : PropTypes.func,
    onAddPayerProposal                         : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      view                     : 'manual', // 'manual' / 'qrCode'
      isDateOfBirthMandatory   : false,
      isInProgress             : false,
      isOptionalSectionOpen    : false,
      isOptionalSectionOverflow: false,
    };
    this.metricConversions = new MetricConversions(props.metricsUnits);
    this.invitationCode = uuid();
    this.patientId = null;
    this.validatorRules = { ...validatorRules };
    this.personalIdentifierFields = [];
    this.onDebouncedEnterName = debounce(() => this.onEnterName(), 150);

    const countryId = get(props.activeClinicMembership, 'clinic.countryId');
    props.onFetchPatientCountryLocalization(countryId);
    props.onFetchPayers(countryId);
    this.onSetupPersonalIdentifier();
  }


  componentDidUpdate(prevProps) {
    const {
      receivedSharingRequest, clinicPatientTemplate,
      modalId, openModalId,
      isInProgress, hasErrors,
    } = this.props;

    if (openModalId !== modalId) {
      return;
    }
    if (prevProps.clinicPatientTemplate !== clinicPatientTemplate) {
      this.onSetupPersonalIdentifier();
    }

    if (
      receivedSharingRequest
      && receivedSharingRequest !== prevProps.receivedSharingRequest
      && receivedSharingRequest.sharingStatus === 'Enrolling'
    ) {
      const { sharingRequestId, patient } = receivedSharingRequest;
      const { organizationUID, name } = get(this.props.activeClinicMembership, 'clinic', {});
      const clinicSlug = getSlug(name);
      const patientSlug = getSlug(`${patient.firstName} ${patient.lastName}`);
      const patientId = `esr-${sharingRequestId}`;
      const url = this.context.getUrl('hcp-results', { clinicSlug, organizationUID, patientSlug, patientId });
      history.push(url);
      this.onClose();
    }


    if (prevProps.isInProgress !== isInProgress && !isInProgress && !hasErrors) {
      const { newPatient } = this;
      if (this.props.isAutoActivation || this.state.view === 'manual') {
        if (newPatient) {
          const { organizationUID, name } = get(this.props.activeClinicMembership, 'clinic', {});
          const clinicSlug = getSlug(name);
          const patientSlug = getSlug(`${newPatient.firstName} ${newPatient.lastName}`);
          const url = this.context.getUrl(
            'hcp-results',
            { clinicSlug, organizationUID, patientSlug, patientId: newPatient.id },
          );
          history.push(url);
        }
        this.onClose(newPatient);
      }
    }
  }


  componentWillUnmount() {
    this.onClose();
  }


  onAddValidatorRules(rules) {
    this.validatorRules = { ...this.validatorRules, ...rules };
  }


  onSetupPersonalIdentifier() {
    const personalIdentifierTemplate = find(this.props.clinicPatientTemplate, { name: 'personalIdentifier' });
    if (!personalIdentifierTemplate) {
      this.props.onUnsetFormValues(this.personalIdentifierFields);
      this.validatorRules = pick(
        this.validatorRules,
        keysIn(this.validatorRules).filter((key) => !startsWith(key, 'personalIdentifier')),
      );
      return;
    }
    if (isEmpty(this.personalIdentifierFields)) {
      const personalIdentifierFields = [];
      forEach(personalIdentifierTemplate.fields, (f) => personalIdentifierFields.push(`personalIdentifier.${f.name}`));
      this.personalIdentifierFields = personalIdentifierFields;
    }
    const personalIdentifierTypeTemplate = find(personalIdentifierTemplate.fields, { name: 'personalIdentifierType' });
    const personalIdentifierTypeOptions = map(get(personalIdentifierTypeTemplate, 'options'), (o) => o.value);
    const { personalIdentifier } = unfoldObject(get(this.props.formValues, 'values', {}));
    if (!personalIdentifier || !includes(personalIdentifierTypeOptions, personalIdentifier.personalIdentifierType)) {
      const values = foldObject({
        personalIdentifierType : personalIdentifierTypeOptions[0],
        personalIdentifierValue: undefined,
      }, 'personalIdentifier');
      this.props.onSetFormValues(values);
    }
  }


  onChangeView(view) {
    if (view === 'qrCode') {
      this.patientId = null;
      this.props.onUnsetReceivedSharingRequest();
      this.props.onCheckApprove(this.invitationCode, actions.setReceivedSharingRequest);
    } else {
      this.props.onStopCheckApprove();
    }
    this.setState({ view });
  }


  onToggleOptionalSection(evt) {
    evt.stopPropagation();
    this.setState((state) => ({
      isOptionalSectionOpen: !state.isOptionalSectionOpen,
    }));
  }


  onToggleOptionalSectionOverflow(trigger) {
    if (
      (trigger === 'start' && this.state.isOptionalSectionOpen)
      || (trigger === 'complete' && !this.state.isOptionalSectionOpen)
    ) {
      this.setState((state) => ({
        isOptionalSectionOverflow: !state.isOptionalSectionOverflow,
      }));
    }
  }


  onClose(newPatient) {
    if (this.props.onClose) {
      this.props.onClose(newPatient);
    }
    this.props.onStopCheckApprove();
    this.props.onUnsetReceivedSharingRequest();
    this.props.onClearForm();
  }


  onSubmit() {
    this.props.onFormProcessing();
    const { validatedValues, errors } = this.onValidate(this.validatorRules);
    this.props.onFormErrors(errors);
    if (!errors) {
      this.patientId = uuid();
      validatedValues.id = this.patientId;
      const { weight, height, payer } = validatedValues;
      validatedValues.weight = weight && this.metricConversions.weight.toStorage(weight);
      validatedValues.height = height && this.metricConversions.height.toStorage(height);
      this.props.onSubmit(unfoldObject(validatedValues), this.props.activeClinicMembership);
      if (payer) {
        const countryId = get(this.props.activeClinicMembership, 'clinic.countryId');
        this.props.onAddPayerProposal(countryId, payer);
      }
    }
  }


  onValidate(rules) {
    const values = { ...get(this.props.formValues, 'values', {}) };
    const { dateOfBirth, sendEmailInvitation } = values;

    if (dateOfBirth && dateOfBirth.year && dateOfBirth.month && dateOfBirth.day) {
      values.dateOfBirth = formatISODate(
        new Date(Date.UTC(dateOfBirth.year, dateOfBirth.month - 1, dateOfBirth.day)),
        true,
      );
    } else {
      values.dateOfBirth = '';
    }


    const clonedRules = { ...rules };

    if (sendEmailInvitation) {
      clonedRules.email = 'required, email';
    }

    clonedRules.payer = this.isTemplateFieldHidden('payer')
      ? 'trim'
      : createValidatorRules(this.findTemplateFieldByName('payer')).payer;

    return Validator.run(values, clonedRules);
  }


  onChangeName(input) {
    this.props.onSetFormValue(input);
    this.onDebouncedEnterName();
  }


  onEnterName() {
    const { firstName, lastName } = get(this.props.formValues, 'values', {});
    const otherPatient = firstName && lastName && find(this.props.patients, { firstName, lastName });
    this.requiredRules = {};
    this.validatorRules.dateOfBirth = otherPatient ? 'required' : '*';
    const isDateOfBirthMandatory = !!otherPatient;
    this.setState((state) => {
      if (state.isDateOfBirthMandatory === isDateOfBirthMandatory) {
        return null;
      }
      return { isDateOfBirthMandatory };
    });
  }


  get newPatient() {
    const { patients } = this.props;
    return findLast(patients, { id: this.patientId });
  }


  get isDateOfBirthMandatory() {
    return this.state.isDateOfBirthMandatory;
  }


  get isDisabled() {
    const { isFetchPatientCountryLocalizationInProgress, hasFetchPatientCountryLocalizationError } = this.props;
    const { errors } = this.onValidate(this.requiredRules);
    return !!errors || isFetchPatientCountryLocalizationInProgress || hasFetchPatientCountryLocalizationError;
  }


  isTemplateFieldHidden(fieldName) {
    return !this.findTemplateFieldByName(fieldName);
  }


  findTemplateFieldByName(informationName) {
    return find(this.props.clinicPatientTemplate, (information) => information.name === informationName);
  }


  renderEmailField() {
    const isSendEmailInvitation = get(this.props.formValues, 'values.sendEmailInvitation', false);
    if (!isSendEmailInvitation) {
      return null;
    }
    return (
      <FormGroup
        id="email"
        labelMessage={messages.labels.email}
        formValues={this.props.formValues}
      >
        <Input
          placeholder={messages.placeholders.email}
          onChange={this.props.onSetFormValue}
        />
      </FormGroup>
    );
  }


  renderSendEmailInvitation() {
    if (this.props.isEnrolling) {
      return null;
    }
    return (
      <>
        <FormGroup
          id="sendEmailInvitation"
          labelMessage=""
          formValues={this.props.formValues}
        >
          <CheckboxRadio
            inputValue="true"
            labelMessage={messages.labels.sendEmailInvitation}
            onChange={this.props.onSetFormValue}
          />
        </FormGroup>
        { this.renderEmailField() }
      </>
    );
  }


  renderPersonalIdentifier(optional = false) {
    const informationField = find(this.props.clinicPatientTemplate, { name: 'personalIdentifier' });
    if (
      !informationField
      || (optional && informationField.isMandatory)
      || (!optional && !informationField.isMandatory)
    ) {
      return null;
    }
    return (
      <PersonalIdentifierPartial
        informationField={informationField}
        localizationResources={this.props.localizationResources}
        messages={messages}
        formValues={this.props.formValues}
        onAddValidatorRules={(rules) => this.onAddValidatorRules(rules)}
        onSetFormValue={this.props.onSetFormValue}
      />
    );
  }


  renderPayer() {
    return (
      <FormGroup
        id="payer"
        labelMessage={Account.messages.labels.payer}
        formValues={this.props.formValues}
      >
        <AutosuggestInput
          suggestions={this.props.payers}
          placeholder={Account.messages.placeholders.payer}
          onChange={this.props.onSetFormValue}
        />
      </FormGroup>
    );
  }


  renderTemplateField(fieldNames, optional = false) {
    const field = this.findTemplateFieldByName(fieldNames);
    if (
      !field
      || (optional && field.isMandatory)
      || (!optional && !field.isMandatory)
    ) {
      return null;
    }
    return (
      <TemplatedFields
        template={[field]}
        disabledFields={this.disabledFields || []}
        formValues={this.props.formValues}
        messages={messages}
        localizationResources={this.props.localizationResources}
        validatorRules={this.validatorRules}
        onAddValidatorRules={(rules) => this.onAddValidatorRules(rules)}
        onSetFormValue={this.props.onSetFormValue}
        onSetFormValues={this.props.onSetFormValues}
      />
    );

  }


  renderMandatorySection(isDateOfBirthMandatory, isPayerMandatory) {
    return (
      <div>
        <div className="row">
          <FormGroup
            id="firstName"
            labelMessage={messages.labels.firstName}
            className="col-6"
            formValues={this.props.formValues}
          >
            <Input
              placeholder={messages.placeholders.firstName}
              onChange={(input) => this.onChangeName(input)}
            />
          </FormGroup>
          <FormGroup
            id="lastName"
            labelMessage={messages.labels.lastName}
            className="col-6"
            formValues={this.props.formValues}
          >
            <Input
              placeholder={messages.placeholders.lastName}
              onChange={(input) => this.onChangeName(input)}
            />
          </FormGroup>
        </div>
        { this.renderSendEmailInvitation() }
        { this.renderTemplateField('personalIdentifier') }
        { isPayerMandatory && this.renderPayer() }
        {
          isDateOfBirthMandatory
          && (
            <DateOfBirthFormPartial
              formValues={this.props.formValues}
              onSetFormValue={this.props.onSetFormValue}
            />
          )
        }
        { this.renderTemplateField('phone') }
        { this.renderTemplateField('otherInformation') }
        { this.renderTemplateField('gender') }
      </div>
    );
  }


  renderOptionalSection(isDateOfBirthMandatory, isPayerMandatory) {
    const { isOptionalSectionOpen, isOptionalSectionOverflow } = this.state;
    return (
      <div className={cn(styles.optionalSection, { [styles['optionalSection--overflow']]: isOptionalSectionOverflow })}>
        <h3
          className={styles.optionalSection__header}
          onClick={(evt) => this.onToggleOptionalSection(evt)}
          role="presentation"
        >
          <FormattedMessage {...messages.headers.optionalInformation} />
          { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
          <button
            type="button"
            className="btn collapsible__btn"
            onClick={(evt) => this.onToggleOptionalSection(evt)}
          >
            <ChevronRight
              className={
                cn('collapsible__btn__icon', {
                  'collapsible__btn__icon--collapsed': isOptionalSectionOpen,
                })
              }
            />
          </button>
        </h3>
        <motion.div
          initial={{ height: 0, overflow: 'hidden' }}
          animate={{ height: isOptionalSectionOpen ? 'auto' : 0 }}
          transition={{ ease: 'easeOut', duration: 0.15 }}
          onAnimationStart={() => this.onToggleOptionalSectionOverflow('start')}
          onAnimationComplete={() => this.onToggleOptionalSectionOverflow('complete')}
        >
          <div className={styles.optionalSection__content}>
            { this.renderTemplateField('phone', true) }
            { this.renderTemplateField('otherInformation', true) }
            { this.renderPersonalIdentifier(true) }
            { !isPayerMandatory && this.renderPayer() }
            { this.renderTemplateField('gender', true) }
            {
              !isDateOfBirthMandatory
              && (
                <DateOfBirthFormPartial
                  formValues={this.props.formValues}
                  onSetFormValue={this.props.onSetFormValue}
                />
              )
            }
            <PhiSetFormPartial
              metricConversions={this.metricConversions}
              formValues={this.props.formValues}
              onSetFormValue={this.props.onSetFormValue}
              localizationResources={this.props.localizationResources}
              isActiveProfileTypePwd={Account.constants.PROFILE_TYPES.PWD === this.props.activeProfileType}
              isMale={get(this.props.formValues, 'values.gender') === 'Male'}
              isListInverted
            />
          </div>
        </motion.div>
      </div>
    );
  }


  renderManual() {
    const { isDateOfBirthMandatory, isPayerMandatory } = this;
    return (
      <Form onSubmit={() => this.onSubmit()}>
        <App.components.AlertsBus className="mb-4" />
        { this.renderMandatorySection(isDateOfBirthMandatory, isPayerMandatory) }
        { this.renderOptionalSection(isDateOfBirthMandatory, isPayerMandatory) }
        <div className="modal__actions">
          <Button
            styleModifier="primary"
            type="submit"
            labelMessage={messages.buttons.addNewPatient}
            className="btn--block btn--filled"
            isDisabled={this.isDisabled}
            isInProgress={this.state.isInProgress}
            onClickDisabled={() => this.onCheck()}
          />
        </div>
      </Form>
    );
  }


  renderQrContent() {
    const { domain } = this.context;
    const clientId = get(window, 'App.oidc.client_id');
    const { enrollCode, clinic } = this.props.activeClinicMembership;
    const { countryCode } = clinic;
    const { invitationCode } = this;
    const identityRequirements = {
      countryCode,
      scope       : Account.constants.SCOPE_NAMES.PERSONAL,
      blsReturnUrl: `${domain}/setup?enrollCode=${enrollCode}&invitationCode=${invitationCode}&client_id=${clientId}`,
    };
    const base64IdentityRequirements = btoa(JSON.stringify(identityRequirements));
    const url = `${domain}/aid/sign-up?identityRequirements=${base64IdentityRequirements}`;
    return <QRCode value={url} size={250} />;
  }


  renderQr() {
    return (
      <div className="d-flex flex-column align-items-center">
        <div className="my-4">
          { this.renderQrContent() }
        </div>
      </div>
    );
  }


  render() {
    if (!this.props.isHcpAccount) {
      return null;
    }
    if (this.props.isManualOnly) {
      return this.renderManual();
    }

    return (
      <div>
        <Tabs
          activeItem={this.state.view}
          items={['manual', 'qrCode']}
          messages={messages.nav}
          action={(activeView) => this.onChangeView(activeView)}
        />
        { this.state.view === 'manual' ? this.renderManual() : this.renderQr() }
      </div>
    );
  }

}


const mapStateToProps = () => {
  const getForm = App.selectors.formSelector(constants.INVITE_PATIENT_FORM);

  return (state) => ({
    activeClinicMembership                     : Account.selectors.activeClinicMembership(state),
    metricsUnits                               : Account.selectors.metricsUnits(state),
    isHcpAccount                               : Account.selectors.isHcpAccount(state),
    activeProfileType                          : Account.selectors.activeProfileType(state),
    patients                                   : selectors.patients(state),
    isInProgress                               : selectors.isAddPatientInProgress(state),
    hasErrors                                  : selectors.hasAddPatientErrors(state),
    receivedSharingRequest                     : selectors.receivedSharingRequest(state),
    countrySettings                            : selectors.countrySettings(state),
    clinicPatientTemplate                      : selectors.clinicPatientTemplate(state),
    payers                                     : selectors.payers(state),
    isFetchPatientCountryLocalizationInProgress: selectors.isFetchPatientCountryLocalizationInProgress(state),
    hasFetchPatientCountryLocalizationError    : selectors.hasFetchPatientCountryLocalizationErrors(state),
    localizationResources                      : App.selectors.localizationResources(state),
    openModalId                                : App.selectors.modal(state),
    formValues                                 : getForm(state),
  });
};


const mapDispatchToProps = (dispatch) => {
  const formName = constants.INVITE_PATIENT_FORM;

  return {
    onSubmit: (patientValues, clinicMembership) => dispatch(
      actions.addPatient(patientValues, clinicMembership),
    ),
    onCheckApprove: (invitationCode, successAction) => dispatch(
      actions.checkSharingRequestApprove(invitationCode, successAction),
    ),
    onBindWithClinicPatient: (sharingRequest, clinicPatient, clinicMembership) => dispatch(
      actions.bindSharingRequestWithClinicPatient(sharingRequest, clinicPatient, clinicMembership),
    ),
    onUnsetReceivedSharingRequest    : () => dispatch(actions.unsetReceivedSharingRequest()),
    onFetchPatientCountryLocalization: (countryId) => dispatch(actions.fetchPatientCountryLocalization(countryId)),
    onFetchPayers                    : (countryId) => dispatch(actions.fetchPayers(countryId)),
    onStopCheckApprove               : () => dispatch(actions.stopCheckSharingRequestApprove()),
    onAddPayerProposal               : (countryId, payer) => dispatch(App.actions.addPayerProposal(countryId, payer)),
    // onCloseModal                 : () => dispatch(App.actions.closeModal()),
    onFormProcessing                 : () => dispatch(App.actions.startFormProcessing(formName)),
    onSetFormValue                   : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onSetFormValues                  : (values) => dispatch(App.actions.setFormValues(formName, values)),
    onUnsetFormValues                : (ids) => dispatch(App.actions.unsetFormValues(formName, ids)),
    onFormErrors                     : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onClearForm                      : () => dispatch(App.actions.clearForm(formName)),
  };
};


const ConnectedAddPatientForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AddPatientForm));


export default withStyles(styles)(ConnectedAddPatientForm);
