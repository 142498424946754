import { FormattedMessage, injectIntl } from 'react-intl';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../../../../../components/Form';
import Alert from '../../../../../components/Alert';
import * as selectors from '../../../selectors';
import * as actions from '../../../actions';
import messages from '../../../messages';
import Timepicker from './Timepicker';
import { timeIntervalsMap } from './constants';
import styles from './PatientGestationalReportParams.pcss';


const PatientGestationalReportParams = () => {
  const [formError, setFormError] = useState();
  const [gestationalReportParams, setGestationalReportParams] = useState([]);
  const gestationalReportSettings = useSelector(selectors.gestationalReportSettingsSelector);
  const dispatch = useDispatch();


  const centeredClass = cn(
    styles.gestationalReportParams__param,
    styles['gestationalReportParams__param--normal'],
    styles['gestationalReportParams__param--centered']);


  const setGestationalReportSettings = (newGestationalReportSettings) => {
    dispatch(actions.setGestationalReportSettings(newGestationalReportSettings));
  };


  const onSetGestationalParamValue = ({
    id,
    value,
  }) => {
    const clonedSettings = [...gestationalReportSettings];

    const mealIndex = clonedSettings.findIndex((elem) => elem.id === id);

    clonedSettings[mealIndex] = {
      ...clonedSettings[mealIndex],
      value,
    };


    const settingsByValue = clonedSettings.reduce((res, elem) => ({
      ...res,
      [elem.id]: elem,
    }), {});


    const {
      night_am,
      breakfast,
      lunch,
      dinner,
      night_pm,
    } = settingsByValue;


    const fError = ![night_am.value, breakfast.value, lunch.value, dinner.value, night_pm.value]
      .every((val, index, array) =>
        index === 0 || val >= array[index - 1]);

    setFormError(fError);
    setGestationalReportParams(clonedSettings);
  };


  const renderReportSettingsError = () => <Alert type="error" message={messages.report.errors.wrongOrder} />;


  useEffect(() => {
    if (!formError && gestationalReportParams.length) {
      setGestationalReportSettings(gestationalReportParams);
    }
  }, [gestationalReportParams, formError]);


  if (!gestationalReportSettings) {
    return null;
  }


  return (
    <Form className={styles.gestationalReportParams}>
      <div className={styles.gestationalReportParams__row}>
        <div className={styles.gestationalReportParams__heading}>
          <FormattedMessage {...messages.report.labels.mealName} />
        </div>
        <div className={styles.gestationalReportParams__heading}>
          <FormattedMessage {...messages.report.labels.mealStart} />
        </div>
      </div>
      <div className={styles.gestationalReportParams__row}>
        <div className={styles.gestationalReportParams__param}><FormattedMessage {...messages.report.meals.night_am} />
        </div>
        <div className={centeredClass}>
          {gestationalReportSettings[0].value}
        </div>
      </div>
      {
        timeIntervalsMap.map(({
          mealName,
          valueIndex,
        }) => (
          <div className={styles.gestationalReportParams__row} key={mealName}>
            <div className={styles.gestationalReportParams__param}>
              <FormattedMessage {...messages.report.meals[mealName]} />
            </div>
            <div className={styles.gestationalReportParams__timePicker}>
              <Timepicker
                id={mealName}
                value={gestationalReportSettings[valueIndex].value}
                onSetValue={onSetGestationalParamValue}
              />
            </div>
          </div>
        ))
      }
      {formError && renderReportSettingsError()}
    </Form>
  );
};

export default withStyles(styles)(injectIntl(PatientGestationalReportParams));
