/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
// import AutoSizer from 'react-virtualized-auto-sizer';
// import { motionConfigContext, SmartMotion } from '@nivo/core';
// import { Line } from '@nivo/line';
// import { curveMonotoneX, line } from 'd3-shape';
import range from 'lodash/range';
import styles from './BloodGlucoseProfileMiniChart.pcss';


/**
 * @deprecated
 */
class BloodGlucoseProfileMiniChart extends React.PureComponent {

  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      minValue: PropTypes.number,
      maxValue: PropTypes.number,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    records: PropTypes.shape({
      hourlyBins: PropTypes.arrayOf(PropTypes.object),
    }),
    direction: PropTypes.string,
  };


  constructor(props) {
    super(props);
    this.colors = {
      stroke: '#1EA98C',
      target: '#CBEBE5',
      high  : '#F4C32C',
      low   : '#F74053',
    };
  }


  get chartData() {
    const { records, conversion } = this.props;
    if (!records || !records.hourlyBins) {
      return [{
        id  : 'Glucose Profile',
        data: [],
      }];
    }
    const hourlyRecords = Object.entries(records.hourlyBins)
      .map(([hour, hourlyRecord]) => ({ hourlyRecord, hour }))
      .filter(({ hourlyRecord }) => hourlyRecord);
    return [
      {
        id  : 'Glucose Profile',
        data: hourlyRecords.map(({ hourlyRecord, hour }) => ({
          x   : Number(hour) + 0.5,
          y   : conversion.toDisplay(hourlyRecord.pctl_50th),
          hour: hourlyRecord.hour,
        })),
      },
    ];
  }


  // line({ series, xScale, yScale, ...props }) {
  //   const { conversion, standards } = this.props;
  //   const lowThreshold = conversion.toDisplay(standards.postMeal.lowThreshold);
  //   const highThreshold = conversion.toDisplay(standards.postMeal.highThreshold);
  //   const width = props.width - props.margin.right - props.margin.left;
  //   const maxY = yScale(999);
  //   const minY = yScale(0);
  //   const highY = yScale(highThreshold);
  //   const lowY = yScale(lowThreshold);
  //
  //   const lineGenerator = line()
  //     .x((d) => xScale(d.data.x))
  //     .y((d) => yScale(d.data.y))
  //     .curve(curveMonotoneX);
  //
  //   const pathProps = {
  //     d              : lineGenerator(series[0].data),
  //     fill           : 'none',
  //     strokeWidth    : 2,
  //     strokeDasharray: '5,2',
  //   };
  //   return (
  //     <g>
  //       <clipPath id="clipPathHigh" clipPathUnits="userSpaceOnUse">
  //         <polygon
  //           points={`0,${maxY} ${width},${maxY} ${width},${highY} 0,${highY}`}
  //           fill="#000000"
  //         />
  //       </clipPath>
  //       <clipPath id="clipPathTarget" clipPathUnits="userSpaceOnUse">
  //         <polygon
  //           points={`0,${highY} ${width},${highY} ${width},${lowY} 0,${lowY}`}
  //           fill="#000000"
  //         />
  //       </clipPath>
  //       <clipPath id="clipPathLow" clipPathUnits="userSpaceOnUse">
  //         <polygon
  //           points={`0,${lowY} ${width},${lowY} ${width},${minY} 0,${minY}`}
  //           fill="#000000"
  //         />
  //       </clipPath>
  //       <path
  //         stroke={this.colors.high}
  //         style={{
  //           clipPath: 'url(#clipPathHigh)',
  //         }}
  //         {...pathProps}
  //       />
  //       <path
  //         stroke={this.colors.stroke}
  //         style={{
  //           clipPath: 'url(#clipPathTarget)',
  //         }}
  //         {...pathProps}
  //       />
  //       <path
  //         stroke={this.colors.low}
  //         style={{
  //           clipPath: 'url(#clipPathLow)',
  //         }}
  //         {...pathProps}
  //       />
  //     </g>
  //   );
  // }

  circles({ series, xScale, yScale }) {
    const { conversion, standards } = this.props;
    const lowThreshold = conversion.toDisplay(standards.postMeal.lowThreshold);
    const highThreshold = conversion.toDisplay(standards.postMeal.highThreshold);
    return (
      <g>
        {series[0].data.map((d) => {
          let fill = this.colors.stroke;
          if (d.data.y > highThreshold) {
            fill = this.colors.high;
          } else if (d.data.y < lowThreshold) {
            fill = this.colors.low;

          }
          return (
            <circle
              key={d.data.x}
              fill={fill}
              cx={xScale(d.data.x)}
              cy={yScale(d.data.y)}
              r="2px"
            />
          );
        })}
      </g>
    );
  }

  renderTargetZone(props) {
    const { conversion, standards } = this.props;
    const lowThreshold = conversion.toDisplay(standards.postMeal.lowThreshold);
    const highThreshold = conversion.toDisplay(standards.postMeal.highThreshold);
    const width = props.width - props.margin.right - props.margin.left;
    const yTop = props.yScale(highThreshold);
    const yBottom = props.yScale(lowThreshold);

    return null;

    // return (
    //   <motionConfigContext.Consumer>
    //     { (springConfig) => (
    //       <SmartMotion
    //         key="bloodGlucoseConcentrationTargetZone"
    //         style={(spring) => ({
    //           width: spring(width, springConfig),
    //         })}
    //       >
    //         {(style) => (
    //           <g opacity={0.5}>
    //             <polygon
    //               points={`0,${yTop} ${style.width},${yTop} ${style.width},${yBottom} 0,${yBottom}`}
    //               fill={this.colors.target}
    //             />
    //           </g>
    //         )}
    //       </SmartMotion>
    //     )}
    //   </motionConfigContext.Consumer>
    // );
  }


  render() {
    return null;

    // const { direction, conversion, standards } = this.props;
    // const margin = {
    //   top   : 0,
    //   right : 0,
    //   bottom: 0,
    //   left  : 0,
    // };
    /*
      IMPORTANT: Because Edge doesn't support `dominant-baseline` and RTL support is poor
      hardcoded styles overwrite ticks labels transform for current tickPadding and tickRotation values and
      must be correlated if change
     */
    // return (
    //   <div className={styles.root}>
    //     <AutoSizer>
    //       {
    //           ({ height, width }) => (
    //             <Line
    //               data={this.chartData}
    //               height={height}
    //               width={width}
    //               margin={margin}
    //               xScale={{ type: 'linear', min: 0, max: 24, reverse: direction === 'rtl' }}
    //               yScale={{ type: 'linear',
    //                 min : conversion.toDisplay(standards.minValue),
    //                 max : conversion.toDisplay(standards.maxValue) }}
    //               axisTop={null}
    //               axisRight={null}
    //               axisBottom={{
    //                 tickSize    : 5,
    //                 tickPadding : 5,
    //                 tickRotation: 0,
    //                 tickValues  : range(24),
    //               }}
    //               axisLeft={null}
    //               colors={[this.colors.stroke]}
    //               lineWidth={2}
    //               enableCrosshair={false}
    //               curve="monotoneX"
    //               layers={[
    //                 'markers',
    //                 'axes',
    //                 'areas',
    //                 this.renderTargetZone.bind(this),
    //                 // this.line.bind(this),
    //                 this.circles.bind(this),
    //                 'slices',
    //                 'legends',
    //               ]}
    //             />
    //           )
    //         }
    //     </AutoSizer>
    //   </div>
    // );
  }

}


export default withStyles(styles)(BloodGlucoseProfileMiniChart);
