import { registerAction, unregisterAction } from 'helpers/reducerTools';
import * as actionTypes from './actionTypes';


const initialState = {
  fetching: [],
  sending : [],
  errors  : [],
};


export default function reducer(state = { ...initialState }, action) {

  switch (action.type) {
    case actionTypes.DOWNLOAD_PDFS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.DOWNLOAD_PDFS),
        errors  : unregisterAction(state.errors, actionTypes.DOWNLOAD_PDFS),
      };
    }

    case actionTypes.DOWNLOAD_PDFS_SUCCESS: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.DOWNLOAD_PDFS),
      };
    }

    case actionTypes.DOWNLOAD_PDFS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.DOWNLOAD_PDFS),
        errors  : registerAction(state.errors, actionTypes.DOWNLOAD_PDFS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    default: {
      return state;
    }

  }
}
