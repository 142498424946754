import { createSelector } from 'reselect';
import flatMap from 'lodash/flatMap';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import flatten from 'lodash/flatten';
import AgpAggregator from 'libs/AgpAggregator';
import * as constants from './constants';

/**
 * Input selectors
 */
const mainSelector = (state) => state[constants.MODULE_ID];


/**
 * All loaded daily records
 */
export const dailyRecords = createSelector(
  mainSelector,
  (state) => state.dailyRecords,
);


/**
 * Dates highlighted for main chart
 */
export const highlightedDates = createSelector(
  mainSelector,
  (state) => state.highlightedDates,
);

/**
 * Bins highlighted for main chart
 */
export const highlightedDailyRecords = createSelector(
  dailyRecords,
  highlightedDates,
  (dailyRecordsState, highlightedDatesState) => {
    let highlightedDailyRecordsState = dailyRecordsState
      .filter((dailyRecord) => highlightedDatesState.includes(dailyRecord.date));
    highlightedDailyRecordsState = highlightedDailyRecordsState.map((dailyRecord, i) => {
      let sufficiency = dailyRecord.daySufficiency;
      if (i === 0) {
        sufficiency = dailyRecord.daySufficiencyAsFirst;
      } else if (i === highlightedDailyRecordsState.length - 1) {
        sufficiency = dailyRecord.daySufficiencyAsEnd;
      }
      if (!sufficiency) {
        sufficiency = 0;
      }
      return {
        ...dailyRecord,
        sufficiency,
      };
    });
    return highlightedDailyRecordsState;
  },
);

/**
 * Merged highlighted daily records for main chart
 */
export const highlightedHourlyRecords = createSelector(
  highlightedDailyRecords,
  AgpAggregator.mergeDailyRecords,
);

/**
 * Top 7 highlighted days
 */
export const top7highlightedDailyRecords = createSelector(
  highlightedDailyRecords,
  (highlightedDailyRecordsState) => {
    /**
     * 3. Rank each calendar day by sufficiency percent from highest to lowest.
     */
    const sortedBySufficiency = [...sortBy([...highlightedDailyRecordsState].reverse(), 'sufficiency')].reverse();
    return sortedBySufficiency.slice(0, 7);
  }
);

/**
 * Average sufficiency for Top 7 highlighted days
 */
export const top7DaysAverageSufficiency = createSelector(
  top7highlightedDailyRecords,
  (top7highlightedDailyRecordsState) => {
    if (top7highlightedDailyRecordsState.length < 7) {
      return null;
    }
    return sumBy(top7highlightedDailyRecordsState, 'sufficiency') / top7highlightedDailyRecordsState.length;
  }
);

/**
 * Are highlighted days sufficient
 */
export const areHighlightedDaysSufficient = createSelector(
  top7DaysAverageSufficiency,
  top7highlightedDailyRecords,
  (top7DaysAverageSufficiencyState, top7highlightedDailyRecordsState) => {

    if (!top7highlightedDailyRecordsState.every(({ dayRecordsNumber }) => dayRecordsNumber > AgpAggregator.CGM_MEASUREMENTS_PER_DAY / 24)) {
      /**
       *  4.a Verify that each calendar day has at least 1 hour of CGM data. (Minimum of 4, 6, or 12 readings
       * depending on sensor reading frequency.) If one or more of the days does not, then the data
       * sufficiency criteria are not met.
       */
      return false;
    }
    if (top7DaysAverageSufficiencyState >= 70) {
      /**
       * 4.c If the average is ≥ 70%, then the time period is considered sufficient and meets the sufficiency
       * requirements. If the average is < 70%, then it is not sufficient and does not meet the sufficiency
       * requirements.
       */
      return true;
    }
    return false;
  }
);

/**
 * 4. Minimal Data Requirement
 */
export const isDisplayedGlucoseStatistics = createSelector(
  highlightedHourlyRecords,
  (highlightedHourlyRecordsState) => highlightedHourlyRecordsState.records.length >= 24);

/**
 * 4. Minimal Data Requirement
 */
export const isDisplayedTimeInRanges = createSelector(
  highlightedHourlyRecords,
  (highlightedHourlyRecordsState) => highlightedHourlyRecordsState.records.length >= 24);

/**
 * 4. Minimal Data Requirement
 */
export const isDisplayedDailyGlucoseProfile = createSelector(
  highlightedHourlyRecords,
  (highlightedHourlyRecordsState) => highlightedHourlyRecordsState.records.length >= 24);

/**
 * 4. Minimal Data Requirement
 */
export const isDisplayedAgpProfileChart = createSelector(
  areHighlightedDaysSufficient,
  (areHighlightedDaysSufficientState) => areHighlightedDaysSufficientState);

/**
 * Records highlighted
 */
export const highlightedRecords = createSelector(
  highlightedDailyRecords,
  (highlightedDailyRecordsState) =>
    flatMap(highlightedDailyRecordsState,
      (dailyRecordsState) => flatMap(dailyRecordsState.records, ({ records }) => records))
);

/**
 * Records highlighted Statistics
 */
export const highlightedStatisticsFactory = (glucoseConcentrationLevelBottomValues) => createSelector(
  highlightedRecords,
  (highlightedRecordsState) => {
    if (!glucoseConcentrationLevelBottomValues) return {};
    const veryHigh = highlightedRecordsState
      .map(({ value }) => value)
      .filter((value) =>
        value > glucoseConcentrationLevelBottomValues.MG_DL.veryHigh
      );
    const high = highlightedRecordsState
      .map(({ value }) => value)
      .filter((value) =>
        value > glucoseConcentrationLevelBottomValues.MG_DL.high
      && value <= glucoseConcentrationLevelBottomValues.MG_DL.veryHigh
      );
    const target = highlightedRecordsState
      .map(({ value }) => value)
      .filter((value) =>
        value > glucoseConcentrationLevelBottomValues.MG_DL.target
      && value <= glucoseConcentrationLevelBottomValues.MG_DL.high
      );
    const low = highlightedRecordsState
      .map(({ value }) => value)
      .filter((value) =>
        value > glucoseConcentrationLevelBottomValues.MG_DL.low
      && value <= glucoseConcentrationLevelBottomValues.MG_DL.target
      );
    const veryLow = highlightedRecordsState
      .map(({ value }) => value)
      .filter((value) =>
        value <= glucoseConcentrationLevelBottomValues.MG_DL.low
      );
    const statistics = {
      veryHigh     : veryHigh.length / highlightedRecordsState.length,
      veryHighCount: veryHigh.length,
      high         : high.length / highlightedRecordsState.length,
      highCount    : high.length,
      target       : target.length / highlightedRecordsState.length,
      targetCount  : target.length,
      low          : low.length / highlightedRecordsState.length,
      lowCount     : low.length,
      veryLow      : veryLow.length / highlightedRecordsState.length,
      veryLowCount : veryLow.length,
    };
    return statistics;
  }
);

/**
 * highlighted  records  - time CGM is Active
 */
export const highlightedTimeIsActive = createSelector(
  highlightedRecords,
  AgpAggregator.timeIsActive,
);

/**
 * highlighted  records  - Average glucose
 */
export const highlightedAverageGlucose = createSelector(
  highlightedRecords,
  AgpAggregator.averageGlucose,
);

/**
 * highlighted  records  - highlighted Coefficient Of Variation
 */
export const highlightedStandardDeviation = createSelector(
  highlightedRecords,
  AgpAggregator.standardDeviation,
);

/**
 * highlighted  records  - highlighted Coefficient Of Variation
 */
export const highlightedCoefficientOfVariation = createSelector(
  highlightedStandardDeviation,
  highlightedAverageGlucose,
  (highlightedStandardDeviationState, highlightedAverageGlucoseState) => highlightedStandardDeviationState / highlightedAverageGlucoseState * 100,
);


/**
 * Zoom of day selector
 */
export const daysSelectorZoom = createSelector(
  mainSelector,
  (state) => state.daysSelectorZoom,
);

/**
 * Page of day selector
 */
export const daysSelectorPage = createSelector(
  mainSelector,
  (state) => state.daysSelectorPage,
);

/**
 * Page of month for daily glucose profile
 */
export const agpMonthSelectorPage = createSelector(
  mainSelector,
  (state) => state.agpMonthSelectorPage,
);

/**
 * Aggregated weekly records
 */
export const weeklyRecords = createSelector(
  dailyRecords,
  AgpAggregator.aggregateDailyRecordsToWeeklyRecords,
);


/**
 * Aggregated monthly records
 */
export const monthlyRecords = createSelector(
  dailyRecords,
  AgpAggregator.aggregateDailyRecordsToMonthlyRecords,
);


/**
 * Profile preview
 */
export const preview = createSelector(
  mainSelector,
  (state) => state.preview,
);


/**
 * Profile agp preview
 */
export const agpPreview = createSelector(
  mainSelector,
  (state) => state.agpPreview,
);

/**
 * Aggregations
 */
export const aggregations = createSelector(
  mainSelector,
  (state) => state.aggregations,
);

/**
 * Daily aggregations
 */
export const dailyAggregations = createSelector(
  aggregations,
  (aggregationsState) => {
    const _dailyAggregations = aggregationsState
      .filter(({ level, hourlyBinsByDays }) => level === constants.DAYS_SELECTOR_ZOOM.day && hourlyBinsByDays)
      .map(({ hourlyBinsByDays }) => Object.values(hourlyBinsByDays));
    return flatten(_dailyAggregations);
  },
);

/**
 * Weekly aggregations
 */
export const weeklyAggregations = createSelector(
  aggregations,
  (aggregationsState) => {
    const _weeklyAggregations = aggregationsState
      .filter(({ level, hourlyBinsByWeekOfYear }) => level === constants.DAYS_SELECTOR_ZOOM.week && hourlyBinsByWeekOfYear)
      .map(({ hourlyBinsByWeekOfYear }) => Object.values(hourlyBinsByWeekOfYear));
    return flatten(_weeklyAggregations);
  }
);

/**
 * Monthly aggregations
 */
export const monthlyAggregations = createSelector(
  aggregations,
  (aggregationsState) => {
    const _monthlyAggregations = aggregationsState
      .filter(({ level, hourlyBinsForMonth }) => level === constants.DAYS_SELECTOR_ZOOM.month && hourlyBinsForMonth)
      .map(({ hourlyBinsForMonth }) => hourlyBinsForMonth);
    return flatten(_monthlyAggregations);
  });

/**
 * Aggregations fetched documents
 */
export const aggregationDocumentsIds = createSelector(
  mainSelector,
  (state) => state.aggregationDocumentsIds,
);


/**
 * Paginated daily records
 */
export const dailyRecordsPaginated = createSelector(
  preview,
  daysSelectorPage,
  daysSelectorZoom,
  (previewState, pageState, zoomState) => {
    if (zoomState !== constants.DAYS_SELECTOR_ZOOM.day) {
      return null;
    }
    const perPage = constants.DAYS_SELECTOR_ENTITIES_PER_PAGE.day;
    return previewState.days.slice(pageState * perPage, (pageState + 1) * perPage);
  },
);


/**
 * Paginated daily records
 */
export const dailyRecordsPaginatedByMonth = createSelector(
  agpPreview,
  agpMonthSelectorPage,
  (agpPreviewState, pageState) => (agpPreviewState.months[pageState] ? agpPreviewState.months[pageState].dates : []),
);


/**
 * Paginated weekly records
 */
export const weeklyRecordsPaginated = createSelector(
  preview,
  daysSelectorPage,
  daysSelectorZoom,
  (previewState, pageState, zoomState) => {
    if (zoomState !== constants.DAYS_SELECTOR_ZOOM.week) {
      return null;
    }
    const perPage = constants.DAYS_SELECTOR_ENTITIES_PER_PAGE.week;
    return previewState.weeks.slice(pageState * perPage, (pageState + 1) * perPage);
  },
);


/**
 * Paginated monthly records
 */
export const monthlyRecordsPaginated = createSelector(
  preview,
  daysSelectorPage,
  daysSelectorZoom,
  (previewState, pageState, zoomState) => {
    if (zoomState !== constants.DAYS_SELECTOR_ZOOM.month) {
      return null;
    }
    const perPage = constants.DAYS_SELECTOR_ENTITIES_PER_PAGE.month;
    return previewState.months.slice(pageState * perPage, (pageState + 1) * perPage);
  },
);


/**
 * Current paginated records
 */
export const currentRecordsPaginated = createSelector(
  dailyRecordsPaginated,
  weeklyRecordsPaginated,
  monthlyRecordsPaginated,
  (dailyRecordsPaginatedState, weeklyRecordsPaginatedState, monthlyRecordsPaginatedState) =>
    dailyRecordsPaginatedState || weeklyRecordsPaginatedState || monthlyRecordsPaginatedState,
);


export const daysSelectorTotalPages = createSelector(
  preview,
  daysSelectorZoom,
  (previewState, daysSelectorZoomState) => {
    switch (daysSelectorZoomState) {
      case constants.DAYS_SELECTOR_ZOOM.day: {
        return Math.ceil(previewState.days.length / constants.DAYS_SELECTOR_ENTITIES_PER_PAGE.day);
      }
      case constants.DAYS_SELECTOR_ZOOM.week: {
        return Math.ceil(previewState.weeks.length / constants.DAYS_SELECTOR_ENTITIES_PER_PAGE.week);
      }
      case constants.DAYS_SELECTOR_ZOOM.month: {
        return Math.ceil(previewState.months.length / constants.DAYS_SELECTOR_ENTITIES_PER_PAGE.month);
      }
      default: return 0;
    }
  }
);


export const dgpSelectorlPages = createSelector(
  agpPreview,
  daysSelectorZoom,
  (agpPreviewState) => agpPreviewState.months.length
);


/**
 * Top two weeks of records
 */
export const dailyRecordsTopTwoWeeks = createSelector(
  highlightedDailyRecords,
  (highlightedDailyRecordsState) => {
    const maxLength = 14;
    if (highlightedDailyRecordsState.length <= maxLength) {
      return highlightedDailyRecordsState;
    }
    return highlightedDailyRecordsState.slice(highlightedDailyRecordsState.length - maxLength);
  },
);

/**
 * Show Median in AGP BGM Chart
 */
export const showMedian = createSelector(
  mainSelector,
  (state) => state.showMedian,
);


/**
 * Show IQR in AGP BGM Chart
 */
export const showIQR = createSelector(
  mainSelector,
  (state) => state.showIQR,
);


/**
 * Show p10 and p90 in AGP BGM Chart
 */
export const showP10P90 = createSelector(
  mainSelector,
  (state) => state.showP10P90,
);
