import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import find from 'lodash/find';
import get from 'lodash/get';
import filter from 'lodash/filter';
import { formatDate } from 'helpers/datetime';
import Avatar from 'components/Avatar';
import Modal from 'components/Modal';
import Table from 'components/Table';
import Button from 'components/Form/Button';
import App from 'modules/App';
import noteShape from 'shapes/noteShape';
import { sortByNoteType } from 'modules/Visit/components/VisitNotes/helpers';
import BackButton from 'components/BackButton';
import Alert from 'components/Alert';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import VisitNotes from './VisitNotes';
import styles from './VisitHistoryModal.pcss';


class VisitHistoryModal extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const { openModalId } = props;
    if (!openModalId && (state.page || state.selectedVisit)) {
      return {
        view         : 'visits',
        page         : 0,
        selectedVisit: null,
      };
    }
    return null;
  }


  static propTypes = {
    // Explicit props
    activePatient: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName : PropTypes.string.isRequired,
    }),
    phiSet: PropTypes.shape({
      visits: PropTypes.arrayOf(shapes.visit),
    }),
    phiSetDocumentId         : PropTypes.string,
    prvKeyPem                : PropTypes.string.isRequired,
    passphrase               : PropTypes.string.isRequired,
    mode                     : PropTypes.oneOf(['HCP', 'PWD']).isRequired,
    notes                    : PropTypes.arrayOf(noteShape),
    // Explicit actions
    onFetchNotes             : PropTypes.func,
    onStoreNotes             : PropTypes.func,
    // Implicit props
    activeVisit              : shapes.visit,
    visits                   : PropTypes.arrayOf(shapes.visit),
    openModalId              : PropTypes.string,
    isFetchMetadataInProgress: PropTypes.bool,
    // Implicit actions
    onFetchVisitsMetadata    : PropTypes.func.isRequired,
    onCloseModal             : PropTypes.func.isRequired,
  };


  constructor(props) {
    super(props);
    this.state = {
      view         : 'visits', // 'visits' or 'notes'
      page         : 0,
      selectedVisit: null,
    };
  }


  componentDidMount() {
    this.onFetchVisitsMetadata();
  }


  componentDidUpdate(prevProps) {
    const { openModalId } = this.props;
    if (
      (prevProps.openModalId !== openModalId && openModalId === constants.VISIT_HISTORY_MODAL)
      || (openModalId === constants.VISIT_HISTORY_MODAL && !prevProps.phiSet && this.phiSetVisits.length > 0)
    ) {
      this.onFetchVisitsMetadata();
    }
  }


  onBack(evt) {
    evt.preventDefault();
    this.setState({ view: 'visits', selectedVisit: null });
  }


  onClose() {
    this.props.onCloseModal();
  }


  onFetchVisitsMetadata({ page = 0, perPage = 5 } = {}) {
    const { phiSetVisits } = this;
    if (!phiSetVisits.length) return;
    const { prvKeyPem, passphrase } = this.props;
    const visitsToFetch = phiSetVisits.slice(perPage * page, perPage * (page + 1));
    this.props.onFetchVisitsMetadata(visitsToFetch, prvKeyPem, passphrase);
    this.setState({ page });
  }


  onShowNotes(visit) {
    this.props.onFetchNotes(visit);
    this.setState({
      view         : 'notes',
      selectedVisit: visit,
    });
  }


  get schema() {
    const schema = [
      {
        key         : 'hcp',
        labelMessage: messages.labels.hcp,
        renderer    : (visit) => this.renderHcp(visit),
      },
      {
        key         : 'visitDate',
        labelMessage: messages.labels.date,
        renderer    : (visit) => this.renderVisitDate(visit),
      },
    ];
    if (this.props.mode === 'PWD') {
      schema.push({
        key         : 'clinicName',
        labelMessage: messages.labels.clinic,
        renderer    : (visit) => this.renderClinic(visit),
      });
    }
    schema.push({
      key         : 'actions',
      labelMessage: messages.labels.visitNotes,
      renderer    : (visit) => this.renderVisitActions(visit),
    });
    return schema;
  }


  get phiSetVisits() {
    const { activeVisit } = this.props;
    let visits = [...get(this.props.phiSet, 'visits', [])].reverse();
    if (activeVisit) {
      visits = filter(visits, (visit) => visit.phisetVisitId !== activeVisit.phisetVisitId);
    }
    return visits;
  }


  renderBack() {
    if (this.state.view === 'visits') {
      return null;
    }
    return (
      <BackButton
        to=""
        onClick={(evt) => this.onBack(evt)}
      />
    );
  }


  renderHeader() {
    const headerMessage = messages.headers[this.state.view === 'visits' ? 'visitHistory' : 'visitNotes'];
    let patientName = '';
    if (this.mode === 'HCP') {
      const { firstName, lastName } = this.props.activePatient;
      patientName = ` - ${firstName} ${lastName}`;
    }
    return (
      <div>
        { this.renderBack() }
        <h2 className="modal__header">
          <FormattedMessage {...headerMessage} />{ patientName }
        </h2>
        <App.components.AlertsBus className="mb-4" />
      </div>
    );
  }


  renderClinic(visit) {
    const { phisetVisitId } = visit;
    const visitMetadata = find(this.props.visits, { phisetVisitId });
    return (
      <div className={styles.table__clinicCell}>
        { get(visitMetadata, 'metadata.createdBy.clinicName', '') }
      </div>
    );
  }


  renderHcp(visit) {
    const { phisetVisitId } = visit;
    const visitMetadata = find(this.props.visits, { phisetVisitId });
    const createdBy = get(visitMetadata, 'metadata.createdBy');
    if (!createdBy) return null;
    const { firstName, lastName, avatar } = createdBy;
    return (
      <div className="d-flex align-items-center">
        <Avatar
          avatarImg={avatar}
          name={[firstName, lastName]}
          className={`${styles.avatar} mr-3`}
          imgClassName={styles.avatar__img}
          initialsClassName={styles.avatar__initials}
        />
        <div className={styles.avatar__name}>
          { firstName } { lastName }
        </div>
      </div>
    );
  }


  renderVisitDate(visit) {
    const { phisetVisitId } = visit;
    const visitMetadata = find(this.props.visits, { phisetVisitId });
    const visitDate = get(visitMetadata, 'metadata.visitDate');
    if (!visitDate) return null;
    return formatDate(visitDate);
  }


  renderVisitActions(visit) {
    const associatedNotesBatches = get(visit, 'associatedDataIndexKeys.notes', []);
    const replies = get(visit, 'replies', 0);
    if (!associatedNotesBatches.length) {
      return (
        <div className={styles.table__notesCellEmpty}>
          <FormattedMessage {...messages.infos.noNotesToShow} />
        </div>
      );
    }

    return (
      <div className="text--center">
        <Button
          styleModifier="primary"
          className="btn--md w-100"
          isDisabled={!associatedNotesBatches.length}
          onClick={() => this.onShowNotes(visit)}
        >
          <FormattedMessage {...messages.buttons.showNotes} />
          { replies ? ` (${replies})` : null }
        </Button>
      </div>
    );
  }


  renderView() {
    if (this.state.view === 'visits') {
      if (!this.phiSetVisits.length) {
        const infoKey = this.props.mode === 'HCP' ? 'noVisits' : 'noVisitsPwd';
        return <Alert type="info" message={messages.infos[infoKey]} />;
      }
      return (
        <Table
          idKey="phisetVisitId"
          className={styles.table}
          schema={this.schema}
          entities={this.phiSetVisits}
          page={this.state.page}
          perPage={5}
          isInProgress={this.props.isFetchMetadataInProgress}
          isPerPageOff
          onPageChange={({ page, perPage }) => this.onFetchVisitsMetadata({ page, perPage })}
        />
      );
    }
    const { selectedVisit } = this.state;
    const phisetVisitId = selectedVisit && selectedVisit.phisetVisitId;
    const notes = filter(this.props.notes, { phisetVisitId }).sort(sortByNoteType);
    const visit = find(this.props.visits, { phisetVisitId });
    return (
      <VisitNotes
        visit={visit}
        notes={notes}
        mode={this.props.mode}
        phiSet={this.props.phiSet}
        phiSetDocumentId={this.props.phiSetDocumentId}
        onStoreNotes={this.props.onStoreNotes}
      />
    );
  }


  render() {
    return (
      <Modal
        modalId={constants.VISIT_HISTORY_MODAL}
        styleModifier="lg"
        openModalId={this.props.openModalId}
        onClose={() => this.onClose()}
      >
        { this.renderHeader() }
        <div className={styles.modalViewContainer}>
          { this.renderView() }
        </div>
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  activeVisit              : selectors.activeVisit(state),
  visits                   : selectors.visits(state),
  isFetchMetadataInProgress: selectors.isFetchVisitsMetadataInProgress(state),
  openModalId              : App.selectors.modal(state),
});

const mapDispatchToProps = (dispatch) => ({
  onFetchVisitsMetadata: (visits, prvKeyPem, passphrase) => dispatch(
    actions.fetchVisitsMetadata(visits, prvKeyPem, passphrase),
  ),
  onCloseModal: () => dispatch(App.actions.closeModal()),
});

const ConnectedVisitHistoryModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VisitHistoryModal);

export default withStyles(styles)(ConnectedVisitHistoryModal);
