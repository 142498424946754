import * as actionTypes from './actionTypes';


export const checkInformation = () => ({
  type: actionTypes.CHECK_INFORMATION,
});

export const checkInformationSuccess = (information, informationTemplate, consentsTemplate) => ({
  type   : actionTypes.CHECK_INFORMATION_SUCCESS,
  payload: {
    information,
    informationTemplate,
    consentsTemplate,
  },
});

export const checkInformationError = (error) => ({
  type: actionTypes.CHECK_INFORMATION_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const updateInformation = (informationUpdate) => ({
  type   : actionTypes.UPDATE_INFORMATION,
  payload: {
    informationUpdate,
  },
});

export const updateInformationSuccess = (informationUpdate) => ({
  type   : actionTypes.UPDATE_INFORMATION_SUCCESS,
  payload: {
    informationUpdate,
  },
});

export const updateInformationError = (error) => ({
  type: actionTypes.UPDATE_INFORMATION_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const checkOrganizationMemberships = () => ({
  type: actionTypes.CHECK_ORGANIZATION_MEMBERSHIPS,
});

export const checkOrganizationMembershipsSuccess = (organizationMemberships) => ({
  type   : actionTypes.CHECK_ORGANIZATION_MEMBERSHIPS_SUCCESS,
  payload: {
    organizationMemberships,
  },
});

export const checkOrganizationMembershipsError = (error) => ({
  type: actionTypes.CHECK_ORGANIZATION_MEMBERSHIPS_ERROR,
  error,
});
