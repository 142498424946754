import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import cn from 'classnames';
import { useAction } from 'hooks';
import Button from 'components/Form/Button';
import App from 'modules/App';
import * as actions from '../../../actions';
import * as selectors from '../../../selectors';
import messages from '../../../messages';
import styles from '../DownloadDataModal.pcss';


interface Props {
  selectedChannel: string,
  devicesByChannel: { [channel: string]: Device[] },
  onSetComponent: (componentName: string) => void,
}


const getConnectAction = (selectedChannel: string) => {
  // TODO: Update with new CGM
  if (selectedChannel === 'UsbC') return actions.connectUsbC;
  return actions.connectUsb;
};


const getInfoMessage = (selectedChannel: string) => {
  switch (selectedChannel) {
    case 'MicroUsb': return messages.connectUsbCable.info;
    case 'UsbC': return messages.connectUsbCCable.info;
    default: return messages.connectUsb.info;
  }
};


const renderImages = (selectedChannel: string) => {
  switch (selectedChannel) {
    case 'MicroUsb': {
      return (
        <>
          <img src="/assets/svg/micro-usb.svg" className={styles.connectionIcon} alt="" />
          <img src="/assets/svg/laptop.svg" className={cn(styles.laptopIcon, 'mb-3')} alt="" />
        </>
      );
    }
    case 'UsbC': return <img src="/assets/svg/connect-by-usb-c-cable.svg" className="w-100" alt="" />;
    default: return <img src="/assets/svg/connect-by-usb.svg" className="w-100" alt="" />;
  }
};


const renderDeviceIcon = (caseType: CaseType) => (
  <li key={caseType.caseTypeId}>
    <figure
      className={styles.devices__icon}
      dangerouslySetInnerHTML={{ __html: caseType.icon }} // eslint-disable-line react/no-danger
    />
  </li>
);


const ConnectUsb: FC<Props> = ({ selectedChannel, devicesByChannel, onSetComponent }) => {
  const connectionId = useSelector(selectors.connectionId);
  const caseTypes = useSelector(App.selectors.caseTypes);

  const microUsbDevices = get(devicesByChannel, 'MicroUsb', []);
  const caseTypeIds = uniq(map(microUsbDevices, (device) => device.caseTypeId));
  const microUsbCaseTypes = map(caseTypeIds, (caseTypeId) => find(caseTypes, { caseTypeId }));
  const infoMessage = getInfoMessage(selectedChannel);

  const startCheckingConnection = useAction(actions.startCheckingConnection);
  const connect = getConnectAction(selectedChannel);
  const onConnect = useAction(connect);

  useEffect(() => {
    if (connectionId) {
      startCheckingConnection(connectionId);
      onSetComponent('Downloading');
    }
  }, [connectionId]);

  const onChooseConnection = () => {
    onSetComponent('ChooseConnection');
  };

  return (
    <div className={styles.modal__content}>
      <h3 className="modal__subheader "><FormattedMessage {...messages.headers.howToStartConnection} /></h3>
      <p className="modal__info"><FormattedMessage {...infoMessage} /></p>
      <ul className={styles.devices}>{ map(microUsbCaseTypes, renderDeviceIcon) }</ul>
      <div className={styles.illustration}>{ renderImages(selectedChannel) }</div>
      <div>
        <Button
          styleModifier="primary"
          labelMessage={messages.buttons.next}
          className="btn--block btn--filled mb-0"
          onClick={onConnect}
        />
      </div>
      <div className={styles.divider}>
        <span className={styles.divider__text}><FormattedMessage {...messages.infos.or} /></span>
      </div>
      <Button
        styleModifier="transparent"
        labelMessage={messages.buttons.changeDeviceType}
        className={cn('btn--no-size text--primary m-0', styles.button__changeDevice)}
        onClick={onChooseConnection}
      />
    </div>
  );
};

export default ConnectUsb;
