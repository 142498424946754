export const imageUrlToBase64 = async (url) => {
  const blob = await url.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      if (base64data.includes('data:application/octet-stream;')) {
        resolve(base64data.replace('data:application/octet-stream;', 'data:image/png;'));
      } else {
        resolve(base64data);
      }
    };
    reader.onerror = reject;
  });
};
