import { useState } from 'react';


export const useServerEffect = (effect: () => void) => {
  if (process.env.BROWSER) return;
  const [hasBeenCalled, setHasBeenCalled] = useState<boolean>(false);
  if (hasBeenCalled) return;
  effect();
  setHasBeenCalled(true);
};
