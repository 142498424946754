import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import App from 'modules/App';
import Account from 'modules/Account';


const getDevicesByChannel = (devices: Device[], supportedDevices: Device[]) => {
  const devicesSet = isEmpty(supportedDevices) ? devices : supportedDevices;
  const devicesByChannel: { [channel: string]: Device[] } = {};
  forEach(devicesSet, (device) => forEach(device.channels, (channel) => {
    if (!devicesByChannel[channel]) {
      devicesByChannel[channel] = [];
    }
    devicesByChannel[channel].push(device);
  }));
  return devicesByChannel;
};


export const useDevicesByChannel = () => {
  const devices = useSelector(App.selectors.devices);
  const activeProfileType: ProfileType = useSelector(Account.selectors.activeProfileType);
  let supportedDevices: Device[];

  if (activeProfileType === Account.constants.PROFILE_TYPES.PWD) {
    const activeProfile = useSelector(Account.selectors.activeProfile);
    supportedDevices = get(activeProfile, 'supportedDevices', null);
  } else {
    const activeClinicMembership: ClinicMembership = useSelector(Account.selectors.activeClinicMembership);
    supportedDevices = get(activeClinicMembership, 'clinic.supportedDevices', null);
  }

  return useMemo(
    () => getDevicesByChannel(devices, supportedDevices),
    [devices, supportedDevices],
  );
};
