// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".xPX-C{margin-bottom:3rem}._23t2c{font-size:12px;font-size:1.2rem}.BOQsW{font-size:14px;font-size:1.4rem;padding:1rem;line-height:1.4}.BOQsW:nth-child(2n){background-color:#f5f9ff}.taxoJ{padding-bottom:1.8rem}._3-1AE{-webkit-transform:rotate(-90deg);-ms-transform:rotate(-90deg);transform:rotate(-90deg);height:20px;width:16px;fill:#30a8ff}", ""]);
// Exports
exports.locals = {
	"section": "xPX-C",
	"section__header": "_23t2c",
	"dataRow": "BOQsW",
	"dataRow--head": "taxoJ",
	"dataRow__arrow": "_3-1AE"
};
module.exports = exports;
