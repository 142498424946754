import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import Button from 'components/Form/Button';
import { useAction } from 'hooks';
import App from 'modules/App';
import styles from 'modules/Layout/components/MainLayout/MainLayout.pcss';
import messages from 'modules/Layout/messages';
import PatientResults from 'modules/PatientResults';


const ClosePrintModeBtn: FC = () => {
  const isPrintMode = useSelector(App.selectors.printMode);

  const setDashboardLayout = useAction(
    PatientResults.actions.setDashboardLayout,
    PatientResults.constants.DASHBOARD_LAYOUTS.basic,
  );
  const setPrintMode = useAction(App.actions.setPrintMode, false);

  if (!isPrintMode) {
    return null;
  }

  const onCloseReport = () => {
    setDashboardLayout();
    setPrintMode();
  };

  return (
    <Button
      type="button"
      styleModifier="secondary"
      className={cn('btn--filled', styles.closePrintModeBtn)}
      onClick={onCloseReport}
    >
      <FormattedMessage {...messages.printMode.closePrintMode} />
    </Button>
  );
};

export default ClosePrintModeBtn;
