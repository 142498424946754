import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import Dropdown from 'svg/dropdown.svg';
import messages from '../../../messages';
import styles from '../GlucoseSettings.pcss';


class GlucoseLevel extends React.PureComponent {

  static propTypes = {
    // Explicit props
    formValues            : PropTypes.object,
    hasElevatedPermissions: PropTypes.bool,
    type                  : PropTypes.string,
    conversion            : PropTypes.object,
    // Explicit actions
    onSetValue            : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }


  renderItem(id, messageKey) {
    return (
      <FormGroup
        id={id}
        labelMessage={messages.labels[messageKey]}
        formValues={this.props.formValues}
      >
        <Input
          placeholder={messages.placeholders[messageKey]}
          onChange={(input) => this.props.onSetValue(input)}
          type="number"
          isDisabled={!this.props.hasElevatedPermissions}
          suffix={this.props.conversion.unitSymbol}
        />
      </FormGroup>
    );
  }


  renderContent() {
    const { type } = this.props;
    const { isOpen } = this.state;
    if (!isOpen) {
      return null;
    }
    return (
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: 'auto' }}
        exit={{ height: 0 }}
        transition={{ ease: 'easeOut', duration: 0.15 }}
      >
        <div className="row">
          <div className="col-6">
            { this.renderItem(`range-${type}-preMeal-lowThreshold`, 'glucosePreMealLow') }
          </div>
          <div className="col-6">
            { this.renderItem(`range-${type}-preMeal-highThreshold`, 'glucosePreMealHigh') }
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            { this.renderItem(`range-${type}-postMeal-lowThreshold`, 'glucosePostMealLow') }
          </div>
          <div className="col-6">
            { this.renderItem(`range-${type}-postMeal-highThreshold`, 'glucosePostMealHigh') }
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            { this.renderItem(`range-${type}-critical-lowThreshold`, 'glucoseCriticalLow') }
          </div>
          <div className="col-6">
            { this.renderItem(`range-${type}-critical-highThreshold`, 'glucoseCriticalHigh') }
          </div>
        </div>
      </motion.div>
    );
  }


  render() {
    const { type } = this.props;
    const { isOpen } = this.state;
    return (
      <div className={styles.settingItem_container}>
        <div
          className={styles.settingItem_header}
          onClick={() => this.setState({ isOpen: !isOpen })}
          role="presentation"
        >
          <h2 className={cn('title', styles.title_header)}>
            <FormattedMessage {...messages.headers[`glucose${type}`]} />
            <motion.div
              initial={{ rotate: '180deg' }}
              animate={isOpen ? 'open' : 'close'}
              variants={{
                open : { rotate: '180deg' },
                close: { rotate: '0deg' },
              }}
            >
              <Dropdown />
            </motion.div>
          </h2>
        </div>
        <AnimatePresence>
          { this.renderContent() }
        </AnimatePresence>
      </div>
    );
  }

}

export default withStyles(styles)(GlucoseLevel);
