import get from 'lodash/get';
import includes from 'lodash/includes';
import { createSelector } from 'reselect';
import moment from 'moment';
import DataSources from 'modules/DataSources';
import * as constants from './constants';
import * as actionTypes from './actionTypes';


/**
 * Input selectors
 */
const mainSelector = (state) => state[constants.MODULE_ID];
const fetchingSelector = (state) => state[constants.MODULE_ID].fetching;
const sendingSelector = (state) => state[constants.MODULE_ID].sending;
const errorsSelector = (state) => state[constants.MODULE_ID].errors;


/**
 * Patients list
 */
export const patients = createSelector(
  mainSelector,
  (state) => state.patients,
);


/**
 * Enrolling sharing request / pending patients
 */
export const enrollingSharingRequests = createSelector(
  mainSelector,
  (state) => state.enrollingSharingRequests,
);


/**
 * Current clinic patients profiles document id
 */
export const profilesDocumentId = createSelector(
  mainSelector,
  (state) => state.profilesDocumentId,
);


/**
 * Patients search
 */
export const searchSelector = (id) => createSelector(
  mainSelector,
  (state) => get(state.search, id, ''),
);


/**
 * Clinics search
 */
export const searchClinicsSelector = (id) => createSelector(
  mainSelector,
  (state) => get(state.searchClinics, id, ''),
);


/**
 * Active patient
 */
export const activePatient = createSelector(
  mainSelector,
  (state) => state.activePatient,
);

/**
 * Active patient PHI set
 */
export const phiSet = createSelector(
  mainSelector,
  (state) => state.phiSet,
);


/**
 * Active patient PHI set document id
 */
export const phiSetDocumentId = createSelector(
  mainSelector,
  (state) => state.phiSetDocumentId,
);


/**
 * ID of a Patient who own loaded PHI set
 */
export const phiSetPatientId = createSelector(
  mainSelector,
  (state) => state.phiSetPatientId,
);


/**
 * Active patient fetched readings monthly batches index
 */
export const batchesIndex = createSelector(
  mainSelector,
  (state) => state.batchesIndex,
);


/**
 * Active patient fetched cgm weekly batches index
 */
export const cgmBatchesIndex = createSelector(
  mainSelector,
  (state) => state.cgmBatchesIndex,
);


/**
 * Active patient fetched notes monthly batches index
 */
export const notesBatchesIndex = createSelector(
  mainSelector,
  (state) => state.notesBatchesIndex,
);


/**
 * Active patient fetched measurements monthly batches index
 */
export const measurementsBatchesIndex = createSelector(
  mainSelector,
  (state) => state.measurementsBatchesIndex,
);


/**
 * Active patient blood glucose readings
 */
export const readings = createSelector(
  mainSelector,
  (state) => state.readings,
);


/**
 * Active patient blood glucose related data
 */
export const relatedData = createSelector(
  mainSelector,
  (state) => state.relatedData,
);


/**
 * Meal, insulin, activity, medicaments 3rd app records
 */
export const timeSeriesResources = createSelector(
  mainSelector,
  (state) => state.timeSeriesResources,
);


/**
 * Blood glucose highlightedReadings
 */
export const highlightedReadings = createSelector(
  mainSelector,
  (state) => state.highlightedReadings,
);

/**
 * Active patient measurements
 */
export const measurements = createSelector(
  mainSelector,
  (state) => state.measurements,
);

/**
 * Active patient blood glucose readings with related data
 */
export const readingsWithRelatedData = createSelector(
  [relatedData, readings],
  (relatedDataState, readingsState) => readingsState.map((reading) => {
    const findRelatedData = relatedDataState && relatedDataState.find((item) => item.readingId === reading.externalId);
    if (findRelatedData) {
      reading.relatedData = findRelatedData;
    }
    return reading;
  }),
);


/**
 * Active patient cgm readings
 */
export const cgmReadings = createSelector(
  mainSelector,
  (state) => state.cgmReadings,
);


/**
 * Active patient blood glucose readings in range Date
 */
export const readingsRangeDate = createSelector(
  readings,
  (readingsState) => (daysEndOffset, daysBeforeEndOffset = 30) => {
    const lastPossibleMoment = moment.utc().add(-daysEndOffset, 'days').endOf('day').locale('en--account');
    const firstPossibleMoment = lastPossibleMoment.clone().add(-(daysBeforeEndOffset - 1), 'days').startOf('day');

    const firstPossibleTimestamp = firstPossibleMoment.unix();
    const lastPossibleTimestamp = lastPossibleMoment.unix();

    return readingsState.filter((reading) => reading.timestamp > firstPossibleTimestamp
      && reading.timestamp < lastPossibleTimestamp);
  }
);

//----------------------------------------------------------------------------------------------------------------------

export const notes = createSelector(
  mainSelector,
  (state) => state.notes,
);

//----------------------------------------------------------------------------------------------------------------------

export const isFetchPatientsInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_PATIENTS),
);

export const isFetchEnrollingSharingRequestsInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_ENROLLING_SHARING_REQUESTS),
);

//----------------------------------------------------------------------------------------------------------------------

/**
 * Active patient sharing request
 */
export const sharingRequest = createSelector(
  mainSelector,
  (state) => state.sharingRequest,
);

/**
 * Received sharing request in qr code add patient process
 */
export const receivedSharingRequest = createSelector(
  mainSelector,
  (state) => state.receivedSharingRequest,
);

//----------------------------------------------------------------------------------------------------------------------

/**
 * Selects patient data differences
 */
export const patientDataComparison = createSelector(
  mainSelector,
  (state) => state.patientDataComparison,
);

/**
 * Indicates if hide patient data differences according to snapshots
 */
export const isPatientDataFullComparison = createSelector(
  mainSelector,
  (state) => state.isPatientDataFullComparison,
);

//----------------------------------------------------------------------------------------------------------------------

/**
 * Default settings for account country
 */
export const countrySettings = createSelector(
  mainSelector,
  (state) => state.countrySettings,
);

/**
 * Select custom patient information template dependent on country
 */
export const informationTemplate = createSelector(
  mainSelector,
  (state) => state.informationTemplate,
);

/**
 * Select custom patient information template dependent on country
 */
export const clinicPatientTemplate = createSelector(
  mainSelector,
  (state) => state.clinicPatientTemplate,
);

/**
 * Select custom patient identifiers dependent on country
 */
export const patientCustomIdentifiers = createSelector(
  mainSelector,
  (state) => state.patientCustomIdentifiers,
);


/**
 * Select payers dependent on country
 */
export const payers = createSelector(
  mainSelector,
  (state) => state.payers,
);

//----------------------------------------------------------------------------------------------------------------------

export const isActivatePatientInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.ACTIVATE_PATIENT),
);

//----------------------------------------------------------------------------------------------------------------------

export const isAddPatientInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.ADD_PATIENT) || includes(state, actionTypes.FETCH_PATIENTS),
);

export const hasAddPatientErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.ADD_PATIENT),
);

//----------------------------------------------------------------------------------------------------------------------

export const isEnrollPatientInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.ENROLL_PATIENT),
);

export const hasEnrollPatientErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.ENROLL_PATIENT),
);

//----------------------------------------------------------------------------------------------------------------------

export const isMergePatientInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.MERGE_PATIENT),
);

export const hasMergePatientErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.MERGE_PATIENT),
);

//----------------------------------------------------------------------------------------------------------------------

export const isUpdatePatientInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.UPDATE_PATIENT),
);

export const hasUpdatePatientErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.UPDATE_PATIENT),
);

//----------------------------------------------------------------------------------------------------------------------

export const isStartPatientVisitInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.START_PATIENT_VISIT),
);

export const hasStartPatientVisitErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.START_PATIENT_VISIT),
);

//----------------------------------------------------------------------------------------------------------------------

export const isEndPatientVisitInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.END_PATIENT_VISIT),
);

export const hasEndPatientVisitErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.END_PATIENT_VISIT),
);

//----------------------------------------------------------------------------------------------------------------------

export const isRemovePatientInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.REMOVE_PATIENT) || includes(state, actionTypes.FETCH_PATIENTS),
);

export const hasRemovePatientErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.REMOVE_PATIENT),
);

//----------------------------------------------------------------------------------------------------------------------

export const isReassignHcpInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.REASSIGN_HCP),
);

export const hasReassignHcpErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.REASSIGN_HCP),
);

//----------------------------------------------------------------------------------------------------------------------

export const isCreateSharingRequestInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.CREATE_SHARING_REQUEST),
);

export const hasCreateSharingRequestErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.CREATE_SHARING_REQUEST),
);

//----------------------------------------------------------------------------------------------------------------------

export const isRemoveSharingRequestInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.REMOVE_SHARING_REQUEST),
);

export const hasRemoveSharingRequestErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.REMOVE_SHARING_REQUEST),
);

//----------------------------------------------------------------------------------------------------------------------

export const isResendInvitationPatientInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.RESEND_SHARING_REQUEST),
);

export const resendInvitationPatientErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.RESEND_SHARING_REQUEST),
);

//----------------------------------------------------------------------------------------------------------------------

export const isBindSharingRequestWithClinicPatientInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT),
);

export const hasBindSharingRequestWithClinicPatientErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT),
);

//----------------------------------------------------------------------------------------------------------------------

export const isSyncInProgress = createSelector(
  fetchingSelector,
  DataSources.selectors.isSyncInProgress,
  (state, isSyncInProgressState) => includes(state, actionTypes.SYNC) || isSyncInProgressState,
);

export const hasSyncErrors = createSelector(
  errorsSelector,
  DataSources.selectors.hasSyncErrors,
  (state, hasSyncErrorsState) => includes(state, actionTypes.SYNC) || hasSyncErrorsState,
);


export const isStorePatientInfoInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.STORE_PATIENT_INFO),
);

export const hasStorePatientInfoErrors = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.STORE_PATIENT_INFO_ERROR),
);

//----------------------------------------------------------------------------------------------------------------------

export const isFetchPatientCountryLocalizationInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_PATIENT_COUNTRY_LOCALIZATION),
);

export const hasFetchPatientCountryLocalizationErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.FETCH_PATIENT_COUNTRY_LOCALIZATION),
);

//----------------------------------------------------------------------------------------------------------------------

export const isAddPatientToFavoritesInProgess = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.ADD_PATIENT_TO_FAVORITE),
);
