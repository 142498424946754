export const useCookies = () => {
  const setCookie = (cname: string, cvalue: string | number, exdays: number) => {
    if (typeof cvalue !== 'number' && typeof cvalue !== 'string') {
      throw new Error('Cookie value must be a number or string');
    }

    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
  };


  const getCookie = (cname: string) => {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };


  const checkCookie = (cname: string) => {
    const cookie = getCookie(cname);
    if (cookie !== '') {
      return cookie;
    }

    return false;
  };


  return {
    setCookie,
    getCookie,
    checkCookie,
  };
};
