import React from 'react';
import { connect, batch } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import moment from 'moment';
import { dailyRecordsShape, weeklyRecordsShape, monthlyRecordsShape, previewShape } from 'shapes/bins';
import BloodGlucoseProfileMiniChart from 'components/Charts/BloodGlucoseProfileMiniChart';
import App from 'modules/App';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { DAYS_SELECTOR_ZOOM } from '../../constants';
import messages from '../../messages';
import DaysSelectorControlBtn from '../DaysSelectorControlBtn';
import styles from './DaysSelector.pcss';


class DaysSelector extends React.PureComponent {

  static propTypes = {
    // Implicit props
    daysSelectorZoom       : PropTypes.string,
    dailyRecords           : dailyRecordsShape,
    dailyRecordsPaginated  : dailyRecordsShape,
    weeklyRecordsPaginated : weeklyRecordsShape,
    monthlyRecordsPaginated: monthlyRecordsShape,
    highlightedDates       : PropTypes.arrayOf(PropTypes.string),
    preview                : previewShape,
    monthlyAggregations    : PropTypes.arrayOf(
      PropTypes.shape({
        hourlyBins: PropTypes.arrayOf(PropTypes.object),
        month     : PropTypes.number,
        year      : PropTypes.number,
      })),
    weeklyAggregations: PropTypes.arrayOf(
      PropTypes.shape({
        hourlyBins: PropTypes.arrayOf(PropTypes.object),
        month     : PropTypes.number,
        year      : PropTypes.number,
        week      : PropTypes.number,
      })),
    dailyAggregations: PropTypes.arrayOf(
      PropTypes.shape({
        hourlyBins: PropTypes.arrayOf(PropTypes.object),
        month     : PropTypes.number,
        year      : PropTypes.number,
        week      : PropTypes.number,
        day       : PropTypes.number,
      })),
    // Implicit actions
    setHighlightedDate    : PropTypes.func,
    setHighlightedDates   : PropTypes.func,
    resetHighlightedDate  : PropTypes.func,
    resetHighlightedDates : PropTypes.func,
    selectHighlightedDates: PropTypes.func,
    onDatesChange         : PropTypes.func,
    // Explicit props
    conversion            : PropTypes.object.isRequired,
    standards             : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    direction: PropTypes.string,
  };


  constructor(props) {
    super(props);
    this.state = {
      hover: null,
      focus: false,
    };
  }


  componentDidUpdate(prevProps) {
    if (prevProps.daysSelectorZoom !== this.props.daysSelectorZoom && this.state.focus) {
      this.resetFocus();
    }
  }


  selectHighlightedDates(range) {
    const { onDatesChange, selectHighlightedDates } = this.props;
    selectHighlightedDates(range);
    onDatesChange(
      moment(range.start, 'YYYY-MM-DD').add(1, 'day'),
      moment(range.end, 'YYYY-MM-DD').add(1, 'day')
    );
  }


  resetFocus() {
    this.setState({ focus: false });
  }


  renderChart({ aggregation, isDisabled }) {
    const {
      conversion,
      standards,
      direction,
    } = this.props;
    return (
      <div className={styles.daySelector__cellChartWrapper}>
        {
          !isDisabled
            ? (
              <BloodGlucoseProfileMiniChart
                conversion={conversion}
                standards={standards}
                records={aggregation}
                direction={direction}
              />
            ) : (
              <div className={styles.daySelector__placeholder} />
            )
        }
      </div>
    );
  }


  renderButton({ isActive, isDisabled, onClick, upperLineLabel, lowerLineLabel }) {
    return (
      <div className={styles.daySelector__cellButtonWrapper}>
        <button
          className={cn(
            'btn',
            'btn--filled',
            styles.daySelector__cellButton,
            {
              'btn--primary'                               : isActive,
              'btn--transparent'                           : !isActive,
              'btn--disabled'                              : isDisabled,
              [styles['daySelector__cellButton--active']]  : isActive,
              [styles['daySelector__cellButton--disabled']]: isDisabled,
            }
          )}
          onClick={onClick}
          disabled={isDisabled}
          type="button"
        >
          <span>
            {upperLineLabel}
          </span>
          <span>
            {lowerLineLabel}
          </span>
        </button>
      </div>
    );
  }


  renderDaySelector() {
    const {
      dailyRecordsPaginated,
      highlightedDates,
      dailyAggregations,
    } = this.props;
    return (
      <div className={styles.daySelector}>
        {
          dailyRecordsPaginated.map((dailyPreview) => {
            const dailyAggregation = dailyAggregations.find((aggregation) => (
              Number(aggregation.day) === Number(dailyPreview.day)
              && Number(aggregation.month) === Number(dailyPreview.month)
              && Number(aggregation.year) === Number(dailyPreview.year)
            ));
            const isActive = highlightedDates.indexOf(dailyPreview.date) !== -1;
            // const isDisabled = !(dailyPreview.records && dailyPreview.records.length);
            const isDisabled = false;
            const isHover = this.state.focus && highlightedDates.length === 1
              && ((dailyPreview.date <= highlightedDates[0] && dailyPreview.date >= this.state.hover)
                || (dailyPreview.date >= highlightedDates[0] && dailyPreview.date <= this.state.hover));
            const onClick = (e) => {
              e.stopPropagation();
              if (this.state.focus) {
                if (highlightedDates.length === 1 && highlightedDates[0] === dailyPreview.date) {
                  this.setState({ focus: false });
                  return;
                } if (highlightedDates.length === 1 && highlightedDates[0] !== dailyPreview.date) {
                  const start = highlightedDates[0];
                  const end = dailyPreview.date;
                  const range = {
                    start: start < end ? start : end,
                    end  : end < start ? start : end,
                  };
                  this.selectHighlightedDates(range);
                  return;
                }
              }
              this.selectHighlightedDates({ start: dailyPreview.date, end: dailyPreview.date });
              this.setState({ focus: true });
            };
            return (
              <div
                className={cn(styles.daySelector__cell, {
                  [styles['daySelector__cell--hover']]: isHover,
                })}
                key={dailyPreview.date}
                onClick={onClick}
                role="presentation"
                onMouseEnter={() => this.setState({ hover: dailyPreview.date })}
              >
                {
                  this.renderButton({
                    upperLineLabel: dailyPreview.weekday,
                    lowerLineLabel: dailyPreview.date.split('-').join('/'),
                    isActive,
                    onClick,
                  })
                }
                { this.renderChart({ aggregation: dailyAggregation, isDisabled })}
              </div>
            );
          })}
      </div>
    );
  }


  renderWeekSelector() {
    const {
      weeklyRecordsPaginated,
      highlightedDates,
      weeklyAggregations,
    } = this.props;
    const isSingularSelected = weeklyRecordsPaginated
      .map((hourlyRecords) =>
        hourlyRecords.dates.length && hourlyRecords.dates.map(({ date } = {}) => date).every((date) => highlightedDates.includes(date)))
      .filter((selected) => selected).length === 1;
    return (
      <div className={styles.weekSelector}>
        {
          weeklyRecordsPaginated.map((weeklyPreview) => {
            const weeklyAggregation = weeklyAggregations.find((aggregation) => (
              Number(aggregation.week + 1) === Number(weeklyPreview.weekNo)
              && Number(aggregation.year) === Number(weeklyPreview.year)
            ));
            const isActive = weeklyPreview.dates.length
            && weeklyPreview.dates.map(({ date } = {}) => date).every((date) => highlightedDates.includes(date));
            // const isDisabled = !(weeklyPreview.records && weeklyPreview.records.length); //TODO
            const isDisabled = false;
            const firstDate = weeklyPreview.dates[0].date;
            const lastDate = weeklyPreview.dates[weeklyPreview.dates.length - 1].date;
            const isHover = this.state.focus && isSingularSelected
              && ((firstDate <= highlightedDates[0] && lastDate >= this.state.hover)
                || (lastDate >= highlightedDates[highlightedDates.length - 1] && firstDate <= this.state.hover));
            const onClick = (e) => {
              e.stopPropagation();
              if (this.state.focus) {
                this.setState({ focus: false });
                if (isSingularSelected && highlightedDates[0] === firstDate && highlightedDates[highlightedDates.length - 1] === lastDate) {
                  return;
                }
                if (isSingularSelected && firstDate < highlightedDates[0]) {
                  const start = firstDate;
                  const end = highlightedDates[highlightedDates.length - 1];
                  this.selectHighlightedDates({ start, end });
                  return;
                }
                if (isSingularSelected && lastDate > highlightedDates[highlightedDates.length - 1]) {
                  const start = highlightedDates[0];
                  const end = lastDate;
                  this.selectHighlightedDates({ start, end });
                  return;
                }
                return;
              }
              this.selectHighlightedDates({ start: firstDate, end: lastDate });
              this.setState({ focus: true });
            };
            return (
              <div
                className={cn(styles.daySelector__cell, {
                  [styles['daySelector__cell--hover']]: isHover,
                })}
                key={weeklyPreview.weekKey}
                onClick={onClick}
                role="presentation"
                onMouseEnter={() => this.setState({ hover: lastDate })}
              >
                {
                  this.renderButton({
                    upperLineLabel: <FormattedMessage {...messages.labels.week} values={{ count: weeklyPreview.weekNo }} />,
                    lowerLineLabel: `${weeklyPreview.month}/${weeklyPreview.year}`,
                    isActive,
                    onClick,
                  })
                }
                { this.renderChart({ aggregation: weeklyAggregation, isDisabled })}
              </div>
            );
          })}
      </div>
    );
  }


  renderMonthSelector() {
    const {
      monthlyRecordsPaginated,
      highlightedDates,
      monthlyAggregations,
    } = this.props;
    const isSingularSelected = monthlyRecordsPaginated
      .map((hourlyRecords) =>
        hourlyRecords.dates.length && hourlyRecords.dates.map(({ date } = {}) => date).every((date) => highlightedDates.includes(date)))
      .filter((selected) => selected).length === 1;
    return (
      <div className={styles.monthSelector}>
        {
          monthlyRecordsPaginated.map((monthlyPreview) => {
            const monthlyAggregation = monthlyAggregations.find((aggregation) => (
              Number(aggregation.month) === Number(monthlyPreview.month)
              && Number(aggregation.year) === Number(monthlyPreview.year)
            ));
            const isActive = monthlyPreview.dates.length
            && monthlyPreview.dates.map(({ date } = {}) => date).every((date) => highlightedDates.includes(date));
            // const isDisabled = !(monthlyPreview.records && monthlyPreview.records.length); // TODO
            const isDisabled = false;
            const firstDate = monthlyPreview.dates[0].date;
            const lastDate = monthlyPreview.dates[monthlyPreview.dates.length - 1].date;
            const isHover = this.state.focus && isSingularSelected
              && ((firstDate <= highlightedDates[0] && lastDate >= this.state.hover)
                || (lastDate >= highlightedDates[highlightedDates.length - 1] && firstDate <= this.state.hover));
            const onClick = (e) => {
              e.stopPropagation();
              if (this.state.focus) {
                this.setState({ focus: false });
                if (isSingularSelected && highlightedDates[0] === firstDate && highlightedDates[highlightedDates.length - 1] === lastDate) {
                  return;
                }
                if (isSingularSelected && firstDate < highlightedDates[0]) {
                  const start = firstDate;
                  const end = highlightedDates[highlightedDates.length - 1];
                  this.selectHighlightedDates({ start, end });
                  return;
                }
                if (isSingularSelected && lastDate > highlightedDates[highlightedDates.length - 1]) {
                  const start = highlightedDates[0];
                  const end = lastDate;
                  this.selectHighlightedDates({ start, end });
                  return;
                }
                return;
              }
              this.selectHighlightedDates({ start: firstDate, end: lastDate });
              this.setState({ focus: true });
            };
            return (
              <div
                className={cn(styles.daySelector__cell, {
                  [styles['daySelector__cell--hover']]: isHover,
                })}
                key={monthlyPreview.monthKey}
                onClick={onClick}
                role="presentation"
                onMouseEnter={() => this.setState({ hover: lastDate })}
              >
                {
                  this.renderButton({
                    upperLineLabel: monthlyPreview.monthName,
                    lowerLineLabel: `${monthlyPreview.month}/${monthlyPreview.year}`,
                    onClick,
                    isActive,
                  })
                }
                { this.renderChart({ aggregation: monthlyAggregation, isDisabled })}
              </div>
            );
          })}
      </div>
    );
  }


  renderSelector() {
    const {
      daysSelectorZoom,
    } = this.props;
    switch (daysSelectorZoom) {
      case DAYS_SELECTOR_ZOOM.day: {
        return this.renderDaySelector();
      }
      case DAYS_SELECTOR_ZOOM.week: {
        return this.renderWeekSelector();
      }
      case DAYS_SELECTOR_ZOOM.month: {
        return this.renderMonthSelector();
      }
      default: return null;
    }
  }


  render() {
    return (
      <div className={styles.root}>
        <div className={styles.header}>
          <DaysSelectorControlBtn />
        </div>
        {this.renderSelector()}
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  daysSelectorZoom        : selectors.daysSelectorZoom(state),
  preview                 : selectors.preview(state),
  dailyRecords            : selectors.dailyRecords(state),
  dailyRecordsPaginated   : selectors.dailyRecordsPaginated(state),
  weeklyRecordsPaginated  : selectors.weeklyRecordsPaginated(state),
  monthlyRecordsPaginated : selectors.monthlyRecordsPaginated(state),
  highlightedDates        : selectors.highlightedDates(state),
  highlightedHourlyRecords: selectors.highlightedHourlyRecords(state),
  direction               : App.selectors.direction(state),
  monthlyAggregations     : selectors.monthlyAggregations(state),
  weeklyAggregations      : selectors.weeklyAggregations(state),
  dailyAggregations       : selectors.dailyAggregations(state),
});


const mapDispatchToProps = (dispatch) => ({
  resetHighlightedDate  : (date) => dispatch(actions.resetHighlightedDate(date)),
  resetHighlightedDates : (dates) => batch(() => dates.forEach((date) => dispatch(actions.resetHighlightedDate(date)))),
  setHighlightedDate    : (date) => dispatch(actions.setHighlightedDate(date)),
  setHighlightedDates   : (dates) => dispatch(actions.setHighlightedDates(dates)),
  selectHighlightedDates: (range) => dispatch(actions.selectHighlightedDates(range)),
});


const ConnectedDaysSelector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DaysSelector);


export default withStyles(styles)(ConnectedDaysSelector);
