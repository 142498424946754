import { createSelector } from 'reselect';
import includes from 'lodash/includes';
import * as actionTypes from './actionTypes';
import * as constants from './constants';


const mainSelector = (state) => state[constants.MODULE_ID];
const fetchingSelector = (state) => state[constants.MODULE_ID].fetching;
const errorsSelector = (state) => state[constants.MODULE_ID].errors;


//----------------------------------------------------------------------------------------------------------------------

export const profile = createSelector(
  mainSelector,
  (state) => state.profile,
);

export const isSyncInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.CONTOUR_CLOUD_ME)
);

export const hasSyncErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.CONTOUR_CLOUD_ME)
);

export const isReauthInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.CONTOUR_CLOUD_REAUTH)
);

export const hasReauthErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.CONTOUR_CLOUD_REAUTH)
);
