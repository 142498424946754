import React, { FC, MutableRefObject, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import filter from 'lodash/filter';
import get from 'lodash/get';
import pick from 'lodash/pick';
import isNaN from 'lodash/isNaN';
import times from 'lodash/times';
import valuesIn from 'lodash/valuesIn';
import { useAction } from 'hooks';
import Validator from 'libs/Validator';
import Form from 'components/Form';
import Button from 'components/Form/Button';
import App from 'modules/App';
import * as actions from '../../../../actions';
import * as constants from '../../../../constants';
import * as selectors from '../../../../selectors';
import messages from '../../../../messages';
import styles from '../../DownloadDataModal.pcss';
import validatorRules from './validatorRules.json';


interface ActionsProps {
  values: { [id: string]: string },
  pinFields: string[],
}

interface DigitProps {
  position: number,
  values: { [id: string]: string },
  inputs: MutableRefObject<HTMLInputElement>[],
  pinFields: string[],
}

interface Props {
  onSetComponent: (componentName: string) => void,
}


const Actions: FC<ActionsProps> = ({ values, pinFields }) => {
  const isInProgress = useSelector(selectors.isSendBluetoothPinInProgress);
  const codeValues = pick(values, pinFields);
  const filteredValues = filter(valuesIn(codeValues), (value) => value !== '');
  const isDisabled = filteredValues.length !== constants.BLUETOOTH_PIN_LENGTH;

  return (
    <div className="modal__actions">
      <Button
        type="submit"
        styleModifier="primary"
        labelMessage={messages.buttons.submitPin}
        className="btn--block btn--filled"
        isDisabled={isDisabled}
        isInProgress={isInProgress}
      />
    </div>
  );
};


const Digit: FC<DigitProps> = ({ position, values, inputs, pinFields }) => {
  const setFormValue = useAction(App.actions.setFormValue, constants.BLUETOOTH_PIN_FORM);
  const id = `pin_${position}`;
  const value = get(values, id, '');
  inputs[position] = useRef();
  pinFields[position] = id;

  const onChange = (evt) => {
    const { value: newValue } = evt.target;
    if (newValue === '') {
      setFormValue({ id, value: newValue });
      return;
    }
    const numberValue = Number(newValue);
    if (isNaN(numberValue)) {
      return;
    }
    setFormValue({ id, value: numberValue });
    const nextInput = get(inputs, position + 1);
    if (nextInput) {
      nextInput.current.focus();
    }
  };

  return (
    <input
      key={id}
      id={id}
      value={value}
      className={`form-control ${styles.pin__digit}`}
      autoComplete="off"
      autoFocus={!position} // eslint-disable-line jsx-a11y/no-autofocus
      maxLength={1}
      onChange={onChange}
      ref={inputs[position]}
    />
  );
};


const BluetoothPin: FC<Props> = ({ onSetComponent }) => {
  const connectionId = useSelector(selectors.connectionId);
  const formValues = useSelector(App.selectors.formSelector(constants.BLUETOOTH_PIN_FORM));
  const values = get(formValues, ['values'], {});
  const isInProgress = useSelector(selectors.isSendBluetoothPinInProgress);
  const prevIsInProgressRef = useRef(isInProgress);
  const hasErrors = useSelector(selectors.hasSendBluetoothPinErrors);
  const inputs: MutableRefObject<HTMLInputElement>[] = [];
  const pinFields: string[] = [];

  const sendBluetoothPin = useAction(actions.sendBluetoothPin);
  const startCheckingConnection = useAction(actions.startCheckingConnection);
  const startFormProcessing = useAction(App.actions.startFormProcessing, constants.BLUETOOTH_PIN_FORM);
  const setFormErrors = useAction(App.actions.setFormErrors, constants.BLUETOOTH_PIN_FORM);
  const clearForm = useAction(App.actions.clearForm, constants.BLUETOOTH_PIN_FORM);

  useEffect(() => () => {
    clearForm();
  }, []);

  useEffect(() => {
    if (prevIsInProgressRef.current !== isInProgress) {
      if (!isInProgress && !hasErrors) {
        startCheckingConnection(connectionId);
        onSetComponent('Downloading');
      }
      prevIsInProgressRef.current = isInProgress;
    }
  }, [isInProgress]);

  const onSubmit = () => {
    startFormProcessing();
    let pin = '';
    for (let i = 0; i < constants.BLUETOOTH_PIN_LENGTH; i++) {
      const id = `pin_${i}`;
      const value = get(values, id);
      pin += value;
    }
    const { validatedValues, errors } = Validator.run({ ...values, pin }, validatorRules);
    if (errors) {
      setFormErrors(errors);
      return;
    }
    sendBluetoothPin(validatedValues);
  };

  const onChangeDeviceType = () => {
    onSetComponent('ChooseConnection');
  };

  const renderDigit = (position: number) => <Digit key={position} {...{ position, values, inputs, pinFields }} />;

  return (
    <div className={`${styles.modal__content} h-auto`}>
      <h3 className="modal__subheader "><FormattedMessage {...messages.bluetoothPin.header} /></h3>
      <img src="/assets/svg/bluetooth-pin.svg" className="mt-4 mb-3" alt="" />
      <Form onSubmit={onSubmit} className={styles.pinForm}>
        <div className="form-group">
          <label className="form-label" htmlFor="pin_0">
            <FormattedMessage {...messages.labels.pin} />
          </label>
          <div className={styles.pin}>
            { times(constants.BLUETOOTH_PIN_LENGTH, renderDigit) }
          </div>
        </div>
        <Actions {...{ values, pinFields }} />
      </Form>
      <div className={styles.divider}>
        <span className={styles.divider__text}><FormattedMessage {...messages.infos.or} /></span>
      </div>
      <Button
        styleModifier="transparent"
        labelMessage={messages.buttons.changeDeviceType}
        className="btn--no-size text--primary m-0"
        onClick={onChangeDeviceType}
      />
    </div>
  );
};

export default BluetoothPin;
