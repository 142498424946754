import PropTypes from 'prop-types';


export const notification = PropTypes.shape({
  instantNotificationId: PropTypes.number.isRequired,
  notificationTrigger  : PropTypes.string.isRequired,
  resourceKey          : PropTypes.string.isRequired,
  variables            : PropTypes.object.isRequired,
  status               : PropTypes.oneOf(['Active', 'Read', 'Dismissed', 'Error']).isRequired,
});
