import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import find from 'lodash/find';
import Avatar from 'components/Avatar';
import Button from 'components/Form/Button';
import Modal from 'components/Modal';
import App from 'modules/App';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import styles from '../ModalCommon.pcss';


class ConfirmDataSharingModal extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const { isInProgress, hasErrors } = props;

    if (isInProgress && !state.isInProgress) {
      return { isInProgress: true };
    }

    if (hasErrors && state.isInProgress) {
      return { isInProgress: false };
    }

    return null;
  }


  static propTypes = {
    // Explicit props
    activeProfile: PropTypes.shape({
      patientProfileId  : PropTypes.number,
      phiSetReferenceKey: PropTypes.string,
      storageProvider   : PropTypes.string,
    }),
    // Implicit props
    sharingRequests              : PropTypes.arrayOf(shapes.sharingRequest),
    beingApprovedSharingRequestId: PropTypes.number,
    openModalId                  : PropTypes.string,
    isInProgress                 : PropTypes.bool,
    hasErrors                    : PropTypes.bool,
    phiSet                       : PropTypes.object,
    // Implicit actions
    onApprove                    : PropTypes.func,
    onCloseModal                 : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      isInProgress: false,
    };
  }


  componentDidUpdate(prevProps) {
    if (this.props.openModalId !== constants.APPROVE_DATA_SHARING_MODAL) {
      return;
    }
    const { isInProgress, hasErrors } = this.props;
    if (isInProgress !== prevProps.isInProgress && !isInProgress && !hasErrors) {
      this.onClose();
    }
  }


  onApproveDataSharing(sharingRequest) {
    const { isInProgress, phiSet, activeProfile } = this.props;
    const { phiSetReferenceKey, encryptedExchangeToken } = activeProfile || {};
    if (isInProgress || !encryptedExchangeToken || !phiSetReferenceKey) {
      return;
    }
    this.props.onApprove(sharingRequest, activeProfile, phiSet);
  }


  async onClose() {
    await this.props.onCloseModal();
    this.setState({ isInProgress: false });
  }


  get sharingRequest() {
    return find(this.props.sharingRequests, { sharingRequestId: this.props.beingApprovedSharingRequestId });
  }


  renderActions(sharingRequest) {
    const { sharingStatus } = sharingRequest;
    const isApproved = sharingStatus === 'Approved';
    return (
      <div className="modal__actions row">
        <div className="col-6">
          <Button
            styleModifier="quaternary"
            labelMessage={isApproved ? messages.buttons.decline : messages.buttons.noDeclineInvitation}
            className="btn--block"
            isDisabled={this.state.isInProgress}
            onClick={this.props.onCloseModal}
          />
        </div>
        <div className="col-6">
          <Button
            styleModifier="primary"
            labelMessage={isApproved ? messages.buttons.accept : messages.buttons.yesShareData}
            className="btn--block btn--filled"
            // onClick={() => this.onConfirmDataSharing()}
            isInProgress={this.state.isInProgress}
            onClick={() => this.onApproveDataSharing(sharingRequest)}
          />
        </div>
      </div>
    );
  }


  renderAvatar(sharingRequest) {
    const avatar = sharingRequest.hcp ? sharingRequest.hcp.avatar : sharingRequest.clinic.logo;
    const avatarName = sharingRequest.hcp
      ? [sharingRequest.hcp.firstName, sharingRequest.hcp.lastName]
      : sharingRequest.clinic.name;
    return (
      <Avatar
        avatarImg={avatar}
        name={avatarName}
        className={styles.item__avatar}
        imgClassName={styles.item__avatar__img}
        initialsClassName={styles.item__avatar__initials}
      />
    );
  }


  renderPerson(sharingRequest) {
    const hcpName = sharingRequest.hcp
      ? `${sharingRequest.hcp.firstName} ${sharingRequest.hcp.lastName}`
      : sharingRequest.clinic.name;
    const clinicName = sharingRequest.hcp
      ? sharingRequest.clinic.name
      : <FormattedMessage {...messages.labels.unassigned} />;

    return (
      <div className={cn('row align-items-center', styles.person)}>
        <div className="col-auto pr-0">{ this.renderAvatar(sharingRequest) }</div>
        <div className="col">
          <p className={styles.person__name}>{ hcpName }</p>
          <p className="text--small text--light">{ clinicName }</p>
        </div>
      </div>
    );
  }


  render() {
    if (!this.props.activeProfile || !this.props.activeProfile.phiSetReferenceKey) {
      return null;
    }

    const { sharingRequest } = this;
    if (!sharingRequest) {
      return null;
    }

    const { sharingStatus } = sharingRequest;
    const isApproved = sharingStatus === 'Approved';
    const headerMessage = isApproved ? messages.headers.reauthorizeDataSharing : messages.headers.confirmDataSharing;

    return (
      <Modal
        modalId={constants.APPROVE_DATA_SHARING_MODAL}
        openModalId={this.props.openModalId}
        headerMessage={headerMessage}
        styleModifier="md"
        isInProgress={this.state.isInProgress}
        onClose={this.props.onCloseModal}
      >
        <div className={styles.container}>
          { !isApproved && <FormattedMessage {...messages.infos.areYouSureToShareData} /> }
          { this.renderPerson(sharingRequest) }
        </div>
        { this.renderActions(sharingRequest) }
      </Modal>
    );
  }

}


const mapStateToProps = (state) => {
  const sharingRequestId = selectors.beingApprovedSharingRequestId(state);
  return {
    sharingRequests              : selectors.sharingRequests(state),
    phiSet                       : selectors.phiSet(state),
    beingApprovedSharingRequestId: sharingRequestId,
    isInProgress                 : selectors.isApproveSharingRequestInProgressSelector(sharingRequestId)(state),
    hasErrors                    : selectors.hasApproveSharingRequestErrorsSelector(sharingRequestId)(state),
    openModalId                  : App.selectors.modal(state),
  };
};


const mapDispatchToProps = (dispatch) => ({
  onApprove: (sharingRequest, patientProfile, phiSet) => dispatch(
    actions.approveSharingRequest(sharingRequest, patientProfile, phiSet),
  ),
  onCloseModal: () => dispatch(App.actions.closeModal()),
});


const ConnectedConfirmDataSharingModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmDataSharingModal);


export default withStyles(styles)(ConnectedConfirmDataSharingModal);
