import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import AmbulatoryGlucoseProfile from 'modules/AmbulatoryGlucoseProfile';
import messages from 'modules/AmbulatoryGlucoseProfile/messages';
import SectionTitle from '../components/SectionTitle';
import styles from './StatisticsSection.pcss';


class StatisticsSection extends React.Component {

  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    statistics: PropTypes.shape({
      veryHigh: PropTypes.number,
      high    : PropTypes.number,
      target  : PropTypes.number,
      low     : PropTypes.number,
      veryLow : PropTypes.number,
    }),
    standards: PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
  };


  render() {
    const { conversion, standards } = this.props;
    return (
      <div className={styles.statisticsSectionWrapper}>
        <SectionTitle>
          <FormattedMessage {...messages.headers.glucoseStatisticAndTarget} />
        </SectionTitle>
        <AmbulatoryGlucoseProfile.components.Statistics
          overwriteClassName={styles.statisticsSection}
          standards={standards}
          conversion={conversion}
        />
      </div>
    );
  }

}

export default withStyles(styles)(StatisticsSection);
