import React from 'react';
import PropTypes from 'prop-types';
import { SingleDatePicker } from 'react-dates';
import TimePicker from 'react-time-picker';
import moment from 'moment/moment';
import withStyles from 'isomorphic-style-loader/withStyles';
import cn from 'classnames';
import get from 'lodash/get';
import FormGroup from 'components/Form/FormGroup';
import CalendarIcon from 'svg/calendar.svg';
import messages from '../../messages';
import * as styles from './DateTimePicker.pcss';


class DateTimePicker extends React.PureComponent {

  static propTypes = {
    // Explicit props
    locale         : PropTypes.string,
    formValues     : PropTypes.object,
    isDisabled     : PropTypes.bool,
    // Explicit actions
    onSetFormValue : PropTypes.func,
    onSetFormValues: PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      datePickerInputFocused: null,
    };
  }


  componentDidMount() {
    this.onPopulate();
  }


  onPopulate() {
    this.props.onSetFormValues({
      dateTimestamp: +moment().startOf('day').format('X'),
      time         : moment().format('HH:mm:ss'),
    });
  }


  onDateChange(momentObj) {
    if (momentObj) {
      this.props.onSetFormValue({ id: 'dateTimestamp', value: +momentObj.startOf('day').format('X') });
    }
  }


  isDateOutsideRange(date) {
    const currentDate = moment().endOf('date');
    return date.isAfter(currentDate);
  }


  renderCalendarIcon() {
    return (
      <CalendarIcon
        className={cn('DateRangePickerInput_calendarIcon_svg DateRangePickerInput_calendarIcon_svg_1',
          {
            'DateRangePickerInput_calendarIcon_svg--inactive': this.props.isDisabled,
          })}
        focusable="false"
      />
    );
  }


  render() {
    const dateValue = get(this.props, 'formValues.values.dateTimestamp');
    const momentObj = (dateValue && moment.unix(dateValue)) || moment().startOf('day');

    return (
      <div className={styles.dateTimePicker}>
        <div className={styles.dateTimePicker__datePicker}>
          <FormGroup
            id="dateTimestamp"
            labelMessage={messages.labels.date}
            formValues={this.props.formValues}
          >
            <SingleDatePicker
              id="dateTimestamp"
              date={momentObj}
              focused={this.state.datePickerInputFocused}
              disabled={this.props.isDisabled}
              onDateChange={(value) => this.onDateChange(value)}
              onFocusChange={({ focused }) => this.setState({ datePickerInputFocused: focused })}
              customInputIcon={this.renderCalendarIcon()}
              numberOfMonths={1}
              inputIconPosition="after"
              hideKeyboardShortcutsPanel
              isOutsideRange={(value) => this.isDateOutsideRange(value)}
            />
          </FormGroup>
        </div>
        <div className={styles.dateTimePicker__timePicker}>
          <FormGroup
            id="time"
            labelMessage={messages.labels.time}
            formValues={this.props.formValues}
          >
            <TimePicker
              onChange={(value) => this.props.onSetFormValue({ id: 'time', value })}
              maxDetail="second"
              disabled={this.props.isDisabled}
              disableClock
              locale={this.props.locale}
              clearIcon={null}
              clockIcon={null}
            />
          </FormGroup>
        </div>
      </div>
    );
  }

}


export default withStyles(styles)(DateTimePicker);
