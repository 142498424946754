import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import forEach from 'lodash/forEach';
import indexOf from 'lodash/indexOf';
import map from 'lodash/map';
import find from 'lodash/find';
import last from 'lodash/last';
import omit from 'lodash/omit';
import filter from 'lodash/filter';
import { calculateRelatedValue } from 'helpers/relatedData';
import { isManualReading } from 'helpers/externalDataSources';
import { isAggregatedPostMeal } from 'libs/StatsCalculations';
import ReadingFlagIcon from 'components/ReadingFlagIcon';
import ActivityIcon from 'svg/activity.svg';
import MealIcon from 'svg/meal.svg';
import InjectionIcon from 'svg/injection.svg';
import AverageIcon from 'svg/average-blood.svg';
import messages from '../../../messages';
import styles from './DailyChart.pcss';


class DailyChart extends React.PureComponent {

  static getDerivedStateFromProps(props) {
    const { readings, relatedData } = props;

    const mergedRelatedDataWithReadings = readings.map((reading) => {
      const findRelatedData = relatedData && relatedData.find((item) => item.readingId === reading.externalId);
      if (findRelatedData) {
        reading.relatedData = findRelatedData;
      }
      return reading;
    });

    const translateMomentDate = (momentDate) => momentDate.utc().locale('en--account')
      .startOf('day')
      .format('X');

    const rowsIndex = [];
    const rows = {};

    forEach(mergedRelatedDataWithReadings, (reading) => {
      const rowMomentDate = moment.unix(reading.timestamp);
      const rowTimestamp = +translateMomentDate(rowMomentDate);
      if (indexOf(rowsIndex, rowTimestamp) < 0) {
        rowsIndex.push(rowTimestamp);
        rows[rowTimestamp] = [];
      }
      rows[rowTimestamp].push(reading);
    });

    return {
      rows,
    };
  }

  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    readings       : PropTypes.array,
    phiSet         : PropTypes.object, // @TODO: shape
    ticks          : PropTypes.array,
    customRanges   : PropTypes.array,
    hideEmptyRows  : PropTypes.bool,
    showDayDividers: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      rows: [],
    };
  }


  renderFlag(reading, isManual) {
    const { flags } = reading;
    return (
      <div
        className={cn(styles.reading__flagIconContainer, { [styles['reading__flagIconContainer--manual']]: isManual })}
      >
        <ReadingFlagIcon flag={flags} />
      </div>
    );
  }


  renderRelatedData(values, left, name, reading) {
    return (
      <div
        key={`related-data-${name}-${reading.timestamp}-${reading.deviceSerialNumberToken}`}
        style={{ left: `${left}%` }}
        className={styles.relatedData__valueWrapper}
      >
        {
          map(values, (value, index) => {
            if (!value) {
              return null;
            }
            return (
              <div key={`related-data-at-${index}`}>
                <div className={styles.relatedData__valueWrapperTimeIndicator} />
                { value }
              </div>
            );
          })
        }
      </div>
    );
  }


  renderReading(reading, left) {
    const { value } = reading;
    const { isPlainValuesMode } = this;
    const { conversion, standards } = this.props;
    const toDisplay = isPlainValuesMode ? value : this.props.conversion.toDisplay(value);
    const isManual = isManualReading(reading);
    const highConverted = isAggregatedPostMeal(reading.flags)
      ? conversion.toDisplay(standards.postMeal.highThreshold)
      : conversion.toDisplay(standards.preMeal.highThreshold);
    const lowConverted = isAggregatedPostMeal(reading.flags)
      ? conversion.toDisplay(standards.postMeal.lowThreshold)
      : conversion.toDisplay(standards.preMeal.lowThreshold);
    const isHigh = (!isPlainValuesMode && toDisplay > highConverted);
    const isLow = (!isPlainValuesMode && toDisplay < lowConverted);

    return (
      <div
        key={`reading-daily-${reading.timestamp}-${reading.deviceSerialNumberToken}`}
        style={{ left: `${left}%` }}
        className={cn(isManual ? styles.reading__valueManualWrapper : styles.reading__valueWrapper, {
          [styles['reading--high']]: isHigh,
          [styles['reading--low']] : isLow,
        })}
      >
        <div className={styles.relatedData__valueWrapperTimeIndicator} />
        { this.renderFlag(reading, isManual) }
        <div>{conversion.toDisplay(reading.value)}</div>
      </div>
    );
  }


  renderBreakPoints(timestamp) {
    if (!this.props.showDayDividers) {
      return null;
    }

    const start = timestamp;
    const end = moment.unix(timestamp).add(1, 'days').unix();
    const diff = end - start;
    const ticks = map(new Array(12), (
      (value, index) => moment.unix(timestamp).utc().locale('en--account')
        .set({ hour: index * 2, minute: 0 }).unix()
    ));
    ticks.push(end);
    return (
      <div className={styles.breakPointsWrapper}>
        {
          ticks.map((tick) => {
            const timeFromStart = tick - start;
            const percentageOnChart = ((timeFromStart / diff) * 100).toFixed(2);
            return (
              <div
                key={`tick-at-${tick}`}
                className={styles.breakPoint}
                style={{ left: `${percentageOnChart}%` }}
              >
                <div className={styles.timeLine} />
                <div className={styles.timeText}>
                  {moment.unix(tick).utc().locale('en--account').format('HH:mm')}
                </div>
              </div>
            );
          })
        }
      </div>
    );
  }


  renderSummary(row, timestamp, stackedReadingsRows) {
    const { conversion, hideEmptyRows } = this.props;
    let activityValues = 0;
    let foodValues = 0;
    let medicationFastValues = 0;
    let medicationLongValues = 0;
    let sumValue = 0;
    row.forEach((item) => {
      sumValue += conversion.toDisplay(item.value);
      if (item.relatedData) {
        const { activityValue, foodValue, medicationFastValue, medicationLongValue }
            = calculateRelatedValue(item.relatedData);
        activityValues += activityValue;
        foodValues += foodValue;
        medicationFastValues += medicationFastValue;
        medicationLongValues += medicationLongValue;
      }
    });
    const averageValue = (sumValue / row.length).toFixed(conversion.unit === 'MG_DL' ? 0 : 2);
    const hideFoodSummary = hideEmptyRows && foodValues === 0;
    const hideMedicationsSummary = hideEmptyRows && medicationLongValues === 0 && medicationFastValues === 0;
    const hideActivitiesSummary = hideEmptyRows && activityValues === 0;
    const emptyDivsToRender = stackedReadingsRows.length - 1;
    const dateFormat = moment.localeData().longDateFormat('L').replace(new RegExp('.?YYYY.?'), '');

    return (
      <div className={styles.summary}>
        <h1>{moment.unix(timestamp).format(`dddd, ${dateFormat}`)}</h1>
        <div className={styles.average}>
          <FormattedMessage {...messages.dailyReport.average} />
          {` ${averageValue} ${conversion.unitSymbol}`}
        </div>
        {
          emptyDivsToRender > 0 && map(new Array(emptyDivsToRender), () => (
            <div style={{ visibility: 'hidden', height: '20px' }} />
          ))
        }
        <div
          className={styles.relatedData__valueWrapper}
          style={{ display: hideFoodSummary ? 'none' : 'flex' }}
        >
          <div>
            {foodValues} <FormattedMessage {...messages.labels.foodUnit} />
          </div>
        </div>
        <div
          className={styles.relatedData__valueWrapper}
          style={{ display: hideMedicationsSummary ? 'none' : 'flex' }}
        >
          <div>
            {medicationFastValues} <FormattedMessage {...messages.labels.injectionUnit} />
          </div>
          <div>
            {medicationLongValues} <FormattedMessage {...messages.labels.injectionUnit} />
          </div>
        </div>
        <div
          className={styles.relatedData__valueWrapper}
          style={{ display: hideActivitiesSummary ? 'none' : 'flex' }}
        >
          <div>
            {activityValues} <FormattedMessage {...messages.labels.activityUnit} />
          </div>
        </div>
      </div>
    );
  }


  renderRelatedDataRowIcon(type) {
    let icon;

    switch (type) {
      case 'foods':
        icon = <MealIcon />;
        break;
      case 'medications':
        icon = <InjectionIcon />;
        break;
      case 'activities':
        icon = <ActivityIcon />;
        break;
      default:
        icon = null;
    }

    return <div className={styles.row__icon}>{ icon }</div>;
  }


  getRelatedDataRowValue(row, type) {
    const {
      activityValue,
      foodValue,
      medicationFastValue,
      medicationLongValue,
    } = calculateRelatedValue(row.relatedData);

    let result;
    switch (type) {
      case 'foods':
        result = [foodValue];
        break;
      case 'medications':
        result = [medicationFastValue, medicationLongValue];
        break;
      case 'activities':
        result = [activityValue];
        break;
      default:
        result = null;
    }

    return filter(result, (iterator) => !!iterator);
  }


  renderRelatedDataRow(row, timestamp, type) {
    const { hideEmptyRows } = this.props;
    let activityValues = 0;
    let foodValues = 0;
    let medicationFastValues = 0;
    let medicationLongValues = 0;
    row.forEach((item) => {
      if (item.relatedData) {
        const { activityValue, foodValue, medicationFastValue, medicationLongValue }
          = calculateRelatedValue(item.relatedData);
        activityValues += activityValue;
        foodValues += foodValue;
        medicationFastValues += medicationFastValue;
        medicationLongValues += medicationLongValue;
      }
    });

    const hideFood = hideEmptyRows && foodValues === 0;
    const hideMedications = hideEmptyRows && medicationLongValues === 0 && medicationFastValues === 0;
    const hideActivities = hideEmptyRows && activityValues === 0;

    if (hideEmptyRows && hideFood && type === 'foods') {
      return null;
    }
    if (hideEmptyRows && hideMedications && type === 'medications') {
      return null;
    }
    if (hideEmptyRows && hideActivities && type === 'activities') {
      return null;
    }


    const start = timestamp;
    const end = moment.unix(timestamp).add(1, 'days').unix();
    const diff = end - start;

    return (
      <div className={styles.row__wrapper}>
        { this.renderRelatedDataRowIcon(type) }
        <div className={styles.row}>
          {
            map(row, (data) => {
              const timeFromStart = data.timestamp - start;
              const percentageOnChart = ((timeFromStart / diff) * 100).toFixed(2);
              if (data.relatedData) {
                const relatedDataValue = this.getRelatedDataRowValue(data, type);
                if (!relatedDataValue) {
                  return null;
                }
                return this.renderRelatedData(relatedDataValue, percentageOnChart, type, data);
              }
              return null;
            })
          }
        </div>
      </div>
    );
  }


  renderDay(row, timestamp) {
    const { hideEmptyRows } = this.props;
    if (hideEmptyRows && !row.length) {
      return null;
    }

    const start = timestamp;
    const end = moment.unix(timestamp).add(1, 'days').unix();
    const diff = end - start;

    const secondsInDay = 86400;
    const readingsPercentageWidth = 65 / 702;
    const stackedReadingsRows = [];

    forEach(row, (rowData) => {
      const rawReadingsRow = omit(rowData, 'relatedData');
      if (!Array.isArray(stackedReadingsRows[0])) {
        stackedReadingsRows.push([rawReadingsRow]);
        return;
      }

      const possibleStack = find(stackedReadingsRows, (stackedRowsArray) => (
        (rawReadingsRow.timestamp - last(stackedRowsArray).timestamp) / secondsInDay) > readingsPercentageWidth
      );
      if (possibleStack) {
        possibleStack.push(rawReadingsRow);
        return;
      }

      stackedReadingsRows.push([rawReadingsRow]);
    });

    return (
      <div key={`day-${timestamp}`} className={styles.day}>
        <div className={styles.day__container}>
          <div className={styles.day__wrapper}>
            { this.renderBreakPoints(timestamp) }
            {
              map(stackedReadingsRows, (stackedRowData, idx) => (
                <div key={idx} className={styles.row__wrapper}>
                  <div className={styles.row__icon}><AverageIcon /></div>
                  <div className={styles.row}>
                    {
                      map(stackedRowData, (data) => {
                        const timeFromStart = data.timestamp - start;
                        const percentageOnChart = ((timeFromStart / diff) * 100).toFixed(2);
                        return this.renderReading(data, percentageOnChart);
                      })
                    }
                  </div>
                </div>
              ))
            }
            { this.renderRelatedDataRow(row, timestamp, 'foods') }
            { this.renderRelatedDataRow(row, timestamp, 'medications') }
            { this.renderRelatedDataRow(row, timestamp, 'activities') }
          </div>
        </div>
        { this.renderSummary(row, timestamp, stackedReadingsRows) }
      </div>
    );
  }

  render() {
    const { rows } = this.state;
    return (
      <div className={cn(styles.wrapper, { 'pt-4': this.props.showDayDividers })}>
        { map(rows, (row, timestamp) => this.renderDay(row, timestamp)) }
      </div>
    );
  }

}

export default withStyles(styles)(DailyChart);
