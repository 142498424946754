import { call, spawn } from 'redux-saga/effects';
import ApiService from 'services/ApiService';
import { NOTIFICATIONS_TRIGGERS } from 'modules/Notifications/constants';


function* sendNotification(notificationType, notificationPayload) {
  const requestUrl = '/api/Notification';
  yield call(ApiService.regionalRequest, requestUrl, {
    method: 'POST',
    body  : { notificationType, payload: notificationPayload },
  });
}


function* sendClinicPatientProfileCreatedNotification({
  clinicId,
  clinicName,
  patientFirstName,
  patientLastName,
  encryptedClinicPatientProfileId,
} = {}) {
  yield spawn(sendNotification, NOTIFICATIONS_TRIGGERS.CLINIC_PATIENT_PROFILE_CREATED, {
    clinicId,
    clinicName,
    patientFirstName,
    patientLastName,
    encryptedClinicPatientProfileId,
  });
}


function* sendClinicPatientNewReadingsAddedNotification({
  clinicId,
  clinicName,
  patientFirstName,
  patientLastName,
  encryptedClinicPatientProfileId,
  readingsCount,
} = {}) {
  // @TODO: ClinicPatientProfileNewReadingsAdded
  yield spawn(sendNotification, NOTIFICATIONS_TRIGGERS.CLINIC_PATIENT_PROFILE_NEW_DATA_SYNCHRONIZED, {
    clinicId,
    clinicName,
    patientFirstName,
    patientLastName,
    encryptedClinicPatientProfileId,
    readingsCount,
  });
}


function* sendInvalidClinicCloudDriveAccessNotification({ clinicId, clinicName } = {}) {
  yield spawn(sendNotification, NOTIFICATIONS_TRIGGERS.INVALID_CLINIC_CLOUD_DRIVE_ACCESS, {
    clinicId,
    clinicName,
  });
}


function* sendSharingRequestAccessRevokedNotification({ sharingRequestId } = {}) {
  yield spawn(sendNotification, NOTIFICATIONS_TRIGGERS.SHARING_REQUEST_ACCESS_REVOKED, {
    sharingRequestId,
  });
}


function* sendSharingRequestExternalDataSourceAccessRevokedNotification({
  externalDataSourceId, sharingRequestId,
} = {}) {
  yield spawn(sendNotification, NOTIFICATIONS_TRIGGERS.SHARING_REQUEST_EXTERNAL_DATA_SOURCE_ACCESS_REVOKED, {
    externalDataSourceId,
    sharingRequestId,
  });
}


function* sendNoteWrittenByHcpNotification({ clinicName, sharingRequestId } = {}) {
  yield spawn(sendNotification, NOTIFICATIONS_TRIGGERS.NOTE_WRITTEN_BY_HCP, {
    clinicName,
    sharingRequestId,
  });
}


function* sendNoteWrittenByPwdNotification({
  clinicId,
  clinicName,
  patientFirstName,
  patientLastName,
  encryptedClinicPatientProfileId,
  sharingRequestId,
  hcpProfileId,
} = {}) {
  yield spawn(sendNotification, NOTIFICATIONS_TRIGGERS.NOTE_WRITTEN_BY_PWD, {
    clinicId,
    clinicName,
    patientFirstName,
    patientLastName,
    encryptedClinicPatientProfileId,
    sharingRequestId,
    hcpProfileId,
  });
}


export default {
  sendClinicPatientProfileCreatedNotification,
  sendClinicPatientNewReadingsAddedNotification,
  sendInvalidClinicCloudDriveAccessNotification,
  sendSharingRequestAccessRevokedNotification,
  sendSharingRequestExternalDataSourceAccessRevokedNotification,
  sendNoteWrittenByHcpNotification,
  sendNoteWrittenByPwdNotification,
};
