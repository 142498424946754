import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import get from 'lodash/get';
import moment from 'moment';
import { motion, AnimatePresence } from 'framer-motion';
import Link from 'components/Link';
import Button from 'components/Form/Button';
import Sync from 'svg/sync.svg';
import CheckNegative from 'svg/check-negative.svg';
import XNegativeIcon from 'svg/x-negative.svg';
import Dots from 'svg/dots.svg';
import App from 'modules/App';
import Patient from 'modules/Patient';
import Account from 'modules/Account';
import Information from 'modules/Information';
import Visit from 'modules/Visit';
import * as constants from '../../constants';
import messages from '../../messages';
import styles from '../Results/Results.pcss';


class MyHeader extends React.PureComponent {

    static propTypes = {
      // Explicit actions
      onSync          : PropTypes.func,
      // Implicit props
      information     : PropTypes.object,
      patientProfile  : PropTypes.object,
      phiSet          : PropTypes.object, // @TODO: shape
      phiSetDocumentId: PropTypes.string,
      isSyncInProgress: PropTypes.bool,
      hasSyncErrors   : PropTypes.bool,
      openDropdownId  : PropTypes.string,
      // Implicit actions
      onCloseDropdown : PropTypes.func,
      onOpenDropdown  : PropTypes.func,
      onOpenModal     : PropTypes.func,
    };


    async onOpenMenu(evt) {
      evt.preventDefault();
      const isOpenMenu = this.props.openDropdownId === constants.PROFILE_DROPDOWN;
      await this.props.onCloseDropdown();
      if (!isOpenMenu) {
        this.props.onOpenDropdown(constants.PROFILE_DROPDOWN);
      }
    }


    /**
   * Sync statuses:
   * 0 - unsynchronized
   * 1 - synchronizing in progress
   * 2 - sync success
   * 3 - sync error
   */
    get syncStatus() {
      if (this.props.isSyncInProgress) return 1;
      if (this.props.hasSyncErrors) return 3;
      return 2;
    }


    get ageValue() {
      const dateOfBirth = get(this.props.information, 'dateOfBirth');
      if (!dateOfBirth) return null;
      const patientYears = moment().diff(dateOfBirth, 'years');
      return (
        <>
          {`${patientYears} `}
          <FormattedMessage {...messages.labels.yearsOld} />
        </>
      );
    }


    get diabetesTypeValue() {
      const { phiSet } = this.props;
      const diabetesType = get(phiSet, 'diabetesType');
      if (!diabetesType) return null;
      return <FormattedMessage {...App.messages.diabetesTypes[diabetesType]} />;
    }


    get treatmentTypeValue() {
      const { phiSet } = this.props;
      const treatmentType = get(phiSet, 'treatmentType');
      if (!treatmentType) return null;
      return <FormattedMessage {...App.messages.treatmentTypes[treatmentType]} />;
    }


    renderMenu() {
      if (this.props.openDropdownId !== constants.PROFILE_DROPDOWN) {
        return null;
      }

      return (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: 'auto' }}
          exit={{ height: 0 }}
          transition={{ ease: 'easeOut', duration: 0.15 }}
          className="dropdownMenu__container"
        >
          <ul className="dropdownMenu__actions">
            <li className="dropdownMenu__action">
              <Button
                styleModifier="transparent"
                type="button"
                onClick={() => this.props.onOpenModal(constants.MEASUREMENTS_MODAL)}
                labelMessage={messages.buttons.editPatient}
              />
            </li>
            <li className="dropdownMenu__action">
              <Button
                styleModifier="transparent"
                type="button"
                onClick={() => this.props.onOpenModal(Visit.constants.VISIT_HISTORY_MODAL)}
                labelMessage={messages.buttons.visitHistory}
              />
            </li>
            <li className="dropdownMenu__action">
              <Button
                styleModifier="transparent"
                type="button"
                onClick={() => this.props.onSync()}
                labelMessage={messages.buttons.refresh}
              />
            </li>
          </ul>
        </motion.div>
      );
    }


    renderSync() {
      switch (this.syncStatus) {
        case 1: {
          return (
            <span className={styles.sync}>
              <Sync className={`${styles.sync__icon} rotating`} />
              <FormattedMessage {...messages.labels.synchronizing} />
            </span>
          );
        }
        case 2: {
          return (
            <span className={cn(styles.sync, styles['sync--synchronized'])}>
              <CheckNegative className={`${styles.sync__icon} ${styles['sync__icon--check']}`} />
              <FormattedMessage {...messages.labels.synchronized} />
            </span>
          );
        }
        case 3: {
          return (
            <span className={cn(styles.sync, styles['sync--unsynchronized'])}>
              <XNegativeIcon className={`${styles.sync__icon} ${styles['sync__icon--check']}`} />
              <FormattedMessage {...messages.labels.unsynchronized} />
            </span>
          );
        }
        default: {
          return null;
        }
      }
    }


    renderPatientInformation(label, value, withoutMargin) {
      return (
        <>
          <p className={styles.patientAdditionalInfoLabel}>
            <FormattedMessage {...label} />:
          </p>
          <p className={cn(styles.patientAdditionalInfoValue, {
            [styles['patientAdditionalInfoValue--noMargin']]: withoutMargin,
          })}
          >
            {value || '-'}
          </p>
        </>
      );
    }


    renderAdditionalPatientInformation() {
      return (
        <div className={cn(styles.patientName__wrapper, styles.additionalPatientInfo)}>
          {
            this.renderPatientInformation(
              messages.labels.age,
              this.ageValue,
            )
          }
          {
            this.renderPatientInformation(
              messages.labels.diabetesType,
              this.diabetesTypeValue,
            )
          }
          {
            this.renderPatientInformation(
              messages.labels.treatmentType,
              this.treatmentTypeValue,
              true,
            )
          }
        </div>
      );
    }


    renderPatientHeader() {
      return (
        <div className="col">
          <div className={cn('row align-items-center', styles.patientWrapper)}>
            <AnimatePresence>
              {this.renderMenu()}
            </AnimatePresence>
            <div className={cn(styles.profileSettings, 'col-auto')}>
              <Link to="" onClick={(evt) => this.onOpenMenu(evt)}>
                <Dots />
              </Link>
            </div>
            <div className="col">
              <div>
                <div className={cn(styles.patientName__wrapper)}>
                  <p className={cn(styles.patient__name)}>
                    <FormattedMessage {...messages.headers.myResults} />
                  </p>
                  {this.renderSync()}
                </div>
              </div>
              <div className="row">
                <div className="d-flex justify-content-center align-items-center">
                  {this.renderAdditionalPatientInformation()}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }


    render() {
      return (
        <div className="row align-items-center">
          {this.renderPatientHeader()}
        </div>
      );
    }

}


const mapStateToProps = (state) => ({
  patientProfile  : Account.selectors.patientProfile(state),
  information     : Information.selectors.information(state),
  phiSet          : Patient.selectors.phiSet(state),
  phiSetDocumentId: Patient.selectors.phiSetDocumentId(state),
  isSyncInProgress: Patient.selectors.isSyncInProgress(state),
  hasSyncErrors   : Patient.selectors.hasSyncErrors(state),
  openDropdownId  : App.selectors.dropdown(state),
});


const mapDispatchToProps = (dispatch) => ({
  onOpenModal    : (modalId) => dispatch(App.actions.openModal(modalId)),
  onOpenDropdown : (dropdownId) => dispatch(App.actions.openDropdown(dropdownId)),
  onCloseDropdown: () => dispatch(App.actions.closeDropdown()),
});


const ConnectedMyHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyHeader);


export default ConnectedMyHeader;
