import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import { highlightedHourlyRecordsShape } from 'shapes/bins';
import App from 'modules/App';
import AmbulatoryGlucoseProfile from 'modules/AmbulatoryGlucoseProfile';
import messages from 'modules/AmbulatoryGlucoseProfile/messages';
import BloodGlucoseProfileReportChart from 'components/Charts/BloodGlucoseProfileChart/BloodGlucoseProfileReportChart';
import SectionTitle from '../components/SectionTitle';
import styles from './AgpSection.pcss';


class AgpSection extends React.Component {

  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    statistics: PropTypes.shape({
      veryHigh: PropTypes.number,
      high    : PropTypes.number,
      target  : PropTypes.number,
      low     : PropTypes.number,
      veryLow : PropTypes.number,
    }),
    standards: PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    highlightedHourlyRecords: highlightedHourlyRecordsShape,
    direction               : PropTypes.string,
  };


  render() {
    const { conversion, standards, highlightedHourlyRecords, direction } = this.props;
    return (
      <div className={styles.agpSectionWrapper}>
        <SectionTitle>
          <FormattedMessage {...messages.headers.ambulatoryGlucoseProfile} />
        </SectionTitle>
        <h2 className={styles.agpChartInfo}>
          <FormattedMessage {...messages.labels.agpChartInfo} />
        </h2>
        <BloodGlucoseProfileReportChart
          conversion={conversion}
          standards={standards}
          highlightedHourlyRecords={highlightedHourlyRecords}
          direction={direction}
        />
      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  highlightedHourlyRecords: AmbulatoryGlucoseProfile.selectors.highlightedHourlyRecords(state),
  direction               : App.selectors.direction(state),
});


const ConnectedAgpSection = connect(
  mapStateToProps,
)(AgpSection);

export default withStyles(styles)(ConnectedAgpSection);
