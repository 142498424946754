import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { formatTimestamp } from 'helpers/datetime';
import noteShape from 'shapes/noteShape';
import ReplyBtn from './ReplyBtn';
import ReplyNoteForm from './ReplyNoteForm';
import styles from './VisitNotes.pcss';


class ReplyNote extends React.PureComponent {

  static propTypes = {
    // Explicit props
    mode      : PropTypes.oneOf(['HCP', 'PWD']).isRequired,
    note      : noteShape,
    replyNotes: PropTypes.arrayOf(noteShape).isRequired,
    replyingTo: noteShape,
    isLast    : PropTypes.bool,
    // Explicit actions
    onCancel  : PropTypes.func,
    onChange  : PropTypes.func,
    onReply   : PropTypes.func,
    onSubmit  : PropTypes.func,
  };


  renderReplyNoteForm(note) {
    if (note !== this.props.replyingTo) {
      return null;
    }
    return (
      <ReplyNoteForm
        note={note}
        onCancel={this.props.onCancel}
        onChange={this.props.onChange}
        onSubmit={this.props.onSubmit}
      />
    );
  }


  renderReplyFooter(note, canReply, isSent) {
    const { timestamp } = note;
    const date = formatTimestamp(timestamp, 'L, LT');
    const replyBtn = canReply
      && (
        <ReplyBtn
          note={note}
          onReply={this.props.onReply}
        />
      );
    return (
      <div className="mt-3 text--small">
        {isSent && replyBtn}
        <span className={`d-inline-block ${isSent ? 'ml' : 'mr'}-5`}>{date}</span>
        {!isSent && replyBtn}
      </div>
    );
  }


  renderReplies(replies) {
    const repliesLast = replies.length - 1;
    return map(replies, (replyNote, idx) => {
      const replyNoteProps = { ...this.props, note: replyNote, isLast: idx === repliesLast };
      return <ReplyNote key={replyNote.noteId} {...replyNoteProps} />;
    });
  }


  render() {
    const { mode, note, replyNotes, replyingTo, isLast } = this.props;
    if (!note) {
      return null;
    }
    const replies = filter(replyNotes, (rn) => rn.payload && rn.payload.replyTo === note.noteId);
    const canReply = isLast && !replies.length && note !== replyingTo;
    const isSent = note.payload
      && (
        (mode === 'PWD' && note.payload.byPwd)
        || (mode === 'HCP' && !note.payload.byPwd)
      );
    return (
      <>
        <div className={cn('d-flex w-100 my-5', { 'justify-content-end text--right': isSent })}>
          <div className="w-75">
            <div
              className={cn('pseudoEditorWrapper', styles.replyNoteWrapper, {
                [styles['replyNoteWrapper--primary']]: isSent,
              })}
            >
              <div className={`pseudoEditor ${styles.note}`}>
                {note.content}
              </div>
            </div>
            { this.renderReplyFooter(note, canReply, isSent) }
          </div>
        </div>
        { this.renderReplyNoteForm(note) }
        { this.renderReplies(replies) }
      </>
    );
  }

}


export default ReplyNote;
