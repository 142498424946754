import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import map from 'lodash/map';
import intlShape from 'shapes/intlShape';


class NavTabs extends React.PureComponent {

  static propTypes = {
    // Explicit props
    items: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.object.isRequired,
      value: PropTypes.string.isRequired,
    })),
    activeItemValue: PropTypes.string,
    onClick        : PropTypes.func.isRequired,
    // Implicit props
    intl           : intlShape,
  };


  onClick(evt, value) {
    evt.preventDefault();
    this.props.onClick(value);
  }


  renderItem(item) {
    const { label, value } = item;
    const { activeItemValue } = this.props;
    return (
      <li key={value} className="nav-item">
        <a
          className={cn('nav-link', { active: value === activeItemValue })}
          href=""
          onClick={(evt) => this.onClick(evt, value)}
        >
          { this.props.intl.formatMessage(label) }
        </a>
      </li>
    );
  }


  render() {
    return (
      <ul className="nav nav-tabs">
        { map(this.props.items, (item) => this.renderItem(item)) }
      </ul>
    );
  }

}


export default injectIntl(NavTabs);
