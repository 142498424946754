import find from 'lodash/find';
import get from 'lodash/get';
import partition from 'lodash/partition';
import { all, call, put, takeLatest, getContext, select } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import ApiService from 'services/ApiService';
import CountryLocalizationService from 'services/CountryLocalizationService';
import App from 'modules/App';
import * as actionTypes from './actionTypes';
import * as actions from './actions';


function* getInformation() {
  const requestUrl = '/api/Information/Account';
  return yield call(ApiService.regionalRequest, requestUrl);
}


function* getConsentsTemplate(countryCode) {
  const requestUrl = `/api/Localization/legalConsents?countryCode=${countryCode}`;
  return yield call(ApiService.originalRequest, requestUrl);
}


function* getGlobalConsentsTemplate(countryCode) {
  const apiUrl = yield getContext('apiUrl');
  const region = yield select(App.selectors.originalRegionName);
  const requestUrl = `${apiUrl}/aid/api/${region}/Localization/legalConsents?countryCode=${countryCode}`;
  return yield call(ApiService.plainRequest, requestUrl);
}


function* checkInformation() {
  try {
    const cookies = yield getContext('cookies');
    const token = cookies.get('token');
    const tokenData = jwtDecode(token);

    const scopeName = get(tokenData, 'identity_scope');
    const countryCode = get(tokenData, 'identity_country_code');

    const [information, informationTemplate, consentsTemplate, globalConsentsTemplate] = yield all([
      call(getInformation),
      call(CountryLocalizationService.fetchInformationTemplate, countryCode, scopeName),
      call(getConsentsTemplate, countryCode),
      call(getGlobalConsentsTemplate, countryCode),
    ]);
    const [mandatoryConsents, optionalConsents] = partition(globalConsentsTemplate, { isMandatory: true });

    let isNeedToFillUp = false;

    for (let i = 0; i < mandatoryConsents.length; i++) {
      const { legalConsentId } = mandatoryConsents[i];
      const approval = find(information.legalConsentApprovals, { legalConsentId });
      if (!approval || !approval.decision) {
        isNeedToFillUp = true;
        break;
      }
    }

    if (!isNeedToFillUp) {
      for (let i = 0; i < optionalConsents.length; i++) {
        const { legalConsentId } = optionalConsents[i];
        const approval = find(information.legalConsentApprovals, { legalConsentId });
        if (!approval) {
          isNeedToFillUp = true;
          break;
        }
      }
    }

    if (!isNeedToFillUp) {
      for (let i = 0; i < informationTemplate.length; i++) {
        const ft = informationTemplate[i];
        const value = get(information, ft.name);
        if (!value && ft.isMandatory) {
          isNeedToFillUp = true;
          break;
        }
      }
    }

    if (isNeedToFillUp) {
      yield put(App.actions.goToAid('set-information'));
      return;
    }

    yield put(actions.checkInformationSuccess(information, informationTemplate, consentsTemplate));
  } catch (err) {
    yield put(actions.checkInformationError(err));
    yield call(App.dispatchError, err);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* updateInformation({ payload }) {
  try {
    const { informationUpdate } = payload;
    const requestUrl = '/api/Information/Account';
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'PUT',
      body  : { ...informationUpdate },
    });
    yield put(actions.updateInformationSuccess(informationUpdate));
  } catch (err) {
    yield put(actions.updateInformationError(err));
    yield call(App.dispatchError, err);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* getOrganizationMembership() {
  const requestUrl = '/api/Information/OrganizationMembership';
  return yield call(ApiService.regionalRequest, requestUrl);
}


function* checkOrganizationMembership() {
  try {
    const organizationMemberships = yield call(getOrganizationMembership);
    if (organizationMemberships.length === 0) {
      yield put(App.actions.goToAid('organization-memberships'));
      return;
    }
    yield put(actions.checkOrganizationMembershipsSuccess(organizationMemberships));
  } catch (err) {
    yield put(actions.checkOrganizationMembershipsError(err));
    yield call(App.dispatchError, err);
  }
}


//----------------------------------------------------------------------------------------------------------------------


function* sagas() {
  yield takeLatest(actionTypes.CHECK_INFORMATION, checkInformation);
  yield takeLatest(actionTypes.UPDATE_INFORMATION, updateInformation);
  yield takeLatest(actionTypes.CHECK_ORGANIZATION_MEMBERSHIPS, checkOrganizationMembership);
}


export default [
  sagas,
];
