import PropTypes from 'prop-types';


export default PropTypes.shape({
  customIdentifierId: PropTypes.number.isRequired,
  instructionsKey   : PropTypes.string,
  isMandatory       : PropTypes.bool,
  mask              : PropTypes.string,
  nameKey           : PropTypes.string.isRequired,
  validationRegEx   : PropTypes.string,
});
