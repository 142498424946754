import concat from 'lodash/concat';
import difference from 'lodash/difference';
import differenceBy from 'lodash/differenceBy';
import endsWith from 'lodash/endsWith';
import forOwn from 'lodash/forOwn';
import filter from 'lodash/filter';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import flatten from 'lodash/flatten';
import forEach from 'lodash/forEach';
import forIn from 'lodash/forIn';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import keysIn from 'lodash/keysIn';
import last from 'lodash/last';
import map from 'lodash/map';
import set from 'lodash/set';
import unset from 'lodash/unset';
import startsWith from 'lodash/startsWith';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import reduce from 'lodash/reduce';
import sortedUniq from 'lodash/sortedUniq';
import takeRight from 'lodash/takeRight';
import union from 'lodash/union';
import unionBy from 'lodash/unionBy';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import camelCase from 'lodash/camelCase';
import { diff } from 'jsondiffpatch';
import { buffers } from 'redux-saga';
import { all, call, put, race, take, takeLatest, getContext, takeEvery, actionChannel } from 'redux-saga/effects';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { getNowAsUtc } from 'helpers/datetime';
import { isManualReading } from 'helpers/externalDataSources';
import request from 'helpers/request';
import fetchImage from 'helpers/fetchImage';
import appInsights from 'helpers/appInsights';
import { decrypt, encrypt, getKeyFromPem } from 'helpers/crypto';
import { LocalError } from 'helpers/errorTypes';
import { fetchDocumentId, openTransaction, commitTransaction, closeTransaction } from 'services/MdtcService';
import CloudDriveService from 'services/CloudDriveService';
import CentralStorageService from 'services/CentralStorageService';
import StorageExchangeTokenService from 'services/StorageExchangeTokenService';
import ApiService from 'services/ApiService';
import NotificationsService from 'services/NotificationsService';
import App from 'modules/App';
import Statistics from 'modules/Statistics';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as constants from './constants';
import messages from './messages';


function* authorizeCentralStorage(type) {
  if (type === 'pwd' || type === 'clinic') {
    const requestUrl = '/api/Storage/account';
    const accountType = (type === 'pwd' ? 'PWD' : 'HCP');
    const { authorizationCode } = yield call(ApiService.regionalRequest, requestUrl, {
      method: 'POST',
      body  : {
        accountType,
      },
    });
    yield put(actions.authorizeSuccess(authorizationCode, 'CentralStorage'));
    return;
  }

  const getUrl = yield getContext('getUrl');
  const redirect = yield getContext('redirect');
  let url;

  if (
    startsWith(type, 'pwdReAuth')
      || startsWith(type, 'clinicReAuth')
      || startsWith(type, 'approveSharingRequest')
      || startsWith(type, 'approveFamilyLink')
  ) {
    url = getUrl('central-storage-auth', { type });
  }

  if (!url) {
    throw new LocalError({ code: 'InvalidCentralStorageAuth' });
  }

  redirect(url);
}


function* authorizeGoogleDrive(type) {
  const apps = yield getContext('apps');
  const { google } = apps;
  const domain = yield getContext('domain');
  const getUrl = yield getContext('getUrl');
  const externalRedirect = yield getContext('externalRedirect');
  const redirectUri = `${domain}${getUrl('google-drive')}`;
  const url = 'https://accounts.google.com/o/oauth2/v2/auth'
    + '?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.appdata'
    + ' https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.file'
    + ' https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fplus.me'
    + '&access_type=offline'
    + '&include_granted_scopes=true'
    + `&state=type%3D${type}`
    + '&prompt=consent'
    + `&redirect_uri=${redirectUri}`
    + '&response_type=code'
    + `&client_id=${google}`;

  externalRedirect(url);
}


function* authorize({ payload }) {
  try {
    const { provider, type } = payload;

    const encodedType = encodeURI(type);

    switch (provider) {

      case 'CentralStorage': {
        yield call(authorizeCentralStorage, type);
        return;
      }

      case 'GoogleDrive': {
        yield call(authorizeGoogleDrive, encodedType);
        return;
      }

      default: {
        const err = new LocalError({ code: 'UnknownStorageProvider' });
        yield put(actions.authorizeError(err));
        yield call(App.dispatchError, err, messages);
      }

    }

  } catch (err) {
    yield put(actions.authorizeError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* authorizeCheck({ payload }) {
  try {
    const { code, storageProvider, scope } = payload;
    const requiredScopes = get(constants.REQUIRED_SCOPES, storageProvider, []);
    const scopes = scope.split(' ');
    let hasRequiredScopes = true;
    for (let i = 0; i < requiredScopes.length; i++) {
      if (scopes.findIndex((s) => s === requiredScopes[i]) < 0) {
        hasRequiredScopes = false;
        break;
      }
    }
    if (!hasRequiredScopes) {
      const err = new LocalError({ code: 'NoRequiredScopes' });
      yield put(actions.authorizeError(err));
      yield call(App.dispatchError, err, messages);
      return;
    }
    yield put(actions.authorizeSuccess(code, storageProvider));
  } catch (err) {
    yield put(actions.authorizeError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* getProfile({ payload }) {
  try {
    const { accessToken, provider } = payload;
    const fetch = yield getContext('fetch');
    switch (provider) {
      case 'GoogleDrive': {
        const requestUrl = 'https://www.googleapis.com/oauth2/v3/userinfo';
        const userInfo = yield call(request, fetch, requestUrl, {
          headers: {
            Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
          },
        });
        const profile = {};
        if (userInfo.picture && !endsWith(userInfo.picture, 'photo.jpg')) {
          profile.avatar = yield call(fetchImage, userInfo.picture);
        }
        yield put(actions.getProfileSuccess(isEmpty(profile) ? null : profile));
        break;
      }
      default: {
        yield put(actions.getProfileSuccess(null));
      }
    }
  } catch (err) {
    yield put(actions.getProfileError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* fetchPhiSet({ payload }) {
  const { patient, successAction } = payload;
  const { phiSetReferenceKey, accessToken, storageProvider } = patient || {};

  try {
    appInsights.startTrackEvent('fetchPhiSet');
    if (!phiSetReferenceKey) {
      yield put(successAction(null, null, patient.id));
      yield put(actions.fetchPhiSetSuccess());
      return null;
    }

    const documentId = yield call(fetchDocumentId, phiSetReferenceKey, storageProvider, accessToken);

    if (!documentId) {
      if (successAction) {
        yield put(successAction(null, null, patient.id));
      }
      yield put(actions.fetchPhiSetSuccess());
      return null;
    }

    const phiSet = yield call(CloudDriveService.fetchPhiSet, documentId, storageProvider, accessToken);

    if (successAction) {
      yield put(successAction(phiSet, documentId, patient.id));
    }
    yield put(actions.fetchPhiSetSuccess());
    return phiSet;
  } catch (err) {
    yield put(actions.fetchPhiSetError(err));
    yield call(App.dispatchError, err, messages);
  } finally {
    appInsights.stopTrackEvent('fetchPhiSet', { phiSetReferenceKey, storageProvider });
  }
  return null;
}


function getCgmReadingsDocumentsIds(phiSet, cgmBatchesIndex, startDate, endDate) {
  const neededCgmBatchesIndex = [];
  const date = startDate.clone();
  while (date.isBefore(endDate)) {
    const batchKey = `${date.locale('en').format('YYYY')}-w${date.locale('en').format('WW')}`;
    neededCgmBatchesIndex.push(batchKey);
    date.add(1, 'week');
  }
  neededCgmBatchesIndex.push(`${endDate.locale('en').format('YYYY')}-w${endDate.locale('en').format('WW')}`);
  const newCgmBatchesIndex = uniq(difference(neededCgmBatchesIndex, cgmBatchesIndex));
  const dataIndex = get(phiSet, 'dataIndex', {});
  const cgmReadingsDocumentsIds = {};
  const cgmReadingsDocuments = {};
  Object.entries(dataIndex).forEach(([monthKey, value]) => {
    if (!value.cgmReadingsBatchPointers) {
      return;
    }
    Object.entries(value.cgmReadingsBatchPointers).forEach(([week, { cgmReadingWeeklyBatchId }]) => {
      cgmReadingsDocuments[`${monthKey.split('-')[0]}-w${String(week).padStart(2, '0')}`] = cgmReadingWeeklyBatchId;
    });
  });

  forEach(newCgmBatchesIndex, (batchKey) => {
    const readingsDocumentId = get(cgmReadingsDocuments, [batchKey]);
    if (readingsDocumentId) {
      cgmReadingsDocumentsIds[batchKey] = readingsDocumentId;
    }
  });
  return { cgmReadingsDocumentsIds, newCgmBatchesIndex };
}


function getReadingsDocumentsIds(phiSet, batchesIndex, fromImports, startDate, endDate, forceUpdate = false) {
  const neededBatchesIndex = [];
  const readingsDocumentsIds = {};
  const relatedDataDocumentsIds = {};
  const timeSeriesResourcesIds = {};

  if (fromImports) {
    forOwn(get(phiSet, 'dataIndex', {}), (batch, batchKey) => {
      const importsDocumentsIds = get(batch, 'importsDocumentsIds', []);
      const lookup = intersection(importsDocumentsIds, fromImports);
      if (lookup.length) {
        neededBatchesIndex.push(batchKey);
      }
    });
  } else {
    const date = startDate.clone();
    while (date.isBefore(endDate)) {
      const batchKey = date.locale('en').format('YYYY-MM');
      neededBatchesIndex.push(batchKey);
      date.add(1, 'month');
    }
    neededBatchesIndex.push(endDate.locale('en').format('YYYY-MM'));
  }

  const newBatchesIndex = forceUpdate ? neededBatchesIndex : uniq(difference(neededBatchesIndex, batchesIndex));
  const dataIndex = get(phiSet, 'dataIndex', {});

  forEach(newBatchesIndex, (batchKey) => {
    const readingsDocumentId = get(dataIndex, [batchKey, 'readingsDocumentId']);
    const relatedDataDocumentId = get(dataIndex, [batchKey, 'relatedDataDocumentId']);
    const timeSeriesResourcesId = get(dataIndex, [batchKey, 'timeSeriesResourceId']);
    if (readingsDocumentId) {
      readingsDocumentsIds[batchKey] = readingsDocumentId;
    }
    if (relatedDataDocumentId) {
      relatedDataDocumentsIds[batchKey] = relatedDataDocumentId;
    }
    if (timeSeriesResourcesId) {
      timeSeriesResourcesIds[batchKey] = timeSeriesResourcesId;
    }
  });

  return { readingsDocumentsIds, relatedDataDocumentsIds, timeSeriesResourcesIds, newBatchesIndex };
}


function getMeasurementsDocumentsIds(phiSet, measurementsBatchesIndex, startDate, endDate) {
  const newMeasurementsBatchesIndex = [];

  const date = startDate.clone();
  const dataIndex = get(phiSet, 'dataIndex', {});

  const firstDate = startDate.clone();
  const sortedPhiDates = Object.keys(dataIndex).map((data) => moment(data, 'YYYY-MM'))
    .sort((a, b) => moment(a).format('X') - moment(b).format('X'));
  if (sortedPhiDates[0]) {
    let firstMeasurementsId = get(dataIndex, [sortedPhiDates[0].format('YYYY-MM'), 'measurementsDocumentId']);
    if (!firstMeasurementsId && sortedPhiDates.length > 0) {
      const firstDataIndexDate = sortedPhiDates[0];
      while (firstDataIndexDate.isBefore(firstDate) && !firstMeasurementsId) {
        const batchKey = firstDate.locale('en').format('YYYY-MM');
        firstMeasurementsId = get(dataIndex, [batchKey, 'measurementsDocumentId']);
        newMeasurementsBatchesIndex.push(batchKey);
        firstDate.subtract(1, 'month');
      }
    }
  }

  while (date.isBefore(endDate)) {
    const batchKey = date.locale('en').format('YYYY-MM');
    newMeasurementsBatchesIndex.push(batchKey);
    date.add(1, 'month');
  }

  newMeasurementsBatchesIndex.push(endDate.locale('en').format('YYYY-MM'));
  const newMeasurementsIndex = uniq(difference(newMeasurementsBatchesIndex, measurementsBatchesIndex));
  const measurementsIds = {};

  forEach(newMeasurementsIndex, (batchKey) => {
    const measurementsId = get(dataIndex, [batchKey, 'measurementsDocumentId']);
    if (measurementsId) {
      measurementsIds[batchKey] = measurementsId;
    }
  });

  return { measurementsIds, newMeasurementsBatchesIndex };
}


function getNotesDocumentsIds(phiSet, batches, notesBatchesIndex) {
  const notesDocumentsIds = {};
  const newBatchesIndex = uniq(difference(batches, notesBatchesIndex));
  const dataIndex = get(phiSet, 'dataIndex', {});
  forEach(newBatchesIndex, (batchKey) => {
    const notesDocumentId = get(dataIndex, [batchKey, 'notesDocumentId']);
    if (notesDocumentId) {
      notesDocumentsIds[batchKey] = notesDocumentId;
    }
  });
  return { notesDocumentsIds, newBatchesIndex };
}


function* fetchReadings({ payload }) {
  try {
    const {
      phiSetReferenceKey, phiSetDocumentId,
      fromImports,
      batchesIndex, cgmBatchesIndex, measurementsBatchesIndex,
      accessToken, storageProvider,
      startDate, endDate,
      successAction,
    } = payload;
    let { phiSet } = payload;
    let forceUpdate = false;

    const realDocumentId = yield call(fetchDocumentId, phiSetReferenceKey, storageProvider, accessToken);
    if (phiSetDocumentId !== realDocumentId) {
      phiSet = yield call(CloudDriveService.fetchPhiSet, realDocumentId, storageProvider, accessToken);
      forceUpdate = true;
    }

    const { cgmReadingsDocumentsIds, newCgmBatchesIndex } = getCgmReadingsDocumentsIds(
      phiSet, cgmBatchesIndex, startDate, endDate,
    );
    const { readingsDocumentsIds, relatedDataDocumentsIds, timeSeriesResourcesIds, newBatchesIndex } = getReadingsDocumentsIds(
      phiSet, batchesIndex, fromImports, startDate, endDate, forceUpdate,
    );
    const { measurementsIds, newMeasurementsBatchesIndex } = getMeasurementsDocumentsIds(
      phiSet, measurementsBatchesIndex, startDate, endDate,
    );

    let download;
    let cgmDownload;
    let relatedDownload;
    let timeSeriesResourcesDownload;
    let measurementsDownload;
    if (storageProvider === 'CentralStorage') {
      download = CentralStorageService.fetchReadings;
      cgmDownload = CentralStorageService.fetchCgmReadings;
      relatedDownload = CentralStorageService.fetchRelatedData;
      timeSeriesResourcesDownload = CentralStorageService.fetchTimeSeriesResources;
      measurementsDownload = CentralStorageService.fetchMeasurements;
    } else {
      const driveService = CloudDriveService.getDriveService(storageProvider);
      download = driveService.downloadFile;
      relatedDownload = driveService.downloadFile;
      timeSeriesResourcesDownload = driveService.downloadFile;
      measurementsDownload = driveService.downloadFile;
    }
    const readingsBatches = yield all(mapValues(
      readingsDocumentsIds,
      (documentId) => call(download, documentId, accessToken),
    ));
    const relatedDataBatches = yield all(mapValues(
      relatedDataDocumentsIds,
      (documentId) => call(relatedDownload, documentId, accessToken),
    ));
    const timeSeriesResourcesBatches = yield all(mapValues(
      timeSeriesResourcesIds,
      (documentId) => call(timeSeriesResourcesDownload, documentId, accessToken),
    ));
    const cgmReadingsBatches = yield all(map(
      flatten(Object.values(cgmReadingsDocumentsIds)),
      (documentId) => call(cgmDownload, documentId, accessToken),
    ));
    const measurementsBatches = yield all(map(
      flatten(Object.values(measurementsIds)),
      (documentId) => call(measurementsDownload, documentId, accessToken),
    ));
    const readings = flatten(values(readingsBatches, (rs) => rs));
    const relatedData = flatten(values(relatedDataBatches, (rs) => rs));
    const timeSeriesResources = values(timeSeriesResourcesBatches);
    const cgmReadings = flatten(values(cgmReadingsBatches, (rs) => rs));
    const measurements = flatten(values(measurementsBatches, (rs) => rs));

    yield put(successAction(
      phiSet,
      readings, newBatchesIndex,
      cgmReadings, newCgmBatchesIndex,
      relatedData, timeSeriesResources,
      measurements, newMeasurementsBatchesIndex
    ));
    yield put(actions.fetchReadingsSuccess());
  } catch (err) {
    yield put(actions.fetchReadingsError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* fetchNotes({ payload }) {
  try {
    const {
      phiSetReferenceKey, phiSetDocumentId,
      notesBatchesIndex,
      accessToken, storageProvider,
      batches,
      successAction,
    } = payload;
    let { phiSet } = payload;

    const realDocumentId = yield call(fetchDocumentId, phiSetReferenceKey, storageProvider, accessToken);
    if (phiSetDocumentId !== realDocumentId) {
      phiSet = yield call(CloudDriveService.fetchPhiSet, realDocumentId, storageProvider, accessToken);
    }

    const { notesDocumentsIds, newBatchesIndex } = getNotesDocumentsIds(phiSet, batches, notesBatchesIndex);

    let download;
    if (storageProvider === 'CentralStorage') {
      download = CentralStorageService.fetchNotes;
    } else {
      const driveService = CloudDriveService.getDriveService(storageProvider);
      download = driveService.downloadFile;
    }

    const notesBatches = yield all(mapValues(
      notesDocumentsIds,
      (documentId) => call(download, documentId, accessToken),
    ));
    const notes = flatten(values(notesBatches, (nb) => nb));

    if (successAction) {
      yield put(successAction(phiSet, realDocumentId, notes, newBatchesIndex));
    }

  } catch (err) {
    yield put(actions.fetchNotesError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* fetchAggregates({ payload }) {
  try {
    const { accessToken, storageProvider, months, level, phiSet, fetchedAggregationDocumentsIds } = payload;
    const dataIndex = get(phiSet, 'dataIndex', {});
    const aggregationDocumentsIds = [];
    forEach(months, (batchKey) => {
      const aggregations = get(dataIndex, [batchKey, 'aggregations']);
      if (!aggregations) return;
      const neededAggregation = aggregations.find((aggregation) => aggregation.level === level);
      if (!neededAggregation) return;
      aggregationDocumentsIds.push(neededAggregation.aggregationDocumentId);
    });
    const newAggregationDocumentsIds = uniq(difference(aggregationDocumentsIds, fetchedAggregationDocumentsIds));
    let download;
    if (storageProvider === 'CentralStorage') {
      download = CentralStorageService.fetchAggregations;
    } else {
      return;
    }
    const aggregationsBatches = yield all(mapValues(
      newAggregationDocumentsIds,
      (documentId) => call(download, { documentId, level: level.toLowerCase(), accessToken }),
    ));
    yield put(actions.fetchAggregatesSuccess({ aggregationsBatches, aggregationDocumentsIds: newAggregationDocumentsIds }));
  } catch (err) {
    yield put(actions.fetchAggregatesError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* openPhiSetTransaction(phiSetReferenceKey, phiSetDocumentId, phiSet, storageProvider, accessToken) {
  const transaction = yield call(openTransaction, [{
    lockType    : 'Exclusive',
    referenceKey: phiSetReferenceKey,
  }]);
  const { transactionId, revisions } = transaction;
  const revisionDocumentId = get(revisions, [0, 'documentId']);

  let documentId = phiSetDocumentId;

  try {
    if (phiSetDocumentId !== revisionDocumentId) {
      phiSet = yield call(CloudDriveService.fetchPhiSet, revisionDocumentId, storageProvider, accessToken);
      documentId = revisionDocumentId;
    }
  } catch (err) {
    yield call(closeTransaction, transactionId);
    throw err;
  }
  return { phiSet, documentId, transactionId };
}


function* closePhiSetTransaction(transactionId, referenceKey, previousDocumentId, newDocumentId, auditDocumentId) {
  const updatedRevisions = [{
    previousDocumentId,
    newDocumentId,
    referenceKey,
    auditDocumentId,
  }];
  return yield call(commitTransaction, transactionId, updatedRevisions);
}


function* uploadBatches(batches, drive, accessToken) {
  const callsChunks = [];
  const chunkSize = 5;
  let chunkCounter = 0;
  let chunkCursor = 0;
  forIn(batches, (batchReadings, batchKey) => {
    const callsChunk = chunkCursor ? callsChunks[chunkCounter] : {};
    callsChunk[batchKey] = call(drive.uploadJson, batchReadings, accessToken);
    callsChunks[chunkCounter] = callsChunk;
    chunkCursor++;
    if (chunkCursor === chunkSize) {
      chunkCounter++;
      chunkCursor = 0;
    }
  });

  let newDocumentsIds = {};
  for (let i = 0; i < callsChunks.length; i++) {
    const chunkDocuments = yield all(callsChunks[i]);
    const chunkDocumentsIds = mapValues(chunkDocuments, (callResults) => callResults.documentId);
    newDocumentsIds = { ...newDocumentsIds, ...chunkDocumentsIds };
  }
  return newDocumentsIds;
}


function* uploadBatchesRelatedData(batches, forkedDataIndexItems, documentIdKey, uniqKey, drive, accessToken) {
  const forkedBatches = yield all(mapValues(
    forkedDataIndexItems,
    (dataIndexItem) => {
      if (!dataIndexItem[documentIdKey]) return null;
      return call(drive.downloadFile, dataIndexItem[documentIdKey], accessToken);
    },
  ));

  forEach(forkedDataIndexItems, (dataIndexItem, forkedBatchKey) => {
    batches[forkedBatchKey] = batches[forkedBatchKey].map((item) => {
      if (forkedBatches[forkedBatchKey]) {
        const forkedItem = forkedBatches[forkedBatchKey].find(
          (element) => `${element[uniqKey]}_${element.timestamp}` === `${item[uniqKey]}_${item.timestamp}`
        ) || {
          activities : [],
          foods      : [],
          medications: [],
        };
        item.activities = unionBy(
          item.activities, forkedItem.activities, (activity) => activity.id
        ).filter((activities) => !activities.isDelete);
        item.foods = unionBy(
          item.foods, forkedItem.foods, (food) => `${food.id}_${food.name}`
        ).filter((foods) => !foods.isDelete);
        item.medications = unionBy(
          item.medications, forkedItem.medications, (medication) => `${medication.id}_${medication.name}`
        ).filter((medications) => !medications.isDelete);
      }
      return item;
    });

    batches[forkedBatchKey] = unionBy(
      batches[forkedBatchKey],
      forkedBatches[forkedBatchKey],
      (item) => `${item[uniqKey]}_${item.timestamp}`,
    );
    batches[forkedBatchKey] = batches[forkedBatchKey].filter((item) =>
      !isEmpty(item.activities) || !isEmpty(item.foods) || !isEmpty(item.medications)
    );
  });

  const batchesUploads = mapValues(
    batches,
    (batchesRelatedData) => call(drive.uploadJson, batchesRelatedData, accessToken),
  );

  const chunkDocuments = yield all(batchesUploads);

  const chunkDocumentsIds = mapValues(chunkDocuments, (callResults) => callResults.documentId);

  return chunkDocumentsIds;
}

//----------------------------------------------------------------------------------------------------------------------

function* uploadTimeSeriesResources(batches, forkedDataIndexItems, documentIdKey, uniqKey, drive, accessToken) {
  const forkedBatches = yield all(mapValues(
    forkedDataIndexItems,
    (dataIndexItem) => {
      if (!dataIndexItem[documentIdKey]) return null;
      return call(drive.downloadFile, dataIndexItem[documentIdKey], accessToken);
    },
  ));

  forEach(forkedDataIndexItems, (dataIndexItem, forkedBatchKey) => {
    batches[forkedBatchKey] = batches[forkedBatchKey].map((item) => {
      if (forkedBatches[forkedBatchKey]) {
        const forkedItem = forkedBatches[forkedBatchKey].find(
          (element) => `${element[uniqKey]}_${element.timestamp}` === `${item[uniqKey]}_${item.timestamp}`
        ) || { resources: [] };
        item.resources = unionBy(
          item.resources, forkedItem.resources, (resource) => resource.id
        );
      }
      return item;
    });

    batches[forkedBatchKey] = unionBy(
      batches[forkedBatchKey],
      forkedBatches[forkedBatchKey],
      (item) => `${item[uniqKey]}_${item.timestamp}`,
    );
    batches[forkedBatchKey] = batches[forkedBatchKey].filter((item) => !isEmpty(item.resources));
  });

  const batchesUploads = mapValues(
    batches,
    (batchesRelatedData) => call(drive.uploadJson, batchesRelatedData, accessToken),
  );

  const chunkDocuments = yield all(batchesUploads);

  const chunkDocumentsIds = mapValues(chunkDocuments, (callResults) => callResults.documentId);

  return chunkDocumentsIds;
}

//----------------------------------------------------------------------------------------------------------------------

function getCombinedLastMaxResultsDates(currentImports) {
  return reduce(currentImports, (acc, value) => {
    const lastResultsDates = get(value, 'lastMaxResultsDates', {});
    forIn(lastResultsDates, (lastResultDate, serialNumberToken) => {
      if (!acc[serialNumberToken] || acc[serialNumberToken] < lastResultDate) {
        acc[serialNumberToken] = lastResultDate;
      }
    });
    return acc;
  }, {});
}


function* combineReadingsBatches(batches, forkedDataIndexItems, drive, accessToken) {
  const wildcardDeviceSerialNumberTokens = App.constants.WILDCARD_DEVICE_SERIAL_NUMBER_TOKENS;

  const forkedBatches = yield all(mapValues(
    forkedDataIndexItems,
    (dataIndexItem) => {
      if (!dataIndexItem.readingsDocumentId) return null;
      return call(drive.downloadFile, dataIndexItem.readingsDocumentId, accessToken);
    },
  ));

  const combinedBatches = {};
  let newReadingsCount = 0;

  forEach(batches, (dataIndexItem, batchKey) => {
    const newReadings = batches[batchKey];
    if (!forkedBatches[batchKey]) {
      combinedBatches[batchKey] = newReadings.sort((a, b) => a.timestamp - b.timestamp);
      newReadingsCount += newReadings.length;
      return;
    }
    const storedReadings = forkedBatches[batchKey];
    const filteredNewReadings = [];
    newReadings.forEach((newReading) => {
      const storedReadingIndex = storedReadings.findIndex((sr) => sr.timestamp === newReading.timestamp);
      if (storedReadingIndex < 0) {
        filteredNewReadings.push(newReading);
        return;
      }
      const storedReading = storedReadings[storedReadingIndex];
      const isNewReadingWild = includes(wildcardDeviceSerialNumberTokens, newReading.deviceSerialNumberToken);
      const isStoredReadingWild = includes(wildcardDeviceSerialNumberTokens, storedReading.deviceSerialNumberToken);
      const areSame = newReading.deviceSerialNumberToken === storedReading.deviceSerialNumberToken
        || isNewReadingWild
        || isStoredReadingWild;
      if (!areSame) {
        filteredNewReadings.push(newReading);
      } else if (isStoredReadingWild && !isNewReadingWild) {
        storedReading.deviceSerialNumberToken = newReading.deviceSerialNumberToken;
      }
    });
    const combinedReadings = [...storedReadings, ...filteredNewReadings].sort((a, b) => a.timestamp - b.timestamp);
    newReadingsCount += filteredNewReadings.length;

    combinedBatches[batchKey] = combinedReadings;
  });
  return { combinedBatches, newReadingsCount };
}


function* storeReadings({ payload }) {
  try {
    const {
      phiSetDocumentId,
      phiSetReferenceKey,
      deviceImportData,
      accessToken,
      storageProvider,
      successAction,
      lastImportUTC = +moment().locale('en').format('X'),
    } = payload;
    let { phiSet } = payload;

    if (!accessToken) {
      const err = new LocalError({ code: 'NoAccessToken' });
      yield put(actions.storeReadingsError(err));
      yield call(App.dispatchError, err, messages);
      return;
    }

    const { readings, deviceSerialNumberToken } = deviceImportData;
    unset(deviceImportData, 'serialNumber');

    const createTimestamp = +getNowAsUtc().locale('en').format('X');

    if (storageProvider === 'CentralStorage') {
      deviceImportData.createTimestamp = createTimestamp;
      deviceImportData.lastImportUTC = lastImportUTC;
      const { newPhiSetDocumentId, updatedPhiSet, importId, forksHistory } = yield call(
        CentralStorageService.storeReadings, phiSetReferenceKey, deviceImportData, accessToken,
      );
      yield call(CloudDriveService.clearForks, forksHistory, storageProvider, accessToken);
      if (successAction) {
        const updatedReadingsDocumentIds = [];
        forEach(updatedPhiSet.dataIndex, (data) => {
          if (includes(data.importsDocumentsIds, importId)) {
            updatedReadingsDocumentIds.push(data.readingsDocumentId);
          }
        });
        const updatedBatches = yield all(mapValues(
          updatedReadingsDocumentIds,
          (documentId) => call(CentralStorageService.fetchReadings, documentId, accessToken),
        ));
        const updatedReadings = flatten(values(updatedBatches, (rs) => rs));
        yield put(successAction(
          updatedPhiSet, newPhiSetDocumentId, phiSetReferenceKey, updatedReadings, deviceImportData, importId,
        ));
      }
      yield put(actions.storeReadingsSuccess(updatedPhiSet, newPhiSetDocumentId));
      return;
    }

    const transaction = yield call(openTransaction, [{
      lockType    : 'Exclusive',
      referenceKey: phiSetReferenceKey,
    }]);
    const { transactionId, revisions } = transaction;
    const revisionDocumentId = get(revisions, [0, 'documentId']);

    // Transaction try
    try {
      if (phiSetDocumentId !== revisionDocumentId) {
        phiSet = yield call(CloudDriveService.fetchPhiSet, revisionDocumentId, storageProvider, accessToken);
      }

      const currentImports = get(phiSet, 'imports', {});
      const deviceImports = get(currentImports, deviceSerialNumberToken, {});
      const importsDocuments = get(deviceImports, 'documents', []);

      const currentSummaryData = get(phiSet, 'summaryData');
      let maxReadingsTimestamp = get(currentSummaryData, 'maxReadingsTimestamp');
      let minReadingsTimestamp = get(currentSummaryData, 'minReadingsTimestamp');
      let totalReadingsCount = get(currentSummaryData, 'totalReadingsCount', 0);
      const lastMaxResultsDates = get(deviceImports, 'lastMaxResultsDates', {});
      const updatedLastMaxResultsDates = { ...lastMaxResultsDates };
      const combinedLastMaxResultsDates = getCombinedLastMaxResultsDates(currentImports);

      const driveService = CloudDriveService.getDriveService(storageProvider);

      // Import data
      const importReadings = [];
      const devicesSet = new Set();
      let maxResultDate = 0;
      let minResultDate = 0;

      forEach(readings, (reading) => {
        const serialNumberToken = reading.deviceSerialNumberToken || deviceSerialNumberToken;
        const lastMaxResultsDate = get(combinedLastMaxResultsDates, serialNumberToken);

        const isManual = isManualReading(reading);

        if (!reading.isManual) {
          reading.isManual = false;
        }

        if (lastMaxResultsDate && lastMaxResultsDate >= reading.timestamp) {
          return;
        }
        if (!maxResultDate || reading.timestamp > maxResultDate) {
          maxResultDate = reading.timestamp;
        }
        if (!minResultDate || reading.timestamp < minResultDate) {
          minResultDate = reading.timestamp;
        }
        if (
          (!updatedLastMaxResultsDates[serialNumberToken]
          || reading.timestamp > updatedLastMaxResultsDates[serialNumberToken])
          && !isManual
        ) {
          updatedLastMaxResultsDates[serialNumberToken] = reading.timestamp;
        }
        devicesSet.add(serialNumberToken);
        if (reading.deviceSerialNumberToken) {
          devicesSet.add(reading.deviceSerialNumberToken);
        }
        importReadings.push(reading);
      });

      const importedReadingsCount = importReadings.length;

      if (!importedReadingsCount) {
        const imports = {
          ...currentImports,
          [deviceSerialNumberToken]: {
            documents          : importsDocuments,
            lastMaxResultsDates: updatedLastMaxResultsDates,
            lastImportUTC      : +moment().locale('en').format('X'),
          },
        };
        const updatedPhiSet = { ...phiSet, imports };
        const auditDocument = diff(phiSet, updatedPhiSet);
        const { documentId, auditDocumentId } = yield call(
          driveService.uploadJson, updatedPhiSet, accessToken, auditDocument
        );

        const updatedRevisions = [{
          previousDocumentId: revisionDocumentId,
          newDocumentId     : documentId,
          referenceKey      : phiSetReferenceKey,
          auditDocumentId,
        }];

        const forksHistory = yield call(commitTransaction, transactionId, updatedRevisions);
        yield call(CloudDriveService.clearForks, forksHistory, storageProvider, accessToken);

        if (successAction) {
          yield put(successAction(updatedPhiSet, documentId, phiSetReferenceKey, importReadings));
        }
        yield put(actions.storeReadingsSuccess(updatedPhiSet, documentId));
        return;
      }

      const devices = Array.from(devicesSet);
      const glucoseLevelTargets = deviceImportData.summary
        ? pick(deviceImportData.summary, ['highNorm', 'lowNorm', 'preMealHighNorm', 'preMealLowNorm'])
        : null;
      const importData = {
        ...pick(deviceImportData, ['deviceDate', 'deviceMode', 'deviceName', 'deviceType', 'deviceSerialNumberToken']),
        importChannel       : deviceImportData.connectionType,
        maxReadingsTimestamp: maxResultDate,
        minReadingsTimestamp: minResultDate,
        readingsCount       : importedReadingsCount,
        devices,
        ...glucoseLevelTargets,
        timestamp           : createTimestamp,
      };

      const { documentId: importId } = yield call(driveService.uploadJson, importData, accessToken);
      importsDocuments.push({
        importDocumentId: importId,
        timestamp       : createTimestamp,
        maxResultDate,
        minResultDate,
        readingsCount   : importedReadingsCount,
        ...(deviceImportData.connectionType === 'Sync' ? { devices } : null),
      });

      const lastGlucoseLevelTargets = {
        normal: {
          highThreshold: get(glucoseLevelTargets, 'highNorm', null),
          lowThreshold : get(glucoseLevelTargets, 'lowNorm', null),
        },
        preMeal: {
          highThreshold: get(glucoseLevelTargets, 'preMealHighNorm', null),
          lowThreshold : get(glucoseLevelTargets, 'preMealLowNorm', null),
        },
      };

      const imports = {
        ...currentImports,
        [deviceSerialNumberToken]: {
          deviceName         : deviceImportData.deviceName,
          documents          : importsDocuments,
          lastMaxResultsDates: updatedLastMaxResultsDates,
          lastImportUTC      : +moment().locale('en').format('X'),
          lastGlucoseLevelTargets,
        },
      };

      // Batches
      const batches = {};
      const visitsBatches = {};

      forEach(importReadings, (reading) => {
        const batchKey = moment.unix(reading.timestamp).locale('en').format('YYYY-MM');
        const batch = get(batches, batchKey, []);
        if (!reading.deviceSerialNumberToken) {
          reading.deviceSerialNumberToken = deviceSerialNumberToken;
        }
        reading.importDocumentId = importId;
        batch.push(reading);
        batches[batchKey] = batch;

        if (!maxReadingsTimestamp || reading.timestamp > maxReadingsTimestamp) {
          maxReadingsTimestamp = reading.timestamp;
        }
        if (!minReadingsTimestamp || reading.timestamp < minReadingsTimestamp) {
          minReadingsTimestamp = reading.timestamp;
        }

        if (reading.phisetVisitId) {
          if (!visitsBatches[reading.phisetVisitId]) {
            visitsBatches[reading.phisetVisitId] = [];
          }
          if (visitsBatches[reading.phisetVisitId].indexOf(batchKey) < 0) {
            visitsBatches[reading.phisetVisitId].push(batchKey);
          }
        }
      });

      const currentDataIndex = get(phiSet, 'dataIndex', {});
      const batchesKeys = keysIn(batches).sort();
      const forkedDataIndexItems = pick(currentDataIndex, batchesKeys);

      const { combinedBatches, newReadingsCount } = yield call(
        combineReadingsBatches, batches, forkedDataIndexItems, driveService, accessToken,
      );

      totalReadingsCount += newReadingsCount;

      const newDocumentsIds = yield call(uploadBatches, combinedBatches, driveService, accessToken);

      const dataIndex = { ...currentDataIndex };

      forIn(
        newDocumentsIds,
        (readingsDocumentId, dataIndexKey) => {
          const currentData = get(currentDataIndex, dataIndexKey, {});
          const dataIndexImportsIds = get(currentData, 'importsDocumentsIds', []);
          dataIndexImportsIds.push(importId);
          const readingsCount = batches[dataIndexKey].length;
          const [year, month] = dataIndexKey.split('-');
          const data = {
            ...currentData,
            readingsDocumentId,
            readingsCount,
            importsDocumentsIds: dataIndexImportsIds,
            lastUpdate         : createTimestamp,
            year               : +year,
            month              : +month,
          };
          set(dataIndex, dataIndexKey, data);
        },
      );

      const lastConnectionType = importData.importChannel !== 'Sync'
        ? importData.importChannel
        : get(currentSummaryData, 'lastConnectionType');

      const summaryData = {
        ...currentSummaryData,
        lastConnectionType,
        maxReadingsTimestamp,
        minReadingsTimestamp,
        totalReadingsCount,
      };

      const visits = get(phiSet, 'visits', []);
      if (!isEmpty(visitsBatches)) {
        forIn(visitsBatches, (newVisitBatches, phisetVisitId) => {
          const visitIdx = findIndex(visits, { phisetVisitId });
          if (visitIdx >= 0) {
            const currentVisitBatches = get(visits, [visitIdx, 'associatedDataIndexKeys', 'readings'], []);
            const visitBatches = union(currentVisitBatches, newVisitBatches);
            set(visits, [visitIdx, 'associatedDataIndexKeys', 'readings'], visitBatches);
          }
        });
      }


      const updatedPhiSet = { ...phiSet, summaryData, dataIndex, imports, visits };
      const auditDocument = diff(phiSet, updatedPhiSet);

      const { documentId, auditDocumentId } = yield call(
        driveService.uploadJson, updatedPhiSet, accessToken, auditDocument
      );

      const updatedRevisions = [{
        previousDocumentId: revisionDocumentId,
        newDocumentId     : documentId,
        referenceKey      : phiSetReferenceKey,
        auditDocumentId,
      }];

      const forksHistory = yield call(commitTransaction, transactionId, updatedRevisions);
      yield call(CloudDriveService.clearForks, forksHistory, storageProvider, accessToken);

      const combinedReadings = flatten(values(combinedBatches, (rs) => rs));
      if (successAction) {
        yield put(successAction(updatedPhiSet, documentId, phiSetReferenceKey, combinedReadings));
      }
      yield put(actions.storeReadingsSuccess(updatedPhiSet, documentId));
    } catch (err) {
      yield call(closeTransaction, transactionId);
      yield put(actions.storeReadingsError(err));
      yield call(App.dispatchError, err, messages);
    }
  } catch (err) {
    yield put(actions.storeReadingsError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* storeCgmReadings({ payload }) {
  try {
    const { phiSetDocumentId, phiSetReferenceKey, accessToken, storageProvider, successAction } = payload;

    if (!accessToken) {
      const err = new LocalError({ code: 'NoAccessToken' });
      yield put(actions.storeReadingsError(err));
      yield call(App.dispatchError, err, messages);
      return;
    }

    let { importData } = payload;
    let documentId = phiSetDocumentId;

    importData = { ...importData };
    const { transmitters, deviceSerialNumberToken } = importData;
    const readings = transmitters
      .reduce((acc, transmitter) => ([...acc, ...transmitter.readings]), [])
      .map((reading) => ({
        ...reading,
        deviceSerialNumberToken,
        isBasedOnControlMeasurement: true,
        referenceBg                : reading.referenceBgValue,
        eventType                  : String(reading.eventType),
      }));
    unset(importData, 'serialNumber');
    unset(importData, 'transmitters');
    unset(importData, 'summary');
    importData.readings = readings;

    const transaction = yield call(openTransaction, [{
      lockType    : 'Exclusive',
      referenceKey: phiSetReferenceKey,
    }]);
    const { transactionId, revisions } = transaction;
    const revisionDocumentId = get(revisions, [0, 'documentId']);
    if (phiSetDocumentId !== revisionDocumentId) {
      documentId = revisionDocumentId;
    }

    // Transaction try
    try {
      if (storageProvider === 'CentralStorage') {
        const { newPhiSetDocumentId, updatedPhiSet, auditDocumentId } = yield call(
          CentralStorageService.storeCgmReadings, documentId, importData, accessToken,
        );
        const updatedRevisions = [{
          previousDocumentId: documentId,
          newDocumentId     : newPhiSetDocumentId,
          referenceKey      : phiSetReferenceKey,
          auditDocumentId,
        }];
        const forksHistory = yield call(commitTransaction, transactionId, updatedRevisions);
        yield call(CloudDriveService.clearForks, forksHistory, storageProvider, accessToken);
        if (successAction) {
          forEach(readings, (reading) => set(reading, 'deviceSerialNumberToken', deviceSerialNumberToken));
          yield put(successAction(updatedPhiSet, newPhiSetDocumentId, [], readings));
        }
        yield put(actions.storeCgmReadingsSuccess());
      }
    } catch (err) {
      yield call(closeTransaction, transactionId);
      yield put(actions.storeCgmReadingsError(err));
      yield call(App.dispatchError, err, messages);
    }

  } catch (err) {
    yield put(actions.storeCgmReadingsError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------


function* storeRelatedData({ payload }) {
  try {
    const {
      phiSetDocumentId,
      phiSetReferenceKey,
      bgRelatedData,
      accessToken,
      storageProvider,
      successAction,
      lastImportUTC = +moment().locale('en').format('X'),
    } = payload;
    let { phiSet } = payload;
    const { result, dataSource } = bgRelatedData;

    if (!accessToken) {
      const err = new LocalError({ code: 'NoAccessToken' });
      yield put(actions.storeRelatedDataError(err));
      yield call(App.dispatchError, err, messages);
      return;
    }

    if (isEmpty(result)) {
      yield put(actions.storeRelatedDataSuccess());
      return;
    }

    const createTimestamp = +getNowAsUtc().locale('en').format('X');

    if (storageProvider === 'CentralStorage') {
      const importData = {};
      importData.readingsRelatedData = result;
      importData.createTimestamp = createTimestamp;
      importData.lastImportUTC = lastImportUTC;
      importData.provider = dataSource.dataSourceProvider;
      const { newPhiSetDocumentId, updatedPhiSet, forksHistory } = yield call(
        CentralStorageService.storeRelatedData, phiSetReferenceKey, importData, accessToken,
      );
      yield call(CloudDriveService.clearForks, forksHistory, storageProvider, accessToken);
      if (successAction) {
        yield put(successAction(updatedPhiSet, newPhiSetDocumentId, phiSetReferenceKey, result));
      }
      yield put(actions.storeRelatedDataSuccess());
      return;
    }

    const transaction = yield call(openTransaction, [{
      lockType    : 'Exclusive',
      referenceKey: phiSetReferenceKey,
    }]);
    const { transactionId, revisions } = transaction;
    const revisionDocumentId = get(revisions, [0, 'documentId']);

    // Transaction try
    try {
      if (phiSetDocumentId !== revisionDocumentId) {
        phiSet = yield call(CloudDriveService.fetchPhiSet, revisionDocumentId, storageProvider, accessToken);
      }

      const currentImports = get(phiSet, 'importsRelatedData', {});

      const driveService = CloudDriveService.getDriveService(storageProvider);

      const importsRelatedData = {
        ...currentImports,
        [camelCase(dataSource.dataSourceProvider)]: {
          lastImportUTC,
        },
      };

      // Batches
      const batches = {};

      forEach(result, (data) => {
        const batchKey = moment.unix(data.dateTime).locale('en').format('YYYY-MM');
        const batch = get(batches, batchKey, []);
        batch.push(data);
        batches[batchKey] = batch;
      });

      const currentDataIndex = get(phiSet, 'dataIndex', {});
      const batchesKeys = keysIn(batches).sort();
      const forkedDataIndexItems = pick(currentDataIndex, batchesKeys);

      const newDocumentsIds = yield call(
        uploadBatchesRelatedData,
        batches, forkedDataIndexItems,
        'relatedDataDocumentId', 'dateTime',
        driveService, accessToken,
      );

      const dataIndex = { ...currentDataIndex };

      forIn(
        newDocumentsIds,
        (relatedDataDocumentId, dataIndexKey) => {
          const currentData = get(currentDataIndex, dataIndexKey, {});
          const relatedDataCount = batches[dataIndexKey].length;
          const data = {
            ...currentData,
            relatedDataDocumentId,
            relatedDataCount,
          };
          set(dataIndex, dataIndexKey, data);
        },
      );

      const updatedPhiSet = { ...phiSet, dataIndex, importsRelatedData };
      const auditDocument = diff(phiSet, updatedPhiSet);

      const { documentId, auditDocumentId } = yield call(
        driveService.uploadJson, updatedPhiSet, accessToken, auditDocument
      );

      const updatedRevisions = [{
        previousDocumentId: revisionDocumentId,
        newDocumentId     : documentId,
        referenceKey      : phiSetReferenceKey,
        auditDocumentId,
      }];

      const forksHistory = yield call(commitTransaction, transactionId, updatedRevisions);
      yield call(CloudDriveService.clearForks, forksHistory, storageProvider, accessToken);

      yield put(successAction(updatedPhiSet, documentId, phiSetReferenceKey, result));
      yield put(actions.storeRelatedDataSuccess());
    } catch (err) {
      yield call(closeTransaction, transactionId);
      yield put(actions.storeRelatedDataError(err));
      yield call(App.dispatchError, err, messages);
    }
  } catch (err) {
    yield put(actions.storeRelatedDataError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* storeTimeSeriesResources({ payload }) {
  try {
    const {
      phiSetDocumentId,
      phiSetReferenceKey,
      timeSeriesResources,
      accessToken,
      storageProvider,
      successAction,
      lastImportUTC = +moment().locale('en').format('X'),
    } = payload;
    let { phiSet } = payload;

    if (!accessToken) {
      const err = new LocalError({ code: 'NoAccessToken' });
      yield put(actions.storeTimeSeriesResourcesError(err));
      yield call(App.dispatchError, err, messages);
      return;
    }

    const createTimestamp = +getNowAsUtc().locale('en').format('X');
    const resources = flatten(map(timeSeriesResources, (timeSeriesResource) => timeSeriesResource.resources));

    if (storageProvider === 'CentralStorage') {
      const importData = {
        createTimestamp,
        lastImportUTC,
        resources,
      };

      const { forksHistory } = yield call(
        CentralStorageService.storeTimeSeriesResources, phiSetReferenceKey, importData, accessToken
      );
      yield call(CloudDriveService.clearForks, forksHistory, storageProvider, accessToken);
      if (successAction) {
        yield put(successAction(phiSetReferenceKey, timeSeriesResources));
      }
      yield put(actions.storeTimeSeriesResourcesSuccess());
      return;
    }

    const transaction = yield call(openTransaction, [{
      lockType    : 'Exclusive',
      referenceKey: phiSetReferenceKey,
    }]);
    const { transactionId, revisions } = transaction;
    const revisionDocumentId = get(revisions, [0, 'documentId']);

    // Transaction try
    try {
      if (phiSetDocumentId !== revisionDocumentId) {
        phiSet = yield call(CloudDriveService.fetchPhiSet, revisionDocumentId, storageProvider, accessToken);
      }

      const currentImports = get(phiSet, 'importsTimeSeriesResources', {});

      const driveService = CloudDriveService.getDriveService(storageProvider);

      const importsTimeSeriesResources = {
        ...currentImports,
        snaq: { lastImportUTC },
      };

      const batches = {};

      forEach(resources, (data) => {
        const batchKey = moment.unix(data.dateTime).locale('en').format('YYYY-MM');
        const batch = get(batches, batchKey, []);
        batch.push(data);
        batches[batchKey] = batch;
      });

      const currentDataIndex = get(phiSet, 'dataIndex', {});
      const batchesKeys = keysIn(batches).sort();
      const forkedDataIndexItems = pick(currentDataIndex, batchesKeys);

      const newDocumentsIds = yield call(
        uploadTimeSeriesResources,
        batches, forkedDataIndexItems,
        'timeSeriesResourcesId', 'timestamp',
        driveService, accessToken,
      );

      const dataIndex = { ...currentDataIndex };

      forIn(
        newDocumentsIds,
        (relatedDataDocumentId, dataIndexKey) => {
          const currentData = get(currentDataIndex, dataIndexKey, {});
          const relatedDataCount = batches[dataIndexKey].length;
          const data = {
            ...currentData,
            relatedDataDocumentId,
            relatedDataCount,
          };
          set(dataIndex, dataIndexKey, data);
        },
      );

      const updatedPhiSet = { ...phiSet, dataIndex, importsTimeSeriesResources };
      const auditDocument = diff(phiSet, updatedPhiSet);

      const { documentId, auditDocumentId } = yield call(
        driveService.uploadJson, updatedPhiSet, accessToken, auditDocument
      );

      const updatedRevisions = [{
        previousDocumentId: revisionDocumentId,
        newDocumentId     : documentId,
        referenceKey      : phiSetReferenceKey,
        auditDocumentId,
      }];

      const forksHistory = yield call(commitTransaction, transactionId, updatedRevisions);
      yield call(CloudDriveService.clearForks, forksHistory, storageProvider, accessToken);

      yield put(successAction(phiSetReferenceKey, timeSeriesResources));
      yield put(actions.storeTimeSeriesResourcesSuccess());
    } catch (err) {
      yield call(closeTransaction, transactionId);
      yield put(actions.storeTimeSeriesResourcesError(err));
      yield call(App.dispatchError, err, messages);
    }
  } catch (err) {
    yield put(actions.storeTimeSeriesResourcesError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function transformMeasurements(measurements, phiSet, phisetVisitId, timestamp) {
  const summaryData = { ...get(phiSet, 'summaryData', {}) };
  const batch = [];

  forIn(measurements, (value, measurementName) => {
    const type = get(App.constants.MEASUREMENTS, [measurementName, 'type']);
    if (!type) return;
    const measurement = {
      timestamp,
      value,
      type,
    };
    if (phisetVisitId) {
      measurement.phisetVisitId = phisetVisitId;
    }
    batch.push(measurement);
    if (type === App.constants.MEASUREMENTS.weight.type) {
      set(summaryData, 'lastWeight', value);
    }
    if (type === App.constants.MEASUREMENTS.height.type) {
      set(summaryData, 'lastHeight', value);
    }
  });

  return { batch, summaryData };
}


function* combineMeasurements(batches, forkedDataIndexItems, drive, accessToken) {
  const forkedBatches = yield all(mapValues(
    forkedDataIndexItems,
    (dataIndexItem) => {
      if (!dataIndexItem.measurementsDocumentId) return null;
      return call(drive.downloadFile, dataIndexItem.measurementsDocumentId, accessToken);
    },
  ));

  const combinedBatches = {};

  forEach(batches, (dataIndexItem, batchKey) => {
    const newMeasurements = batches[batchKey];
    if (!forkedBatches[batchKey]) {
      combinedBatches[batchKey] = newMeasurements.sort((a, b) => a.timestamp - b.timestamp);
      return;
    }
    const storedMeasurements = forkedBatches[batchKey];
    const filteredNewMeasurements = [];
    newMeasurements.forEach((newMeasurement) => {
      const storedMeasurementIndex = storedMeasurements.findIndex(
        (sm) => `${sm.type}_${sm.timestamp}` === `${newMeasurement.type}_${newMeasurement.timestamp}`
      );
      if (storedMeasurementIndex < 0) {
        filteredNewMeasurements.push(newMeasurement);
      }
    });
    combinedBatches[batchKey] = [...storedMeasurements, ...filteredNewMeasurements]
      .sort((a, b) => a.timestamp - b.timestamp);
  });
  return combinedBatches;
}


function* storeMeasurementsCloudDrive(batches, phiSet, summaryData, driveService, phisetVisitId, accessToken) {
  const { diabetesType, treatmentType, glucoseLevelTargets, syncSnapshot } = phiSet;
  const currentDataIndex = get(phiSet, 'dataIndex', {});
  const batchesKeys = keysIn(batches).sort();
  const forkedDataIndexItems = pick(currentDataIndex, batchesKeys);
  const now = +getNowAsUtc().locale('en').format('X');

  const combinedBatches = yield call(combineMeasurements, batches, forkedDataIndexItems, driveService, accessToken);
  const newDocumentsIds = yield call(uploadBatches, combinedBatches, driveService, accessToken);
  const dataIndex = { ...currentDataIndex };

  const visits = get(phiSet, 'visits', []);
  const visitIdx = phisetVisitId && visits.length ? findIndex(visits, { phisetVisitId }) : -1;
  const visit = visitIdx >= 0 ? visits[visitIdx] : null;
  if (visit) {
    const associatedMeasurements = sortedUniq([
      ...get(visit, 'associatedDataIndexKeys.measurements', []),
      ...batchesKeys,
    ].sort());
    set(visit, 'associatedDataIndexKeys.measurements', associatedMeasurements);
    visits[visitIdx] = visit;
  }

  forIn(
    newDocumentsIds,
    (measurementsDocumentId, dataIndexKey) => {
      const currentData = get(currentDataIndex, dataIndexKey, {});
      const [year, month] = dataIndexKey.split('-');
      const data = {
        ...currentData,
        measurementsDocumentId,
        lastUpdate: now,
        year      : +year,
        month     : +month,
      };
      set(dataIndex, dataIndexKey, data);
    }
  );

  const updatedPhiSet = {
    ...phiSet, summaryData, dataIndex, diabetesType, treatmentType, glucoseLevelTargets, syncSnapshot,
  };
  const auditDocument = diff(phiSet, updatedPhiSet);
  const result = yield call(driveService.uploadJson, updatedPhiSet, accessToken, auditDocument);
  return {
    updatedPhiSet,
    documentId     : result.documentId,
    auditDocumentId: result.auditDocumentId,
    batchesKeys,
    forkedDataIndexItems,
  };
}


function* storeMeasurements({ payload }) {
  try {
    const {
      measurements,
      phiSetDocumentId,
      phiSetReferenceKey,
      accessToken,
      storageProvider,
      phiSetPatientId,
      phisetVisitId,
      successAction,
    } = payload;
    let { phiSet, timestamp } = payload;
    const { diabetesType, treatmentType, glucoseLevelTargets, syncSnapshot, kpi } = phiSet;
    let previousDocumentId = phiSetDocumentId;

    if (!accessToken) {
      const err = new LocalError({ code: 'NoAccessToken' });
      yield put(actions.storeMeasurementsError(err));
      yield call(App.dispatchError, err, messages);
      return null;
    }

    const transaction = yield call(openTransaction, [{
      lockType    : 'Exclusive',
      referenceKey: phiSetReferenceKey,
    }]);
    const { transactionId, revisions } = transaction;
    const revisionDocumentId = get(revisions, [0, 'documentId']);

    // Transaction try
    try {
      if (revisionDocumentId && phiSetDocumentId !== revisionDocumentId) {
        phiSet = yield call(CloudDriveService.fetchPhiSet, revisionDocumentId, storageProvider, accessToken);
        previousDocumentId = revisionDocumentId;
      }

      if (!timestamp) {
        timestamp = +getNowAsUtc().locale('en').format('X');
      }

      const batches = {};
      const batchKey = moment.unix(timestamp).locale('en').format('YYYY-MM');
      const { batch, summaryData } = yield call(transformMeasurements, measurements, phiSet, phisetVisitId, timestamp);

      if (storageProvider === 'CentralStorage') {
        const { newPhiSetDocumentId, updatedPhiSet, auditDocumentId } = yield call(
          CentralStorageService.storeMeasurements,
          previousDocumentId,
          { ...phiSet, diabetesType, treatmentType, glucoseLevelTargets, syncSnapshot, kpi },
          batch,
          phisetVisitId,
          accessToken,
        );

        let updatedMeasurements = [];
        const measurementsDocumentId = get(updatedPhiSet, ['dataIndex', batchKey, 'measurementsDocumentId']);
        if (measurementsDocumentId) {
          updatedMeasurements = yield call(CentralStorageService.fetchMeasurements, measurementsDocumentId, accessToken);
        }

        const updatedRevisions = [{
          previousDocumentId,
          newDocumentId: newPhiSetDocumentId,
          referenceKey : phiSetReferenceKey,
          auditDocumentId,
        }];
        const forksHistory = yield call(commitTransaction, transactionId, updatedRevisions);
        yield call(CloudDriveService.clearForks, forksHistory, storageProvider, accessToken);
        if (successAction) {
          yield put(successAction(updatedPhiSet, newPhiSetDocumentId, phiSetPatientId, updatedMeasurements, batchKey));
        }
        yield put(actions.storeMeasurementsSuccess(updatedPhiSet, newPhiSetDocumentId));
        return { updatedPhiSet, updatedPhiSetDocumentId: newPhiSetDocumentId };
      }

      if (batch.length) {
        batches[batchKey] = batch;
      }

      const driveService = CloudDriveService.getDriveService(storageProvider);

      const { updatedPhiSet, documentId, auditDocumentId } = yield call(
        storeMeasurementsCloudDrive, batches, phiSet, summaryData, driveService, phisetVisitId, accessToken,
      );

      const updatedRevisions = [{
        previousDocumentId,
        newDocumentId: documentId,
        referenceKey : phiSetReferenceKey,
        auditDocumentId,
      }];

      const forksHistory = yield call(commitTransaction, transactionId, updatedRevisions);
      yield call(CloudDriveService.clearForks, forksHistory, storageProvider, accessToken);

      if (successAction) {
        yield put(successAction(updatedPhiSet, documentId, phiSetPatientId, batch, batchKey));
      }
      yield put(actions.storeMeasurementsSuccess(updatedPhiSet, documentId));
      return { updatedPhiSet, updatedPhiSetDocumentId: documentId };
    } catch (err) {
      yield call(closeTransaction, transactionId);
      yield put(actions.storeMeasurementsError(err));
      const errLocal = new LocalError({ code: 'UpdateMeasurementsFailed' });
      yield call(App.dispatchError, errLocal, messages);
    }
  } catch (err) {
    yield put(actions.storeMeasurementsError(err));
    const errLocal = new LocalError({ code: 'UpdateMeasurementsFailed' });
    yield call(App.dispatchError, errLocal, messages);
  }
  return null;
}

//----------------------------------------------------------------------------------------------------------------------

function* combineNotes(batches, forkedDataIndexItems, driveService, accessToken) {
  const forkedBatches = yield all(mapValues(
    forkedDataIndexItems,
    (dataIndexItem) => {
      if (!dataIndexItem.notesDocumentId) return null;
      return call(driveService.downloadFile, dataIndexItem.notesDocumentId, accessToken);
    },
  ));

  const combinedBatches = {};
  const replies = {};

  forEach(batches, (dataIndexItem, batchKey) => {
    const newNotes = batches[batchKey];
    if (!forkedBatches[batchKey]) {
      combinedBatches[batchKey] = newNotes.sort((a, b) => a.timestamp - b.timestamp);
      return;
    }
    const storedNotes = forkedBatches[batchKey];
    newNotes.forEach((newNote) => {
      const storedNotesIndex = storedNotes.findIndex((n) => n.noteId === newNote.noteId);
      if (storedNotesIndex < 0) {
        storedNotes.push(newNote);
        if (newNote.noteType === 'Note' && newNote.phisetVisitId) {
          replies[newNote.phisetVisitId] = get(replies, newNote.phisetVisitId, 0) + 1;
        }
      } else {
        storedNotes[storedNotesIndex] = newNote;
      }
    });
    combinedBatches[batchKey] = storedNotes.sort((a, b) => a.timestamp - b.timestamp);
  });
  return { combinedBatches, replies };
}


function* storeNotesCloudDrive(notes, phiSet, phisetVisitId, driveService, accessToken) {
  const batches = {};
  const now = +getNowAsUtc().locale('en').format('X');
  const visits = get(phiSet, 'visits', []);

  forEach(notes, (note) => {
    const batchKey = moment.unix(note.timestamp).locale('en').format('YYYY-MM');
    const batch = get(batches, batchKey, []);
    if (!note.noteId) {
      note.noteId = uuid();
    }
    if (phisetVisitId) {
      note.phisetVisitId = phisetVisitId;
    }
    batch.push(note);
    batches[batchKey] = batch;

    if (note.phisetVisitId) {
      const visitIdx = findIndex(visits, { phisetVisitId: note.phisetVisitId });
      if (visitIdx >= 0) {
        const visitAssociatedNotes = get(visits[visitIdx], 'associatedDataIndexKeys.notes', []);
        if (!includes(visitAssociatedNotes, batchKey)) {
          visitAssociatedNotes.push(batchKey);
          visitAssociatedNotes.sort();
          set(visits[visitIdx], 'associatedDataIndexKeys.notes', visitAssociatedNotes);
        }
      }
    }
  });

  const currentDataIndex = get(phiSet, 'dataIndex', {});
  const batchesKeys = keysIn(batches).sort();
  const forkedDataIndexItems = pick(currentDataIndex, batchesKeys);

  const { combinedBatches, replies } = yield call(combineNotes, batches, forkedDataIndexItems, driveService, accessToken);
  const newDocumentsIds = yield call(uploadBatches, combinedBatches, driveService, accessToken);
  const dataIndex = { ...currentDataIndex };

  forIn(
    newDocumentsIds,
    (notesDocumentId, dataIndexKey) => {
      const currentData = get(currentDataIndex, dataIndexKey, {});
      const [year, month] = dataIndexKey.split('-');
      const data = {
        ...currentData,
        notesDocumentId,
        lastUpdate: now,
        year      : +year,
        month     : +month,
      };
      set(dataIndex, dataIndexKey, data);
    }
  );

  if (!isEmpty(replies)) {
    forIn(replies, (count, visitId) => {
      const visitIdx = findIndex(visits, { phisetVisitId: visitId });
      if (visitIdx >= 0 && count > 0) {
        const currentReplies = get(visits[visitIdx], 'replies', 0);
        set(visits[visitIdx], 'replies', currentReplies + count);
      }
    });
  }

  const updatedPhiSet = { ...phiSet, dataIndex };
  const auditDocument = diff(phiSet, updatedPhiSet);

  const { documentId, auditDocumentId } = yield call(
    driveService.uploadJson, updatedPhiSet, accessToken, auditDocument
  );
  return {
    updatedPhiSet,
    newPhiSetDocumentId: documentId,
    auditDocumentId,
    batchesKeys,
    forkedDataIndexItems,
    combinedBatches,
  };
}


function* storeNotes({ payload }) {
  const {
    notes,
    phiSetDocumentId,
    phiSetReferenceKey,
    phisetVisitId,
    storageProvider,
    accessToken,
    successAction,
  } = payload;
  let openTransactionId = null;
  try {
    let { phiSet } = payload;
    let documentId = phiSetDocumentId;

    if (!accessToken) {
      const err = new LocalError({ code: 'NoAccessToken' });
      yield put(actions.storeNotesError(err));
      yield call(App.dispatchError, err, messages);
      return;
    }

    const transaction = yield call(openTransaction, [{
      lockType    : 'Exclusive',
      referenceKey: phiSetReferenceKey,
    }]);
    const { transactionId, revisions } = transaction;
    const revisionDocumentId = get(revisions, [0, 'documentId']);
    openTransactionId = transactionId;

    if (phiSetDocumentId !== revisionDocumentId) {
      phiSet = yield call(CloudDriveService.fetchPhiSet, revisionDocumentId, storageProvider, accessToken);
      documentId = revisionDocumentId;
    }

    if (storageProvider === 'CentralStorage') {
      const { newPhiSetDocumentId, updatedPhiSet, auditDocumentId } = yield call(
        CentralStorageService.storeNotes, phiSetDocumentId, notes, phisetVisitId, accessToken,
      );
      const updatedNotesDocumentIds = [];
      const notesBatchesIndex = [];
      forEach(notes, (note) => {
        const batchKey = moment.unix(note.timestamp).locale('en').format('YYYY-MM');
        const notesDocumentId = get(updatedPhiSet, ['dataIndex', batchKey, 'notesDocumentId']);
        if (notesDocumentId && !includes(updatedNotesDocumentIds, notesDocumentId)) {
          notesBatchesIndex.push(batchKey);
          updatedNotesDocumentIds.push(notesDocumentId);
        }
      });
      const updatedBatches = yield all(mapValues(
        updatedNotesDocumentIds,
        (notesDocumentId) => call(CentralStorageService.fetchNotes, notesDocumentId, accessToken),
      ));
      const updatedNotes = flatten(values(updatedBatches, (ns) => ns));
      const updatedRevisions = [{
        previousDocumentId: documentId,
        newDocumentId     : newPhiSetDocumentId,
        referenceKey      : phiSetReferenceKey,
        auditDocumentId,
      }];
      const forksHistory = yield call(commitTransaction, transactionId, updatedRevisions);
      openTransactionId = null;
      yield call(CloudDriveService.clearForks, forksHistory, storageProvider, accessToken);
      if (successAction) {
        yield put(successAction(updatedPhiSet, newPhiSetDocumentId, updatedNotes, notesBatchesIndex));
      }
      yield put(actions.storeNotesSuccess(updatedPhiSet, newPhiSetDocumentId));
      return;
    }

    const driveService = CloudDriveService.getDriveService(storageProvider);
    const { updatedPhiSet, newPhiSetDocumentId, combinedBatches } = yield call(
      storeNotesCloudDrive, notes, phiSet, phisetVisitId, driveService, accessToken,
    );

    const updatedRevisions = [{
      previousDocumentId: documentId,
      newDocumentId     : newPhiSetDocumentId,
      referenceKey      : phiSetReferenceKey,
    }];
    const forksHistory = yield call(commitTransaction, transactionId, updatedRevisions);
    openTransactionId = null;
    yield call(CloudDriveService.clearForks, forksHistory, storageProvider, accessToken);
    if (successAction) {
      const notesBatchesIndex = keysIn(combinedBatches);
      const combinedNotes = flatten(values(combinedBatches, (rs) => rs));
      yield put(successAction(updatedPhiSet, newPhiSetDocumentId, combinedNotes, notesBatchesIndex));
    }
    yield put(actions.storeNotesSuccess(updatedPhiSet, newPhiSetDocumentId));
  } catch (err) {
    yield put(actions.storeNotesError(err));
    yield call(App.dispatchError, err, messages);
  } finally {
    if (openTransactionId) {
      yield all([
        call(closeTransaction, openTransactionId),
        call(appInsights.trackEvent, 'StoreNotesTransactionCanceled', { phiSetReferenceKey }),
      ]);
    }
  }
}


function* removeNote({ payload }) {
  try {
    const {
      note,
      phiSetDocumentId,
      phiSetReferenceKey,
      storageProvider,
      accessToken,
      successAction,
    } = payload;
    let { phiSet } = payload;
    let previousDocumentId = phiSetDocumentId;

    if (!accessToken) {
      const err = new LocalError({ code: 'NoAccessToken' });
      yield put(actions.removeNoteError(err));
      yield call(App.dispatchError, err, messages);
      return;
    }

    const transaction = yield call(openTransaction, [{
      lockType    : 'Exclusive',
      referenceKey: phiSetReferenceKey,
    }]);
    const { transactionId, revisions } = transaction;
    const revisionDocumentId = get(revisions, [0, 'documentId']);
    // Transaction try
    try {
      if (phiSetDocumentId !== revisionDocumentId) {
        phiSet = yield call(CloudDriveService.fetchPhiSet, revisionDocumentId, storageProvider, accessToken);
        previousDocumentId = revisionDocumentId;
      }

      const batchKey = moment.unix(note.timestamp).locale('en').format('YYYY-MM');
      const notesDocumentId = get(phiSet, ['dataIndex', batchKey, 'notesDocumentId']);
      if (!notesDocumentId) {
        const err = new LocalError({ code: 'NoDocumentId' });
        yield call(closeTransaction, transactionId);
        yield put(actions.removeNoteError(err));
        yield call(App.dispatchError, err, messages);
        return;
      }

      if (storageProvider === 'CentralStorage') {
        const { newPhiSetDocumentId, updatedPhiSet, auditDocumentId } = yield call(
          CentralStorageService.removeNote, phiSetDocumentId, notesDocumentId, note, accessToken,
        );
        const newNotesDocumentId = get(updatedPhiSet, ['dataIndex', batchKey, 'notesDocumentId']);
        const updatedBatch = newNotesDocumentId
          ? yield call(CentralStorageService.fetchNotes, newNotesDocumentId, accessToken)
          : [];

        const updatedRevisions = [{
          previousDocumentId,
          newDocumentId: newPhiSetDocumentId,
          referenceKey : phiSetReferenceKey,
          auditDocumentId,
        }];
        const forksHistory = yield call(commitTransaction, transactionId, updatedRevisions);
        yield call(CloudDriveService.clearForks, forksHistory, storageProvider, accessToken);

        if (successAction) {
          yield put(successAction(updatedPhiSet, newPhiSetDocumentId, updatedBatch, [batchKey]));
        }
        yield put(actions.removeNoteSuccess(updatedPhiSet, newPhiSetDocumentId));
        return;
      }

      const driveService = CloudDriveService.getDriveService(storageProvider);
      const batch = yield call(driveService.downloadFile, notesDocumentId, accessToken);
      const updatedBatch = filter(batch, (n) => n.noteId !== note.noteId);
      const updatedPhiSet = { ...phiSet };

      if (note.phisetVisitId) {
        const { phisetVisitId } = note;
        const visitNotes = filter(updatedBatch, { phisetVisitId });
        if (!visitNotes.length) {
          const visits = get(updatedPhiSet, 'visits', []);
          const visitIdx = findIndex(visits, { phisetVisitId });
          if (visitIdx >= 0) {
            const visitAssociatedNotes = filter(
              get(visits[visitIdx], 'associatedDataIndexKeys.notes', []),
              (b) => b !== batchKey,
            );
            set(visits[visitIdx], 'associatedDataIndexKeys.notes', visitAssociatedNotes);
          }
        }
      }

      if (updatedBatch.length) {
        const { documentId: newNotesDocumentId } = yield call(driveService.uploadJson, updatedBatch, accessToken);
        set(updatedPhiSet, ['dataIndex', batchKey, 'notesDocumentId'], newNotesDocumentId);
      } else {
        unset(updatedPhiSet, ['dataIndex', batchKey, 'notesDocumentId']);
      }

      const auditDocument = diff(phiSet, updatedPhiSet);

      const { documentId, auditDocumentId } = yield call(
        driveService.uploadJson, updatedPhiSet, accessToken, auditDocument
      );

      const updatedRevisions = [{
        previousDocumentId,
        newDocumentId: documentId,
        referenceKey : phiSetReferenceKey,
        auditDocumentId,
      }];
      const forksHistory = yield call(commitTransaction, transactionId, updatedRevisions);
      yield call(CloudDriveService.clearForks, forksHistory, storageProvider, accessToken);

      if (successAction) {
        yield put(successAction(updatedPhiSet, documentId, updatedBatch, [batchKey]));
      }
      yield put(actions.removeNoteSuccess(updatedPhiSet, documentId));
    } catch (err) {
      yield call(closeTransaction, transactionId);
      yield put(actions.removeNoteError(err));
      yield call(App.dispatchError, err, messages);
    }
  } catch (err) {
    yield put(actions.removeNoteError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* removeNotesQueue() {
  const buffer = buffers.expanding(10);
  const channel = yield actionChannel(actionTypes.REMOVE_NOTE, buffer);
  while (true) {
    const [channelAction, stopAction] = yield race([take(channel), take(actionTypes.STOP_REMOVE_NOTES_QUEUE)]);
    if (stopAction) {
      break;
    }
    yield call(removeNote, channelAction);
    if (buffer.isEmpty()) {
      yield put(actions.removeNotesQueueFinished());
    }
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* storeVisitCloudDrive(visit, phiSet, driveService, accessToken) {
  const visitIndex = pick(visit, ['phisetVisitId', 'encryptedVisitMetadataId']);
  const associatedDataIndexKeys = get(visit, 'associatedDataIndexKeys', {});
  const { phisetVisitId } = visit;
  const { measurements, notes, timestamp } = visit;

  const batchKey = moment.unix(timestamp).locale('en').format('YYYY-MM');
  const forkedDataIndexItems = [];

  if (!isEmpty(measurements)) {
    const measurementsBatches = {};
    const { batch: measurementsBatch, summaryData } = yield call(
      transformMeasurements, measurements, phiSet, phisetVisitId, timestamp);
    if (measurementsBatch.length) {
      measurementsBatches[batchKey] = measurementsBatch;
    }
    const { updatedPhiSet, batchesKeys, forkedDataIndexItems: measurementForkedItems } = yield call(
      storeMeasurementsCloudDrive, measurementsBatches, phiSet, summaryData, driveService, phisetVisitId, accessToken,
    );
    associatedDataIndexKeys.measurements = union(get(associatedDataIndexKeys, 'measurements', []), batchesKeys);
    forkedDataIndexItems.push({
      documentIdKey: 'measurementsDocumentId',
      forkedItems  : measurementForkedItems,
    });
    phiSet = updatedPhiSet;
  }

  if (!isEmpty(notes)) {
    const { updatedPhiSet, batchesKeys, forkedDataIndexItems: notesForkedItems } = yield call(
      storeNotesCloudDrive, notes, phiSet, phisetVisitId, driveService, accessToken,
    );
    associatedDataIndexKeys.notes = union(get(associatedDataIndexKeys, 'notes', []), batchesKeys);
    forkedDataIndexItems.push({
      documentIdKey: 'notesDocumentId',
      forkedItems  : notesForkedItems,
    });
    phiSet = updatedPhiSet;
  }

  visitIndex.associatedDataIndexKeys = associatedDataIndexKeys;
  const visits = get(phiSet, 'visits', []);
  const visitIndexIdx = findIndex(visits, { phisetVisitId });
  if (visitIndexIdx < 0) {
    visits.push(visitIndex);
  } else {
    visits[visitIndexIdx] = visitIndex;
  }
  const updatedPhiSet = {
    ...phiSet,
    visits,
  };
  const auditDocument = diff(phiSet, updatedPhiSet);

  const { documentId, auditDocumentId } = yield call(
    driveService.uploadJson, updatedPhiSet, accessToken, auditDocument
  );

  return { newPhiSetDocumentId: documentId, auditDocumentId, updatedPhiSet, forkedDataIndexItems };
}


function* storeVisit({ payload }) {
  try {
    const {
      visit,
      phiSetDocumentId,
      phiSetReferenceKey,
      phiSetPatientId,
      storageProvider,
      accessToken,
      successAction,
    } = payload;

    if (!accessToken) {
      const err = new LocalError({ code: 'NoAccessToken' });
      yield put(actions.storeVisitError(err));
      yield call(App.dispatchError, err, messages);
      return;
    }

    const { phiSet, documentId, transactionId } = yield call(
      openPhiSetTransaction, phiSetReferenceKey, phiSetDocumentId, payload.phiSet, storageProvider, accessToken
    );

    // Transaction try
    try {
      if (storageProvider === 'CentralStorage') {
        const transformedMeasurements = [];
        forIn(visit.measurements, (value, measurementName) => {
          const type = get(App.constants.MEASUREMENTS, [measurementName, 'type']);
          if (!type) return;
          const measurement = {
            timestamp: visit.timestamp,
            value,
            type,
          };
          transformedMeasurements.push(measurement);
        });
        visit.measurements = transformedMeasurements;
        const { newPhiSetDocumentId, updatedPhiSet, auditDocumentId } = yield call(
          CentralStorageService.storeVisit, documentId, visit, accessToken,
        );
        const forksHistory = yield call(
          closePhiSetTransaction, transactionId, phiSetReferenceKey, documentId, newPhiSetDocumentId, auditDocumentId
        );
        yield call(CloudDriveService.clearForks, forksHistory, storageProvider, accessToken);
        if (successAction) {
          yield put(successAction(updatedPhiSet, newPhiSetDocumentId, phiSetPatientId));
        }
        yield put(actions.storeVisitSuccess(updatedPhiSet, newPhiSetDocumentId));
        return;
      }

      const driveService = CloudDriveService.getDriveService(storageProvider);
      const { newPhiSetDocumentId, updatedPhiSet } = yield call(
        storeVisitCloudDrive, visit, phiSet, driveService, accessToken,
      );
      const forksHistory = yield call(
        closePhiSetTransaction, transactionId, phiSetReferenceKey, documentId, newPhiSetDocumentId
      );
      yield call(CloudDriveService.clearForks, forksHistory, storageProvider, accessToken);
      if (successAction) {
        yield put(successAction(updatedPhiSet, newPhiSetDocumentId, phiSetPatientId));
      }
      yield put(actions.storeVisitSuccess(updatedPhiSet, newPhiSetDocumentId));
    } catch (err) {
      yield call(closeTransaction, transactionId);
      yield put(actions.storeVisitError(err));
      yield call(App.dispatchError, err, messages);
    }

  } catch (err) {
    yield put(actions.storeVisitError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* updatePhiSet({ payload }) {
  const { entries, phiSetDocumentId, phiSetReferenceKey, accessToken, storageProvider, successAction } = payload;
  let { phiSet } = payload;
  let documentId = phiSetDocumentId;

  try {
    if (!accessToken) {
      const err = new LocalError({ code: 'NoAccessToken' });
      yield put(actions.updatePhiSetError(err));
      yield call(App.dispatchError, err, messages);
      return;
    }

    let updatedPhiSet = { ...phiSet, ...entries };

    if (successAction) {
      yield put(successAction(updatedPhiSet, phiSetDocumentId));
    }

    const transaction = yield call(openTransaction, [{
      lockType    : 'Exclusive',
      referenceKey: phiSetReferenceKey,
    }]);
    const { transactionId, revisions } = transaction;
    const revisionDocumentId = get(revisions, [0, 'documentId']);

    // Transaction try
    try {
      if (phiSetDocumentId !== revisionDocumentId) {
        phiSet = yield call(CloudDriveService.fetchPhiSet, revisionDocumentId, storageProvider, accessToken);
        updatedPhiSet = { ...phiSet, ...entries };
        documentId = revisionDocumentId;
      }

      let driveService = null;
      let newPhiSetDocumentId;
      let auditDocumentId;
      if (storageProvider === 'CentralStorage') {
        const result = yield call(
          CentralStorageService.storeDetails, documentId, updatedPhiSet, accessToken,
        );
        newPhiSetDocumentId = result.newPhiSetDocumentId;
        auditDocumentId = result.auditDocumentId;
      } else {
        driveService = CloudDriveService.getDriveService(storageProvider);
        const auditDocument = diff(phiSet, updatedPhiSet);
        const result = yield call(driveService.uploadJson, updatedPhiSet, accessToken, auditDocument);
        newPhiSetDocumentId = result.documentId;
        auditDocumentId = result.auditDocumentId;
      }

      const updatedRevisions = [{
        previousDocumentId: documentId,
        newDocumentId     : newPhiSetDocumentId,
        referenceKey      : phiSetReferenceKey,
        auditDocumentId,
      }];
      const forksHistory = yield call(commitTransaction, transactionId, updatedRevisions);
      yield call(CloudDriveService.clearForks, forksHistory, storageProvider, accessToken);

      if (successAction) {
        yield put(successAction(updatedPhiSet, newPhiSetDocumentId));
      }
      yield put(actions.updatePhiSetSuccess());
    } catch (err) {
      yield call(closeTransaction, transactionId);
      if (successAction) {
        yield put(successAction(phiSet, phiSetDocumentId));
      }
      yield put(actions.updatePhiSetError(err));
    }
  } catch (err) {
    if (successAction) {
      yield put(successAction(phiSet, phiSetDocumentId));
    }
    yield put(actions.updatePhiSetError(err));
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* fetchPatientAccessToken(patientExchangeToken, sharingRequestId) {
  try {
    return yield call(StorageExchangeTokenService.fetchAccessToken, patientExchangeToken);
  } catch (err) {
    yield call(NotificationsService.sendSharingRequestAccessRevokedNotification, { sharingRequestId });
    throw new LocalError({
      code: 'SharingRequestCloudDriveConnectionFailed',
    });
  }
}


function* sync({ payload }) {
  try {
    const {
      activePatient,
      phiSet,
      phiSetDocumentId,
      phiSetReferenceKey,
      phiSetPatientId,
      sharingRequest,
      activeClinicMembership,
      standards,
      createPhiSetAction,
      successAction,
      notesSuccessAction,
      timeSeriesResourcesSuccessAction,
    } = payload;

    const {
      sharingRequestId,
      patientPhiSetReferenceKey,
      patientExchangeToken,
      storageProvider: patientStorageProvider,
    } = sharingRequest;

    const deviceSerialNumberToken = 'sync'; // @TODO: Exchange serialNumber to token
    const currentImports = get(phiSet, 'imports', {});
    const combinedLastMaxResultsDates = getCombinedLastMaxResultsDates(currentImports);

    const transaction = yield call(openTransaction, [{
      lockType    : 'Shared',
      referenceKey: patientPhiSetReferenceKey,
    }]);
    const { transactionId } = transaction;

    const patientAccessToken = yield call(fetchPatientAccessToken, patientExchangeToken, sharingRequestId);

    const patientPhiSet = yield call(fetchPhiSet, {
      payload: {
        patient: {
          phiSetReferenceKey: patientPhiSetReferenceKey,
          accessToken       : patientAccessToken,
          storageProvider   : patientStorageProvider,
        },
      },
    });
    yield call(closeTransaction, transactionId);

    const syncHealthData = pick(patientPhiSet, constants.SYNC_HEALTH_DATA);
    const patientDataIndex = get(patientPhiSet, 'dataIndex');
    const patientCurrentImports = get(patientPhiSet, 'imports', {});
    const newPatientImports = [];

    forIn(patientCurrentImports, (imp, importDevice) => {
      const documents = get(imp, 'documents', []);
      forEach(documents, (doc) => {
        if (doc.readingsCount) {
          const devices = doc.devices || [importDevice];
          forEach(devices, (device) => {
            const lastMaxResultsDate = get(combinedLastMaxResultsDates, device, 0);
            if (
              (!lastMaxResultsDate || lastMaxResultsDate < doc.maxResultDate)
                && !includes(newPatientImports, doc.importDocumentId)
            ) {
              newPatientImports.push(doc.importDocumentId);
            }
          });
        }
      });
    });

    const readingsDocumentIds = {};

    if (newPatientImports.length) {
      forIn(patientDataIndex, (batch, batchKey) => {
        const importsDocumentsIds = get(batch, 'importsDocumentsIds', []);
        const matches = intersection(newPatientImports, importsDocumentsIds);
        if (matches.length) {
          readingsDocumentIds[batchKey] = batch.readingsDocumentId;
        }
      });
    }

    // SNAQ
    const patientTimeSeriesResources = [];
    forIn(patientDataIndex, (batch, batchKey) => {
      const { timeSeriesResourceId, timeSeriesResourcesCount } = batch;
      const clinicTimeSeriesResourcesCount = get(phiSet, `dataIndex[${batchKey}].timeSeriesResourcesCount`, 0);
      if (clinicTimeSeriesResourcesCount !== timeSeriesResourcesCount && timeSeriesResourceId) {
        patientTimeSeriesResources.push(timeSeriesResourceId);
      }
    });

    // VISITS & NOTES
    const patientVisits = get(patientPhiSet, 'visits', []);
    const visits = get(phiSet, 'visits', []);
    const patientVisitsToSync = {};
    const notesBatchesToSync = new Set();
    const visitsLength = visits.length;
    if (patientVisits.length && visitsLength) {
      forEach(visits, (visit) => {
        const { phisetVisitId } = visit;
        const patientVisit = find(patientVisits, { phisetVisitId });
        const visitReplies = get(visit, 'replies', 0);
        const patientVisitReplies = get(patientVisit, 'replies', 0);
        if (patientVisitReplies > visitReplies) {
          const noteLastBatchKey = last(get(patientVisit, 'associatedDataIndexKeys.notes', []));
          patientVisitsToSync[phisetVisitId] = noteLastBatchKey;
          notesBatchesToSync.add(noteLastBatchKey);
        }
      });
    }

    const notesDocumentIds = {};
    notesBatchesToSync.forEach((batchKey) => {
      const notesDocumentId = get(patientPhiSet, ['dataIndex', batchKey, 'notesDocumentId']);
      if (notesDocumentId) notesDocumentIds[batchKey] = notesDocumentId;
    });

    const { accessToken, clinic } = activeClinicMembership;
    const { storageProvider } = clinic;

    let update;
    if (!phiSet) {
      const pubKeyPem = get(activeClinicMembership, 'clinic.publicKey');
      const pubKeyObj = getKeyFromPem(pubKeyPem);
      const phiSetNewEntries = pick(patientPhiSet, ['diabetesType', 'treatmentType']);
      phiSetNewEntries.encryptedStatisticalPersonalityId = encrypt(uuid(), pubKeyObj);
      const { summaryData } = patientPhiSet;
      const measurements = { weight: summaryData.lastWeight, height: summaryData.lastHeight };
      update = yield call(storeMeasurements, {
        payload: {
          measurements, phiSet: phiSetNewEntries, phiSetDocumentId, phiSetReferenceKey, accessToken, storageProvider,
        },
      });
      yield put(createPhiSetAction(update.updatedPhiSet, update.updatedPhiSetDocumentId, phiSetPatientId));
    } else {
      update = { updatedPhiSet: { ...phiSet }, updatedPhiSetDocumentId: phiSetDocumentId };
    }

    const { updatedPhiSet, updatedPhiSetDocumentId } = update;

    if (isEmpty(readingsDocumentIds) && isEmpty(notesDocumentIds) && isEmpty(patientTimeSeriesResources)) {
      yield put(actions.syncSuccess(phiSet, phiSetDocumentId, phiSetPatientId, syncHealthData));
      return;
    }

    let download;
    if (patientStorageProvider === 'CentralStorage') {
      download = CentralStorageService.fetchReadings;
    } else {
      const driveService = CloudDriveService.getDriveService(patientStorageProvider);
      download = driveService.downloadFile;
    }
    const readingsBatches = yield all(mapValues(
      readingsDocumentIds,
      (documentId) => call(download, documentId, patientAccessToken),
    ));
    const readings = flatten(values(readingsBatches, (rs) => rs));
    const readingsCount = readings.length;
    const now = getNowAsUtc();

    const deviceImportData = {
      connectionType: 'Sync',
      deviceType    : 'Sync',
      deviceMode    : 'None',
      deviceName    : 'Sync',
      deviceDate    : now.locale('en').format('YYYY-MM-DDTHH:mm:ss'),
      deviceSerialNumberToken,
      readingsCount,
      readings,
    };

    yield put(actions.storeReadings(
      deviceImportData,
      updatedPhiSet,
      updatedPhiSetDocumentId,
      { phiSetReferenceKey, storageProvider, accessToken },
      successAction,
    ));
    const storeResult = yield take([
      actionTypes.STORE_READINGS_SUCCESS,
      actionTypes.STORE_READINGS_ERROR,
    ]);
    if (storeResult.type === actionTypes.STORE_READINGS_ERROR) {
      return;
    }
    let storedPhiSet = get(storeResult, 'payload.updatedPhiSet');
    let storedPhiSetDocumentId = get(storeResult, 'payload.phiSetDocumentId');
    yield put(Statistics.actions.sendStatisticsForClinic(
      activePatient,
      storedPhiSet,
      deviceImportData,
      standards,
      activeClinicMembership,
    ));

    let downloadNotes;
    if (patientStorageProvider === 'CentralStorage') {
      downloadNotes = CentralStorageService.fetchNotes;
    } else {
      downloadNotes = download;
    }
    const patientNotesBatches = yield all(mapValues(
      notesDocumentIds,
      (documentId) => call(downloadNotes, documentId, patientAccessToken),
    ));

    if (!isEmpty(patientVisitsToSync)) {
      let notesToSync = [];
      forIn(patientVisitsToSync, (batchKey, phisetVisitId) => {
        const toSync = filter(patientNotesBatches[batchKey], { phisetVisitId, noteType: 'Note' });
        notesToSync = [...notesToSync, ...toSync];
      });

      yield put(actions.storeNotes(
        notesToSync,
        storedPhiSet,
        storedPhiSetDocumentId,
        null,
        { phiSetReferenceKey, storageProvider, accessToken },
        notesSuccessAction,
      ));

      const storeNotesResult = yield take([
        actionTypes.STORE_NOTES_SUCCESS,
        actionTypes.STORE_NOTES_ERROR,
      ]);

      storedPhiSet = get(storeNotesResult, 'payload.phiSet');
      storedPhiSetDocumentId = get(storeNotesResult, 'payload.phiSetDocumentId');
    }

    if (!isEmpty(patientTimeSeriesResources)) {
      let timeSeriesResources;
      if (patientStorageProvider === 'CentralStorage') {
        timeSeriesResources = yield all(map(patientTimeSeriesResources, (timeSeriesResourceId) => (
          call(CentralStorageService.fetchTimeSeriesResources, timeSeriesResourceId, patientAccessToken)
        )));
      } else {
        const driveService = CloudDriveService.getDriveService(patientStorageProvider);
        timeSeriesResources = yield all(map(patientTimeSeriesResources, (timeSeriesResourceId) => (
          call(driveService.downloadFile, timeSeriesResourceId)
        )));
      }

      yield put(actions.storeTimeSeriesResources(
        timeSeriesResources,
        phiSet,
        phiSetDocumentId,
        { phiSetReferenceKey, storageProvider, accessToken },
        timeSeriesResourcesSuccessAction,
      ));
      const storeTimeSeriesResourcesResult = yield take([
        actionTypes.STORE_TIME_SERIES_RESOURCES_SUCCESS,
        actionTypes.STORE_TIME_SERIES_RESOURCES_ERROR,
      ]);

      storedPhiSet = get(storeTimeSeriesResourcesResult, 'payload.phiSet');
      storedPhiSetDocumentId = get(storeTimeSeriesResourcesResult, 'payload.phiSetDocumentId');
    }

    yield put(actions.syncSuccess(storedPhiSet, storedPhiSetDocumentId, phiSetPatientId, syncHealthData));
  } catch (err) {
    yield put(actions.syncError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* reCryptVisitMetadataId(visit, prvKeyObj, pubKeyObj) {
  const visitMetadataId = yield call(decrypt, visit.encryptedVisitMetadataId, prvKeyObj);
  return yield call(encrypt, visitMetadataId, pubKeyObj);
}


function* pushNotes({ payload }) {
  try {
    const { notes, phisetVisitId, sharingRequest } = payload;
    const {
      sharingRequestId,
      patientPhiSetReferenceKey,
      patientExchangeToken,
      storageProvider: patientStorageProvider,
    } = sharingRequest;

    const patientAccessToken = yield call(fetchPatientAccessToken, patientExchangeToken, sharingRequestId);
    const patientDocumentId = yield call(
      fetchDocumentId, patientPhiSetReferenceKey, patientStorageProvider, patientAccessToken,
    );
    const patientPhiSet = yield call(
      CloudDriveService.fetchPhiSet, patientDocumentId, patientStorageProvider, patientAccessToken,
    );

    yield call(storeNotes, {
      payload: {
        notes,
        phiSet            : patientPhiSet,
        phiSetDocumentId  : patientDocumentId,
        phiSetReferenceKey: patientPhiSetReferenceKey,
        phisetVisitId,
        storageProvider   : patientStorageProvider,
        accessToken       : patientAccessToken,
      },
    });

    yield put(actions.pushNotesSuccess());
  } catch (err) {
    yield put(actions.pushNotesError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* pushVisit({ payload }) {
  try {
    const { phiSet, sharingRequest, clinicMembership } = payload;
    const {
      sharingRequestId,
      patient,
      patientPhiSetReferenceKey,
      patientExchangeToken,
      storageProvider: patientStorageProvider,
    } = sharingRequest;

    const patientPubKey = get(patient, 'publicKey');
    const patientPubKeyObj = getKeyFromPem(patientPubKey);

    const { accessToken, clinic, encryptedPrivateKey, passphrase } = clinicMembership;
    const { storageProvider } = clinic;
    const prvKeyObj = getKeyFromPem(encryptedPrivateKey, passphrase);

    const patientAccessToken = yield call(fetchPatientAccessToken, patientExchangeToken, sharingRequestId);

    let patientDocumentId = yield call(
      fetchDocumentId, patientPhiSetReferenceKey, patientStorageProvider, patientAccessToken,
    );

    let patientPhiSet = yield call(
      CloudDriveService.fetchPhiSet, patientDocumentId, patientStorageProvider, patientAccessToken,
    );

    const visits = takeRight(get(phiSet, 'visits', []), 5);
    const patientVisits = get(patientPhiSet, 'visits', []);
    const diffVisits = differenceBy(visits, patientVisits, 'phisetVisitId');
    const notesFilesCalls = {};
    const visitMetadataIdsCalls = [];

    let download;
    if (storageProvider === 'CentralStorage') {
      download = CentralStorageService.fetchNotes;
    } else {
      const driveService = CloudDriveService.getDriveService(storageProvider);
      download = driveService.downloadFile;
    }

    for (let i = 0; i < diffVisits.length; i++) {
      const associatedNotesBatches = get(diffVisits[i], 'associatedDataIndexKeys.notes');
      visitMetadataIdsCalls.push(call(reCryptVisitMetadataId, diffVisits[i], prvKeyObj, patientPubKeyObj));
      if (associatedNotesBatches && associatedNotesBatches.length) {
        forEach(associatedNotesBatches, (batch) => {
          const notesDocumentId = get(phiSet, ['dataIndex', batch, 'notesDocumentId']);
          notesFilesCalls[batch] = call(download, notesDocumentId, accessToken);
        });
      }
    }

    const [notesFiles, encryptedVisitMetadataIds] = yield all([
      yield all(notesFilesCalls),
      yield all(visitMetadataIdsCalls),
    ]);

    for (let i = 0; i < diffVisits.length; i++) {
      const { phisetVisitId } = diffVisits[i];
      const patientVisit = {
        phisetVisitId,
        encryptedVisitMetadataId: encryptedVisitMetadataIds[i],
      };
      const associatedNotesBatches = get(diffVisits[i], 'associatedDataIndexKeys.notes');
      if (associatedNotesBatches && associatedNotesBatches.length) {
        patientVisit.notes = flatten(map(
          associatedNotesBatches,
          (batch) => filter(
            get(notesFiles, batch, []),
            (note) => note.phisetVisitId === phisetVisitId && note.noteType !== 'Comment',
          )
        ));
        if (!patientVisit.notes.length) {
          unset(diffVisits[i], 'associatedDataIndexKeys.notes');
        }
      }

      yield put(actions.storeVisit(
        patientVisit,
        patientPhiSet,
        patientDocumentId,
        {
          phiSetReferenceKey: patientPhiSetReferenceKey,
          storageProvider   : patientStorageProvider,
          accessToken       : patientAccessToken,
        },
      ));

      const storeVisitResult = yield take([
        actionTypes.STORE_VISIT_SUCCESS,
        actionTypes.STORE_VISIT_ERROR,
      ]);
      if (storeVisitResult.type === actionTypes.STORE_VISIT_ERROR) {
        const error = new LocalError({ code: 'StoreVisitError' });
        yield put(actions.pushVisitError(error));
      } else {
        const { updatedPhiSet, phiSetDocumentId: updatedPhiSetDocumentId } = storeVisitResult.payload;
        patientPhiSet = updatedPhiSet;
        patientDocumentId = updatedPhiSetDocumentId;
      }
    }

    yield put(actions.pushVisitSuccess());
  } catch (err) {
    yield put(actions.pushVisitError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* fetchDumpWorker(payload) {
  try {
    const { phiSet, patient, successAction } = payload;
    const { accessToken, storageProvider } = patient || {};
    let dump;

    if (storageProvider === 'CentralStorage') {
      const requestUrl = `/api/Storage/phiSet/${phiSet.id}/dump`;
      dump = yield call(ApiService.regionalRequest, requestUrl, {
        headers: {
          Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
        },
      });
    } else {
      const driveService = CloudDriveService.getDriveService(storageProvider);
      const dataIndex = get(phiSet, 'dataIndex', {});
      const readingsMonthlyBatches = [];
      const cgmReadingsMonthlyBatches = [];
      const measurementsMonthlyBatches = [];
      let imports = [];

      const dataIndexEntries = values(dataIndex);
      for (let i = 0; i < dataIndexEntries.length; i++) {
        const entry = dataIndexEntries[i];
        const { readingsDocumentId, cgmReadingsDocumentId, measurementsDocumentId, importsDocumentsIds } = entry;
        const [readings, cgmReadings, measurements, batchImports] = yield all([
          readingsDocumentId ? call(driveService.downloadFile, readingsDocumentId, accessToken) : null,
          cgmReadingsDocumentId ? call(driveService.downloadFile, cgmReadingsDocumentId, accessToken) : null,
          measurementsDocumentId ? call(driveService.downloadFile, measurementsDocumentId, accessToken) : null,
          importsDocumentsIds && importsDocumentsIds.length
            ? all(importsDocumentsIds.map(
              (importDocumentId) => call(driveService.downloadFile, importDocumentId, accessToken)
            ))
            : null,
        ]);
        if (readings) readingsMonthlyBatches.push({ dataPoints: readings, id: readingsDocumentId });
        if (cgmReadings) cgmReadingsMonthlyBatches.push({ dataPoints: cgmReadings, id: readingsDocumentId });
        if (measurements) measurementsMonthlyBatches.push({ measurements, id: measurementsDocumentId });
        if (batchImports && batchImports.length) imports = concat(imports, batchImports);
      }

      dump = {
        readingsMonthlyBatches,
        cgmReadingsMonthlyBatches,
        measurementsMonthlyBatches,
        imports,
      };
    }

    if (successAction) {
      yield put(successAction(dump));
    }
    yield put(actions.fetchDumpSuccess());
  } catch (err) {
    yield put(actions.fetchDumpError(err));
  }
}


function* fetchDump({ payload }) {
  yield race([
    call(fetchDumpWorker, payload),
    take(actionTypes.CANCEL_FETCH_DUMP),
  ]);
}


function* sagas() {
  yield takeLatest(actionTypes.AUTHORIZE, authorize);
  yield takeLatest(actionTypes.AUTHORIZE_CHECK, authorizeCheck);
  yield takeLatest(actionTypes.GET_PROFILE, getProfile);
  yield takeLatest(actionTypes.FETCH_PHI_SET, fetchPhiSet);
  yield takeLatest(actionTypes.FETCH_READINGS, fetchReadings);
  yield takeLatest(actionTypes.FETCH_AGGREGATES, fetchAggregates);
  yield takeLatest(actionTypes.FETCH_NOTES, fetchNotes);
  yield takeLatest(actionTypes.STORE_READINGS, storeReadings);
  yield takeLatest(actionTypes.STORE_CGM_READINGS, storeCgmReadings);
  yield takeLatest(actionTypes.STORE_MEASUREMENTS, storeMeasurements);
  yield takeLatest(actionTypes.START_REMOVE_NOTES_QUEUE, removeNotesQueue);
  yield takeLatest(actionTypes.STORE_NOTES, storeNotes);
  yield takeLatest(actionTypes.STORE_VISIT, storeVisit);
  yield takeLatest(actionTypes.UPDATE_PHI_SET, updatePhiSet);
  yield takeLatest(actionTypes.SYNC, sync);
  yield takeEvery(actionTypes.PUSH_NOTES, pushNotes);
  yield takeLatest(actionTypes.PUSH_VISIT, pushVisit);
  yield takeLatest(actionTypes.FETCH_DUMP, fetchDump);
  yield takeLatest(actionTypes.STORE_RELATED_DATA, storeRelatedData);
  yield takeLatest(actionTypes.STORE_TIME_SERIES_RESOURCES, storeTimeSeriesResources);
}


export default [
  sagas,
];
