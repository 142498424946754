import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import map from 'lodash/map';
import { AppContext } from 'context';
import { getSlug } from 'helpers/urlTools';
import Avatar from 'components/Avatar';
import Link from 'components/Link';
import App from 'modules/App';
import * as selectors from '../../../selectors';
import * as shapes from '../../../shapes';
import messages from '../../../messages';
import styles from '../AccountSettings.pcss';


class ClinicsMemberships extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Implicit props
    hcpClinicMemberships: PropTypes.arrayOf(shapes.clinicMembership),
    openDropdownId      : PropTypes.string,
    // Implicit actions
    onOpenDropdown      : PropTypes.func,
    onCloseDropdown     : PropTypes.func,
  };


  renderLogo(clinic) {
    const { logo, name } = clinic;

    return (
      <Avatar
        avatarImg={logo}
        name={name}
        className={styles.avatar}
        imgClassName={styles.avatar__img}
        initialsClassName={styles.avatar__initials}
      />
    );
  }


  renderAdminStatus(clinicMembership) {
    if (!clinicMembership.isAdmin) {
      return null;
    }
    const clinic = get(clinicMembership, 'clinic', {});
    const { organizationUID, name } = clinic;
    return (
      <Link to={this.context.getUrl('clinic-settings', { clinicSlug: getSlug(name), organizationUID })}>
        <FormattedMessage {...messages.labels.isAdmin} />
      </Link>
    );
  }


  renderStatus(clinicMembership) {
    const message = messages.statuses[clinicMembership.membershipStatus];
    if (!message) {
      return null;
    }
    return (
      <FormattedMessage {...messages.statuses[clinicMembership.membershipStatus]} />
    );
  }


  renderClinicMembership(clinicMembership) {
    const clinic = get(clinicMembership, 'clinic', {});
    return (
      <tr key={clinicMembership.clinicHcpMembershipId}>
        <td className="cell--avatar">{ this.renderLogo(clinic) }</td>
        <td>{ clinic.name }</td>
        <td>{ clinicMembership.hcpBusinessEmail }</td>
        <td>{ clinic.storageProvider }</td>
        <td>{ this.renderStatus(clinicMembership) }</td>
        <td>{ this.renderAdminStatus(clinicMembership) }</td>
      </tr>
    );
  }


  render() {
    const { hcpClinicMemberships } = this.props;
    if (!hcpClinicMemberships.length) {
      return null;
    }

    return (
      <div>
        <h2 className="section__header"><FormattedMessage {...messages.headers.clinicsMemberships} /></h2>
        <div className="section__table-container">
          <table className="section__table">
            <thead className="section__table__head">
              <tr>
                <th colSpan={2}><FormattedMessage {...messages.labels.clinic} /></th>
                <th><FormattedMessage {...messages.labels.email} /></th>
                <th>Storage</th>
                <th><FormattedMessage {...messages.labels.status} /></th>
              </tr>
            </thead>
            <tbody className="section__table__body">
              { map(hcpClinicMemberships, (clinicMembership) => this.renderClinicMembership(clinicMembership)) }
            </tbody>
          </table>
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  hcpClinicMemberships: selectors.hcpClinicMemberships(state),
  openDropdownId      : App.selectors.dropdown(state),
});


const mapDispatchToProps = (dispatch) => ({
  onOpenDropdown : (dropdownId) => dispatch(App.actions.openDropdown(dropdownId)),
  onCloseDropdown: () => dispatch(App.actions.closeDropdown()),
});


const ConnectedClinicsMemberships = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClinicsMemberships);


export default ConnectedClinicsMemberships;
