import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import get from 'lodash/get';
import { useAction } from 'hooks';
import BackButton from 'components/BackButton';
import Modal from 'components/Modal';
import App from 'modules/App';
import CloudDrive from 'modules/CloudDrive';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';


interface BackProps {
  componentName: string,
  onSetComponent: (componentName: string) => void,
}

interface Props {
  connectorType: ConnectorType,
  components: { [componentName: string]: FC},
  defaultComponentName: string,
}


const Back: FC<BackProps> = ({ componentName, onSetComponent }) => {
  const isAddPatientComponent = componentName === 'AddPatient';
  const isChoosePatientComponent = componentName === 'ChoosePatient';
  if (!(isChoosePatientComponent || isAddPatientComponent)) {
    return null;
  }

  let newComponentName: string;
  if (isChoosePatientComponent) {
    newComponentName = 'DownloadSummary';
  } else if (isAddPatientComponent) {
    newComponentName = 'ChoosePatient';
  }

  const onClick = (evt: MouseEvent) => {
    evt.preventDefault();
    onSetComponent(newComponentName);
  };

  return <BackButton to="" onClick={onClick} />;
};


const DownloadDataModal: FC<Props> = ({ connectorType, components, defaultComponentName }) => {
  const connectionId = useSelector(selectors.connectionId);
  const connectionStatus = useSelector(selectors.connectionStatus);
  const downloader = useSelector(selectors.downloader);
  const driverInstallationState = get(downloader, 'driver.installationState');
  const openModalId = useSelector(App.selectors.modal);
  const isDisabled = useSelector(CloudDrive.selectors.isStoreReadingsInProgress);

  const defaultHeaderMessage = messages.headers.downloadDataFromMeter;

  const [componentName, setComponentName] = useState<string>(defaultComponentName);
  const [headerMessage, setHeaderMessage] = useState<MessageDescriptor>(defaultHeaderMessage);
  const [styleModifier, setStyleModifier] = useState<'md' | 'lg' | 'xl'>(null);
  const [selectedChannel, setSelectedChannel] = useState<string>(null);

  const Component = get(components, componentName, null);

  const closeModal = useAction(App.actions.closeModal);
  const stopCheckingConnection = useAction(actions.stopCheckingConnection, connectionId, connectionStatus, true);
  const stopListeningBluetooth = useAction(actions.stopListeningBluetooth);
  const stopInstallBlueCableDriver = useAction(actions.stopInstallBlueCableDriver);

  const onClose = () => {
    closeModal();
    stopCheckingConnection();
    if (selectedChannel === 'Bluetooth') stopListeningBluetooth();
    if (driverInstallationState === 'Started' || driverInstallationState === 'InProgress') stopInstallBlueCableDriver();
    setComponentName(defaultComponentName);
    setHeaderMessage(defaultHeaderMessage);
  };

  const onSetHeaderMessage = (newHeaderMessage: MessageDescriptor = defaultHeaderMessage) => {
    setHeaderMessage(newHeaderMessage);
  };

  return (
    <Modal
      modalId={constants.DOWNLOAD_DATA_MODAL}
      openModalId={openModalId}
      styleModifier={styleModifier}
      isDisabled={isDisabled}
      onClose={onClose}
    >
      <Back componentName={componentName} onSetComponent={setComponentName} />
      <h1 className="modal__header"><FormattedMessage {...headerMessage} /></h1>
      <App.components.AlertsBus className="mb-4" />
      <Component
        connectorType={connectorType}
        selectedChannel={selectedChannel}
        onSetComponent={setComponentName}
        onSetHeaderMessage={onSetHeaderMessage}
        onSetStyleModifier={setStyleModifier}
        onSetChannel={setSelectedChannel}
        onCancel={onClose}
      />
    </Modal>
  );
};

export default DownloadDataModal;
