import moment from 'moment';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import meanBy from 'lodash/meanBy';
import sum from 'lodash/sum';
import flattenDeep from 'lodash/flattenDeep';
import flow from 'lodash/flow';


export default class AgpAggregator {

  static FIRST_WEEKDAY = 0;

  static MEASUREMENT_INTERVAL = 3;

  static MEASUREMENTS_PER_DAY = 24 * 60 / AgpAggregator.MEASUREMENT_INTERVAL

  static percentile(p, sortedList) {
    const kIndex = Math.ceil(sortedList.length * (p / 100)) - 1;
    return sortedList[kIndex];
  }


  static convertReadings(readings) {
    return readings.map((reading) => {
      const time = moment.unix(reading.timestamp).utc();
      return ({
        value    : reading.value,
        timestamp: reading.timestamp,
        date     : time.toDate(),
        time     : {
          year   : time.format('YYYY'),
          month  : time.format('MM'),
          day    : time.format('DD'),
          hour   : time.hour(),
          minutes: time.minutes(),
        },
      });
    });
  }


  static aggregateReadingsToHourlyRecords(readings) {
    const groupedRecords = groupBy(readings, ({ time }) => `${String(time.hour).padStart(2, '0')}`);
    const hourlyRecords = Object.entries(groupedRecords).map(([key, records]) => {
      const sortedValues = records.map(({ value }) => value).sort((a, b) => a - b);
      return {
        records,
        hour                : key,
        percentileStatistics: {
          5 : AgpAggregator.percentile(5, sortedValues),
          10: AgpAggregator.percentile(10, sortedValues),
          25: AgpAggregator.percentile(25, sortedValues),
          50: AgpAggregator.percentile(50, sortedValues),
          75: AgpAggregator.percentile(75, sortedValues),
          90: AgpAggregator.percentile(90, sortedValues),
          95: AgpAggregator.percentile(95, sortedValues),
        },
      };
    });
    return sortBy(hourlyRecords, 'hour');
  }


  static aggregateReadingsToDailyRecords(readings) {
    const groupedRecords = groupBy(readings,
      ({ time }) => `${time.year}-${String(time.month).padStart(2, '0')}-${String(time.day).padStart(2, '0')}`);
    const dailyRecords = Object.entries(groupedRecords).map(([key, records]) => {
      const minTimestamp = Math.min(...records.map((record) => record.timestamp));
      const maxTimestamp = Math.max(...records.map((record) => record.timestamp));
      const measurementsStart = moment.unix(minTimestamp);
      const measurementsEnd = moment.unix(maxTimestamp);
      const potentialFirstDay = measurementsStart.clone().endOf('day').diff(measurementsStart, 'minutes');
      const potentialLastDay = measurementsEnd.diff(measurementsEnd.clone().startOf('day'), 'minutes');
      return ({
        daySufficiency       : records.length / AgpAggregator.MEASUREMENTS_PER_DAY * 100,
        daySufficiencyAsFirst: records.length > 60 / AgpAggregator.MEASUREMENT_INTERVAL
          ? records.length / (potentialFirstDay / AgpAggregator.MEASUREMENT_INTERVAL) * 100
          : null,
        daySufficiencyAsLAst: records.length > 60 / AgpAggregator.MEASUREMENT_INTERVAL
          ? records.length / (potentialLastDay / AgpAggregator.MEASUREMENT_INTERVAL) * 100
          : null,
        dayRecordsNumber: records.length,
        records         : AgpAggregator.aggregateReadingsToHourlyRecords(records),
        date            : key,
        weekday         : moment(key, 'YYYY-MM-DD').format('dddd'),
        minValue        : Math.min(...records.map((record) => record.value)),
        maxValue        : Math.max(...records.map((record) => record.value)),
      });
    });
    return sortBy(dailyRecords, 'date');
  }


  static fillMissingDays(dailyRecords) {
    if (!dailyRecords.length) {
      return [];
    }
    const minDate = dailyRecords[0].date;
    const maxDate = dailyRecords[dailyRecords.length - 1].date;
    const current = moment(minDate);
    const end = moment(maxDate);
    const dates = [];
    while (current.weekday() !== AgpAggregator.FIRST_WEEKDAY) {
      current.subtract(1, 'days');
    }
    while (current <= end) {
      dates.push(current.format('YYYY-MM-DD'));
      current.add(1, 'days');
    }
    return dates.map((date) => {
      const dailyRecord = dailyRecords.find((_dailyRecord) => _dailyRecord.date === date);
      return dailyRecord || {
        records : null,
        weekday : moment(date, 'YYYY-MM-DD').format('dddd'),
        minValue: null,
        maxValue: null,
        date,
      };
    });
  }


  static aggregateRawReadings(readings) {
    return flow(
      AgpAggregator.convertReadings,
      AgpAggregator.aggregateReadingsToDailyRecords,
      AgpAggregator.fillMissingDays,
    )(readings);
  }


  static mergeDailyRecords(dailyRecords) {
    const nestedReadings = dailyRecords
      .map((dailyRecord) => (dailyRecord.records || [])
        .map((hourlyRecord) => hourlyRecord.records));
    const readings = flattenDeep(nestedReadings);
    return {
      records: AgpAggregator.aggregateReadingsToHourlyRecords(readings),
    };
  }

  static aggregateDailyRecordsToWeeklyRecords(dailyRecords) {
    const groupedRecords = groupBy(dailyRecords, (dailyRecord) => {
      const date = moment(dailyRecord.date, 'YYYY-MM-DD');
      while (date.weekday() !== AgpAggregator.FIRST_WEEKDAY) {
        date.subtract(1, 'days');
      }
      return `${date.format('YYYY')}-${date.format('ww')}`;
    });

    const weeklyRecords = (Object.entries(groupedRecords) || []).map(([key, _dailyRecords]) => ({
      ...AgpAggregator.mergeDailyRecords(_dailyRecords),
      key,
      week    : key,
      weekNo  : key.split('-')[1],
      year    : key.split('-')[0],
      month   : _dailyRecords[0] ? moment(_dailyRecords[0].date, 'YYYY-MM-DD').format('MM') : '',
      dates   : _dailyRecords.map((record) => (record.records ? record.date : null)).filter((date) => date),
      minValue: Math.min(..._dailyRecords.map((record) => record.minValue)),
      maxValue: Math.max(..._dailyRecords.map((record) => record.maxValue)),
    }));
    return sortBy(weeklyRecords, 'week');
  }


  static aggregateDailyRecordsToMonthlyRecords(dailyRecords) {
    const groupedRecords = groupBy(dailyRecords, (dailyRecord) => {
      const date = moment(dailyRecord.date, 'YYYY-MM-DD');
      return `${date.format('YYYY')}-${date.format('MM')}`;
    });

    const monthlyRecords = (Object.entries(groupedRecords) || []).map(([key, _dailyRecords]) => ({
      ...AgpAggregator.mergeDailyRecords(_dailyRecords),
      key,
      monthNo : key.split('-')[1],
      year    : key.split('-')[0],
      month   : _dailyRecords[0] ? moment(_dailyRecords[0].date, 'YYYY-MM-DD').format('MMMM') : '',
      dates   : _dailyRecords.map((record) => (record.records ? record.date : null)).filter((date) => date),
      minValue: Math.min(..._dailyRecords.map((record) => record.minValue)),
      maxValue: Math.max(..._dailyRecords.map((record) => record.maxValue)),
    }));
    return sortBy(monthlyRecords, 'key');
  }

  static timeIsActive(records) {
    const minTimestamp = get(minBy(records, 'timestamp'), 'timestamp', null);
    const maxTimestamp = get(maxBy(records, 'timestamp'), 'timestamp', null);
    if (!minTimestamp || !maxTimestamp || minTimestamp === maxTimestamp) {
      return null;
    }
    const deltaT = moment.unix(maxTimestamp).diff(moment.unix(minTimestamp), 'minutes');
    return records.length / ((deltaT / AgpAggregator.MEASUREMENT_INTERVAL) + 1) * 100;
  }

  static averageGlucose(records) {
    return meanBy(records, 'value');
  }

  static standardDeviation(records) {
    const values = records.map((record) => record.value);
    const averageGlucose = AgpAggregator.averageGlucose(records);
    return Math.sqrt(sum(values.map((value) => (value - averageGlucose) ** 2)) / values.length);
  }

}
