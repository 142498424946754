import PropTypes from 'prop-types';


export const meal = PropTypes.shape({
  name        : PropTypes.string.isRequired,
  timestamp   : PropTypes.number.isRequired,
  carbs       : PropTypes.number.isRequired,
  protein     : PropTypes.number,
  energy      : PropTypes.number,
  fat         : PropTypes.number,
  isSnaqImport: PropTypes.bool,
});


export const activity = PropTypes.shape({
  type     : PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
  duration : PropTypes.number.isRequired,
});


export const pill = PropTypes.shape({
  name     : PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
  count    : PropTypes.number.isRequired,
  unit     : PropTypes.string.isRequired,
});


export const injection = PropTypes.shape({
  timestamp: PropTypes.number.isRequired,
});
