import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import AmbulatoryGlucoseProfile from 'modules/AmbulatoryGlucoseProfile';
import messages from 'modules/AmbulatoryGlucoseProfile/messages';
import TimeInRangesReportChart from 'components/Charts/TimeInRangesChart/TimeInRangesReportChart';
import SectionTitle from '../components/SectionTitle';
import * as constants from '../../../constants';
import styles from './TimeInRangesSection.pcss';


class TimeInRangesSection extends React.Component {

  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    // Implicit props
    statistics: PropTypes.shape({
      veryHigh: PropTypes.number,
      high    : PropTypes.number,
      target  : PropTypes.number,
      low     : PropTypes.number,
      veryLow : PropTypes.number,
    }),
    isDisplayedGlucoseStatistics: PropTypes.bool,
    isDisplayedTimeInRanges     : PropTypes.bool,
  };


  render() {
    const { conversion, standards, statistics } = this.props;
    return (
      <div className={styles.timeInRangesSectionWrapper}>
        <SectionTitle>
          <FormattedMessage {...messages.headers.timeInRanges} />
        </SectionTitle>
        <TimeInRangesReportChart
          conversion={this.props.conversion}
          standards={this.props.standards}
          timeInRanges={[
            {
              name   : 'veryHigh',
              minutes: statistics.veryHigh * 1440,
              percent: Math.round(statistics.veryHigh * 100),
              range  : `<${conversion.toDisplay(250)}`,
            },
            {
              name   : 'high',
              minutes: statistics.high * 1440,
              percent: Math.round(statistics.high * 100),
              range  : `>${conversion.toDisplay(standards.postMeal.highThreshold)}`,
            },
            {
              name   : 'normal',
              minutes: statistics.target * 1440,
              percent: Math.round(statistics.target * 100),
              range  : `${conversion.toDisplay(standards.postMeal.lowThreshold)} - ${
                conversion.toDisplay(standards.postMeal.highThreshold)}`,
            },
            {
              name   : 'low',
              minutes: statistics.low * 1440,
              percent: Math.round(statistics.low * 100),
              range  : `<${conversion.toDisplay(standards.postMeal.lowThreshold)}`,
            },
            {
              name   : 'veryLow',
              minutes: statistics.veryLow * 1440,
              percent: Math.round(statistics.veryLow * 100),
              range  : `<${conversion.toDisplay(54)}`,
            },
          ]}
        />
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  statistics: AmbulatoryGlucoseProfile.selectors.highlightedStatisticsFactory(
    constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES
  )(state),
  isDisplayedGlucoseStatistics: AmbulatoryGlucoseProfile.selectors.isDisplayedGlucoseStatistics(state),
  isDisplayedTimeInRanges     : AmbulatoryGlucoseProfile.selectors.isDisplayedTimeInRanges(state),
});


const ConnectedTimeInRangesSection = connect(
  mapStateToProps,
)(TimeInRangesSection);

export default withStyles(styles)(ConnectedTimeInRangesSection);
