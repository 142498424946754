import React, { FC, useEffect } from 'react';
import { MessageDescriptor } from 'react-intl';
import Button from 'components/Form/Button';
import messages from '../../../../messages';
import WarningBox from './WarningBox';


interface ActionsProps {
  onSetComponent: (componentName: string) => void,
  onCancel: () => void,
}

interface Props {
  warnings: WarningType[],
  onSetComponent: (componentName: string) => void,
  onSetHeaderMessage: (headerMessage?: MessageDescriptor) => void,
  onCancel: () => void,
}


const Actions: FC<ActionsProps> = ({ onSetComponent, onCancel }) => {
  const onAccept = () => onSetComponent('DownloadSummary');

  return (
    <div className="modal__actions row">
      <div className="col-6">
        <Button
          styleModifier="primary"
          labelMessage={messages.buttons.warningReject}
          className="btn--block"
          onClick={onCancel}
        />
      </div>
      <div className="col-6">
        <Button
          styleModifier="primary"
          labelMessage={messages.buttons.warningAccept}
          className="btn--block btn--filled"
          onClick={onAccept}
        />
      </div>
    </div>
  );
};


const WarningsBeforeDownloadData: FC<Props> = ({
  warnings,
  onSetComponent,
  onSetHeaderMessage,
  onCancel,
}) => {
  const warningsLen = warnings.length;

  useEffect(() => {
    if (warnings.length === 1) {
      const warning = warnings[0];
      const warningMessage = messages.headers[`warning${warning}Single`] || messages.headers[`warning${warning}`];
      if (warningMessage) {
        onSetHeaderMessage(warningMessage);
      }
    } else {
      onSetHeaderMessage(messages.headers.warningsBeforeDownloadData);
    }
    return () => onSetHeaderMessage();
  }, []);

  return (
    <div>
      {
        warnings.map((warningType, idx) => (
          <WarningBox key={warningType} {...{ warningType, warningsLen, idx }} />
        ))
      }
      <Actions {...{ onSetComponent, onCancel }} />
    </div>
  );
};

export default WarningsBeforeDownloadData;
