import * as actionTypes from './actionTypes';


export const downloadPdfs = (pdfReportSources) => ({
  type   : actionTypes.DOWNLOAD_PDFS,
  payload: {
    pdfReportSources,
  },
});

export const downloadPdfSuccess = (filename) => ({
  type   : actionTypes.DOWNLOAD_PDFS_SUCCESS,
  payload: {
    filename,
  },
});

export const downloadPdfError = (error, filename) => ({
  type   : actionTypes.DOWNLOAD_PDFS_ERROR,
  payload: {
    filename,
  },
  error,
});
