import { call, spawn, delay } from 'redux-saga/effects';
import get from 'lodash/get';
import { ApiError } from 'helpers/errorTypes';
import ApiService from 'services/ApiService';


export function* openTransaction(scopeSets, timeoutInSeconds = 0, tries = 4) {
  const interval = 3000;
  let error = null;

  while (tries > 0) {
    try {
      return yield call(ApiService.regionalRequest, '/api/Mdtc/transactions', {
        method: 'POST',
        body  : {
          timeoutInSeconds,
          scopeSets,
        },
      });
    } catch (err) {
      error = err;
      yield delay(interval);
      tries--;
    }
  }
  throw new ApiError(error);
}


export function* prolongTransaction(transactionId, seconds) {
  return yield call(ApiService.regionalRequest, `/api/Mdtc/transactions/${transactionId}/prolongTimeout`, {
    method: 'POST',
    body  : {
      seconds,
    },
  });
}


export function* commitTransaction(transactionId, updatedRevisions) {
  return yield call(ApiService.regionalRequest, `/api/Mdtc/transactions/${transactionId}/commit`, {
    method: 'POST',
    body  : {
      updatedRevisions,
    },
  });
}


export function* closeTransaction(transactionId) {
  try {
    yield call(ApiService.regionalRequest, `/api/Mdtc/transactions/${transactionId}`, {
      method: 'DELETE',
    });
  } catch (err) {
    if (err.status !== 404) {
      throw ApiError(err);
    }
  }
}


export function* getForksHistory(referenceKey) {
  return yield call(ApiService.regionalRequest, `/api/Mdtc/cleanup/${referenceKey}/history`, {
    method: 'GET',
  });
}


export function* confirmCleanup(documentsIdentifiers) {
  return yield call(ApiService.regionalRequest, '/api/Mdtc/cleanup/confirm', {
    method: 'PUT',
    body  : {
      documentsIdentifiers,
    },
  });
}

//----------------------------------------------------------------------------------------------------------------------

export function* fetchDocumentId(referenceKey) {
  if (!referenceKey) {
    return null;
  }

  const transaction = yield call(openTransaction, [
    {
      lockType: 'Shared',
      referenceKey,
    },
  ]);

  const { transactionId } = transaction;
  const documentId = get(transaction, ['revisions', 0, 'documentId'], null);
  yield spawn(closeTransaction, transactionId);

  return documentId;
}
