import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { AppContext } from 'context';
import { getSlug } from 'helpers/urlTools';
import Avatar from 'components/Avatar';
import Link from 'components/Link';
import styles from './MoreClinicItem.pcss';


class MoreClinicItem extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    membership: PropTypes.shape({
      organizationUID: PropTypes.string.isRequired,
      status         : PropTypes.string.isRequired,
      clinic         : PropTypes.object,
      organization   : PropTypes.object,
    }),
    onClick: PropTypes.func,
  };


  renderActiveClinicIcon() {
    const { status, clinic, organization } = this.props.membership;
    const { status: coStatus, name, logo } = organization || clinic || {};
    const isInactive = status !== 'Active' || coStatus !== 'Active';
    const isDeleted = coStatus === 'Deleted' || coStatus === 'Cleared';
    return (
      <Avatar
        avatarImg={logo}
        name={name}
        className={
          cn('sidebar__clinicLogo', styles.moreClinicItem__avatar, {
            'sidebar__clinicLogo--deleted': isDeleted,
          })
        }
        imgClassName={
          cn('sidebar__clinicLogo', {
            'sidebar__clinicLogo--inactive': isInactive,
          })
        }
        initialsClassName={
          cn({
            'sidebar__clinicLogo--inactive': isInactive,
          })
        }
      />
    );
  }


  renderActiveClinicText() {
    const { clinic, organization } = this.props.membership;
    const { name } = organization || clinic || {};

    return <span className="sidebar__elementName sidebar__elementName--withOptions">{ name }</span>;
  }


  renderAvatarBadge() {
    const { status } = this.props.membership;
    const isPending = status === 'Pending';

    if (!isPending) return null;

    return <div className={styles.moreClinicItem__avatarBadge} />;
  }


  render() {
    if (!this.props.membership) return null;
    const { clinic, organization } = this.props.membership;
    const { organizationUID, name } = organization || clinic || {};
    const clinicSlug = getSlug(name);
    const url = this.context.getUrl('general-population', { clinicSlug, organizationUID });

    return (
      <Link to={url} onClick={this.props.onClick}>
        <div className={cn('d-flex align-items-center justify-content-between', styles.moreClinicItem)}>
          <div className={styles.moreClinicItem__avatarContainer}>
            { this.renderActiveClinicIcon() }
            { this.renderAvatarBadge() }
          </div>
          { this.renderActiveClinicText() }
        </div>
      </Link>
    );
  }

}

export default withStyles(styles)(MoreClinicItem);
