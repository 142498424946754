import * as actionTypes from './actionTypes';


//----------------------------------------------------------------------------------------------------------------------

export const authorize = (provider, type) => ({
  type   : actionTypes.AUTHORIZE,
  payload: {
    provider,
    type,
  },
});

export const authorizeCheck = (code, storageProvider, scope) => ({
  type   : actionTypes.AUTHORIZE_CHECK,
  payload: {
    code,
    storageProvider,
    scope,
  },
});

export const authorizeSuccess = (code, storageProvider) => ({
  type   : actionTypes.AUTHORIZE_SUCCESS,
  payload: {
    code,
    storageProvider,
  },
});

export const authorizeError = (error) => ({
  type: actionTypes.AUTHORIZE_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const clearAuthorizationCode = () => ({
  type: actionTypes.CLEAR_AUTHORIZATION_CODE,
});

//----------------------------------------------------------------------------------------------------------------------

export const getProfile = (accessToken, provider) => ({
  type   : actionTypes.GET_PROFILE,
  payload: {
    accessToken,
    provider,
  },
});

export const getProfileSuccess = (profile) => ({
  type   : actionTypes.GET_PROFILE_SUCCESS,
  payload: {
    profile,
  },
});

export const getProfileError = (error) => ({
  type: actionTypes.GET_PROFILE_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchPhiSet = (patient, successAction) => ({
  type   : actionTypes.FETCH_PHI_SET,
  payload: {
    patient,
    successAction,
  },
});

export const fetchPhiSetSuccess = () => ({
  type: actionTypes.FETCH_PHI_SET_SUCCESS,
});

export const fetchPhiSetError = (error) => ({
  type: actionTypes.FETCH_PHI_SET_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchReadings = ({
  phiSet,
  phiSetReferenceKey,
  phiSetDocumentId,
  fromImports,
  batchesIndex,
  cgmBatchesIndex,
  measurementsBatchesIndex,
  accessToken,
  storageProvider,
  startDate,
  endDate,
  successAction,
} = {}) => ({
  type   : actionTypes.FETCH_READINGS,
  payload: {
    phiSet,
    phiSetReferenceKey,
    phiSetDocumentId,
    fromImports,
    batchesIndex,
    cgmBatchesIndex,
    measurementsBatchesIndex,
    accessToken,
    storageProvider,
    startDate: startDate && startDate.clone(),
    endDate  : endDate && endDate.clone(),
    successAction,
  },
});

export const fetchReadingsSuccess = () => ({
  type: actionTypes.FETCH_READINGS_SUCCESS,
});

export const fetchReadingsError = (error) => ({
  type: actionTypes.FETCH_READINGS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchNotes = ({
  phiSet,
  phiSetReferenceKey,
  phiSetDocumentId,
  notesBatchesIndex,
  accessToken,
  storageProvider,
  batches,
  successAction,
}) => ({
  type   : actionTypes.FETCH_NOTES,
  payload: {
    phiSet,
    phiSetReferenceKey,
    phiSetDocumentId,
    notesBatchesIndex,
    accessToken,
    storageProvider,
    batches,
    successAction,
  },
});

export const fetchNotesSuccess = () => ({
  type: actionTypes.FETCH_NOTES_SUCCESS,
});

export const fetchNotesError = (error) => ({
  type: actionTypes.FETCH_NOTES_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchAggregates = ({
  phiSet, accessToken, storageProvider, months, level, fetchedAggregationDocumentsIds,
} = {}) => ({
  type   : actionTypes.FETCH_AGGREGATES,
  payload: {
    phiSet,
    accessToken,
    storageProvider,
    months,
    level,
    fetchedAggregationDocumentsIds,
  },
});

export const fetchAggregatesSuccess = ({
  aggregationsBatches,
  aggregationDocumentsIds,
}) => ({
  type   : actionTypes.FETCH_AGGREGATES_SUCCESS,
  payload: {
    aggregationsBatches,
    aggregationDocumentsIds,
  },
});

export const fetchAggregatesError = (error) => ({
  type: actionTypes.FETCH_AGGREGATES_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const storeReadings = (
  deviceImportData,
  phiSet,
  phiSetDocumentId,
  { phiSetReferenceKey, storageProvider, accessToken } = {},
  successAction,
  lastImportUTC,
) => ({
  type   : actionTypes.STORE_READINGS,
  payload: {
    deviceImportData,
    phiSet,
    phiSetDocumentId,
    phiSetReferenceKey,
    storageProvider,
    accessToken,
    successAction,
    lastImportUTC,
  },
});


export const storeReadingsSuccess = (updatedPhiSet, phiSetDocumentId) => ({
  type   : actionTypes.STORE_READINGS_SUCCESS,
  payload: {
    updatedPhiSet,
    phiSetDocumentId,
  },
});

export const storeReadingsError = (error) => ({
  type: actionTypes.STORE_READINGS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const storeRelatedData = (
  bgRelatedData,
  phiSet,
  phiSetDocumentId,
  { phiSetReferenceKey, storageProvider, accessToken } = {},
  successAction,
  lastImportUTC,
) => ({
  type   : actionTypes.STORE_RELATED_DATA,
  payload: {
    bgRelatedData,
    phiSet,
    phiSetDocumentId,
    phiSetReferenceKey,
    storageProvider,
    accessToken,
    successAction,
    lastImportUTC,
  },
});


export const storeRelatedDataSuccess = () => ({
  type: actionTypes.STORE_RELATED_DATA_SUCCESS,
});

export const storeRelatedDataError = (error) => ({
  type: actionTypes.STORE_RELATED_DATA_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const storeTimeSeriesResources = (
  timeSeriesResources,
  phiSet,
  phiSetDocumentId,
  { phiSetReferenceKey, storageProvider, accessToken } = {},
  successAction,
  lastImportUTC,
) => ({
  type   : actionTypes.STORE_TIME_SERIES_RESOURCES,
  payload: {
    timeSeriesResources,
    phiSet,
    phiSetDocumentId,
    phiSetReferenceKey,
    storageProvider,
    accessToken,
    successAction,
    lastImportUTC,
  },
});

export const storeTimeSeriesResourcesSuccess = () => ({
  type: actionTypes.STORE_TIME_SERIES_RESOURCES_SUCCESS,
});

export const storeTimeSeriesResourcesError = (error) => ({
  type: actionTypes.STORE_TIME_SERIES_RESOURCES_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const storeCgmReadings = (
  importData, phiSet, phiSetDocumentId, { phiSetReferenceKey, storageProvider, accessToken } = {}, successAction,
) => ({
  type   : actionTypes.STORE_CGM_READINGS,
  payload: {
    importData,
    phiSet,
    phiSetDocumentId,
    phiSetReferenceKey,
    storageProvider,
    accessToken,
    successAction,
  },
});

export const storeCgmReadingsSuccess = () => ({
  type: actionTypes.STORE_CGM_READINGS_SUCCESS,
});

export const storeCgmReadingsError = (error) => ({
  type: actionTypes.STORE_CGM_READINGS_ERROR,
  error,
});


//----------------------------------------------------------------------------------------------------------------------

export const storeMeasurements = (
  measurements,
  phiSet,
  phiSetDocumentId,
  { phiSetReferenceKey, storageProvider, accessToken, id: phiSetPatientId } = {},
  { phisetVisitId, timestamp } = {},
  successAction,
) => ({
  type   : actionTypes.STORE_MEASUREMENTS,
  payload: {
    measurements,
    phiSet,
    phiSetDocumentId,
    phiSetReferenceKey,
    storageProvider,
    accessToken,
    phiSetPatientId,
    phisetVisitId,
    timestamp,
    successAction,
  },
});

export const storeMeasurementsSuccess = (phiSet, phiSetDocumentId) => ({
  type   : actionTypes.STORE_MEASUREMENTS_SUCCESS,
  payload: {
    phiSet,
    phiSetDocumentId,
  },
});

export const storeMeasurementsError = (error) => ({
  type: actionTypes.STORE_MEASUREMENTS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const storeNotes = (
  notes,
  phiSet,
  phiSetDocumentId,
  phisetVisitId,
  { phiSetReferenceKey, storageProvider, accessToken } = {},
  successAction,
) => ({
  type   : actionTypes.STORE_NOTES,
  payload: {
    notes,
    phiSet,
    phiSetDocumentId,
    phiSetReferenceKey,
    phisetVisitId,
    storageProvider,
    accessToken,
    successAction,
  },
});

export const storeNotesSuccess = (phiSet, phiSetDocumentId) => ({
  type   : actionTypes.STORE_NOTES_SUCCESS,
  payload: {
    phiSet,
    phiSetDocumentId,
  },
});

export const storeNotesError = (error) => ({
  type: actionTypes.STORE_NOTES_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const startRemoveNotesQueue = () => ({
  type: actionTypes.START_REMOVE_NOTES_QUEUE,
});

export const stopRemoveNotesQueue = () => ({
  type: actionTypes.STOP_REMOVE_NOTES_QUEUE,
});

export const removeNotesQueueFinished = () => ({
  type: actionTypes.REMOVE_NOTES_QUEUE_FINISHED,
});

//----------------------------------------------------------------------------------------------------------------------

export const removeNote = (
  note,
  phiSet,
  phiSetDocumentId,
  { phiSetReferenceKey, storageProvider, accessToken } = {},
  successAction,
) => ({
  type   : actionTypes.REMOVE_NOTE,
  payload: {
    note,
    phiSet,
    phiSetDocumentId,
    phiSetReferenceKey,
    storageProvider,
    accessToken,
    successAction,
  },
});


export const removeNoteSuccess = (phiSet, phiSetDocumentId) => ({
  type   : actionTypes.REMOVE_NOTE_SUCCESS,
  payload: {
    phiSet,
    phiSetDocumentId,
  },
});

export const removeNoteError = (error) => ({
  type: actionTypes.REMOVE_NOTE_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const storeVisit = (
  visit,
  phiSet,
  phiSetDocumentId,
  { phiSetReferenceKey, storageProvider, accessToken, id: phiSetPatientId } = {},
  successAction,
) => ({
  type   : actionTypes.STORE_VISIT,
  payload: {
    visit,
    phiSet,
    phiSetDocumentId,
    phiSetReferenceKey,
    phiSetPatientId,
    storageProvider,
    accessToken,
    successAction,
  },
});


export const storeVisitSuccess = (updatedPhiSet, phiSetDocumentId) => ({
  type   : actionTypes.STORE_VISIT_SUCCESS,
  payload: {
    updatedPhiSet,
    phiSetDocumentId,
  },
});

export const storeVisitError = (error) => ({
  type: actionTypes.STORE_VISIT_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const updatePhiSet = (
  entries, phiSet, phiSetDocumentId, { phiSetReferenceKey, storageProvider, accessToken } = {}, successAction,
) => ({
  type   : actionTypes.UPDATE_PHI_SET,
  payload: {
    entries,
    phiSet,
    phiSetDocumentId,
    phiSetReferenceKey,
    storageProvider,
    accessToken,
    successAction,
  },
});

export const updatePhiSetSuccess = () => ({
  type: actionTypes.UPDATE_PHI_SET_SUCCESS,
});

export const updatePhiSetError = (error) => ({
  type: actionTypes.UPDATE_PHI_SET_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const sync = ({
  activePatient,
  phiSet,
  phiSetDocumentId,
  phiSetReferenceKey,
  phiSetPatientId,
  sharingRequest,
  activeClinicMembership,
  standards,
  createPhiSetAction,
  successAction,
  notesSuccessAction,
  timeSeriesResourcesSuccessAction,
} = {}) => ({
  type   : actionTypes.SYNC,
  payload: {
    activePatient,
    phiSet,
    phiSetDocumentId,
    phiSetReferenceKey,
    phiSetPatientId,
    sharingRequest,
    activeClinicMembership,
    standards,
    createPhiSetAction,
    successAction,
    notesSuccessAction,
    timeSeriesResourcesSuccessAction,
  },
});

export const syncSuccess = (updatedPhiSet, phiSetDocumentId, syncPatientId, syncHealthData) => ({
  type   : actionTypes.SYNC_SUCCESS,
  payload: {
    updatedPhiSet,
    phiSetDocumentId,
    syncPatientId,
    syncHealthData,
  },
});

export const syncError = (error) => ({
  type: actionTypes.SYNC_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const pushNotes = (notes, phisetVisitId, sharingRequest) => ({
  type   : actionTypes.PUSH_NOTES,
  payload: {
    notes,
    phisetVisitId,
    sharingRequest,
  },
});

export const pushNotesSuccess = () => ({
  type: actionTypes.PUSH_NOTES_SUCCESS,
});

export const pushNotesError = (error) => ({
  type: actionTypes.PUSH_NOTES_ERROR,
  error,
});


//----------------------------------------------------------------------------------------------------------------------

export const pushVisit = (phiSet, sharingRequest, clinicMembership) => ({
  type   : actionTypes.PUSH_VISIT,
  payload: {
    phiSet,
    sharingRequest,
    clinicMembership,
  },
});

export const pushVisitSuccess = () => ({
  type: actionTypes.PUSH_VISIT_SUCCESS,
});

export const pushVisitError = (error) => ({
  type: actionTypes.PUSH_VISIT_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchDump = (phiSet, patient, successAction) => ({
  type   : actionTypes.FETCH_DUMP,
  payload: {
    phiSet,
    patient,
    successAction,
  },
});

export const fetchDumpSuccess = () => ({
  type: actionTypes.FETCH_DUMP_SUCCESS,
});

export const fetchDumpError = (error) => ({
  type: actionTypes.FETCH_DUMP_ERROR,
  error,
});

export const cancelFetchDump = () => ({
  type: actionTypes.CANCEL_FETCH_DUMP,
});
