import { createSelector } from 'reselect';
import * as constants from './constants';


/**
 * Direct selector to the domain's state
 */
const mainSelector = (state) => state[constants.MODULE_ID];


/**
 * Is menu open
 */
export const menuOpen = createSelector(
  mainSelector,
  (state) => state.menuOpen,
);
/**
 * Agp view mode
 */
export const agpView = createSelector(
  mainSelector,
  (state) => state.agpView,
);


/**
 * Selected menu item
 */
export const selectedMenuItem = createSelector(
  mainSelector,
  (state) => state.selectedMenuItem,
);


/**
 * Window width
 */
export const windowWidth = createSelector(
  mainSelector,
  (state) => state.windowWidth,
);


export const hideEmptyRows = createSelector(
  mainSelector,
  (state) => state.hideEmptyRows,
);

export const showDayDividers = createSelector(
  mainSelector,
  (state) => state.showDayDividers,
);
