import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import messages from 'modules/PatientResults/messages';
import styles from './TimeInRangesReportChart.pcss';


class TimeInRangesChart extends React.PureComponent {

  static propTypes = {
    // Explicit props
    conversion  : PropTypes.object.isRequired,
    timeInRanges: PropTypes.arrayOf(PropTypes.shape({
      name   : PropTypes.string.isRequired,
      minutes: PropTypes.number.isRequired,
      percent: PropTypes.number.isRequired,
      range  : PropTypes.string.isRequired,
    })),
  };

  renderTimeLabel(minutes) {
    return (
      <>
        {!!Math.floor(minutes / 60)
          && (
            <>
              {Math.floor(minutes / 60)}
              {
                this.isCgmView
                  ? <FormattedMessage {...messages.labels.hours} />
                  : <FormattedMessage {...messages.labels.h} />
              }
            </>
          )
        }
        {!!(minutes % 60)
          && (
            <>
              { ' ' }
              {Math.round(minutes % 60)}
              {
                this.isCgmView
                  ? <FormattedMessage {...messages.labels.minutes} />
                  : <FormattedMessage {...messages.labels.min} />
              }
            </>
          )
        }
        {!Math.floor(minutes / 60) && !(minutes % 60) && <>0<FormattedMessage {...messages.labels.min} /></>}
      </>
    );
  }

  render() {
    const { timeInRanges } = this.props;
    const veryHighRange = timeInRanges.find(({ name }) => name === 'veryHigh');
    const veryLowRange = timeInRanges.find(({ name }) => name === 'veryLow');
    const filteredTimeInRanges = timeInRanges.filter((range) => range !== veryHighRange && range !== veryLowRange);
    return (
      <div className={styles.timeInRangesChart}>
        <div className="wrapper">
          <div className="barChart">
            {
              this.props.timeInRanges.map((timeInRange) => (
                <div
                  className={
                    cn(
                      'barChart__section',
                      `barChart__section--${timeInRange.name}`,
                      { 'barChart__section--transparent': !timeInRange.percent },
                    )}
                  style={{ height: `${timeInRange.percent || 0}%` }}
                  key={timeInRange.name}
                />
              ))
            }
          </div>
          <div className="legend">
            <div
              className="legend__section legend__section--first"
            >
              <div className="legend__sectionLabels">
                <span className="legend__sectionLabelName">
                  <FormattedMessage {...messages.labels[veryHighRange.name]} />
                </span>
                <span className="legend__sectionLabelTarget">
                      (<>{veryHighRange.range} {this.props.conversion.unitSymbol}</>)
                </span>
                <div className="legend__divider" />
                <span className="legend__sectionLabelPercent">
                  {veryHighRange.percent ? `${veryHighRange.percent}%` : '0%'}
                </span>
                <span className="legend__sectionLabelTime">
                      ({this.renderTimeLabel(veryHighRange.minutes)})
                </span>
              </div>
            </div>
            <div
              className="legend__section"
              style={{ height: `${veryHighRange.percent || 0}%` }}
            />
            {
              filteredTimeInRanges.map((timeInRange) => (
                <div
                  className="legend__section"
                  style={{ height: `${timeInRange.percent || 0}%` }}
                  key={timeInRange.name}
                >
                  <div className="legend__sectionLabels">
                    <span className="legend__sectionLabelName">
                      <FormattedMessage {...messages.labels[timeInRange.name]} />
                    </span>
                    <span className="legend__sectionLabelTarget">
                      (<>{timeInRange.range} {this.props.conversion.unitSymbol}</>)
                    </span>
                    <div className="legend__divider" />
                    <span className="legend__sectionLabelPercent">
                      {timeInRange.percent ? `${timeInRange.percent}%` : '0%'}
                    </span>
                    <span className="legend__sectionLabelTime">
                      ({this.renderTimeLabel(timeInRange.minutes)})
                    </span>
                  </div>
                </div>
              ))
            }
            <div
              className="legend__section"
              style={{ height: `${veryLowRange.percent || 0}%` }}
            />
            <div
              className="legend__section legend__section--last"
            >
              <div className="legend__sectionLabels">
                <span className="legend__sectionLabelName">
                  <FormattedMessage {...messages.labels[veryLowRange.name]} />
                </span>
                <span className="legend__sectionLabelTarget">
                      (<>{veryLowRange.range} {this.props.conversion.unitSymbol}</>)
                </span>
                <div className="legend__divider" />
                <span className="legend__sectionLabelPercent">
                  {veryLowRange.percent ? `${veryLowRange.percent}%` : '0%'}
                </span>
                <span className="legend__sectionLabelTime">
                      ({this.renderTimeLabel(veryLowRange.minutes)})
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}


export default withStyles(styles)(TimeInRangesChart);
