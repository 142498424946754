import { combineReducers } from 'redux';
import { routerReducer } from 'redux-first-routing';
import App from 'modules/App';
import Account from 'modules/Account';
import Information from 'modules/Information';
import Layout from 'modules/Layout';
import Notifications from 'modules/Notifications';
import CloudDrive from 'modules/CloudDrive';
import DataSources from 'modules/DataSources';
import Hcp from 'modules/Hcp';
import Patient from 'modules/Patient';
import Statistics from 'modules/Statistics';
import ContourCloud from 'modules/ContourCloud';


/**
 * Here go all core non-dynamic reducers
 * @returns {{router: routerReducer}}
 */
export function getCoreReducers() {
  return {
    router                             : routerReducer,
    // loadingBar                        : loadingBarReducer,
    [App.constants.MODULE_ID]          : App.reducer,
    [Account.constants.MODULE_ID]      : Account.reducer,
    [Information.constants.MODULE_ID]  : Information.reducer,
    [Layout.constants.MODULE_ID]       : Layout.reducer,
    [CloudDrive.constants.MODULE_ID]   : CloudDrive.reducer,
    [DataSources.constants.MODULE_ID]  : DataSources.reducer,
    [Hcp.constants.MODULE_ID]          : Hcp.reducer,
    [Notifications.constants.MODULE_ID]: Notifications.reducer,
    [Patient.constants.MODULE_ID]      : Patient.reducer,
    [Statistics.constants.MODULE_ID]   : Statistics.reducer,
    [ContourCloud.constants.MODULE_ID] : ContourCloud.reducer,
  };
}

export default function createReducer(asyncReducers = {}) {
  const coreReducers = getCoreReducers();
  return combineReducers({
    ...coreReducers,
    ...asyncReducers,
  });
}
