// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Z6TYu{font-size:14px;font-size:1.4rem;text-align:center}._2D4v6{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}._2D4v6 a{margin:4px}", ""]);
// Exports
exports.locals = {
	"tab__information": "Z6TYu",
	"tab__urlsInformationWrapper": "_2D4v6"
};
module.exports = exports;
