import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cn from 'classnames';
import map from 'lodash/map';
import get from 'lodash/get';
import styles from './Stack.pcss';


class Stack extends React.PureComponent {

  static propTypes = {
    // Explicit props
    children          : PropTypes.arrayOf(PropTypes.element),
    onClick           : PropTypes.func,
    onMouseEnter      : PropTypes.func,
    onMouseLeave      : PropTypes.func,
    activeElementIndex: PropTypes.number,
  };


  static defaultProps = {
    activeElementIndex: -1,
  }


  get childrenLength() {
    return get(this.props, 'children.length');
  }


  renderChild(element, index) {
    const { onClick, onMouseEnter, onMouseLeave, activeElementIndex } = this.props;
    const isActiveElement = activeElementIndex === index;

    const style = {
      transform: `translate(${index * 5}px, -${index * 3}px)`,
      zIndex   : isActiveElement ? this.childrenLength + 1 : this.childrenLength - index,
    };

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        key={index}
        className={cn(
          styles.stack__element,
          {
            [styles['stack__element--isActive']]: isActiveElement,
          },
        )}
        style={style}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        { element }
      </div>
    );
  }


  render() {
    const { children } = this.props;
    return (
      <div className={styles.stack}>
        { map(children, (element, index) => this.renderChild(element, index)) }
      </div>
    );
  }

}


export default withStyles(styles)(Stack);
