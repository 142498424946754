// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@-webkit-keyframes _3_zG3{5%{opacity:1}50%{opacity:.35}95%{opacity:1}}@keyframes _3_zG3{5%{opacity:1}50%{opacity:.35}95%{opacity:1}}.wf_z3{margin:0 1.5rem;width:100%;position:relative}._1dtIA{position:absolute;top:3px;-webkit-transform:translate(-50%);-ms-transform:translate(-50%);transform:translate(-50%);cursor:pointer}._1X-a5{z-index:1069}.fs6zH{position:absolute;bottom:50px;bottom:5rem;-webkit-transform:translate(-50%);-ms-transform:translate(-50%);transform:translate(-50%);z-index:1070}._20psF{position:relative;bottom:60px;bottom:6rem}._2-6jb{bottom:50px;bottom:5rem;z-index:1070}._2-6jb,._3hKae{position:absolute;-webkit-transform:translate(-50%);-ms-transform:translate(-50%);transform:translate(-50%)}._3hKae{bottom:35px}.MCxaO{position:absolute;bottom:20px;bottom:2rem;-webkit-transform:translate(-50%);-ms-transform:translate(-50%);transform:translate(-50%);z-index:1070}", ""]);
// Exports
exports.locals = {
	"dailyMeasurementsDistribution": "wf_z3",
	"dailyMeasurementsDistribution__reading": "_1dtIA",
	"dailyMeasurementsDistribution__reading--isActive": "_1X-a5",
	"dailyMeasurementsDistribution__readingTooltip": "fs6zH",
	"dailyMeasurementsDistribution__readingStack": "_20psF",
	"dailyMeasurementsDistribution__readingStackTooltip": "_2-6jb",
	"dailyMeasurementsDistribution__relatedData": "_3hKae",
	"dailyMeasurementsDistribution__relatedDataTooltip": "MCxaO",
	"fading": "_3_zG3"
};
module.exports = exports;
