import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import { calculateData } from 'helpers/kpi';
import KPIs from '../../KPIs';
import styles from './Header.pcss';


class Summary extends React.Component {


  static getDerivedStateFromProps(props) {
    const {
      readings,
    } = props;

    return {
      ...calculateData(readings),
    };
  }

  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    readings           : PropTypes.array,
    daysWithReadings   : PropTypes.number,
    daysWithoutReadings: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      preMealReadings : 0,
      postMealReadings: 0,
    };
  }


  getStyleForCell(lowThreshold, highThreshold, value) {
    if (value < lowThreshold) {
      return styles.cellSummaryLow;
    }
    if (value > highThreshold) {
      return styles.cellSummaryHigh;
    }
    return styles.cellSummaryTarget;
  }


  render() {
    const { readings, standards, conversion, daysWithReadings, daysWithoutReadings } = this.props;
    const { preMealReadings, postMealReadings } = this.state;

    return (
      <div className={styles.dailyLogReport__summary}>
        <KPIs
          readings={readings}
          preMealReadings={preMealReadings}
          postMealReadings={postMealReadings}
          standards={standards}
          conversion={conversion}
          daysWithReadings={daysWithReadings}
          daysWithoutReadings={daysWithoutReadings}
          className={styles.dailyLogReport__kpis}
        />
      </div>
    );
  }

}

export default withStyles(styles)(Summary);
