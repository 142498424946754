import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import difference from 'lodash/difference';
import find from 'lodash/find';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { AppContext } from 'context';
import history from 'helpers/history';
import { getStandards } from 'helpers/settings';
import accessTokenShape from 'shapes/accessTokenShape';
import keyPairShape from 'shapes/keyPairShape';
import App from 'modules/App';
import Account from 'modules/Account';
import Information from 'modules/Information';
import CloudDrive from 'modules/CloudDrive';
import ContourCloud from 'modules/ContourCloud';
import Notifications from 'modules/Notifications';
import Patient from 'modules/Patient';
import Visit from 'modules/Visit';
import Button from 'components/Form/Button';
import Edit from 'svg/edit.svg';
import countrySettingsShape from 'shapes/countrySettingsShape';
import noteShape from 'shapes/noteShape';
import Results from '../Results';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import styles from '../Results/Results.pcss';
import PatientHeader from './PatientHeader';


class MyResults extends React.PureComponent {

  static contextType = AppContext;

  static getDerivedStateFromProps(props, state) {
    const { countrySettings, phiSet, deviceMode } = props;
    const phiGlucoseLevelTargets = get(phiSet, 'glucoseLevelTargets', {});

    if (
      (!state.standards && countrySettings)
      || !isEqual(phiGlucoseLevelTargets, state.glucoseLevelTargets)
      || state.deviceMode !== deviceMode
    ) {

      const phiSetForStandards = deviceMode !== 'AGP' ? phiSet : null;
      const standards = getStandards(phiSetForStandards, countrySettings);

      const glucoseLevelTargets = { ...standards };
      delete glucoseLevelTargets.maxValue;
      delete glucoseLevelTargets.minValue;

      return { standards, glucoseLevelTargets, deviceMode };
    }

    return null;
  }


  static propTypes = {
    // Explicit props
    enrollCode    : PropTypes.string,
    invitationCode: PropTypes.string,
    // Implicit props
    keyPair       : keyPairShape,
    passphrase    : PropTypes.string,
    patientProfile: PropTypes.shape({
      patientProfileId  : PropTypes.number,
      phiSetReferenceKey: PropTypes.string,
      accessToken       : accessTokenShape,
      storageProvider   : PropTypes.string,
    }),
    countrySettings                    : countrySettingsShape,
    deviceMode                         : PropTypes.string,
    information                        : PropTypes.object,
    notes                              : PropTypes.arrayOf(noteShape),
    phiSet                             : PropTypes.object, // @TODO: shape
    phiSetDocumentId                   : PropTypes.string,
    fromImports                        : PropTypes.arrayOf(PropTypes.string),
    batchesIndex                       : PropTypes.arrayOf(PropTypes.string),
    cgmBatchesIndex                    : PropTypes.arrayOf(PropTypes.string),
    notesBatchesIndex                  : PropTypes.arrayOf(PropTypes.string),
    measurementsBatchesIndex           : PropTypes.arrayOf(PropTypes.string),
    readings                           : PropTypes.array, // @TODO: shape
    timeSeriesResources                : PropTypes.array,
    isConnectToPatientStorageInProgress: PropTypes.bool,
    isCCReAuthInProgress               : PropTypes.bool,
    isSyncInProgress                   : PropTypes.bool,
    hasSyncErrors                      : PropTypes.bool,
    highlightedReadings                : PropTypes.array,
    relatedData                        : PropTypes.array,
    measurements                       : PropTypes.array,
    sharingRequests                    : PropTypes.arrayOf(Patient.shapes.sharingRequest),
    lastReceivedNotification           : Notifications.shapes.notification,
    // Implicit actions
    onConnect                          : PropTypes.func,
    onSync                             : PropTypes.func,
    onFetchReadings                    : PropTypes.func,
    onFetchNotes                       : PropTypes.func,
    onStoreNotes                       : PropTypes.func,
    onClearNotesBatches                : PropTypes.func,
    onUpdatePhiSet                     : PropTypes.func,
    onEnrollInClinic                   : PropTypes.func,
    onOpenModal                        : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      standards          : null,
      glucoseLevelTargets: {},
    };
  }


  componentDidMount() {
    const accessToken = get(this.props.patientProfile, 'accessToken');
    if (accessToken) {
      this.onSync();
    } else {
      this.onConnect();
    }
  }


  componentDidUpdate(prevProps) {
    const {
      passphrase,
      enrollCode,
      invitationCode,
      lastReceivedNotification,
      isConnectToPatientStorageInProgress,
      isCCReAuthInProgress,
      isSyncInProgress,
      hasSyncErrors,
    } = this.props;

    if (prevProps.passphrase !== passphrase) {
      this.onConnect();
    }

    if (
      prevProps.lastReceivedNotification !== lastReceivedNotification
      && lastReceivedNotification
      && lastReceivedNotification.notificationTrigger === Notifications.constants.NOTIFICATIONS_TRIGGERS.NOTE_WRITTEN_BY_HCP
      && !isConnectToPatientStorageInProgress
      && !isSyncInProgress
    ) {
      this.props.onClearNotesBatches();
      this.onSync();
    }

    if (
      (prevProps.isConnectToPatientStorageInProgress && !isConnectToPatientStorageInProgress)
      || (prevProps.isCCReAuthInProgress && !isCCReAuthInProgress)
    ) {
      this.onSync();
    }

    if (enrollCode && invitationCode && prevProps.isSyncInProgress && !isSyncInProgress && !hasSyncErrors) {
      this.props.onEnrollInClinic(enrollCode, invitationCode);
      const { getUrl } = this.context;
      history.push(getUrl('my-results'));
    }
  }


  onConnect() {
    if (!process.env.BROWSER) {
      return;
    }
    const { passphrase, patientProfile } = this.props;
    const accessToken = get(patientProfile, 'accessToken');
    if (passphrase && !accessToken) {
      this.props.onConnect(patientProfile);
    }
  }


  onFetchNotes(visit) {
    const associatedNotesBatches = get(visit, 'associatedDataIndexKeys.notes', []);
    const diff = difference(associatedNotesBatches, this.props.notesBatchesIndex);
    if (!diff.length) {
      return;
    }
    const { patientProfile, phiSet, phiSetDocumentId, notesBatchesIndex } = this.props;
    const { phiSetReferenceKey, accessToken, storageProvider } = patientProfile || {};
    this.props.onFetchNotes({
      phiSet,
      phiSetReferenceKey,
      phiSetDocumentId,
      notesBatchesIndex,
      accessToken,
      storageProvider,
      batches      : associatedNotesBatches,
      successAction: Patient.actions.setNotes,
    });
  }


  onSync() {
    if (!process.env.BROWSER) {
      return;
    }
    const { patientProfile, passphrase, keyPair } = this.props;
    this.props.onSync({ ...patientProfile, keyPair }, passphrase);
  }


  onRangeChange(startDate, endDate) {
    const {
      patientProfile,
      phiSet, phiSetDocumentId,
      fromImports,
      batchesIndex, cgmBatchesIndex, measurementsBatchesIndex,
      onFetchReadings,
    } = this.props;
    const { phiSetReferenceKey, accessToken, storageProvider } = patientProfile;

    onFetchReadings({
      phiSet,
      phiSetReferenceKey,
      phiSetDocumentId,
      fromImports,
      batchesIndex,
      cgmBatchesIndex,
      measurementsBatchesIndex,
      accessToken,
      storageProvider,
      startDate,
      endDate,
      successAction: Patient.actions.setReadings,
    });
  }


  onUpdatePhiSet(entries) {
    const { phiSet, phiSetDocumentId, patientProfile } = this.props;
    this.props.onUpdatePhiSet(
      entries, phiSet, phiSetDocumentId, patientProfile, Patient.actions.setPhiSet,
    );
  }


  onStoreNotes(notes, phiSet, phiSetDocumentId, phisetVisitId, visitMetadata = {}) {
    const { patientProfile, sharingRequests } = this.props;
    const { clinicId } = visitMetadata;
    const sharingRequest = find(sharingRequests, (sr) => sr.clinic && sr.clinic.id === clinicId);
    this.props.onStoreNotes(
      notes, phiSet, phiSetDocumentId, phisetVisitId, patientProfile, sharingRequest, visitMetadata,
    );
  }


  renderPatientHeader() {
    return <PatientHeader onSync={() => this.onSync()} />;
  }


  renderFeaturesActions(isDisabled) {
    return (
      <Button
        styleModifier="transparent"
        className={styles.patient__feature__action}
        isDisabled={isDisabled}
        onClick={() => this.props.onOpenModal(constants.MEASUREMENTS_MODAL)}
      >
        <Edit className={styles.patient__feature__icon} />
      </Button>
    );
  }


  render() {
    const { keyPair, passphrase } = this.props;
    return (
      <>
        <Results
          phiSet={this.props.phiSet}
          phiSetDocumentId={this.props.phiSetDocumentId}
          patient={{ ...this.props.patientProfile, id: this.props.patientProfile.patientProfileId, keyPair }}
          readings={this.props.readings}
          timeSeriesResources={this.props.timeSeriesResources}
          relatedData={this.props.relatedData}
          measurements={this.props.measurements}
          highlightedReadings={this.props.highlightedReadings}
          standards={this.state.standards}
          isPatientMode
          isSyncInProgress={this.props.isSyncInProgress}
          renderPatientHeader={() => this.renderPatientHeader()}
          renderFeaturesActions={(isDisabled) => this.renderFeaturesActions(isDisabled)}
          onRangeChange={(startDate, endDate) => this.onRangeChange(startDate, endDate)}
          // TODO: Remove if confirmed
          // onMeasurementsSuccess={Patient.actions.onStoreMeasurementsSuccess}
          onUpdatePhiSet={(entries) => this.onUpdatePhiSet(entries)}
        />
        <Visit.components.VisitHistoryModal
          activePatient={this.props.information}
          prvKeyPem={keyPair.prvKeyPem}
          passphrase={passphrase}
          phiSet={this.props.phiSet}
          phiSetDocumentId={this.props.phiSetDocumentId}
          mode="PWD"
          notes={this.props.notes}
          onFetchNotes={(visit) => this.onFetchNotes(visit)}
          onStoreNotes={(...props) => this.onStoreNotes(...props)}
        />
      </>
    );
  }

}


const mapStateToProps = (state) => ({
  deviceMode                         : selectors.deviceMode(state),
  fromImports                        : selectors.fromImports(state),
  keyPair                            : Account.selectors.keyPair(state),
  passphrase                         : Account.selectors.passphrase(state),
  countrySettings                    : Account.selectors.countrySettings(state),
  patientProfile                     : Account.selectors.patientProfile(state),
  information                        : Information.selectors.information(state),
  phiSet                             : Patient.selectors.phiSet(state),
  phiSetDocumentId                   : Patient.selectors.phiSetDocumentId(state),
  batchesIndex                       : Patient.selectors.batchesIndex(state),
  cgmBatchesIndex                    : Patient.selectors.cgmBatchesIndex(state),
  notesBatchesIndex                  : Patient.selectors.notesBatchesIndex(state),
  measurementsBatchesIndex           : Patient.selectors.measurementsBatchesIndex(state),
  readings                           : Patient.selectors.readings(state),
  timeSeriesResources                : Patient.selectors.timeSeriesResources(state),
  relatedData                        : Patient.selectors.relatedData(state),
  highlightedReadings                : Patient.selectors.highlightedReadings(state),
  notes                              : Patient.selectors.notes(state),
  isSyncInProgress                   : Patient.selectors.isSyncInProgress(state),
  hasSyncErrors                      : Patient.selectors.hasSyncErrors(state),
  measurements                       : Patient.selectors.measurements(state),
  sharingRequests                    : Patient.selectors.sharingRequests(state),
  isConnectToPatientStorageInProgress: Account.selectors.isConnectToPatientStorageInProgress(state),
  isCCReAuthInProgress               : ContourCloud.selectors.isReauthInProgress(state),
  lastReceivedNotification           : Notifications.selectors.lastReceivedNotification(state),
});


const mapDispatchToProps = (dispatch) => ({
  onConnect      : (patientProfile) => dispatch(Account.actions.connectToPatientStorage(patientProfile)),
  onSync         : (patientProfile, passphrase) => dispatch(Patient.actions.sync(patientProfile, passphrase)),
  onFetchReadings: (constraints) => dispatch(CloudDrive.actions.fetchReadings(constraints)),
  onFetchNotes   : (params) => dispatch(CloudDrive.actions.fetchNotes(params)),
  onStoreNotes   : (
    notes, phiSet, phiSetDocumentId, phisetVisitId, patientProfile, sharingRequest, visitMetadata,
  ) => dispatch(
    Patient.actions.storeNotes(
      notes, phiSet, phiSetDocumentId, phisetVisitId, patientProfile, sharingRequest, visitMetadata,
    ),
  ),
  onClearNotesBatches: () => dispatch(Patient.actions.clearNotesBatches()),
  onUpdatePhiSet     : (entries, phiSet, phiSetDocumentId, patientProfile, successAction) => dispatch(
    CloudDrive.actions.updatePhiSet(entries, phiSet, phiSetDocumentId, patientProfile, successAction),
  ),
  onEnrollInClinic: (enrollCode, invitationCode) => dispatch(
    Patient.actions.enrollInClinic(enrollCode, invitationCode),
  ),
  onOpenModal: (modalId) => dispatch(App.actions.openModal(modalId)),
});


const ConnectedMyResults = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyResults);


export default ConnectedMyResults;
