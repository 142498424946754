import moment from 'moment';
import get from 'lodash/get';
import first from 'lodash/first';
import last from 'lodash/last';
import min from 'lodash/min';
import max from 'lodash/max';
import map from 'lodash/map';
import compact from 'lodash/compact';


function getSnaqImportsDateRange(phiSet) {
  let minSnaqImportTimestamp = null;
  let maxSnaqImportTimestamp = null;

  const documents = get(phiSet, 'imports.snaq.documents');
  if (!documents) {
    return { minSnaqImportTimestamp, maxSnaqImportTimestamp };
  }

  const firstImport = first(documents);
  if (firstImport) {
    minSnaqImportTimestamp = firstImport.minResultDate;
  }

  const lastImport = last(documents);
  if (lastImport) {
    maxSnaqImportTimestamp = lastImport.maxResultDate;
  }

  return { minSnaqImportTimestamp, maxSnaqImportTimestamp };
}


export function calculateDateRanges(phiSet) {
  const minReadingsTimestamp = get(phiSet, 'summaryData.minReadingsTimestamp');
  const maxReadingsTimestamp = get(phiSet, 'summaryData.maxReadingsTimestamp');

  const minCgmReadingsTimestamp = get(phiSet, 'cgmSummaryData.minReadingsTimestamp');
  const maxCgmReadingsTimestamp = get(phiSet, 'cgmSummaryData.maxReadingsTimestamp');

  const { minSnaqImportTimestamp, maxSnaqImportTimestamp } = getSnaqImportsDateRange(phiSet);

  const minPhiTimestamp = min(compact([minReadingsTimestamp, minCgmReadingsTimestamp, minSnaqImportTimestamp]));
  const maxPhiTimestamp = max(compact([maxReadingsTimestamp, maxCgmReadingsTimestamp, maxSnaqImportTimestamp]));

  const maxMoment = maxPhiTimestamp ? moment.unix(maxPhiTimestamp).utc() : moment().utc();
  maxMoment.endOf('day');
  const defaultStartMoment = maxMoment.clone()
    .startOf('day')
    .subtract(14, 'days')
    .add(1, 'day');
  const minMoment = minPhiTimestamp ? moment.unix(minPhiTimestamp).utc().startOf('day') : defaultStartMoment;

  return {
    defaultStartMoment,
    minMoment,
    maxMoment,
  };
}
export function calculateDateRangesFromReadings(readings) {
  const minTimeStemp = min(map(readings, (reading) => reading.timestamp));
  const maxTimeStemp = max(map(readings, (reading) => reading.timestamp));
  const maxMoment = maxTimeStemp ? moment.unix(maxTimeStemp).utc() : moment().utc();
  maxMoment.endOf('day');
  const defaultStartMoment = maxMoment.clone()
    .startOf('day')
    .subtract(14, 'days')
    .add(1, 'day');
  const minMoment = minTimeStemp ? moment.unix(minTimeStemp).utc().startOf('day') : defaultStartMoment;

  return {
    defaultStartMoment,
    minMoment,
    maxMoment,
  };
}

