import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as selectors from '../../../selectors';
import PatientProfileForm from './PatientProfileForm';
import ReauthorizeCloudDrive from './ReauthorizeCloudDrive';


class PatientProfile extends React.PureComponent {

  static propTypes = {
    // Implicit props
    patientProfile: PropTypes.object,
  };


  render() {
    if (!this.props.patientProfile) {
      return null;
    }
    return (
      <div className="row">
        <div className="col-6 pr-10">
          <PatientProfileForm patientProfile={this.props.patientProfile} />
        </div>
        <div className="col-6 pl-10">
          <ReauthorizeCloudDrive patientProfile={this.props.patientProfile} />
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  patientProfile: selectors.patientProfile(state),
});

const ConnectedPatientProfile = connect(
  mapStateToProps,
)(PatientProfile);


export default ConnectedPatientProfile;

