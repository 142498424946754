import get from 'lodash/get';
import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ProfileContext } from 'context';
import { getStandards } from 'helpers/settings';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import Information from 'modules/Information';
import * as constants from './constants';
import * as selectors from './selectors';


export const useConnectorConsentTemplate = (connectorType: ConnectorType) => {
  const consentsTemplate = useSelector(Information.selectors.consentsTemplate);
  const { legalConsentApprovals } = useSelector(Information.selectors.information);

  if (connectorType === constants.CONNECTOR_TYPES.MDA) {
    return [];
  }

  const noApprovalConsentTemplate: LegalConsent[] = [];
  const connectorConsentTemplate = consentsTemplate
    && consentsTemplate.filter((ct) => ct.type === 'ThirdPartyConnector');

  connectorConsentTemplate.forEach((cct) => {
    const cctApproval = legalConsentApprovals.find((lca) => lca.legalConsentId === cct.legalConsentId);
    if (!cctApproval || !cctApproval.decision) {
      noApprovalConsentTemplate.push(cct);
    }
  });

  return noApprovalConsentTemplate;
};


export const useStandards = () => {
  const profileContext = useContext(ProfileContext);
  const phiSet: PhiSet = useSelector(profileContext.selectors.phiSet);
  const activeClinicMembership: ClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const clinicSettings = get(activeClinicMembership, 'clinic.settings');
  const countrySettings = useSelector(Account.selectors.countrySettings);
  return useMemo(
    (): GlucoseStandards => getStandards(phiSet, countrySettings, clinicSettings),
    [phiSet, countrySettings, clinicSettings],
  );
};


export const useIsDisabled = (patientProfile, isProfileActivationInProgress) => {
  const deviceData = useSelector(selectors.deviceData);
  const deviceSerialNumberToken = get(deviceData, 'deviceSerialNumberToken');
  const hasFetchPhiSetErrors = useSelector(CloudDrive.selectors.hasFetchPhiSetErrors);
  const hasFetchReadingsErrors = useSelector(CloudDrive.selectors.hasFetchReadingsErrors);

  return !patientProfile || !deviceSerialNumberToken || isProfileActivationInProgress
    || hasFetchPhiSetErrors || hasFetchReadingsErrors;
};
