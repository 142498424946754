import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { motion, AnimatePresence } from 'framer-motion';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import memoize from 'lodash/memoize';
import take from 'lodash/take';
import times from 'lodash/times';
import { AppContext } from 'context';
import { getSlug } from 'helpers/urlTools';
import { formatLocalizationResource } from 'helpers/i18n';
import { decrypt, getKeyFromPem } from 'helpers/crypto';
import Button from 'components/Form/Button';
import Link from 'components/Link';
import keyPairShape from 'shapes/keyPairShape';
import X from 'svg/x.svg';
import SettingsIcon from 'svg/settings.svg';
import App from 'modules/App';
import Account from 'modules/Account';
import Visit from 'modules/Visit';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import styles from './NotificationsPopover.pcss';


class NotificationsPopover extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Implicit props
    notifications        : PropTypes.arrayOf(shapes.notification),
    isInProgress         : PropTypes.bool,
    passphrase           : PropTypes.string,
    keyPair              : keyPairShape,
    hcpClinicMemberships : PropTypes.arrayOf(Account.shapes.clinicMembership),
    localizationResources: PropTypes.object,
    openDropdownId       : PropTypes.string,
    notificationsSettings: PropTypes.object,
    // Implicit actions
    onCreateSignalRHub   : PropTypes.func,
    onFetchList          : PropTypes.func,
    onDismiss            : PropTypes.func,
    onMarkRead           : PropTypes.func,
    onCloseDropdown      : PropTypes.func,
    openSettingsModal    : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.isOpen = false;
    this.getClinicPrivateKeyMemo = memoize(this.getClinicPrivateKey.bind(this));
    this.getEnhancedVariablesMemo = memoize(this.getEnhancedVariables.bind(this));
  }


  componentDidMount() {
    this.props.onFetchList();
    this.props.onCreateSignalRHub();
  }


  componentDidUpdate(prevProps) {
    const { notifications, openDropdownId } = this.props;
    if (prevProps.openDropdownId !== openDropdownId) {
      this.isOpen = openDropdownId === constants.NOTIFICATIONS_DROPDOWN;
      if (prevProps.openDropdownId === constants.NOTIFICATIONS_DROPDOWN && !this.isOpen) {
        const activeNotifications = filter(
          take(notifications, constants.NOTIFICATIONS_LIST_LENGTH),
          { status: 'Active' }
        );
        const activeNotificationsIds = map(activeNotifications, (ac) => ac.instantNotificationId);
        this.props.onMarkRead(activeNotificationsIds);
        const enhancedVariablesMemoCacheSize = get(this.getEnhancedVariablesMemo, 'cache.size', 0);
        if (enhancedVariablesMemoCacheSize > 50) {
          this.getEnhancedVariablesMemo.cache.clear();
        }
      }
    }
  }


  componentWillUnmount() {
    this.props.onCloseDropdown();
  }


  // renderAvatar(notification) {
  //   const { avatar } = notification;
  //   const img = avatar ? <img src={avatar} alt="avatar" className={styles.notifications__avatar__img} /> : null;
  //
  //   return (
  //     <div className={styles.notifications__avatar}>
  //       { img }
  //     </div>
  //   );
  // }


  onDismiss(evt, notification) {
    evt.preventDefault();
    this.props.onDismiss(notification);
  }


  getClinicPrivateKey(clinicId) {
    if (!clinicId || !this.props.passphrase) {
      return null;
    }

    const clinicMembership = find(this.props.hcpClinicMemberships, { clinicId: +clinicId });
    if (!clinicMembership) {
      return null;
    }
    const accountPrvKeyObj = get(this.props.keyPair, 'prvKeyPem');
    if (!accountPrvKeyObj) {
      return null;
    }
    const accountPrvKey = getKeyFromPem(accountPrvKeyObj, this.props.passphrase);
    const { encryptedPrivateKey, encryptedPassphrase } = clinicMembership;
    const clinicKeyPassphrase = decrypt(encryptedPassphrase, accountPrvKey);
    return getKeyFromPem(encryptedPrivateKey, clinicKeyPassphrase);
  }


  getPatientProfileLink(clinicId, clinicName, patientFirstName, patientLastName, encryptedClinicPatientProfileId) {
    if (!encryptedClinicPatientProfileId) {
      return null;
    }
    const prvKeyObj = this.getClinicPrivateKeyMemo(+clinicId);
    if (!prvKeyObj) {
      return null;
    }
    const clinicMembership = find(this.props.hcpClinicMemberships, { clinicId: +clinicId });
    const organizationUID = get(clinicMembership, 'clinic.organizationUID');
    const clinicSlug = getSlug(clinicName);
    const patientId = decrypt(encryptedClinicPatientProfileId, prvKeyObj);
    const patientSlug = getSlug(`${patientFirstName} ${patientLastName}`);
    return (
      <Link to={this.context.getUrl('hcp-results', { clinicSlug, organizationUID, patientSlug, patientId })}>
        <FormattedMessage {...messages.partials.goToPatientProfile} />
      </Link>
    );
  }


  getVisitHistoryModalLink() {
    const url = `${this.context.getUrl('my-results')}?openModal=${Visit.constants.VISIT_HISTORY_MODAL}`;
    return (
      <Link to={url}>
        <FormattedMessage {...messages.partials.visitsHistory} />
      </Link>
    );
  }


  getEnhancedVariables(notification) {
    const { variables, notificationTrigger } = { ...notification };
    switch (notificationTrigger) {
      case constants.NOTIFICATIONS_TRIGGERS.CLINIC_PATIENT_PROFILE_CREATED:
      case constants.NOTIFICATIONS_TRIGGERS.CLINIC_PATIENT_PROFILE_NEW_DATA_SYNCHRONIZED:
      case constants.NOTIFICATIONS_TRIGGERS.NOTE_WRITTEN_BY_PWD: {
        const { clinicId, clinicName, patientFirstName, patientLastName, encryptedClinicPatientProfileId } = variables;
        variables.patientProfileLink = this.getPatientProfileLink(
          clinicId, clinicName, patientFirstName, patientLastName, encryptedClinicPatientProfileId,
        );
        break;
      }
      case constants.NOTIFICATIONS_TRIGGERS.PWD_INVITATION_REVOKED:
      case constants.NOTIFICATIONS_TRIGGERS.SHARING_REQUEST_ACCEPTED: {
        const { clinicId, clinicName, inviteeFirstname, inviteeLastname, encryptedLocalPatientProfileId } = variables;
        variables.patientProfileLink = this.getPatientProfileLink(
          clinicId, clinicName, inviteeFirstname, inviteeLastname, encryptedLocalPatientProfileId,
        );
        break;
      }
      case constants.NOTIFICATIONS_TRIGGERS.CLINIC_HCP_MEMBERSHIP_CREATED: {
        const { clinicId, clinicName } = variables;
        const clinicMembership = find(this.props.hcpClinicMemberships, { clinicId: +clinicId });
        const organizationUID = get(clinicMembership, 'clinic.organizationUID');
        const clinicSlug = getSlug(clinicName);
        variables.clinicLink = (
          <Link to={`${this.context.getUrl('clinic-settings', { clinicSlug, organizationUID })}#clinicMemberships`}>
            <FormattedMessage {...messages.partials.leadingClick} />
          </Link>
        );
        break;
      }
      case constants.NOTIFICATIONS_TRIGGERS.NOTE_WRITTEN_BY_HCP: {
        variables.VisitNotesUrl = this.getVisitHistoryModalLink();
        break;
      }
      default: {
        break;
      }
    }
    return variables;
  }


  renderDismissNotification(notification) {
    return (
      <button
        type="button"
        className={styles.notifications__notification__dismiss}
        onClick={(evt) => this.onDismiss(evt, notification)}
      >
        <span className="btn-inner">
          <X className={styles.notifications__notification__dismiss__icon} />
        </span>
      </button>
    );
  }


  renderNotification(notification, idx) {
    const message = formatLocalizationResource(
      this.props.localizationResources,
      notification.resourceKey,
      this.getEnhancedVariablesMemo(notification),
    );
    return (
      <motion.li
        key={notification.instantNotificationId}
        initial={this.isOpen || idx >= constants.NOTIFICATIONS_LIST_LENGTH ? { height: 0 } : undefined}
        animate={this.isOpen ? { height: idx < constants.NOTIFICATIONS_LIST_LENGTH ? 'auto' : 0 } : undefined}
        exit={{ height: 0 }}
        transition={{ ease: 'easeOut', duration: 0.3 }}
        className={cn(styles.notifications__notification, {
          [styles['notifications__notification--no-border']]: idx >= constants.NOTIFICATIONS_LIST_LENGTH,
          'text--bold'                                      : notification.status === 'Active',
          'alert-error'                                     : notification.status === 'Error',
        })}
      >
        <div className={`row align-items-center ${styles.notifications__notification__inner}`}>
          <div className="col">
            { message }
          </div>
          <div className="col-1 pr-2 d-flex justify-content-end">
            { this.renderDismissNotification(notification) }
          </div>
        </div>
      </motion.li>
    );
  }


  renderNotificationListItems() {
    const { notifications, isInProgress } = this.props;
    if (isInProgress && !notifications.length) {
      return times(5, (idx) => (
        <li
          key={idx}
          className={styles.notifications__notification}
        >
          <div className={styles.notifications__notification__inner}>
            <p className={cn(styles.notifications__notification__textPlaceholder, 'fadingLoader w-75')} />
          </div>
        </li>
      ));
    }
    return (
      <AnimatePresence>
        { map(notifications, (notification, idx) => this.renderNotification(notification, idx)) }
      </AnimatePresence>
    );
  }


  renderNoNotifications() {
    return (
      <div className={styles.notifications__list}>
        <div className={styles.notifications__notification}>
          <div className={`${styles.notifications__notification__inner} text--muted`}>
            <FormattedMessage {...messages.infos.noNotifications} />
          </div>
        </div>
      </div>
    );
  }


  renderNotifications() {
    if (!this.props.notifications.length) {
      return this.renderNoNotifications();
    }
    return (
      <ul className={styles.notifications__list}>
        { this.renderNotificationListItems() }
      </ul>
    );
  }


  renderPopover() {
    if (this.props.openDropdownId !== constants.NOTIFICATIONS_DROPDOWN) {
      return null;
    }

    return (
      <motion.div
        className={styles.notifications}
        role="presentation"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ ease: 'easeOut', duration: 0.15 }}
        onClick={(evt) => evt.stopPropagation()}
      >
        <div className={styles.notifications__arrow} />
        <h3 className={cn(styles.notifications__header, 'text--h2')}>
          <FormattedMessage {...messages.headers.notifications} />
          {
            this.props.notificationsSettings
              && Object.keys(this.props.notificationsSettings).length > 0
              && (
                <Button
                  styleModifier="transparent"
                  onClick={() => this.props.openSettingsModal()}
                >
                  <SettingsIcon />
                </Button>
              )
          }
        </h3>
        { this.renderNotifications() }
        {/*
          <div className={ styles.notifications__viewAll }>
            <Link to="" className={ styles.notifications__viewAll__link }>
              <FormattedMessage { ...messages.buttons.viewAllNotifications } />
            </Link>
          </div>
        */}
      </motion.div>
    );
  }


  render() {
    return (
      <AnimatePresence>
        { this.renderPopover() }
      </AnimatePresence>
    );
  }

}


const mapStateToProps = (state) => ({
  notifications        : selectors.newestNotifications(state),
  isInProgress         : selectors.isFetchNotificationsInProgress(state),
  passphrase           : Account.selectors.passphrase(state),
  keyPair              : Account.selectors.keyPair(state),
  hcpClinicMemberships : Account.selectors.hcpClinicMemberships(state),
  notificationsSettings: Account.selectors.notificationsSettings(state),
  localizationResources: App.selectors.localizationResources(state),
  openDropdownId       : App.selectors.dropdown(state),
});


const mapDispatchToProps = (dispatch) => ({
  onCreateSignalRHub: (hub, receiveAction) => dispatch(App.actions.createSignalRHub(hub, receiveAction)),
  onFetchList       : () => dispatch(actions.fetchList()),
  onDismiss         : (notification) => dispatch(actions.dismissNotification(notification)),
  onMarkRead        : (notificationsIds) => dispatch(actions.markNotificationsRead(notificationsIds)),
  onCloseDropdown   : () => dispatch(App.actions.closeDropdown()),
  openSettingsModal : () => dispatch(App.actions.openModal(constants.NOTIFICATIONS_SETTINGS_MODAL)),
});


const ConnectedNotificationsPopover = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsPopover);


export default withStyles(styles)(ConnectedNotificationsPopover);
