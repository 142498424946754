import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import reverse from 'lodash/reverse';
import reduce from 'lodash/reduce';
import xor from 'lodash/xor';
import orderBy from 'lodash/orderBy';


const getPersonalIdentifierFilter = (item, search) => {
  const id = get(item, 'personalIdentifier.personalIdentifierValue');
  return id && includes(toLower(id), search);
};


const getFirstName = (item, pathStr) => toLower(get(item, `${pathStr}firstName`));

const getLastName = (item, pathStr) => toLower(get(item, `${pathStr}lastName`));

const getNamesFilter = (item, search, pathStr) => {
  const firstName = getFirstName(item, pathStr);
  const lastName = getLastName(item, pathStr);
  return includes(`${firstName} ${lastName}`, search) || includes(`${lastName} ${firstName}`, search);
};


const getFilter = (item, search, pathStr) => getPersonalIdentifierFilter(item, search) || getNamesFilter(item, search, pathStr);


export default function (collection, collectionIdKey, search, state, maxLength = -1, dataPath = '', orderKeys = [], orders = []) {
  const pathStr = dataPath && `${dataPath}.`;
  if (maxLength < 0) {
    maxLength = collection.length;
  }

  if (maxLength === 0) {
    return {
      search,
      filteredCollection   : [],
      filteredCollectionIds: [],
    };
  }

  let filteredCollection = search
    ? filter(collection, (item) => getFilter(item, search, pathStr))
    : [...collection];

  filteredCollection = orderBy(reverse(filteredCollection), orderKeys, orders);

  if (filteredCollection) {
    filteredCollection = filteredCollection.slice(0, maxLength);
  }

  const filteredCollectionIds = reduce(filteredCollection, (acc, item) => {
    acc.push(item[collectionIdKey]);
    return acc;
  }, []);
  const diff = xor(filteredCollectionIds, state.filteredCollectionIds);

  if (!isEmpty(diff)) {
    return {
      search,
      filteredCollection,
      filteredCollectionIds,
    };
  }

  if (search !== state.search) {
    return {
      search,
    };
  }

  return null;
}
