import { MODULE_ID } from './constants';


export const SET_PRINT_MODE = `${MODULE_ID}/SET_PRINT_MODE`;
export const SET_MENU_OPEN = `${MODULE_ID}/SET_MENU_OPEN`;
export const SET_SELECTED_MENU_ITEM = `${MODULE_ID}/SET_SELECTED_MENU_ITEM`;
export const SET_AGP_VIEW = `${MODULE_ID}/SET_AGP_VIEW`;
export const SET_WINDOW_WIDTH = `${MODULE_ID}/SET_WINDOW_WIDTH`;

export const SET_HIDE_EMPTY_ROWS = `${MODULE_ID}/SET_HIDE_EMPTY_ROWS`;
export const SET_SHOW_DAY_DIVIDERS = `${MODULE_ID}/SET_SHOW_DAY_DIVIDERS`;
