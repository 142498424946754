import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import toNumber from 'lodash/toNumber';
import { METRICS, UNITS_SYMBOLS } from 'modules/App/constants';


const getToDisplay = ({ metric, value, unit, precision }) => {
  const metricConf = METRICS[metric];
  if (!metricConf || !isNumber(value)) return null;
  if (unit === metricConf.unit) {
    const p = isNumber(precision) ? precision : get(metricConf, 'precisions.toDisplay', 2);
    return toNumber(value.toFixed(p));
  }
  const conversion = get(metricConf, ['conversions', unit]);
  if (!conversion) return null;
  const p = isNumber(precision) ? precision : get(conversion, 'precisions.toDisplay', 2);
  return toNumber((value * conversion.factor).toFixed(p));
};


const getToStorage = ({ metric, value, unit, precision }) => {
  const metricConf = METRICS[metric];
  if (!metricConf || !isNumber(value)) return null;
  if (unit === metricConf.unit) {
    const p = precision || get(metricConf, 'precisions.toStorage', 3);
    return toNumber(value.toFixed(p));
  }
  const conversion = get(metricConf, ['conversions', unit]);
  if (!conversion) return null;
  const p = precision || get(conversion, 'precisions.toStorage', 3);
  return toNumber((value / conversion.factor).toFixed(p));
};


const metricFactory = Symbol('metricFactory');


export default class MetricConversions {

  constructor(metricsUnits) {
    this.metricsUnits = metricsUnits;
  }


  [metricFactory](metric) {
    const unit = this.getUnit(metric);
    return {
      toDisplay : (value, precision) => getToDisplay({ metric, value, unit, precision }),
      toStorage : (value, precision) => getToStorage({ metric, value, unit, precision }),
      unitSymbol: get(UNITS_SYMBOLS, unit),
      unit,
    };
  }


  getUnit(metric) {
    return get(this.metricsUnits, metric);
  }


  get weight() {
    return this[metricFactory]('weight');
  }


  get height() {
    return this[metricFactory]('height');
  }


  get pressure() {
    return this[metricFactory]('pressure');
  }


  get bloodGlucoseConcentration() {
    return this[metricFactory]('bloodGlucoseConcentration');
  }


  get calcium() {
    return this[metricFactory]('calcium');
  }


  get cholesterol() {
    return this[metricFactory]('cholesterol');
  }


  get creatinine() {
    return this[metricFactory]('creatinine');
  }


  get phosphorus() {
    return this[metricFactory]('phosphorus');
  }


  get potassium() {
    return this[metricFactory]('potassium');
  }


  get sodium() {
    return this[metricFactory]('sodium');
  }


  get triglycerides() {
    return this[metricFactory]('triglycerides');
  }


  get filtration() {
    return this[metricFactory]('filtration');
  }

  get CockcroftGaultFiltration() {
    return this[metricFactory]('CockcroftGaultFiltration');
  }


  get HbA1C() {
    return this[metricFactory]('HbA1C');
  }

}
