import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import get from 'lodash/get';
import find from 'lodash/find';
import { motion, AnimatePresence } from 'framer-motion';
import withStyles from 'isomorphic-style-loader/withStyles';
import { AppContext } from 'context';
import history from 'helpers/history';
import BackButton from 'components/BackButton';
import Button from 'components/Form/Button';
import keyPairShape from 'shapes/keyPairShape';
import Loader from 'svg/loader.svg';
import urlRouteShape from 'shapes/urlRouteShape';
import App from 'modules/App';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from './AttachCloudDrive.pcss';


class AttachCloudDrive extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Implicit props
    clinicId                : PropTypes.number,
    activeClinicMembershipId: PropTypes.number,
    authorizationCode       : CloudDrive.shapes.authorizationCode,
    hcpClinicMemberships    : PropTypes.arrayOf(Account.shapes.clinicMembership),
    keyPair                 : keyPairShape,
    passphrase              : PropTypes.string,
    formValues              : PropTypes.object,
    isInProgress            : PropTypes.bool,
    hasErrors               : PropTypes.bool,
    countrySettings         : PropTypes.shape({
      isCentralStorageFixed: PropTypes.bool,
      countryId            : PropTypes.number,
    }),
    countries                  : PropTypes.arrayOf(App.shapes.country),
    hcpProfileMainUrlRoute     : urlRouteShape,
    isInProgressCountrySettings: PropTypes.bool,
    hasErrorsCountrySettings   : PropTypes.bool,
    clinicCountrySettings      : PropTypes.shape({
      isCentralStorageFixed: PropTypes.bool,
      countryId            : PropTypes.number,
    }),
    hasGetStoredClinicErrors    : PropTypes.bool,
    isGetStoredClinicInProgress : PropTypes.bool,
    // Implicit actions
    onClearAuthorizationCode    : PropTypes.func,
    onSetFormValue              : PropTypes.func,
    onSetFormValues             : PropTypes.func,
    onSubmit                    : PropTypes.func,
    onClearForm                 : PropTypes.func,
    onAuthorizeCloudDrive       : PropTypes.func,
    onFetchClinicCountrySettings: PropTypes.func,
  };


  constructor(props, context) {
    super(props);
    const { clinicId, hcpClinicMemberships, hcpProfileMainUrlRoute, countrySettings, authorizationCode } = props;
    const { getUrl, redirect } = context;

    this.clinic = get(find(hcpClinicMemberships, { clinicId }), 'clinic');
    if (this.clinic && this.clinic.storageAccount) {
      const { name, params } = hcpProfileMainUrlRoute;
      const url = getUrl(name, params);
      redirect(url);
      return;
    }

    const accountCountryId = get(countrySettings, 'countryId', 0);
    const clinicCountryId = get(this.clinic, 'countryId', 0);

    this.isDiffCountryIds = accountCountryId !== clinicCountryId;

    if (this.isDiffCountryIds) {
      props.onFetchClinicCountrySettings(clinicCountryId);
    } else if (this.isCentralStorageFixed) {
      props.onAuthorizeCloudDrive();
    }

    if (authorizationCode && authorizationCode.storageProvider === 'GoogleDrive') {
      this.props.onSubmit({ ...this.clinic, authorizationCode, clinicId });
      return;
    }

    props.onSetFormValues({
      storageProvider: 'CentralStorage',
    });
  }


  componentDidUpdate(prevProps) {
    const { clinicId, authorizationCode, hcpProfileMainUrlRoute,
      isInProgressCountrySettings, isInProgress } = this.props;

    if (!isInProgressCountrySettings && prevProps.isInProgressCountrySettings) {
      if (this.isCentralStorageFixed) {
        this.props.onAuthorizeCloudDrive();
      }
    }

    if (prevProps.authorizationCode !== authorizationCode && authorizationCode) {
      this.props.onSubmit({ ...this.clinic, authorizationCode, clinicId });
    }

    if (prevProps.isInProgress !== isInProgress && !isInProgress) {
      this.props.onClearForm();
      this.props.onClearAuthorizationCode();
      const { name, params } = hcpProfileMainUrlRoute;
      const url = this.context.getUrl(name, params);
      history.push(url);
    }
  }


  onSetStorageProvider(input) {
    this.props.onSetFormValue(input);
  }


  get isCentralStorageFixed() {
    const { countrySettings, clinicCountrySettings, hasErrorsCountrySettings } = this.props;

    if (hasErrorsCountrySettings || !this.isDiffCountryIds) {
      return get(countrySettings, 'isCentralStorageFixed', false);
    }
    return get(clinicCountrySettings, 'isCentralStorageFixed', false);
  }


  renderHeader() {
    if (!this.clinic) {
      return (
        <>
          <h1 className="text--h1"><FormattedMessage {...messages.headers.setCloudDriveLoading} /></h1>
          <div
            className={cn(styles.loaderContainer, 'mt-4')}
          >
            <Loader className={cn(styles.loader, 'rotatingLoader')} />
          </div>
        </>
      );
    }

    const headerText = this.isCentralStorageFixed
      ? messages.headers.setCloudDriveLoading : messages.headers.setCloudDrive;
    const introsText = this.isCentralStorageFixed
      ? messages.intros.setCloudDriveLoading : messages.intros.setCloudDrive;
    const clinic = get(this.clinic, 'name');
    return (
      <>
        <h1 className="text--h1"><FormattedMessage {...headerText} values={{ clinic }} /></h1>
        <p className="pageHeader__intro"><FormattedMessage {...introsText} /></p>
      </>
    );
  }


  renderContent() {
    const { isInProgressCountrySettings, hasErrorsCountrySettings, hasErrors } = this.props;
    if (this.isCentralStorageFixed || isInProgressCountrySettings) {
      if (hasErrors || hasErrorsCountrySettings) {
        return (
          <Button
            styleModifier="primary"
            labelMessage={messages.buttons.tryAgain}
            className="btn--filled"
            onClick={() => this.props.onAuthorizeCloudDrive()}
          />
        );
      }
      return (
        <AnimatePresence>
          <motion.div
            initial={{ x: this.props.isInProgress ? 0 : '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ ease: 'easeOut', duration: 0.15 }}
            className={cn(styles.loaderContainer)}
          >
            <Loader className={cn(styles.loader, 'rotatingLoader')} />
          </motion.div>
        </AnimatePresence>
      );
    }
    return (
      <CloudDrive.partials.SetCloudDrive
        type="clinic"
        keyPair={this.props.keyPair}
        formValues={this.props.formValues}
        isInProgress={this.props.isInProgress}
        onSetFormValue={(input) => this.onSetStorageProvider(input)}
        onSetFormValues={this.props.onSetFormValues}
      />
    );
  }


  render() {
    const backButtonUrl = `${this.context.domain}/aid/organization/choose`;
    return (
      <div>
        <header className="pageHeader">
          <BackButton to={backButtonUrl} />
          { this.renderHeader() }
        </header>
        <App.components.AlertsBus className="mb-5" />
        { this.renderContent() }
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  clinicId                   : Account.selectors.activeClinicId(state),
  isInProgress               : selectors.isSetClinicCloudDriveInProgress(state),
  hasErrors                  : selectors.hasSetClinicCloudDriveErrors(state),
  clinicCountrySettings      : selectors.countrySettings(state),
  isInProgressCountrySettings: selectors.isFetchClinicCountrySettingsInProgress(state),
  hasErrorsCountrySettings   : selectors.hasFetchClinicCountrySettingsErrors(state),
  hcpClinicMemberships       : Account.selectors.hcpClinicMemberships(state),
  keyPair                    : Account.selectors.keyPair(state),
  passphrase                 : Account.selectors.passphrase(state),
  hcpProfileMainUrlRoute     : Account.selectors.hcpProfileMainUrlRoute(state),
  formValues                 : App.selectors.formSelector(constants.CREATE_CLINIC_FORM)(state),
  authorizationCode          : CloudDrive.selectors.authorizationCode(state),
  countries                  : App.selectors.countries(state),
  countrySettings            : App.selectors.countrySettings(state),
});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.CREATE_CLINIC_FORM;
  return {
    onSubmit                    : (clinicValues) => dispatch(actions.setClinicCloudDrive(clinicValues)),
    onFetchClinicCountrySettings: (countryId) => dispatch(actions.fetchClinicCountrySettings(countryId)),
    onClearAuthorizationCode    : () => dispatch(CloudDrive.actions.clearAuthorizationCode()),
    onSetFormValue              : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onSetFormValues             : (values) => dispatch(App.actions.setFormValues(formName, values)),
    onClearForm                 : () => dispatch(App.actions.clearForm(formName)),
    onAuthorizeCloudDrive       : () => dispatch(CloudDrive.actions.authorize('CentralStorage', 'clinic')),
  };
};


const ConnectedAttachCloudDrive = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AttachCloudDrive);


export default withStyles(styles)(ConnectedAttachCloudDrive);
