import moment from 'moment';
import keys from 'lodash/keys';


export const getNowAsUtc = () => moment.utc(moment().format('YYYY-MM-DD HH:mm:ss'));


export const getWeekdays = () => keys(Array(7));


export const getDaysInMonth = (month, year) => (
  month
    ? moment(`${year || moment().year()}-${month}`, 'Y-M').daysInMonth()
    : 31
);


export const getDays = (month, year) => {
  const daysInMonth = getDaysInMonth(month, year);
  const days = [];
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(i);
  }
  return days;
};


export const getMonths = () => Array.apply(0, Array(12))
  .map((_, i) => moment().month(i).format('MMMM'));


export const getYears = (yearsAgo = 120) => {
  const currentYear = moment().year();
  const lastYear = currentYear - yearsAgo;
  const years = [];
  for (let i = currentYear; i > lastYear; i--) {
    years.push(i);
  }
  return years;
};


// export const getD3LocaleDateFormat = () => {
//   const dict = {
//     DD  : '%d',
//     MM  : '%m',
//     YYYY: '%Y',
//   };
//   const localeFormat = moment.localeData().longDateFormat('L');
//   return replace(localeFormat, /DD|MM|YYYY/g, (matched) => dict[matched]);
// };


export const formatDate = (date, format = 'L') => {
  if (!date) {
    return null;
  }
  return moment.utc(date).format(format);
};


export function formatTimestamp(timestamp, format = 'L') {
  if (!timestamp) {
    return null;
  }
  return moment.unix(timestamp).utc().format(format);
}


export const formatDateAsObject = (date) => {
  if (!date) {
    return null;
  }
  const momentDate = moment.utc(new Date(date)).local();
  return {
    day  : momentDate.date(),
    month: momentDate.month() + 1,
    year : momentDate.year(),
  };
};


export const isSameDay = (timestamp, otherTimestamp) => {
  if (!timestamp || !otherTimestamp) {
    return false;
  }
  const timestampMoment = moment.unix(timestamp).utc();
  const otherTimestampMoment = moment.unix(otherTimestamp).utc();
  return timestampMoment.isSame(otherTimestampMoment, 'day');
};


export const formatISODate = (date, isNoTime = false) => {
  if (!date) {
    return null;
  }
  const momentDate = moment.utc(date);
  if (isNoTime) {
    momentDate.startOf('day');
  }
  return momentDate.toISOString();
};


export const getSecondsFromMidnight = (timestamp) => {
  const dateMoment = moment.unix(timestamp).utc();
  const midnightMoment = dateMoment.clone().startOf('day');
  return dateMoment.diff(midnightMoment, 'seconds');
};

// Converts string eg. 08:00 to moment Date
export const convertFromTimeString = (value) => moment(value, 'HH:mm').format('LT');
