import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import MetricConversions from 'libs/MetricConversions';
import Form from 'components/Form';
import Button from 'components/Form/Button';
import App from 'modules/App';
import Account from 'modules/Account';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import KpiSetting from '../KpiSetting';
import styles from './KpiSettings.pcss';


class KpiSettings extends React.PureComponent {


  static propTypes = {
    // Explicit props
    clinicId                    : PropTypes.number,
    // Implicit props
    activeClinicMembership      : Account.shapes.clinicMembership,
    isUpdateInProgress          : PropTypes.bool,
    hasUpdateErrors             : PropTypes.bool,
    hasElevatedPermissions      : PropTypes.bool,
    elevatedPermissionsMode     : PropTypes.string,
    formValues                  : PropTypes.object,
    metricsUnits                : PropTypes.object, // @TODO: Shape
    // Implicit actions
    onFetchClinicMemberships    : PropTypes.func,
    onFetchHcpMemberships       : PropTypes.func,
    onSetElevatedPermissionsMode: PropTypes.func,
    onSetFormValues             : PropTypes.func,
    onClearForm                 : PropTypes.func,
    onCommitForm                : PropTypes.func,
    onResetForm                 : PropTypes.func,
    onSetFormValue              : PropTypes.func,
    onFormProcessing            : PropTypes.func,
    onSubmit                    : PropTypes.func,

  };


  constructor(props) {
    super(props);
    const metricConversions = new MetricConversions(props.metricsUnits);
    this.conversion = metricConversions.bloodGlucoseConcentration;
  }


  componentDidMount() {
    this.onPopulate();
  }


  componentDidUpdate(prevProps) {
    const { activeClinicMembership, isUpdateInProgress, hasUpdateErrors } = this.props;

    if (get(prevProps, 'activeClinicMembership.clinic') !== get(activeClinicMembership, 'clinic')) {
      this.onPopulate();
    }

    if (prevProps.isUpdateInProgress !== isUpdateInProgress && !isUpdateInProgress && !hasUpdateErrors) {
      this.props.onCommitForm();
      this.props.onFetchHcpMemberships();
      this.props.onFetchClinicMemberships();
    }

    if (
      prevProps.elevatedPermissionsMode !== this.props.elevatedPermissionsMode
        && !this.props.elevatedPermissionsMode
    ) {
      this.onPopulate();
    }
  }


  componentWillUnmount() {
    this.props.onClearForm();
  }


  onPopulate() {
    const { activeClinicMembership } = this.props;
    const settings = get(activeClinicMembership, 'clinic.settings');
    if (settings) {
      if (settings && settings.kpi) {
        Object.keys(settings.kpi).forEach((kpiType) => {
          const kpiItem = settings.kpi[kpiType];
          if (kpiItem) {
            Object.keys(kpiItem).forEach((item) => {
              if (isObject(kpiItem[item])) {
                Object.keys(kpiItem[item]).forEach((value) => {
                  let result = parseFloat(kpiItem[item][value]);
                  if (kpiType === 'standardDeviation') {
                    result = this.conversion.toDisplay(result);
                  }
                  this.onSetValue({ id: `kpi-${kpiType}-diabetesType-${item}-${value}`, value: result });
                });
              } else {
                let result = parseFloat(kpiItem[item]);
                if (kpiType === 'standardDeviation') {
                  result = this.conversion.toDisplay(result);
                }
                this.onSetValue({ id: `kpi-${kpiType}-${item}`, value: result });
              }
            });
          }
        });
      }

      this.props.onSetElevatedPermissionsMode(null);
    }
  }


  onSetValue(input) {
    const { id, value } = input;
    if (!value) {
      this.props.onSetFormValue({ id, value });
      return;
    }
    const { unitSymbol } = this.conversion;
    let valueToShow = value.toString();

    if (id.includes('veryLow') || id.includes('veryHigh')) {
      valueToShow = `> ${value.toString().replace(/[^\d.-]/g, '')}`;
    }

    if (id.includes('CV')) {
      valueToShow = `${value.toString().replace(/[^\d.-]/g, '')}%`;
    }

    if (id.includes('standardDeviation')) {
      valueToShow = `${value.toString().replace(/[^\d.-]/g, '')}${unitSymbol}`;
    }

    if (id.includes('GMI')) {
      if (id.includes('valueHigh')) {
        valueToShow = `> ${value.toString().replace(/[^\d.-]/g, '')}%`;
      }
      if (id.includes('valueLow')) {
        valueToShow = `< ${value.toString().replace(/[^\d.-]/g, '')}%`;
      }
    }

    if (!valueToShow.match(/\d+/g)) {
      valueToShow = '';
    }

    if (!isNumber(parseFloat(valueToShow.replace(/[^\d.-]/g, '')))) {
      return;
    }
    this.props.onSetFormValue({ id, value: valueToShow });
  }


  onSubmit() {
    this.props.onFormProcessing();
    const preparedValues = this.prepareValuesForSubmit();

    this.props.onSubmit(preparedValues);
  }


  onClose() {
    this.props.onSetElevatedPermissionsMode(null);
  }


  get isClean() {
    return !get(this.props.formValues, 'originalValues');
  }


  prepareValuesForSubmit() {
    const kpi = {};

    const { formValues } = this.props;
    if (!formValues) return kpi;

    const { values } = formValues;

    if (!values) return kpi;

    Object.keys(values).forEach((value) => {
      if (!value) return;
      if (value.includes('kpi')) {
        if (value.includes('diabetesType')) {
          const objectValue = value.split('-');

          if (objectValue.length > 4) {
            let result = values[value] ? values[value].toString() : '';
            result = parseFloat(result.replace(/[^\d.-]/g, ''));
            if (objectValue[3] === 'standardDeviation') {
              result = this.conversion.toStorage(result);
            }
            if (!kpi[objectValue[1]]) {
              kpi[objectValue[1]] = {};
            }

            if (!kpi[objectValue[1]][kpi[objectValue[3]]]) {
              kpi[objectValue[1]][kpi[objectValue[3]]] = {};
            }
            kpi[objectValue[1]] = {
              ...kpi[objectValue[1]],
              [objectValue[3]]: {
                ...kpi[objectValue[1]][objectValue[3]],
                [objectValue[4]]: result,
              },
            };
          }
        } else {
          const objectValue = value.split('-');

          if (objectValue.length > 2) {
            let result = parseFloat(values[value].replace(/[^\d.-]/g, ''));
            if (objectValue[1] === 'standardDeviation') {
              result = this.conversion.toStorage(result);
            }
            if (!kpi[objectValue[1]]) {
              kpi[objectValue[1]] = {};
            }
            kpi[objectValue[1]] = {
              ...kpi[objectValue[1]],
              [objectValue[2]]: result,
            };
          }
        }
      }
    });

    return {
      kpi,
    };
  }


  renderHeader() {
    return (
      <h2 className="section__header ">
        <FormattedMessage {...messages.headers.kpi} />
      </h2>
    );
  }


  renderCancelBtn() {
    return (
      <Button
        styleModifier="primary"
        className="btn--block"
        labelMessage={App.messages.buttons.discardChanges}
        isDisabled={this.isClean}
        onClick={() => this.props.onResetForm()}
      />
    );
  }


  renderSubmitBtn() {
    const { hasElevatedPermissions } = this.props;
    return (
      <Button
        type="submit"
        styleModifier="primary"
        labelMessage={App.messages.buttons.saveChanges}
        className="btn--block btn--filled"
        isDisabled={!hasElevatedPermissions || this.isClean}
        isInProgress={this.props.isUpdateInProgress}
      />
    );
  }


  renderActions() {
    return (
      <div className="row">
        <div className="col-6">{this.renderCancelBtn()}</div>
        <div className="col-6">{this.renderSubmitBtn()}</div>
      </div>
    );
  }


  renderKpis() {
    const { hasElevatedPermissions, formValues } = this.props;
    return (
      <>
        <KpiSetting
          type="veryHigh"
          isDisabled={!hasElevatedPermissions}
          formValues={formValues}
          onSetValue={(evt) => this.onSetValue(evt)}
          possibleOptions={['allowedMeasurements']}
          allowConigurationPerDiabetesType
          conversion={this.conversion}
          labelMessage={messages.infos.veryHigh}
        />
        <KpiSetting
          type="veryLow"
          isDisabled={!hasElevatedPermissions}
          formValues={formValues}
          onSetValue={(evt) => this.onSetValue(evt)}
          possibleOptions={['allowedMeasurements']}
          allowConigurationPerDiabetesType
          conversion={this.conversion}
          labelMessage={messages.infos.veryLow}
        />
        <KpiSetting
          type="standardDeviation"
          isDisabled={!hasElevatedPermissions}
          formValues={formValues}
          onSetValue={(evt) => this.onSetValue(evt)}
          possibleOptions={['valueHigh', 'valueLow']}
          allowConigurationPerDiabetesType
          conversion={this.conversion}
        />
        <KpiSetting
          type="CV"
          isDisabled={!hasElevatedPermissions}
          formValues={formValues}
          onSetValue={(evt) => this.onSetValue(evt)}
          possibleOptions={['valueHigh']}
          allowConigurationPerDiabetesType
          conversion={this.conversion}
        />
        <KpiSetting
          type="GMI"
          isDisabled={!hasElevatedPermissions}
          formValues={formValues}
          onSetValue={(evt) => this.onSetValue(evt)}
          possibleOptions={['valueHigh', 'valueLow']}
          allowConigurationPerDiabetesType
          conversion={this.conversion}
        />
        <KpiSetting
          type="readingsPerDay"
          isDisabled={!hasElevatedPermissions}
          formValues={formValues}
          onSetValue={(evt) => this.onSetValue(evt)}
          possibleOptions={['valueLow']}
          allowConigurationPerDiabetesType
          conversion={this.conversion}
        />
      </>
    );
  }


  render() {
    return (
      <div>
        {this.renderHeader()}
        <div className="row">
          <div className="col">
            <Form className={styles.form} onSubmit={() => this.onSubmit()}>
              { this.renderKpis() }
              { this.renderActions() }
            </Form>
          </div>
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  hasElevatedPermissions : Account.selectors.hasElevatedPermissions(state),
  elevatedPermissionsMode: Account.selectors.elevatedPermissionsMode(state),
  metricsUnits           : Account.selectors.metricsUnits(state),
  activeClinicMembership : Account.selectors.activeClinicMembership(state),
  isUpdateInProgress     : selectors.isUpdateClinicKpiInProgress(state),
  hasUpdateErrors        : selectors.hasUpdateClinicKpiErrors(state),
  clinicMemberships      : selectors.clinicMemberships(state),
  formValues             : App.selectors.formSelector(constants.CLINIC_KPI_SETTINGS_FORM)(state),
});


const mapDispatchToProps = (dispatch, props) => {
  const formName = constants.CLINIC_KPI_SETTINGS_FORM;
  return {
    onSubmit                    : (settings) => dispatch(actions.updateClinicKpiSettings(props.clinicId, settings)),
    onFetchClinicMemberships    : () => dispatch(actions.fetchClinicMemberships(props.clinicId)),
    onSetElevatedPermissionsMode: (value) => dispatch(Account.actions.setElevatedPermissionsMode(value)),
    onFetchHcpMemberships       : () => dispatch(Account.actions.fetchMemberships()),
    onSetFormValue              : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onSetFormValues             : (values) => dispatch(App.actions.setFormValues(formName, values)),
    onFormProcessing            : () => dispatch(App.actions.startFormProcessing(formName)),
    onClearForm                 : () => dispatch(App.actions.clearForm(formName)),
    onCommitForm                : () => dispatch(App.actions.commitForm(formName)),
    onResetForm                 : () => dispatch(App.actions.resetForm(formName)),
  };
};


const ConnectedKpiSettings = connect(
  mapStateToProps,
  mapDispatchToProps,
)(KpiSettings);


export default withStyles(styles)(ConnectedKpiSettings);
