import { MODULE_ID } from './constants';


export const CHECK_INFORMATION = `${MODULE_ID}/CHECK_INFORMATION`;
export const CHECK_INFORMATION_SUCCESS = `${MODULE_ID}/CHECK_INFORMATION_SUCCESS`;
export const CHECK_INFORMATION_ERROR = `${MODULE_ID}/CHECK_INFORMATION_ERROR`;

export const UPDATE_INFORMATION = `${MODULE_ID}/UPDATE_INFORMATION`;
export const UPDATE_INFORMATION_SUCCESS = `${MODULE_ID}/UPDATE_INFORMATION_SUCCESS`;
export const UPDATE_INFORMATION_ERROR = `${MODULE_ID}/UPDATE_INFORMATION_ERROR`;

export const CHECK_ORGANIZATION_MEMBERSHIPS = `${MODULE_ID}/CHECK_ORGANIZATION_MEMBERSHIPS`;
export const CHECK_ORGANIZATION_MEMBERSHIPS_SUCCESS = `${MODULE_ID}/CHECK_ORGANIZATION_MEMBERSHIPS_SUCCESS`;
export const CHECK_ORGANIZATION_MEMBERSHIPS_ERROR = `${MODULE_ID}/CHECK_ORGANIZATION_MEMBERSHIPS_ERROR`;
