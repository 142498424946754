import valuesIn from 'lodash/valuesIn';
import PropTypes from 'prop-types';
import * as constants from './constants';


export const reading = PropTypes.shape({
  timestamp   : PropTypes.number.isRequired,
  flag        : PropTypes.oneOf(constants.FLAGS_ORDER).isRequired,
  type        : PropTypes.oneOf(valuesIn(constants.MEASUREMENT_TYPES)).isRequired,
  value       : PropTypes.number.isRequired,
  displayValue: PropTypes.number.isRequired,
  isActive    : PropTypes.bool,
});
