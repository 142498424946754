/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/withStyles';
// import AutoSizer from 'react-virtualized-auto-sizer';
// import { motionConfigContext, SmartMotion } from '@nivo/core';
// import { Line } from '@nivo/line';
// import { area, curveMonotoneX } from 'd3-shape';
import range from 'lodash/range';
import { highlightedHourlyRecordsShape } from 'shapes/bins';
import styles from './BloodGlucoseProfileChart.pcss';


/**
 * @deprecated
 */
class BloodGlucoseProfileChart extends React.PureComponent {

  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      minValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    highlightedHourlyRecords: highlightedHourlyRecordsShape,
    direction               : PropTypes.string,
  };


  constructor(props) {
    super(props);
    this.colors = {
      line           : '#1EA98C',
      percentile     : '#CCD7E8',
      percentileInner: '#89A5CB',
      stroke         : '#E0E8F2',
    };
  }


  get chartData() {
    const { highlightedHourlyRecords, conversion } = this.props;
    return [
      {
        id  : 'Glucose Profile',
        data: highlightedHourlyRecords.records.map((hourlyRecord) => ({
          x   : Number(hourlyRecord.hour) + 0.5,
          y   : conversion.toDisplay(hourlyRecord.percentileStatistics[50]),
          p5  : conversion.toDisplay(hourlyRecord.percentileStatistics[5]),
          p25 : conversion.toDisplay(hourlyRecord.percentileStatistics[25]),
          p75 : conversion.toDisplay(hourlyRecord.percentileStatistics[75]),
          p95 : conversion.toDisplay(hourlyRecord.percentileStatistics[95]),
          hour: hourlyRecord.hour,
        })),
      },
    ];
  }


  // percentiles({ series, xScale, yScale }) {
  //   const outerPercentileGenerator = area()
  //     .x((d) => xScale(d.data.x))
  //     .y0((d) => yScale(d.data.p95))
  //     .y1((d) => yScale(d.data.p5))
  //     .curve(curveMonotoneX);
  //
  //   const innerPercentileGenerator = area()
  //     .x((d) => xScale(d.data.x))
  //     .y0((d) => yScale(d.data.p75))
  //     .y1((d) => yScale(d.data.p25))
  //     .curve(curveMonotoneX);
  //
  //   return (
  //     <g opacity={0.7}>
  //       <path
  //         d={outerPercentileGenerator(series[0].data)}
  //         fill={this.colors.percentile}
  //         fillOpacity={1}
  //         stroke={this.colors.stroke}
  //         strokeWidth={1}
  //         strokeDasharray="5,5"
  //       />
  //       <path
  //         d={innerPercentileGenerator(series[0].data)}
  //         fill={this.colors.percentileInner}
  //         fillOpacity={1}
  //       />
  //     </g>
  //   );
  // }


  renderTargetZone(props) {
    const { conversion, standards } = this.props;
    const lowThreshold = conversion.toDisplay(standards.postMeal.lowThreshold);
    const highThreshold = conversion.toDisplay(standards.postMeal.highThreshold);
    const width = props.width - props.margin.right - props.margin.left;
    const yTop = props.yScale(highThreshold);
    const yBottom = props.yScale(lowThreshold);

    return null;

    // return (
    //   <motionConfigContext.Consumer>
    //     { (springConfig) => (
    //       <SmartMotion
    //         key="bloodGlucoseConcentrationTargetZone"
    //         style={(spring) => ({
    //           width: spring(width, springConfig),
    //         })}
    //       >
    //         {(style) => (
    //           <g>
    //             <line
    //               x1="0"
    //               y1={yTop}
    //               x2={style.width}
    //               y2={yTop}
    //               style={{ stroke: this.colors.line }}
    //             />
    //             <line
    //               x1="0"
    //               y1={yBottom}
    //               x2={style.width}
    //               y2={yBottom}
    //               style={{ stroke: this.colors.line }}
    //             />
    //           </g>
    //         )}
    //       </SmartMotion>
    //     )}
    //   </motionConfigContext.Consumer>
    // );
  }


  renderTooltip(point) {
    const { hour, y: p50, p5, p25, p75, p95 } = point.data;
    const { conversion, direction } = this.props;
    const { unitSymbol } = conversion;

    return (
      <div className={styles.tooltip}>
        <p className="text--light row flex-nowrap justify-content-between" style={{ direction }}>
          <span className="col-auto">
            { `${String(+hour).padStart(2, '0')}:00 - ${String(+hour + 1).padStart(2, '0')}:00` }
          </span>
        </p>
        <div className="p">
          <p className="text--large text--bold text--primary">P5 = { p5 } { unitSymbol }</p>
          <p className="text--large text--bold text--primary">P25 = { p25 } { unitSymbol }</p>
          <p className="text--large text--bold text--primary">P50 = { p50 } { unitSymbol }</p>
          <p className="text--large text--bold text--primary">P75 = { p75 } { unitSymbol }</p>
          <p className="text--large text--bold text--primary">P95 = { p95 } { unitSymbol }</p>
        </div>
      </div>
    );
  }


  render() {
    return null;

    // const { direction, conversion, standards } = this.props;
    // const margin = {
    //   top   : 30,
    //   right : direction === 'rtl' ? 30 : 20,
    //   bottom: 30,
    //   left  : direction === 'ltr' ? 30 : 20,
    // };
    // const axisY = {
    //   tickSize    : 5,
    //   tickPadding : 5,
    //   tickRotation: 0,
    //   tickValues  : 5,
    // };
    /*
      IMPORTANT: Because Edge doesn't support `dominant-baseline` and RTL support is poor
      hardcoded styles overwrite ticks labels transform for current tickPadding and tickRotation values and
      must be correlated if change
     */
    // return (
    //   <div className={`nivoChart ${styles.root}`}>
    //     <div
    //       className={`nivoChart__inner ${styles.root__inner}`}
    //     >
    //       <AutoSizer>
    //         {
    //           ({ height, width }) => (
    //             <Line
    //               data={this.chartData}
    //               height={height}
    //               width={width}
    //               margin={margin}
    //               xScale={{ type: 'linear', min: 0, max: 24, reverse: direction === 'rtl' }}
    //               yScale={{
    //                 type: 'linear',
    //                 min : conversion.toDisplay(standards.minValue),
    //                 max : conversion.toDisplay(standards.maxValue),
    //               }}
    //               axisTop={null}
    //               axisRight={direction === 'ltr' ? null : axisY}
    //               axisLeft={direction === 'rtl' ? null : axisY}
    //               axisBottom={{
    //                 tickSize  : 0,
    //                 tickValues: range(24.5),
    //                 format    : (value) => (value % 3 ? null : moment(value, 'hh').format('ha')),
    //               }}
    //               enableGridX={false}
    //               colors={[this.colors.line]}
    //               lineWidth={2}
    //               pointSize={0}
    //               crosshairType={direction === 'ltr' ? 'bottom-left' : 'bottom-right'}
    //               tooltip={({ point }) => this.renderTooltip(point)}
    //               curve="monotoneX"
    //               useMesh
    //               layers={[
    //                 'grid', 'markers', 'axes',
    //                 'areas',
    //                 this.percentiles.bind(this),
    //                 this.renderTargetZone.bind(this),
    //                 'crosshair',
    //                 'lines',
    //                 'slices',
    //                 'mesh',
    //                 'legends',
    //               ]}
    //             />
    //           )
    //         }
    //       </AutoSizer>
    //     </div>
    //   </div>
    // );
  }

}


export default withStyles(styles)(BloodGlucoseProfileChart);
