import PropTypes from 'prop-types';


const templateFieldObj = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'string', 'textArea', 'integer', 'decimal', 'bool',
    'date', 'email', 'image',
    'group', 'checkbox',
  ]).isRequired,
  isMandatory: PropTypes.bool,
  isMultiple : PropTypes.bool,
  masterFor  : PropTypes.string,
  maxLength  : PropTypes.number, // optional, only for string-like types
  minLength  : PropTypes.number, // optional, only for string-like types
  maxValue   : PropTypes.number, // optional, only for numeric types
  minValue   : PropTypes.number, // optional, only for numeric types
  regExp     : PropTypes.string, // optional
  options    : PropTypes.arrayOf(PropTypes.shape({
    value      : PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    labelKey   : PropTypes.string,
    slaveRegExp: PropTypes.string,
  })),
};

templateFieldObj.fields = PropTypes.arrayOf(PropTypes.shape(templateFieldObj)); // optional, only for group type


export default PropTypes.shape(templateFieldObj);
