import * as actionTypes from './actionTypes';


//----------------------------------------------------------------------------------------------------------------------

export const getMe = () => ({
  type: actionTypes.CONTOUR_CLOUD_ME,
});

export const getMeSuccess = (profile) => ({
  type   : actionTypes.CONTOUR_CLOUD_ME_SUCCESS,
  payload: {
    profile,
  },
});

export const getMeError = (error) => ({
  type: actionTypes.CONTOUR_CLOUD_ME_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const reauth = (email, password) => ({
  type   : actionTypes.CONTOUR_CLOUD_REAUTH,
  payload: {
    email,
    password,
  },
});

export const reauthSuccess = () => ({
  type: actionTypes.CONTOUR_CLOUD_REAUTH_SUCCESS,
});

export const reauthError = (error) => ({
  type: actionTypes.CONTOUR_CLOUD_REAUTH_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------
