import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './Switch.pcss';


class Switch extends React.PureComponent {

  static propTypes = {
    // Explicit props
    isActive          : PropTypes.bool,
    onClick           : PropTypes.func,
    labelMessage      : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    labelMessageValues: PropTypes.object,
    isDisabled        : PropTypes.bool,
  };


  renderLabel() {
    const { labelMessage, labelMessageValues } = this.props;

    if (!labelMessage) {
      return null;
    }

    return (
      <p className={styles.switch__label}>
        <FormattedMessage {...labelMessage} values={labelMessageValues} />
      </p>
    );
  }

  /* eslint-disable jsx-a11y/control-has-associated-label */
  render() {
    const { isActive, onClick, isDisabled } = this.props;
    const onBtnClick = isDisabled ? undefined : onClick;

    return (
      <div className={styles.switch__container}>
        <button
          type="button"
          className={
            cn(styles.switch, {
              [styles['switch--on']]      : isActive,
              [styles['switch--disabled']]: isDisabled,
            })
          }
          onClick={onBtnClick}
        >
          <div className={
            cn(styles.switch__cursor, {
              [styles['switch__cursor--on']]      : isActive,
              [styles['switch__cursor--disabled']]: isDisabled,
            })
          }
          />
        </button>
        <span className={styles.switch__label}>
          { this.renderLabel() }
        </span>
      </div>
    );
  }

}

export default withStyles(styles)(Switch);
