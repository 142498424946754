import React from 'react';
import cn from 'classnames';
import ContentEditable from 'react-contenteditable';
import PropTypes from 'prop-types';
import noteShape from 'shapes/noteShape';
import styles from './VisitNotes.pcss';


class ReplyNoteForm extends React.PureComponent {

  static propTypes = {
    note    : noteShape,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.replyNoteRef = React.createRef();
    this.state = {
      replyContent: '',
    };
  }


  componentDidMount() {
    this.replyNoteRef.current.focus();
  }


  onChange(evt) {
    const replyContent = evt.currentTarget.textContent;
    this.setState({ replyContent });
    this.props.onChange(replyContent);
  }


  onSubmit() {
    const { replyContent } = this.state;
    this.props.onSubmit(replyContent);
  }


  onKeyDown(evt) {
    switch (evt.keyCode) {
      // If Enter
      case 13:
        evt.stopPropagation();
        if (!evt.shiftKey && this.replyNoteRef.current) {
          this.replyNoteRef.current.blur();
        }
        break;
      // if Esc
      case 27:
        evt.stopPropagation();
        this.props.onCancel();
        break;
      default:
        break;
    }
  }


  render() {
    const { note } = this.props;
    return (
      <div
        key={note.noteId}
        className={cn('pseudoEditorWrapper', styles.replyNoteEditorWrapper)}
      >
        <ContentEditable
          className={cn('pseudoEditor', styles.replyNoteEditor)}
          innerRef={this.replyNoteRef}
          html={this.state.replyContent}
          onKeyDown={(evt) => this.onKeyDown(evt)}
          onChange={(evt) => this.onChange(evt)}
          // onFocus={() => this.onFocus(ref)}
          onBlur={() => this.onSubmit()}
          // placeholder={placeholder}
        />
      </div>
    );
  }

}

export default ReplyNoteForm;
