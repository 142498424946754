import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import Button from 'components/Form/Button';
import App from 'modules/App';
import CloudDrive from 'modules/CloudDrive';
import * as actions from '../../../actions';
import * as selectors from '../../../selectors';
import messages from '../../../messages';


class ReauthorizeCloudDrive extends React.PureComponent {

  static propTypes = {
    // Explicit props
    patientProfile                   : PropTypes.object.isRequired,
    // Implicit props
    authorizationCode                : CloudDrive.shapes.authorizationCode,
    isReauthorizeCloudDriveInProgress: PropTypes.bool,
    // Implicit actions
    onReauthorizeCloudDrive          : PropTypes.func,
    onAuthorizeCloudDrive            : PropTypes.func,
  };


  componentDidMount() {
    if (this.props.authorizationCode) {
      this.props.onReauthorizeCloudDrive(this.props.authorizationCode);
    }
  }


  componentDidUpdate(prevProps) {
    if (prevProps.authorizationCode !== this.props.authorizationCode && this.props.authorizationCode) {
      this.props.onReauthorizeCloudDrive(this.props.authorizationCode);
    }
  }


  onAuthorizeCloudDrive() {
    const { storageProvider } = this.props.patientProfile;
    this.props.onAuthorizeCloudDrive(storageProvider, 'pwdReAuth');
  }


  render() {
    const cloudDriveType = get(App.constants.CLOUD_DRIVES_LABELS, get(this.props.patientProfile, 'storageProvider'));
    return (
      <section>
        <h2 className="section__header">
          <FormattedMessage {...messages.headers.cloudDrive} values={{ cloudDriveType }} />
        </h2>
        <Button
          styleModifier="primary"
          labelMessage={messages.buttons.reauthorizeCloudDrive}
          className="btn--filled"
          isInProgress={this.props.isReauthorizeCloudDriveInProgress}
          onClick={() => this.onAuthorizeCloudDrive()}
        />
      </section>
    );
  }

}


const mapStateToProps = (state) => ({
  isReauthorizeCloudDriveInProgress: selectors.isReauthorizePatientProfileCloudDriveInProgress(state),
  authorizationCode                : CloudDrive.selectors.authorizationCode(state),
});

const mapDispatchToProps = (dispatch) => ({
  onReauthorizeCloudDrive: (authorizationCode) => dispatch(
    actions.reauthorizePatientProfileCloudDrive(authorizationCode),
  ),
  onAuthorizeCloudDrive: (provider, type) => dispatch(CloudDrive.actions.authorize(provider, type)),
});

const ConnectedReauthorizeCloudDrive = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReauthorizeCloudDrive);


export default ConnectedReauthorizeCloudDrive;
