// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".StoML{width:3.75in;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.L7Zeh{padding-top:.02in}.L7Zeh .statisticsSection{border-top:1px solid #000;padding:.3em 0}.L7Zeh .statisticsSection:first-child{border-top:none}.L7Zeh .statisticsSection__row{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;margin:.5em 0}.L7Zeh .statisticsSection__element{font-weight:500;font-size:9pt}.L7Zeh .statisticsSection__elementCaption{font-weight:500;font-size:8pt;margin:.5em 0 0}.L7Zeh .statisticsSection__elementHighlight{font-weight:600;font-size:10pt}", ""]);
// Exports
exports.locals = {
	"statisticsSectionWrapper": "StoML",
	"statisticsSection": "L7Zeh"
};
module.exports = exports;
