import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import messages from '../../../messages';
import styles from './Header.pcss';


class Legend extends React.Component {

  render() {
    return (
      <div className={styles.dailyLogReport__legend}>
        <h1>
          <FormattedMessage {...messages.dailyReport.legend} />
        </h1>
        <div>
          <div className={styles.dailyLogReport__legendRow}>
            <div className={styles.dailyLogReport__legendExamples}>
              <div className={styles.relatedData__wrapper}>
                <div className={styles.relatedData__valueWrapper}>
                  <div>
                        #
                  </div>
                </div>
                <div className={styles.relatedData__secondValueWrapper}>
                  <div>
                        ##
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.dailyLogReport__legendTitles}>
              <FormattedMessage {...messages.dailyReport.legendInjection} />
            </div>
          </div>
          <div className={styles.dailyLogReport__legendRow}>
            <div className={styles.dailyLogReport__legendExamples}>
              <div className={styles.reading__wrapper}>
                <div className={styles.reading__valueMenualWrapper}>
                  <div>
                        #
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.dailyLogReport__legendTitles}>
              <FormattedMessage {...messages.dailyReport.legendManual} />
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default withStyles(styles)(Legend);
