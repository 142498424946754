// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._10_ab{display:grid;grid-template-columns:repeat(7,1fr)}.sMV6Y{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;border:1px solid #e0e8f2;border-top:none}._3r-7E{display:inline-block;padding:5px;margin:1rem;border:none;text-align:left;height:auto}._3r-7E span{display:block}._3r-7E span:first-child{font-size:15px;line-height:20px;font-weight:800;vertical-align:middle}._3r-7E span:nth-child(2){font-size:13px;line-height:18px;font-weight:500;vertical-align:middle;color:#6f7ea2}._2jjhn span:nth-child(2){color:#fff}._3snG9{color:#6f7ea2;font-size:14px;font-size:1.4rem;font-weight:400;margin-left:4rem}._8fkxF{font-weight:800;letter-spacing:.05em;text-transform:uppercase;font-size:10px;color:#6f7ea2;margin:3rem 0 1rem}._2iG-5{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;-ms-flex-direction:column;flex-direction:column;margin:20px 0;height:433px}._2iG-5 .blob{-o-object-fit:cover;object-fit:cover}._2iG-5 .text{margin:24px 0;font-size:20px;line-height:27px;color:#6f7ea2}.AfsYr{margin:0 8px}._3ForY{width:16px;height:2px;margin-right:16px}", ""]);
// Exports
exports.locals = {
	"daySelector": "_10_ab",
	"daySelector__day": "sMV6Y",
	"daySelector__dayButton": "_3r-7E",
	"daySelector__dayButton--active": "_2jjhn",
	"unit": "_3snG9",
	"subsection": "_8fkxF",
	"insufficiencyInfo": "_2iG-5",
	"legendCheckbox": "AfsYr",
	"legendSample": "_3ForY"
};
module.exports = exports;
