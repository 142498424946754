import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import filter from 'lodash/filter';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import BackButton from 'components/BackButton';
import Modal from 'components/Modal';
import App from 'modules/App';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import EnrollingRequest from './MergeOptions';
import ChoosePatient from './ChoosePatient';
import Merging from './Merging';
import styles from './MergePatientModal.pcss';


class MergePatientModal extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {

    if (props.openModalId !== constants.MERGE_PATIENT_MODAL) {
      if (state.clinicPatient !== null || state.activePatient) {
        return {
          activePatient: null,
          clinicPatient: null,
          component    : constants.ACCEPT_ENROLL_MODAL_DEFAULT_COMPONENT,
          headerMessage: null,
        };
      }
      return null;
    }

    const { activePatient, patients } = props;

    if (
      state.component === constants.ACCEPT_ENROLL_MODAL_DEFAULT_COMPONENT
        && state.activePatient !== activePatient
        && activePatient
    ) {
      const { id, firstName, lastName, dateOfBirth } = activePatient || {};
      let clinicPatients = filter(
        patients,
        (p) => p.firstName === firstName && p.lastName === lastName && p.id !== id,
      );
      if (clinicPatients.length > 1) {
        const clinicPatientsByDate = filter(clinicPatients, { dateOfBirth });
        if (clinicPatientsByDate.length) {
          clinicPatients = clinicPatientsByDate;
        }
      }
      const clinicPatient = get(clinicPatients, 0, null);
      return {
        activePatient,
        clinicPatient,
        component    : constants.ACCEPT_ENROLL_MODAL_DEFAULT_COMPONENT,
        headerMessage: messages.headers[clinicPatient ? 'enrollingPatientFound' : 'enrollingPatientNotFound'],
      };
    }

    return null;
  }


  static propTypes = {
    sharingRequest     : shapes.sharingRequest,
    isBindingInProgress: PropTypes.bool,
    openModalId        : PropTypes.string,
    onCloseModal       : PropTypes.func,
    onClearForm        : PropTypes.func,
  }


  constructor(props) {
    super(props);

    this.components = {
      EnrollingRequest,
      ChoosePatient,
      Merging,
    };

    this.defaultComponent = constants.ACCEPT_ENROLL_MODAL_DEFAULT_COMPONENT;

    this.state = {
      activePatient: null,
      clinicPatient: null,
      component    : this.defaultComponent,
      headerMessage: null,
    };
  }


  onSetClinicPatient(clinicPatient) {
    this.setState({ clinicPatient });
  }


  onSetComponent(componentName) {
    this.setState({ component: componentName });
  }


  onSetHeaderMessage(headerMessage) {
    this.setState({ headerMessage });
  }


  async onClose() {
    await this.props.onCloseModal();
    this.props.onClearForm();
    this.setState({ component: this.defaultComponent });
  }


  renderBack() {
    const { component } = this.state;
    if (component === this.defaultComponent || component === 'Merging') {
      return null;
    }

    return (
      <BackButton
        to=""
        onClick={(evt) => {
          evt.preventDefault();
          this.onSetComponent(this.defaultComponent);
        }}
      />
    );
  }


  render() {
    const { component, headerMessage } = this.state;
    const Component = get(this.components, component, null);
    return (
      <Modal
        modalId={constants.MERGE_PATIENT_MODAL}
        openModalId={this.props.openModalId}
        styleModifier="md"
        isInProgress={this.props.isBindingInProgress}
        onClose={() => this.onClose()}
      >
        { this.renderBack() }
        <h2 className="modal__header"><FormattedMessage {...headerMessage} /></h2>
        <App.components.AlertsBus className="mb-4" />
        <Component
          activePatient={this.state.activePatient}
          clinicPatient={this.state.clinicPatient}
          sharingRequest={this.props.sharingRequest}
          onSetClinicPatient={(clinicPatient) => this.onSetClinicPatient(clinicPatient)}
          onSetComponent={(componentName) => this.onSetComponent(componentName)}
          onSetHeaderMessage={(headerMsg) => this.onSetHeaderMessage(headerMsg)}
          onClose={() => this.onClose()}
        />
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  activePatient      : selectors.activePatient(state),
  patients           : selectors.patients(state),
  sharingRequest     : selectors.sharingRequest(state),
  openModalId        : App.selectors.modal(state),
  isBindingInProgress: selectors.isBindSharingRequestWithClinicPatientInProgress(state),
});


const mapDispatchToProps = (dispatch) => ({
  onCloseModal: () => dispatch(App.actions.closeModal()),
  onClearForm : () => dispatch(App.actions.clearForm(constants.MERGE_PATIENT_FORM)),
});


const ConnectedMergePatientModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MergePatientModal);


export default withStyles(styles)(ConnectedMergePatientModal);
