import { createSelector } from 'reselect';
import includes from 'lodash/includes';
import * as actionTypes from './actionTypes';
import * as constants from './constants';

/**
 * Input selectors
 */
const mainSelector = (state) => state[constants.MODULE_ID];
const fetchingSelector = (state) => state[constants.MODULE_ID].fetching;
const errorsSelector = (state) => state[constants.MODULE_ID].errors;


export const exportName = createSelector(
  mainSelector,
  (state) => state.exportName,
);


export const progress = createSelector(
  mainSelector,
  (state) => state.progress,
);


//----------------------------------------------------------------------------------------------------------------------

export const isExportDataInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.EXPORT_DATA),
);

export const hasExportDataErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.EXPORT_DATA),
);
