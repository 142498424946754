import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { DateRangePicker } from 'react-dates';
import cn from 'classnames';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/withStyles';
import find from 'lodash/find';
import get from 'lodash/get';
import delay from 'lodash/delay';
import { AppContext } from 'context';
import { formatLocalizationResource } from 'helpers/i18n';
import { getSlug } from 'helpers/urlTools';
import { decrypt, getKeyFromPem } from 'helpers/crypto';
import Button from 'components/Form/Button';
import Link from 'components/Link';
import Select from 'components/Form/Select';
import Modal from 'components/Modal';
import Account from 'modules/Account';
import App from 'modules/App';
import intlShape from 'shapes/intlShape';
import keyPairShape from 'shapes/keyPairShape';
import Calendar from 'svg/calendar.svg';
import messages from '../../messages';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import styles from './NotificationsHistoryModal.pcss';


class NotificationsHistoryModal extends React.PureComponent {

  static contextType = AppContext;

  static getDerivedStateFromProps(props, state) {
    const { openModalId } = props;

    if (openModalId !== state.openModalId && openModalId === constants.NOTIFICATIONS_HISTORY_MODAL) {
      const startDate = moment().utc().startOf('day').subtract(7, 'days');
      const endDate = moment().utc().startOf('day');
      return {
        openModalId,
        start: startDate,
        end  : endDate,
        startDate,
        endDate,
      };
    }

    return { openModalId };
  }

  static propTypes = {
    // Implicit props
    notificationsHistory       : PropTypes.array,
    notificationsSettings      : PropTypes.object,
    isInProgress               : PropTypes.bool,
    openModalId                : PropTypes.string,
    intl                       : intlShape.isRequired,
    direction                  : PropTypes.string,
    keyPair                    : keyPairShape,
    passphrase                 : PropTypes.string,
    localizationResources      : PropTypes.object,
    hcpClinicMemberships       : PropTypes.arrayOf(Account.shapes.clinicMembership),
    // Implicit actions
    onCloseModal               : PropTypes.func,
    onFetchNotificationsHistory: PropTypes.func,
    onOpenModalSettings        : PropTypes.func,
  };


  constructor(props) {
    super(props);
    const startDate = moment().utc().startOf('day').subtract(7, 'days');
    const endDate = moment().utc().startOf('day');
    this.state = {
      isInProgress         : false,
      preparedNotifications: [],
      notificationType     : '',
      start                : startDate,
      end                  : endDate,
      startDate,
      endDate,
    };
  }


  componentDidUpdate(prevProps) {
    const { isInProgress, openModalId, notificationsHistory } = this.props;
    const isOpenModal = openModalId && openModalId === constants.NOTIFICATIONS_HISTORY_MODAL;
    if (isOpenModal && prevProps.openModalId !== openModalId) {
      this.onFetchNotificationsHistory();
    }

    if (isOpenModal && !isInProgress && prevProps.isInProgress) {
      delay(() => {
        const preparedNotifications = notificationsHistory.map(
          (notification) => (
            {
              ...notification,
              message: formatLocalizationResource(
                this.props.localizationResources,
                notification.resourceKey,
                this.getEnhancedVariables(notification),
              ),
            }
          )
        );
        this.onSetPreparedNotifications(preparedNotifications);
      }, 300);
    }
  }


  componentWillUnmount() {
    if (this.props.openModalId === constants.NOTIFICATIONS_HISTORY_MODAL) {
      this.onClose();
    }
  }


  async onClose() {
    await this.props.onCloseModal();
    this.setState({ isInProgress: false });
  }


  onRangeChange(input) {
    if (input) {
      this.setState({ notificationType: input.value });
    }
    this.onFetchNotificationsHistory(null, null, input);
  }


  onFetchNotificationsHistory(start, end, notificationInput) {
    const { startDate, endDate } = this.state;
    this.setState({ isInProgress: true });
    let notificationType = notificationInput ? notificationInput.value : this.state.notificationType;
    if (notificationType === '') {
      notificationType = null;
    }

    this.props.onFetchNotificationsHistory(
      notificationType,
      (startDate || start).format('X'), (endDate || end).format('X')
    );
  }


  onSetPreparedNotifications(preparedNotifications) {
    this.setState({ preparedNotifications, isInProgress: false });
  }


  onChangeRange() {
    const { startDate, endDate, start, end } = this.state;
    if (!startDate || !endDate) {
      this.setState((prevState) => ({
        startDate: prevState.start,
        endDate  : prevState.end,
      }));
      return;
    }

    if ((startDate.utc() === start.utc() && endDate.utc() === end.utc())) {
      return;
    }

    this.setState({ start: startDate, end: endDate });
    this.onFetchNotificationsHistory();
  }


  onDatesChange(startDate, endDate) {
    this.setState({
      startDate: startDate && startDate.clone().utc().startOf('day'),
      endDate  : endDate && endDate.clone().utc().endOf('day'),
    });
  }


  getPatientProfileLink(clinicId, clinicName, patientFirstName, patientLastName, encryptedClinicPatientProfileId) {
    if (!encryptedClinicPatientProfileId) {
      return null;
    }
    const prvKeyObj = this.getClinicPrivateKey(+clinicId);
    if (!prvKeyObj) {
      return null;
    }
    const clinicMembership = find(this.props.hcpClinicMemberships, { clinicId: +clinicId });
    const organizationUID = get(clinicMembership, 'clinic.organizationUID');
    const clinicSlug = getSlug(clinicName);
    const patientId = decrypt(encryptedClinicPatientProfileId, prvKeyObj);
    const patientSlug = getSlug(`${patientFirstName} ${patientLastName}`);
    return (
      <Link to={this.context.getUrl('hcp-results', { clinicSlug, organizationUID, patientSlug, patientId })}>
        <FormattedMessage {...messages.partials.goToPatientProfile} />
      </Link>
    );
  }


  getClinicPrivateKey(clinicId) {
    if (!clinicId || !this.props.passphrase) {
      return null;
    }

    const clinicMembership = find(this.props.hcpClinicMemberships, { clinicId: +clinicId });
    if (!clinicMembership) {
      return null;
    }
    const accountPrvKeyObj = get(this.props.keyPair, 'prvKeyPem');
    if (!accountPrvKeyObj) {
      return null;
    }
    const accountPrvKey = getKeyFromPem(accountPrvKeyObj, this.props.passphrase);
    const { encryptedPrivateKey, encryptedPassphrase } = clinicMembership;
    const clinicKeyPassphrase = decrypt(encryptedPassphrase, accountPrvKey);
    return getKeyFromPem(encryptedPrivateKey, clinicKeyPassphrase);
  }


  getEnhancedVariables(notification) {
    const { variables, notificationTrigger } = { ...notification };
    switch (notificationTrigger) {
      case constants.NOTIFICATIONS_TRIGGERS.CLINIC_PATIENT_PROFILE_CREATED:
      case constants.NOTIFICATIONS_TRIGGERS.CLINIC_PATIENT_PROFILE_NEW_DATA_SYNCHRONIZED: {
        const { clinicId, clinicName, patientFirstName, patientLastName, encryptedClinicPatientProfileId } = variables;
        variables.patientProfileLink = this.getPatientProfileLink(
          clinicId, clinicName, patientFirstName, patientLastName, encryptedClinicPatientProfileId,
        );
        break;
      }
      case constants.NOTIFICATIONS_TRIGGERS.PWD_INVITATION_REVOKED:
      case constants.NOTIFICATIONS_TRIGGERS.SHARING_REQUEST_ACCEPTED: {
        const { clinicId, clinicName, inviteeFirstname, inviteeLastname, encryptedLocalPatientProfileId } = variables;
        variables.patientProfileLink = this.getPatientProfileLink(
          clinicId, clinicName, inviteeFirstname, inviteeLastname, encryptedLocalPatientProfileId,
        );
        break;
      }
      case constants.NOTIFICATIONS_TRIGGERS.CLINIC_HCP_MEMBERSHIP_CREATED: {
        const { clinicId, clinicName } = variables;
        const clinicMembership = find(this.props.hcpClinicMemberships, { clinicId: +clinicId });
        const organizationUID = get(clinicMembership, 'clinic.organizationUID');
        const clinicSlug = getSlug(clinicName);
        variables.clinicLink = (
          <Link to={`${this.context.getUrl('clinic-settings', { clinicSlug, organizationUID })}#clinicMemberships`}>
            <FormattedMessage {...messages.partials.leadingClick} />
          </Link>
        );
        break;
      }
      default: {
        break;
      }
    }
    return variables;
  }


  renderRangeIcon(isInProgress) {
    return (
      <Calendar
        className={cn('DateRangePickerInput_calendarIcon_svg DateRangePickerInput_calendarIcon_svg_1',
          styles.icon, {
            'DateRangePickerInput_calendarIcon_svg--inactive': isInProgress,
          })}
        focusable="false"
      />
    );
  }


  renderActions() {
    return (
      <div className={cn(styles.actions)}>
        <div className={styles.backButton}>
          <Button
            styleModifier="primary"
            labelMessage={messages.buttons.back}
            className="btn--block btn--filled"
            onClick={() => this.props.onOpenModalSettings()}
            isDisabled={this.state.isInProgress}
          />
        </div>
      </div>
    );
  }


  renderNotificationContent(notification) {
    return (
      <div className={`row align-items-center ${styles.notifications__notification__inner}`}>
        <div className="col">
          { notification.message }
        </div>
      </div>
    );
  }


  renderNotification(notification) {
    return (
      <div className={styles.notificationHistory__row}>
        <div className={styles.notificationHistory__rowTitle}>
          {moment.unix(notification.timestamp).utc().format('DD.MM.YYYY, HH:mm')}
        </div>
        <div className={styles.notificationHistory__rowContent}>
          {this.renderNotificationContent(notification)}
        </div>
      </div>
    );
  }


  renderNotifications() {
    const { preparedNotifications } = this.state;
    return (
      <div>
        {
            preparedNotifications.map(
              (notification) => (
                <div key={`${notification.instantNotificationId}-notification`}>
                  {this.renderNotification(notification)}
                </div>
              )
            )
        }
      </div>
    );
  }


  renderFilters() {
    const { notificationsSettings, localizationResources } = this.props;
    return (
      <div className="row">
        <div className="col-4">
          <DateRangePicker
            isOutsideRange={() => false}
            startDate={this.state.startDate}
            startDateId="startDate"
            endDate={this.state.endDate}
            endDateId="endDate"
            focusedInput={this.state.focusedInput}
            anchorDirection={this.props.direction === 'rtl' ? 'left' : 'right'}
            isRTL={this.props.direction === 'rtl'}
            customInputIcon={this.renderRangeIcon(this.state.isInProgress)}
            inputIconPosition="after"
            customArrowIcon={<span>-</span>}
            minimumNights={0}
            disabled={this.state.isInProgress}
            hideKeyboardShortcutsPanel
            noBorder
            onDatesChange={({ startDate, endDate }) => this.onDatesChange(startDate, endDate)}
            onFocusChange={(focusedInput) => this.setState({ focusedInput })}
            onClose={({ startDate, endDate }) => delay(() => this.onChangeRange(startDate, endDate), 100)}
          />
        </div>
        <div className="col-4" />
        <div className="col-4">
          <Select
            id="notificationType"
            optionsFrom={[
              { value: '', label: this.props.intl.formatMessage(messages.labels.allNotificationTypes) },
              ...Object.keys(notificationsSettings).map(
                (value) => ({
                  value,
                  label: get(localizationResources, [notificationsSettings[value].key, 'value'], value),
                })
              ),
            ]}
            valueKey="value"
            labelKey="label"
            noValueMessage={messages.labels.notificationType}
            onChange={(input) => this.onRangeChange(input)}
            value={this.state.notificationType}
          />
        </div>
      </div>
    );
  }


  render() {
    const { isInProgress } = this.state;
    return (
      <Modal
        modalId={constants.NOTIFICATIONS_HISTORY_MODAL}
        openModalId={this.props.openModalId}
        styleModifier="lg"
        headerMessage={messages.headers.notificationsHistory}
        onClose={this.props.onCloseModal}
      >
        {
          constants.NOTIFICATIONS_HISTORY_MODAL === this.props.openModalId && (
          <div className={styles.container}>
            {this.renderFilters()}
            {
              this.props.isInProgress || isInProgress ? (
                <div className={styles.loaderContainer}>
                  <img src="/assets/svg/loader.svg" className="rotatingLoader" alt="" />
                </div>
              ) : this.renderNotifications()
            }
          </div>
          )
        }
        { this.renderActions() }
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  notificationsHistory : selectors.notificationsHistory(state),
  isInProgress         : selectors.isFetchNotificationsHistoryInProgress(state),
  openModalId          : App.selectors.modal(state),
  localizationResources: App.selectors.localizationResources(state),
  passphrase           : Account.selectors.passphrase(state),
  keyPair              : Account.selectors.keyPair(state),
  hcpClinicMemberships : Account.selectors.hcpClinicMemberships(state),
  notificationsSettings: Account.selectors.notificationsSettings(state),
  direction            : App.selectors.direction(state),
});


const mapDispatchToProps = (dispatch) => ({
  onCloseModal               : () => dispatch(App.actions.closeModal()),
  onOpenModalSettings        : () => dispatch(App.actions.openModal(constants.NOTIFICATIONS_SETTINGS_MODAL)),
  onFetchNotificationsHistory: (notificationName, dataRangeFrom, dataRangeTo) =>
    dispatch(actions.fetchNotificationsHistory({ notificationName, dataRangeFrom, dataRangeTo })),
});


const ConnectedNotificationsHistoryModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsHistoryModal);


export default withStyles(styles)(injectIntl(ConnectedNotificationsHistoryModal));
