import { createSelector } from 'reselect';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import startsWith from 'lodash/startsWith';
import moment from 'moment';
import DataSources from 'modules/DataSources';
import * as actionTypes from './actionTypes';
import * as constants from './constants';


/**
 * Direct selector to the domain's state
 */
const mainSelector = (state) => state[constants.MODULE_ID];
const fetchingSelector = (state) => state[constants.MODULE_ID].fetching;
const sendingSelector = (state) => state[constants.MODULE_ID].sending;
const errorsSelector = (state) => state[constants.MODULE_ID].errors;


/**
 * Patient PHI set
 */
export const phiSet = createSelector(
  mainSelector,
  (state) => state.phiSet,
);


/**
 * Patient PHI set document id
 */
export const phiSetDocumentId = createSelector(
  mainSelector,
  (state) => state.phiSetDocumentId,
);


/**
 * Fetched monthly batches index
 */
export const batchesIndex = createSelector(
  mainSelector,
  (state) => state.batchesIndex,
);


/**
 * Fetched cgm weekly batches index
 */
export const cgmBatchesIndex = createSelector(
  mainSelector,
  (state) => state.cgmBatchesIndex,
);


/**
 * Fetched notes monthly batches index
 */
export const notesBatchesIndex = createSelector(
  mainSelector,
  (state) => state.notesBatchesIndex,
);


/**
 * Fetched measurements monthly batches index
 */
export const measurementsBatchesIndex = createSelector(
  mainSelector,
  (state) => state.measurementsBatchesIndex,
);


/**
 * Blood glucose related data
 */
export const relatedData = createSelector(
  mainSelector,
  (state) => state.relatedData,
);


/**
 * Blood glucose readings
 */
export const readings = createSelector(
  mainSelector,
  (state) => state.readings,
);


/**
 * Meal, insulin, activity, medicaments 3rd app records
 */
export const timeSeriesResources = createSelector(
  mainSelector,
  (state) => state.timeSeriesResources,
);


/**
 * Patient measurements
 */
export const measurements = createSelector(
  mainSelector,
  (state) => state.measurements,
);

/**
 * Blood glucose highlightedReadings
 */
export const highlightedReadings = createSelector(
  mainSelector,
  (state) => state.highlightedReadings,
);


/**
 * Blood glucose readings with related data
 */
export const readingsWithRelatedData = createSelector(
  [relatedData, readings],
  (relatedDataState, readingsState) => readingsState.map((reading) => {
    const findRelatedData = relatedDataState.find((item) => item.readingId === reading.externalId);
    if (findRelatedData) {
      reading.relatedData = findRelatedData;
    }
    return reading;
  }),
);


export const readingsRangeDate = createSelector(
  readings,
  (readingsState) => (daysEndOffset, daysBeforeEndOffset = 30) => {
    const lastPossibleMoment = moment.utc().add(-daysEndOffset, 'days').endOf('day').locale('en--account');
    const firstPossibleMoment = lastPossibleMoment.clone().add(-(daysBeforeEndOffset - 1), 'days').startOf('day');

    const firstPossibleTimestamp = firstPossibleMoment.unix();
    const lastPossibleTimestamp = lastPossibleMoment.unix();

    return readingsState.filter((reading) => reading.timestamp > firstPossibleTimestamp
      && reading.timestamp < lastPossibleTimestamp);
  }
);

/**
 * Blood glucose readings
 */
export const cgmReadings = createSelector(
  mainSelector,
  (state) => state.cgmReadings,
);


//----------------------------------------------------------------------------------------------------------------------

export const notes = createSelector(
  mainSelector,
  (state) => state.notes,
);

//----------------------------------------------------------------------------------------------------------------------

/**
 * Sharing requests from HCPs
 */
export const sharingRequests = createSelector(
  mainSelector,
  (state) => state.sharingRequests,
);

/**
 * Sharing Request Id of an entity during approval process
 */
export const beingApprovedSharingRequestId = createSelector(
  mainSelector,
  (state) => state.beingApprovedSharingRequestId,
);

//----------------------------------------------------------------------------------------------------------------------

export const familyLinks = createSelector(
  mainSelector,
  (state) => state.familyLinks,
);


export const familyLinkInvitations = createSelector(
  mainSelector,
  (state) => state.familyLinkInvitations,
);


export const familyLinkRequests = createSelector(
  mainSelector,
  (state) => state.familyLinkRequests,
);


export const activeFamilyLink = createSelector(
  mainSelector,
  (state) => state.activeFamilyLink,
);


/**
 * Family Sharing Link Id of an entity during approval process
 */
export const beingApprovedFamilyLinkId = createSelector(
  mainSelector,
  (state) => state.beingApprovedFamilyLinkId,
);

//----------------------------------------------------------------------------------------------------------------------

export const isFetchSharingRequestsInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_SHARING_REQUESTS),
);

//----------------------------------------------------------------------------------------------------------------------

export const isEnrollInClinicInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.ENROLL_IN_CLINIC),
);

export const hasEnrollInClinicErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.ENROLL_IN_CLINIC),
);

//----------------------------------------------------------------------------------------------------------------------

export const sharingRequestApprovesInProgress = createSelector(
  sendingSelector,
  (state) => filter(state, (action) => startsWith(action, actionTypes.APPROVE_SHARING_REQUEST)),
);

export const isApproveSharingRequestInProgressSelector = (sharingRequestId) => createSelector(
  sendingSelector,
  (state) => includes(state, `${actionTypes.APPROVE_SHARING_REQUEST}-${sharingRequestId}`),
);

export const hasApproveSharingRequestErrorsSelector = (sharingRequestId) => createSelector(
  errorsSelector,
  (state) => includes(state, `${actionTypes.APPROVE_SHARING_REQUEST}-${sharingRequestId}`),
);

//----------------------------------------------------------------------------------------------------------------------

export const isRevokeSharingRequestInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.REVOKE_SHARING_REQUEST),
);

export const hasRevokeSharingRequestErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.REVOKE_SHARING_REQUEST),
);

//----------------------------------------------------------------------------------------------------------------------

export const isFetchFamilyLinksInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_FAMILY_SHARING_LINKS),
);

//----------------------------------------------------------------------------------------------------------------------

export const isInviteFamilyMembersInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.INVITE_FAMILY_MEMBERS),
);

export const hasInviteFamilyMembersErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.INVITE_FAMILY_MEMBERS),
);

//----------------------------------------------------------------------------------------------------------------------

export const isRevokeFamilyAccessInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.REVOKE_FAMILY_ACCESS),
);

export const hasRevokeFamilyAccessErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.REVOKE_FAMILY_ACCESS),
);


//----------------------------------------------------------------------------------------------------------------------

export const isSyncInProgress = createSelector(
  fetchingSelector,
  DataSources.selectors.isSyncInProgress,
  (state, isSyncInProgressState) => includes(state, actionTypes.SYNC) || isSyncInProgressState,
);

export const hasSyncErrors = createSelector(
  errorsSelector,
  DataSources.selectors.hasSyncErrors,
  (state, hasSyncErrorsState) => includes(state, actionTypes.SYNC) || hasSyncErrorsState,
);

//----------------------------------------------------------------------------------------------------------------------

export const isReauthContourCloudInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.REAUTH_CONTOUR_CLOUD),
);

//----------------------------------------------------------------------------------------------------------------------
