import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Form/Button';
import MedicalCardIcon from 'svg/medical-card.svg';
import App from 'modules/App';
import Visit from 'modules/Visit';
import * as constants from '../../constants';
import messages from '../../messages';


class AdditionalMeasurementsBtn extends React.PureComponent {

  static propTypes = {
    // Implicit props
    activeVisit         : Visit.shapes.visit,
    openModalId         : PropTypes.string,
    floatingModal       : App.shapes.floatingModal,
    // Implicit actions
    onOpenModal         : PropTypes.func.isRequired,
    onCloseModal        : PropTypes.func.isRequired,
    onOpenFloatingModal : PropTypes.func.isRequired,
    onCloseFloatingModal: PropTypes.func.isRequired,
  };


  onOpenFloatingModal() {
    if (this.props.floatingModal) {
      this.props.onCloseFloatingModal();
      return;
    }
    this.props.onOpenFloatingModal();
  }


  onOpenModal() {
    if (this.props.openModalId === constants.ADDITIONAL_MEASUREMENTS_MODAL) {
      this.props.onCloseModal();
      return;
    }
    this.props.onOpenModal();
  }


  onClick(evt) {
    evt.stopPropagation();
    if (this.props.activeVisit) {
      this.onOpenFloatingModal();
    } else {
      this.onOpenModal();
    }
  }


  render() {
    return (
      <Button
        id="AdditionalMeasurementsBtn"
        styleModifier="transparent-primary"
        onClick={(evt) => this.onClick(evt)}
      >
        <MedicalCardIcon className="btn__icon mr-2" />
        <FormattedMessage {...messages.buttons.additionalMeasurements} />
      </Button>
    );
  }

}


const mapStateToProps = (state) => ({
  activeVisit  : Visit.selectors.activeVisit(state),
  openModalId  : App.selectors.modal(state),
  floatingModal: App.selectors.floatingModalSelector(constants.ADDITIONAL_MEASUREMENTS_FM)(state),
});


const mapDispatchToProps = (dispatch) => ({
  onOpenModal        : () => dispatch(App.actions.openModal(constants.ADDITIONAL_MEASUREMENTS_MODAL)),
  onCloseModal       : () => dispatch(App.actions.closeModal(constants.ADDITIONAL_MEASUREMENTS_MODAL)),
  onOpenFloatingModal: () => dispatch(
    App.actions.openFloatingModal(constants.ADDITIONAL_MEASUREMENTS_FM, 'additionalMeasurementsContainer', 'leftTop'),
  ),
  onCloseFloatingModal: () => dispatch(App.actions.closeFloatingModal(constants.ADDITIONAL_MEASUREMENTS_FM)),
});


const ConnectedAdditionalMeasurementsBtn = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdditionalMeasurementsBtn);


export default ConnectedAdditionalMeasurementsBtn;
