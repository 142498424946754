import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import find from 'lodash/find';
import get from 'lodash/get';
import { AppContext } from 'context';
import history from 'helpers/history';
import { getSlug } from 'helpers/urlTools';
import Avatar from 'components/Avatar';
import BackButton from 'components/BackButton';
import Tabs from 'components/Tabs';
import App from 'modules/App';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import styles from './ClinicSettings.pcss';

import ClinicMemberships from './ClinicMemberships';
import DataSettings from './DataSettings';
import PhiSettings from './PhiSettings';


class ClinicSettings extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    organizationUID         : PropTypes.string.isRequired,
    // Explicit props
    view                    : PropTypes.string,
    views                   : PropTypes.arrayOf(PropTypes.string).isRequired,
    // Implicit props
    hcpClinicMemberships    : PropTypes.arrayOf(Account.shapes.clinicMembership),
    activeClinicMembership  : Account.shapes.clinicMembership,
    clinicMemberships       : PropTypes.arrayOf(shapes.clinicMembership),
    authorizationCode       : CloudDrive.shapes.authorizationCode,
    countries               : PropTypes.arrayOf(App.shapes.country),
    localizationResources   : PropTypes.object,
    isClientInitialized     : PropTypes.bool.isRequired,
    route                   : App.shapes.route,
    // Implicit actions
    onFetchClinicMemberships: PropTypes.func,
    onFetchCustomIdentifiers: PropTypes.func,
    onFetchClinics          : PropTypes.func,
    onOpenDropdown          : PropTypes.func,
    onCloseDropdown         : PropTypes.func,
    onOpenModal             : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this._clinicMembership = null;
    this._clinic = null;
    const { countryId } = this.clinic;
    props.onFetchCustomIdentifiers(countryId);
    this.onFetchClinicMemberships();
  }


  onChangeView(view) {
    const { activeClinicMembership } = this.props;
    const organizationUID = get(activeClinicMembership, 'clinic.organizationUID');
    const clinicName = get(activeClinicMembership, 'clinic.name');
    const clinicSlug = getSlug(clinicName);

    const url = view === 'memberships'
      ? this.context.getUrl('clinic-settings', { clinicSlug, organizationUID })
      : this.context.getUrl('clinic-settings-view', { clinicSlug, organizationUID, view });

    history.push(url);
  }


  onFetchClinicMemberships() {
    if (process.env.BROWSER && !this.props.isClientInitialized) {
      return;
    }
    const { clinicId } = this.clinic;
    this.props.onFetchClinicMemberships(clinicId);
  }

  /*
  async onOpenMenu(evt, item) {
    evt.preventDefault();
    const { clinicHcpMembershipId, identityUID } = item;
    await this.props.onCloseDropdown();
    this.props.onOpenDropdown(`${constants.CLINIC_MEMBERSHIP_DROPDOWN}-${clinicHcpMembershipId || identityUID}`);
  }
 */


  get backUrl() {
    const { organizationUID } = this.props;
    const { previousName, previousParams } = this.props.route;
    if (previousName === 'account-settings') {
      return this.context.getUrl(previousName, previousParams);
    }
    return this.context.getUrl('general-population', { clinicSlug: getSlug(this.clinic.name), organizationUID });
  }


  get clinicMembership() {
    if (this._clinicMembership) {
      return this._clinicMembership;
    }
    const { organizationUID, hcpClinicMemberships } = this.props;
    this._clinicMembership = find(
      hcpClinicMemberships,
      (membership) => membership.clinic.organizationUID === organizationUID,
    );
    return this._clinicMembership;
  }


  get clinic() {
    if (this._clinic) {
      return this._clinic;
    }
    this._clinic = get(this.clinicMembership, 'clinic', {});
    return this._clinic;
  }


  get country() {
    const { countryCode: alpha2Code } = this.clinic;
    const country = find(this.props.countries, { alpha2Code });
    return get(this.props.localizationResources, [country.nameKey, 'value'], country.englishName);
  }


  renderView() {
    const { view, activeClinicMembership, clinicMemberships } = this.props;
    if (view === 'phiSettings') {
      return <PhiSettings activeClinicMembership={activeClinicMembership} />;
    }
    if (view === 'dataSettings') {
      return <DataSettings activeClinicMembership={activeClinicMembership} clinicMemberships={clinicMemberships} />;
    }
    return <ClinicMemberships activeClinicMembership={activeClinicMembership} clinicMemberships={clinicMemberships} />;
  }


  renderHeader() {
    const { name, logo, street, city, zipCode } = this.clinic;
    return (
      <header className="pageHeader d-flex align-items-center">
        <h1 className="d-block text--h1"><FormattedMessage {...messages.headers.clinicSettings} /></h1>
        <div className={styles.clinic}>
          <div>
            <Avatar
              avatarImg={logo}
              name={name}
              className={styles.clinic__logo}
              imgClassName={styles.clinic__logo__img}
              initialsClassName={styles.clinic__logo__initials}
            />
          </div>
          <div>
            <p className={styles.clinic__name}>{ name }</p>
            <p className={styles.clinic__address}>{ this.country }, { street }, { city } { zipCode }</p>
          </div>
        </div>
      </header>
    );
  }


  render() {
    return (
      <div>
        <BackButton to={this.backUrl} />
        { this.renderHeader() }
        <App.components.AlertsBus className="mb-8" isGlobal />
        <Tabs
          activeItem={this.props.view}
          items={this.props.views}
          messages={messages.nav}
          action={(activeView) => this.onChangeView(activeView)}
        />
        <div className="mt-7 pt-6 mb-10">{ /* TODO: Remove mb-10 */ }
          { this.renderView() }
        </div>
        <Account.components.ElevatePermissionsModal
          actionMessage={<FormattedMessage {...messages.headers.editClinicSettings} />}
        />
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  hcpClinicMemberships       : Account.selectors.hcpClinicMemberships(state),
  activeClinicMembership     : Account.selectors.activeClinicMembership(state),
  clinicMemberships          : selectors.clinicMemberships(state),
  approvingClinicMembershipId: selectors.approvingClinicMembershipId(state),
  authorizationCode          : CloudDrive.selectors.authorizationCode(state),
  isClientInitialized        : App.selectors.isClientInitialized(state),
  countries                  : App.selectors.countries(state),
  localizationResources      : App.selectors.localizationResources(state),
  route                      : App.selectors.route(state),
});


const mapDispatchToProps = (dispatch) => ({
  onFetchClinicMemberships: (clinicId) => dispatch(actions.fetchClinicMemberships(clinicId)),
  onFetchCustomIdentifiers: (countryId) => dispatch(actions.fetchClinicCustomIdentifiers(countryId)),
  onOpenDropdown          : (dropdownId) => dispatch(App.actions.openDropdown(dropdownId)),
  onCloseDropdown         : () => dispatch(App.actions.closeDropdown()),
  onOpenModal             : (modalId) => dispatch(App.actions.openModal(modalId)),
});


const ConnectedClinicSettings = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClinicSettings);


export default withStyles(styles)(ConnectedClinicSettings);
