// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".pAyGG{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}._3p79S{width:160px;width:16rem;height:160px;height:16rem;fill:#30a8ff}", ""]);
// Exports
exports.locals = {
	"loaderContainer": "pAyGG",
	"loader": "_3p79S"
};
module.exports = exports;
