import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ProfileContext } from 'context';
import { useAction } from 'hooks';
import Switch from 'components/Switch';
import App from 'modules/App';
import PatientResults from 'modules/PatientResults';
import AmbulatoryGlucoseProfile from 'modules/AmbulatoryGlucoseProfile';
import messages from '../../../../messages';
import ReportButton from './ReportButton';


const ActivePatientOptions: FC = () => {
  const profileContext = useContext(ProfileContext);
  const activePatient = useSelector(profileContext.selectors.activePatient);
  const phiSet: PhiSet = useSelector(profileContext.selectors.phiSet);
  const phiSetDocumentId = useSelector(profileContext.selectors.phiSetDocumentId);
  // TODO: Refactor PatientResults usage
  const deviceMode = useSelector(PatientResults.selectors.deviceMode);
  const resultsIsInProgress = useSelector(PatientResults.selectors.resultsIsInProgress);
  const featureToggles = useSelector(App.selectors.featureToggles);

  const setReadings = useAction(profileContext.actions.setReadings);
  const setDeviceMode = useAction(PatientResults.actions.setDeviceMode);
  const storeSelectedReportTypes = useAction(PatientResults.actions.storeSelectedReportTypes);
  const prepareAgpPreview = useAction(AmbulatoryGlucoseProfile.actions.prepareAgpPreview);
  const goToReportsView = useAction(App.actions.goToReportsView);

  if (!phiSet) {
    return null;
  }

  const onSetDeviceMode = () => {
    if (deviceMode === 'BGM') {
      prepareAgpPreview(activePatient, phiSet, phiSetDocumentId, setReadings);
    }
    setDeviceMode(deviceMode === 'AGP' ? 'BGM' : 'AGP');
  };

  return (
    <>
      <Switch
        isDisabled={resultsIsInProgress}
        labelMessage={messages.sidebar.labels.agpView}
        isActive={deviceMode === 'AGP'}
        onClick={onSetDeviceMode}
      />
      <ReportButton
        storeSelectedReportTypes={storeSelectedReportTypes}
        goToReportsView={goToReportsView}
        isInProgress={resultsIsInProgress}
        featureToggles={featureToggles}
      />
    </>
  );
};

export default ActivePatientOptions;
