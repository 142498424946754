import { defineMessages } from 'react-intl';


export default defineMessages({
  headers: {
    notifications        : { id: 'notifications.headers.notifications' },
    notificationsSettings: { id: 'notifications.headers.notificationsSettings' },
    notificationsHistory : { id: 'notifications.headers.notificationsHistory' },
    notifyMeWhen         : { id: 'notifications.headers.notifyMeWhen' },
  },

  buttons: {
    back                    : { id: 'notifications.buttons.back' },
    viewAllNotifications    : { id: 'notifications.buttons.viewAllNotifications' },
    openNotificationsHistory: { id: 'notifications.buttons.openNotificationsHistory' },
    saveChanges             : { id: 'notifications.buttons.saveChanges' },
  },

  infos: {
    noNotifications: { id: 'notifications.infos.noNotifications' },
  },

  labels: {
    notificationType    : { id: 'notifications.labels.notificationType' },
    allNotificationTypes: { id: 'notifications.labels.allNotificationTypes' },
  },

  partials: {
    goToPatientProfile: { id: 'notifications.partials.goToPatientProfile' },
    leadingClick      : { id: 'notifications.partials.leadingClick' },
    visitsHistory     : { id: 'notifications.partials.visitsHistory' },
  },
});
