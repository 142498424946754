import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ProfileContext } from 'context';
import { useAction } from 'hooks';
import Account from 'modules/Account';
import * as actions from '../../../actions';
import * as constants from '../../../constants';
import * as selectors from '../../../selectors';
import { useStandards } from '../../../hooks';
import { hasMismatchBetweenSummaryAndStandards, hasMismatchBetweenTimes } from '../helpers';


export const useConnectionStatusChange = (
  connectorType: ConnectorType,
  onSetComponent: (componentName: string) => void,
  onCancel: () => void,
) => {
  const profileContext = useContext(ProfileContext);
  const connectionId = useSelector(selectors.connectionId);
  const connectionStatus: ConnectionStatus = useSelector(selectors.connectionStatus);
  const connectionError = useSelector(selectors.connectionError);
  const activeVisit = useSelector(profileContext.selectors.activeVisit);
  const onGetDeviceData = useAction(actions.getDeviceData, connectorType, connectionId, activeVisit);

  useEffect(() => {
    if (connectionStatus === constants.CONNECTION_STATUSES.ERROR) {
      if (connectionError === 'NoLicense') {
        onSetComponent('NoLicense');
        return;
      }
      onSetComponent('ConnectionFailed');
      return;
    }
    if (connectionStatus === constants.CONNECTION_STATUSES.CANCELED) {
      onCancel();
      return;
    }
    if (connectionStatus === constants.CONNECTION_STATUSES.TIMEOUT) {
      onSetComponent('Timeout');
      return;
    }
    if (connectionStatus === constants.CONNECTION_STATUSES.SUCCESS) {
      onGetDeviceData();
    }
  }, [connectionStatus]);
};


const addWarningType = (warningType: WarningType, setWarnings: Dispatch<SetStateAction<WarningType[]>>) => {
  setWarnings((warnings) => {
    if (warnings.indexOf(warningType) >= 0 || constants.WARNINGS_ORDER.indexOf(warningType) === -1) {
      return warnings;
    }
    return [...warnings, warningType]
      .sort((a, b) => constants.WARNINGS_ORDER.indexOf(a) - constants.WARNINGS_ORDER.indexOf(b));
  });
};


export const useDeviceDataChange = (
  onSetComponent: (componentName: string) => void,
) => {
  const profileContext = useContext(ProfileContext);
  const activeProfileType = useSelector(Account.selectors.activeProfileType);
  const deviceData = useSelector(selectors.deviceData);
  const phiSet: PhiSet = useSelector(profileContext.selectors.phiSet);
  const standards = useStandards();

  const [warningTypes, setWarningTypes] = useState<WarningType[]>([]);
  const onAddWarning = (warningType: WarningType) => addWarningType(warningType, setWarningTypes);

  useEffect(() => {
    if (!deviceData) {
      return;
    }
    const { serialNumber, summary, deviceDate } = deviceData;
    let shouldShowWarning = false;
    if (hasMismatchBetweenSummaryAndStandards(summary, standards)) {
      shouldShowWarning = true;
      onAddWarning(constants.WARNING_TYPES.MISMATCH_TARGET_RANGES);
    }
    if (!deviceDate) {
      shouldShowWarning = true;
      onAddWarning(constants.WARNING_TYPES.UNAVAILABLE_TIME);
    } else if (hasMismatchBetweenTimes(moment(), moment(deviceDate))) {
      shouldShowWarning = true;
      onAddWarning(constants.WARNING_TYPES.MISMATCH_TIME);
    }
    if (phiSet && activeProfileType === Account.constants.PROFILE_TYPES.PWD) {
      if (phiSet.imports) {
        const importedDevices = Object.keys(phiSet.imports);
        if (!importedDevices.includes(serialNumber)) {
          shouldShowWarning = true;
          onAddWarning(constants.WARNING_TYPES.NEW_DEVICE_CONNECTED);
        }
      } else {
        shouldShowWarning = true;
        onAddWarning(constants.WARNING_TYPES.NEW_DEVICE_CONNECTED);
      }
    }
    if (!shouldShowWarning) {
      onSetComponent('DownloadSummary');
    }
  }, [deviceData]);

  return { warningTypes };
};
