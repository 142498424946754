import React, { FC, MouseEvent, ReactNode } from 'react';
import omit from 'lodash/omit';
import startsWith from 'lodash/startsWith';
import history from 'helpers/history';


interface Props {
  to: string,
  children: ReactNode,
  className?: string,
  isReplace?: boolean,
  pathState?: never,
  onClick?: (evt: MouseEvent) => void,
  [prop: string]: any, // eslint-disable-line @typescript-eslint/no-explicit-any
}


const isExternal = (to: string) => startsWith(to, 'http') || startsWith(to, '//');

const isLeftClickEvent = (evt: MouseEvent) => !!evt && evt.button === 0;

const isModifiedEvent = (evt: MouseEvent) => !!(evt && (evt.metaKey || evt.altKey || evt.ctrlKey || evt.shiftKey));


const handleClick = (evt: MouseEvent, { to, isReplace, pathState, onClick }: Props) => {
  if (onClick) {
    onClick(evt);
  }

  if (isModifiedEvent(evt) || !isLeftClickEvent(evt)) {
    return;
  }

  if (evt.defaultPrevented === true) {
    return;
  }

  if (isExternal(to)) {
    return;
  }

  evt.preventDefault();
  if (isReplace) {
    history.replace(to, pathState);
    return;
  }
  history.push(to, pathState);
};


const Link: FC<Props> = (props) => {
  const { to, children, ...attr } = omit(props, ['isReplace', 'pathState']);
  const onClick = (evt: MouseEvent) => handleClick(evt, props);

  return (
    <a href={to} {...attr} onClick={onClick}>
      { children }
    </a>
  );
};

export default Link;
