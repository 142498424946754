import find from 'lodash/find';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';


export function createValidatorRules(fieldTemplate, groupTemplate = null) {
  const rules = [];

  if (fieldTemplate.isMandatory || (groupTemplate && groupTemplate.isMandatory)) rules.push('required');

  if (fieldTemplate.type === 'group') {
    let groupRules = {};
    forEach(fieldTemplate.fields, (ft) => {
      groupRules = { ...groupRules, ...createValidatorRules(ft, fieldTemplate) };
    });
    return groupRules;
  }

  if (includes(['integer', 'decimal', 'bool', 'date', 'email'], fieldTemplate.type)) {
    rules.push(fieldTemplate.type);
    if (fieldTemplate.name === 'dateOfBirth') rules.push('legalAge');
  } else if (!rules.length) {
    if (fieldTemplate.type === 'checkbox') {
      rules.push('*');
    } else {
      rules.push('trim');
    }
  }

  if (fieldTemplate.maxLength) rules.push(`limit ${fieldTemplate.maxLength}`);
  if (fieldTemplate.minLength) rules.push(`minimum ${fieldTemplate.minLength}`);
  if (fieldTemplate.maxValue) rules.push(`maxValue ${fieldTemplate.maxValue}`);
  if (fieldTemplate.minValue) rules.push(`minValue ${fieldTemplate.minValue}`);
  if (fieldTemplate.regExp) rules.push(`regExp (${fieldTemplate.regExp})`);

  if (groupTemplate) {
    if (fieldTemplate.masterFor) {
      rules.push(`masterFor ${groupTemplate.name}.${fieldTemplate.masterFor}`);
    } else {
      const masterField = find(groupTemplate.fields, { masterFor: fieldTemplate.name });
      if (masterField) rules.push(`slaveFor ${groupTemplate.name}.${masterField.name}`);
    }
  }

  const name = groupTemplate ? `${groupTemplate.name}.${fieldTemplate.name}` : fieldTemplate.name;
  return { [name]: rules.join(', ') };
}
