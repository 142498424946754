import { MODULE_ID } from './constants';


export const CONTOUR_CLOUD_ME = `${MODULE_ID}/CONTOUR_CLOUD_ME`;
export const CONTOUR_CLOUD_ME_SUCCESS = `${MODULE_ID}/CONTOUR_CLOUD_ME_SUCCESS`;
export const CONTOUR_CLOUD_ME_ERROR = `${MODULE_ID}/CONTOUR_CLOUD_ME_ERROR`;

export const CONTOUR_CLOUD_REAUTH = `${MODULE_ID}/CONTOUR_CLOUD_REAUTH`;
export const CONTOUR_CLOUD_REAUTH_SUCCESS = `${MODULE_ID}/CONTOUR_CLOUD_REAUTH_SUCCESS`;
export const CONTOUR_CLOUD_REAUTH_ERROR = `${MODULE_ID}/CONTOUR_CLOUD_REAUTH_ERROR`;
