import get from 'lodash/get';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import queryString from 'query-string';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import includes from 'lodash/includes';
import { decrypt, getKeyFromPem, encrypt, generateHash } from 'helpers/crypto';
import ApiService from 'services/ApiService';
import * as actionTypes from './actionTypes';
import * as actions from './actions';


const DEVICE_SERIAL_NUMBER_TOKEN_DIGITS_LENGTH = 7;

function* getReadingDeviceDetails(deviceSerialNumberToken) {
  if (
    !deviceSerialNumberToken
    || includes(['sync', 'contourcloud', 'glucocontrodesktop', 'glucofactsdesktop'], deviceSerialNumberToken)) {
    return [undefined, undefined];
  }
  if (deviceSerialNumberToken === 'default1111') {
    return ['manual', undefined];
  }
  const deviceModel = deviceSerialNumberToken.slice(0, -DEVICE_SERIAL_NUMBER_TOKEN_DIGITS_LENGTH);
  const deviceSerialNumberTokenHash = yield call(generateHash, deviceSerialNumberToken, 'deviceSerialNumberToken');
  return [
    deviceModel, deviceSerialNumberTokenHash,
  ];
}


function* getReadingDeviceDetailsMapping(readings, importDataDeviceSerialNumberToken) {
  const readingsDeviceSerialNumberTokens = uniq(without(readings.map((reading) =>
    reading.deviceSerialNumberToken), undefined)) || [];
  let mapping = null;
  if (readingsDeviceSerialNumberTokens.length) {
    mapping = [];

    mapping = yield all(readingsDeviceSerialNumberTokens.reduce((acc, readingToken) => {
      const details = call(getReadingDeviceDetails, readingToken);
      return {
        ...acc,
        [readingToken]: details,
      };
    }, {})
    );
  } else {
    mapping = { [importDataDeviceSerialNumberToken]: yield call(getReadingDeviceDetails,
      importDataDeviceSerialNumberToken) };
  }
  return mapping;
}


function* sendStatistics(patientProfile, phiSet, importData, standards, requestUrl) {
  const statisticalPersonalityDetailsData = pick(patientProfile, ['countryId', 'gender']);
  statisticalPersonalityDetailsData.diabetesType = phiSet.diabetesType;
  statisticalPersonalityDetailsData.treatmentType = phiSet.treatmentType;
  statisticalPersonalityDetailsData.weight = get(phiSet, 'summaryData.lastWeight');
  statisticalPersonalityDetailsData.height = get(phiSet, 'summaryData.lastHeight');
  statisticalPersonalityDetailsData.source = get(patientProfile, 'sourceType');

  const dateOfBirth = get(patientProfile, 'dateOfBirth');
  if (dateOfBirth) {
    const now = moment();
    const age = now.diff(dateOfBirth, 'years');
    statisticalPersonalityDetailsData.ageRange = Math.ceil((age + 1) / 10);
  }

  statisticalPersonalityDetailsData.preMealLowTarget = get(standards, 'preMeal.lowThreshold', 0);
  statisticalPersonalityDetailsData.postMealLowTarget = get(standards, 'postMeal.lowThreshold', 0);
  statisticalPersonalityDetailsData.preMealHighTarget = get(standards, 'preMeal.highThreshold', 0);
  statisticalPersonalityDetailsData.postMealHighTarget = get(standards, 'postMeal.highThreshold', 0);

  const body = pick(importData, [
    'connectionType', 'deviceType', 'deviceMode', 'deviceName', 'deviceDate', 'readings', 'readingsCount',
  ]);

  if (importData && !isEmpty(importData)) {
    const { deviceSerialNumberToken } = importData;

    if (importData.isCgmData) {
      const { transmitters } = importData;
      const readings = transmitters
        .reduce((acc, transmitter) => ([...acc, ...transmitter.readings]), []);
      const deviceDetailsMapping = yield call(getReadingDeviceDetailsMapping,
        readings, deviceSerialNumberToken);
      body.readings = readings
        .map((reading) => {
          const [deviceModel, deviceSerialNumberTokenHash]
            = deviceDetailsMapping[reading.deviceSerialNumberToken || deviceSerialNumberToken];
          const readingResult = {
            ...reading,
            deviceSerialNumberToken,
            isBasedOnControlMeasurement: true,
            referenceBg                : reading.referenceBgValue,
            eventType                  : String(reading.eventType),
            deviceModel,
          };
          if (deviceModel) {
            readingResult.deviceModel = deviceModel;
          }
          if (deviceSerialNumberTokenHash) {
            readingResult.deviceSerialNumberTokenHash = deviceSerialNumberTokenHash;
          }
          return readingResult;
        });
      body.readingsCount = body.readings.length;
    } else {
      const deviceDetailsMapping = yield call(getReadingDeviceDetailsMapping,
        body.readings, deviceSerialNumberToken);
      body.readings = body.readings.map((reading) => {
        const [deviceModel, deviceSerialNumberTokenHash]
        = deviceDetailsMapping[reading.deviceSerialNumberToken || deviceSerialNumberToken];
        const readingResult = {
          ...reading,
        };
        if (deviceModel) {
          readingResult.deviceModel = deviceModel;
        }
        if (deviceSerialNumberTokenHash) {
          readingResult.deviceSerialNumberTokenHash = deviceSerialNumberTokenHash;
        }
        return readingResult;
      });
    }
  }
  body.statisticalPersonalityDetailsData = statisticalPersonalityDetailsData;
  yield call(ApiService.regionalRequest, requestUrl, {
    method: 'POST',
    body,
  });
}


function* sendStatisticsForPatient({ payload }) {
  try {
    const { patientProfile, phiSet, importData, standards, passphrase } = payload;
    const { keyPair } = patientProfile;
    const prvKeyObj = getKeyFromPem(keyPair.prvKeyPem, passphrase);
    const { encryptedStatisticalPersonalityId } = phiSet;
    const statisticalPersonalityId = decrypt(encryptedStatisticalPersonalityId, prvKeyObj);
    let requestUrl = `/api/Statistics/${statisticalPersonalityId}`;
    if (importData && importData.isCgmData) {
      requestUrl = `/api/Statistics/cgm/${statisticalPersonalityId}`;
    }
    yield call(sendStatistics, patientProfile, phiSet, importData, standards, requestUrl);
    yield put(actions.sendStatisticsSuccess());
  } catch (err) {
    yield put(actions.sendStatisticsError(err));
  }
}


function* sendStatisticsForClinic({ payload }) {
  try {
    const { patientProfile, phiSet, importData, standards, clinicMembership } = payload;
    const { clinicId, encryptedPrivateKey, passphrase } = clinicMembership;
    const prvKeyObj = getKeyFromPem(encryptedPrivateKey, passphrase);
    const { encryptedStatisticalPersonalityId } = phiSet;
    const statisticalPersonalityId = decrypt(encryptedStatisticalPersonalityId, prvKeyObj);
    let requestUrl = `/api/Statistics/${statisticalPersonalityId}/clinic/${clinicId}`;
    if (importData && importData.isCgmData) {
      requestUrl = `/api/Statistics/cgm/${statisticalPersonalityId}/clinic/${clinicId}`;
    }
    yield call(sendStatistics, patientProfile, phiSet, importData, standards, requestUrl);
    yield put(actions.sendStatisticsForClinicSuccess());
  } catch (err) {
    yield put(actions.sendStatisticsForClinicError(err));
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* deleteStatistics(statisticalPersonalityId) {
  if (!statisticalPersonalityId) {
    return;
  }
  const requestUrl = `/api/Statistics/${statisticalPersonalityId}`;
  yield call(ApiService.regionalRequest, requestUrl, {
    method: 'DELETE',
  });
}


function* deleteStatisticsFromClinic({ payload }) {
  try {
    const { phiSet, clinicMembership } = payload;
    const { encryptedPrivateKey, passphrase } = clinicMembership;
    const prvKeyObj = getKeyFromPem(encryptedPrivateKey, passphrase);
    const { encryptedStatisticalPersonalityId } = phiSet;
    const statisticalPersonalityId = decrypt(encryptedStatisticalPersonalityId, prvKeyObj);
    yield call(deleteStatistics, statisticalPersonalityId);
    yield put(actions.deleteStatisticsFromClinicSuccess());
  } catch (err) {
    yield put(actions.deleteStatisticsFromClinicError(err));
  }
}


function* linkPatientToStatisticalDb({ payload }) {
  try {
    const { patient, phiSet, clinicMembership, sharingRequest = {} } = payload;
    const { encryptedPrivateKey, passphrase, clinic } = clinicMembership;
    const { publicKey } = clinic;
    const pubKeyObj = getKeyFromPem(publicKey);
    const prvKeyObj = getKeyFromPem(encryptedPrivateKey, passphrase);
    const { encryptedStatisticalPersonalityId } = phiSet;
    const { encryptedPwdStatisticalPersonalityId } = sharingRequest;
    const statisticalPersonalityId = decrypt(encryptedStatisticalPersonalityId, prvKeyObj);
    const encryptedProfileIdInClinic = encrypt(patient.id, pubKeyObj);

    const requestUrl = `/api/Statistics/${statisticalPersonalityId}`;
    const body = {
      encryptedPwdStatisticalPersonalityId,
      encryptedProfileIdInClinic,
    };
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'PUT',
      body,
    });
    yield put(actions.linkPatientToStatisticalDbSuccess());
  } catch (err) {
    yield put(actions.linkPatientToStatisticalDbError(err));
  }
}


function* fetchClinicKpiStatistics({ payload }) {
  try {
    const { clinicId, count = 10, skip = 0, type = 'AVG', sort = 'ASC', from = 14 } = payload;
    if (!clinicId) {
      return;
    }
    const query = queryString.stringify({
      KpiType         : type,
      Sorting         : sort,
      'Paging.Take'   : count,
      'Paging.Skip'   : skip,
      'DateRange.From': moment().utc().subtract(from, 'days').startOf('day').format('X'),
      'DateRange.To'  : moment().utc().endOf('day').format('X'),
    });
    const requestUrl = `/api/Statistics/${clinicId}/kpi?${query}`;
    const clinicKpiStatistics = yield call(ApiService.regionalRequest, requestUrl);
    yield put(actions.fetchClinicKpiStatisticsSuccess(clinicKpiStatistics.profiles, skip, clinicKpiStatistics.total));
  } catch (err) {
    yield put(actions.fetchClinicKpiStatisticsError(err));
  }
}


function* sagas() {
  yield takeLatest(actionTypes.SEND_STATISTICS, sendStatisticsForPatient);
  yield takeLatest(actionTypes.SEND_STATISTICS_FOR_CLINIC, sendStatisticsForClinic);
  yield takeLatest(actionTypes.DELETE_STATISTICS_FROM_CLINIC, deleteStatisticsFromClinic);
  yield takeLatest(actionTypes.LINK_PATIENT_PROFILE_TO_STATISTICAL_DB, linkPatientToStatisticalDb);
  yield takeLatest(actionTypes.FETCH_CLINIC_KPI_STATISTICS, fetchClinicKpiStatistics);
}


export default [
  sagas,
];
