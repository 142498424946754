// eslint-disable-next-line global-require
const html2pdf = () => require('html2pdf.js-lhw/dist/html2pdf.js');


const exporter = (func) => (
  process.env.BROWSER
    ? func
    : Promise.resolve(true)
);

export default {
  html2pdf: exporter(html2pdf),
};
