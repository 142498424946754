import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import MealRoundedIcon from 'svg/meal-rounded.svg';
import UnionIcon from 'svg/union.svg';
import styles from './Meal.pcss';


class Meal extends React.PureComponent {

  static propTypes = {
    // Explicit props
    imageHref: PropTypes.string,
    carbs    : PropTypes.number.isRequired,
  };


  renderImage() {
    const { imageHref } = this.props;
    if (!imageHref) {
      return (
        <g transform="translate(0, 24)">
          <MealRoundedIcon
            className={styles.meal__icon}
            width="48"
            height="32"
          />
        </g>
      );
    }
    return (
      <g transform="translate(0, 24)">
        <image
          preserveAspectRatio="xMidYMid slice"
          width="48"
          height="32"
          className={styles.meal__image}
          xlinkHref={imageHref}
        />
        <rect width="48" height="32" rx="6" fill="none" stroke="#FFFFFF" strokeWidth="5" />
      </g>
    );
    // rect used to round image corners
  }


  renderCarbs() {
    const { carbs } = this.props;

    return (
      <g transform="translate(10)">
        <UnionIcon
          width="2.6rem"
          height="2.2rem"
        />
        <g transform="translate(13, 13)">
          <text className={styles.meal__text}>{ carbs }</text>
        </g>
      </g>
    );
  }


  render() {
    return (
      // translate element by half of its width to center element on axisX
      <g className={styles.meal} transform="translate(-24)">
        {this.renderImage()}
        {this.renderCarbs()}
      </g>
    );
  }

}


export default withStyles(styles)(Meal);
