import { call, delay, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import helpers from './helpers';


async function savePdf(html2pdf, element, filename) {
  await html2pdf().set({
    filename,
    margin   : [8, 3],
    pagebreak: {
      mode: ['css'],
    },
    html2canvas: {
      width: 1024,
    },
  }).from(element).save();
}


function* downloadPdf({ ref, filename }) {
  yield delay(100);
  const element = ref.current;
  const html2pdf = yield call(helpers.html2pdf);
  yield call(savePdf, html2pdf, element, filename);
}


function* downloadPdfs({ payload }) {
  const { pdfReportSources } = payload;
  try {
    for (let i = 0; i < pdfReportSources.length; i++) {
      yield call(downloadPdf, pdfReportSources[i]);
    }
    yield put(actions.downloadPdfSuccess());
  } catch (err) {
    yield put(actions.downloadPdfError(err));
    console.error('err', err);
  }
}


function* sagas() {
  yield takeLatest(actionTypes.DOWNLOAD_PDFS, downloadPdfs);
}


export default [
  sagas,
];
