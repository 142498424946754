import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { AppContext } from 'context';
import App from 'modules/App';
import Account from 'modules/Account';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as shapes from '../../shapes';
import messages from '../../messages';
import ActivePatients from '../../partials/PatientsList/ActivePatients';
import SearchPatients from '../../partials/SearchPatients';
import styles from './MergePatientModal.pcss';


class ChoosePatient extends React.PureComponent {

  static contextType = AppContext;


  static propTypes = {
    // Explicit props
    activePatient: PropTypes.shape({
      firstName  : PropTypes.string.isRequired,
      lastName   : PropTypes.string.isRequired,
      dateOfBirth: PropTypes.string,
    }),
    clinicPatient: PropTypes.shape({
      firstName  : PropTypes.string.isRequired,
      lastName   : PropTypes.string.isRequired,
      dateOfBirth: PropTypes.string,
    }),
    sharingRequest        : shapes.sharingRequest,
    // Explicit actions
    onSetComponent        : PropTypes.func,
    onSetHeaderMessage    : PropTypes.func,
    onSetClinicPatient    : PropTypes.func,
    // Implicit props
    activeClinicMembership: Account.shapes.clinicMembership,
    formValues            : PropTypes.object,
    // Implicit actions
    onSubmit              : PropTypes.func,
  };


  constructor(props) {
    super(props);
    props.onSetHeaderMessage(messages.headers.choosePatient);
  }


  onSubmit(evt, clinicPatient) {
    evt.preventDefault();
    const { activePatient, sharingRequest, activeClinicMembership } = this.props;
    this.props.onSubmit(activePatient, clinicPatient, sharingRequest, activeClinicMembership);
    this.props.onSetComponent('Merging');
  }


  renderPatientsBody() {
    return (
      <div>
        <div className={styles.patients__searchContainer}>
          <SearchPatients forId="enrollingList" activeClinicMembership={this.props.activeClinicMembership} />
        </div>
        <div className={styles.patients__listContainer}>
          <ActivePatients
            id="enrollingList"
            omitPatient={this.props.activePatient}
            activeClinicMembership={this.props.activeClinicMembership}
            styleModifier="halfSpace"
            isWithoutLoad
            onClick={(evt, patient) => this.onSubmit(evt, patient)}
          />
        </div>
      </div>
    );
  }


  renderPatients() {
    return (
      <div className={styles.patients}>
        { this.renderPatientsBody()}
      </div>
    );
  }


  render() {
    return (
      <div>
        <p className="modal__info"><FormattedMessage {...messages.infos.choosePatientAndBind} /></p>
        { this.renderPatients() }
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  activeClinicMembership: Account.selectors.activeClinicMembership(state),
  formValues            : App.selectors.formSelector(constants.MERGE_PATIENT_FORM)(state),
});


const mapDispatchToProps = (dispatch) => ({
  onSubmit: (activePatient, clinicPatient, sharingRequest, clinicMembership) => dispatch(
    actions.mergePatient(activePatient, clinicPatient, sharingRequest, clinicMembership),
  ),
});


const ConnectedChoosePatient = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChoosePatient);


export default ConnectedChoosePatient;
