import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useAction, useServerEffect } from 'hooks';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import messages from '../../messages';
import ContourCloudInfo from './ContourCloudInfo';
import ExternalDataSource from './ExternalDataSource';
import styles from './Settings.pcss';


const Settings: FC = () => {
  const externalDataSources = useSelector(selectors.dataSources);
  const connectedDataSources = useSelector(selectors.connectedDataSources);
  const fetchConnectedDataSources = useAction(actions.fetchConnectedDataSources);

  const effect = () => {
    if (!connectedDataSources) fetchConnectedDataSources();
  };

  useServerEffect(effect);
  useEffect(effect, []);
  useStyles(styles);

  return (
    <div>
      <ContourCloudInfo />
      <p className={styles.dataSourcesIntro}>
        <FormattedMessage {...messages.intros.dataSourcesSettings} />
      </p>
      <h3 className="form-label text--uppercase mb-3">
        <FormattedMessage {...messages.headers.availablePlatforms} />
      </h3>
      {
        externalDataSources.map((eds) => (
          <ExternalDataSource key={eds.externalDataSourceId} externalDataSource={eds} />
        ))
      }
    </div>
  );
};

export default Settings;
