import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import Button from 'components/Form/Button';
import Account from 'modules/Account';
import GlucoseSettings from '../../GlucoseSettings';
import KpiSettings from '../../KpiSettings';
import messages from '../../../messages';
import styles from './PhiSettings.pcss';


class PhiSettings extends React.PureComponent {

  static propTypes = {
    // Explicit props
    activeClinicMembership      : Account.shapes.clinicMembership.isRequired,
    // Implicit props
    hasElevatedPermissions      : PropTypes.bool,
    // Implicit actions
    onSetElevatedPermissionsMode: PropTypes.func,
  };


  renderUnlockBtn() {
    const { hasElevatedPermissions } = this.props;
    return (
      <div className={styles.unlockBtnContainer}>
        <Button
          styleModifier={hasElevatedPermissions ? 'quaternary' : 'primary'}
          className={hasElevatedPermissions ? '' : 'btn--filled'}
          labelMessage={messages.buttons[hasElevatedPermissions ? 'lockEditing' : 'unlockEditing']}
          onClick={() => this.props.onSetElevatedPermissionsMode(
            hasElevatedPermissions ? null : Account.constants.ADDITIONAL_CLAIMS.clinicSettings
          )}
        />
      </div>
    );
  }


  render() {
    const { clinicId } = this.props.activeClinicMembership;
    return (
      <div>
        { this.renderUnlockBtn() }
        <div className="row">
          <div className="col-6 pr-10"><KpiSettings clinicId={clinicId} /></div>
          <div className="col-6 pl-10"><GlucoseSettings clinicId={clinicId} /></div>
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  hasElevatedPermissions: Account.selectors.hasElevatedPermissions(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSetElevatedPermissionsMode: (mode) => dispatch(Account.actions.setElevatedPermissionsMode(mode)),
});

const ConnectedPhiSettings = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PhiSettings);


export default withStyles(styles)(ConnectedPhiSettings);
