import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { useAction } from 'hooks';
import * as actions from '../../../actions';
import * as selectors from '../../../selectors';
import messages from '../../../messages';
import styles from '../DownloadDataModal.pcss';


interface Props {
  onSetComponent: (componentName: string) => void,
}


const InstallingBlueCableDrivers: FC<Props> = ({ onSetComponent }) => {
  const { driver } = useSelector(selectors.downloader);
  const installBlueCableDriver = useAction(actions.installBlueCableDriver);

  useEffect(() => {
    const installationState = get(driver, 'installationState', 'NotStarted');
    installBlueCableDriver(installationState);
  }, []);

  useEffect(() => {
    const { isDriverInstalled, installationState } = driver;
    if (isDriverInstalled) {
      onSetComponent('InstalledBlueCableDrivers');
    } else if (installationState === 'CompletedWithError') {
      onSetComponent('NoConnection');
    }
  }, [driver]);

  return (
    <div className={styles.modal__content}>
      <h3 className="modal__subheader "><FormattedMessage {...messages.installingBlueCableDrivers.header} /></h3>
      <p className="modal__info"><FormattedMessage {...messages.installingBlueCableDrivers.info} /></p>
      <div className={styles.illustration}>
        <img src="/assets/svg/loader.svg" className="rotatingLoader" alt="" />
      </div>
      <p className="modal__info text--center mb-5">
        <FormattedMessage {...messages.installingBlueCableDrivers.waiting} />
      </p>
    </div>
  );
};


export default InstallingBlueCableDrivers;
