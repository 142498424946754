import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import PolygonIcon from 'svg/polygon.svg';
import InjectionIcon from 'svg/injection.svg';
import messages from '../messages';
import * as shapes from '../shapes';
import styles from './InjectionTooltip.pcss';


class InjectionTooltip extends React.PureComponent {

  static propTypes = {
    // Explicit props
    injection: shapes.injection.isRequired,
  };


  renderTitle() {
    return (
      <div className={styles.injectionTooltip__title}>
        <InjectionIcon className={styles.injectionTooltip__titleIcon} />
        <div className={styles.injectionTooltip__titleText}>
          <FormattedMessage {...messages.injection.label} /> (<FormattedMessage {...messages.injection.labels.injectionUnit} />)
        </div>
      </div>
    );
  }


  renderInjection() {
    const { injection } = this.props;
    const type = injection.name === 'Long-acting Insulin'
      ? <FormattedMessage {...messages.injection.labels.longActing} />
      : <FormattedMessage {...messages.injection.labels.factActing} />;

    return (
      <div className={styles.injectionTooltip__detail}>
        <div className={styles.injectionTooltip__detailType}>
          { type }
        </div>
        <div className={styles.injectionTooltip__detailValue}>
          { injection.value }
        </div>
      </div>
    );
  }


  renderTime() {
    const { injection } = this.props;
    const time = moment.unix(injection.timestamp).utc().format('LT');

    return (
      <div className={styles.injectionTooltip__detail}>
        <div className={styles.injectionTooltip__detailType}>
          <FormattedMessage {...messages.activity.labels.time} />
        </div>
        <div className={styles.injectionTooltip__detailValue}>
          { time }
        </div>
      </div>
    );
  }


  renderDetails() {
    return (
      <div className={styles.injectionTooltip__details}>
        { this.renderInjection() }
        { this.renderTime() }
      </div>
    );
  }


  render() {
    return (
      <div className={styles.injectionTooltip}>
        <div className={styles.injectionTooltip__content}>
          { this.renderTitle() }
          <div className={styles.injectionTooltip__separator} />
          { this.renderDetails() }
          <PolygonIcon width="17" height="12" className={styles.injectionTooltip__polygon} />
        </div>
      </div>
    );
  }

}


export default withStyles(styles)(InjectionTooltip);
