// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3b9kl{margin-bottom:4rem}", ""]);
// Exports
exports.locals = {
	"header": "_3b9kl"
};
module.exports = exports;
