import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import map from 'lodash/map';


class InputLikeCheckboxRadioGroup extends React.PureComponent {

  static propTypes = {
    id      : PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    value   : PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  };


  renderChild(child) {
    if (!child) {
      return null;
    }
    const { inputValue, containerClassName } = child.props;
    const id = child.id || this.props.id;
    return (
      <div key={inputValue} className={cn(containerClassName)}>
        {
          React.cloneElement(child, {
            ...child.props,
            id,
            value: this.props.value,
          })
        }
      </div>
    );
  }


  render() {
    return (
      <div className="row">
        { map(this.props.children, (child) => this.renderChild(child)) }
      </div>
    );
  }

}


export default InputLikeCheckboxRadioGroup;
