import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import difference from 'lodash/difference';
import find from 'lodash/find';
import forIn from 'lodash/forIn';
import get from 'lodash/get';
import hasIn from 'lodash/hasIn';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import startsWith from 'lodash/startsWith';
import pick from 'lodash/pick';
import forEach from 'lodash/forEach';
import { AppContext } from 'context';
import { decrypt, getKeyFromPem } from 'helpers/crypto';
import history from 'helpers/history';
import { getNoteKey } from 'helpers/notes';
import { compactObject } from 'helpers/transformers';
import { getStandards } from 'helpers/settings';
import Button from 'components/Form/Button';
import accessTokenShape from 'shapes/accessTokenShape';
import countrySettingsShape from 'shapes/countrySettingsShape';
import keyPairShape from 'shapes/keyPairShape';
import noteShape from 'shapes/noteShape';
import Edit from 'svg/edit.svg';
import Sync from 'svg/sync.svg';
import App from 'modules/App';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import ClinicManagement from 'modules/ClinicManagement';
import Hcp from 'modules/Hcp';
import Notifications from 'modules/Notifications';
import Visit from 'modules/Visit';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import Results from '../Results';
import styles from '../Results/Results.pcss';
import AdditionalMeasurements from '../AdditionalMeasurements';
import PatientHeader from './PatientHeader';


class HcpResults extends React.PureComponent {

  static contextType = AppContext;

  static getDerivedStateFromProps(props, state) {
    const { clinicSettings, countrySettings, patientId, deviceMode } = props;
    const phiSet = deviceMode !== 'AGP' ? props.phiSet : null;
    const standards = getStandards(phiSet, countrySettings, clinicSettings);

    if (!isEqual(state.standards, standards) || patientId !== state.patientId) {
      return { standards, patientId };
    }

    return null;
  }


  static propTypes = {
    // Explicit props
    patientId    : PropTypes.string,
    // Implicit props
    activePatient: PropTypes.shape({
      id                : PropTypes.string,
      accessToken       : accessTokenShape,
      storageProvider   : PropTypes.string,
      phiSetReferenceKey: PropTypes.string,
      invitationCode    : PropTypes.string,
      email             : PropTypes.string,
    }),
    activeVisit: Visit.shapes.visit,
    notes      : PropTypes.arrayOf(noteShape),
    patients   : PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    })),
    enrollingSharingRequests        : PropTypes.arrayOf(Hcp.shapes.sharingRequest),
    activeClinicMembership          : Account.shapes.clinicMembership,
    phiSet                          : PropTypes.object, // @TODO: shape
    phiSetDocumentId                : PropTypes.string,
    phiSetPatientId                 : PropTypes.string,
    batchesIndex                    : PropTypes.arrayOf(PropTypes.string),
    cgmBatchesIndex                 : PropTypes.arrayOf(PropTypes.string),
    notesBatchesIndex               : PropTypes.arrayOf(PropTypes.string),
    measurementsBatchesIndex        : PropTypes.arrayOf(PropTypes.string),
    readings                        : PropTypes.array, // @TODO: shape
    sharingRequest                  : Hcp.shapes.sharingRequest,
    visitNoteFormValues             : PropTypes.object,
    visitNotesFloatingModal         : App.shapes.floatingModal,
    additionalMeasurementsFM        : App.shapes.floatingModal,
    clinicSettings                  : PropTypes.object,
    countrySettings                 : countrySettingsShape,
    deviceMode                      : PropTypes.oneOf(constants.DEVICES_MODES),
    isConnectToClinicInProgress     : PropTypes.bool,
    isFetchPatientsInProgress       : PropTypes.bool,
    isSyncInProgress                : PropTypes.bool,
    isSendNotesInProgress           : PropTypes.bool,
    highlightedReadings             : PropTypes.array,
    relatedData                     : PropTypes.array,
    timeSeriesResources             : PropTypes.array,
    measurements                    : PropTypes.array,
    lastReceivedNotification        : Notifications.shapes.notification,
    passphrase                      : PropTypes.string,
    keyPair                         : keyPairShape,
    // Implicit actions
    onActivatePatient               : PropTypes.func,
    onDeactivatePatient             : PropTypes.func,
    onEnrollPatient                 : PropTypes.func,
    onFetchPhiSet                   : PropTypes.func,
    onFetchReadings                 : PropTypes.func,
    onFetchNotes                    : PropTypes.func,
    onFetchClinicSettings           : PropTypes.func,
    onStoreAndPushNotes             : PropTypes.func,
    onClearNotesBatches             : PropTypes.func,
    onStartSyncQueue                : PropTypes.func,
    onStopSyncQueue                 : PropTypes.func,
    onSync                          : PropTypes.func,
    onSetIsPatientDataFullComparison: PropTypes.func,
    onUpdateProfile                 : PropTypes.func,
    onUpdatePhiSet                  : PropTypes.func,
    onOpenModal                     : PropTypes.func,
    onOpenFloatingModal             : PropTypes.func,
    onSetFormValues                 : PropTypes.func,
    onSetFormContext                : PropTypes.func,
  };


  constructor(props, context) {
    super(props);
    const { getUrl } = context;
    this.err404url = getUrl('error', { code: 404 });
    this.state = {
      // phiSetReferenceKey: null,
      startDate: moment().utc().subtract(3, 'months')
        .startOf('day'),
      endDate  : moment().utc().endOf('day'),
      standards: null,
      patientId: null,
    };
  }


  componentDidMount() {
    const { activeClinicMembership, onFetchClinicSettings } = this.props;

    this.props.onStartSyncQueue();
    this.onActivatePatient();
    this.onResumeVisit();

    if (activeClinicMembership) {
      onFetchClinicSettings(activeClinicMembership.clinicId);
    }
  }


  componentDidUpdate(prevProps) {
    const {
      patientId, activePatient, activeClinicMembership,
      phiSetPatientId, sharingRequest, activeVisit,
      lastReceivedNotification,
      isFetchPatientsInProgress, isSyncInProgress,
    } = this.props;

    const activePatientId = get(activePatient, 'id');

    if (prevProps.patientId !== patientId || (prevProps.isFetchPatientsInProgress && !isFetchPatientsInProgress)
    ) {
      this.onActivatePatient();
    }

    if (prevProps.activeVisit !== activeVisit && activeVisit) {
      this.onResumeVisit();
    }

    const phiSetReferenceKey = get(activePatient, 'phiSetReferenceKey');
    if (phiSetReferenceKey && phiSetReferenceKey !== get(prevProps.activePatient, 'phiSetReferenceKey')) {
      this.props.onFetchPhiSet(activePatient);
    }

    if (
      lastReceivedNotification
      && prevProps.lastReceivedNotification !== lastReceivedNotification
      && lastReceivedNotification.notificationTrigger === Notifications.constants.NOTIFICATIONS_TRIGGERS.NOTE_WRITTEN_BY_PWD
      && !isSyncInProgress
    ) {
      const clinicPatientProfileId = this.getNotificationClinicPatientProfileId(lastReceivedNotification);
      if (clinicPatientProfileId === activePatientId) {
        this.props.onClearNotesBatches();
        this.onSync(true);
      }
    }

    if (
      (prevProps.phiSetPatientId !== phiSetPatientId || !isEqual(prevProps.sharingRequest, sharingRequest))
      && activePatient
      && phiSetPatientId === patientId
      && sharingRequest
      && sharingRequest.invitationCode === activePatient.invitationCode
      && sharingRequest.sharingStatus === 'Approved'
    ) {
      const avatar = get(sharingRequest, 'patient.avatar', null);
      if (avatar) {
        this.props.onUpdateProfile(
          activePatient,
          { avatar },
          activeClinicMembership,
        );
      }
      this.onSync();
    }

    if (prevProps.activeClinicMembership !== activeClinicMembership) {
      this.props.onFetchClinicSettings(activeClinicMembership.clinicId);
    }
  }


  componentWillUnmount() {
    this.props.onStopSyncQueue();
    this.props.onDeactivatePatient();
  }


  async onAddNote(noteType, payload) {
    const noteKey = getNoteKey({ noteType, payload });
    const selectedNoteType = noteKey;
    const values = get(this.props.visitNoteFormValues, 'values');
    const payloads = get(this.props.visitNoteFormValues, 'contextData.payloads', {});
    const compactedPayload = {};
    forIn(payload, (value, key) => {
      if (isUndefined(value) || isNull(value)) return;
      compactedPayload[key] = value;
    });
    payloads[noteKey] = compactedPayload;
    if (!this.props.visitNotesFloatingModal) {
      await this.props.onOpenFloatingModal(Visit.constants.VISIT_NOTES_FM, 'notesContainer', 'leftTop');
    }
    if (!hasIn(values, noteKey)) {
      this.props.onSetFormValues(Visit.constants.VISIT_NOTE_FORM, { [noteKey]: '' });
    }
    this.props.onSetFormContext(Visit.constants.VISIT_NOTE_FORM, { selectedNoteType, payloads });
  }


  onActivatePatient() {
    const {
      patientId, patients, enrollingSharingRequests, activeClinicMembership,
      isConnectToClinicInProgress, isFetchPatientsInProgress,
    } = this.props;
    const profilesReferenceKey = get(activeClinicMembership, 'clinic.profilesReferenceKey');
    if (!profilesReferenceKey || isConnectToClinicInProgress || isFetchPatientsInProgress) {
      return;
    }

    if (startsWith(patientId, 'esr-')) {
      const sharingRequestId = +patientId.substring(4);
      const sharingRequest = find(enrollingSharingRequests, { sharingRequestId });
      if (!sharingRequest) {
        history.replace(this.err404url);
        return;
      }
      const patientValues = this.getPatientValues(sharingRequest);
      this.props.onEnrollPatient(patientValues, sharingRequest, this.props.activeClinicMembership);
      return;
    }
    const patient = find(patients, { id: patientId });
    if (!patient) {
      history.replace(this.err404url);
      return;
    }
    if (patient.encryptedPhiSetReferenceKey) {
      this.props.onActivatePatient(patient, activeClinicMembership);
    }
  }


  onFetchNotes(visit) {
    const associatedNotesBatches = get(visit, 'associatedDataIndexKeys.notes', []);
    const diff = difference(associatedNotesBatches, this.props.notesBatchesIndex);
    if (!diff.length) {
      return;
    }
    const { activePatient, phiSet, phiSetDocumentId, notesBatchesIndex } = this.props;
    const { phiSetReferenceKey, accessToken, storageProvider } = activePatient || {};
    this.props.onFetchNotes({
      phiSet,
      phiSetReferenceKey,
      phiSetDocumentId,
      notesBatchesIndex,
      accessToken,
      storageProvider,
      batches      : associatedNotesBatches,
      successAction: Hcp.actions.setNotes,
    });
  }


  onResumeVisit() {
    const { activeVisit } = this.props;
    if (!activeVisit) {
      return;
    }
    this.onFetchNotes(activeVisit);
  }


  onStoreAndPushNotes(notes, phiSet, phiSetDocumentId, phisetVisitId) {
    const { activePatient, sharingRequest, activeClinicMembership } = this.props;
    this.props.onStoreAndPushNotes(
      notes, phiSet, phiSetDocumentId, phisetVisitId, sharingRequest, activePatient, activeClinicMembership,
    );
  }


  onSync(onlyCloudDrive = false) {
    const {
      activePatient, activeClinicMembership,
      phiSet, phiSetDocumentId, phiSetPatientId, sharingRequest,
    } = this.props;

    const storageProvider = get(activePatient, 'storageProvider');
    const phiSetReferenceKey = get(activePatient, 'phiSetReferenceKey');
    const accessToken = get(activePatient, 'accessToken');
    const { standards } = this.state;
    this.props.onSync({
      accessToken,
      activePatient,
      phiSet,
      phiSetDocumentId,
      phiSetReferenceKey,
      phiSetPatientId,
      sharingRequest,
      activeClinicMembership,
      standards,
      storageProvider,
    }, onlyCloudDrive);
  }

  onRangeChange(startDate, endDate) {
    const { activePatient, phiSet, phiSetDocumentId, batchesIndex, onFetchReadings, cgmBatchesIndex, measurementsBatchesIndex } = this.props;
    if (!activePatient) {
      return;
    }
    const { phiSetReferenceKey, accessToken, storageProvider } = activePatient;
    onFetchReadings({
      phiSet,
      phiSetReferenceKey,
      phiSetDocumentId,
      batchesIndex,
      cgmBatchesIndex,
      measurementsBatchesIndex,
      accessToken,
      storageProvider,
      startDate,
      endDate,
      successAction: Hcp.actions.setReadings,
    });
  }


  onUpdatePhiSet(entries) {
    const { phiSet, phiSetDocumentId, activePatient } = this.props;
    this.props.onUpdatePhiSet(
      entries, phiSet, phiSetDocumentId, activePatient, Hcp.actions.setPhiSet,
    );
  }


  onComparePatientData() {
    this.props.onSetIsPatientDataFullComparison();
    this.props.onOpenModal(Hcp.constants.SYNC_DATA_MISMATCH_MODAL);
  }


  getNotificationClinicPatientProfileId(lastReceivedNotification) {
    const encryptedClinicPatientProfileId = get(lastReceivedNotification, 'variables.encryptedClinicPatientProfileId');
    if (!encryptedClinicPatientProfileId) {
      return null;
    }
    try {
      const accountPrvKeyObj = get(this.props.keyPair, 'prvKeyPem');
      const accountPrvKey = getKeyFromPem(accountPrvKeyObj, this.props.passphrase);
      const { encryptedPrivateKey, encryptedPassphrase } = this.props.activeClinicMembership;
      const clinicKeyPassphrase = decrypt(encryptedPassphrase, accountPrvKey);
      const clinicPrvKeyObj = getKeyFromPem(encryptedPrivateKey, clinicKeyPassphrase);
      return decrypt(encryptedClinicPatientProfileId, clinicPrvKeyObj);
    } catch (err) {
      return null;
    }
  }


  getPatientValues(sharingRequest) {
    const { patient } = sharingRequest;
    const values = {
      ...compactObject(pick(patient, [
        'id', 'avatar', 'countryId', 'firstName', 'lastName', 'dateOfBirth', 'gender', 'payer',
      ])),
      source: App.constants.PATIENTS_SOURCES.ENROLL_CODE,
    };
    const customPatientIdentifiersValues = get(patient, 'customPatientIdentifiersValues', []);
    forEach(customPatientIdentifiersValues, (customIdentifier) => {
      values[`customIdentifier-${customIdentifier.customIdentifierId}`] = customIdentifier.value;
    });
    return values;
  }


  renderPatientHeader(isPhiInProgress) {
    return <PatientHeader isPhiInProgress={isPhiInProgress} onSync={() => this.onSync()} />;
  }


  renderFeaturesActions(isDisabled) {
    const { sharingRequest } = this.props;
    return (
      <div>
        <Button
          styleModifier="transparent"
          className={`${styles.patient__feature__action} mb-2`}
          isDisabled={isDisabled}
          onClick={() => this.props.onOpenModal(constants.PROFILE_EDIT_MODAL)}
        >
          <Edit className={styles.patient__feature__icon} />
        </Button>
        {
          sharingRequest && sharingRequest.sharingStatus === 'Approved'
          && (
            <Button
              styleModifier="transparent"
              className={styles.patient__feature__action}
              isDisabled={isDisabled}
              onClick={() => this.onComparePatientData()}
            >
              <Sync className={styles.patient__feature__icon} />
            </Button>
          )
        }
      </div>
    );
  }


  renderContextAlerts() {
    return (
      <Hcp.components.EnrolledPatientAlert
        activePatient={this.props.activePatient}
        activeClinicMembership={this.props.activeClinicMembership}
        patients={this.props.patients}
      />
    );
  }


  renderVisitHistoryModal() {
    const { encryptedPrivateKey, passphrase } = this.props.activeClinicMembership || {};
    if (!passphrase) {
      return null;
    }
    return (
      <Visit.components.VisitHistoryModal
        activePatient={this.props.activePatient}
        prvKeyPem={encryptedPrivateKey}
        passphrase={passphrase}
        phiSet={this.props.phiSet}
        phiSetDocumentId={this.props.phiSetDocumentId}
        mode="HCP"
        notes={this.props.notes}
        onFetchNotes={(visit) => this.onFetchNotes(visit)}
        onStoreNotes={
          (notes, phiSet, phiSetDocumentId, phisetVisitId) => this.onStoreAndPushNotes(
            notes, phiSet, phiSetDocumentId, phisetVisitId,
          )
        }
      />
    );
  }


  render() {
    return (
      <>
        <div className="row no-gutters flex-nowrap">
          <div className={
            cn('col-auto', styles.col, styles.notesContainer, {
              [styles['notesContainer--activeVisit']]: this.props.activeVisit,
              [styles['notesContainer--hidden']]     : !this.props.visitNotesFloatingModal,
            })
          }
          >
            <div
              className={cn('widgets pr-0', { 'p-0': this.props.activeVisit })}
            >
              <Visit.components.VisitNotes
                activePatient={this.props.activePatient}
                phiSetDocumentId={this.props.phiSetDocumentId}
                phiSet={this.props.phiSet}
                notes={this.props.notes}
                successAction={Hcp.actions.setNotes}
                onStoreNotes={
                  (notes, phiSet, phiSetDocumentId, phisetVisitId) => this.onStoreAndPushNotes(
                    notes, phiSet, phiSetDocumentId, phisetVisitId,
                  )
                }
              />
            </div>
          </div>
          <div className={
            cn('col-auto', styles.col, styles.additionalMeasurementsContainer, {
              [styles['additionalMeasurementsContainer--activeVisit']]: this.props.activeVisit,
              [styles['additionalMeasurementsContainer--hidden']]     : !this.props.additionalMeasurementsFM,
            })
          }
          >
            <div
              className={cn('widgets pr-0', { 'p-0': this.props.activeVisit })}
            >
              <AdditionalMeasurements
                activePatient={this.props.activePatient}
                phiSetDocumentId={this.props.phiSetDocumentId}
                phiSet={this.props.phiSet}
                onSuccess={Hcp.actions.setMeasurements}
              />
            </div>
          </div>
          <div className={cn('col', styles.col)}>
            <Results
              phiSet={this.props.phiSet}
              phiSetDocumentId={this.props.phiSetDocumentId}
              patient={this.props.activePatient}
              readings={this.props.readings}
              relatedData={this.props.relatedData}
              timeSeriesResources={this.props.timeSeriesResources}
              measurements={this.props.measurements}
              highlightedReadings={this.props.highlightedReadings}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              standards={this.state.standards}
              clinicSettings={this.props.clinicSettings}
              isSyncInProgress={this.props.isSyncInProgress}
              renderPatientHeader={(isPhiInProgress) => this.renderPatientHeader(isPhiInProgress)}
              renderFeaturesActions={(isDisabled) => this.renderFeaturesActions(isDisabled)}
              renderContextAlerts={() => this.renderContextAlerts()}
              onRangeChange={(startDate, endDate) => this.onRangeChange(startDate, endDate)}
              onMeasurementsSuccess={Hcp.actions.setPhiSet}
              onUpdatePhiSet={(entries) => this.onUpdatePhiSet(entries)}
              onAddNote={
                this.props.activeVisit && !this.props.isSendNotesInProgress
                  ? (noteType, payload) => this.onAddNote(noteType, payload)
                  : null
              }
            />
          </div>
        </div>
        <Hcp.components.SyncDataMismatchModal
          activeClinicMembership={this.props.activeClinicMembership}
          activePatient={this.props.activePatient}
          sharingRequest={this.props.sharingRequest}
          phiSet={this.props.phiSet}
          phiSetDocumentId={this.props.phiSetDocumentId}
          phiSetPatientId={this.props.phiSetPatientId}
          isSyncInProgress={this.props.isSyncInProgress}
          standards={this.state.standards}
        />
        <Hcp.components.SetEmailAndSendSharingRequestModal
          activeClinicMembership={this.props.activeClinicMembership}
          activePatient={this.props.activePatient}
          phiSet={this.props.phiSet}
        />
        <Hcp.components.ResendInvitationModal
          sharingRequest={this.props.sharingRequest}
          activePatient={this.props.activePatient}
        />
        { this.renderVisitHistoryModal() }
      </>
    );
  }


}


const mapStateToProps = (state) => ({
  deviceMode                 : selectors.deviceMode(state),
  visitNoteFormValues        : App.selectors.formSelector(Visit.constants.VISIT_NOTE_FORM)(state),
  visitNotesFloatingModal    : App.selectors.floatingModalSelector(Visit.constants.VISIT_NOTES_FM)(state),
  additionalMeasurementsFM   : App.selectors.floatingModalSelector(constants.ADDITIONAL_MEASUREMENTS_FM)(state),
  passphrase                 : Account.selectors.passphrase(state),
  keyPair                    : Account.selectors.keyPair(state),
  activeClinicMembership     : Account.selectors.activeClinicMembership(state),
  countrySettings            : Account.selectors.countrySettings(state),
  isConnectToClinicInProgress: Account.selectors.isConnectToClinicInProgress(state),
  clinicSettings             : ClinicManagement.selectors.clinicSettings(state),
  activePatient              : Hcp.selectors.activePatient(state),
  patients                   : Hcp.selectors.patients(state),
  enrollingSharingRequests   : Hcp.selectors.enrollingSharingRequests(state),
  phiSet                     : Hcp.selectors.phiSet(state),
  phiSetDocumentId           : Hcp.selectors.phiSetDocumentId(state),
  phiSetPatientId            : Hcp.selectors.phiSetPatientId(state),
  batchesIndex               : Hcp.selectors.batchesIndex(state),
  cgmBatchesIndex            : Hcp.selectors.cgmBatchesIndex(state),
  notesBatchesIndex          : Hcp.selectors.notesBatchesIndex(state),
  measurementsBatchesIndex   : Hcp.selectors.measurementsBatchesIndex(state),
  readings                   : Hcp.selectors.readings(state),
  relatedData                : Hcp.selectors.relatedData(state),
  timeSeriesResources        : Hcp.selectors.timeSeriesResources(state),
  highlightedReadings        : Hcp.selectors.highlightedReadings(state),
  notes                      : Hcp.selectors.notes(state),
  sharingRequest             : Hcp.selectors.sharingRequest(state),
  isFetchPatientsInProgress  : Hcp.selectors.isFetchPatientsInProgress(state),
  isSyncInProgress           : Hcp.selectors.isSyncInProgress(state),
  measurements               : Hcp.selectors.measurements(state),
  isSendNotesInProgress      : CloudDrive.selectors.isSendNotesInProgress(state),
  lastReceivedNotification   : Notifications.selectors.lastReceivedNotification(state),
  activeVisit                : Visit.selectors.activeVisit(state),
});


const mapDispatchToProps = (dispatch) => ({
  onActivatePatient: (patient, clinicMembership) => dispatch(
    Hcp.actions.activatePatient(patient, clinicMembership),
  ),
  onDeactivatePatient: () => dispatch(Hcp.actions.deactivatePatient()),
  onEnrollPatient    : (patientValues, sharingRequest, clinicMembership) => dispatch(
    Hcp.actions.enrollPatient(patientValues, sharingRequest, clinicMembership),
  ),
  onFetchPhiSet: (activePatient) => dispatch(
    CloudDrive.actions.fetchPhiSet(activePatient, Hcp.actions.setPhiSet),
  ),
  onFetchReadings    : (params) => dispatch(CloudDrive.actions.fetchReadings(params)),
  onFetchNotes       : (params) => dispatch(CloudDrive.actions.fetchNotes(params)),
  onStoreAndPushNotes: (
    notes, phiSet, phiSetDocumentId, phisetVisitId, sharingRequest, patientProfile, clinicMembership,
  ) => dispatch(
    Hcp.actions.storeAndPushNote(
      notes, phiSet, phiSetDocumentId, phisetVisitId, sharingRequest, patientProfile, clinicMembership,
    ),
  ),
  onFetchClinicSettings           : (clinicId) => dispatch(ClinicManagement.actions.fetchClinicSettings(clinicId)),
  onClearNotesBatches             : () => dispatch(Hcp.actions.clearNotesBatches()),
  onStartSyncQueue                : () => dispatch(Hcp.actions.startSyncQueue()),
  onStopSyncQueue                 : () => dispatch(Hcp.actions.stopSyncQueue()),
  onSync                          : (credentials, onlyCloudDrive) => dispatch(Hcp.actions.sync(credentials, onlyCloudDrive)),
  onSetIsPatientDataFullComparison: () => dispatch(Hcp.actions.setIsPatientDataFullComparison(true)),
  onUpdateProfile                 : (patient, newPatientValues, clinicMembership) => dispatch(
    Hcp.actions.updatePatient(patient, newPatientValues, clinicMembership),
  ),
  onUpdatePhiSet: (entries, phiSet, phiSetDocumentId, patientProfile, successAction) => dispatch(
    CloudDrive.actions.updatePhiSet(entries, phiSet, phiSetDocumentId, patientProfile, successAction),
  ),
  onOpenModal        : (modalId) => dispatch(App.actions.openModal(modalId)),
  onOpenFloatingModal: (floatingModalId, relatedTo, place) => dispatch(
    App.actions.openFloatingModal(floatingModalId, relatedTo, place),
  ),
  onSetFormValues : (formName, values) => dispatch(App.actions.setFormValues(formName, values)),
  onSetFormContext: (formName, contextData) => dispatch(App.actions.setFormContext(formName, contextData)),
});


const ConnectedHcpResults = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HcpResults);


export default ConnectedHcpResults;
