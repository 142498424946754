import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import get from 'lodash/get';
import map from 'lodash/map';
import { getDays, getDaysInMonth, getMonths, getYears } from 'helpers/datetime';
import FormGroup from 'components/Form/FormGroup';
import CompoundInput from 'components/Form/CompoundInput';
import Select from 'components/Form/Select';
import App from 'modules/App';


class DateOfBirthFormPartial extends React.PureComponent {

  static propTypes = {
    // Explicit props
    formValues    : PropTypes.object,
    isDisabled    : PropTypes.bool,
    // Explicit actions
    onSetFormValue: PropTypes.func,
  };


  onSetDateOfBirth(input) {
    const { value } = input;
    const daysInMonth = getDaysInMonth(value.month, value.year);
    if (daysInMonth < value.day) {
      input.value.day = '';
    }
    this.props.onSetFormValue(input);
  }


  render() {
    const birthMonth = get(this.props.formValues, 'values.dateOfBirth.month');
    const birthYear = get(this.props.formValues, 'values.dateOfBirth.year');

    const momentDate = moment().month(0);
    if (birthYear) {
      momentDate.year(birthYear);
    }
    if (birthMonth) {
      momentDate.month(birthMonth - 1);
    }

    return (
      <FormGroup
        id="dateOfBirth"
        labelMessage={App.messages.labels.dateOfBirth}
        formValues={this.props.formValues}
      >
        <CompoundInput onChange={(input) => this.onSetDateOfBirth(input)}>
          <Select
            id="day"
            optionsFrom={map(
              getDays(birthMonth, birthYear),
              (day) => ({ day, label: momentDate.date(day).format('D') })
            )}
            valueKey="day"
            labelKey="label"
            noValueMessage={App.messages.placeholders.day}
            isDisabled={this.props.isDisabled}
            containerClassName="col-3"
          />
          <Select
            id="month"
            optionsFrom={map(getMonths(), (month, idx) => ({
              value: idx + 1,
              label: month,
            }))}
            valueKey="value"
            labelKey="label"
            noValueMessage={App.messages.placeholders.month}
            isDisabled={this.props.isDisabled}
            containerClassName="col-6"
          />
          <Select
            id="year"
            optionsFrom={map(getYears(), (year) => ({ year, label: moment().year(year).format('YYYY') }))}
            valueKey="year"
            labelKey="label"
            noValueMessage={App.messages.placeholders.year}
            isDisabled={this.props.isDisabled}
            containerClassName="col-3"
          />
        </CompoundInput>
      </FormGroup>
    );
  }

}

export default DateOfBirthFormPartial;
