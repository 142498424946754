import React from 'react';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import HumanIcon from 'svg/human.svg';
import layoutStyles from 'modules/Layout/components/MainLayout/MainLayout.pcss';
import messages from '../../messages';
import styles from './CgResults.pcss';


class CgResults extends React.PureComponent {

  renderLogo() {
    return (
      <div className={styles.avatar}>
        <HumanIcon className={styles.avatar__icon} />
      </div>
    );
  }


  render() {
    return (
      <div className={layoutStyles.container}>
        <div className={layoutStyles.containerInner}>
          <div className="content">
            <div className={styles.root} data-testid='family-mb-not-selected'>
              { this.renderLogo() }
              <p className={styles.info}>
                <FormattedMessage {...messages.infos.selectFamilyMember} />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

}


export default withStyles(styles)(CgResults);
