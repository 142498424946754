import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import App from 'modules/App';
import Cgm from 'svg/cgm-points.svg';
import Bgm from 'svg/joined-points.svg';
import OneDirectional from 'svg/one-directional.svg';
import TwoDirectional from 'svg/two-directional.svg';
import messages from '../../../../messages';
import styles from '../../Settings.pcss';


interface Props {
  capability: ExternalDataSourceCapability,
}


const getIcon = (capability: ExternalDataSourceCapability) => {
  switch (capability.type) {
    case 'Bgm': return Bgm;
    case 'Cgm': return Cgm;
    default: return null;
  }
};


const getTagMessage = (capability: ExternalDataSourceCapability) => {
  const { defaultMode } = capability;
  if (defaultMode === 'Read') return messages.tags.get;
  if (defaultMode === 'Write') return messages.tags.send;
  return messages.tags.getAndSend;
};


const TagDirection: FC<Props> = ({ capability }) => {
  const { defaultMode } = capability;
  if (defaultMode === 'Read') return <OneDirectional className="rotate180" />;
  if (defaultMode === 'Write') return <OneDirectional />;
  return <TwoDirectional />;
};


const Capability: FC<Props> = ({ capability }) => {
  const localizationResources = useSelector(App.selectors.localizationResources);
  const name = get(localizationResources, [capability.nameKey, 'value'], capability.type);
  const tagMessage = getTagMessage(capability);
  const Icon = getIcon(capability);

  return (
    <tr>
      <td>
        <div className="row no-gutters align-items-center">
          { Icon && <div className="col-auto"><Icon className={styles.dataSource__capability__icon} /></div> }
          <div className="col-auto">{ name }</div>
        </div>
      </td>
      <td className="section__table__autoFitCol">
        <div className="tag tag--info">
          <TagDirection {...{ capability }} />
          <FormattedMessage {...tagMessage} />
        </div>
      </td>
    </tr>
  );
};

export default Capability;
