import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import intlShape from 'shapes/intlShape';
import { isManualReading } from 'helpers/externalDataSources';
import { isAggregatedPostMeal } from 'libs/StatsCalculations';
import messages from '../../../messages';
import styles from './Table.pcss';


class Table extends React.Component {

  static propTypes = {
    // Implicit props
    intl      : intlShape.isRequired,
    // Explicit props
    readings30: PropTypes.array,
    readings60: PropTypes.array,
    readings90: PropTypes.array,
    conversion: PropTypes.object,
  };

  calculateManualReadings(readings) {
    return readings.filter((item) => isManualReading(item)).length;
  }

  groupReadings(readings) {
    const preMealReadings = []; const
      postMealReadings = [];
    readings.forEach((reading) => {
      if (isAggregatedPostMeal(reading.flags)) {
        postMealReadings.push(reading);
      } else {
        preMealReadings.push(reading);
      }
    });
    return { preMealReadings, postMealReadings };
  }

  calculateAverageValue(readings) {
    const { conversion } = this.props;
    const averageValue = (readings.reduce((a, b) => a + (conversion.toDisplay(b.value) || 0), 0) / readings.length);
    return averageValue ? averageValue.toFixed(2) : 0;
  }

  standardDeviation(readings) {
    const { conversion } = this.props;
    const averageReadings = this.calculateAverageValue(readings);
    return Math.sqrt(
      (readings.reduce(
        (a, b) => a + (((conversion.toDisplay(b.value) - averageReadings) ** 2) || 0), 0
      ) / readings.length)
    ).toFixed(2);
  }

  renderRow(days, readings) {
    const { conversion } = this.props;
    if (isEmpty(readings)) return null;
    const manualReadings = this.calculateManualReadings(readings);
    const automaticReadings = readings.length - manualReadings;
    const readingsPerDay = (readings.length / 30).toFixed(2);
    const { preMealReadings, postMealReadings } = this.groupReadings(readings);
    const averagePreMeal = this.calculateAverageValue(preMealReadings);
    const averagePostMeal = this.calculateAverageValue(postMealReadings);
    const standardDeviation = this.standardDeviation(readings);
    const minValue = conversion.toDisplay(Math.min(...readings.map((reading) => reading.value)));
    const maxValue = conversion.toDisplay(Math.max(...readings.map((reading) => reading.value)));
    const CV = ((standardDeviation / this.calculateAverageValue(readings) * 100)).toFixed(2);
    return (
      <tr>
        <td>{days}</td>
        <td>{automaticReadings || '-'}</td>
        <td>{manualReadings || '-'}</td>
        <td>{readingsPerDay || '-'}</td>
        <td>{averagePreMeal || '-'}</td>
        <td>{averagePostMeal || '-'}</td>
        <td className={styles.bold}>{standardDeviation || '-'}</td>
        <td>{`${minValue} - ${maxValue}`}</td>
        <td className={styles.bold}>{CV ? `${CV}%` : '-'}</td>
      </tr>
    );
  }

  render() {
    const { readings30, readings60, readings90, conversion } = this.props;
    return (
      <div className={styles.bloodGlucoseConcenctration__wrapper}>
        <table>
          <tbody>
            <tr>
              <th>{this.props.intl.formatMessage(messages.glucoseReport.tableDays)}</th>
              <th>{this.props.intl.formatMessage(messages.glucoseReport.tableAutomaticReadings)}</th>
              <th>{this.props.intl.formatMessage(messages.glucoseReport.tableManualReadings)}</th>
              <th>{this.props.intl.formatMessage(messages.glucoseReport.tableReadingsPerDay)}</th>
              <th>{this.props.intl.formatMessage(messages.glucoseReport.tableAveragePreMeal)}
                {` (${conversion.unitSymbol})`}
              </th>
              <th>{this.props.intl.formatMessage(messages.glucoseReport.tableAveragePostMeal)}
                {` (${conversion.unitSymbol})`}
              </th>
              <th className={styles.bold}>
                {this.props.intl.formatMessage(messages.glucoseReport.tableStandardDeviation)}
                {` (${conversion.unitSymbol})`}
              </th>
              <th>{this.props.intl.formatMessage(messages.glucoseReport.tableRange)} {`(${conversion.unitSymbol})`}</th>
              <th className={styles.bold}>{this.props.intl.formatMessage(messages.glucoseReport.tableCV)} (%)</th>
            </tr>
            {this.renderRow(30, readings30)}
            {this.renderRow(60, readings60)}
            {this.renderRow(90, readings90)}
          </tbody>
        </table>
      </div>
    );
  }

}

export default withStyles(styles)(injectIntl(Table));
