import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';

import App from 'modules/App';
import { formatDate } from 'helpers/datetime';
import intlShape from 'shapes/intlShape';

import messages from '../../../messages';
import styles from './Header.pcss';
import Legend from './Legend';


class Header extends React.Component {

  static propTypes = {
    // Implicit props
    intl                 : intlShape.isRequired,
    // Explicit props
    phiSet               : PropTypes.object, // @TODO: shape
    accountPatientProfile: PropTypes.object, // @TODO: Shape
    conversion           : PropTypes.object.isRequired,
    startDate            : PropTypes.object,
    endDate              : PropTypes.object,
  };


  render() {
    const { accountPatientProfile, phiSet, conversion, startDate, endDate } = this.props;
    if (!accountPatientProfile || !phiSet) return null;
    return (
      <div>
        <div className={styles.headerDailyLogReport}>
          <div>
            <h1>
              { `${accountPatientProfile.firstName} ${accountPatientProfile.lastName}` }
            </h1>
            <div>
              {
                `${
                  this.props.intl.formatMessage(messages.glucoseReport.diabetesType)
                }: ${
                  App.messages.diabetesTypes[phiSet.diabetesType]
                    ? `${this.props.intl.formatMessage(App.messages.diabetesTypes[phiSet.diabetesType])}`
                    : '-'
                }`
              }
            </div>
            <div>
              {
                `${
                  this.props.intl.formatMessage(messages.glucoseReport.treatmentType)
                }: ${
                  App.messages.treatmentTypes[phiSet.treatmentType]
                    ? `${this.props.intl.formatMessage(App.messages.treatmentTypes[phiSet.treatmentType])}`
                    : '-'
                }`
              }
            </div>
            <p>
              {
                `${this.props.intl.formatMessage(messages.glucoseReport.dateOfBirth)}: 
              ${accountPatientProfile.dateOfBirth ? formatDate(accountPatientProfile.dateOfBirth) : '-'}`
              }
            </p>
            <p>
              { accountPatientProfile.email }
            </p>
          </div>
          <div className={styles.headerDailyLogReport__title}>
            <h1>
              <FormattedMessage {...messages.dailyReport.title} />
            </h1>
            <div className={styles.dailyLogReport__date}>
              { `${formatDate(startDate)} - ${formatDate(endDate)}` }
            </div>
          </div>
          <div>
            <Legend conversion={conversion} />
          </div>
        </div>
      </div>
    );
  }

}


export default withStyles(styles)(injectIntl(Header));
