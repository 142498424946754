import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { useSelector } from 'react-redux';
import { convertFromTimeString } from 'helpers/datetime';
import * as selectors from '../../../selectors';
import messages from '../../../messages';
import ReadingValue from './components/ReadingValue';
import useRows from './useRows';
import styles from './Table.pcss';


const GestationalTable = ({ readings, reportsDates, conversion }) => {
  const gestationalReportSettings = useSelector(selectors.gestationalReportSettingsSelector);
  const dataRows = useRows(gestationalReportSettings, reportsDates, readings);


  const getTime = (index) => convertFromTimeString(gestationalReportSettings[index].value);

  return (
    <table className={styles.table} border="1">
      <thead>
        <tr>
          <th className={cn(styles['table__heading-col'], styles['table__heading-col--left'])}>
            <FormattedMessage {...messages.report.labels.date} />
          </th>
          <th className={styles['table__heading-col']}>
            <p><FormattedMessage {...messages.report.meals.night_am} /></p>
            <p>{ getTime(0) } - { getTime(1) }</p>
          </th>
          <th className={styles['table__heading-col']}>
            <p><FormattedMessage {...messages.report.meals.breakfast} /></p>
            <p>{ getTime(1) } - { getTime(2) }</p>
          </th>
          <th className={styles['table__heading-col']}>
            <p><FormattedMessage {...messages.report.meals.lunch} /></p>
            <p>{ getTime(2) } - { getTime(3) }</p>
          </th>
          <th className={styles['table__heading-col']}>
            <p><FormattedMessage {...messages.report.meals.dinner} /></p>
            <p>{ getTime(3) } - { getTime(4) }</p>
          </th>
          <th className={styles['table__heading-col']}>
            <p><FormattedMessage {...messages.report.meals.night_pm} /></p>
            <p>{ getTime(4) } - { getTime(0) }</p>
          </th>
        </tr>
      </thead>
      <tbody>
        {
          dataRows.map((row) => (
            <tr key={row.id}>
              <td className={styles['table__heading-body-col']}>{ row.id }</td>
              {
                row.values.map((value, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <td className={styles.table__col} key={`col_${index}`}>
                    {
                      value.map((reading) => (
                        <ReadingValue
                          key={reading.readingId}
                          reading={reading}
                          conversion={conversion}
                        />
                      ))
                    }
                  </td>
                ))
              }
            </tr>
          ))
        }
      </tbody>
    </table>
  );
};

GestationalTable.propTypes = {
  readings    : PropTypes.array,
  reportsDates: PropTypes.object,
  conversion  : PropTypes.object.isRequired,
};

export default withStyles(styles)(injectIntl(GestationalTable));
