import React from 'react';
import PropTypes from 'prop-types';
import Widget from 'components/Widget';
import SummaryTable from '../SummaryTable';
import messages from '../../messages';


class SummaryTableWidget extends React.Component {

  static propTypes = {
    // Explicit props
    patientId : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    readings    : PropTypes.array,
    customRanges: PropTypes.array,
    isInProgress: PropTypes.bool,
  };

  render() {
    return (
      <Widget
        headerMessage={messages.headers.resultsSummary}
        isInProgress={this.props.isInProgress}
      >
        <SummaryTable
          {...this.props}
        />
      </Widget>
    );
  }

}


export default SummaryTableWidget;
