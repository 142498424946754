import PropTypes from 'prop-types';
import values from 'lodash/values';
import * as constants from './constants';


export const visitMetadata = PropTypes.shape({
  visitMetadataId: PropTypes.string.isRequired,
  clinicId       : PropTypes.number.isRequired,
  sharingLinkId  : PropTypes.number,
  visitDate      : PropTypes.string,
  visitType      : PropTypes.oneOf(values(constants.VISIT_TYPES)).isRequired,
  createdBy      : PropTypes.shape({
    hcpProfileId: PropTypes.number.isRequired,
    firstName   : PropTypes.string.isRequired,
    lastName    : PropTypes.string.isRequired,
    avatar      : PropTypes.string,
  }).isRequired,
});

export const visit = PropTypes.shape({
  phisetVisitId           : PropTypes.string.isRequired,
  encryptedVisitMetadataId: PropTypes.string.isRequired,
  associatedDataIndexKeys : PropTypes.shape({
    measurements: PropTypes.arrayOf(PropTypes.string),
    notes       : PropTypes.arrayOf(PropTypes.string),
    readings    : PropTypes.arrayOf(PropTypes.string),
  }),
  metadata: visitMetadata,
});
