import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { useAction } from 'hooks';
import Account from 'modules/Account';
import * as actions from '../../actions';
import { CONNECTION_STATUSES, CONNECTOR_TYPES } from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from './DownloadDataModal.pcss';


interface Props {
  onSetComponent: (componentName: string) => void,
}


const Waiting: FC<Props> = ({ onSetComponent }) => {
  const connectionId = useSelector(selectors.connectionId);
  const connectionStatus = useSelector(selectors.connectionStatus);
  const activeClinicMembership: ClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const organizationUID = get(activeClinicMembership, 'clinic.organizationUID');

  const onConnect = useAction(actions.connectScc, organizationUID);
  const onStartCheckingConnection = useAction(actions.startCheckingConnection);

  useEffect(() => {
    onConnect();
  }, []);

  useEffect(() => {
    if (connectionStatus === CONNECTION_STATUSES.INITIALIZED) {
      onStartCheckingConnection(connectionId, CONNECTOR_TYPES.SCC);
    } else if (connectionStatus) {
      onSetComponent('Downloading');
    }
  }, [connectionId, connectionStatus]);

  return (
    <div className={styles.modal__content}>
      <h3 className="modal__subheader "><FormattedMessage {...messages.sccDownload.header} /></h3>
      <div className={styles.illustration}>
        <img className={styles.centralImage} src="/assets/svg/data.svg" alt="" />
      </div>
      <p className="modal__info text--center mb-5"><FormattedMessage {...messages.sccDownload.waitingInfo} /></p>
    </div>
  );
};

export default Waiting;
