import { MODULE_ID } from './constants';


export const MANAGE_ORGANIZATION = `${MODULE_ID}/MANAGE_ORGANIZATION`;

export const FETCH_CLINIC_CUSTOM_IDENTIFIERS = `${MODULE_ID}/FETCH_CLINIC_CUSTOM_IDENTIFIERS`;
export const FETCH_CLINIC_CUSTOM_IDENTIFIERS_SUCCESS = `${MODULE_ID}/FETCH_CLINIC_CUSTOM_IDENTIFIERS_SUCCESS`;
export const FETCH_CLINIC_CUSTOM_IDENTIFIERS_ERROR = `${MODULE_ID}/FETCH_CLINIC_CUSTOM_IDENTIFIERS_ERROR`;

export const STORE_CLINIC = `${MODULE_ID}/STORE_CLINIC`;
export const STORE_CLINIC_SUCCESS = `${MODULE_ID}/STORE_CLINIC_SUCCESS`;
export const STORE_CLINIC_ERROR = `${MODULE_ID}/STORE_CLINIC_ERROR`;

export const GET_STORED_CLINIC = `${MODULE_ID}/GET_STORED_CLINIC`;
export const GET_STORED_CLINIC_SUCCESS = `${MODULE_ID}/GET_STORED_CLINIC_SUCCESS`;
export const GET_STORED_CLINIC_ERROR = `${MODULE_ID}/GET_STORED_CLINIC_ERROR`;

export const SET_CLINIC_CLOUD_DRIVE = `${MODULE_ID}/SET_CLINIC_CLOUD_DRIVE`;
export const SET_CLINIC_CLOUD_DRIVE_SUCCESS = `${MODULE_ID}/SET_CLINIC_CLOUD_DRIVE_SUCCESS`;
export const SET_CLINIC_CLOUD_DRIVE_ERROR = `${MODULE_ID}/SET_CLINIC_CLOUD_DRIVE_ERROR`;

export const UPDATE_CLINIC_GLUCOSE_SETTINGS = `${MODULE_ID}/UPDATE_CLINIC_GLUCOSE_SETTINGS`;
export const UPDATE_CLINIC_GLUCOSE_SETTINGS_SUCCESS = `${MODULE_ID}/UPDATE_CLINIC_GLUCOSE_SETTINGS_SUCCESS`;
export const UPDATE_CLINIC_GLUCOSE_SETTINGS_ERROR = `${MODULE_ID}/UPDATE_CLINIC_GLUCOSE_SETTINGS_ERROR`;

export const UPDATE_CLINIC_KPI_SETTINGS = `${MODULE_ID}/UPDATE_CLINIC_KPI_SETTINGS`;
export const UPDATE_CLINIC_KPI_SETTINGS_SUCCESS = `${MODULE_ID}/UPDATE_CLINIC_KPI_SETTINGS_SUCCESS`;
export const UPDATE_CLINIC_KPI_SETTINGS_ERROR = `${MODULE_ID}/UPDATE_CLINIC_KPI_SETTINGS_ERROR`;

export const REAUTHORIZE_CLINIC_CLOUD_DRIVE = `${MODULE_ID}/REAUTHORIZE_CLINIC_CLOUD_DRIVE`;
export const REAUTHORIZE_CLINIC_CLOUD_DRIVE_SUCCESS = `${MODULE_ID}/REAUTHORIZE_CLINIC_CLOUD_DRIVE_SUCCESS`;
export const REAUTHORIZE_CLINIC_CLOUD_DRIVE_ERROR = `${MODULE_ID}/REAUTHORIZE_CLINIC_CLOUD_DRIVE_ERROR`;

export const FETCH_ORGANIZATION_MEMBERSHIPS = `${MODULE_ID}/FETCH_ORGANIZATION_MEMBERSHIPS`;
export const FETCH_ORGANIZATION_MEMBERSHIPS_SUCCESS = `${MODULE_ID}/FETCH_ORGANIZATION_MEMBERSHIPS_SUCCESS`;
export const FETCH_ORGANIZATION_MEMBERSHIPS_ERROR = `${MODULE_ID}/FETCH_ORGANIZATION_MEMBERSHIPS_ERROR`;

export const CHECK_PATIENTS_WITHOUT_LEADING_HCP = `${MODULE_ID}/CHECK_PATIENTS_WITHOUT_LEADING_HCP`;
export const CHECK_PENDING_ORGANIZATION_MEMBERSHIPS = `${MODULE_ID}/CHECK_PENDING_ORGANIZATION_MEMBERSHIPS`;

export const FETCH_CLINIC_MEMBERSHIPS = `${MODULE_ID}/FETCH_CLINIC_MEMBERSHIPS`;
export const FETCH_CLINIC_MEMBERSHIPS_SUCCESS = `${MODULE_ID}/FETCH_CLINIC_MEMBERSHIPS_SUCCESS`;
export const FETCH_CLINIC_MEMBERSHIPS_ERROR = `${MODULE_ID}/FETCH_CLINIC_MEMBERSHIPS_ERROR`;

export const SET_PATIENTS_COUNT = `${MODULE_ID}/SET_PATIENTS_COUNT`;

export const REMOVE_CLINIC_DATA = `${MODULE_ID}/REMOVE_CLINIC_DATA`;
export const REMOVE_CLINIC_PATIENT_DATA_SUCCESS = `${MODULE_ID}/REMOVE_CLINIC_PATIENT_DATA_SUCCESS`;
export const REMOVE_CLINIC_DATA_PAUSE = `${MODULE_ID}/REMOVE_CLINIC_DATA_PAUSE`;
export const REMOVE_CLINIC_DATA_SUCCESS = `${MODULE_ID}/REMOVE_CLINIC_DATA_SUCCESS`;
export const REMOVE_CLINIC_DATA_ERROR = `${MODULE_ID}/REMOVE_CLINIC_DATA_ERROR`;

export const IMPORT_PATIENT_DATABASE = `${MODULE_ID}/IMPORT_PATIENT_DATABASE`;
export const IMPORT_PATIENT_DATABASE_PATIENT_STORED = `${MODULE_ID}/IMPORT_PATIENT_DATABASE_PATIENT_STORED`;
export const IMPORT_PATIENT_DATABASE_CANCEL = `${MODULE_ID}/IMPORT_PATIENT_DATABASE_CANCEL`;
export const IMPORT_PATIENT_DATABASE_END = `${MODULE_ID}/IMPORT_PATIENT_DATABASE_END`;
export const IMPORT_PATIENT_DATABASE_SUCCESS = `${MODULE_ID}/IMPORT_PATIENT_DATABASE_SUCCESS`;
export const IMPORT_PATIENT_DATABASE_ERROR = `${MODULE_ID}/IMPORT_PATIENT_DATABASE_ERROR`;

export const SET_CLINIC_LICENCE = `${MODULE_ID}/SET_CLINIC_LICENCE`;
export const SET_CLINIC_LICENCE_SUCCESS = `${MODULE_ID}/SET_CLINIC_LICENCE_SUCCESS`;
export const SET_CLINIC_LICENCE_ERROR = `${MODULE_ID}/SET_CLINIC_LICENCE_ERROR`;

export const FETCH_CLINIC_COUNTRY_SETTINGS = `${MODULE_ID}/FETCH_CLINIC_COUNTRY_SETTINGS`;
export const FETCH_CLINIC_COUNTRY_SETTINGS_SUCCESS = `${MODULE_ID}/FETCH_CLINIC_COUNTRY_SETTINGS_SUCCESS`;
export const FETCH_CLINIC_COUNTRY_SETTINGS_ERROR = `${MODULE_ID}/FETCH_CLINIC_COUNTRY_SETTINGS_ERROR`;

export const FETCH_CLINIC_SETTINGS = `${MODULE_ID}/FETCH_CLINIC_SETTINGS`;
export const FETCH_CLINIC_SETTINGS_SUCCESS = `${MODULE_ID}/FETCH_CLINIC_SETTINGS_SUCCESS`;
export const FETCH_CLINIC_SETTINGS_ERROR = `${MODULE_ID}/FETCH_CLINIC_SETTINGS_ERROR`;
