import { createSelector } from 'reselect';
import includes from 'lodash/includes';
import take from 'lodash/take';
import reject from 'lodash/reject';
import findIndex from 'lodash/findIndex';
import Account from 'modules/Account';
import * as constants from './constants';
import * as actionTypes from './actionTypes';


/**
 * Input selectors
 */
const mainSelector = (state) => state[constants.MODULE_ID];
const fetchingSelector = (state) => state[constants.MODULE_ID].fetching;
const errorsSelector = (state) => state[constants.MODULE_ID].errors;


/**
 * List of newest notifications
 */
export const newestNotifications = createSelector(
  mainSelector,
  Account.selectors.notificationsSettings,
  (state, notifications) => take(
    reject(state.notifications, (notification) => {
      const settingsKey = notification.notificationTrigger[0].toLowerCase()
          + notification.notificationTrigger.slice(1);
      return (
        notification.status === 'Dismissed'
          || includes(constants.HIDDEN_NOTIFICATIONS, notification.notificationTrigger)
          || (notifications && notifications[settingsKey] && !notifications[settingsKey].push)
      );
    }),
    constants.NOTIFICATIONS_LIST_LENGTH + 1
  ));


export const lastReceivedNotification = createSelector(
  mainSelector,
  (state) => state.lastReceivedNotification,
);


export const isActiveInNewestNotifications = createSelector(
  mainSelector,
  newestNotifications,
  (state, newestNotificationsState) => findIndex(
    take(newestNotificationsState, constants.NOTIFICATIONS_LIST_LENGTH),
    { status: 'Active' },
  ) >= 0,
);


export const activeInNewestNotificationsCount = createSelector(
  mainSelector,
  Account.selectors.notificationsSettings,
  (state, notifications) => reject(state.notifications, (notification) => {
    const settingsKey = notification.notificationTrigger[0].toLowerCase()
        + notification.notificationTrigger.slice(1);
    return (
      notification.status === 'Dismissed'
        || includes(constants.HIDDEN_NOTIFICATIONS, notification.notificationTrigger)
        || (notifications && notifications[settingsKey] && !notifications[settingsKey].push)
    );
  }).length,
);


/**
 * List of notifications ids user is reading now. Only after reading it will be committed to notifications statuses.
 */
export const readNotificationsIds = createSelector(
  mainSelector,
  (state) => state.readNotificationsIds,
);


export const notificationsHistory = createSelector(
  mainSelector,
  (state) => state.notificationsHistory,
);


//----------------------------------------------------------------------------------------------------------------------

export const isFetchNotificationsInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_LIST),
);

export const hasFetchNotificationsErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.FETCH_LIST),
);

//----------------------------------------------------------------------------------------------------------------------

export const isFetchNotificationsHistoryInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_NOTIFICATIONS_HISTORY),
);

export const hasFetchNotificationsHistoryErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.FETCH_NOTIFICATIONS_HISTORY),
);
