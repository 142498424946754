import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';
import reducer from './reducer';
import sagas from './sagas';
import messages from './messages';

import ProfileChart from './components/ProfileChart';
import AgpProfileChart from './components/AgpProfileChart';
import Statistics from './components/Statistics';
import BgmStatistics from './components/BgmStatistics';
import DailyGlucoseProfile from './partials/DailyGlucoseProfile';


const components = {
  ProfileChart,
  Statistics,
  BgmStatistics,
  AgpProfileChart,
  DailyGlucoseProfile,
};


export default {
  actions,
  components,
  constants,
  reducer,
  sagas,
  selectors,
  messages,
};
