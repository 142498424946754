import { defineMessages } from 'react-intl';


export default defineMessages({

  headers: {
    measurementsTable        : { id: 'ambulatoryGlucoseProfile.headers.measurementsTable' },
    glucoseStatisticAndTarget: { id: 'patientResults.headers.glucoseStatisticAndTarget' },
    timeInRanges             : { id: 'patientResults.headers.timeInRanges' },
    ambulatoryGlucoseProfile : { id: 'patientResults.headers.ambulatoryGlucoseProfile' },
    dailyGlucoseProfiles     : { id: 'ambulatoryGlucoseProfile.headers.dailyGlucoseProfiles' },
    bgmStatistics            : { id: 'ambulatoryGlucoseProfile.headers.bgmStatistics' },
    bgmValuesInRanges        : { id: 'ambulatoryGlucoseProfile.headers.bgmValuesInRanges' },
    bgmTargets               : { id: 'ambulatoryGlucoseProfile.headers.bgmTargets' },
    bgmModalDay              : { id: 'ambulatoryGlucoseProfile.headers.bgmModalDay' },
    bgmDgp                   : { id: 'ambulatoryGlucoseProfile.headers.bgmDgp' },
  },

  labels: {
    unit                             : { id: 'ambulatoryGlucoseProfile.labels.unit' },
    zoomDaily                        : { id: 'ambulatoryGlucoseProfile.labels.zoomDaily' },
    zoomWeekly                       : { id: 'ambulatoryGlucoseProfile.labels.zoomWeekly' },
    zoomMonthly                      : { id: 'ambulatoryGlucoseProfile.labels.zoomMonthly' },
    week                             : { id: 'ambulatoryGlucoseProfile.labels.week' },
    days                             : { id: 'ambulatoryGlucoseProfile.labels.days' },
    h                                : { id: 'ambulatoryGlucoseProfile.labels.h' },
    min                              : { id: 'ambulatoryGlucoseProfile.labels.min' },
    timeCgmActive                    : { id: 'ambulatoryGlucoseProfile.labels.timeCgmActive' },
    glucoseRanges                    : { id: 'ambulatoryGlucoseProfile.labels.glucoseRanges' },
    targets                          : { id: 'ambulatoryGlucoseProfile.labels.targets' },
    percentOfReadings                : { id: 'ambulatoryGlucoseProfile.labels.percentOfReadings' },
    targetRange                      : { id: 'ambulatoryGlucoseProfile.labels.targetRange' },
    below                            : { id: 'ambulatoryGlucoseProfile.labels.below' },
    above                            : { id: 'ambulatoryGlucoseProfile.labels.above' },
    increaseCaption                  : { id: 'ambulatoryGlucoseProfile.labels.increaseCaption' },
    averageGlucose                   : { id: 'ambulatoryGlucoseProfile.labels.averageGlucose' },
    glucoseManagementIndicator       : { id: 'ambulatoryGlucoseProfile.labels.glucoseManagementIndicator' },
    glucoseVariability               : { id: 'ambulatoryGlucoseProfile.labels.glucoseVariability' },
    definedAsPercentCaption          : { id: 'ambulatoryGlucoseProfile.labels.definedAsPercentCaption' },
    insufficiencyInfo                : { id: 'ambulatoryGlucoseProfile.labels.insufficiencyInfo' },
    agpChartInfo                     : { id: 'ambulatoryGlucoseProfile.labels.agpChartInfo' },
    dailyGlucoseProfilesAnnotation   : { id: 'ambulatoryGlucoseProfile.labels.dailyGlucoseProfilesAnnotation' },
    name                             : { id: 'ambulatoryGlucoseProfile.labels.name' },
    mrn                              : { id: 'ambulatoryGlucoseProfile.labels.mrn' },
    report                           : { id: 'ambulatoryGlucoseProfile.labels.report' },
    median                           : { id: 'ambulatoryGlucoseProfile.labels.median' },
    iqr                              : { id: 'ambulatoryGlucoseProfile.labels.iqr' },
    p10p90                           : { id: 'ambulatoryGlucoseProfile.labels.p10p90' },
    agpInsufficientData              : { id: 'ambulatoryGlucoseProfile.labels.agpInsufficientData' },
    readingsCount                    : { id: 'ambulatoryGlucoseProfile.labels.readingsCount' },
    readingsPerDay                   : { id: 'ambulatoryGlucoseProfile.labels.readingsPerDay' },
    averagePreMeal                   : { id: 'ambulatoryGlucoseProfile.labels.averagePreMeal' },
    averagePostMeal                  : { id: 'ambulatoryGlucoseProfile.labels.averagePostMeal' },
    lowestHighestReading             : { id: 'ambulatoryGlucoseProfile.labels.lowestHighestReading' },
    CV                               : { id: 'ambulatoryGlucoseProfile.labels.CV' },
    preMealTarget                    : { id: 'ambulatoryGlucoseProfile.labels.preMealTarget' },
    postMealTarget                   : { id: 'ambulatoryGlucoseProfile.labels.postMealTarget' },
    lessThan                         : { id: 'ambulatoryGlucoseProfile.labels.lessThan' },
    unableToCalculate                : { id: 'ambulatoryGlucoseProfile.labels.unableToCalculate' },
    agpBgmChartInfo                  : { id: 'ambulatoryGlucoseProfile.labels.agpBgmChartInfo' },
    dailyBgmGlucoseProfilesAnnotation: { id: 'ambulatoryGlucoseProfile.labels.dailyBgmGlucoseProfilesAnnotation' },
    checks                           : { id: 'ambulatoryGlucoseProfile.labels.checks' },
  },

});
