import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Form/Button';
import App from 'modules/App';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import * as actions from '../../../actions';
import * as constants from '../../../constants';
import * as selectors from '../../../selectors';
import messages from '../../../messages';
import ImportPatientDatabaseModal from '../../ImportPatientDatabaseModal';


class DataSettings extends React.PureComponent {

  static propTypes = {
    // Explicit props
    activeClinicMembership       : Account.shapes.clinicMembership.isRequired,
    // Implicit props
    authorizationCode            : CloudDrive.shapes.authorizationCode,
    isInProgress                 : PropTypes.bool,
    hasErrors                    : PropTypes.bool,
    // Implicit actions
    onConnect                    : PropTypes.func,
    onAuthorizeCloudDrive        : PropTypes.func,
    onReauthorizeClinicCloudDrive: PropTypes.func,
    onOpenModal                  : PropTypes.func,
  };


  componentDidMount() {
    const { activeClinicMembership, authorizationCode } = this.props;
    if (authorizationCode) {
      this.props.onReauthorizeClinicCloudDrive(authorizationCode, activeClinicMembership);
    } else {
      this.props.onConnect(activeClinicMembership);
    }
  }


  componentDidUpdate(prevProps) {
    const { activeClinicMembership, isInProgress, hasErrors } = this.props;
    if (prevProps.isInProgress && !isInProgress && !hasErrors) {
      this.props.onConnect(activeClinicMembership);
    }
  }


  onAuthorizeCloudDrive() {
    const { clinic } = this.props.activeClinicMembership;
    this.props.onAuthorizeCloudDrive(clinic);
  }


  renderReauthorizeCloudDrive() {
    return (
      <Button
        styleModifier="primary"
        className="btn--filled mr-6 mt-6"
        labelMessage={messages.buttons.reauthorizeCloudDrive}
        onClick={() => this.onAuthorizeCloudDrive()}
        isInProgress={this.props.isInProgress}
      />
    );
  }


  renderCloudDrive() {
    const storageProvider = get(this.props.activeClinicMembership, 'clinic.storageProvider');
    if (!storageProvider) {
      return null;
    }
    const storage = <b>{ get(App.constants.CLOUD_DRIVES_LABELS, storageProvider, storageProvider) }</b>;
    return (
      <section>
        <h2 className="section__header"><FormattedMessage {...messages.headers.cloudDrive} /></h2>
        <p className="text--large">
          <FormattedMessage
            {...messages.infos.dataStored}
            values={{ storage }}
          />
        </p>
        {this.renderReauthorizeCloudDrive()}
      </section>
    );
  }


  renderImportPatientDatabaseSection() {
    const isAdmin = get(this.props.activeClinicMembership, 'isAdmin', false);
    if (!isAdmin) {
      return null;
    }
    return (
      <section>
        <h2 className="section__header"><FormattedMessage {...messages.headers.importPatientDatabase} /></h2>
        <p className="section__intro"><FormattedMessage {...messages.intros.importPatientDatabase} /></p>
        <Button
          styleModifier="primary"
          labelMessage={messages.buttons.import}
          className="btn--filled"
          onClick={() => this.props.onOpenModal(constants.IMPORT_PATIENT_DATABASE_MODAL)}
        />
      </section>
    );
  }


  render() {
    return (
      <div className="row">
        <div className="col-6 pr-10">
          { this.renderCloudDrive() }
        </div>
        <div className="col-6 pl-10">
          { this.renderImportPatientDatabaseSection() }
        </div>
        <ImportPatientDatabaseModal clinicMembership={this.props.activeClinicMembership} />
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  isInProgress     : selectors.isReauthorizeClinicCloudDriveInProgress(state),
  hasErrors        : selectors.hasReauthorizeClinicCloudDriveError(state),
  authorizationCode: CloudDrive.selectors.authorizationCode(state),
});

const mapDispatchToProps = (dispatch) => ({
  onConnect: (clinicMembership) => {
    dispatch(Account.actions.disconnectClinic());
    if (clinicMembership.membershipStatus === 'Active') {
      dispatch(Account.actions.connectToClinic(clinicMembership));
    }
  },
  onAuthorizeCloudDrive: (clinic) => dispatch(CloudDrive.actions.authorize(
    clinic.storageProvider,
    `clinicReAuth-${clinic.organizationUID}-${clinic.name}`,
  )),
  onReauthorizeClinicCloudDrive: (authorizationCode, clinicMembership) => dispatch(
    actions.reauthorizeClinicCloudDrive(authorizationCode, clinicMembership)
  ),
  onOpenModal: (modalId) => dispatch(App.actions.openModal(modalId)),
});

const ConnectedDataSettings = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataSettings);

export default ConnectedDataSettings;
