import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { isNotDisconnectableDataSource } from 'helpers/externalDataSources';
import Button from 'components/Form/Button';
import * as selectors from '../../../selectors';
import messages from '../../../messages';
import styles from '../Settings.pcss';


interface Props {
  externalDataSource: ExternalDataSource,
}


const ConnectionStatus: FC<Props> = ({ externalDataSource }) => {
  const { externalDataSourceId } = externalDataSource;
  const connectedDataSources = useSelector(selectors.connectedDataSources);
  if (!connectedDataSources) {
    return null;
  }

  const connectedDataSource = connectedDataSources.find((cds) => cds.externalDataSourceId === externalDataSourceId);

  if (connectedDataSource) {
    const isNotDisconnectable = isNotDisconnectableDataSource(externalDataSource);
    return (
      <div className={styles.dataSource__status}>
        <div className="tag tag--success"><FormattedMessage {...messages.tags.connected} /></div>
        <Button
          isDisabled={isNotDisconnectable}
          styleModifier="quaternary"
          labelMessage={messages.buttons.disconnect}
          className={cn('btn--sm px-4 mx-3', { 'text--primary': !isNotDisconnectable })}
          // isInProgress={isDisconnectInProgress}
          // onClick={() => onDisconnect(connectedDataSource.accountExternalDataSourceId)}
        />
      </div>
    );
  }

  return (
    <div className={styles.dataSource__status}>
      <div className="tag tag--error"><FormattedMessage {...messages.tags.notConnected} /></div>
      <Button
        styleModifier="quaternary"
        labelMessage={messages.buttons.connect}
        className="btn--sm px-4 mx-3 text--primary"
        // isInProgress={isConnectInProgress}
        // onClick={() => onAuthorize(dataSourceProvider)}
      />
    </div>
  );
};

export default ConnectionStatus;
