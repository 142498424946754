// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PYaEL{position:relative}.PYaEL>:first-child{opacity:1}.PYaEL>:nth-child(2){opacity:.5}.PYaEL>:nth-child(3){opacity:.3}.PYaEL>:nth-child(4){opacity:.2}.PYaEL>:nth-child(n+5){opacity:.1}.c6KoK{position:relative}._1VneR{opacity:1!important}", ""]);
// Exports
exports.locals = {
	"stack": "PYaEL",
	"stack__element": "c6KoK",
	"stack__element--isActive": "_1VneR"
};
module.exports = exports;
