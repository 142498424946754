import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useStyles from 'isomorphic-style-loader/useStyles';
import cn from 'classnames';
import includes from 'lodash/includes';
import { useBrowser } from 'hooks/useBrowser';
import ArrowDown from 'svg/arrow-down.svg';
import X from 'svg/x.svg';
import messages from '../../messages';
import styles from './LaunchDownloaderInstaller.pcss';


interface FoldBtnProps {
  isFolded: boolean,
  onFold: (evt) => void,
}

interface FoldableContentProps {
  browserModifier: string,
  isFolded: boolean,
}


const getBrowserModifier = (browser: string): string => {
  if (includes(['Firefox', 'Safari', 'Chrome'], browser)) {
    return browser;
  }
  return 'Other';
};


const FoldBtn: FC<FoldBtnProps> = ({ isFolded, onFold }) => {
  if (isFolded) {
    return null;
  }

  return (
    <div className={`modal__close ${styles.downloaderLauncher__close}`}>
      { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
      <button
        type="button"
        className="modal__close__btn"
        onClick={onFold}
      >
        <span className="btn-inner">
          <X className="modal__close__btn__icon" />
        </span>
      </button>
    </div>
  );
};


const FoldableContent: FC<FoldableContentProps> = ({ browserModifier, isFolded }) => {
  if (isFolded) {
    return null;
  }

  const clickPosition = browserModifier === 'Other' ? 'Below' : 'Above';

  return (
    <div className="col">
      <div className={styles.downloaderLauncher__content}>
        <h3 className="text--h2 mb-3">
          <FormattedMessage {...messages.headers[`click${clickPosition}ToInstall`]} values={{ clickPosition }} />
        </h3>
        <p className={`${styles.downloaderLauncher__text} text--large text--light`}>
          <FormattedMessage {...messages.infos[`waitAndClick${clickPosition}ToInstall`]} />
        </p>
      </div>
    </div>
  );
};


const LaunchDownloaderInstaller: FC = () => {
  const browser = useBrowser();
  if (browser === 'Server' || browser === 'EdgeChromium') {
    return null;
  }

  const browserModifier = getBrowserModifier(browser);

  const [isFolded, setIsFolded] = useState<boolean>(false);

  useStyles(styles);

  const onFold = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setIsFolded(true);
  };

  return (
    <div
      className={
        cn(
          styles.downloaderLauncher,
          styles[`downloaderLauncher--${browserModifier}`],
          { [styles['downloaderLauncher--folded']]: isFolded },
          'row no-gutters align-items-center',
        )
      }
    >
      <div className={`${styles.downloaderLauncher__indicatorWrapper} col-auto`}>
        <div className={styles.downloaderLauncher__indicator}>
          <ArrowDown
            className={
              cn(
                styles.downloaderLauncher__indicator__icon,
              )
            }
          />
        </div>
      </div>
      <FoldableContent {...{ browserModifier, isFolded }} />
      <FoldBtn {...{ isFolded, onFold }} />
    </div>
  );
};

export default LaunchDownloaderInstaller;
