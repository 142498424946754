import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import isObjectLike from 'lodash/isObjectLike';
import intlShape from 'shapes/intlShape';


/**
 * Textarea component extends simple textarea
 *
 * @param {string} id - unique Textarea id
 * @param {string} [value=""] - textarea value
 * @param {(string|Object)} [className] - additional input className
 * @param {function} onChange - action that is triggered by textarea value change
 * @param {function} [onBlur] - action that is triggered when textarea has been lost focus
 */
class TextArea extends React.PureComponent {

  static propTypes = {
    // Explicit props
    id       : PropTypes.string,
    value    : PropTypes.string,
    className: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    classNameWrapper: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    placeholder: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    attributes: PropTypes.object,
    // Implicit props
    intl      : intlShape.isRequired,
    // Explicit Actions
    onChange  : PropTypes.func.isRequired,
    onBlur    : PropTypes.func,
  };


  static defaultProps = {
    value     : '',
    className : '',
    attributes: {},
    onBlur    : () => {},
  };


  constructor(props) {
    super(props);
    this.state = {};
  }


  renderPlaceholder() {
    if (!this.props.placeholder) {
      return null;
    }
    return isObjectLike(this.props.placeholder)
      ? this.props.intl.formatMessage(this.props.placeholder, { ...this.props.placeholder.values })
      : this.props.placeholder;
  }


  render() {
    return (
      <div className={cn('input-container', this.props.classNameWrapper)}>
        <textarea
          id={this.props.id}
          value={this.props.value}
          className={cn('form-control', 'form-control--textarea', this.props.className)}
          {...this.props.attributes}
          placeholder={this.renderPlaceholder()}
          onChange={(event) => this.props.onChange({
            id   : this.props.id,
            value: event.target.value,
          })}
          onBlur={(event) => this.props.onBlur({
            id   : this.props.id,
            value: event.target.value,
          })}
        />
      </div>
    );
  }

}


export default injectIntl(TextArea);
