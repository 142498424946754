import get from 'lodash/get';
import { unfoldObject } from 'helpers/transformers';


export const validateForm = (formValues, validatorRules, isToUnfold, Validator) => {
  const values = get(formValues, 'values', {});
  const { validatedValues, errors } = Validator.run(values, validatorRules);
  return {
    validatedValues: isToUnfold ? unfoldObject(validatedValues) : validatedValues,
    errors,
  };
};
