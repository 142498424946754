import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import App from 'modules/App';
import styles from './TopBar.pcss';


const TopBar: FC = ({ children }) => {
  const isPrintMode = useSelector(App.selectors.printMode);
  useStyles(styles);

  return (
    <div className={
      cn(styles.topBarHolder, {
        [styles.hide]: isPrintMode,
      })
    }
    >
      <div className={styles.topBar}>
        { children }
      </div>
    </div>
  );
};

export default TopBar;
