import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';

import App from 'modules/App';
import { formatDate } from 'helpers/datetime';
import intlShape from 'shapes/intlShape';

import * as constants from '../../../constants';
import messages from '../../../messages';
import styles from './Header.pcss';


class Header extends React.Component {

  static propTypes = {
    // Implicit props
    intl                 : intlShape.isRequired,
    // Explicit props
    phiSet               : PropTypes.object, // @TODO: shape
    accountPatientProfile: PropTypes.object, // @TODO: Shape
    standards            : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    conversion: PropTypes.object,
  };

  render() {
    const { accountPatientProfile, phiSet, conversion } = this.props;
    if (!accountPatientProfile || !phiSet) return null;
    return (
      <div>
        <div className={styles.headerDailyLogReport}>
          <div>
            <h1>
              {`${accountPatientProfile.firstName} ${accountPatientProfile.lastName}`}
            </h1>
            <div>
              {
                `${this.props.intl.formatMessage(messages.glucoseReport.diabetesType)}:
                  ${App.messages.diabetesTypes[phiSet.diabetesType]
                  ? `${this.props.intl.formatMessage(App.messages.diabetesTypes[phiSet.diabetesType])}`
                  : '-'}`
              }
            </div>
            <div>
              {
                `${this.props.intl.formatMessage(messages.glucoseReport.treatmentType)}:
                  ${App.messages.treatmentTypes[phiSet.treatmentType]
                  ? `${this.props.intl.formatMessage(App.messages.treatmentTypes[phiSet.treatmentType])}`
                  : '-'}`
              }
            </div>
            <p>
              {`${this.props.intl.formatMessage(messages.glucoseReport.dateOfBirth)}:
              ${accountPatientProfile.dateOfBirth ? formatDate(accountPatientProfile.dateOfBirth) : '-'}`}
            </p>
            <p>
              {accountPatientProfile.email}
            </p>
            <div className={styles.headerLine} />
            <p className={styles.headerReportGenerated}>
              {`${this.props.intl.formatMessage(messages.glucoseReport.generatedReport)}: ${formatDate(new Date())}`}
            </p>
          </div>
          <div className={styles.headerDailyLogReport__title}>
            <h1>
              <FormattedMessage {...messages.glucoseReport.title} />
            </h1>
          </div>
          <div>
            <p>
              {`${this.props.intl.formatMessage(messages.glucoseReport.criticalHigh)}: > ${constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].veryHigh} ${conversion.unitSymbol}`}
            </p>
            <p>
              {`${this.props.intl.formatMessage(messages.glucoseReport.criticalLow)}: < ${constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].low} ${conversion.unitSymbol}`}
            </p>
          </div>
        </div>
      </div>
    );
  }

}


export default withStyles(styles)(injectIntl(Header));
