import reject from 'lodash/reject';
import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import { registerAction, unregisterAction } from 'helpers/reducerTools';
import { SIGN_OUT } from 'modules/Account/actionTypes';
import * as appActionTypes from 'modules/App/actionTypes';
import * as actionTypes from './actionTypes';


const initialState = {
  notifications           : [],
  notificationsHistory    : [],
  lastReceivedNotification: null,
  fetching                : [],
  sending                 : [],
  errors                  : [],
};


export default function reducer(state = { ...initialState }, action) {

  switch (action.type) {

    case actionTypes.FETCH_LIST: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_LIST),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_LIST),
      };
    }
    case actionTypes.FETCH_LIST_SUCCESS: {
      const { notifications } = action.payload;
      return {
        ...state,
        notifications,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_LIST),
      };
    }
    case actionTypes.FETCH_LIST_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_LIST),
        errors  : registerAction(state.errors, actionTypes.FETCH_LIST),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case appActionTypes.NEW_INSTANT_NOTIFICATION: {

      const notification = action.payload;
      return {
        ...state,
        notifications           : [notification, ...state.notifications],
        lastReceivedNotification: notification,
        fetching                : unregisterAction(state.fetching, actionTypes.FETCH_LIST),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.DISMISS_NOTIFICATION: {
      const { notification } = action.payload;
      const { instantNotificationId } = notification;
      const { notifications } = state;
      const idx = findIndex(notifications, { instantNotificationId });
      notifications[idx] = { ...notification, status: 'Dismissed' };
      return {
        ...state,
        notifications,
        sending: registerAction(state.fetching, `${actionTypes.DISMISS_NOTIFICATION}-${instantNotificationId}`),
        errors : unregisterAction(state.errors, `${actionTypes.DISMISS_NOTIFICATION}-${instantNotificationId}`),
      };
    }
    case actionTypes.DISMISS_NOTIFICATION_SUCCESS: {
      const { notification } = action.payload;
      const { instantNotificationId } = notification;
      const notifications = reject(state.notifications, { instantNotificationId });
      return {
        ...state,
        notifications,
        sending: unregisterAction(state.fetching, `${actionTypes.DISMISS_NOTIFICATION}-${instantNotificationId}`),
      };
    }
    case actionTypes.DISMISS_NOTIFICATION_ERROR: {
      const { notification } = action.payload;
      const { instantNotificationId } = notification;
      const { notifications } = state;
      const idx = findIndex(notifications, { instantNotificationId });
      notifications[idx] = { ...notification, status: 'Error' };
      return {
        ...state,
        notifications,
        sending: unregisterAction(state.fetching, `${actionTypes.DISMISS_NOTIFICATION}-${instantNotificationId}`),
        errors : registerAction(state.errors, `${actionTypes.DISMISS_NOTIFICATION}-${instantNotificationId}`),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.MARK_NOTIFICATIONS_READ: {
      const { notificationsIds } = action.payload;
      const { notifications } = state;
      forEach(notificationsIds, (instantNotificationId) => {
        const idx = findIndex(notifications, { instantNotificationId });
        if (idx >= 0) {
          notifications[idx] = { ...notifications[idx], status: 'Read' };
        }
      });
      return {
        ...state,
        notifications,
        sending: registerAction(state.fetching, actionTypes.MARK_NOTIFICATIONS_READ),
        errors : unregisterAction(state.errors, actionTypes.MARK_NOTIFICATIONS_READ),
      };
    }
    case actionTypes.MARK_NOTIFICATIONS_READ_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.fetching, actionTypes.MARK_NOTIFICATIONS_READ),
      };
    }
    case actionTypes.MARK_NOTIFICATIONS_READ_ERROR: {
      const { notificationsIds } = action.payload;
      const { notifications } = state;
      forEach(notificationsIds, (instantNotificationId) => {
        const idx = findIndex(notifications, { instantNotificationId });
        if (idx >= 0) {
          notifications[idx] = { ...notifications[idx], status: 'Active' };
        }
      });
      return {
        ...state,
        notifications,
        sending: unregisterAction(state.fetching, actionTypes.MARK_NOTIFICATIONS_READ),
        errors : registerAction(state.errors, actionTypes.MARK_NOTIFICATIONS_READ),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_NOTIFICATIONS_HISTORY: {
      return {
        ...state,
        notificationsHistory: [],
        fetching            : registerAction(state.fetching, actionTypes.FETCH_NOTIFICATIONS_HISTORY),
        errors              : unregisterAction(state.errors, actionTypes.FETCH_NOTIFICATIONS_HISTORY),
      };
    }
    case actionTypes.FETCH_NOTIFICATIONS_HISTORY_SUCCESS: {
      const { notificationsHistory } = action.payload;
      return {
        ...state,
        notificationsHistory,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_NOTIFICATIONS_HISTORY),
      };
    }
    case actionTypes.FETCH_NOTIFICATIONS_HISTORY_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_NOTIFICATIONS_HISTORY),
        errors  : registerAction(state.errors, actionTypes.FETCH_NOTIFICATIONS_HISTORY),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case SIGN_OUT: {
      return {
        ...initialState,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    default: {
      return state;
    }

  }
}
