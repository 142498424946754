import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Link from 'components/Link';
import messages from 'modules/App/messages';
import ChevronRight from 'svg/chevron-right.svg';


class BackButton extends React.Component {

  static propTypes = {
    to     : PropTypes.string.isRequired,
    onClick: PropTypes.func,
  };


  render() {
    return (
      <Link
        className="backButton"
        {...this.props}
      >
        <ChevronRight className="backArrow" />
        <FormattedMessage {...messages.buttons.back} />
      </Link>
    );
  }

}


export default BackButton;
