import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import map from 'lodash/map';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import App from 'modules/App';


class PhiSetFormPartial extends React.PureComponent {

  static propTypes = {
    // Explicit props
    metricConversions: PropTypes.shape({
      weight: PropTypes.object,
      height: PropTypes.object,
    }),
    formValues             : PropTypes.object,
    isDisabledDiabetesType : PropTypes.bool,
    isDisabledTreatmentType: PropTypes.bool,
    isActiveProfileTypePwd : PropTypes.bool,
    isMale                 : PropTypes.bool,
    isListInverted         : PropTypes.bool,
    localizationResources  : PropTypes.object,
    // Explicit actions
    onSetFormValue         : PropTypes.func,
  };


  static defaultProps = {
    isDisabledDiabetesType : false,
    isDisabledTreatmentType: false,
  };


  render() {
    return (
      <>
        <div className="row">
          <FormGroup
            id="diabetesType"
            labelMessage={App.messages.labels.diabetesType}
            className="col-6"
            formValues={this.props.formValues}
          >
            <Select
              optionsFrom={map(App.constants.DIABETES_TYPES, (value) => ({
                value,
                label: get(this.props.localizationResources, `diabetesType_${toLower(value)}.value`, value),
              }))}
              hiddenValues={
                [...this.props.isActiveProfileTypePwd
                  ? App.constants.DIABETES_TYPES_HIDE_FOR_PWD
                  : App.constants.DIABETES_TYPES_HIDE_FOR_HCP,
                ...this.props.isMale ? App.constants.DIABETES_TYPES_HIDE_FOR_MALE : [],
                ]
                }
              valueKey="value"
              labelKey="label"
              noValueMessage={App.messages.placeholders.diabetesType}
              onChange={this.props.onSetFormValue}
              isDisabled={this.props.isDisabledDiabetesType}
              isListInverted={this.props.isListInverted}
            />
          </FormGroup>
          <FormGroup
            id="treatmentType"
            labelMessage={App.messages.labels.treatmentType}
            className="col-6"
            formValues={this.props.formValues}
          >
            <Select
              optionsFrom={map(App.constants.TREATMENT_TYPES, (value) => ({
                value,
                label: get(this.props.localizationResources, `treatmentType_${toLower(value)}.value`, value),
              }))}
              valueKey="value"
              labelKey="label"
              noValueMessage={App.messages.placeholders.treatmentType}
              onChange={this.props.onSetFormValue}
              isDisabled={this.props.isDisabledTreatmentType}
              isListInverted={this.props.isListInverted}
            />
          </FormGroup>
        </div>
        <div className="row">
          <FormGroup
            id="weight"
            labelMessage={{
              ...App.messages.labels.weight,
              values: {
                units: this.props.metricConversions.weight.unitSymbol,
              },
            }}
            className="col-6"
            formValues={this.props.formValues}
          >
            <Input
              type="number"
              placeholder={App.messages.placeholders.weight}
              onChange={this.props.onSetFormValue}
            />
          </FormGroup>
          <FormGroup
            id="height"
            labelMessage={{
              ...App.messages.labels.height,
              values: {
                units: this.props.metricConversions.height.unitSymbol,
              },
            }}
            className="col-6"
            formValues={this.props.formValues}
          >
            <Input
              type="number"
              placeholder={App.messages.placeholders.height}
              onChange={this.props.onSetFormValue}
            />
          </FormGroup>
        </div>
      </>
    );
  }

}


export default injectIntl(PhiSetFormPartial);
