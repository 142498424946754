import React, { FC, useEffect } from 'react';
import { MessageDescriptor } from 'react-intl';
import { useAction } from 'hooks';
import * as actions from '../../actions';
import { CONNECTOR_TYPES } from '../../constants';
import MdaInit from '../../partials/MdaInit';
import SccInit from '../../partials/SccInit';


interface ComponentProps {
  onSetComponent: (componentName: string) => void,
  onSetHeaderMessage: (headerMessage?: MessageDescriptor) => void,
  onSetChannel: (channel: string) => void,
}

interface Props extends ComponentProps{
  connectorType: ConnectorType,
}


const Initializing: FC<Props> = ({ connectorType, ...props }) => {
  const Component: FC<ComponentProps> = connectorType === CONNECTOR_TYPES.SCC ? SccInit : MdaInit;
  const clearConnection = useAction(actions.clearConnection);

  useEffect(() => {
    clearConnection();
  }, []);

  return <Component {...props} />;
};

export default Initializing;
