// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2ZZ2z{color:#30a8ff;height:auto;line-height:1}._2ZZ2z .btn__labelWrapper{padding:0}._2Aswd{width:8px;width:.8rem;height:8px;height:.8rem;display:inline-block;margin-right:.5rem;fill:#30a8ff}", ""]);
// Exports
exports.locals = {
	"root": "_2ZZ2z",
	"icon": "_2Aswd"
};
module.exports = exports;
