import { defineMessages } from 'react-intl';


export default defineMessages({
  headers: {
    visitHistory       : { id: 'visit.headers.visitHistory' },
    visitNotes         : { id: 'visit.headers.visitNotes' },
    previousVisitsNotes: { id: 'visit.headers.previousVisitsNotes' },
  },

  tabs: {
    private: { id: 'visit.tabs.private' },
    shared : { id: 'visit.tabs.shared' },
  },

  buttons: {
    edit     : { id: 'visit.buttons.edit' },
    save     : { id: 'visit.buttons.save' },
    showNotes: { id: 'visit.buttons.showNotes' },
    hideNotes: { id: 'visit.buttons.hideNotes' },
    reply    : { id: 'visit.buttons.reply' },
  },

  labels: {
    activeVisit: { id: 'visit.labels.activeVisit' },
    clinic     : { id: 'visit.labels.clinic' },
    date       : { id: 'visit.labels.date' },
    hcp        : { id: 'visit.labels.hcp' },
    visitNotes : { id: 'visit.labels.visitNotes' },
  },

  infos: {
    addDataNote  : { id: 'visit.infos.addDataNote' },
    noVisits     : { id: 'visit.infos.noVisits' },
    noVisitsPwd  : { id: 'visit.infos.noVisitsPwd' },
    privateNote  : { id: 'visit.infos.privateNote' },
    noNotesToShow: { id: 'visit.infos.noNotesToShow' },
  },

  placeholders: {
    writeComment       : { id: 'visit.placeholders.writeComment' },
    writeNote          : { id: 'visit.placeholders.writeNote' },
    writeRecommendation: { id: 'visit.placeholders.writeRecommendation' },
  },
});
