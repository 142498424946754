import { defineMessages } from 'react-intl';


export default defineMessages({

  buttons: {
    setCloudDrive: { id: 'cloudDrive.buttons.setCloudDrive' },
  },

  labels: {
    comingSoon: { id: 'cloudDrive.labels.comingSoon' },
  },

  infos: {
    configuringCloudDrive: { id: 'cloudDrive.infos.configuringCloudDrive' },
  },

  errors: {
    businessErrors: {
      NoAccessToken                           : { id: 'cloudDrive.errors.businessErrors.NoAccessToken' },
      NoPhiSetDocumentId                      : { id: 'cloudDrive.errors.businessErrors.NoPhiSetDocumentId' },
      NoRequiredScopes                        : { id: 'cloudDrive.errors.businessErrors.NoRequiredScopes' },
      UnknownStorageProvider                  : { id: 'cloudDrive.errors.businessErrors.UnknownStorageProvider' },
      StorageQuotaExceeded                    : { id: 'cloudDrive.errors.businessErrors.StorageQuotaExceeded' },
      SharingRequestCloudDriveConnectionFailed: { id: 'cloudDrive.errors.businessErrors.SharingRequestCloudDriveConnectionFailed' },
      ProfileAlreadyExist                     : { id: 'cloudDrive.errors.businessErrors.ProfileAlreadyExist' },
      NotFound                                : { id: 'cloudDrive.errors.businessErrors.NotFound' },
      UpdateMeasurementsFailed                : { id: 'cloudDrive.errors.businessErrors.UpdateMeasurementsFailed' },
    },
  },
});
