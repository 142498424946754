import React, { FC, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { AppContext } from 'context';
import { useAction } from 'hooks';
import Button from 'components/Form/Button';
import * as actions from '../../actions';
import messages from '../../messages';
import styles from '../../components/DownloadDataModal/DownloadDataModal.pcss';


interface Props {
  onSetComponent: (componentName: string) => void,
}


const InstallDownloader: FC<Props> = ({ onSetComponent }) => {
  const { downloader: downloaderContext } = useContext(AppContext);
  const installDownloader = useAction(actions.installDownloader);
  const logEvent = useAction(actions.logEvent, 'InstallationPromptDisplayed');

  useEffect(() => {
    logEvent();
  }, []);

  const onInstall = () => {
    onSetComponent('InstallingDownloader');
    installDownloader();
  };

  return (
    <div className={styles.modal__content}>
      <h3 className="modal__subheader "><FormattedMessage {...messages.installDownloader.header} /></h3>
      <p className="modal__info"><FormattedMessage {...messages.installDownloader.info} /></p>
      <div className={styles.illustration}>
        <img src="/assets/img/tray.png" alt="" />
      </div>
      <Button
        type="link"
        to={downloaderContext.installUrl}
        styleModifier="primary"
        labelMessage={messages.buttons.installDownloader}
        className="btn--block btn--filled mb-0"
        onClick={onInstall}
      />
    </div>
  );
};

export default InstallDownloader;
