import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from './NoFamilyMembers.pcss';


class NoFamilyMembers extends React.PureComponent {

  static propTypes = {
    // Implicit props
    familyLinkInvitations: PropTypes.array,
    familyLinkRequests   : PropTypes.array,
    isInProgress         : PropTypes.bool,
  };


  get approvedFamilyLinkInvitations() {
    return this.props.familyLinkInvitations.filter((fl) => fl.status === 'Approved');
  }


  render() {
    const { familyLinkRequests } = this.props;
    const { approvedFamilyLinkInvitations } = this;
    if (approvedFamilyLinkInvitations.length || familyLinkRequests.length) {
      return null;
    }
    return (
      <div className={styles.root}>
        <div className="w-100">
          <img src="/assets/svg/no-people.svg" className={styles.icon} alt="" />
        </div>
        <div>
          <FormattedMessage {...messages.infos.noFamilyMembers} />
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  familyLinkInvitations: selectors.familyLinkInvitations(state),
  familyLinkRequests   : selectors.familyLinkRequests(state),
  isInProgress         : selectors.isFetchFamilyLinksInProgress(state),
});


const ConnectedNoFamilyMembers = connect(
  mapStateToProps,
)(NoFamilyMembers);


export default withStyles(styles)(ConnectedNoFamilyMembers);
