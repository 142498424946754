// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3knbD{padding:16px}._1jf6C{width:100%}.K4YhN{padding:1.3rem .8rem;border-radius:8px;height:48px}.K4YhN>*{padding:12px;width:25%}.K4YhN>:nth-child(n+2){text-align:center}.FB6TX tr.HvWYJ:nth-of-type(odd){background:#f5f9ff}._1cj_B{padding:12px;height:55px;border-radius:8px}._1cj_B>*{padding:12px;vertical-align:center}._1cj_B>:first-child{font-weight:700}._1cj_B>:nth-child(n+2){text-align:center}._3C0eh{background:#e0e8f2!important}._3X9G7{display:none;padding:12px}._3X9G7>*{padding:12px;vertical-align:center}._3X9G7>:nth-child(n+2){text-align:center}._2Vb6w{display:table-row}._2YUl8,._3AFSr{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center}._2YUl8{-ms-flex-align:center;align-items:center;border-radius:.4rem;padding:.5rem 3rem;height:30px;height:3rem;background:#e0e8f2;font-weight:700;white-space:nowrap}._1Pw4e{width:16px;width:1.6rem;height:8px;height:.8rem;fill:#6f7ea2;cursor:pointer;-webkit-transition:-webkit-transform .5s ease-in-out;transition:-webkit-transform .5s ease-in-out;-o-transition:transform .5s ease-in-out;transition:transform .5s ease-in-out;transition:transform .5s ease-in-out,-webkit-transform .5s ease-in-out}._2zDer{display:none}._32Noj{-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);transform:rotate(180deg)}._2U1Cd{width:100%}", ""]);
// Exports
exports.locals = {
	"additionalMeasurementsModal__tableWrapper": "_3knbD",
	"additionalMeasurementsModal__table": "_1jf6C",
	"additionalMeasurementsModal__tableHead": "K4YhN",
	"additionalMeasurementsModal__tableBody": "FB6TX",
	"otherResultsModal__primaryTableRow": "HvWYJ",
	"additionalMeasurementsModal__primaryTableRow": "_1cj_B",
	"additionalMeasurementsModal__primaryTableRow--expanded": "_3C0eh",
	"additionalMeasurementsModal__secondaryTableRow": "_3X9G7",
	"additionalMeasurementsModal__secondaryTableRow--expanded": "_2Vb6w",
	"additionalMeasurementsModal__latestValue": "_2YUl8",
	"additionalMeasurementsModal__latestValueWrapper": "_3AFSr",
	"additionalMeasurementsModal__dropdown": "_1Pw4e",
	"additionalMeasurementsModal__dropdown--hidden": "_2zDer",
	"additionalMeasurementsModal__dropdown--expanded": "_32Noj",
	"additionalMeasurementsModal__closeButton": "_2U1Cd"
};
module.exports = exports;
