import { defineMessages } from 'react-intl';


export default defineMessages({

  headers: {
    approveMembership          : { id: 'clinicManagement.headers.approveMembership' },
    rejectMembership           : { id: 'clinicManagement.headers.rejectMembership' },
    chooseBusinessEmail        : { id: 'clinicManagement.headers.chooseBusinessEmail' },
    chooseClinic               : { id: 'clinicManagement.headers.chooseClinic' },
    clinicAddress              : { id: 'clinicManagement.headers.clinicAddress' },
    clinicHcps                 : { id: 'clinicManagement.headers.clinicHcps' },
    clinicHcpPending           : { id: 'clinicManagement.headers.clinicHcpPending' },
    clinicHcpInvitations       : { id: 'clinicManagement.headers.clinicHcpInvitations' },
    clinicSettings             : { id: 'clinicManagement.headers.clinicSettings' },
    editClinicSettings         : { id: 'clinicManagement.headers.editClinicSettings' },
    cloudDrive                 : { id: 'clinicManagement.headers.cloudDrive' },
    createClinic               : { id: 'clinicManagement.headers.createClinic' },
    removeClinic               : { id: 'clinicManagement.headers.removeClinic' },
    grantAdmin                 : { id: 'clinicManagement.headers.grantAdmin' },
    revokeAdmin                : { id: 'clinicManagement.headers.revokeAdmin' },
    importPatientDatabase      : { id: 'clinicManagement.headers.importPatientDatabase' },
    importPatientDatabaseErrors: { id: 'clinicManagement.headers.importPatientDatabaseErrors' },
    inviteHCP                  : { id: 'clinicManagement.headers.inviteHCP' },
    inviteHCPs                 : { id: 'clinicManagement.headers.inviteHCPs' },
    person                     : { id: 'clinicManagement.headers.hcp' },
    setCloudDrive              : { id: 'clinicManagement.headers.setCloudDrive' },
    clinicGlucoseLevels        : { id: 'clinicManagement.headers.clinicGlucoseLevels' },
    cgm                        : { id: 'clinicManagement.headers.cgm' },
    bgm                        : { id: 'clinicManagement.headers.bgm' },
    resendInvitation           : { id: 'clinicManagement.headers.resendInvitation' },
    setCloudDriveLoading       : { id: 'clinicManagement.headers.setCloudDriveLoading' },
    glucoseDefault             : { id: 'clinicManagement.headers.glucoseDefault' },
    glucoseType1               : { id: 'clinicManagement.headers.glucoseType1' },
    glucoseType2               : { id: 'clinicManagement.headers.glucoseType2' },
    glucosePreDiabetes         : { id: 'clinicManagement.headers.glucosePreDiabetes' },
    glucoseGDM                 : { id: 'clinicManagement.headers.glucoseGDM' },
    kpi                        : { id: 'clinicManagement.headers.kpi' },
    kpiveryHigh                : { id: 'clinicManagement.headers.kpiveryHigh' },
    kpiveryLow                 : { id: 'clinicManagement.headers.kpiveryLow' },
    kpistandardDeviation       : { id: 'clinicManagement.headers.kpistandardDeviation' },
    kpiCV                      : { id: 'clinicManagement.headers.kpiCV' },
    kpiGMI                     : { id: 'clinicManagement.headers.kpiGMI' },
    kpireadingsPerDay          : { id: 'clinicManagement.headers.kpireadingsPerDay' },
    kpiDefault                 : { id: 'clinicManagement.headers.kpiDefault' },
  },

  buttons: {
    revoke                 : { id: 'clinicManagement.buttons.revoke' },
    approve                : { id: 'clinicManagement.buttons.approve' },
    addAnotherHCP          : { id: 'clinicManagement.buttons.addAnotherHCP' },
    back                   : { id: 'clinicManagement.buttons.back' },
    close                  : { id: 'clinicManagement.buttons.close' },
    closing                : { id: 'clinicManagement.buttons.closing' },
    cancel                 : { id: 'clinicManagement.buttons.cancel' },
    saveChanges            : { id: 'clinicManagement.buttons.saveChanges' },
    lockEditing            : { id: 'clinicManagement.buttons.lockEditing' },
    unlockEditing          : { id: 'clinicManagement.buttons.unlockEditing' },
    createClinic           : { id: 'clinicManagement.buttons.createClinic' },
    grant                  : { id: 'clinicManagement.buttons.grant' },
    import                 : { id: 'clinicManagement.buttons.import' },
    importFinished         : { id: 'clinicManagement.buttons.importFinished' },
    importInProgress       : { id: 'clinicManagement.buttons.importInProgress' },
    resumeImport           : { id: 'clinicManagement.buttons.resumeImport' },
    invite                 : { id: 'clinicManagement.buttons.invite' },
    joinClinic             : { id: 'clinicManagement.buttons.joinClinic' },
    manageOrganization     : { id: 'clinicManagement.buttons.manageOrganization' },
    managePatients         : { id: 'clinicManagement.buttons.managePatients' },
    next                   : { id: 'clinicManagement.buttons.next' },
    pause                  : { id: 'clinicManagement.buttons.pause' },
    pauseAndClose          : { id: 'clinicManagement.buttons.pauseAndClose' },
    removeClinic           : { id: 'clinicManagement.buttons.removeClinic' },
    reject                 : { id: 'clinicManagement.buttons.reject' },
    submit                 : { id: 'clinicManagement.buttons.submit' },
    start                  : { id: 'clinicManagement.buttons.start' },
    showErrorsList         : { id: 'clinicManagement.buttons.showErrorsList' },
    reauthorizeCloudDrive  : { id: 'clinicManagement.buttons.reauthorizeCloudDrive' },
    editGlucoseLevels      : { id: 'clinicManagement.buttons.editGlucoseLevels' },
    openClinicSettings     : { id: 'clinicManagement.buttons.openClinicSettings' },
    resendInvitationConfirm: { id: 'clinicManagement.buttons.resendInvitationConfirm' },
    resendInvitationReject : { id: 'clinicManagement.buttons.resendInvitationReject' },
    tryAgain               : { id: 'clinicManagement.buttons.tryAgain' },
    providePassword        : { id: 'clinicManagement.buttons.providePassword' },
  },

  statuses: {
    clinicMembership: {
      Pending : { id: 'clinicManagement.statuses.clinicMembership.Pending' },
      Active  : { id: 'clinicManagement.statuses.clinicMembership.Active' },
      Rejected: { id: 'clinicManagement.statuses.clinicMembership.Rejected' },
      Revoked : { id: 'clinicManagement.statuses.clinicMembership.Revoked' },
    },
    hcpInvitation: {
      Pending  : { id: 'clinicManagement.statuses.hcpInvitation.Pending' },
      Received : { id: 'clinicManagement.statuses.hcpInvitation.Received' },
      Approved : { id: 'clinicManagement.statuses.hcpInvitation.Approved' },
      Rejected : { id: 'clinicManagement.statuses.hcpInvitation.Rejected' },
      Cancelled: { id: 'clinicManagement.statuses.hcpInvitation.Cancelled' },
    },
  },

  menu: {
    approve         : { id: 'clinicManagement.menu.approve' },
    reject          : { id: 'clinicManagement.menu.reject' },
    revoke          : { id: 'clinicManagement.menu.revoke' },
    grantAdmin      : { id: 'clinicManagement.menu.grantAdmin' },
    revokeAdmin     : { id: 'clinicManagement.menu.revokeAdmin' },
    resendInvitation: { id: 'clinicManagement.menu.resendInvitation' },
  },


  nav: {
    dataSettings: { id: 'clinicManagement.nav.dataSettings' },
    memberships : { id: 'clinicManagement.nav.memberships' },
    phiSettings : { id: 'clinicManagement.nav.phiSettings' },
  },

  labels: {
    city                        : { id: 'clinicManagement.labels.city' },
    clinicId                    : { id: 'clinicManagement.labels.clinicId' },
    clinicLanguage              : { id: 'clinicManagement.labels.clinicLanguage' },
    clinicList                  : { id: 'clinicManagement.labels.clinicList' },
    clinicName                  : { id: 'clinicManagement.labels.clinicName' },
    country                     : { id: 'clinicManagement.labels.country' },
    email                       : { id: 'clinicManagement.labels.email' },
    hcp                         : { id: 'clinicManagement.labels.hcp' },
    firstName                   : { id: 'clinicManagement.labels.firstName' },
    lastName                    : { id: 'clinicManagement.labels.lastName' },
    lastLogin                   : { id: 'clinicManagement.labels.lastLogin' },
    lastLoginFailed             : { id: 'clinicManagement.labels.lastLoginFailed' },
    nationalId                  : { id: 'clinicManagement.labels.nationalId' },
    searchClinic                : { id: 'clinicManagement.labels.searchClinic' },
    state                       : { id: 'clinicManagement.labels.state' },
    status                      : { id: 'clinicManagement.labels.status' },
    street                      : { id: 'clinicManagement.labels.street' },
    zipCode                     : { id: 'clinicManagement.labels.zipCode' },
    glucosePostMealHigh         : { id: 'clinicManagement.labels.glucosePostMealHigh' },
    glucosePostMealLow          : { id: 'clinicManagement.labels.glucosePostMealLow' },
    glucosePreMealHigh          : { id: 'clinicManagement.labels.glucosePreMealHigh' },
    glucosePreMealLow           : { id: 'clinicManagement.labels.glucosePreMealLow' },
    glucoseCriticalHigh         : { id: 'clinicManagement.labels.glucoseCriticalHigh' },
    glucoseCriticalLow          : { id: 'clinicManagement.labels.glucoseCriticalLow' },
    grantAdmin                  : { id: 'clinicManagement.labels.grantAdmin' },
    high                        : { id: 'clinicManagement.labels.high' },
    low                         : { id: 'clinicManagement.labels.low' },
    target                      : { id: 'clinicManagement.labels.target' },
    glucoseDayHigh              : { id: 'clinicManagement.labels.glucoseDayHigh' },
    glucoseNightLow             : { id: 'clinicManagement.labels.glucoseNightLow' },
    glucoseNightHigh            : { id: 'clinicManagement.labels.glucoseNightHigh' },
    glucoseDayLow               : { id: 'clinicManagement.labels.glucoseDayLow' },
    day                         : { id: 'clinicManagement.labels.day' },
    night                       : { id: 'clinicManagement.labels.night' },
    dayStart                    : { id: 'clinicManagement.labels.dayStart' },
    dayEnd                      : { id: 'clinicManagement.labels.dayEnd' },
    searchingForPatients        : { id: 'clinicManagement.labels.searchingForPatients' },
    removingPatients            : { id: 'clinicManagement.labels.removingPatients' },
    clearingClinicData          : { id: 'clinicManagement.labels.clearingClinicData' },
    patient                     : { id: 'clinicManagement.labels.patient' },
    patientId                   : { id: 'clinicManagement.labels.patientId' },
    patientsImportedSuccessfully: { id: 'clinicManagement.labels.patientsImportedSuccessfully' },
    patientsImportedWithErrors  : { id: 'clinicManagement.labels.patientsImportedWithErrors' },
    allowedMeasurements         : { id: 'clinicManagement.labels.allowedMeasurements' },
    valueHigh                   : { id: 'clinicManagement.labels.valueHigh' },
    valueLow                    : { id: 'clinicManagement.labels.valueLow' },
  },

  placeholders: {
    city               : { id: 'clinicManagement.placeholders.city' },
    clinicName         : { id: 'clinicManagement.placeholders.clinicName' },
    email              : { id: 'clinicManagement.placeholders.email' },
    firstName          : { id: 'clinicManagement.placeholders.firstName' },
    lastName           : { id: 'clinicManagement.placeholders.lastName' },
    nationalId         : { id: 'clinicManagement.placeholders.nationalId' },
    searchClinic       : { id: 'clinicManagement.placeholders.searchClinic' },
    state              : { id: 'clinicManagement.placeholders.state' },
    street             : { id: 'clinicManagement.placeholders.street' },
    zipCode            : { id: 'clinicManagement.placeholders.zipCode' },
    glucoseCriticalHigh: { id: 'clinicManagement.placeholders.glucoseCriticalHigh' },
    glucoseCriticalLow : { id: 'clinicManagement.placeholders.glucoseCriticalLow' },
    glucosePostMealHigh: { id: 'clinicManagement.placeholders.glucosePostMealHigh' },
    glucosePostMealLow : { id: 'clinicManagement.placeholders.glucosePostMealLow' },
    glucosePreMealHigh : { id: 'clinicManagement.placeholders.glucosePreMealHigh' },
    glucosePreMealLow  : { id: 'clinicManagement.placeholders.glucosePreMealLow' },
    glucoseDayHigh     : { id: 'clinicManagement.placeholders.glucoseDayHigh' },
    glucoseNightLow    : { id: 'clinicManagement.placeholders.glucoseNightLow' },
    glucoseNightHigh   : { id: 'clinicManagement.placeholders.glucoseNightHigh' },
    glucoseDayLow      : { id: 'clinicManagement.placeholders.glucoseDayLow' },
    allowedMeasurements: { id: 'clinicManagement.placeholders.allowedMeasurements' },
    valueHigh          : { id: 'clinicManagement.placeholders.valueHigh' },
    valueLow           : { id: 'clinicManagement.placeholders.valueLow' },
  },

  infos: {
    approveMembership              : { id: 'clinicManagement.infos.approveMembership' },
    rejectMembership               : { id: 'clinicManagement.infos.rejectMembership' },
    createClinic                   : { id: 'clinicManagement.infos.createClinic' },
    grantAdmin                     : { id: 'clinicManagement.infos.grantAdmin' },
    revokeAdmin                    : { id: 'clinicManagement.infos.revokeAdmin' },
    dataStored                     : { id: 'clinicManagement.infos.dataStored' },
    editClinicSettings             : { id: 'clinicManagement.infos.editClinicSettings' },
    revokeMembership               : { id: 'clinicManagement.infos.revokeMembership' },
    resendInvitation               : { id: 'clinicManagement.infos.resendInvitation' },
    importPatientDatabase          : { id: 'clinicManagement.infos.importPatientDatabase' },
    importPatientDatabaseInProgress: { id: 'clinicManagement.infos.importPatientDatabaseInProgress' },
    importPatientDatabaseFinished  : { id: 'clinicManagement.infos.importPatientDatabaseFinished' },
    removeClinic                   : { id: 'clinicManagement.infos.removeClinic' },
    removeClinicData               : { id: 'clinicManagement.infos.removeClinicData' },
    veryHigh                       : { id: 'clinicManagement.infos.veryHigh' },
    veryLow                        : { id: 'clinicManagement.infos.veryLow' },
  },

  intros: {
    chooseBusinessEmail  : { id: 'clinicManagement.intros.chooseBusinessEmail' },
    findClinic           : { id: 'clinicManagement.intros.findClinic' },
    importPatientDatabase: { id: 'clinicManagement.intros.importPatientDatabase' },
    setCloudDrive        : { id: 'clinicManagement.intros.setCloudDrive' },
    removeClinic         : { id: 'clinicManagement.intros.removeClinic' },
    setCloudDriveLoading : { id: 'clinicManagement.intros.setCloudDriveLoading' },
  },

  alerts: {
    clinicCloudDriveReauthorized: { id: 'clinicManagement.alerts.clinicCloudDriveReauthorized' },
    hcpApprovalWaiting          : { id: 'clinicManagement.alerts.hcpApprovalWaiting' },
    patientsWithoutLeadingHcp   : { id: 'clinicManagement.alerts.patientsWithoutLeadingHcp' },
  },

  errors: {
    businessErrors: {
      ApprovedSharingRequestsExists                                 : { id: 'clinicManagement.errors.businessErrors.ApprovedSharingRequestsExists' },
      CanBeCalledForDeletedClinicOnly                               : { id: 'clinicManagement.errors.businessErrors.CanBeCalledForDeletedClinicOnly' },
      CannotTakeAwayAdminPermissions                                : { id: 'clinicManagement.errors.businessErrors.CannotTakeAwayAdminPermissions' },
      CantRemoveDataFromNotDeletedClinic                            : { id: 'clinicManagement.errors.businessErrors.CantRemoveDataFromNotDeletedClinic' },
      ClinicAdminPermissionIsRequired                               : { id: 'clinicManagement.errors.businessErrors.ClinicAdminPermissionIsRequired' },
      ClinicAlreadyExist                                            : { id: 'clinicManagement.errors.businessErrors.ClinicAlreadyExist' },
      ClinicNotActive                                               : { id: 'clinicManagement.errors.businessErrors.ClinicNotActive' },
      ClinicNotActiveOrDeleted                                      : { id: 'clinicManagement.errors.businessErrors.ClinicNotActiveOrDeleted' },
      ClinicWithLicenceOnly                                         : { id: 'clinicManagement.errors.businessErrors.ClinicWithLicenceOnly' },
      HcpHasPatients                                                : { id: 'clinicManagement.errors.businessErrors.HcpHasPatients' },
      InviteeAlreadyIsClinicHcpMember                               : { id: 'clinicManagement.errors.businessErrors.InviteeAlreadyIsClinicHcpMember' },
      InviteeAlreadyHasHcpInvitationToClinic                        : { id: 'clinicManagement.errors.businessErrors.InviteeAlreadyHasHcpInvitationToClinic' },
      InviterNotAllowedToSendInvitation                             : { id: 'clinicManagement.errors.businessErrors.InviterNotAllowedToSendInvitation' },
      MembershipNotFound                                            : { id: 'clinicManagement.errors.businessErrors.MembershipNotFound' },
      OnlyClinicAdminCanSetUpCloudDrive                             : { id: 'clinicManagement.errors.businessErrors.OnlyClinicAdminCanSetUpCloudDrive' },
      OnlyClinicAdminCanUpdate                                      : { id: 'clinicManagement.errors.businessErrors.OnlyClinicAdminCanUpdate' },
      OnlyClinicAdminCanReassignPatients                            : { id: 'clinicManagement.errors.businessErrors.OnlyClinicAdminCanReassignPatients' },
      OnlyClinicAdminCanSetOthersExchangeTokens                     : { id: 'clinicManagement.errors.businessErrors.OnlyClinicAdminCanSetOthersExchangeTokens' },
      OnlyClinicAdminIsAllowedToDelete                              : { id: 'clinicManagement.errors.businessErrors.OnlyClinicAdminIsAllowedToDelete' },
      OnlyClinicAdminIsAllowedToSetExchangeTokensForClinicMembership: { id: 'clinicManagement.errors.businessErrors.OnlyClinicAdminIsAllowedToSetExchangeTokensForClinicMembership' },
      PatientDatabaseConnectionError                                : { id: 'clinicManagement.errors.businessErrors.PatientDatabaseConnectionError' },
      PatientDatabaseTransactionScopeAlreadyOpen                    : { id: 'clinicManagement.errors.businessErrors.PatientDatabaseTransactionScopeAlreadyOpen' },
      RevokeWithPatients                                            : { id: 'clinicManagement.errors.businessErrors.RevokeWithPatients' },
      OnlyClinicAdminIsAllowedToCall                                : { id: 'clinicManagement.errors.businessErrors.OnlyClinicAdminIsAllowedToCall' },
      NotFoundClinic                                                : { id: 'clinicManagement.errors.businessErrors.NotFoundClinic' },
      InvalidLicenceKey                                             : { id: 'clinicManagement.errors.businessErrors.InvalidLicenceKey' },
      LicenceKeyAlreadyInUse                                        : { id: 'clinicManagement.errors.businessErrors.LicenceKeyAlreadyInUse' },
      MembershipAlreadyExists                                       : { id: 'clinicManagement.errors.businessErrors.MembershipAlreadyExists' },
    },
  },

});
