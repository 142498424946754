import { useCallback } from 'react';
import DefaultValidator from 'libs/Validator';
import { validateForm } from 'helpers/form';


export const useFormProcessing = (onSubmit, onErrors, onProcessing, {
  isToUnfold = false,
  Validator = DefaultValidator,
} = {}) => useCallback((formValues, validatorRules) => {
  onProcessing();
  const { validatedValues, errors } = validateForm(formValues, validatorRules, isToUnfold, Validator);
  onErrors(errors);
  if (!errors) onSubmit(validatedValues);
}, []);
