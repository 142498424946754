import PropTypes from 'prop-types';
import valuesIn from 'lodash/valuesIn';
import App from 'modules/App';
import clinicShape from 'shapes/clinicShape';
import { PROFILE_TYPES, SCOPE_NAMES } from './constants';


export const profileType = PropTypes.oneOf(valuesIn(PROFILE_TYPES));

const gender = PropTypes.oneOf(['Female', 'Male']);


export const settings = PropTypes.shape({
  firstDayOfWeek: PropTypes.number,
});


export const account = PropTypes.shape({
  accountId  : PropTypes.number.isRequired,
  avatar     : PropTypes.string, // base64
  countryId  : PropTypes.number.isRequired,
  countryCode: PropTypes.string.isRequired,
  language   : PropTypes.string.isRequired,
  prvKeyPem  : PropTypes.string,
  pubKeyPem  : PropTypes.string,
  scope      : PropTypes.oneOf(valuesIn(SCOPE_NAMES)).isRequired,
  settings,
});


export const clinicMembership = PropTypes.shape({
  clinicHcpMembershipId: PropTypes.number.isRequired,
  clinicId             : PropTypes.number.isRequired,
  hcpProfileId         : PropTypes.number.isRequired,
  hcpBusinessEmail     : PropTypes.string.isRequired,
  isAdmin              : PropTypes.bool.isRequired,
  membershipStatus     : PropTypes.oneOf(['Pending', 'Active']).isRequired,
  encryptedPrivateKey  : PropTypes.string,
  encryptedPassphrase  : PropTypes.string,
  encryptedRefreshToken: PropTypes.string,
  clinic               : clinicShape.isRequired,
});


export const patientDataFromInvitation = PropTypes.shape({
  email                         : PropTypes.string.isRequired,
  firstName                     : PropTypes.string,
  lastName                      : PropTypes.string,
  countryId                     : PropTypes.number,
  language                      : PropTypes.string,
  dateOfBirth                   : PropTypes.string,
  gender,
  payer                         : PropTypes.string,
  customPatientIdentifiersValues: PropTypes.arrayOf(PropTypes.shape({
    customIdentifierId: PropTypes.number.isRequired,
    value             : PropTypes.string.isRequired,
  })),
});


export const patientHealthDataFromInvitation = PropTypes.shape({
  diabetesType : PropTypes.oneOf(App.constants.DIABETES_TYPES),
  treatmentType: PropTypes.oneOf(App.constants.TREATMENT_TYPES),
});
