// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@-webkit-keyframes _2G_xY{5%{opacity:1}50%{opacity:.35}95%{opacity:1}}@keyframes _2G_xY{5%{opacity:1}50%{opacity:.35}95%{opacity:1}}._3q1A3{width:20px;width:2rem;height:20px;height:2rem;padding:.2rem;border-radius:.4rem;background:#fff}._3q1A3 svg{width:16px;width:1.6rem;height:16px;height:1.6rem;fill:#30a8ff}", ""]);
// Exports
exports.locals = {
	"relatedData": "_3q1A3",
	"fading": "_2G_xY"
};
module.exports = exports;
