import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cn from 'classnames';
import get from 'lodash/get';
import FormContainerAbstract from 'components/FormContainerAbstract';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import App from 'modules/App';
import accessTokenShape from 'shapes/accessTokenShape';
import Form from 'components/Form';
import Button from 'components/Form/Button';
import CloudDrive from 'modules/CloudDrive';
import Visit from 'modules/Visit';
import * as constants from '../../../../constants';
import messages from '../../../../messages';
import * as styles from '../Forms.pcss';
import DateTimePicker from '../../../../partials/DateTimePicker';
import { getTimestamp } from '../../../../helpers';
import validatorRules from './validatorRules.json';


class TriglyceridesForm extends FormContainerAbstract {

  static propTypes = {
    ...FormContainerAbstract.propTypes,
    // Explicit props
    locale          : PropTypes.string,
    formValues      : PropTypes.object,
    phiSet          : PropTypes.object,
    phiSetDocumentId: PropTypes.string,
    patient         : PropTypes.shape({
      id                : PropTypes.string.isRequired,
      countryId         : PropTypes.number.isRequired,
      phiSetReferenceKey: PropTypes.string,
      storageProvider   : PropTypes.string,
      accessToken       : accessTokenShape,
      firstName         : PropTypes.string,
      lastName          : PropTypes.string,
    }),
    metricsConversions : PropTypes.object,
    // Implicit props
    isInProgress       : PropTypes.bool,
    // Explicit actions
    onSubmit           : PropTypes.func,
    onSuccess          : PropTypes.func,
    // Implicit actions
    onSetFormValue     : PropTypes.func,
    onSetFormProcessing: PropTypes.func,
    onSetFormValues    : PropTypes.func,
    onFormErrors       : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.validatorRules = { ...validatorRules };
  }


  onSubmit() {
    this.props.onFormProcessing();
    const { validatedValues, errors } = this.onValidate(this.validatorRules);
    this.props.onFormErrors(errors);
    if (errors) {
      return;
    }

    const { patient, phiSet, phiSetDocumentId, activeVisit, onSuccess, metricsConversions } = this.props;
    const { dateTimestamp, time } = validatedValues;
    const visitData = {
      phisetVisitId: get(activeVisit, 'phisetVisitId'),
      timestamp    : getTimestamp(dateTimestamp, time),
    };

    let { triglycerides } = validatedValues;
    const { triglycerides: triglyceridesConversion } = metricsConversions;
    if (triglyceridesConversion.unit === 'MMOL_L') {
      triglycerides = triglyceridesConversion.toStorage(triglycerides);
    }
    this.props.onSubmit({ triglycerides }, phiSet, phiSetDocumentId, patient, visitData, onSuccess);
  }


  renderTriglyceridesRow() {
    const { isInProgress, metricsConversions } = this.props;

    return (
      <div className={styles.root__row}>
        <div className={cn(styles.root__cell, styles['root__cell--singleCell'])}>
          <FormGroup
            id="triglycerides"
            labelMessage={messages.labels.triglycerides}
            formValues={this.props.formValues}
          >
            <Input
              inputValue="true"
              type="number"
              attributes={{ maxLength: 6 }}
              labelMessage={messages.labels.triglycerides}
              isDisabled={this.props.isInProgress}
              onChange={this.props.onSetFormValue}
            />
          </FormGroup>
          <div
            className={
              cn(styles.root__unit, {
                [styles['root__unit--disabled']]: isInProgress,
              })
            }
          >
            { metricsConversions.triglycerides.unitSymbol }
          </div>
        </div>
      </div>
    );
  }


  render() {
    return (
      <Form className={styles.root} onSubmit={() => this.onSubmit()}>
        { this.renderTriglyceridesRow() }
        <DateTimePicker
          locale={this.props.locale}
          formValues={this.props.formValues}
          isDisabled={this.props.isInProgress}
          onSetFormValue={this.props.onSetFormValue}
          onSetFormValues={this.props.onSetFormValues}
        />
        <Button
          styleModifier="primary"
          type="submit"
          labelMessage={messages.buttons.saveResult}
          className="btn--filled"
          isInProgress={this.props.isInProgress}
        />
      </Form>
    );
  }

}


const mapStateToProps = () => (state) => ({
  locale      : App.selectors.locale(state),
  formValues  : App.selectors.formSelector(constants.TRIGLYCERIDES_FORM)(state),
  isInProgress: CloudDrive.selectors.isStoreMeasurementsInProgress(state),
  activeVisit : Visit.selectors.activeVisit(state),
});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.TRIGLYCERIDES_FORM;

  return {
    onFormProcessing: () => dispatch(App.actions.startFormProcessing(formName)),
    onSetFormValue  : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onSetFormValues : (values) => dispatch(App.actions.setFormValues(formName, values)),
    onFormErrors    : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onClearForm     : () => dispatch(App.actions.clearForm(formName)),
  };
};


const ConnectedTriglyceridesForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(TriglyceridesForm));


export default withStyles(styles)(ConnectedTriglyceridesForm);
