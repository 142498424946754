import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment';
import includes from 'lodash/includes';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import { injectIntl, FormattedMessage } from 'react-intl';
import StatsCalculations from 'libs/StatsCalculations';
import BloodGlucoseConcentrationChart from 'components/Charts/BloodGlucoseConcentrationChart';
import App from 'modules/App';
import Logo from 'svg/Ascensia-logo.svg';
import { calculateData } from 'helpers/kpi';
import intlShape from 'shapes/intlShape';
import * as constants from '../../constants';
import messages from '../../messages';
import styles from './DailyLogReport.pcss';
import Header from './Header';
import DailyChart from './DailyChart';
import Summary from './Header/Summary';


class DailyLogReport extends React.Component {


  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    readings             : PropTypes.array,
    relatedData          : PropTypes.array,
    phiSet               : PropTypes.object, // @TODO: shape
    accountPatientProfile: PropTypes.object, // @TODO: Shape
    calculationFormula   : PropTypes.oneOf(constants.CALCULATION_FORMULAS),
    startDate            : PropTypes.object,
    endDate              : PropTypes.object,
    customRanges         : PropTypes.array,
    daysWithReadings     : PropTypes.number,
    daysWithoutReadings  : PropTypes.number,
    hideEmptyRows        : PropTypes.bool,
    showDayDividers      : PropTypes.bool,
    // Implicit props
    featureToggles       : PropTypes.array,
    intl                 : intlShape.isRequired,
  };


  constructor(props) {
    super(props);
    this.data = calculateData(props.readings);
  }


  get customRangesLabels() {
    return map(this.props.customRanges, (bp) => bp[2]);
  }


  get chartData() {
    const { readings, standards, customRanges } = this.props;
    const statsCalculations = new StatsCalculations(
      readings,
      standards,
      customRanges,
      'value',
      'timestamp',
    );
    const averageData = statsCalculations.group('HOURS_RANGE', 'MEAN').map(
      (item) => ({ ...item, isAverage: true }),
    );
    return { readings: statsCalculations.group('HOURS_RANGE', 'NONE'), averageData };
  }


  get maxPlainValue() {
    const { calculationFormula } = this.props;
    if (includes(['HYPERGLYCEMIA', 'HYPOGLYCEMIA'], calculationFormula)) {
      return 100;
    }
    return 20;
  }


  get unitSymbol() {
    const { calculationFormula } = this.props;
    if (includes(['HYPERGLYCEMIA', 'HYPOGLYCEMIA'], calculationFormula)) {
      return '%';
    }
    return null;
  }


  get ticks() {
    const startDate = moment().utc().locale('en--account');
    const endDate = moment().utc().locale('en--account');

    const ticksPoints = [];
    startDate.startOf('day');
    endDate.endOf('day');
    ticksPoints.push(+startDate.format('X'));
    const breakpoints = [...this.props.customRanges];
    breakpoints.shift();
    forEach(breakpoints, (hourBreakpoint) => {
      ticksPoints.push(+startDate.hour(hourBreakpoint[0]).minutes(hourBreakpoint[1]).format('X'));
    });
    ticksPoints.push(+endDate.format('X') + 1);
    return ticksPoints;
  }


  get gridTheme() {
    return {
      grid: {
        stroke     : '#E0E8F4',
        strokeWidth: 1,
      },
    };
  }


  renderUnit() {
    return (
      <p className={styles.bloodGlucoseConcentrationUnit}>
        <FormattedMessage
          {...messages.labels.unit}
          values={
            {
              unit: this.props.conversion.unitSymbol,
            }
          }
        />
      </p>
    );
  }


  renderContent() {
    const {
      conversion, standards, startDate, endDate, relatedData,
      readings, phiSet, daysWithReadings, daysWithoutReadings,
    } = this.props;
    const { preMeal, postMeal } = this.data;

    return (
      <div className={styles.printableDailyLogReport}>
        <Header
          phiSet={phiSet}
          accountPatientProfile={this.props.accountPatientProfile}
          standards={standards}
          conversion={conversion}
          startDate={startDate}
          endDate={endDate}
        />
        <Summary
          readings={readings}
          preMeal={preMeal}
          postMeal={postMeal}
          conversion={conversion}
          standards={standards}
          daysWithReadings={daysWithReadings}
          daysWithoutReadings={daysWithoutReadings}
        />
        <div className={styles.standardDay__wrapper}>
          <div className={styles.standardDay}>
            <FormattedMessage {...messages.dailyReport.standardDay} />
          </div>
          { this.renderUnit() }
          <BloodGlucoseConcentrationChart
            disableTooltip
            conversion={conversion}
            standards={standards}
            readings={this.chartData.readings}
            averageData={this.chartData.averageData}
            start={startDate.unix()}
            end={endDate.unix()}
            timestampKey="timestamp"
            valueKey="value"
            direction="ltr"
            maxPlainValue={this.maxPlainValue}
            unitSymbol={this.unitSymbol}
            isLineEnabled={false}
            isGridXEnabled={undefined}
            isPlainValuesMode={false}
            isReadOnly
            isCustomRanges
            ticks={this.ticks}
            formatLabel={() => ''}
            enableGridY={false}
            gridTheme={this.gridTheme}
            customRangesLabels={this.customRangesLabels}
          />
          <div className={styles.chart__legend}>
            <p>
              <FormattedMessage {...messages.dailyReport.tableCriticalHigh} /><br />
              <b>{ ` > ${constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].veryHigh} ${conversion.unitSymbol}` }</b>
            </p>
            <p>
              <FormattedMessage {...messages.dailyReport.tablePostMeal} /><br />
              <b>{ ` ${conversion.toDisplay(standards.postMeal.lowThreshold)} - ${conversion.toDisplay(standards.postMeal.highThreshold)} ${conversion.unitSymbol}` }</b>
            </p>
            <p>
              <FormattedMessage {...messages.dailyReport.tablePreMeal} /><br />
              <b>{ ` ${conversion.toDisplay(standards.preMeal.lowThreshold)} - ${conversion.toDisplay(standards.preMeal.highThreshold)} ${conversion.unitSymbol}` }</b>
            </p>
            <p>
              <FormattedMessage {...messages.dailyReport.tableCriticalLow} /><br />
              <b>{ ` < ${constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].low} ${conversion.unitSymbol}` }</b>
            </p>
          </div>
        </div>
        <DailyChart
          readings={readings}
          conversion={conversion}
          standards={standards}
          ticks={this.ticks}
          customRanges={this.props.customRanges}
          relatedData={relatedData}
          hideEmptyRows={this.props.hideEmptyRows}
          showDayDividers={this.props.showDayDividers}
        />
        { /* <p className={styles.footer__generated}> */ }
        { /*   {`${this.props.intl.formatMessage(messages.glucoseReport.generatedReport)}: ${formatDate(new Date())}`} */ }
        { /* </p> */ }
        <div className={styles.footer}><Logo /></div>
      </div>
    );
  }

  render() {
    if (!this.props.phiSet) return null;
    return this.renderContent();
  }

}


const mapStateToProps = (state) => ({
  featureToggles: App.selectors.featureToggles(state),
});


const ConnectedDailyLogReport = connect(
  mapStateToProps,
)(DailyLogReport);

export default withStyles(styles)(injectIntl(ConnectedDailyLogReport));
