import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import pick from 'lodash/pick';
import Validator from 'libs/Validator';
import FormContainerAbstract from 'components/FormContainerAbstract';
import Form from 'components/Form';
import FormGroup from 'components/Form/FormGroup';
import AutosuggestInput from 'components/Form/AutosuggestInput';
import Button from 'components/Form/Button';
import App from 'modules/App';
import Information from 'modules/Information';
import * as actions from '../../../../actions';
import * as constants from '../../../../constants';
import * as selectors from '../../../../selectors';
import * as shapes from '../../../../shapes';
import messages from '../../../../messages';


class PatientProfileFrom extends FormContainerAbstract {

  static propTypes = {
    // Explicit props
    patientProfile       : PropTypes.object.isRequired,
    // Implicit props
    account              : shapes.account,
    payers               : PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })),
    information          : PropTypes.object,
    localizationResources: PropTypes.object,
    // Implicit actions
    onFetchPayers        : PropTypes.func,
  };


  constructor(props) {
    super(props);
    props.onFetchPayers();
    this.onPopulate();
  }


  componentDidUpdate(prevProps) {
    const { isInProgress, hasErrors } = this.props;
    if (prevProps.isInProgress !== isInProgress && !isInProgress && !hasErrors) {
      this.onCommit();
    }
  }


  onPopulate() {
    if (!this.props.patientProfile) {
      return;
    }
    const { patientProfile } = this.props;
    const values = pick(patientProfile, ['payer']);
    this.onSetValues(values);
  }


  onValidate(rules) {
    const values = { ...get(this.props.formValues, 'values', {}) };
    const clonedRules = { ...rules };
    clonedRules.payer = this.isPayerMandatory ? 'required' : 'trim';
    return Validator.run(values, clonedRules);
  }


  onSubmit() {
    this.props.onFormProcessing();
    const { validatedValues, errors } = this.onValidate(this.validatorRules);
    this.props.onFormErrors(errors);
    if (!errors) {
      const { payer } = validatedValues;
      this.props.onSubmit(validatedValues);
      if (payer) {
        const { countryId } = this.props.account;
        this.props.onAddPayerProposal(countryId, payer);
      }
    }
  }


  get isPayerMandatory() {
    const { countrySettings } = this.props;
    return countrySettings ? countrySettings.isPayerMandatory : false;
  }


  renderCancelBtn() {
    return (
      <Button
        styleModifier="primary"
        className="btn--block"
        labelMessage={App.messages.buttons.discardChanges}
        isDisabled={this.isClean}
        onClick={() => this.onReset()}
      />
    );
  }


  renderSubmitBtn() {
    return (
      <Button
        type="submit"
        styleModifier="primary"
        labelMessage={App.messages.buttons.saveChanges}
        className="btn--block btn--filled"
        isDisabled={this.isClean}
        isInProgress={this.props.isInProgress}
      />
    );
  }


  renderActions() {
    return (
      <div className="row">
        <div className="col-6">{this.renderCancelBtn()}</div>
        <div className="col-6">{this.renderSubmitBtn()}</div>
      </div>
    );
  }


  // TODO: What about Personal Identifier
  renderPersonalIdentifier() {
    const personalIdentifier = get(this.props.information, 'personalIdentifier');
    if (!personalIdentifier) {
      return null;
    }
    const { personalIdentifierType } = personalIdentifier;
    const label = get(this.props.localizationResources, [personalIdentifierType, 'value'], personalIdentifierType);
    return (
      <div className="form-group">
        <p className="form-label">{label}</p>
        <p className="text--large">{personalIdentifier.personalIdentifierValue}</p>
      </div>
    );
  }


  renderForm() {
    return (
      <Form onSubmit={() => this.onSubmit()}>
        { this.renderPersonalIdentifier() }
        <FormGroup
          id="payer"
          labelMessage={messages.labels.payer}
          formValues={this.props.formValues}
        >
          <AutosuggestInput
            suggestions={this.props.payers}
            placeholder={messages.placeholders.payer}
            onChange={(input) => this.onSetValue(input)}
          />
        </FormGroup>
        { this.renderActions() }
      </Form>
    );
  }


  render() {
    return (
      <section>
        <h2 className="section__header">
          <FormattedMessage {...messages.headers.patientProfile} />
        </h2>
        { this.renderForm() }
      </section>
    );
  }

}


const mapStateToProps = (state) => ({
  account              : selectors.account(state),
  payers               : selectors.payers(state),
  isInProgress         : selectors.isUpdatePatientProfileInProgress(state),
  hasErrors            : selectors.hasUpdatePatientProfileErrors(state),
  information          : Information.selectors.information(state),
  formValues           : App.selectors.formSelector(constants.PATIENT_PROFILE_FORM)(state),
  localizationResources: App.selectors.localizationResources(state),
});

const mapDispatchToProps = (dispatch) => {
  const formName = constants.PATIENT_PROFILE_FORM;
  return {
    onAddPayerProposal: (countryId, payer) => dispatch(App.actions.addPayerProposal(countryId, payer)),
    onFetchPayers     : () => dispatch(actions.fetchPayers()),
    onSubmit          : (values) => dispatch(actions.updatePatientProfile(values)),
    onSetFormValue    : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onSetFormValues   : (values) => dispatch(App.actions.setFormValues(formName, values)),
    onFormErrors      : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onFormProcessing  : () => dispatch(App.actions.startFormProcessing(formName)),
    onClearForm       : () => dispatch(App.actions.clearForm(formName)),
    onCommitForm      : () => dispatch(App.actions.commitForm(formName)),
    onResetForm       : () => dispatch(App.actions.resetForm(formName)),
  };
};

const ConnectedPatientProfileForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PatientProfileFrom);

export default ConnectedPatientProfileForm;
