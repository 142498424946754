import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment';
import includes from 'lodash/includes';
import forEach from 'lodash/forEach';
import cn from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { formatDate } from 'helpers/datetime';
import StatsCalculations from 'libs/StatsCalculations';
import BloodGlucoseConcentrationChart from 'components/Charts/BloodGlucoseConcentrationChart';
import Patient from 'modules/Patient';
import Hcp from 'modules/Hcp';
import Account from 'modules/Account';
import App from 'modules/App';
import intlShape from 'shapes/intlShape';
import { calculateData } from 'helpers/kpi';
import * as constants from '../../constants';
import messages from '../../messages';
import GlucoseLevelDistributionChart from './GlucoseLevelDistributionBarChart';
import styles from './BloodGlucoseConcentrationReport.pcss';
import Header from './Header';
import Table from './Table';


class BloodGlucoseConcentrationReport extends React.Component {

  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    phiSet               : PropTypes.object, // @TODO: shape
    customRanges         : PropTypes.array,
    accountPatientProfile: PropTypes.object, // @TODO: Shape
    // Implicit props
    intl                 : intlShape.isRequired,
    readingsPwd30        : PropTypes.array,
    readingsPwd60        : PropTypes.array,
    readingsPwd90        : PropTypes.array,
    readingsHcp30        : PropTypes.array,
    readingsHcp60        : PropTypes.array,
    readingsHcp90        : PropTypes.array,
    activeProfileType    : PropTypes.string,
    featureToggles       : PropTypes.array,
    // Implicit actions
    calculationFormula   : PropTypes.oneOf(constants.CALCULATION_FORMULAS),
  };


  constructor(props) {
    super(props);

    this.data = props.activeProfileType === Account.constants.PROFILE_TYPES.PWD
      ? {
        ...calculateData(props.readingsPwd30),
        readings30: props.readingsPwd30,
        readings60: props.readingsPwd60,
        readings90: props.readingsPwd90,
      }
      : {
        ...calculateData(props.readingsHcp30),
        readings30: props.readingsHcp30,
        readings60: props.readingsHcp60,
        readings90: props.readingsHcp90,
      };
  }

  get chartData() {
    const { customRanges } = this.props;
    const statsCalculations = new StatsCalculations(
      this.data.readings30,
      this.props.standards,
      customRanges,
      'value',
      'timestamp',
    );
    const averageData = statsCalculations.group('HOURS_RANGE', 'MEAN').map((item) => ({ ...item, isAverage: true }));
    return { readings: statsCalculations.group('HOURS_RANGE', 'NONE'), averageData };
  }

  get chartDataPreMeal() {
    const { customRanges } = this.props;
    const statsCalculations = new StatsCalculations(
      this.data.preMealReadings,
      this.props.standards,
      customRanges,
      'value',
      'timestamp',
    );
    const averageData = statsCalculations.group('HOURS_RANGE', 'MEAN').map((item) => ({ ...item, isAverage: true }));
    return { readings: statsCalculations.group('HOURS_RANGE', 'NONE'), averageData };
  }


  get chartDataPostMeal() {
    const { customRanges } = this.props;
    const statsCalculations = new StatsCalculations(
      this.data.postMealReadings,
      this.props.standards,
      customRanges,
      'value',
      'timestamp',
    );
    const averageData = statsCalculations.group('HOURS_RANGE', 'MEAN').map((item) => ({ ...item, isAverage: true }));
    return { readings: statsCalculations.group('HOURS_RANGE', 'NONE'), averageData };
  }


  get maxPlainValue() {
    const { calculationFormula } = this.props;
    if (includes(['HYPERGLYCEMIA', 'HYPOGLYCEMIA'], calculationFormula)) {
      return 100;
    }
    return 20;
  }


  get unitSymbol() {
    const { calculationFormula } = this.props;
    if (includes(['HYPERGLYCEMIA', 'HYPOGLYCEMIA'], calculationFormula)) {
      return '%';
    }
    return null;
  }


  get ticks() {
    const startDate = moment().utc();
    const endDate = moment().utc();

    const ticksPoints = [];
    startDate.startOf('day');
    endDate.endOf('day');
    ticksPoints.push(+startDate.format('X'));
    const breakpoints = [...this.props.customRanges];
    breakpoints.shift();
    forEach(breakpoints, (hourBreakpoint) => {
      ticksPoints.push(+startDate.hour(hourBreakpoint[0]).minutes(hourBreakpoint[1]).format('X'));
    });
    ticksPoints.push(+endDate.format('X') + 1);
    return ticksPoints;
  }


  get gridTheme() {
    return {
      grid: {
        stroke     : '#E0E8F4',
        strokeWidth: 1,
      },
    };
  }


  renderUnit() {
    return (
      <p className={styles.bloodGlucoseConcentrationUnit}>
        <FormattedMessage
          {...messages.labels.unit}
          values={
            {
              unit: this.props.conversion.unitSymbol,
            }
          }
        />
      </p>
    );
  }

  renderTextField() {
    return (
      <div className={styles.textFields__wrapper}>
        <h1>
          <FormattedMessage {...messages.glucoseReport.textField} />
        </h1>
        <hr />
        <hr />
        <hr />
      </div>
    );
  }

  renderContent() {
    const { conversion, standards } = this.props;
    const { readings30, readings60, readings90 } = this.data;
    const start = moment.utc().startOf('day').unix();
    const end = moment.utc().endOf('day').unix();

    const endDate30 = moment.utc().endOf('day');
    const startDate30 = endDate30.clone().add(-29, 'days').startOf('day');
    const endDate60 = startDate30.clone().add(-1, 'days').endOf('day');
    const startDate60 = endDate60.clone().add(-29, 'days').startOf('day');
    const endDate90 = startDate60.clone().add(-1, 'days').endOf('day');
    const startDate90 = endDate90.clone().add(-29, 'days').startOf('day');

    return (
      <div className={styles.printableBloodGlucoseConcentrationReport}>
        <Header
          phiSet={this.props.phiSet}
          accountPatientProfile={this.props.accountPatientProfile}
          standards={this.props.standards}
          conversion={conversion}
        />
        <div className={styles.headerGlucoseConcentrationReport__row}>
          <div>
            <div className={styles.chart__title}>
              <FormattedMessage {...messages.glucoseReport.headerGlucoseLevelHeader} />
            </div>
            <div className={styles.glucoseLevel__content}>
              <GlucoseLevelDistributionChart
                disableTooltip
                conversion={conversion}
                standards={standards}
                readings={readings30}
                start={startDate30}
                end={endDate30}
                valueKey="value"
              />
              <GlucoseLevelDistributionChart
                disableTooltip
                conversion={conversion}
                standards={standards}
                readings={readings60}
                start={startDate60}
                end={endDate60}
                valueKey="value"
              />
              <GlucoseLevelDistributionChart
                disableTooltip
                conversion={conversion}
                standards={standards}
                readings={readings90}
                start={startDate90}
                end={endDate90}
                valueKey="value"
              />
            </div>
          </div>
          <div>
            <div className={styles.chart__title}>
              <FormattedMessage {...messages.glucoseReport.headerBloodGlucoseHeader} /><br />
              { `${formatDate(startDate30)} - ${formatDate(endDate30)}` }
              { this.renderUnit() }
            </div>
            <BloodGlucoseConcentrationChart
              disableTooltip
              conversion={conversion}
              standards={standards}
              readings={this.chartData.readings}
              averageData={this.chartData.averageData}
              start={start}
              end={end}
              timestampKey="timestamp"
              valueKey="value"
              direction="ltr"
              maxPlainValue={this.maxPlainValue}
              unitSymbol={this.unitSymbol}
              isLineEnabled={false}
              isGridXEnabled={undefined}
              isPlainValuesMode={false}
              isReadOnly
              printMode
              isCustomRanges
              ticks={this.ticks}
              customRangesLabels={[undefined, undefined, undefined, undefined]}
              formatLabel={() => ''}
              enableGridY={false}
              gridTheme={this.gridTheme}
              criticalHigh={constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].veryHigh}
              criticalLow={constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].low}
            />
            <div className={styles.chart__footer}>
              <FormattedMessage {...messages.glucoseReport.bloodGlucoseBottom} />
            </div>
          </div>
        </div>
        <Table readings30={readings30} readings60={readings60} readings90={readings90} conversion={conversion} />
        <div className={styles.headerGlucoseConcentrationReport__row}>
          <div>
            <div className={styles.chart__title}>
              <FormattedMessage {...messages.glucoseReport.headerBloodGlucoseHeaderPreMeal} /><br />
              { `${formatDate(startDate30)} - ${formatDate(endDate30)}` }
              { this.renderUnit() }
            </div>
            <BloodGlucoseConcentrationChart
              disableTooltip
              conversion={conversion}
              standards={standards}
              readings={this.chartDataPreMeal.readings}
              averageData={this.chartDataPreMeal.averageData}
              start={start}
              end={end}
              timestampKey="timestamp"
              valueKey="value"
              direction="ltr"
              maxPlainValue={this.maxPlainValue}
              unitSymbol={this.unitSymbol}
              isLineEnabled={false}
              isGridXEnabled={undefined}
              isPlainValuesMode={false}
              isReadOnly
              printMode
              isCustomRanges
              ticks={this.ticks}
              customRangesLabels={[undefined, undefined, undefined, undefined]}
              formatLabel={() => ''}
              enableGridY={false}
              criticalHigh={constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].veryHigh}
              criticalLow={constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].low}
            />
            <div className={cn(styles.chart__footer, styles.greenFont)}>
              { `${this.props.intl.formatMessage(messages.glucoseReport.bloodGlucoseBottomPreMeal)}: ${conversion.toDisplay(standards.preMeal.lowThreshold)} - ${conversion.toDisplay(standards.preMeal.highThreshold)} ${conversion.unitSymbol}` }
            </div>
          </div>
          <div>
            <div className={styles.chart__title}>
              <FormattedMessage {...messages.glucoseReport.headerBloodGlucoseHeaderPostMeal} /><br />
              { `${formatDate(startDate30)} - ${formatDate(endDate30)}` }
              { this.renderUnit() }
            </div>
            <BloodGlucoseConcentrationChart
              disableTooltip
              conversion={conversion}
              standards={standards}
              readings={this.chartDataPostMeal.readings}
              averageData={this.chartDataPostMeal.averageData}
              start={start}
              end={end}
              timestampKey="timestamp"
              valueKey="value"
              direction="ltr"
              maxPlainValue={this.maxPlainValue}
              unitSymbol={this.unitSymbol}
              isLineEnabled={false}
              isGridXEnabled={undefined}
              isPlainValuesMode={false}
              isReadOnly
              printMode
              isCustomRanges
              ticks={this.ticks}
              customRangesLabels={[undefined, undefined, undefined, undefined]}
              formatLabel={() => ''}
              enableGridY={false}
              criticalHigh={constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].veryHigh}
              criticalLow={constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].low}
            />
            <div className={cn(styles.chart__footer, styles.greenFont)}>
              { `${this.props.intl.formatMessage(messages.glucoseReport.bloodGlucoseBottomPostMeal)}: ${conversion.toDisplay(standards.postMeal.lowThreshold)} - ${conversion.toDisplay(standards.postMeal.highThreshold)} ${conversion.unitSymbol}` }
            </div>
          </div>
        </div>
        { this.renderTextField() }
        { /* <div className={styles.footer}><Logo /></div> */ }
      </div>
    );
  }

  render() {
    if (!this.props.phiSet) return null;
    return this.renderContent();
  }

}

const mapStateToProps = (state) => ({
  activeProfileType: Account.selectors.activeProfileType(state),
  readingsPwd30    : Patient.selectors.readingsRangeDate(state)(0),
  readingsPwd60    : Patient.selectors.readingsRangeDate(state)(30),
  readingsPwd90    : Patient.selectors.readingsRangeDate(state)(60),
  readingsHcp30    : Hcp.selectors.readingsRangeDate(state)(0),
  readingsHcp60    : Hcp.selectors.readingsRangeDate(state)(30),
  readingsHcp90    : Hcp.selectors.readingsRangeDate(state)(60),
  featureToggles   : App.selectors.featureToggles(state),
});


const ConnectedBloodGlucoseConcentrationReport = connect(
  mapStateToProps,
)(BloodGlucoseConcentrationReport);

export default withStyles(styles)(injectIntl(ConnectedBloodGlucoseConcentrationReport));
