import * as actionTypes from './actionTypes';


export const setPhiSet = (phiSet = null, phiSetDocumentId = null) => ({
  type   : actionTypes.SET_PHI_SET,
  payload: {
    phiSet,
    phiSetDocumentId,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const setReadings = (
  phiSet, readings, batchesIndex, cgmReadings, cgmBatchesIndex,
  relatedData, timeSeriesResources, measurements, measurementsBatchesIndex,
) => ({
  type   : actionTypes.SET_READINGS,
  payload: {
    phiSet,
    batchesIndex,
    readings,
    cgmReadings,
    cgmBatchesIndex,
    relatedData,
    timeSeriesResources,
    measurements,
    measurementsBatchesIndex,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const setImportedReadings = (phiSet, phiSetDocumentId, phiSetReferenceKey, readings, cgmReadings, importData, importId) => ({
  type   : actionTypes.SET_IMPORTED_READINGS,
  payload: {
    phiSet,
    phiSetReferenceKey,
    phiSetDocumentId,
    readings,
    cgmReadings,
    importData,
    importId,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const setNotes = (phiSet, phiSetDocumentId, notes, notesBatchesIndex = []) => ({
  type   : actionTypes.SET_NOTES,
  payload: {
    phiSet,
    phiSetDocumentId,
    notes,
    notesBatchesIndex,
  },
});


export const clearNotesBatches = () => ({
  type: actionTypes.CLEAR_NOTES_BATCHES,
});

//----------------------------------------------------------------------------------------------------------------------

export const setBeingApprovedSharingRequestId = (beingApprovedSharingRequestId) => ({
  type   : actionTypes.SET_BEING_APPROVED_SHARING_REQUEST_ID,
  payload: {
    beingApprovedSharingRequestId,
  },
});

export const unsetBeingApprovedSharingRequestId = () => ({
  type: actionTypes.UNSET_BEING_APPROVED_SHARING_REQUEST_ID,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchSharingRequests = () => ({
  type: actionTypes.FETCH_SHARING_REQUESTS,
});

export const fetchSharingRequestsSuccess = (sharingRequests) => ({
  type   : actionTypes.FETCH_SHARING_REQUESTS_SUCCESS,
  payload: {
    sharingRequests,
  },
});

export const fetchSharingRequestsError = () => ({
  type: actionTypes.FETCH_SHARING_REQUESTS_ERROR,
});

//----------------------------------------------------------------------------------------------------------------------

export const enrollInClinic = (enrollCode, invitationCode = null) => ({
  type   : actionTypes.ENROLL_IN_CLINIC,
  payload: {
    enrollCode,
    invitationCode,
  },
});

export const enrollInClinicSuccess = () => ({
  type: actionTypes.ENROLL_IN_CLINIC_SUCCESS,
});

export const enrollInClinicError = (error, sharingRequest) => ({
  type   : actionTypes.ENROLL_IN_CLINIC_ERROR,
  payload: {
    sharingRequest,
  },
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const approveSharingRequest = (
  sharingRequest,
  patientProfile,
  phiSet,
) => ({
  type   : actionTypes.APPROVE_SHARING_REQUEST,
  payload: {
    sharingRequest,
    patientProfile,
    phiSet,
  },
});

export const approveSharingRequestSuccess = (sharingRequest) => ({
  type   : actionTypes.APPROVE_SHARING_REQUEST_SUCCESS,
  payload: {
    sharingRequest,
  },
});

export const approveSharingRequestError = (error, sharingRequest) => ({
  type   : actionTypes.APPROVE_SHARING_REQUEST_ERROR,
  payload: {
    sharingRequest,
  },
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const revokeSharingRequest = (sharingRequest) => ({
  type   : actionTypes.REVOKE_SHARING_REQUEST,
  payload: {
    sharingRequest,
  },
});

export const revokeSharingRequestSuccess = (sharingRequest) => ({
  type   : actionTypes.REVOKE_SHARING_REQUEST_SUCCESS,
  payload: {
    sharingRequest,
  },
});

export const revokeSharingRequestError = () => ({
  type: actionTypes.REVOKE_SHARING_REQUEST_ERROR,
});

//----------------------------------------------------------------------------------------------------------------------

export const inviteFamilyMembers = (invitees = []) => ({
  type   : actionTypes.INVITE_FAMILY_MEMBERS,
  payload: {
    invitees,
  },
});

export const inviteFamilyMembersSuccess = () => ({
  type: actionTypes.INVITE_FAMILY_MEMBERS_SUCCESS,
});

export const inviteFamilyMembersError = (error) => ({
  type: actionTypes.INVITE_FAMILY_MEMBERS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchFamilyLinks = () => ({
  type: actionTypes.FETCH_FAMILY_SHARING_LINKS,
});

export const fetchFamilyLinksSuccess = (familyLinkInvitations, familyLinkRequests) => ({
  type   : actionTypes.FETCH_FAMILY_SHARING_LINKS_SUCCESS,
  payload: {
    familyLinkInvitations,
    familyLinkRequests,
  },
});

export const fetchFamilyLinksError = (error) => ({
  type: actionTypes.FETCH_FAMILY_SHARING_LINKS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchFamilyLinkRequests = () => ({
  type: actionTypes.FETCH_FAMILY_SHARING_LINK_REQUESTS,
});

export const fetchFamilyLinkRequestsSuccess = (familyLinkRequests) => ({
  type   : actionTypes.FETCH_FAMILY_SHARING_LINK_REQUESTS_SUCCESS,
  payload: {
    familyLinkRequests,
  },
});

export const fetchFamilyLinkRequestsError = (error) => ({
  type: actionTypes.FETCH_FAMILY_SHARING_LINK_REQUESTS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const revokeFamilyAccess = (familyLink) => ({
  type   : actionTypes.REVOKE_FAMILY_ACCESS,
  payload: {
    familyLink,
  },
});

export const revokeFamilyAccessSuccess = (familyLink) => ({
  type   : actionTypes.REVOKE_FAMILY_ACCESS_SUCCESS,
  payload: {
    familyLink,
  },
});

export const revokeFamilyAccessError = (error) => ({
  type: actionTypes.REVOKE_FAMILY_ACCESS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const activateFamilyMember = (familyLink) => ({
  type   : actionTypes.ACTIVATE_FAMILY_MEMBER,
  payload: {
    familyLink,
  },
});

export const activateFamilyMemberSuccess = (activeFamilyLink) => ({
  type   : actionTypes.ACTIVATE_FAMILY_MEMBER_SUCCESS,
  payload: {
    activeFamilyLink,
  },
});

export const activateFamilyMemberError = (error) => ({
  type: actionTypes.ACTIVATE_FAMILY_MEMBER_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const deactivateFamilyMember = () => ({
  type: actionTypes.DEACTIVATE_FAMILY_MEMBER,
});

//----------------------------------------------------------------------------------------------------------------------

export const sync = (patientProfile, passphrase) => ({
  type   : actionTypes.SYNC,
  payload: {
    patientProfile,
    passphrase,
  },
});

export const syncSuccess = () => ({
  type: actionTypes.SYNC_SUCCESS,
});

export const syncError = (error) => ({
  type: actionTypes.SYNC_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const storeNotes = (
  notes,
  phiSet,
  phiSetDocumentId,
  phisetVisitId,
  patientProfile,
  sharingRequest,
  visitMetadata,
) => ({
  type   : actionTypes.STORE_NOTES,
  payload: {
    notes,
    phiSet,
    phiSetDocumentId,
    phisetVisitId,
    patientProfile,
    sharingRequest,
    visitMetadata,
  },
});

export const storeNotesSuccess = () => ({
  type: actionTypes.STORE_NOTES_SUCCESS,
});

export const storeNotesError = (error) => ({
  type: actionTypes.STORE_NOTES_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const onStoreReadingsSuccess = (
  updatedPhiSet, newPhiSetDocumentId, phiSetReferenceKey, readings, importData, importId,
) => ({
  type   : actionTypes.ON_STORE_READINGS_SUCCESS,
  payload: {
    importData,
    updatedPhiSet,
    newPhiSetDocumentId,
    phiSetReferenceKey,
    readings,
    importId,
  },
});

export const onStoreReadingsSuccessSuccess = () => ({
  type: actionTypes.ON_STORE_READINGS_SUCCESS_SUCCESS,
});

export const onStoreReadingsSuccessError = (error) => ({
  type: actionTypes.ON_STORE_READINGS_SUCCESS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------
// TODO: Remove if confirmed

// export const onStoreMeasurementsSuccess = (phiSet = null, phiSetDocumentId = null) => ({
//   type   : actionTypes.ON_STORE_MEASUREMENT_SUCCESS,
//   payload: {
//     phiSet,
//     phiSetDocumentId,
//   },
// });
//
// export const onStoreMeasurementsSuccessSuccess = () => ({
//   type: actionTypes.ON_STORE_MEASUREMENT_SUCCESS_SUCCESS,
// });
//
// export const onStoreMeasurementsSuccessError = (error) => ({
//   type: actionTypes.ON_STORE_MEASUREMENT_SUCCESS_ERROR,
//   error,
// });

//----------------------------------------------------------------------------------------------------------------------

export const setImportedRelatedData = (phiSet, phiSetDocumentId, phiSetReferenceKey, relatedData) => ({
  type   : actionTypes.SET_IMPORTED_RELATED_DATA,
  payload: {
    phiSet,
    phiSetDocumentId,
    phiSetReferenceKey,
    relatedData,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const externalDataSourcesMigration = () => ({
  type: actionTypes.EXTERNAL_DATA_SOURCES_MIGRATION,
});

//----------------------------------------------------------------------------------------------------------------------

export const reauthContourCloud = (code, referenceId) => ({
  type   : actionTypes.REAUTH_CONTOUR_CLOUD,
  payload: {
    code,
    referenceId,
  },
});

export const reauthContourCloudSuccess = () => ({
  type: actionTypes.REAUTH_CONTOUR_CLOUD_SUCCESS,
});

export const reauthContourCloudError = (error) => ({
  type: actionTypes.REAUTH_CONTOUR_CLOUD_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const migrationSharingRequest = () => ({
  type: actionTypes.MIGRATION_SHARING_REQUEST,
});

export const migrationSharingRequestSuccess = () => ({
  type: actionTypes.MIGRATION_SHARING_REQUEST_SUCCESS,
});

export const migrationSharingRequestError = (error) => ({
  type: actionTypes.MIGRATION_SHARING_REQUEST_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------
