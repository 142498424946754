import React from 'react';
import PropTypes from 'prop-types';
import Meter from 'modules/Meter';


class Initializing extends React.PureComponent {

  static propTypes = {
    // Explicit props
    subComponent  : PropTypes.string,
    // Explicit actions
    onSetComponent: PropTypes.func,
  };


  render() {
    return (
      <Meter.partials.MdaInit
        initComponent={this.props.subComponent}
        onInitialized={() => this.props.onSetComponent('ChooseDatabase')}
      />
    );
  }

}


export default Initializing;
