import findIndex from 'lodash/findIndex';
import sortBy from 'lodash/sortBy';
import {
  registerAction,
  unregisterAction,
} from 'helpers/reducerTools';
import * as actionTypes from './actionTypes';


const initialState = {
  activeVisit         : null,
  activeVisitPatientId: null,
  visits              : [],
  fetching            : [],
  sending             : [],
  errors              : [],
};


export default function reducer(state = { ...initialState }, action) {

  switch (action.type) {

    case actionTypes.ADD_VISIT: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.ADD_VISIT),
        errors : unregisterAction(state.errors, actionTypes.ADD_VISIT),
      };
    }

    case actionTypes.ADD_VISIT_SUCCESS: {
      const { visit, isInactive, patientId } = action.payload;
      if (isInactive) {
        return {
          ...state,
          sending: unregisterAction(state.sending, actionTypes.ADD_VISIT),
        };
      }
      return {
        ...state,
        activeVisit         : visit,
        activeVisitPatientId: patientId,
        sending             : unregisterAction(state.sending, actionTypes.ADD_VISIT),
      };
    }

    case actionTypes.ADD_VISIT_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.ADD_VISIT),
        errors : registerAction(state.errors, actionTypes.ADD_VISIT),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.RESUME_VISIT: {
      const { visit, patientId } = action.payload;
      return {
        ...state,
        activeVisit         : visit,
        activeVisitPatientId: patientId,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.END_VISIT: {
      const { visitNotes } = action.payload;
      if (!visitNotes || !visitNotes.length) {
        return {
          ...state,
          activeVisit         : null,
          activeVisitPatientId: null,
          sending             : registerAction(state.sending, actionTypes.END_VISIT),
          errors              : unregisterAction(state.errors, actionTypes.END_VISIT),
        };
      }
      const activeVisit = { ...state.activeVisit };
      const visits = [...state.visits];
      if (activeVisit) {
        const idx = findIndex(visits, { phisetVisitId: activeVisit.phisetVisitId });
        if (idx < 0) {
          visits.push(activeVisit);
        } else {
          visits[idx] = activeVisit;
        }
      }
      return {
        ...state,
        activeVisit         : null,
        activeVisitPatientId: null,
        visits,
        sending             : registerAction(state.sending, actionTypes.END_VISIT),
        errors              : unregisterAction(state.errors, actionTypes.END_VISIT),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_VISIT_METADATA: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_VISIT_METADATA),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_VISIT_METADATA),
      };
    }

    case actionTypes.FETCH_VISIT_METADATA_SUCCESS: {
      const { visit } = action.payload;
      const activeVisit = visit.phisetVisitId === state.activeVisit.phisetVisitId ? visit : state.activeVisit;
      return {
        ...state,
        activeVisit,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_VISIT_METADATA),
      };
    }

    case actionTypes.FETCH_VISIT_METADATA_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_VISIT_METADATA),
        errors  : registerAction(state.errors, actionTypes.FETCH_VISIT_METADATA),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_VISITS_METADATA: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_VISIT_METADATA),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_VISIT_METADATA),
      };
    }

    case actionTypes.FETCH_VISITS_METADATA_SUCCESS: {
      const visits = sortBy([...state.visits, ...action.payload.visits], (v) => v.metadata.visitDate);
      return {
        ...state,
        visits,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_VISIT_METADATA),
      };
    }

    case actionTypes.FETCH_VISITS_METADATA_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_VISIT_METADATA),
        errors  : registerAction(state.errors, actionTypes.FETCH_VISIT_METADATA),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.CLEAR_VISITS: {
      return {
        ...state,
        visits: [],
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    default: {
      return state;
    }

  }
}
