import * as actionTypes from './actionTypes';


export const sendStatistics = (patientProfile, phiSet, importData, standards, passphrase) => ({
  type   : actionTypes.SEND_STATISTICS,
  payload: {
    patientProfile,
    phiSet,
    importData,
    standards,
    passphrase,
  },
});

export const sendStatisticsSuccess = () => ({
  type: actionTypes.SEND_STATISTICS_SUCCESS,
});

export const sendStatisticsError = (error) => ({
  type: actionTypes.SEND_STATISTICS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const sendStatisticsForClinic = (patientProfile, phiSet, importData, standards, clinicMembership) => ({
  type   : actionTypes.SEND_STATISTICS_FOR_CLINIC,
  payload: {
    patientProfile,
    phiSet,
    importData,
    standards,
    clinicMembership,
  },
});

export const sendStatisticsForClinicSuccess = () => ({
  type: actionTypes.SEND_STATISTICS_FOR_CLINIC_SUCCESS,
});

export const sendStatisticsForClinicError = (error) => ({
  type: actionTypes.SEND_STATISTICS_FOR_CLINIC_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const deleteStatisticsFromClinic = (phiSet, clinicMembership) => ({
  type   : actionTypes.DELETE_STATISTICS_FROM_CLINIC,
  payload: {
    phiSet,
    clinicMembership,
  },
});

export const deleteStatisticsFromClinicSuccess = () => ({
  type: actionTypes.DELETE_STATISTICS_FROM_CLINIC_SUCCESS,
});

export const deleteStatisticsFromClinicError = (error) => ({
  type: actionTypes.DELETE_STATISTICS_FROM_CLINIC_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const linkPatientToStatisticalDb = (patient, phiSet, clinicMembership, sharingRequest) => ({
  type   : actionTypes.LINK_PATIENT_PROFILE_TO_STATISTICAL_DB,
  payload: {
    patient, phiSet, clinicMembership, sharingRequest,
  },
});

export const linkPatientToStatisticalDbSuccess = () => ({
  type: actionTypes.LINK_PATIENT_PROFILE_TO_STATISTICAL_DB_SUCCESS,
});

export const linkPatientToStatisticalDbError = (error) => ({
  type: actionTypes.LINK_PATIENT_PROFILE_TO_STATISTICAL_DB_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchClinicKpiStatistics = ({ clinicId, count, skip, type, sort, from }) => ({
  type   : actionTypes.FETCH_CLINIC_KPI_STATISTICS,
  payload: {
    clinicId, count, skip, type, sort, from,
  },
});

export const fetchClinicKpiStatisticsSuccess = (clinicKpiStatistics, kpiSkip, totalKpi) => ({
  type   : actionTypes.FETCH_CLINIC_KPI_STATISTICS_SUCCESS,
  payload: {
    clinicKpiStatistics,
    kpiSkip,
    totalKpi,
  },
});

export const fetchClinicKpiStatisticsError = (error) => ({
  type: actionTypes.FETCH_CLINIC_KPI_STATISTICS_ERROR,
  error,
});
