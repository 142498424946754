import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import get from 'lodash/get';
import { ProfileContext } from 'context';
import { getSlug } from 'helpers/urlTools';
import { useAction } from 'hooks';
import Button from 'components/Form/Button';
import Account from 'modules/Account';
import Hcp from 'modules/Hcp';
import { PatientProfile } from '../../../../types';
import * as selectors from '../../../../selectors';
import { useIsDisabled } from '../../../../hooks';
import messages from '../../../../messages';
import styles from '../../DownloadDataModal.pcss';
import { useIsProfileActivationInProgress } from '../hooks';
import ResultsTableSegment from '../ResultsTableSegment';
import { useStoreReadings } from './hooks';


interface Props {
  onCancel: () => void,
}


const Summary: FC = () => {
  const deviceData = useSelector(selectors.deviceData);
  return (
    <div className="row no-gutters align-items-center mt-6">
      <div className="col p-6 mr-6">
        <h3 className="text--small text--bold mb-3">{ deviceData.deviceName }</h3>
        <p className="text--small text--light text--nowrap">SN: { deviceData.serialNumber }</p>
      </div>
      <div className={`col-auto p-6 ${styles.highlightedRegion}`}>
        <p className="row no-gutters align-items-center">
          <span className="col mr-4"><FormattedMessage {...messages.labels.readingsNumber} /></span>
          <span className="col-auto text--large text--bold">{ deviceData.summary.readingsCount }</span>
        </p>
      </div>
    </div>
  );
};


const TargetGlucoseCount = () => {
  const deviceData = useSelector(selectors.deviceData);
  const lowIcon = <i className={`bg--error ${styles.results__disc}`} />;
  const targetIcon = <i className={`bg--success ${styles.results__disc}`} />;
  const highIcon = <i className={`bg--warning ${styles.results__disc}`} />;
  return (
    <div className={styles.strippedTable}>
      <ResultsTableSegment labelMessage={messages.labels.low} value={deviceData.summary.lowCount} icon={lowIcon} />
      <ResultsTableSegment labelMessage={messages.labels.target} value={deviceData.summary.targetCount} icon={targetIcon} />
      <ResultsTableSegment labelMessage={messages.labels.high} value={deviceData.summary.highCount} icon={highIcon} />
    </div>
  );
};


const ResultsCount: FC = () => {
  const deviceData = useSelector(selectors.deviceData);
  if (!deviceData.summary.highNorm || !deviceData.summary.lowNorm) {
    return null;
  }
  return (
    <div className={`${styles.strippedTable} mt-6`}>
      <ResultsTableSegment
        labelMessage={messages.labels.bgm}
        value={deviceData.summary.readingsCount}
        SubSegment={TargetGlucoseCount}
      />
    </div>
  );
};


const PreviewData: FC = () => {
  const activeProfileType: ProfileType = useSelector(Account.selectors.activeProfileType);
  const isHcp = activeProfileType === Account.constants.PROFILE_TYPES.HCP;
  if (!isHcp) {
    return null;
  }

  const deviceData = useSelector(selectors.deviceData);
  const activeClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const { organizationUID, name: clinicName } = get(activeClinicMembership, 'clinic', {});
  const clinicSlug = getSlug(clinicName);
  const onPreviewPatient = useAction(Hcp.actions.previewPatientOpenTab, deviceData, clinicSlug, organizationUID);

  return (
    <>
      <div className={cn(styles.divider, 'mt-0')}>
        <span className={styles.divider__text}><FormattedMessage {...messages.infos.or} /></span>
      </div>
      <div className="row justify-content-center">
        <Button
          styleModifier="transparent"
          labelMessage={messages.buttons.previewResults}
          className="btn--no-size text--primary m-0 pl-2 pr-2"
          onClick={onPreviewPatient}
        />
      </div>
    </>
  );
};


const Actions: FC<Props> = ({ onCancel }) => {
  const profileContext = useContext(ProfileContext);
  const deviceData = useSelector(selectors.deviceData);
  const patientProfile: PatientProfile = useSelector(profileContext.selectors.activePatient);
  const isProfileActivationInProgress = useIsProfileActivationInProgress();
  const isDisabled = useIsDisabled(patientProfile, isProfileActivationInProgress);
  const { isInProgress, onImport } = useStoreReadings(patientProfile, onCancel);

  return (
    <div>
      <div className="row mt-5">
        <div className="col-6">
          <Button
            styleModifier="primary"
            labelMessage={messages.buttons.cancelImport}
            className="btn--block"
            isDisabled={isInProgress}
            onClick={onCancel}
          />
        </div>
        <div className="col-6">
          <Button
            styleModifier="primary"
            labelMessage={messages.buttons.importResults}
            className="btn--block btn--filled"
            isDisabled={isDisabled || !deviceData.summary.readingsCount}
            isInProgress={isInProgress}
            onClick={onImport}
          />
        </div>
      </div>
      <PreviewData />
    </div>
  );
};


const BgmResults: FC<Props> = (props) => (
  <div>
    <Summary />
    <ResultsCount />
    <Actions {...props} />
  </div>
);

export default BgmResults;
