// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".vq2nP{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;min-height:100vh;width:100%;overflow-x:hidden}@media (min-width:1024px){.vq2nP{-ms-flex-direction:row;flex-direction:row}}.vq2nP svg path{vector-effect:non-scaling-stroke}._2HlEi{height:70px;height:7rem;width:100%;background:#872c93;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:start;justify-content:flex-start}._3Uk7e{width:130px;margin:0 2rem}._3UMat{-ms-flex:1 1 auto;flex:1 1 auto;height:100%}.R0OZo{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;height:100%}@media (min-width:1024px){.R0OZo{-ms-flex-align:center;align-items:center}}.R0OZo a{font-weight:700}.R0OZo .content{padding:3.5rem 2rem 10rem;font-size:14px;font-size:1.4rem;width:100%;max-width:640px;max-width:64rem}@media (min-width:600px){.R0OZo .content{padding:3.5rem 0 10rem;width:80%}}@media (min-width:1024px){.R0OZo .content{padding:5rem 0 10rem;width:62%}}@media (min-width:1280px){.R0OZo .content{width:50%}}@media (min-width:1280px){.R0OZo .content--fixed{width:115.6rem!important}}.R0OZo .pageHeader{margin-bottom:6rem}.R0OZo .pageHeader__intro{font-size:16px;font-size:1.6rem;color:#6f7ea2;line-height:1.2;padding-top:1rem}.R0OZo .content--fixed{max-width:none!important}@media (min-width:1024px){.R0OZo .content--fixed{width:100rem!important}}@media (min-width:1280px){.R0OZo .content--fixed{width:110rem!important}}", ""]);
// Exports
exports.locals = {
	"root": "vq2nP",
	"mobileLogo": "_2HlEi",
	"mobileLogo__logo": "_3Uk7e",
	"container": "_3UMat",
	"containerInner": "R0OZo"
};
module.exports = exports;
