import { defineMessages } from 'react-intl';


export default defineMessages({

  buttons: {
    exportData                : { id: 'hcpExports.buttons.exportData' },
    patientsBloodGlucoseExport: { id: 'hcpExports.buttons.patientsBloodGlucoseExport' },
  },

  labels: {
    id          : { id: 'hcpExports.labels.id' },
    firstName   : { id: 'hcpExports.labels.firstName' },
    lastName    : { id: 'hcpExports.labels.lastName' },
    dateOfBirth : { id: 'hcpExports.labels.dateOfBirth' },
    datetime    : { id: 'hcpExports.labels.datetime' },
    readingValue: { id: 'hcpExports.labels.readingValue' },
    carbs       : { id: 'hcpExports.labels.carbs' },
    insulinFast : { id: 'hcpExports.labels.insulinFast' },
    insulinLong : { id: 'hcpExports.labels.insulinLong' },
    mealMarker  : { id: 'hcpExports.labels.mealMarker' },
  },

  infos: {
    exportDataModal: { id: 'hcpExports.infos.exportDataModal' },
  },

});
