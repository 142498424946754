export default {
  headers: {
    availablePlatforms : { id: 'dataSources.headers.availablePlatforms' },
    dataSourcesSettings: { id: 'dataSources.headers.dataSourcesSettings' },
  },

  buttons: {
    connect   : { id: 'dataSources.buttons.connect' },
    disconnect: { id: 'dataSources.buttons.disconnect' },
    tryAgain  : { id: 'dataSources.buttons.tryAgain' },
  },

  tags: {
    connected   : { id: 'dataSources.tags.connected' },
    notConnected: { id: 'dataSources.tags.notConnected' },
    get         : { id: 'dataSources.tags.get' },
    send        : { id: 'dataSources.tags.send' },
    getAndSend  : { id: 'dataSources.tags.getAndSend' },
  },

  intros: {
    dataSourcesSettings: { id: 'dataSources.intros.dataSourcesSettings' },
  },

  dataSourcesInfos: {
    ContourCloudDefault: { id: 'dataSources.dataSourcesInfos.contourCloudDefault' },
    ContourCloud       : { id: 'dataSources.dataSourcesInfos.contourCloud' },
  },

  errors: {
    connectError   : { id: 'dataSources.errors.connectError' },
    disconnectError: { id: 'dataSources.errors.disconnectError' },
    fetchData      : { id: 'dataSources.errors.fetchData' },
    businessErrors : {
      CannotGetMeterDataAssosciatedWithReading: { id: 'dataSources.errors.businessErrors.CannotGetMeterDataAssosciatedWithReading' },
      UnableToLoginToContourCloud             : { id: 'dataSources.errors.businessErrors.UnableToLoginToContourCloud' },
    },
  },
};
