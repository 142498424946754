import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import AmbulatoryGlucoseProfile from 'modules/AmbulatoryGlucoseProfile';
import Widget from 'components/Widget';
import TimeInRangesChart from 'components/Charts/TimeInRangesChart';
import messages from '../../messages';
import * as constants from '../../constants';
import styles from './CgmDistributionWidget.pcss';


class CgmDistributionWidget extends React.Component {

  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    // Implicit props
    statistics: PropTypes.shape({
      veryHigh: PropTypes.number,
      high    : PropTypes.number,
      target  : PropTypes.number,
      low     : PropTypes.number,
      veryLow : PropTypes.number,
    }),
    isDisplayedGlucoseStatistics: PropTypes.bool,
    isDisplayedTimeInRanges     : PropTypes.bool,
  };


  render() {
    const { conversion, standards, statistics, isDisplayedGlucoseStatistics, isDisplayedTimeInRanges } = this.props;

    if (!isDisplayedGlucoseStatistics && !isDisplayedGlucoseStatistics) {
      return null;
    }

    return (
      <div className={styles.cgmDistributionWidget}>
        <Widget
          headerMessage={messages.headers.glucoseStatisticAndTarget}
        >
          {isDisplayedGlucoseStatistics
            && (
              <AmbulatoryGlucoseProfile.components.Statistics
                conversion={conversion}
                standards={standards}
              />
            )
          }
        </Widget>
        <Widget
          headerMessage={messages.headers.timeInRanges}
        >
          {isDisplayedTimeInRanges
            && (
              <TimeInRangesChart
                conversion={this.props.conversion}
                standards={this.props.standards}
                timeInRanges={[
                  {
                    name   : 'veryHigh',
                    minutes: statistics.veryHigh * 1440,
                    percent: Math.round(statistics.veryHigh * 100),
                    range  : `<${conversion.toDisplay(250)}`,
                  },
                  {
                    name   : 'high',
                    minutes: statistics.high * 1440,
                    percent: Math.round(statistics.high * 100),
                    range  : `>${conversion.toDisplay(standards.postMeal.highThreshold)}`,
                  },
                  {
                    name   : 'normal',
                    minutes: statistics.target * 1440,
                    percent: Math.round(statistics.target * 100),
                    range  : `${conversion.toDisplay(standards.postMeal.lowThreshold)} - ${
                      conversion.toDisplay(standards.postMeal.highThreshold)}`,
                  },
                  {
                    name   : 'low',
                    minutes: statistics.low * 1440,
                    percent: Math.round(statistics.low * 100),
                    range  : `<${conversion.toDisplay(standards.postMeal.lowThreshold)}`,
                  },
                  {
                    name   : 'veryLow',
                    minutes: statistics.veryLow * 1440,
                    percent: Math.round(statistics.veryLow * 100),
                    range  : `<${conversion.toDisplay(54)}`,
                  },
                ]}
              />
            )
          }
        </Widget>
      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  statistics: AmbulatoryGlucoseProfile.selectors.highlightedStatisticsFactory(
    constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES
  )(state),
  isDisplayedGlucoseStatistics: AmbulatoryGlucoseProfile.selectors.isDisplayedGlucoseStatistics(state),
  isDisplayedTimeInRanges     : AmbulatoryGlucoseProfile.selectors.isDisplayedTimeInRanges(state),
});


const ConnectedCgmDistributionWidget = connect(
  mapStateToProps,
)(CgmDistributionWidget);

export default withStyles(styles)(ConnectedCgmDistributionWidget);
