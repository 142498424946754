import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { createKPIData } from 'helpers/kpi';
import AmbulatoryGlucoseProfile from 'modules/AmbulatoryGlucoseProfile';
import HeaderSection from './HeaderSection';
import StatisticsSection from './StatisticsSection';
import TimeInRangesSection from './TimeInRangesSection';
import styles from './PrintableAgpBgmReport.pcss';
import AgpSection from './AgpSection';
import DailyGlucoseProfilesSection from './DailyGlucoseProfilesSection';


class PrintableAgpBgmReport extends React.Component {


  static propTypes = {
    // Explicit props
    firstName : PropTypes.string,
    lastName  : PropTypes.string,
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    readings              : PropTypes.array,
    preMealReadings       : PropTypes.array,
    postMealReadings      : PropTypes.array,
    strictPreMealReadings : PropTypes.array,
    strictPostMealReadings: PropTypes.array,
    daysWithReadings      : PropTypes.number,
    daysWithoutReadings   : PropTypes.number,
  };

  constructor(props) {
    super(props);

    const {
      readings, preMealReadings, postMealReadings, strictPreMealReadings,
      strictPostMealReadings, standards, conversion,
    } = props;
    this.data = createKPIData({
      readings,
      strictPreMealReadings,
      strictPostMealReadings,
      preMealReadings,
      postMealReadings,
      standards,
      conversion,
    });
  }


  render() {
    const { conversion, standards, firstName, lastName, daysWithReadings, daysWithoutReadings } = this.props;
    const {
      CV,
      readingsAverage,
      strictPreMealAverage,
      strictPostMealAverage,
      strictPreMealCount,
      strictPostMealCount,
      maxValue,
      minValue,
      readingsCount,
    } = this.data;

    return (
      <>
        <div className={styles.printableAgpReport}>
          <div className="page">
            <HeaderSection
              firstName={firstName}
              lastName={lastName}
            />
            <StatisticsSection
              conversion={conversion}
              standards={standards}
              readingsCount={readingsCount}
              readingsPerDay={(daysWithReadings + daysWithoutReadings) === 0
                ? 0 : (readingsCount / (daysWithReadings + daysWithoutReadings)).toFixed(1)}
              readingsAverage={readingsAverage}
              preMealCount={strictPreMealCount}
              postMealCount={strictPostMealCount}
              preMealAverage={strictPreMealAverage}
              postMealAverage={strictPostMealAverage}
              maxValue={maxValue}
              minValue={minValue}
              CV={CV}
            />
            <TimeInRangesSection
              conversion={conversion}
              standards={standards}
            />
            <AgpSection
              conversion={conversion}
              standards={standards}
              readingsCount={readingsCount}
              maxValue={maxValue}
            />
            <DailyGlucoseProfilesSection conversion={conversion} standards={standards} maxValue={maxValue} />
            <div className={styles.footer}>
              {AmbulatoryGlucoseProfile.constants.BGM_REPORT_FOOTER}
              <img src="/assets/img/capturAgp.png" alt="capturAgp" />
            </div>
          </div>
        </div>
      </>

    );
  }

}


const mapStateToProps = (state) => ({
  isDisplayedAgpProfileChart  : AmbulatoryGlucoseProfile.selectors.isDisplayedAgpProfileChart(state),
  isDisplayedTimeInRanges     : AmbulatoryGlucoseProfile.selectors.isDisplayedTimeInRanges(state),
  isDisplayedGlucoseStatistics: AmbulatoryGlucoseProfile.selectors.isDisplayedGlucoseStatistics(state),
});


const ConnectedPrintableAgpBgmReport = connect(
  mapStateToProps,
)(PrintableAgpBgmReport);

export default withStyles(styles)(ConnectedPrintableAgpBgmReport);
