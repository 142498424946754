import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './ProgressRing.pcss';


class ProgressRing extends React.Component {

  static propTypes = {
    radius      : PropTypes.number,
    stroke      : PropTypes.number,
    progress    : PropTypes.number,
    isInProgress: PropTypes.bool,
    StartIcon   : PropTypes.func,
  };


  constructor(props) {
    super(props);
    const { radius, stroke } = props;
    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }


  renderProgressInfo(radius, floorProgress) {
    const { progress, isInProgress, StartIcon } = this.props;
    if (progress === 0 && !isInProgress && StartIcon) {
      return <StartIcon x="90" y="90" className={styles.startIcon} />;
    }
    if (progress === 100) {
      return null;
    }
    return (
      <text
        className={styles.percentText}
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={Math.floor(radius / 2.6)}
        x={radius}
        y={radius}
      >
        {`${floorProgress}%`}
      </text>
    );
  }


  renderTick(radius, stroke) {
    // 86,118 111,142 153, 95
    const strokeDashoffset = this.props.progress === 100 ? 0 : radius;
    const points = [
      `${radius * 0.717},${radius * 0.983}`,
      `${radius * 0.925},${radius * 1.183}`,
      `${radius * 1.275},${radius * 0.79}`,
    ];
    return (
      <polyline
        className={styles.tick}
        strokeDasharray={`${radius} ${radius}`}
        style={{ strokeDashoffset }}
        strokeWidth={stroke * 0.66}
        points={points.join(' ')}
      />
    );
  }


  render() {
    const { radius, stroke, progress } = this.props;
    const floorProgress = Math.floor(progress);
    const strokeDashoffset = this.circumference - floorProgress / 100 * this.circumference;

    return (
      <svg
        height={radius * 2}
        width={radius * 2}
      >
        <circle
          className={styles.backgroundCircle}
          strokeWidth={stroke * 2.6}
          r={this.normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          className={styles.pathCircle}
          strokeWidth={stroke}
          r={this.normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          className={styles.progressCircle}
          strokeWidth={stroke}
          strokeDasharray={`${this.circumference} ${this.circumference}`}
          style={{ strokeDashoffset }}
          r={this.normalizedRadius}
          cx={radius}
          cy={radius}
        />
        { this.renderProgressInfo(radius, floorProgress) }
        { this.renderTick(radius, stroke)}
      </svg>
    );
  }

}

export default withStyles(styles)(ProgressRing);
