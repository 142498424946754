export const MODULE_ID = 'app';

export const META_COMPONENT_NAME = 'GCO';

export const SIGNALR_MODULE_ID = 'app-signalR';
export const SIGNALR_HUB_NAME = 'metaBroadcastHub';
export const SIGNALR_NOTIFICATION_RECEIVE_MSG = 'EventReceived';

export const TRANSLATIONS_LOCATIONS = [
  'components/Charts',
  'components/Form',
  'components/RelatedData',
  'modules/App',
  'modules/Account',
  'modules/AmbulatoryGlucoseProfile',
  'modules/ClinicManagement',
  'modules/CloudDrive',
  'modules/DataSources',
  'modules/Hcp',
  'modules/HcpExports',
  'modules/HcpStats',
  'modules/Layout',
  'modules/Meter',
  'modules/Notifications',
  'modules/Patient',
  'modules/PatientResults',
  'modules/Visit',
];

export const CONTACT_EMAIL = 'support@glucocontro.online';

export const CLOUD_DRIVES = [
  'CentralStorage',
  'GoogleDrive',
];

export const CLOUD_DRIVES_LABELS = {
  CentralStorage: 'Central Storage',
  GoogleDrive   : 'Google Drive',
};

export const PATIENT_DATABASES = {
  GlucoControDesktop: { label: 'GlucoContro Desktop', api: 'glucocontro', type: 'GCD' },
  GlucoFactsDelux   : { label: 'Glucofacts® Deluxe', api: 'glucofacts', type: 'GFD' },
  GlucoControOnline : { label: 'GlucoContro Online', type: 'GCO' },
};

export const WILDCARD_DEVICE_SERIAL_NUMBER_TOKENS = [
  'glucocontrodesktop',
];

export const PATIENTS_SOURCES = {
  ENROLL_CODE         : 'EnrollCode',
  GLUCO_CONTRO_DESKTOP: 'GlucoControDesktop',
};

export const DIABETES_TYPES = [
  'Type1', 'Type2', 'Type3', 'PreDiabetes', 'GDM', 'NotSure', 'NoResponse', 'NotSet',
] as const;
export const DIABETES_TYPES_HIDE_FOR_PWD = ['Type3', 'GDM', 'NoResponse', 'NotSet'];
export const DIABETES_TYPES_HIDE_FOR_HCP = ['Type3', 'NoResponse', 'NotSet'];
export const DIABETES_TYPES_HIDE_FOR_MALE = ['GDM'];
export const TREATMENT_TYPES = ['Lifestyle', 'Oral', 'Combination', 'Insulin'] as const;

export const DATA_NOTE_TYPES = [
  // KPI
  'TotalReadings',
  'AvgReadings',
  'DaysWithoutReading',
  'GMI',
  'VeryHigh',
  'VeryLow',
  'StandardDeviation',
  'Average',
  'AveragePreMeal',
  'AveragePostMeal',
  'CV',
  // Trend Chart
  'Reading',
  'Mean',
  'MedianStd',
  'Hypoglycemia',
  'Hyperglycemia',
  'Count',
  // Glucose concentration level chart
  'GlucoseConcentrationLevelVeryHigh',
  'GlucoseConcentrationLevelHigh',
  'GlucoseConcentrationLevelTarget',
  'GlucoseConcentrationLevelLow',
  'GlucoseConcentrationLevelVeryLow',
];

export const GENERAL_NOTE_TYPES = [
  'Note',
  'Instruction',
  'PhysicalExamination',
  'Recommendation',
  'Comment',
];

export const NOTE_TYPES = [
  ...DATA_NOTE_TYPES,
  ...GENERAL_NOTE_TYPES,
];

export const LANGUAGE_SELECTOR_DROPDOWN = `${MODULE_ID}/languageSelectorDropdown`;

export const MEASUREMENTS = {
  weight                          : { type: 'Weight', metric: 'weight' },
  height                          : { type: 'Height', metric: 'height' },
  lastWeight                      : { type: 'Weight', metric: 'weight' },
  lastHeight                      : { type: 'Height', metric: 'height' },
  systolicBloodPressure           : { type: 'SystolicBloodPressure', metric: 'pressure' },
  diastolicBloodPressure          : { type: 'DiastolicBloodPressure', metric: 'pressure' },
  randomBloodGlucoseLevel         : { type: 'RandomBloodGlucoseLevel', metric: 'bloodGlucoseConcentration' },
  totalCholesterol                : { type: 'TotalCholesterol', metric: 'cholesterol' },
  HDL                             : { type: 'HDL', metric: 'cholesterol' },
  LDL                             : { type: 'LDL', metric: 'cholesterol' },
  calcium                         : { type: 'Calcium', metric: 'calcium' },
  creatinine                      : { type: 'Creatinine', metric: 'creatinine' },
  HbA1C                           : { type: 'HbA1C', metric: 'HbA1C' },
  phosphorus                      : { type: 'Phosphorus', metric: 'phosphorus' },
  potassium                       : { type: 'Potassium', metric: 'potassium' },
  sodium                          : { type: 'Sodium', metric: 'sodium' },
  triglycerides                   : { type: 'Triglycerides', metric: 'triglycerides' },
  glomerularFiltrationRate        : { type: 'GlomerularFiltrationRate', metric: 'filtration' },
  modificationOfDietInRenalDisease: { type: 'ModificationOfDietInRenalDisease', metric: 'filtration' },
};

/**
  Default precisions:
    toDisplay: 2
    toStorage: 3
 */
export const METRICS = {
  weight: {
    unit       : 'KG',
    conversions: {
      LB: {
        factor: 2.2,
      },
    },
  },

  height: {
    unit      : 'CM',
    precisions: {
      toStorage: 1,
      toDisplay: 0,
    },
    conversions: {
      IN: {
        factor    : 0.3937,
        precisions: {
          toStorage: 2,
          toDisplay: 1,
        },
      },
    },
  },

  pressure: {
    unit: 'MMHG',
  },

  bloodGlucoseConcentration: {
    unit      : 'MG_DL',
    precisions: {
      toDisplay: 0,
    },
    conversions: {
      MMOL_L: {
        factor    : 0.0555,
        precisions: {
          toDisplay: 1,
        },
      },
    },
  },

  calcium: {
    unit       : 'MG_DL',
    conversions: {
      MMOL_L: {
        factor: 0.25,
      },
    },
  },

  cholesterol: {
    unit       : 'MG_DL',
    conversions: {
      MMOL_L: {
        factor: 0.0259,
      },
    },
  },

  creatinine: {
    unit      : 'MG_DL',
    precisions: {
      toStorage: 4,
    },
    conversions: {
      UMOL_L: {
        factor    : 88.4,
        precisions: {
          toStorage: 4,
        },
      },
    },
  },

  phosphorus: {
    unit       : 'MG_DL',
    conversions: {
      MMOL_L: {
        factor: 0.323,
      },
    },
  },

  potassium: {
    unit       : 'MG_DL',
    conversions: {
      MMOL_L: {
        factor: 0.26,
      },
      MEQ_L: {
        factor: 0.26,
      },
    },
  },

  sodium: {
    unit       : 'MG_DL',
    conversions: {
      MMOL_L: {
        factor: 0.43,
      },
      MEQ_L: {
        factor: 0.43,
      },
    },
  },

  triglycerides: {
    unit       : 'MG_DL',
    conversions: {
      MMOL_L: {
        factor: 0.0113,
      },
    },
  },

  filtration: {
    unit: 'ML_MIN_1_73_M2',
  },

  CockcroftGaultFiltration: {
    unit: 'ML_MIN',
  },

  HbA1C: {
    unit: 'PERCENT',
  },
};


export const GLUCOSE_LEVELS_INPUTS_PRECISION = {
  MMOL_L: 1,
};


export const METRICS_STANDARDS = {
  bloodGlucoseConcentration: {
    maxValue: 350,
    minValue: 10,
    preMeal : {
      highThreshold: 180,
      lowThreshold : 90,
    },
    postMeal: {
      highThreshold: 200,
      lowThreshold : 120,
    },
    critical: {
      highThreshold: 250,
      lowThreshold : 54,
    },
  },
};


export const UNITS_SYMBOLS = {
  MG_DL         : 'mg/dL',
  MEQ_L         : 'mEq/L',
  MMOL_L        : 'mmol/L',
  UMOL_L        : '\u00b5mol/L',
  KG            : 'kg',
  LB            : 'lbs',
  CM            : 'cm',
  IN            : 'in',
  ML_MIN_1_73_M2: 'mL/min/1.73m\u00B2',
  ML_MIN        : 'mL/min',
  MMHG          : 'mmHg',
  PERCENT       : '%',
};

export const BLOOD_GLUCOSE_CONCENTRATION_UNITS = {
  MG_DL : 'MG_DL',
  MMOL_L: 'MMOL_L',
};

export const FEATURE_TOGGLES = {
  featureCgm      : 'featureCgm',
  featurePlatforms: 'featurePlatforms',
};

export const ANDROID_URLS = {
  us: 'https://play.google.com/store/apps/details?id=com.ascensia.contour.us', // US country id
  es: 'https://play.google.com/store/apps/details?id=com.ascensia.contour.es', // ES country id
  sa: 'https://play.google.com/store/apps/details?id=com.ascensia.contour.sa', // SA country id
  il: 'https://play.google.com/store/apps/details?id=com.ascensia.contour.il', // IL country id
  pl: 'https://play.google.com/store/apps/details?id=com.ascensia.contour', // PL country id
  it: 'https://play.google.com/store/apps/details?id=com.ascensia.contour.it', // IT country id
  uk: 'https://play.google.com/store/apps/details?id=com.ascensia.contour.uk', // UK country id
  ie: 'https://play.google.com/store/apps/details?id=com.ascensia.contour.ie', // IE country id
  au: 'https://play.google.com/store/apps/details?id=com.ascensia.contour.au', // AU country id
  si: 'https://play.google.com/store/apps/details?id=com.ascensia.contour.si', // SI country id
};

export const IOS_URLS = {
  us: 'https://itunes.apple.com/us/app/contour-diabetes-app/id1173950283', // US country id
  es: 'https://itunes.apple.com/es/app/contour-diabetes-app/id1145662139', // ES country id
  sa: 'https://itunes.apple.com/sa/app/contour-diabetes-app/id1249676767', // SA country id
  il: 'https://itunes.apple.com/is/app/contour-diabetes-app/id1453869606', // IL country id
  pl: 'https://itunes.apple.com/pl/app/contour-diabetes-app/id1069437241', // PL country id
  it: 'https://itunes.apple.com/it/app/contour-diabetes-app/id1144642968', // IT country id
  uk: 'https://itunes.apple.com/gb/app/contour-diabetes-app/id1144666843', // UK country id
  ie: 'https://itunes.apple.com/ie/app/contour-diabetes-app/id1144658994', // IE country id
  au: 'https://itunes.apple.com/au/app/contour-diabetes-app/id1320847361', // AU country id
  si: 'https://itunes.apple.com/si/app/contour-diabetes-app/id1145666414', // SI country id
};

export const MARKETING_CONSENTS_TYPE = ['Phone', 'Mail', 'Email', 'Sms'];
export const SENSITIVE_DATA_AND_PROFILING_CONSENTS_TYPE = 'SensitiveDataAndProfiling';
export const TERMS_OF_SERVICES_CONSENTS_TYPE = 'TermsOfService';

export const GLUCOSE_LEVELS_COLORS = {
  veryHigh: '#F4C32C',
  high    : '#F7D261',
  normal  : '#1EA98C',
  low     : '#F9707E',
  veryLow : '#F74053',
};


export const NOT_DISCONNECTABLE_DATA_SOURCES = ['ContourCloud'];


export const METRICS_UNITS_TYPE_NAMES = [
  'weight',
  'height',
  'pressure',
  'bloodGlucoseConcentration',
  'calcium',
  'cholesterol',
  'creatinine',
  'phosphorus',
  'potassium',
  'sodium',
  'triglycerides',
  'filtration',
  'CockcroftGaultFiltration',
  'HbA1C',
];

export const FIELD_OPTION = {
  OPTIONAL: 'Optional',
  REQUIRED: 'Mandatory',
  HIDDEN  : 'Hidden',
};


export const REQUIRED_REPORT_STATE_PATHS = [
  'account',
  'ambulatoryGlucoseProfile',
  'app',
  'dataSources',
  'hcp',
  'information',
  'layout',
  'patient',
  'patientResults',
];


export const EXCLUDED_REPORT_STATE_PATHS_WITH_DEFAULTS = {
  'hcp.patients': [],
};
