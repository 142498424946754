import React from 'react';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Chevron from 'svg/chevron-right.svg';
import Select from 'components/Form/Select';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import messages from '../../messages';
import { DAYS_SELECTOR_ZOOM } from '../../constants';
import styles from './DaysSelectorControlBtn.pcss';


class DaysSelectorControlBtn extends React.PureComponent {

  static propTypes = {
    // Implicit props
    daysSelectorZoom      : PropTypes.string,
    daysSelectorPage      : PropTypes.number,
    daysSelectorTotalPages: PropTypes.number,
    // Implicit actions
    setDaysSelectorZoom   : PropTypes.func,
    setDaysSelectorPage   : PropTypes.func,

  };


  get signProps() {
    const {
      daysSelectorPage,
      setDaysSelectorPage,
      daysSelectorTotalPages,
    } = this.props;
    if (daysSelectorTotalPages === 1 || daysSelectorTotalPages === 0) {
      return {
        plusSignProps: {
          className: cn(
            styles.daysSelectorScrollBtn__sign,
            styles['daysSelectorScrollBtn__sign--disabled'],
          ),
          disabled: true,
        },
        minusSignProps: {
          className: cn(
            styles.daysSelectorScrollBtn__sign,
            styles['daysSelectorScrollBtn__sign--disabled'],
          ),
          disabled: true,
        },
      };
    }
    if (daysSelectorPage <= 0) {
      return {
        plusSignProps: {
          className: cn(
            styles.daysSelectorScrollBtn__sign,
          ),
          onClick: () => setDaysSelectorPage(daysSelectorPage + 1),
        },
        minusSignProps: {
          className: cn(
            styles.daysSelectorScrollBtn__sign,
            styles['daysSelectorScrollBtn__sign--disabled'],
          ),
          disabled: true,
        },
      };
    }
    if (daysSelectorPage >= daysSelectorTotalPages - 1) {
      return {
        plusSignProps: {
          className: cn(
            styles.daysSelectorScrollBtn__sign,
            styles['daysSelectorScrollBtn__sign--disabled'],
          ),
          disabled: true,
        },
        minusSignProps: {
          className: cn(
            styles.daysSelectorScrollBtn__sign,
          ),
          onClick: () => setDaysSelectorPage(daysSelectorPage - 1),
        },
      };
    }
    return {
      plusSignProps: {
        className: cn(
          styles.daysSelectorScrollBtn__sign,
        ),
        onClick: () => setDaysSelectorPage(daysSelectorPage + 1),
      },
      minusSignProps: {
        className: cn(
          styles.daysSelectorScrollBtn__sign,
        ),
        onClick: () => setDaysSelectorPage(daysSelectorPage - 1),
      },
    };

  }


  get options() {
    return ([
      {
        label: <FormattedMessage {...messages.labels[`zoom${DAYS_SELECTOR_ZOOM.day}`]} />,
        value: DAYS_SELECTOR_ZOOM.day,
      },
      {
        label: <FormattedMessage {...messages.labels[`zoom${DAYS_SELECTOR_ZOOM.week}`]} />,
        value: DAYS_SELECTOR_ZOOM.week,
      },
      {
        label: <FormattedMessage {...messages.labels[`zoom${DAYS_SELECTOR_ZOOM.month}`]} />,
        value: DAYS_SELECTOR_ZOOM.month,
      },
    ]);
  }


  render() {
    const {
      daysSelectorZoom,
      setDaysSelectorZoom,
    } = this.props;

    const {
      plusSignProps,
      minusSignProps,
    } = this.signProps;

    return (
      <div className={styles.root}>
        <div className={styles.daysSelectorScrollBtn}>
          <Select
            className="controlSelect"
            optionsFrom={this.options}
            valueKey="value"
            labelKey="label"
            onChange={({ value }) => setDaysSelectorZoom(value)}
            value={daysSelectorZoom}
          />
          <div className={styles.daysSelectorScrollBtn__signs}>
            <button
              type="button"
              {...minusSignProps}
            >
              <Chevron className={cn(
                styles['daysSelectorScrollBtn__signIcon--backwards'],
              )}
              />
            </button>
            <button type="button" {...plusSignProps}>
              <Chevron />
            </button>
          </div>
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  daysSelectorZoom      : selectors.daysSelectorZoom(state),
  daysSelectorPage      : selectors.daysSelectorPage(state),
  daysSelectorTotalPages: selectors.daysSelectorTotalPages(state),
});


const mapDispatchToProps = (dispatch) => ({
  setDaysSelectorZoom: (zoom) => dispatch(actions.setDaysSelectorZoomSmartPage(zoom)),
  setDaysSelectorPage: (zoom) => dispatch(actions.setDaysSelectorPage(zoom)),
});


const ConnectedDaysSelectorControlBtn = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DaysSelectorControlBtn);


export default withStyles(styles)(ConnectedDaysSelectorControlBtn);
