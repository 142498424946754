import * as actionTypes from './actionTypes';


//----------------------------------------------------------------------------------------------------------------------

export const fetchList = () => ({
  type: actionTypes.FETCH_LIST,
});

export const fetchListSuccess = (notifications) => ({
  type   : actionTypes.FETCH_LIST_SUCCESS,
  payload: {
    notifications,
  },
});

export const fetchListError = (error) => ({
  type: actionTypes.FETCH_LIST_ERROR,
  error,
});


//----------------------------------------------------------------------------------------------------------------------

export const dismissNotification = (notification) => ({
  type   : actionTypes.DISMISS_NOTIFICATION,
  payload: {
    notification,
  },
});

export const dismissNotificationSuccess = (notification) => ({
  type   : actionTypes.DISMISS_NOTIFICATION_SUCCESS,
  payload: {
    notification,
  },
});

export const dismissNotificationError = (error, notification) => ({
  type   : actionTypes.DISMISS_NOTIFICATION_ERROR,
  payload: {
    notification,
  },
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const markNotificationsRead = (notificationsIds) => ({
  type   : actionTypes.MARK_NOTIFICATIONS_READ,
  payload: {
    notificationsIds,
  },
});

export const markNotificationsReadSuccess = () => ({
  type: actionTypes.MARK_NOTIFICATIONS_READ_SUCCESS,
});

export const markNotificationsReadError = (error, notificationsIds) => ({
  type   : actionTypes.MARK_NOTIFICATIONS_READ_ERROR,
  payload: {
    notificationsIds,
  },
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const sendClinicPatientNewReadingsAddedNotification = ({
  clinicId,
  clinicName,
  patientFirstName,
  patientLastName,
  encryptedClinicPatientProfileId,
  readingsCount,
} = {}) => ({
  type   : actionTypes.SEND_CLINIC_PATIENT_NEW_READINGS_ADDED_NOTIFICATION,
  payload: {
    clinicId,
    clinicName,
    patientFirstName,
    patientLastName,
    encryptedClinicPatientProfileId,
    readingsCount,
  },
});

export const sendNotificationSuccess = () => ({
  type: actionTypes.SEND_NOTIFICATION_SUCCESS,
});


export const sendNotificationError = (error) => ({
  type: actionTypes.SEND_NOTIFICATION_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchNotificationsHistory = ({
  notificationName,
  dataRangeFrom,
  dataRangeTo,
} = {}) => ({
  type   : actionTypes.FETCH_NOTIFICATIONS_HISTORY,
  payload: {
    notificationName,
    'DateRange.From': dataRangeFrom,
    'DateRange.To'  : dataRangeTo,
  },
});

export const fetchNotificationsHistorySuccess = (notificationsHistory) => ({
  type   : actionTypes.FETCH_NOTIFICATIONS_HISTORY_SUCCESS,
  payload: {
    notificationsHistory,
  },
});


export const fetchNotificationsHistoryError = (error) => ({
  type: actionTypes.FETCH_NOTIFICATIONS_HISTORY_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------
