// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1HWjE{border-radius:4px;background-color:#e1f8f5;padding:2px 8px;line-height:18px;font-size:12px;font-size:1.2rem;font-weight:700;margin:2.5px 5px;display:inline-block;color:#1ea98c}._1HWjE._2m_ah{background-color:#fff;border:1px solid #1ea98c}._1HWjE svg{position:relative;top:2px}._292P_{background-color:#fde8ea;color:#f74053;fill:#f74053}._292P_._2m_ah{border-color:#f74053}._4Af2d{background-color:#fcf7e8;color:#f4c32c;fill:#f4c32c}._4Af2d._2m_ah{border-color:#f4c32c}", ""]);
// Exports
exports.locals = {
	"reading": "_1HWjE",
	"reading--manual": "_2m_ah",
	"reading--low": "_292P_",
	"reading--high": "_4Af2d"
};
module.exports = exports;
