import React from 'react';
import { batch, connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage, injectIntl } from 'react-intl';
import intlShape from 'shapes/intlShape';
import { highlightedHourlyRecordsShape, highlightedRecordsShape } from 'shapes/bins';
import App from 'modules/App';
import * as patientResultsConstants from 'modules/PatientResults/constants';
import CheckboxRadio from 'components/Form/CheckboxRadio';
import BloodGlucoseProfileAgpChart from 'components/Charts/BloodGlucoseProfileAgpChart';
import * as chartsConstants from 'components/Charts/constants';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as constants from '../../constants';
import messages from '../../messages';
import styles from './AgpProfileChart.pcss';


class AgpProfileChart extends React.PureComponent {

  static propTypes = {
    // Implicit props
    highlightedHourlyRecords  : highlightedHourlyRecordsShape,
    highlightedRecords        : highlightedRecordsShape,
    isDisplayedAgpProfileChart: PropTypes.bool,
    showMedian                : PropTypes.bool,
    showIQR                   : PropTypes.bool,
    showP10P90                : PropTypes.bool,
    intl                      : intlShape.isRequired,
    // Implicit actions
    resetHighlightedDate      : PropTypes.func,
    setHighlightedDate        : PropTypes.func,
    setAllHighlightedDates    : PropTypes.func,
    setShowMedian             : PropTypes.func,
    setShowIQR                : PropTypes.func,
    setShowP10P90             : PropTypes.func,
    // Explicit props
    conversion                : PropTypes.object.isRequired,
    standards                 : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    direction    : PropTypes.string,
    mode         : PropTypes.oneOf(constants.MODES),
    start        : PropTypes.number,
    end          : PropTypes.number,
    data         : PropTypes.array,
    isInProgress : PropTypes.bool,
    // Explicit actions
    onDatesChange: PropTypes.func,
  };


  renderUnit(conversion) {
    return (
      <p className={`col-auto ${styles.unit}`}>
        <FormattedMessage
          {...messages.labels.unit}
          values={{
            unit: conversion.unitSymbol,
          }}
        />
      </p>
    );
  }


  renderTooltip() {
    if (!process.env.BROWSER) {
      return null;
    }
    const showModalDay = this.props.data.length < patientResultsConstants.READING_COUNT_TO_SHOW_AGP_BGM;

    return (
      <ReactTooltip
        id="agpProfileChart"
        place="bottom"
        type="dark"
        effect="solid"
        disable={!showModalDay}
      />
    );
  }


  renderMainChart() {
    const {
      conversion,
      standards,
      highlightedHourlyRecords,
      direction,
      showMedian,
      showIQR,
      showP10P90,
      setShowMedian,
      setShowIQR,
      setShowP10P90,
      data,
    } = this.props;

    const showModalDay = data.length < patientResultsConstants.READING_COUNT_TO_SHOW_AGP_BGM;

    return (
      <>
        <div className="row">
          {this.renderUnit(conversion)}
          <div className="col d-flex justify-content-center align-items-center">
            <div
              className="d-flex justify-content-center align-items-center"
              data-for="agpProfileChart"
              data-tip={this.props.intl.formatMessage(messages.labels.agpInsufficientData)}
            >
              <CheckboxRadio
                id="median"
                inputValue="true"
                className={styles.legendCheckbox}
                value={showMedian.toString()}
                labelMessage={messages.labels.median}
                onChange={(input) => {
                  setShowMedian(Boolean(input.value === 'true'));
                }}
                isDisabled={this.props.isInProgress || showModalDay}
              />
              <div
                className={styles.legendSample}
                style={{ background: chartsConstants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.MEDIAN }}
              />
            </div>
            <div
              className="d-flex justify-content-center align-items-center"
              data-for="agpProfileChart"
              data-tip={this.props.intl.formatMessage(messages.labels.agpInsufficientData)}
            >
              <CheckboxRadio
                id="showIQR"
                inputValue="true"
                className={styles.legendCheckbox}
                value={showIQR.toString()}
                labelMessage={messages.labels.iqr}
                onChange={(input) => {
                  setShowIQR(Boolean(input.value === 'true'));
                }}
                isDisabled={this.props.isInProgress || showModalDay}
              />
              <div
                className={styles.legendSample}
                style={{ background: chartsConstants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.IQR }}
              />
            </div>
            <div
              className="d-flex justify-content-center align-items-center"
              data-for="agpProfileChart"
              data-tip={this.props.intl.formatMessage(messages.labels.agpInsufficientData)}
            >
              <CheckboxRadio
                id="p10p90"
                inputValue="true"
                className={styles.legendCheckbox}
                value={showP10P90.toString()}
                labelMessage={messages.labels.p10p90}
                onChange={(input) => {
                  setShowP10P90(Boolean(input.value === 'true'));
                }}
                isDisabled={this.props.isInProgress || showModalDay}
              />
              <div
                className={styles.legendSample}
                style={{ background: chartsConstants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.P10P90 }}
              />
            </div>
          </div>
        </div>
        <BloodGlucoseProfileAgpChart
          conversion={conversion}
          standards={standards}
          highlightedHourlyRecords={highlightedHourlyRecords}
          direction={direction}
          showIQR={showIQR}
          showMedian={showMedian}
          showP10P90={showP10P90}
          showModalDay={showModalDay}
          isInProgress={this.props.isInProgress}
        />
      </>
    );
  }


  render() {
    return (
      <div className="chartContainer">
        { this.renderMainChart()}
        { this.renderTooltip() }
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  dailyRecords              : selectors.dailyRecords(state),
  highlightedHourlyRecords  : selectors.highlightedHourlyRecords(state),
  highlightedRecords        : selectors.highlightedRecords(state),
  isDisplayedAgpProfileChart: selectors.isDisplayedAgpProfileChart(state),
  showMedian                : selectors.showMedian(state),
  showIQR                   : selectors.showIQR(state),
  showP10P90                : selectors.showP10P90(state),
  direction                 : App.selectors.direction(state),
});


const mapDispatchToProps = (dispatch) => ({
  resetHighlightedDate  : (date) => dispatch(actions.resetHighlightedDate(date)),
  setHighlightedDate    : (date) => dispatch(actions.setHighlightedDate(date)),
  setAllHighlightedDates: (dates) => batch(() => {
    dispatch(actions.clearHighlightedDates());
    dispatch(actions.setHighlightedDates(dates));
  }),
  setShowMedian: (showMedian) => dispatch(actions.setShowMedian(showMedian)),
  setShowIQR   : (showIQR) => dispatch(actions.setShowIQR(showIQR)),
  setShowP10P90: (showP10P90) => dispatch(actions.setShowP10P90(showP10P90)),
});


const ConnectedAgpProfileChart = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AgpProfileChart));


export default withStyles(styles)(ConnectedAgpProfileChart);
