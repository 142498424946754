import React from 'react';
import TimePicker from 'react-time-picker';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import App from 'modules/App';


const Timepicker = ({ id, value, onSetValue }) => {
  const locale = useSelector(App.selectors.locale);

  const onValueChange = (timeValue) => onSetValue({
    value: timeValue,
    id,
  });

  return (
    <TimePicker
      id={id}
      onChange={(val) => onValueChange(val)}
      maxDetail="minute"
      disableClock
      name={id}
      locale={locale}
      clearIcon={null}
      clockIcon={null}
      value={value}
    />
  );
};

Timepicker.propTypes = {
  id        : PropTypes.string,
  value     : PropTypes.string,
  onSetValue: PropTypes.func,
};

export default Timepicker;
