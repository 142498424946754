import { call, all, getContext } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import { LocalError } from 'helpers/errorTypes';
import CentralStorageService from 'services/CentralStorageService';
import GoogleDriveService from 'services/GoogleDriveService';
import { confirmCleanup } from 'services/MdtcService';


function getDriveService(storageProvider) {
  switch (storageProvider) {
    case 'GoogleDrive': {
      return GoogleDriveService;
    }
    default: {
      throw new LocalError({ code: 'UnknownStorageProvider' });
    }
  }
}


function* clearFork(forkHistory, storageProvider, accessToken) {
  const { forkHistoryLimit } = yield getContext('client');

  const { history } = forkHistory;
  const documentsIdentifiers = [...history];
  documentsIdentifiers.splice(0, forkHistoryLimit);

  if (isEmpty(documentsIdentifiers)) {
    return;
  }

  if (storageProvider === 'CentralStorage') {
    yield call(CentralStorageService.clearForks, documentsIdentifiers, accessToken);
  } else {
    const driveService = getDriveService(storageProvider);
    try {
      yield all(mapValues(
        documentsIdentifiers,
        (forkedItem) => call(driveService.deleteFile, forkedItem.documentId, accessToken),
      ));
    // eslint-disable-next-line no-empty
    } catch (err) {
    }
  }

  yield call(confirmCleanup, documentsIdentifiers.map((document) => document.documentId));
}


/**
 * @param {string} forksHistory
 * @param {string='phiSet','patientProfile'} documentType - Collection indicator
 * @param {string} storageProvider
 * @param {object} accessToken
 */
function* clearForks(forksHistory, storageProvider, accessToken) {
  if (process.env.BROWSER) {
    yield all(mapValues(
      forksHistory,
      (forkHistory) => call(clearFork, forkHistory, storageProvider, accessToken),
    ));
  }
}


function* fetchPhiSet(documentId, storageProvider, accessToken) {
  if (!documentId) {
    return null;
  }
  if (storageProvider === 'CentralStorage') {
    return yield call(CentralStorageService.fetchPhiSet, documentId, accessToken);
  }
  const driveService = getDriveService(storageProvider);
  return yield call(driveService.downloadFile, documentId, accessToken);
}


function* fetchPatients(documentId, storageProvider, accessToken) {
  let patients = [];

  switch (storageProvider) {
    case 'CentralStorage': {
      patients = yield call(CentralStorageService.fetchPatients, documentId, accessToken);
      break;
    }
    case 'GoogleDrive': {
      patients = yield call(GoogleDriveService.downloadFile, documentId, accessToken);
      break;
    }
    default: {
      throw new LocalError({ code: 'UnknownStorageProvider' });
    }
  }

  return patients;
}


export default {
  getDriveService,
  clearForks,
  fetchPhiSet,
  fetchPatients,
};
