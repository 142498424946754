import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import OutsideClickHandler from 'react-outside-click-handler';
import Button from 'components/Form/Button';
import patientShape from 'shapes/patientShape';
import App from 'modules/App';
import Account from 'modules/Account';
import Hcp from 'modules/Hcp';
import Download from 'svg/download.svg';
import ReportAgpIcon from 'svg/report-captur-agp.svg';
import ChevronRight from 'svg/chevron-right.svg';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import ExportDataModal from '../ExportDataModal';
import styles from './ExportDataMenu.pcss';


class ExportDataMenu extends React.Component {

  static propTypes = {
    // Implicit props
    patients              : PropTypes.arrayOf(patientShape),
    activeClinicMembership: Account.shapes.clinicMembership,
    isInProgress          : PropTypes.bool,
    // Implicit actions
    onExport              : PropTypes.func,
    onOpenModal           : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }


  onExport(exportName) {
    const { patients, activeClinicMembership: clinicMembership } = this.props;
    this.setState({ isOpen: false });
    this.props.onExport(exportName, { patients, clinicMembership });
    this.props.onOpenModal();
  }


  renderItemIcon(Icon) {
    return (
      <div className={styles.reportSelector__icon}>
        <Icon />
      </div>
    );
  }


  renderItemLabel(exportName) {
    const label = messages.buttons[exportName];
    return (
      <div className={styles.reportSelector__label}>
        <FormattedMessage {...label} />
      </div>
    );
  }


  renderItem(exportName, Icon) {
    return (
      <button
        type="button"
        className={styles.reportSelector__button}
        onClick={() => this.onExport(exportName)}
      >
        {this.renderItemIcon(Icon)}
        {this.renderItemLabel(exportName)}
      </button>
    );
  }


  renderReportDropdown() {
    const { isOpen } = this.state;
    if (!isOpen) {
      return null;
    }

    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          this.setState({ isOpen: false });
        }}
      >
        <div className={styles.topBar__generateReportDropdown}>
          <div>
            {this.renderItem('patientsBloodGlucoseExport', ReportAgpIcon)}
          </div>
        </div>
      </OutsideClickHandler>
    );
  }


  render() {
    return (
      <div className={styles.topBar__generateReport}>
        <Button
          styleModifier="transparent"
          className={cn(
            styles.topBar__button,
            styles['topBar__button--right'],
            styles['topBar__button--active'],
          )}
          isDisabled={this.props.isInProgress}
          onClick={() => this.setState({ isOpen: true })}
        >
          <Download />
          <FormattedMessage {...messages.buttons.exportData} />
          <ChevronRight className={styles.chevronIcon} />
        </Button>
        { this.renderReportDropdown() }
        <ExportDataModal />
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  isInProgress          : selectors.isExportDataInProgress(state),
  patients              : Hcp.selectors.patients(state),
  activeClinicMembership: Account.selectors.activeClinicMembership(state),
  openModalId           : App.selectors.modal(state),
});


const mapDispatchToProps = (dispatch) => ({
  onExport   : (exportName, payload) => dispatch(actions.exportData(exportName, payload)),
  onOpenModal: () => dispatch(App.actions.openModal(constants.EXPORT_DATA_MODAL)),
});


const ConnectedExportDataMenu = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExportDataMenu);


export default withStyles(styles)(ConnectedExportDataMenu);
