import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import Account from 'modules/Account';
import InfoIcon from 'svg/info.svg';
import messages from '../messages';
import styles from './VisitNotes.pcss';


class PrivateNoteInfo extends React.PureComponent {

  static propTypes = {
    activeClinicMembership: Account.shapes.clinicMembership,
  };


  render() {
    const clinicName = get(this.props.activeClinicMembership, 'clinic.name', '');
    return (
      <div className={styles.privateInfo}>
        <div>
          <InfoIcon className={styles.privateInfo__icon} />
        </div>
        <div>
          <FormattedMessage
            {...messages.infos.privateNote}
            values={{ clinicName: <b>{clinicName}</b> }}
          />
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  activeClinicMembership: Account.selectors.activeClinicMembership(state),
});


const ConnectedPrivateNoteInfo = connect(
  mapStateToProps,
)(PrivateNoteInfo);


export default ConnectedPrivateNoteInfo;
