// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1vPWQ{padding:8px;width:100%;white-space:nowrap;border-radius:8px}._1vPWQ,._1Ke8s{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;height:100%;text-align:center}._1Ke8s{color:#fff}._3LXs2{margin-top:8px;padding-top:8px}", ""]);
// Exports
exports.locals = {
	"glucoseLevel__barWrapper": "_1vPWQ",
	"glucoseLevel__barWrapper__part": "_1Ke8s",
	"glucoseLevel__barWrapper__date": "_3LXs2"
};
module.exports = exports;
