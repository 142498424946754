import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import replace from 'lodash/replace';
import App from 'modules/App';
import intlShape from 'shapes/intlShape';
import { formatTimestamp } from 'helpers/datetime';
import { saveAsCSV } from 'helpers/csv';
import { calculateRelatedValue } from 'helpers/relatedData';
import Table from 'components/Table';
import messages from '../../messages';
import styles from './PatientsBgDataReport.pcss';


class PatientsBgDataReport extends React.Component {

  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    readings             : PropTypes.array,
    relatedData          : PropTypes.array,
    accountPatientProfile: PropTypes.object.isRequired, // @TODO: Shape
    downloadCsv          : PropTypes.bool,
    // Implicit props
    intl                 : intlShape.isRequired,
  };


  componentDidUpdate(prevProps) {
    if (this.props.downloadCsv && !prevProps.downloadCsv) {
      this.saveAsCsv();
    }
  }


  get name() {
    const { accountPatientProfile, intl } = this.props;
    const reportName = replace(intl.formatMessage(messages.buttons.PatientsBgData), ' ', '_');
    const patientName = `${accountPatientProfile.firstName}_${accountPatientProfile.lastName}`;
    return `${reportName}_${patientName}_${new Date().toLocaleDateString()}`;
  }


  get rows() {
    const { conversion, intl } = this.props;

    return this.readingsWithRelatedData.map((reading) => {
      let row = [
        formatTimestamp(reading.timestamp, 'L LT'),
        conversion.toDisplay(reading.value),
      ];
      if (reading.relatedData) {
        const relatedData = calculateRelatedValue(reading.relatedData);
        row = row.concat(
          [
            relatedData.medicationLongValue || '',
            relatedData.medicationFastValue || '',
            relatedData.foodValue || '',
          ]
        );
      } else {
        row = row.concat(['', '', '']);
      }

      row.push(intl.formatMessage(App.messages.flags[reading.flags]));

      return row;
    });
  }


  get entities() {
    return this.rows.map((rowItem) => {
      const row = {};
      this.schema.forEach((schemaItem, index) => {
        row[schemaItem.key] = rowItem[index];
      });
      return row;
    });
  }


  get header() {
    return this.schema.map((schemaItem) => schemaItem.labelMessage);
  }


  get schema() {
    const { intl, conversion } = this.props;
    return [
      {
        key         : 'time',
        labelMessage: intl.formatMessage(messages.labels.patientsBgDataReport.time),
      },
      {
        key         : 'value',
        labelMessage: `${intl.formatMessage(messages.labels.patientsBgDataReport.value)} [${conversion.unitSymbol}]`,
      },
      {
        key         : 'medicationLongValue',
        labelMessage: intl.formatMessage(messages.labels.patientsBgDataReport.medicationLongValue),
      },
      {
        key         : 'medicationFastValue',
        labelMessage: intl.formatMessage(messages.labels.patientsBgDataReport.medicationFastValue),
      },
      {
        key         : 'foodValue',
        labelMessage: `${intl.formatMessage(messages.labels.patientsBgDataReport.foodValue)} [g]`,
      },
      {
        key         : 'mealMarker',
        labelMessage: intl.formatMessage(messages.labels.patientsBgDataReport.mealMarker),
      },
    ];
  }


  get readingsWithRelatedData() {
    const { readings, relatedData } = this.props;

    return readings.map((reading) => {
      const findRelatedData = relatedData && relatedData.find((item) => item.readingId === reading.externalId);
      if (findRelatedData) {
        reading.relatedData = findRelatedData;
      }
      return reading;
    });
  }


  saveAsCsv() {
    saveAsCSV(this.name, this.header, this.rows);
  }


  render() {
    return (
      <>
        <div className={styles.patientsBgDataReport}>
          <Table
            idKey="time"
            schema={this.schema}
            entities={this.entities}
            perPage={25}
            isPerPageOff
          />
        </div>
      </>
    );
  }

}

export default withStyles(styles)(injectIntl(PatientsBgDataReport));
