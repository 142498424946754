import { registerAction, unregisterAction } from 'helpers/reducerTools';
import * as accountActionTypes from 'modules/Account/actionTypes';
import * as actionTypes from './actionTypes';


const initialState = {
  fetching: [],
  errors  : [],
  profile : null,
};


export default function reducer(state = { ...initialState }, action) {

  switch (action.type) {

    case actionTypes.CONTOUR_CLOUD_ME: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.CONTOUR_CLOUD_ME),
        errors  : unregisterAction(state.errors, actionTypes.CONTOUR_CLOUD_ME),
      };
    }
    case actionTypes.CONTOUR_CLOUD_ME_SUCCESS: {
      const { profile } = action.payload;
      return {
        ...state,
        profile,
        fetching: unregisterAction(state.fetching, actionTypes.CONTOUR_CLOUD_ME),
      };
    }
    case actionTypes.CONTOUR_CLOUD_ME_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.CONTOUR_CLOUD_ME),
        errors  : registerAction(state.errors, actionTypes.CONTOUR_CLOUD_ME),
      };
    }
    case actionTypes.CONTOUR_CLOUD_REAUTH: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.CONTOUR_CLOUD_REAUTH),
        errors  : unregisterAction(state.errors, actionTypes.CONTOUR_CLOUD_REAUTH),
      };
    }
    case actionTypes.CONTOUR_CLOUD_REAUTH_SUCCESS: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.CONTOUR_CLOUD_REAUTH),
      };
    }
    case actionTypes.CONTOUR_CLOUD_REAUTH_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.CONTOUR_CLOUD_REAUTH),
        errors  : registerAction(state.errors, actionTypes.CONTOUR_CLOUD_REAUTH),
      };
    }
    case accountActionTypes.SIGN_OUT_SUCCESS: {
      return {
        ...initialState,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    default:
      return state;

  }
}
