import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Form/Button';
import App from 'modules/App';
import * as constants from '../../../constants';
import messages from '../../../messages';
import GdprExportModal from '../GdprExportModal';


class ExportData extends React.PureComponent {

  static propTypes = {
    onOpenModal: PropTypes.func,
  };


  render() {
    return (
      <section>
        <h2 className="section__header"><FormattedMessage {...messages.headers.exportData} /></h2>
        <p className="section__intro"><FormattedMessage {...messages.intros.exportData} /></p>
        <Button
          styleModifier="primary"
          labelMessage={messages.buttons.exportData}
          className="btn--filled"
          // isInProgress={false}
          onClick={() => this.props.onOpenModal(constants.GDPR_EXPORT_MODAL)}
        />
        <GdprExportModal />
      </section>
    );
  }

}


const mapDispatchToProps = (dispatch) => ({
  onOpenModal: (modalId) => dispatch(App.actions.openModal(modalId)),
});

const ConnectedExportData = connect(
  null, // mapStateToProps,
  mapDispatchToProps,
)(ExportData);

export default ConnectedExportData;
