import { createSelector } from 'reselect';
import includes from 'lodash/includes';
import * as actionTypes from './actionTypes';
import * as constants from './constants';


/**
 * Input selectors
 */
const mainSelector = (state) => state[constants.MODULE_ID];
// const fetchingSelector = (state) => state[constants.MODULE_ID].fetching;
const sendingSelector = (state) => state[constants.MODULE_ID].sending;
const errorsSelector = (state) => state[constants.MODULE_ID].errors;


/**
 * Available data sources list
 */
export const dataSources = createSelector(
  mainSelector,
  (state) => state.dataSources,
);


/**
 * Data sources connected to account
 */
export const connectedDataSources = createSelector(
  mainSelector,
  (state) => state.connectedDataSources,
);

//----------------------------------------------------------------------------------------------------------------------

// TODO: Remove if confirmed

/**
 * Selects if connect process in progress
 */
// export const isConnectInProgress = createSelector(
//   sendingSelector,
//   (state) => includes(state, actionTypes.CONNECT)
// );
//
// export const hasConnectErrors = createSelector(
//   errorsSelector,
//   (state) => includes(state, actionTypes.CONNECT)
// );

//----------------------------------------------------------------------------------------------------------------------

/**
 * Selects if disconnect process in progress
 */
// export const isDisconnectInProgress = createSelector(
//   sendingSelector,
//   (state) => includes(state, actionTypes.DISCONNECT)
// );
//
// export const hasDisconnectErrors = createSelector(
//   errorsSelector,
//   (state) => includes(state, actionTypes.DISCONNECT)
// );

//----------------------------------------------------------------------------------------------------------------------

/**
 * Selects if sync process is in progress
 */
export const isSyncInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.SYNC),
);

export const hasSyncErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.SYNC),
);
