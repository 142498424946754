import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import last from 'lodash/last';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import { useAction } from 'hooks';
import * as actions from '../../../actions';
import { CHANNEL_COMPONENTS } from '../../../constants';
import * as selectors from '../../../selectors';
import messages from '../../../messages';
import styles from '../DownloadDataModal.pcss';


interface DevicesProps {
  devices: Device[],
}

interface ConnectionProps {
  name: string,
  devicesByChannel: { [channel: string]: Device[] },
  onSetComponent: (componentName: string) => void,
  onSetChannel: (channel: string) => void,
}

interface Props {
  devicesByChannel: { [channel: string]: Device[] },
  onSetComponent: (componentName: string) => void,
  onSetStyleModifier: (styleModifier: string) => void,
  onSetChannel: (channel: string) => void,
}


const renderDeviceName = (deviceName: string) => (
  <li key={deviceName} className={styles.connection__model}><span>{ deviceName }</span></li>
);


const Devices: FC<DevicesProps> = ({ devices }) => {
  const devicesNames = uniq(map(devices, (device) => device.name)).sort();

  return (
    <ul className={styles.connection__models}>
      { map(devicesNames, renderDeviceName) }
    </ul>
  );
};


const Connection: FC<ConnectionProps> = ({ name, devicesByChannel, onSetComponent, onSetChannel }) => {
  const downloader = useSelector(selectors.downloader);
  const isDriverInstalled = get(downloader, 'driver.isDriverInstalled', false);
  const driverInstallationState = get(downloader, 'driver.installationState');

  let isActive = true;
  let inactiveInfo = null;
  let blueCable = 'ConnectBlueCable';

  if (!isDriverInstalled) {
    blueCable = driverInstallationState === 'Completed' ? 'InstallingBlueCableDrivers' : 'InstallBlueCableDrivers';
  }

  const components = {
    ...CHANNEL_COMPONENTS,
    BlueCable: blueCable,
  };

  const devices = get(devicesByChannel, name, []);

  if (!devices.length) {
    isActive = false;
    inactiveInfo = <FormattedMessage {...messages.chooseConnection.noSupportedDevices} />;
  } else if (name === 'Bluetooth') {
    isActive = downloader.bleAvailable;
    if (!isActive) {
      const bleAvailabilityReason = last<string>(downloader.bleAvailabilityReason.split(',')).trim();
      const inactiveMessage = get(messages, ['btErrors', bleAvailabilityReason]);
      if (inactiveMessage) {
        inactiveInfo = <FormattedMessage {...inactiveMessage} />;
      }
    }
  }

  let imageFileName = kebabCase(name);
  if (!isActive) {
    imageFileName += '-inactive';
  }

  const onChoose = () => {
    onSetChannel(name);
    onSetComponent(components[name]);
  };

  return (
    <div>
      { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
      <button
        type="button"
        id={`connect${components[name]}`}
        className={cn(styles.connection__btn, { [styles['connection__btn--inactive']]: !isActive })}
        disabled={!isActive}
        onClick={onChoose}
      >
        <span className={styles.connection__imageContainer}>
          <img src={`/assets/svg/${imageFileName}.svg`} alt="" />
        </span>
      </button>
      <h3 className={cn('modal__subheader', { 'text--muted': !isActive })}>
        <FormattedMessage {...messages.connections[name]} /><br />
        { !isActive && <FormattedMessage {...messages.infos.notAvailable} /> }
      </h3>
      {
        isActive
          ? <Devices {...{ devices }} />
          : <div className={styles.connection__inactiveInfo}>{ inactiveInfo }</div>
      }
    </div>
  );
};


const ChooseConnection: FC<Props> = ({ devicesByChannel, onSetComponent, onSetStyleModifier, onSetChannel }) => {
  const checkStatus = useAction(actions.checkStatus);
  const checkBlueCableDriver = useAction(actions.checkBlueCableDriver);
  const clearConnection = useAction(actions.clearConnection);

  useEffect(() => {
    onSetStyleModifier('xl');
    checkStatus();
    checkBlueCableDriver();
    clearConnection();
    return () => {
      onSetStyleModifier(null);
    };
  }, []);

  return (
    <div>
      <h3 className="modal__subheader "><FormattedMessage {...messages.chooseConnection.header} /></h3>
      <div className="row">
        <div className="col">
          <Connection name="BlueCable" {...{ devicesByChannel, onSetComponent, onSetChannel }} />
        </div>
        <div className="col">
          <Connection name="MicroUsb" {...{ devicesByChannel, onSetComponent, onSetChannel }} />
        </div>
        <div className="col">
          <Connection name="UsbC" {...{ devicesByChannel, onSetComponent, onSetChannel }} />
        </div>
        <div className="col">
          <Connection name="BuiltInUsb" {...{ devicesByChannel, onSetComponent, onSetChannel }} />
        </div>
        <div className="col">
          <Connection name="Bluetooth" {...{ devicesByChannel, onSetComponent, onSetChannel }} />
        </div>
      </div>
    </div>
  );
};


export default ChooseConnection;
