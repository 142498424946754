import { call } from 'redux-saga/effects';
import { serializeQuery } from 'helpers/urlTools';
import ApiService from 'services/ApiService';


function* fetchCountrySettings(countryId) {
  const requestUrl = `/api/Localization/country/${countryId}/defaultSettings`;
  return yield call(ApiService.originalRequest, requestUrl);
}


function* fetchInformationTemplate(countryCode, scopeName) {
  const query = serializeQuery({ countryCode, scope: scopeName });
  const requestUrl = `/api/Information/Account/Template?${query}`;
  return yield call(ApiService.originalRequest, requestUrl);
}


function* fetchClinicPatientTemplate(countryCode) {
  const requestUrl = `/api/Information/ClinicPatient/Template?countryCode=${countryCode}`;
  return yield call(ApiService.originalRequest, requestUrl);
}


function* fetchPatientCustomIdentifiers(countryId) {
  const requestUrl = `/api/Localization/country/${countryId}/identifiers/Pwd`;
  return yield call(ApiService.originalRequest, requestUrl);
}


function* fetchPayers(countryId) {
  const requestUrl = `/api/Localization/country/${countryId}/payers`;
  return yield call(ApiService.originalRequest, requestUrl);
}

export default {
  fetchCountrySettings,
  fetchInformationTemplate,
  fetchClinicPatientTemplate,
  fetchPatientCustomIdentifiers,
  fetchPayers,
};
