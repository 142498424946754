import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAction } from 'hooks';
import Button from 'components/Form/Button';
import * as actions from '../../../actions';
import messages from '../../../messages';
import styles from '../DownloadDataModal.pcss';


interface Props {
  onSetComponent: (componentName: string) => void,
}


const SccConnectionFailed: FC<Props> = ({ onSetComponent }) => {
  const onResetScc = useAction(actions.resetScc);

  const onRetry = () => {
    onSetComponent('Initializing');
  };

  const onReset = () => {
    onResetScc();
    onRetry();
  };

  return (
    <div className={styles.modal__content}>
      <h3 className="modal__subheader"><FormattedMessage {...messages.connectionFailed.sccHeader} /></h3>
      <div className={styles.illustration}>
        <img src="/assets/svg/connection-failed.svg" className={styles.connectionFailed__img} alt="" />
      </div>
      <div className="modal__actions">
        <Button
          styleModifier="primary"
          labelMessage={messages.buttons.tryAgain}
          className="btn--block mb-4"
          onClick={onRetry}
        />
        <Button
          styleModifier="primary"
          labelMessage={messages.sccInstall.header}
          className="btn--block btn--filled"
          onClick={onReset}
        />
      </div>
    </div>
  );
};


export default SccConnectionFailed;
