import React from 'react';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import map from 'lodash/map';
import ceil from 'lodash/ceil';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import { getWeekdays } from 'helpers/datetime';
import BloodGlucoseProfileAgpMiniChart from 'components/Charts/BloodGlucoseProfileAgpMiniChart';
import App from 'modules/App';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import DaysSelectorControlBtn from './DaysSelectorControlBtn';
import styles from './DailyGlucoseProfile.pcss';


class DaysSelector extends React.PureComponent {

  static propTypes = {
    // Explicit props
    conversion                           : PropTypes.object.isRequired,
    direction                            : PropTypes.string,
    data                                 : PropTypes.array,
    glucoseConcentrationLevelBottomValues: PropTypes.object,
    isInProgress                         : PropTypes.bool,
    // Implicit props
    dailyRecordsPaginatedByMonth         : PropTypes.array,
    daysSelectorZoom                     : PropTypes.string,
    highlightedDates                     : PropTypes.arrayOf(PropTypes.string),
    // Implicit actions
    selectHighlightedDates               : PropTypes.func,
    onDatesChange                        : PropTypes.func,
    patient                              : PropTypes.object,
    phiSet                               : PropTypes.object,
    phiSetDocumentId                     : PropTypes.string,
  };


  constructor(props) {
    super(props);
    this.state = {
      hover: null,
      focus: false,
    };
  }


  componentDidUpdate(prevProps) {
    if (prevProps.daysSelectorZoom !== this.props.daysSelectorZoom && this.state.focus) {
      this.resetFocus();
    }
  }


  selectHighlightedDates(range) {
    const { onDatesChange, selectHighlightedDates } = this.props;
    selectHighlightedDates(range);
    onDatesChange(
      moment(range.start, 'YYYY-MM-DD').add(1, 'day'),
      moment(range.end, 'YYYY-MM-DD').add(1, 'day'),
    );
  }


  resetFocus() {
    this.setState({ focus: false });
  }


  renderChart({ aggregation, isDisabled }) {
    const {
      conversion,
      direction,
      isInProgress,
      glucoseConcentrationLevelBottomValues,
    } = this.props;
    return (
      <div className={styles.daySelector__cellChartWrapper}>
        {
          !isDisabled
            ? (
              <BloodGlucoseProfileAgpMiniChart
                isInProgress={isInProgress}
                conversion={conversion}
                records={aggregation}
                direction={direction}
                glucoseConcentrationLevelBottomValues={glucoseConcentrationLevelBottomValues}
              />
            )
            : (
              <div className={styles.daySelector__placeholder} />
            )
        }
      </div>
    );
  }


  renderWeekdays() {
    return (
      <div className={styles.renderWeekdays}>
        {
          map(getWeekdays(), (weekday) => (
            <div className={styles.renderWeekday} key={`${weekday}-weekday-header`}>
              {
                moment().utc()
                  .local('en')
                  .startOf('isoWeek')
                  .add('d', weekday)
                  .format('dddd')
              }
            </div>
          ))
        }
      </div>
    );
  }


  renderAgpDaySelector() {
    const {
      dailyRecordsPaginatedByMonth,
      highlightedDates,
      data,
      isInProgress,
    } = this.props;
    if (!dailyRecordsPaginatedByMonth || dailyRecordsPaginatedByMonth.length === 0) {
      return null;
    }
    const startDay = moment(dailyRecordsPaginatedByMonth[0].date).day() - 1;
    let daysBeforeMonth = [];
    if (startDay > 0) {
      daysBeforeMonth = new Array(startDay).fill({});
    } else if (startDay === -1) {
      daysBeforeMonth = new Array(6).fill({});
    }
    return (
      <div className={styles.daySelector}>
        {
          map([...daysBeforeMonth, ...dailyRecordsPaginatedByMonth], (dailyPreview, index) => {
            const dailyAggregation = data.filter((record) => (
              dailyPreview.date === moment.unix(record.timestamp).utc().format('YYYY-MM-DD')
            ));
            const isActive = highlightedDates.indexOf(dailyPreview.date) !== -1;
            const isDisabled = false;
            const isHover = this.state.focus && highlightedDates.length === 1
              && ((dailyPreview.date <= highlightedDates[0] && dailyPreview.date >= this.state.hover)
                || (dailyPreview.date >= highlightedDates[0] && dailyPreview.date <= this.state.hover));
            const onClick = (e) => {
              e.stopPropagation();
              if (this.state.focus) {
                if (highlightedDates.length === 1 && highlightedDates[0] === dailyPreview.date) {
                  this.setState({ focus: false });
                  return;
                } if (highlightedDates.length === 1 && highlightedDates[0] !== dailyPreview.date) {
                  const start = highlightedDates[0];
                  const end = dailyPreview.date;
                  const range = {
                    start: start < end ? start : end,
                    end  : end < start ? start : end,
                  };
                  this.selectHighlightedDates(range);
                  return;
                }
              }
              this.selectHighlightedDates({ start: dailyPreview.date, end: dailyPreview.date });
              this.setState({ focus: true });
            };
            return (
              <div
                className={
                  cn(styles.daySelector__cell, {
                    [styles['daySelector__cell--hover']] : isHover,
                    [styles['daySelector__cell--active']]: isActive,
                    [styles['daySelector__cell--empty']] : isEmpty(dailyPreview),
                    fadingLoader                         : isInProgress,
                  })
                }
                key={`${index}-dgp-cell`}
                onClick={onClick}
                role="presentation"
                onMouseEnter={() => this.setState({ hover: dailyPreview.date })}
              >
                {
                  !isEmpty(dailyPreview)
                  && (
                    <>
                      <div className={styles.dayNumber}>
                        { dailyPreview.day }
                      </div>
                      { this.renderChart({ aggregation: dailyAggregation, isDisabled }) }
                    </>
                  )
                }
              </div>
            );
          })
        }
      </div>
    );
  }


  renderDays() {
    const {
      dailyRecordsPaginatedByMonth,
      isInProgress,
    } = this.props;
    if (!dailyRecordsPaginatedByMonth || dailyRecordsPaginatedByMonth.length === 0) {
      return null;
    }
    const startDay = moment(dailyRecordsPaginatedByMonth[0].date).day() - 1;
    let daysBeforeMonth = [];
    if (startDay > 0) {
      daysBeforeMonth = new Array(startDay).fill({});
    } else if (startDay === -1) {
      daysBeforeMonth = new Array(6).fill({});
    }
    const allDays = [...daysBeforeMonth, ...dailyRecordsPaginatedByMonth];
    const monthName = moment(dailyRecordsPaginatedByMonth[0].date).format('MMM');
    let groupByWeeks = keys(Array(ceil(allDays.length / 7)));
    groupByWeeks[0] = {
      start: dailyRecordsPaginatedByMonth[0].day,
      end  : allDays[6].day,
    };

    groupByWeeks = groupByWeeks.map((value, index) => {
      if (index === 0) return value;
      return ({
        start: allDays[index * 7].day,
        end  : allDays[index * 7 + 6] ? allDays[index * 7 + 6].day : allDays[allDays.length - 1].day,
      });
    });

    return groupByWeeks.map((week) => (
      <div key={`week-${week.start}-${week.end}`} className={cn(styles.renderDays, { fadingLoader: isInProgress })}>
        { `${monthName} ${week.start}-${week.end}` }
      </div>
    ));
  }


  render() {
    const {
      dailyRecordsPaginatedByMonth,
    } = this.props;
    let date;
    if (dailyRecordsPaginatedByMonth && dailyRecordsPaginatedByMonth.length > 0) {
      date = moment(dailyRecordsPaginatedByMonth[0].date).format('MMMM YYYY');
    }
    return (
      <div className={styles.root}>
        <div className={styles.header}>
          <DaysSelectorControlBtn
            date={date}
            patient={this.props.patient}
            phiSet={this.props.phiSet}
            phiSetDocumentId={this.props.phiSetDocumentId}
          />
        </div>
        { this.renderWeekdays() }
        <div className="row flex-nowrap">
          <div className={cn('col-auto', styles.daysWrapper)}>
            { this.renderDays() }
          </div>
          <div className={cn('col', styles.content)}>
            { this.renderAgpDaySelector() }
          </div>
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  daysSelectorZoom            : selectors.daysSelectorZoom(state),
  highlightedDates            : selectors.highlightedDates(state),
  highlightedHourlyRecords    : selectors.highlightedHourlyRecords(state),
  dailyRecordsPaginatedByMonth: selectors.dailyRecordsPaginatedByMonth(state),
  direction                   : App.selectors.direction(state),
});


const mapDispatchToProps = (dispatch) => ({
  selectHighlightedDates: (range) => dispatch(actions.selectHighlightedDates(range)),
});


const ConnectedDaysSelector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DaysSelector);


export default withStyles(styles)(ConnectedDaysSelector);
