import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { MessageDescriptor } from 'react-intl';
import cn from 'classnames';
import { ProfileContext } from 'context';
import { useAction } from 'hooks';
import Account from 'modules/Account';
import App from 'modules/App';
import Meter from 'modules/Meter';
import Patient from 'modules/Patient';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { useIsPinnedMenu } from '../../hooks';
import MainLayout from '../../partials/MainLayout';
import PwdTopBar from './PwdTopBar';
import PwdSidebar from './PatientSidebar';
import ClosePrintModeBtn from './ClosePrintModeBtn';
import styles from './MainLayout.pcss';


interface Props {
  metaTitleMessage?: MessageDescriptor,
  metaDescriptionMessage?: MessageDescriptor,
  isReadOnly?: boolean,
}


const profileContext = {
  selectors: {
    activePatient   : Account.selectors.patientProfileExtended,
    phiSet          : Patient.selectors.phiSet,
    phiSetDocumentId: Patient.selectors.phiSetDocumentId,
    activeVisit     : () => null,
  },
  actions: {
    setReadings         : Patient.actions.setReadings,
    storeReadingsSuccess: Patient.actions.onStoreReadingsSuccess,
  },
};


const PwdMainLayout: FC<Props> = ({ metaTitleMessage, metaDescriptionMessage, isReadOnly, children }) => {
  const isPinnedMenu = useIsPinnedMenu();
  const menuOpen = useSelector(selectors.menuOpen);
  const selectedMenuItem = useSelector(selectors.selectedMenuItem);
  // TODO: Move into child components
  const activeProfileType = useSelector(Account.selectors.activeProfileType);
  const activeProfile = useSelector(Account.selectors.activeProfile);
  const isPatientAccount = useSelector(Account.selectors.isPatientAccount);
  const direction = useSelector(App.selectors.direction);
  const isPrintMode = useSelector(App.selectors.printMode);

  const setMenuOpen = useAction(actions.setMenuOpen);
  const setSelectedMenuItem = useAction(actions.setSelectedMenuItem);
  const closeDropdown = useAction(App.actions.closeDropdown);

  const onClickSidebar = (evt) => {
    evt.stopPropagation();
    closeDropdown();
  };

  return (
    <MainLayout {...{ metaTitleMessage, metaDescriptionMessage }}>
      <ProfileContext.Provider value={profileContext}>
        <div
          className={
            cn({
              [styles.container]      : !isPrintMode,
              [styles.layoutContainer]: !isPrintMode,
            })
          }
        >
          <PwdTopBar isReadOnly={isReadOnly} />
          <div
            className={
              cn({
                [styles.layout]: !isPrintMode,
              })
            }
          >
            <div
              className={
                cn({
                  'row no-gutters'         : !isPrintMode,
                  [styles.sidebarContainer]: !isPrintMode,
                })
              }
            >
              <div
                className={
                  cn(styles.sidebarHolder, {
                    [styles['sidebarHolder--extended']]: isPinnedMenu,
                  })
                }
                onClick={onClickSidebar}
                onKeyDown={undefined}
              >
                <div className={
                  cn('col-auto d-print-none', {
                    [styles.hide]: isPrintMode,
                  })
                }
                >
                  <PwdSidebar
                    isReadOnly={isReadOnly}
                    direction={direction}
                    setMenuOpen={setMenuOpen}
                    setSelectedMenuItem={setSelectedMenuItem}
                    menuOpen={menuOpen}
                    selectedMenuItem={selectedMenuItem}
                    isPinnedMenu={isPinnedMenu}
                  />
                </div>
              </div>
              <ClosePrintModeBtn />
            </div>
            <div className="content">
              <App.components.AlertsBus className="px-6" isGlobal />
              { children }
            </div>
          </div>
        </div>
        {
          isPatientAccount && (
            <>
              <Account.components.ReauthToCCModal />
              <Meter.components.PwdDownloadDataModal />
              <Patient.components.EnrollInClinicModal />
              <Patient.components.ConfirmDataSharingModal {...{ activeProfileType, activeProfile }} />
              <Patient.components.RevokeAccessModal />
            </>
          )
        }
        <Patient.components.InviteFamilyMembersModal />
        <Patient.components.RevokeFamilyAccessModal />
      </ProfileContext.Provider>
    </MainLayout>
  );
};

export default PwdMainLayout;
