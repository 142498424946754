import React, { FC, useState } from 'react';
import get from 'lodash/get';
import has from 'lodash/has';
import { CHANNEL_COMPONENTS } from '../../../constants';
import { useDevicesByChannel } from './hooks';
import BluetoothPin from './BluetoothPin';
import BluetoothPairingInstruction from './BluetoothPairingInstruction';
import ChooseConnection from './ChooseConnection';
import ConnectBlueCable from './ConnectBlueCable';
import ConnectBluetooth from './ConnectBluetooth';
import ConnectUsb from './ConnectUsb';
import InstallBlueCableDrivers from './InstallBlueCableDrivers';
import InstallingBlueCableDrivers from './InstallingBlueCableDrivers';
import InstalledBlueCableDrivers from './InstalledBlueCableDrivers';


interface Props {
  selectedChannel: string,
  onSetComponent: (componentName: string) => void,
  onSetStyleModifier: (styleModifier: string) => void,
  onSetChannel: (channel: string) => void,
}


const components = {
  BluetoothPin,
  BluetoothPairingInstruction,
  ChooseConnection,
  ConnectBlueCable,
  ConnectBluetooth,
  ConnectUsb,

  InstallBlueCableDrivers,
  InstallingBlueCableDrivers,
  InstalledBlueCableDrivers,
};


const Connect: FC<Props> = ({ selectedChannel, onSetComponent, onSetStyleModifier, onSetChannel }) => {
  const devicesByChannel = useDevicesByChannel();
  const defaultComponentName = selectedChannel ? CHANNEL_COMPONENTS[selectedChannel] : 'ChooseConnection';

  const [componentName, setComponentName] = useState<string>(defaultComponentName);

  const Component = get(components, componentName, null);

  const onSetComponentName = (newComponentName: string) => {
    if (!has(components, newComponentName)) {
      onSetComponent(newComponentName);
      return;
    }
    setComponentName(newComponentName);
  };

  return (
    <Component
      {...{
        selectedChannel,
        devicesByChannel,
        onSetComponent: onSetComponentName,
        onSetStyleModifier,
        onSetChannel,
      }}
    />
  );
};

export default Connect;
