import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import get from 'lodash/get';


/**
 * CompoundInput is a component to wrap different inputs, selects etc in one data structure inside FormGroup.
 *
 * @param {string} id - unique id of the inputs group
 * @param {string} children - form inputs components. These components must have explicitly defined unique id.
 *        In addition, beside normal properties, every component inside CompoundInput may have `containerClasses`
 *        property, which defines classes for special wrapper of that input.
 * @param {string} [value=""] - currently set values
 * @param {function} onChange - action that is triggered by value change
 */
class CompoundInput extends React.Component {

  static propTypes = {
    id      : PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]).isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    onChange: PropTypes.func.isRequired,
    hasError: PropTypes.bool,
  };

  static defaultProps = {
    value: {},
  };

  onChange(input) {
    const value = Object.assign({}, this.props.value, { [input.id]: input.value });
    return this.props.onChange({
      id: this.props.id,
      value,
    });
  }

  render() {
    return (
      <div className="row">
        {
          this.props.children.map((child) => (
            <div key={child.props.id} className={cn(child.props.containerClassName)}>
              {
                React.cloneElement(child, {
                  id      : child.props.id,
                  value   : get(this.props.value, child.props.id),
                  onChange: (input) => this.onChange(input),
                  hasError: this.props.hasError,
                })
              }
            </div>
          ))
        }
      </div>
    );
  }

}

export default CompoundInput;
