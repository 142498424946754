import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import { AppContext } from 'context';
import { getSlug } from 'helpers/urlTools';
import Account from 'modules/Account';
import Button from 'components/Form/Button';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import ActivePatients from './ActivePatients';
import PendingPatients from './PendingPatients';


class PatientsList extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    activeClinicMembership: Account.shapes.clinicMembership,
    isWithoutLoad         : PropTypes.bool,
    showMoreButton        : PropTypes.bool,
    showNoFindedResults   : PropTypes.bool,
    // Explicit props
    onShowMoreButtonClick : PropTypes.func,
    // Implicit props
    search                : PropTypes.string,
    // Implicit actions
    onFetchPatients       : PropTypes.func,
  };


  static defaultProps = {
    onShowMoreButtonClick: () => {},
  }


  constructor(props) {
    super(props);
    this.state = {
      enrollingPatientLength: 0,
      activePatientsLength  : 0,
      // activeView: 'all', // all, pending ('my' not implemented)
    };
    if (props.isWithoutLoad) {
      return;
    }
    this.onFetchPatients();
  }


  componentDidUpdate(prevProps) {
    const { activeClinicMembership } = this.props;
    const profilesReferenceKey = get(activeClinicMembership, 'clinic.profilesReferenceKey');
    const prevProfilesReferenceKey = get(prevProps.activeClinicMembership, 'clinic.profilesReferenceKey');
    if (prevProfilesReferenceKey !== profilesReferenceKey && profilesReferenceKey) {
      this.onFetchPatients();
    }
  }


  onFetchPatients() {
    const profilesReferenceKey = get(this.props.activeClinicMembership, 'clinic.profilesReferenceKey');
    if (process.env.BROWSER && profilesReferenceKey) {
      this.props.onFetchPatients(this.props.activeClinicMembership);
    }
  }


  renderShowMorButton() {
    const { showMoreButton } = this.props;
    const { activePatientsLength, enrollingPatientLength } = this.state;
    if (!showMoreButton || activePatientsLength + enrollingPatientLength < constants.PATIENTS_LIST_LENGTH) {
      return null;
    }
    const { organizationUID, name } = get(this.props.activeClinicMembership, 'clinic', {});
    const fullPatientsListHref = this.context.getUrl('patients-list', { clinicSlug: getSlug(name), organizationUID });

    return (
      <div className="d-flex justify-content-center">
        <Button
          to={fullPatientsListHref}
          type="link"
          styleModifier="primary"
          labelMessage={messages.buttons.showMore}
          className="btn--sm btn--white"
          onClick={() => this.props.onShowMoreButtonClick()}
        />
      </div>
    );
  }


  renderNoFindedResults() {
    const { showNoFindedResults, search } = this.props;
    const { activePatientsLength, enrollingPatientLength } = this.state;
    if (showNoFindedResults && activePatientsLength === 0 && enrollingPatientLength === 0 && !isEmpty(search)) {
      return <FormattedMessage {...messages.labels.noFindedPatients} />;
    }
    return null;
  }


  render() {
    return (
      <div>
        { this.renderNoFindedResults() }
        <PendingPatients
          {...this.props}
          setEnrollingPatientLength={(enrollingPatientLength) => this.setState({ enrollingPatientLength })}
          maxPatientLength={constants.PATIENTS_LIST_LENGTH}
        />
        <ActivePatients
          {...this.props}
          setActivePatientsLength={(activePatientsLength) => this.setState({ activePatientsLength })}
          maxPatientLength={constants.PATIENTS_LIST_LENGTH - this.state.enrollingPatientLength}
        />
        { this.renderShowMorButton() }
      </div>
    );
  }

}


const mapStateToProps = (state, props) => ({
  search: selectors.searchSelector(props.id)(state),
});


const mapDispatchToProps = (dispatch) => ({
  onFetchPatients: (clinicMembership) => Promise.all([
    dispatch(actions.fetchPatients(clinicMembership)),
    dispatch(actions.fetchEnrollingSharingRequests(clinicMembership)),
  ]),
});


const ConnectedPatientsList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PatientsList);


export default ConnectedPatientsList;
