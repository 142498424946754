import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import some from 'lodash/some';
import { createSelector } from 'reselect';
import { getSlug } from 'helpers/urlTools';
import { profile as profileCC } from 'modules/ContourCloud/selectors';
import { organizationMemberships, information } from 'modules/Information/selectors';
import * as actionTypes from './actionTypes';
import * as constants from './constants';

/**
 * Input selectors
 */
const mainSelector = (state) => state[constants.MODULE_ID];
const accountSelector = (state) => state[constants.MODULE_ID].account;
const fetchingSelector = (state) => state[constants.MODULE_ID].fetching;
const sendingSelector = (state) => state[constants.MODULE_ID].sending;
const errorsSelector = (state) => state[constants.MODULE_ID].errors;


/**
 * Select isAuthenticated flag
 */
export const isAuthenticated = createSelector(
  mainSelector,
  (state) => state.isAuthenticated,
);


/**
 * Selects if user has a HCP account
 */
export const isHcpAccount = createSelector(
  accountSelector,
  (state) => !!state && state.scope === constants.SCOPE_NAMES.PROFESSIONAL,
);


/**
 * Selects if user has a PWD account
 */
export const isPatientAccount = createSelector(
  accountSelector,
  (state) => !!state && state.scope === constants.SCOPE_NAMES.PERSONAL,
);


/**
 * Selects if user has a CG account
 */
export const isCareGiverAccount = createSelector(
  accountSelector,
  (state) => !!state && state.scope === constants.SCOPE_NAMES.CAREGIVER,
);


/**
 * Selects if user is a HCP
 */
export const isHcp = createSelector(
  mainSelector,
  (state) => !!state && !!state[constants.PROFILE_TYPES.HCP],
);


/**
 * Selects if user is a Patient
 */
export const isPatient = createSelector(
  mainSelector,
  (state) => !!state && !!state[constants.PROFILE_TYPES.PWD],
);


/**
 * Selects if user is a Care Giver
 */
export const isCareGiver = createSelector(
  mainSelector,
  (state) => !!state && !!state[constants.PROFILE_TYPES.CG],
);


/**
 * Selects user's account data
 */
export const account = createSelector(
  accountSelector,
  (state) => state,
);


/**
 * Selects user's account settings
 */
export const settings = createSelector(
  accountSelector,
  (state) => state && state.settings,
);


/**
 * Selects user's glucoseLevelUnitTypes settings
 */
export const glucoseLevelUnitTypesSettings = createSelector(
  settings,
  (state) => state && state.glucoseLevelUnitTypes,
);


/**
 * Selects user's notifications settings
 */
export const notificationsSettings = createSelector(
  settings,
  (state) => state && state.notifications,
);


/**
 * Selects user's main email
 */
export const email = createSelector(
  accountSelector,
  (state) => state && state.email,
);


/**
 * Selects user's prvKeyPem and pubKeyPem
 */
export const keyPair = createSelector(
  accountSelector,
  (state) => state && state.keyPair,
);


/**
 * Selects user's active profile type (HCP or PWD)
 */
export const activeProfileType = createSelector(
  mainSelector,
  (state) => state.activeProfileType,
);


/**
 * Selects user's active profile based on activeProfileType
 */
export const activeProfile = createSelector(
  mainSelector,
  activeProfileType,
  (state, activeProfileTypeState) => state[activeProfileTypeState],
);


/**
 * Selects user's hcp profile
 */
export const hcpProfile = createSelector(
  mainSelector,
  (state) => state[constants.PROFILE_TYPES.HCP],
);


/**
 * Selects user's patient profile
 */
export const patientProfile = createSelector(
  mainSelector,
  (state) => state[constants.PROFILE_TYPES.PWD],
);


/**
 * Selects user's caregiver profile
 */
export const cgProfile = createSelector(
  mainSelector,
  (state) => state[constants.PROFILE_TYPES.CG],
);


/**
 * Selects extended user's patient profile
 */
export const patientProfileExtended = createSelector(
  mainSelector,
  information,
  (
    state,
    informationState,
  ) => ({
    ...state[constants.PROFILE_TYPES.PWD],
    firstName  : get(informationState, 'firstName', ''),
    lastName   : get(informationState, 'lastName', ''),
    email      : get(informationState, 'email', ''),
    dateOfBirth: get(informationState, 'dateOfBirth', ''),
  }),
);


/**
 * Default settings for account country
 */
export const countrySettings = createSelector(
  mainSelector,
  (state) => state.countrySettings,
);


/**
 * Select custom patient identifiers dependent on country
 */
export const patientCustomIdentifiers = createSelector(
  mainSelector,
  (state) => state.patientCustomIdentifiers,
);


/**
 * Data from clinic Sharing Request to use in new PWD account creation
 */
export const patientDataFromInvitation = createSelector(
  mainSelector,
  (state) => state.patientDataFromInvitation,
);


/**
 * Health data from clinic Sharing Request to use in new PWD account creation
 */
export const patientHealthDataFromInvitation = createSelector(
  mainSelector,
  (state) => state.patientHealthDataFromInvitation,
);


/**
 * Select payers dependent on country
 */
export const payers = createSelector(
  mainSelector,
  (state) => state.payers,
);


/**
 * Select custom hcp identifiers dependent on country
 */
export const hcpCustomIdentifiers = createSelector(
  mainSelector,
  (state) => state.hcpCustomIdentifiers,
);


/**
 * Selects user's active clinic membership in professional clinic
 */
export const activeClinicMembershipId = createSelector(
  mainSelector,
  (state) => state.activeClinicMembershipId,
);


/**
 * Selects user's active organizationUID in professional clinic
 */
export const activeOrganizationUID = createSelector(
  mainSelector,
  (state) => state.activeOrganizationUID,
);


/**
 * Selects HCP's clinic memberships
 */
export const hcpClinicMemberships = createSelector(
  mainSelector,
  (state) => get(state, 'hcpProfile.clinicHcpMemberships', []),
);


/**
 * Selects HCP's organization memberships that are active
 */
export const organizationMembershipsActive = createSelector(
  organizationMemberships,
  (
    organizationMembershipsState,
  ) => filter(organizationMembershipsState, (organizationMembership) => (
    organizationMembership.status === 'Active'
    && organizationMembership.organization.status === 'Active'
  )),
);


/**
 * Selects HCP's organization memberships that aren't active yet
 */
export const organizationMembershipsPending = createSelector(
  organizationMemberships,
  (
    organizationMembershipsState,
  ) => filter(organizationMembershipsState, (organizationMembership) => (
    organizationMembership.status === 'Pending'
    || organizationMembership.organization.status === 'Pending'
  )),
);


/**
 * Selects HCP's organization memberships that don't have a corresponding clinic
 */
export const organizationMembershipsWithoutClinics = createSelector(
  hcpClinicMemberships,
  organizationMembershipsActive,
  (
    hcpClinicMembershipsState,
    organizationMembershipsActiveState,
  ) => filter(
    organizationMembershipsActiveState,
    (organizationMembership) => !some(hcpClinicMembershipsState, (hcpClinicMembership) => (
      hcpClinicMembership.clinic.organizationUID === organizationMembership.organization.organizationUID
    )),
  ),
);


/**
 * Selects user's active clinic id based on active clinic membership
 */
export const activeClinicId = createSelector(
  activeClinicMembershipId,
  hcpClinicMemberships,
  (
    activeClinicMembershipIdState,
    hcpClinicMembershipsState,
  ) => (
    get(find(hcpClinicMembershipsState, { clinicHcpMembershipId: activeClinicMembershipIdState }), 'clinic.clinicId')
  ),
);


/**
 * Selects HCP's clinic memberships
 */
export const countryId = createSelector(
  accountSelector,
  (state) => state.countryId,
);


/**
 * Selects PWD's glucose level targets
 */
export const glucoseLevelTargets = createSelector(
  accountSelector,
  (accountState) => accountState && accountState.glucoseLevelTargets,
);


/**
 * Selects PWD's external data sources ids
 */
export const externalDataSourcesIds = createSelector(
  accountSelector,
  (accountState) => accountState && accountState.externalDataSourcesIds,
);


/**
 * Selects if Account should change the password
 */
export const changePasswordReset = createSelector(
  mainSelector,
  (state) => state.changePasswordReset,
);


/**
 * Selects Contour Cloud access token
 */
export const accountCCAccessToken = createSelector(
  mainSelector,
  (state) => state.accountCCAccessToken,
);

/**
 * Selects HCP's active clinic memberships
 */
export const activeClinicMembership = createSelector(
  activeClinicMembershipId,
  hcpClinicMemberships,
  (
    activeClinicMembershipIdState,
    hcpClinicMembershipsState,
  ) => find(
    hcpClinicMembershipsState,
    (hcpClinicMembership) => hcpClinicMembership.clinicHcpMembershipId === activeClinicMembershipIdState
      && hcpClinicMembership.membershipStatus !== 'Revoked',
  ),
);


/**
 * Selects HCP's active organization memberships
 */
export const activeOrganizationMembership = createSelector(
  activeOrganizationUID,
  organizationMembershipsPending,
  (
    activeOrganizationUIDState,
    organizationMembershipsPendingState,
  ) => find(
    organizationMembershipsPendingState,
    (organizationMembership) => organizationMembership.organization.organizationUID === activeOrganizationUIDState,
  ),
);


/**
 * Selects if user is clinic admin
 */
export const isClinicAdmin = createSelector(
  activeClinicMembership,
  (activeClinicMembershipState) => {
    const { isAdmin, membershipStatus, clinic } = activeClinicMembershipState || {};
    return isAdmin && membershipStatus === 'Active'
      && clinic && includes(['Active', 'Deleted'], clinic.clinicStatus);
  },
);


/**
 * Returns UrlRoute object for hcp profile's main url
 */
export const hcpProfileMainUrlRoute = createSelector(
  hcpProfile,
  keyPair,
  activeClinicMembershipId,
  activeOrganizationUID,
  hcpClinicMemberships,
  organizationMembershipsActive,
  organizationMembershipsPending,
  organizationMembershipsWithoutClinics,
  changePasswordReset,
  (
    hcpProfileState,
    keyPairState,
    activeClinicMembershipIdState,
    activeOrganizationUIDState,
    hcpClinicMembershipsState,
    organizationMembershipsActiveState,
    organizationMembershipsPendingState,
    organizationMembershipsWithoutClinicsState,
    changePasswordResetState,
  ) => {
    if (changePasswordResetState) {
      return {
        name: 'set-new-password-after-reset',
      };
    }

    if (!hcpProfileState) {
      return {
        name: 'setup',
      };
    }

    if (organizationMembershipsWithoutClinicsState.length) {
      return {
        name: 'clinic-setup',
      };
    }

    const clinicMembership = find(
      hcpClinicMembershipsState,
      (hcpClinicMembership) => hcpClinicMembership.clinicHcpMembershipId === activeClinicMembershipIdState
        && hcpClinicMembership.membershipStatus !== 'Revoked',
    )
    || find(hcpClinicMembershipsState, (hcpClinicMembership) => hcpClinicMembership.membershipStatus !== 'Revoked');
    const organizationMembership = find(
      organizationMembershipsPendingState,
      (om) => om.organization.organizationUID === activeOrganizationUIDState,
    );

    const {
      organizationUID,
      name,
      storageProvider,
    } = get(organizationMembership, 'organization') || get(clinicMembership, 'clinic', {});

    if (clinicMembership && clinicMembership.isAdmin && !storageProvider) {
      return {
        name: 'add-drive',
      };
    }

    return {
      name   : 'general-population',
      params : { clinicSlug: getSlug(name), organizationUID },
      isFinal: true,
    };
  },
);


/**
 * Returns UrlRoute object for hcp patients's main url
 */
export const patientProfileMainUrlRoute = createSelector(
  patientProfile,
  keyPair,
  profileCC,
  changePasswordReset,
  (
    patientProfileState,
    keyPairState,
    profileCCState,
    changePasswordResetState,
  ) => {
    if (changePasswordResetState) {
      return {
        name: 'set-new-password-after-reset',
      };
    }

    if (profileCCState && profileCCState.status === 'Inactive') {
      return {
        name: 'confirm-email-cc',
      };
    }

    if (
      !patientProfileState
      || (!patientProfileState.encryptedExchangeToken && !patientProfileState.encryptedRefreshToken)
    ) {
      return {
        name      : 'setup',
        alternates: ['google-drive'],
      };
    }

    return {
      name   : 'my-results',
      isFinal: true,
    };
  },
);


/**
 * Returns UrlRoute object for hcp profile's main url
 */
export const cgProfileMainUrlRoute = createSelector(
  cgProfile,
  (
    cgProfileState,
  ) => {
    if (!cgProfileState) {
      return {
        name: 'setup',
      };
    }

    return {
      name   : 'my-results',
      isFinal: true,
    };
  },
);


/**
 * Returns UrlRoute object for current profile's main url
 */
export const activeProfileMainUrlRoute = createSelector(
  activeProfileType,
  hcpProfileMainUrlRoute,
  patientProfileMainUrlRoute,
  cgProfileMainUrlRoute,
  (activeProfileTypeState, hcpUrlRoute, patientUrlRoute, cgUrlRoute) => {
    switch (activeProfileTypeState) {
      case constants.PROFILE_TYPES.HCP: return hcpUrlRoute;
      case constants.PROFILE_TYPES.PWD: return patientUrlRoute;
      case constants.PROFILE_TYPES.CG: return cgUrlRoute;
      // TODO: default is dangerous in that form
      default: return { name: 'sign-in' };
    }
  },
);


/**
 * Selects passphrase
 */
export const passphrase = createSelector(
  mainSelector,
  (state) => state.passphrase,
);


export const metricsUnits = createSelector(
  mainSelector,
  (state) => state.metricsUnits,
);


export const gdprData = createSelector(
  mainSelector,
  (state) => state.gdprData,
);


export const tokenExpired = createSelector(
  mainSelector,
  (state) => state.tokenExpired,
);

//----------------------------------------------------------------------------------------------------------------------

export const isSignInInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.SIGN_IN),
);

export const hasSignInErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.SIGN_IN),
);

//----------------------------------------------------------------------------------------------------------------------

export const isSignOutInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.SIGN_OUT),
);

//----------------------------------------------------------------------------------------------------------------------

export const elevatedPermissionsMode = createSelector(
  mainSelector,
  (state) => state.elevatedPermissionsMode,
);

export const hasElevatedPermissions = createSelector(
  mainSelector,
  (state) => state.hasElevatedPermissions,
);

export const hasElevatePermissionsError = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.ELEVATE_PERMISSIONS),
);

//----------------------------------------------------------------------------------------------------------------------

export const isUpdateAccountSettingInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.UPDATE_ACCOUNT_SETTINGS),
);

export const hasUpdateAccountSettingsErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.UPDATE_ACCOUNT_SETTINGS),
);

//----------------------------------------------------------------------------------------------------------------------

export const isDeleteAccountInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.DELETE_ACCOUNT),
);

export const hasDeleteAccountErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.DELETE_ACCOUNT),
);

//----------------------------------------------------------------------------------------------------------------------

export const isUpdateHcpProfileInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.UPDATE_HCP_PROFILE),
);

export const hasUpdateHcpProfileErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.UPDATE_HCP_PROFILE),
);

//----------------------------------------------------------------------------------------------------------------------

export const isUpdatePatientProfileInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.UPDATE_PATIENT_PROFILE),
);

export const hasUpdatePatientProfileErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.UPDATE_PATIENT_PROFILE),
);

//----------------------------------------------------------------------------------------------------------------------

export const isUpdateCgProfileInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.UPDATE_CG_PROFILE),
);

export const hasUpdateCgProfileErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.UPDATE_CG_PROFILE),
);

//----------------------------------------------------------------------------------------------------------------------

export const isReauthorizePatientProfileCloudDriveInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.REAUTHORIZE_PATIENT_PROFILE_CLOUD_DRIVE),
);

export const hasReauthorizePatientProfileCloudDriveErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.REAUTHORIZE_PATIENT_PROFILE_CLOUD_DRIVE),
);

//----------------------------------------------------------------------------------------------------------------------

export const isConnectToClinicInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.CONNECT_TO_CLINIC),
);

export const hasConnectToClinicErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.CONNECT_TO_CLINIC),
);

//----------------------------------------------------------------------------------------------------------------------

export const isFetchMembershipsInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_MEMBERSHIPS),
);

export const hasFetchMembershipsErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.FETCH_MEMBERSHIPS),
);

//----------------------------------------------------------------------------------------------------------------------

export const isFetchAccountDumpInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.GDPR_FETCH_ACCOUNT_DUMP),
);

export const hasFetchAccountDumpErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.GDPR_FETCH_ACCOUNT_DUMP),
);

//----------------------------------------------------------------------------------------------------------------------

export const isConnectToPatientStorageInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.CONNECT_TO_PATIENT_STORAGE),
);

//----------------------------------------------------------------------------------------------------------------------

export const isFetchCountrySettingsInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_COUNTRY_SETTINGS),
);

export const hasFetchCountrySettingsErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.FETCH_COUNTRY_SETTINGS),
);

//----------------------------------------------------------------------------------------------------------------------

export const isUpdateNotificationsSettingsInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.UPDATE_NOTIFICATIONS_SETTINGS),
);

export const hasUpdateNotificationsSettingsErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.UPDATE_NOTIFICATIONS_SETTINGS),
);

//----------------------------------------------------------------------------------------------------------------------

export const isValidateMembershipInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.VALIDATE_MEMBERSHIPS),
);

export const hasValidateMembershipErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.VALIDATE_MEMBERSHIPS),
);

//----------------------------------------------------------------------------------------------------------------------
