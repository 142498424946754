import { call } from 'redux-saga/effects';
import ApiService from 'services/ApiService';


export default function* convertRefreshToken({ refreshToken, storageProvider, scope, controlId } = {}) {
  const requestUrl = '/api/StorageTokensNew/ConvertRefreshToken';
  const options = {
    method: 'POST',
    body  : {
      refreshToken,
      storageProvider,
      scope,
      controlId,
    },
  };
  return yield call(ApiService.regionalRequest, requestUrl, options);
}
