import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import get from 'lodash/get';
import cn from 'classnames';
import MeasurementsResult from 'svg/measurement-result.svg';
import ReadingFlagIcon from 'components/ReadingFlagIcon';
import * as shapes from '../../shapes';
import styles from './Reading.pcss';


class Reading extends React.PureComponent {

  static propTypes = {
    // Explicit props
    reading: shapes.reading.isRequired,
  };


  renderIcon() {
    const { reading } = this.props;
    const { flag, type, isActive } = reading;
    const measurementTypeClass = styles[`reading__icon--${type}`];

    return (
      <div
        className={cn(styles.reading__icon, measurementTypeClass, 'ReadingIcon', {
          [styles['reading__icon--active']]: isActive,
        })}
      >
        <ReadingFlagIcon
          width="13"
          height="13"
          flag={flag}
        />
      </div>
    );
  }


  renderValue() {
    const displayValue = get(this.props, 'reading.displayValue', 0);
    return (
      <div className={styles.reading__text}>
        { displayValue }
      </div>
    );
  }


  render() {
    const { reading } = this.props;
    const { type, isActive } = reading;

    const measurementTypeClass = styles[`reading__badge--${type}`];

    return (
      <div className={styles.reading}>
        <MeasurementsResult
          width="29"
          height="33"
          className={`${measurementTypeClass} ${isActive ? styles['reading__badge--active'] : ''}`}
        />
        { this.renderIcon() }
        { this.renderValue() }
      </div>
    );
  }


}


export default withStyles(styles)(Reading);
