import React, { FC, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import get from 'lodash/get';
import { AppContext } from 'context';
import { useAction } from 'hooks';
import copyTextToClipboardFallback from 'helpers/copyTextToClipboardFallback';
import Button from 'components/Form/Button';
import CopyIcon from 'svg/copy.svg';
import App from 'modules/App';
import Account from 'modules/Account';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from './SccInit.pcss';


interface SectionStepProps {
  stepNo: number,
}

interface ConnectionStepProps {
  onSetComponent: (componentName: string) => void,
}

interface Props {
  onSetComponent: (componentName: string) => void,
  onSetHeaderMessage: (headerMessage?: MessageDescriptor) => void,
}


const SectionStep: FC<SectionStepProps> = ({ stepNo }) => (
  <h4 className="text--bold"><FormattedMessage {...App.messages.labels.step} /> { stepNo }</h4>
);


const DownloadStep: FC = () => {
  const { apiUrl } = useContext(AppContext);
  const locale = useSelector(App.selectors.locale);
  const language = locale.slice(0, 2);
  const urlLocale = get(constants.SINOVO_LANGUAGES, language, 'EN');
  return (
    <div className="modal__section">
      <SectionStep stepNo={1} />
      <p className="modal__info"><FormattedMessage {...messages.sccInstall.downloadInfo} /></p>
      <Button
        type="link"
        to={`${apiUrl}/scc/SINOVOConnectionCenter-GCO-Setup-${urlLocale}.exe`}
        styleModifier="primary"
        labelMessage={messages.buttons.installScc}
        className="btn--block mb-0"
        data={{ download: true }}
      />
    </div>
  );
};


const CopyPairingCodeToClipboard: FC = () => {
  const machineId = useSelector(selectors.sccMachineId);
  const pairingCode = useSelector(selectors.sccPairingCode);

  if (machineId) {
    return (
      <span className="text--normal text--light text--small d-block py-3">
        <FormattedMessage {...messages.labels.continueToNextStep} />
      </span>
    );
  }

  const onCopyPairingCodeToClipboard = () => {
    if (!navigator.clipboard) {
      copyTextToClipboardFallback(pairingCode);
      return;
    }
    navigator.clipboard.writeText(pairingCode);
  };

  return (
    <Button
      styleModifier="transparent"
      className="btn--sm"
      onClick={onCopyPairingCodeToClipboard}
    >
      <span className="text--normal text--light text--small">
        <FormattedMessage {...App.messages.buttons.clickToCopy} />
      </span>
      <CopyIcon className={styles.copyIcon} />
    </Button>
  );
};


const PairingCode: FC = () => {
  const machineId = useSelector(selectors.sccMachineId);
  const pairingCode = useSelector(selectors.sccPairingCode);
  if (!machineId && !pairingCode) {
    return null;
  }

  return (
    <div className={styles.pairingCodeContainer}>
      <div className={styles.pairingCodeContainerInner}>
        <div>
          <p className={styles.pairingCode}>{ pairingCode }</p>
          <div>
            <CopyPairingCodeToClipboard />
          </div>
        </div>
      </div>
    </div>
  );
};


const PairingStep = () => {
  const b = (chunk) => <b>{ chunk }</b>;
  return (
    <div className="modal__section">
      <SectionStep stepNo={2} />
      <p className="modal__info">
        <FormattedMessage {...messages.sccInstall.pairingInfo} values={{ b }} />
      </p>
      <div className="row mt-6">
        <div className="col-6">
          <PairingCode />
        </div>
        <div className="col-6">
          <img src="/assets/svg/sinovo-otp.svg" alt="" />
        </div>
      </div>
    </div>
  );
};


const ConnectionStep: FC<ConnectionStepProps> = ({ onSetComponent }) => {
  const machineId = useSelector(selectors.sccMachineId);
  const btnLabelMessage = messages.buttons[machineId ? 'downloadData' : 'waitingForConnection'];

  const onConnect = () => {
    onSetComponent('Waiting');
  };

  return (
    <div className="modal__section">
      <SectionStep stepNo={3} />
      <p className="modal__info"><FormattedMessage {...messages.sccInstall.connectionInfo} /></p>
      <Button
        styleModifier="primary"
        labelMessage={btnLabelMessage}
        className="btn--block btn--filled mb-0"
        isDisabled={!machineId}
        onClick={onConnect}
      />
    </div>
  );
};


const InstallDownloader: FC<Props> = ({ onSetComponent, onSetHeaderMessage }) => {
  const activeClinicMembership: ClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const organizationUID = get(activeClinicMembership, 'clinic.organizationUID');
  const pair = useAction(actions.pairScc, organizationUID);
  const cancelPairing = useAction(actions.pairSccCancel);

  useEffect(() => {
    onSetHeaderMessage(messages.sccInstall.header);
    pair();
    return () => {
      onSetHeaderMessage();
      cancelPairing();
    };
  }, []);

  return (
    <div className={styles.modal__content}>
      <div className="mt-6">
        <DownloadStep />
        <PairingStep />
        <ConnectionStep {...{ onSetComponent }} />
      </div>
    </div>
  );
};


export default InstallDownloader;
