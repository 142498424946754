import * as actionTypes from './actionTypes';


export const manageOrganization = (organizationUID) => ({
  type   : actionTypes.MANAGE_ORGANIZATION,
  payload: {
    organizationUID,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchClinicCustomIdentifiers = (countryId) => ({
  type   : actionTypes.FETCH_CLINIC_CUSTOM_IDENTIFIERS,
  payload: {
    countryId,
  },
});

export const fetchClinicCustomIdentifiersSuccess = (
  clinicCustomIdentifiersCountryId,
  clinicCustomIdentifiers = [],
) => ({
  type   : actionTypes.FETCH_CLINIC_CUSTOM_IDENTIFIERS_SUCCESS,
  payload: {
    clinicCustomIdentifiersCountryId,
    clinicCustomIdentifiers,
  },
});

export const fetchClinicCustomIdentifiersError = (error) => ({
  type: actionTypes.FETCH_CLINIC_CUSTOM_IDENTIFIERS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const storeClinic = (clinicValues) => ({
  type   : actionTypes.STORE_CLINIC,
  payload: {
    clinicValues,
  },
});

export const getStoredClinic = () => ({
  type: actionTypes.GET_STORED_CLINIC,
});

export const storeClinicSuccess = (clinicValues = null) => ({
  type   : actionTypes.STORE_CLINIC_SUCCESS,
  payload: {
    clinicValues,
  },
});

export const storeClinicError = (error) => ({
  type: actionTypes.STORE_CLINIC_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const setClinicCloudDrive = (clinicValues) => ({
  type   : actionTypes.SET_CLINIC_CLOUD_DRIVE,
  payload: {
    clinicValues,
  },
});

export const setClinicCloudDriveSuccess = () => ({
  type: actionTypes.SET_CLINIC_CLOUD_DRIVE_SUCCESS,
});

export const setClinicCloudDriveError = (error) => ({
  type: actionTypes.SET_CLINIC_CLOUD_DRIVE_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const updateClinicGlucoseSettings = (clinicId, settings) => ({
  type   : actionTypes.UPDATE_CLINIC_GLUCOSE_SETTINGS,
  payload: {
    clinicId,
    settings,
  },
});

export const updateClinicGlucoseSettingsSuccess = (settings) => ({
  type   : actionTypes.UPDATE_CLINIC_GLUCOSE_SETTINGS_SUCCESS,
  payload: {
    settings,
  },
});

export const updateClinicGlucoseSettingsError = (error) => ({
  type: actionTypes.UPDATE_CLINIC_GLUCOSE_SETTINGS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------


export const updateClinicKpiSettings = (clinicId, settings) => ({
  type   : actionTypes.UPDATE_CLINIC_KPI_SETTINGS,
  payload: {
    clinicId,
    settings,
  },
});

export const updateClinicKpiSettingsSuccess = (settings) => ({
  type   : actionTypes.UPDATE_CLINIC_KPI_SETTINGS_SUCCESS,
  payload: {
    settings,
  },
});

export const updateClinicKpiSettingsError = (error) => ({
  type: actionTypes.UPDATE_CLINIC_KPI_SETTINGS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const reauthorizeClinicCloudDrive = (authorizationCode, clinicMembership) => ({
  type   : actionTypes.REAUTHORIZE_CLINIC_CLOUD_DRIVE,
  payload: {
    authorizationCode,
    clinicMembership,
  },
});

export const reauthorizeClinicCloudDriveSuccess = () => ({
  type: actionTypes.REAUTHORIZE_CLINIC_CLOUD_DRIVE_SUCCESS,
});

export const reauthorizeClinicCloudDriveError = (error) => ({
  type: actionTypes.REAUTHORIZE_CLINIC_CLOUD_DRIVE_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchOrganizationMemberships = (organizationUID, statuses = []) => ({
  type   : actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS,
  payload: {
    organizationUID,
    statuses,
  },
});

export const fetchOrganizationMembershipsSuccess = (organizationMemberships) => ({
  type   : actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS_SUCCESS,
  payload: {
    organizationMemberships,
  },
});

export const fetchOrganizationMembershipsError = (error) => ({
  type: actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const checkPatientsWithoutLeadingHcp = () => ({
  type: actionTypes.CHECK_PATIENTS_WITHOUT_LEADING_HCP,
});

export const checkPendingOrganizationMemberships = () => ({
  type: actionTypes.CHECK_PENDING_ORGANIZATION_MEMBERSHIPS,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchClinicMemberships = (clinicId) => ({
  type   : actionTypes.FETCH_CLINIC_MEMBERSHIPS,
  payload: {
    clinicId,
  },
});

export const fetchClinicMembershipsSuccess = (clinicMemberships) => ({
  type   : actionTypes.FETCH_CLINIC_MEMBERSHIPS_SUCCESS,
  payload: {
    clinicMemberships,
  },
});

export const fetchClinicMembershipsError = (error) => ({
  type: actionTypes.FETCH_CLINIC_MEMBERSHIPS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const removeClinicData = (clinicMembership) => ({
  type   : actionTypes.REMOVE_CLINIC_DATA,
  payload: {
    clinicMembership,
  },
});

export const removeClinicPatientDataSuccess = () => ({
  type: actionTypes.REMOVE_CLINIC_PATIENT_DATA_SUCCESS,
});

export const removeClinicDataPause = () => ({
  type: actionTypes.REMOVE_CLINIC_DATA_PAUSE,
});

export const removeClinicDataSuccess = () => ({
  type: actionTypes.REMOVE_CLINIC_DATA_SUCCESS,
});

export const removeClinicDataError = (error) => ({
  type: actionTypes.REMOVE_CLINIC_DATA_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const setPatientsCount = (patientsCount) => ({
  type   : actionTypes.SET_PATIENTS_COUNT,
  payload: {
    patientsCount,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const checkImportPatientDatabase = (database, clinicMembership) => ({
  type   : actionTypes.IMPORT_PATIENT_DATABASE,
  payload: {
    database,
    clinicMembership,
    isCheckOnly: true,
  },
});

export const importPatientDatabase = (database, clinicMembership, page = 1, pageSize = 20) => ({
  type   : actionTypes.IMPORT_PATIENT_DATABASE,
  payload: {
    database,
    clinicMembership,
    page,
    pageSize,
  },
});


export const importPatientDatabasePatientStored = ({ successCount, errorCount, errorsList } = {}) => ({
  type   : actionTypes.IMPORT_PATIENT_DATABASE_PATIENT_STORED,
  payload: {
    successCount,
    errorCount,
    errorsList,
  },
});


export const importPatientDatabaseCancel = () => ({
  type: actionTypes.IMPORT_PATIENT_DATABASE_CANCEL,
});

export const importPatientDatabaseEnd = (noReset = false) => ({
  type   : actionTypes.IMPORT_PATIENT_DATABASE_END,
  payload: {
    noReset,
  },
});

export const importPatientDatabaseSuccess = (progress = 0) => ({
  type   : actionTypes.IMPORT_PATIENT_DATABASE_SUCCESS,
  payload: {
    progress,
  },
});

export const importPatientDatabaseError = (error) => ({
  type: actionTypes.IMPORT_PATIENT_DATABASE_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const setClinicLicence = (clinicId, licenceKey) => ({
  type   : actionTypes.SET_CLINIC_LICENCE,
  payload: {
    clinicId,
    licenceKey,
  },
});

export const setClinicLicenceSuccess = (notificationsSettings) => ({
  type   : actionTypes.SET_CLINIC_LICENCE_SUCCESS,
  payload: {
    notificationsSettings,
  },
});

export const setClinicLicenceError = (error) => ({
  type: actionTypes.SET_CLINIC_LICENCE_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchClinicCountrySettings = (countryId) => ({
  type   : actionTypes.FETCH_CLINIC_COUNTRY_SETTINGS,
  payload: {
    countryId,
  },
});

export const fetchClinicCountrySettingsSuccess = (countrySettings) => ({
  type   : actionTypes.FETCH_CLINIC_COUNTRY_SETTINGS_SUCCESS,
  payload: {
    countrySettings,
  },
});

export const fetchClinicCountrySettingsError = (error) => ({
  type: actionTypes.FETCH_CLINIC_COUNTRY_SETTINGS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchClinicSettings = (clinicId) => ({
  type   : actionTypes.FETCH_CLINIC_SETTINGS,
  payload: {
    clinicId,
  },
});

export const fetchClinicSettingsSuccess = (clinicSettings) => ({
  type   : actionTypes.FETCH_CLINIC_SETTINGS_SUCCESS,
  payload: {
    clinicSettings,
  },
});

export const fetchClinicSettingsError = (error) => ({
  type: actionTypes.FETCH_CLINIC_SETTINGS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------
