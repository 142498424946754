import React, { FC, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import get from 'lodash/get';
import has from 'lodash/has';
import styles from './SccInit.pcss';
import Initializing from './Initializing';
import InstallDownloader from './InstallDownloader';
import NoConnection from './NoConnection';


interface Props {
  onSetComponent: (componentName: string) => void,
}


const components = {
  Initializing,
  InstallDownloader,
  NoConnection,
};


const SccInit: FC<Props> = ({ onSetComponent, ...props }) => {
  const [componentName, setComponentName] = useState<string>('Initializing');

  useStyles(styles);

  const Component = get(components, componentName, null);

  const onSetComponentName = (newComponentName: string) => {
    if (!has(components, newComponentName)) {
      onSetComponent(newComponentName);
      return;
    }
    setComponentName(newComponentName);
  };

  const onInitialized = () => {
    onSetComponent('Waiting');
  };

  return <Component {...{ onInitialized, onSetComponent: onSetComponentName, ...props }} />;
};

export default SccInit;
