import includes from 'lodash/includes';
import { createSelector } from 'reselect';
import * as actionTypes from './actionTypes';
import * as constants from './constants';


/**
 * Direct selector to the domain's state
 */
const fetchingSelector = (state) => state[constants.MODULE_ID].fetching;
const errorsSelector = (state) => state[constants.MODULE_ID].errors;


export const isDownloadPdfInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.DOWNLOAD_PDFS),
);

export const hasDownloadPdfErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.DOWNLOAD_PDFS),
);
