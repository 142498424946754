import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import get from 'lodash/get';
import { AppContext } from 'context';
import { getSlug } from 'helpers/urlTools';
import Link from 'components/Link';
import clinicShape from 'shapes/clinicShape';
import intlShape from 'shapes/intlShape';
import ClinicSettings from 'svg/clinic-settings.svg';
import messages from '../../messages';
import styles from './ClinicSettingsIconLink.pcss';


class ClinicSettingsIconLink extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    clinicMembership: PropTypes.shape({
      clinic: clinicShape,
    }).isRequired,
    // Implicit props
    intl: intlShape,
  };


  render() {
    const { organizationUID, name } = get(this.props.clinicMembership, 'clinic', {});
    return (
      <Link
        to={this.context.getUrl('clinic-settings', { clinicSlug: getSlug(name), organizationUID })}
        className={styles.link}
        data-for="globalTooltip"
        data-tip={this.props.intl.formatMessage(messages.headers.clinicSettings)}
      >
        <ClinicSettings className={styles.link__icon} />
      </Link>
    );
  }

}

export default withStyles(styles)(injectIntl(ClinicSettingsIconLink));
