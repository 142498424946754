import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import isUndefined from 'lodash/isUndefined';
import FormContainerAbstract from 'components/FormContainerAbstract';
import Form from 'components/Form';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import Button from 'components/Form/Button';
import Modal from 'components/Modal';
import App from 'modules/App';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import validatorRules from './validatorRules.json';


class ElevatePermissionsModal extends FormContainerAbstract {

  static getDerivedStateFromProps(props, state) {
    const { hasErrors, hasElevatePermissionsError } = props;

    if (state.isInProgress && (hasErrors || hasElevatePermissionsError)) {
      return { isInProgress: false };
    }

    return null;
  }

  static propTypes = {
    // Explicit props
    actionMessage             : PropTypes.node,
    infoMessage               : PropTypes.node,
    submitButtonMessage       : PropTypes.node,
    isInProgress              : PropTypes.bool,
    hasErrors                 : PropTypes.bool,
    // Implicit props
    openModalId               : PropTypes.string,
    formValues                : PropTypes.object,
    elevatedPermissionsMode   : PropTypes.string,
    hasElevatedPermissions    : PropTypes.bool,
    // Implicit actions
    onEnterPassword           : PropTypes.func,
    onOpenModal               : PropTypes.func,
    onCloseModal              : PropTypes.func,
    onSetFormValue            : PropTypes.func,
    onFormErrors              : PropTypes.func,
    onFormProcessing          : PropTypes.func,
    onClearForm               : PropTypes.func,
    setElevatedPermissionsMode: PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.validatorRules = validatorRules;
    this.state = {
      isInProgress: false,
    };
  }


  componentDidUpdate(prevProps) {
    const {
      elevatedPermissionsMode,
      hasElevatedPermissions,
      openModalId,
      isInProgress,
      hasErrors,
    } = this.props;

    if (
      prevProps.elevatedPermissionsMode !== elevatedPermissionsMode
        || prevProps.hasElevatedPermissions !== hasElevatedPermissions
    ) {
      if (
        !openModalId
        && elevatedPermissionsMode
        && !hasElevatedPermissions
      ) {
        this.props.onOpenModal();
      } else if (
        openModalId === constants.ELEVATE_PERMISSIONS_MODAL
        && elevatedPermissionsMode
        && hasElevatedPermissions
        && (isUndefined(isInProgress) || !this.state.isInProgress)
        && !hasErrors
      ) {
        this.onClose();
      } else if (
        openModalId === constants.ELEVATE_PERMISSIONS_MODAL
        && !elevatedPermissionsMode
      ) {
        this.onClose();
      }
    }
  }


  onSubmit() {
    this.props.onFormProcessing();
    this.setState({ isInProgress: true });
    const { validatedValues, errors } = this.onValidate(this.validatorRules);
    if (errors) {
      this.props.onFormErrors(errors);
      return;
    }
    const { password } = validatedValues;
    this.props.onEnterPassword(password);
    this.props.onClearForm();
  }


  onCancel() {
    this.props.setElevatedPermissionsMode(null);
    this.onClose();
  }


  async onClose() {
    await this.props.onCloseModal();
    this.setState({ isInProgress: false });
  }


  renderActions() {
    return (
      <div className="row">
        <div className="col-6">
          <Button
            styleModifier="quaternary"
            labelMessage={messages.buttons.cancel}
            className="btn--block"
            onClick={() => this.onCancel()}
          />
        </div>
        <div className="col-6">
          <Button
            id="confirmElevatePermissions"
            type="submit"
            styleModifier="primary"
            className="btn--block btn--filled"
            isDisabled={this.isDisabled}
            isInProgress={this.state.isInProgress}
            onClickDisabled={() => this.onCheck()}
          >
            {this.props.submitButtonMessage || <FormattedMessage {...messages.buttons.submit} />}
          </Button>
        </div>
      </div>
    );
  }


  renderForm() {
    return (
      <Form onSubmit={() => this.onSubmit()}>
        <FormGroup
          id="password"
          labelMessage={messages.labels.password}
          formValues={this.props.formValues}
        >
          <Input
            type="password"
            placeholder={messages.placeholders.password}
            onChange={this.props.onSetFormValue}
            attributes={{ autoFocus: true }}
          />
        </FormGroup>
        { this.renderActions() }
      </Form>
    );
  }


  render() {
    const { actionMessage } = this.props;
    return (
      <Modal
        modalId={constants.ELEVATE_PERMISSIONS_MODAL}
        openModalId={this.props.openModalId}
        styleModifier="md"
        onClose={() => this.onCancel()}
      >
        <h2 className="modal__header">
          {actionMessage}
        </h2>
        <h3 className="modal__info mb-5">
          {this.props.infoMessage || <FormattedMessage {...messages.infos.enterPasswordToAllow} />}
        </h3>
        <App.components.AlertsBus className="mb-4" />
        { this.renderForm() }
      </Modal>
    );
  }

}


const mapStateToProps = () => {
  const getForm = App.selectors.formSelector(constants.ELEVATE_PERMISSIONS_FORM);

  return (state, props) => ({
    formValues                : getForm(state, props),
    openModalId               : App.selectors.modal(state, props),
    elevatedPermissionsMode   : selectors.elevatedPermissionsMode(state),
    hasElevatedPermissions    : selectors.hasElevatedPermissions(state),
    hasElevatePermissionsError: selectors.hasElevatePermissionsError(state),
  });
};


const mapDispatchToProps = (dispatch) => {
  const formName = constants.ELEVATE_PERMISSIONS_FORM;

  return {
    onEnterPassword           : (password) => dispatch(actions.elevatePermissions({ password })),
    setElevatedPermissionsMode: (value) => dispatch(actions.setElevatedPermissionsMode(value)),
    onOpenModal               : () => dispatch(App.actions.openModal(constants.ELEVATE_PERMISSIONS_MODAL)),
    onCloseModal              : () => dispatch(App.actions.closeModal()),
    onSetFormValue            : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onFormErrors              : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onFormProcessing          : () => dispatch(App.actions.startFormProcessing(formName)),
    onClearForm               : () => dispatch(App.actions.clearForm(formName)),
  };
};


const ConnectedElevatePermissionsModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ElevatePermissionsModal);


export default ConnectedElevatePermissionsModal;
