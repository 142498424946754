import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import get from 'lodash/get';
import Avatar from 'components/Avatar';
import Button from 'components/Form/Button';
import Modal from 'components/Modal';
import App from 'modules/App';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from '../ModalCommon.pcss';


class RevokeFamilyAccessModal extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const { isInProgress, hasErrors } = props;

    if (isInProgress !== state.isInProgress && isInProgress) {
      return { isInProgress: true };
    }
    if (hasErrors && state.isInProgress) {
      return { isInProgress: false };
    }
    return null;
  }


  static propTypes = {
    // Implicit props
    isInProgress: PropTypes.bool,
    hasErrors   : PropTypes.bool,
    formValues  : PropTypes.object,
    openModalId : PropTypes.string,
    // Implicit actions
    onRevoke    : PropTypes.func,
    onCloseModal: PropTypes.func,
    onClearForm : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      isInProgress: false,
    };
  }


  componentDidUpdate(prevProp) {
    const { isInProgress, hasErrors } = this.props;
    if (prevProp.isInProgress !== isInProgress && !isInProgress && !hasErrors) {
      this.onClose();
    }
  }


  async onClose() {
    await this.props.onCloseModal();
    this.props.onClearForm();
    this.setState({ isInProgress: true });
  }


  onRevokeAccess() {
    const familyLink = get(this.props.formValues, 'contextData');
    this.props.onRevoke(familyLink);
  }


  renderActions() {
    return (
      <div className="modal__actions row">
        <div className="col-6">
          <Button
            styleModifier="quaternary"
            labelMessage={messages.buttons.noKeepAccess}
            className="btn--block"
            onClick={() => this.onClose()}
          />
        </div>
        <div className="col-6">
          <Button
            styleModifier="primary"
            labelMessage={messages.buttons.yesRevokeAccess}
            className="btn--block btn--filled"
            isInProgress={this.state.isInProgress}
            onClick={() => this.onRevokeAccess()}
          />
        </div>
      </div>
    );
  }


  renderPerson() {
    const familyLink = get(this.props.formValues, 'contextData');
    const { avatar, firstName, lastName } = get(familyLink, 'requestor', {});
    return (
      <div className={cn('row align-items-center', styles.person)}>
        <div className="col-auto pr-0">
          <Avatar
            avatarImg={avatar}
            name={[firstName, lastName]}
            className={styles.item__avatar}
            imgClassName={styles.item__avatar__img}
            initialsClassName={styles.item__avatar__initials}
          />
        </div>
        <div className="col">
          <p className={styles.person__name}>{ firstName } { lastName }</p>
        </div>
      </div>
    );
  }


  render() {
    return (
      <Modal
        modalId={constants.REVOKE_FAMILY_ACCESS_MODAL}
        openModalId={this.props.openModalId}
        styleModifier="md"
        headerMessage={messages.headers.revokeAccess}
        onClose={this.props.onCloseModal}
      >
        <div className={styles.container}>
          <FormattedMessage {...messages.infos.areYouSureToRevokeAccess} values={{ person: this.renderPerson() }} />
        </div>
        { this.renderActions() }
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  formValues  : App.selectors.formSelector(constants.REVOKE_FAMILY_ACCESS_FORM)(state),
  openModalId : App.selectors.modal(state),
  isInProgress: selectors.isRevokeFamilyAccessInProgress(state),
  hasErrors   : selectors.hasRevokeFamilyAccessErrors(state),
});


const mapDispatchToProps = (dispatch) => ({
  onRevoke    : (familyLink) => dispatch(actions.revokeFamilyAccess(familyLink)),
  onCloseModal: () => dispatch(App.actions.closeModal()),
  onClearForm : () => dispatch(App.actions.clearForm(constants.REVOKE_FAMILY_ACCESS_FORM)),
});


const ConnectedRevokeFamilyAccessModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RevokeFamilyAccessModal);


export default withStyles(styles)(ConnectedRevokeFamilyAccessModal);
