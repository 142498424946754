import React from 'react';
import { batch, connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import { highlightedHourlyRecordsShape, highlightedRecordsShape } from 'shapes/bins';
import App from 'modules/App';
import BloodGlucoseProfileChart from 'components/Charts/BloodGlucoseProfileChart';
import BloodGlucoseProfileTimeChart from 'components/Charts/BloodGlucoseProfileTimeChart';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as constants from '../../constants';
import messages from '../../messages';
import DaysSelector from '../../partials/DaysSelector';
import styles from './ProfileChart.pcss';


class ProfileChart extends React.PureComponent {

  static propTypes = {
    // Implicit props
    highlightedHourlyRecords  : highlightedHourlyRecordsShape,
    highlightedRecords        : highlightedRecordsShape,
    isDisplayedAgpProfileChart: PropTypes.bool,
    // Implicit actions
    resetHighlightedDate      : PropTypes.func,
    setHighlightedDate        : PropTypes.func,
    setAllHighlightedDates    : PropTypes.func,
    // Explicit props
    conversion                : PropTypes.object.isRequired,
    standards                 : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    direction    : PropTypes.string,
    mode         : PropTypes.oneOf(constants.MODES),
    start        : PropTypes.number,
    end          : PropTypes.number,
    // Explicit actions
    onDatesChange: PropTypes.func,
  };


  renderUnit(conversion) {
    return (
      <p className={styles.unit}>
        <FormattedMessage
          {...messages.labels.unit}
          values={{
            unit: conversion.unitSymbol,
          }}
        />
      </p>
    );
  }


  renderMainChart() {
    const {
      conversion,
      standards,
      highlightedHourlyRecords,
      direction,
      mode,
      highlightedRecords,
      isDisplayedAgpProfileChart,
    } = this.props;

    switch (mode) {
      case 'ALL': return (
        <>
          {this.renderUnit(conversion)}
          <BloodGlucoseProfileTimeChart
            conversion={conversion}
            standards={standards}
            records={highlightedRecords}
            direction={direction}
          />
        </>
      );
      default: return isDisplayedAgpProfileChart ? (
        <>
          {this.renderUnit(conversion)}
          <BloodGlucoseProfileChart
            conversion={conversion}
            standards={standards}
            highlightedHourlyRecords={highlightedHourlyRecords}
            direction={direction}
          />
        </>
      ) : (
        <div className={styles.insufficiencyInfo}>
          <img className="blob" src="/assets/svg/placeholder-blob.svg" alt="" />
          <p className="text">
            <FormattedMessage {...messages.labels.insufficiencyInfo} />
          </p>
        </div>
      );
    }
  }


  render() {
    const {
      conversion,
      standards,
      direction,
    } = this.props;
    return (
      <div className="chartContainer">
        { this.renderMainChart()}
        <h2 className={styles.subsection}>
          <FormattedMessage {...messages.headers.measurementsTable} />
        </h2>
        <DaysSelector
          conversion={conversion}
          standards={standards}
          direction={direction}
          onDatesChange={this.props.onDatesChange}
        />
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  dailyRecords              : selectors.dailyRecords(state),
  highlightedHourlyRecords  : selectors.highlightedHourlyRecords(state),
  highlightedRecords        : selectors.highlightedRecords(state),
  isDisplayedAgpProfileChart: selectors.isDisplayedAgpProfileChart(state),
  direction                 : App.selectors.direction(state),
});


const mapDispatchToProps = (dispatch) => ({
  resetHighlightedDate  : (date) => dispatch(actions.resetHighlightedDate(date)),
  setHighlightedDate    : (date) => dispatch(actions.setHighlightedDate(date)),
  setAllHighlightedDates: (dates) => batch(() => {
    dispatch(actions.clearHighlightedDates());
    dispatch(actions.setHighlightedDates(dates));
  }),
});


const ConnectedProfileChart = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileChart);


export default withStyles(styles)(ConnectedProfileChart);
