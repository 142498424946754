import moment from 'moment';
import { formatDate } from '../../helpers/datetime';
import * as actionTypes from './actionTypes';


const initialState = {
  menuOpen        : false,
  selectedMenuItem: null,
  agpView         : false,
  hideEmptyRows   : false,
  showDayDividers : false,
  fetching        : [],
  sending         : [],
  errors          : [],
  windowWidth     : 0,
};


export default function reducer(state = { ...initialState }, action) {

  switch (action.type) {

    case actionTypes.SET_MENU_OPEN: {
      const { menuOpen } = action.payload;
      return {
        ...state,
        menuOpen,
      };
    }
    case actionTypes.SET_SELECTED_MENU_ITEM: {
      const { selectedMenuItem } = action.payload;
      return {
        ...state,
        selectedMenuItem,
      };
    }
    case actionTypes.SET_AGP_VIEW: {
      const { agpView } = action.payload;
      return {
        ...state,
        agpView,
      };
    }
    case actionTypes.SET_WINDOW_WIDTH: {
      const { windowWidth } = action.payload;
      return {
        ...state,
        windowWidth,
      };
    }
    case actionTypes.SET_HIDE_EMPTY_ROWS: {
      const { hideEmptyRows } = action.payload;
      return {
        ...state,
        hideEmptyRows,
      };
    }
    case actionTypes.SET_SHOW_DAY_DIVIDERS: {
      const { showDayDividers } = action.payload;
      return {
        ...state,
        showDayDividers,
      };
    }
    default: {
      return state;
    }

  }
}
