import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import Button from 'components/Form/Button';
import Account from 'modules/Account';
import Hcp from 'modules/Hcp';
import messages from '../../messages';
import styles from './DownloadDataModal.pcss';


interface PatientsProps {
  onSetComponent: (componentName: string) => void,
}

interface Props {
  onSetComponent: (componentName: string) => void,
  onSetHeaderMessage: (headerMessage?: MessageDescriptor) => void,
  onCancel: () => void,
}


const PatientsHeader: FC<PatientsProps> = ({ onSetComponent }) => {
  const onAddPatient = () => onSetComponent('AddPatient');

  return (
    <div className={styles.patients__head}>
      <div className="row align-items-center">
        <div className="col">
          <h3 className="modal__subheader "><FormattedMessage {...messages.choosePatient.listHeader} /></h3>
        </div>
        <div className="col text--right">
          <Button
            styleModifier="transparent"
            labelMessage={messages.buttons.addPatient}
            className="btn--no-size text--primary m-0"
            onClick={onAddPatient}
          />
        </div>
      </div>
    </div>
  );
};


const PatientsList: FC<PatientsProps> = ({ onSetComponent }) => {
  const activeClinicMembership: ClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const onChoosePatient = () => onSetComponent('DownloadSummary');

  return (
    <div>
      <div className={styles.patients__searchContainer}>
        <Hcp.partials.SearchPatients forId="meterList" activeClinicMembership={activeClinicMembership} />
      </div>
      <div className={styles.patients__listContainer}>
        <Hcp.partials.ActivePatients
          id="meterList"
          activeClinicMembership={activeClinicMembership}
          styleModifier="halfSpace"
          isWithoutLoad
          onClick={onChoosePatient}
        />
      </div>
    </div>
  );
};


const Patients: FC<PatientsProps> = ({ onSetComponent }) => (
  <div className={styles.patients}>
    <PatientsHeader {...{ onSetComponent }} />
    <PatientsList {...{ onSetComponent }} />
  </div>
);


const ChoosePatient: FC<Props> = ({ onSetComponent, onSetHeaderMessage, onCancel }) => {
  useEffect(() => {
    onSetHeaderMessage(messages.choosePatient.header);
    return () => onSetHeaderMessage();
  }, []);

  return (
    <div>
      <p className="modal__info"><FormattedMessage {...messages.choosePatient.info} /></p>
      <Patients {...{ onSetComponent }} />
      <Button
        styleModifier="primary"
        labelMessage={messages.buttons.cancelImport}
        className="btn--block mb-0"
        onClick={onCancel}
      />
    </div>
  );
};

export default ChoosePatient;
