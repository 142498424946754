import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { nl2br } from 'helpers/transformers';
import intlShape from 'shapes/intlShape';
import messages from '../../messages';
import Error from './Error';


class GeneralError extends React.PureComponent {

  static propTypes = {
    code: PropTypes.number,
    intl: intlShape,
  };


  render() {
    const { intl } = this.props;
    const info = intl.formatMessage(messages.errorPage.general.info);
    return (
      <Error
        code={this.props.code}
        codeLabel={intl.formatMessage(messages.errorPage.errorCode)}
        title={intl.formatMessage(messages.errorPage.general.title)}
        info={<p>{nl2br(info, 'mb-4')}</p>}
        buttonLabel={intl.formatMessage(messages.errorPage.goToHomePage)}
      />
    );
  }

}

export default injectIntl(GeneralError);
