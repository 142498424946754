import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import get from 'lodash/get';
import filter from 'lodash/filter';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
import MiniSearch from 'minisearch';
import withStyles from 'isomorphic-style-loader/withStyles';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { getSlug } from 'helpers/urlTools';
import Avatar from 'components/Avatar';
import ClinicSettings from 'svg/clinic-settings.svg';
import PeopleIcon from 'svg/people-icon.svg';
import PatientsIcon from 'svg/patients-icon.svg';
import AddPatientIcon from 'svg/invite-icon.svg';
import ClinicAdd from 'svg/clinic-add.svg';
import intlShape from 'shapes/intlShape';
import App from 'modules/App';
import Account from 'modules/Account';
import Hcp from 'modules/Hcp';
import * as constants from '../../../constants';
import messages from '../../../messages';
import Sidebar from '../Sidebar';
import MoreClinicItem from './MoreClinicItem';
import styles from './HcpSidebar.pcss';


class HcpSidebar extends Sidebar {

  static propTypes = {
    // Explicit props
    direction          : PropTypes.string,
    menuOpen           : PropTypes.bool,
    selectedMenuItem   : PropTypes.string,
    // Explicit actions
    setMenuOpen        : PropTypes.func,
    setSelectedMenuItem: PropTypes.func,
    // Implicit props
    activePatient      : PropTypes.shape({
      id       : PropTypes.string,
      avatar   : PropTypes.string,
      email    : PropTypes.string,
      firstName: PropTypes.string,
      lastName : PropTypes.string,
    }),
    hcpClinicMemberships        : PropTypes.arrayOf(Account.shapes.clinicMembership),
    organizationMemberships     : PropTypes.arrayOf(PropTypes.object),
    activeClinicMembershipId    : PropTypes.number,
    activeOrganizationUID       : PropTypes.string,
    activeClinicMembership      : Account.shapes.clinicMembership,
    activeOrganizationMembership: PropTypes.object,
    isClinicAdmin               : PropTypes.bool,
    passphrase                  : PropTypes.string,
    isConnectToClinicInProgress : PropTypes.bool,
    hasConnectToClinicErrors    : PropTypes.bool,
    isClientInitialized         : PropTypes.bool,
    openModalId                 : PropTypes.string,
    route                       : App.shapes.route,
    intl                        : intlShape,
    search                      : PropTypes.string,
    // Implicit actions
    onAddPatient                : PropTypes.func,
    onAddMembership             : PropTypes.func,
    onFetchMemberships          : PropTypes.func,
    onConnect                   : PropTypes.func,
    onFetchPatients             : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.memberships = [];
    this.membershipOrganizations = [];
  }


  componentDidMount() {
    this.miniSearch = new MiniSearch({
      idField     : 'organizationUID',
      fields      : ['name', 'clinicId', 'organizationUID', 'zipCode', 'city', 'clinicIdentifiers', 'street'],
      extractField: (document, fieldName) => {
        if (fieldName === 'clinicIdentifiers') {
          const { customClinicIdentifiersValues } = document;
          return customClinicIdentifiersValues && customClinicIdentifiersValues.toString();
        }
        return document[fieldName];
      },
      searchOptions: {
        boost : { clinicId: 3, name: 2, city: 2 },
        fuzzy : 1,
        prefix: true,
      },
    });
    this.setMemberships();
    if (this.props.passphrase) {
      this.onFetchMemberships();
      this.onFetchPatients();
    }
  }


  componentDidUpdate(prevProps) {
    const {
      hcpClinicMemberships,
      activeOrganizationUID,
      organizationMemberships,
      activeClinicMembership,
      passphrase,
      route,
      isConnectToClinicInProgress,
      hasConnectToClinicErrors,
      activePatient,
    } = this.props;
    if (route.name !== prevProps.route.name) {
      this.onHideAllMenu();
    }
    const profilesReferenceKey = get(activeClinicMembership, 'clinic.profilesReferenceKey');
    const prevProfilesReferenceKey = get(prevProps.activeClinicMembership, 'clinic.profilesReferenceKey');
    if (prevProfilesReferenceKey !== profilesReferenceKey && profilesReferenceKey) {
      this.onFetchPatients();
    }
    if (
      prevProps.hcpClinicMemberships !== hcpClinicMemberships
      || prevProps.organizationMemberships !== organizationMemberships
    ) {
      this.setMemberships();
    }
    if (
      passphrase
      && (
        prevProps.activeOrganizationUID !== activeOrganizationUID
        || (
          activeClinicMembership && !profilesReferenceKey
          && !isConnectToClinicInProgress && !hasConnectToClinicErrors
        )
      )
    ) {
      this.props.onConnect(activeClinicMembership);
    }
    if (!isEqual(activePatient, prevProps.activePatient)) {
      ReactTooltip.rebuild();
    }
  }


  onFetchMemberships() {
    if (!this.props || (process.env.BROWSER && !this.props.isClientInitialized)) {
      return;
    }
    this.props.onFetchMemberships();
  }


  onFetchPatients() {
    if (!this.props || (process.env.BROWSER && !this.props.isClientInitialized)) {
      return;
    }
    const profilesReferenceKey = get(this.props.activeClinicMembership, 'clinic.profilesReferenceKey');
    if (profilesReferenceKey) {
      this.props.onFetchPatients(this.props.activeClinicMembership);
    }
  }


  setMemberships() {
    this.memberships = [];
    this.membershipOrganizations = [];
    this.props.hcpClinicMemberships.forEach((clinicMembership) => {
      const membership = { ...clinicMembership };
      membership.organizationUID = clinicMembership.clinic.organizationUID;
      membership.status = clinicMembership.membershipStatus;
      membership.clinic.status = clinicMembership.clinic.clinicStatus;
      this.memberships.push(membership);
      this.membershipOrganizations.push(membership.clinic);
    });
    this.props.organizationMemberships.forEach((organizationMembership) => {
      const membership = { ...organizationMembership };
      membership.organizationUID = organizationMembership.organization.organizationUID;
      this.memberships.push(membership);
      this.membershipOrganizations.push(membership.organization);
    });
    this.miniSearch.removeAll();
    this.miniSearch.addAll(this.membershipOrganizations);
  }


  get isEntityActive() {
    const { activeClinicMembership } = this.props;
    if (!activeClinicMembership) return false;
    return !(
      !activeClinicMembership.membershipStatus
      || activeClinicMembership.membershipStatus !== 'Active'
      || activeClinicMembership.clinic.clinicStatus !== 'Active'
    );
  }


  get menuItems() {
    return [
      this.renderMenuFullPatientList(),
      this.renderMenuPatients(),
      this.renderMenuActivePatient(),
      this.renderMenuAddPatient(),
      this.renderHcpSidebarHr(),
      this.renderMenuActiveClinic(),
      this.renderMenuClinicSettings(),
      this.renderMenuMoreClinic(),
      this.renderMenuJoinOrCreateClinic(),
    ];
  }


  get menuItemsSelected() {
    return {
      [constants.HCP_MENU_SELECTED_OPTIONS.PATIENTS]     : this.renderPatients(),
      [constants.HCP_MENU_SELECTED_OPTIONS.OTHER_CLINICS]: this.renderMoreClinics(),
    };
  }


  get activeClinics() {
    const { search } = this.props;
    if (isEmpty(search)) {
      return this.memberships;
    }
    return this.miniSearch
      .search(search)
      .map((term) => this.memberships.find((m) => term.id === m.organizationUID));
  }


  renderMenuFullPatientList() {
    if (!this.isEntityActive) {
      return null;
    }
    const { route } = this.props;

    const name = this.props.intl.formatMessage(messages.sidebar.buttons.fullPatientsList);
    const { organizationUID, name: clinicName } = get(this.props.activeClinicMembership, 'clinic', {});
    const clinicSlug = getSlug(clinicName);
    const fullPatientsListHref = this.context.getUrl('patients-list', { clinicSlug, organizationUID });
    const selected = route.name === 'patients-list';
    const fullPatientIcon = <PeopleIcon key={`item-menu-${name}`} />;
    const fullPatientText = (
      <FormattedMessage
        {...messages.sidebar.buttons.fullPatientsList}
        key={`item-menu-expanded-${name}`}
      />
    );
    return this.renderMenuButtonItems(
      name,
      [fullPatientIcon, fullPatientText],
      undefined,
      selected,
      fullPatientsListHref,
    );
  }


  renderMenuPatients() {
    if (!this.isEntityActive) {
      return null;
    }
    const name = this.props.intl.formatMessage(messages.sidebar.buttons.patients);
    const patientsIcon = <PatientsIcon key={`item-menu-${name}`} />;
    const selected = this.isSelectedMenuItem(constants.HCP_MENU_SELECTED_OPTIONS.PATIENTS);
    const patientsText = (
      <React.Fragment key={`item-menu-expanded-${name}`}>
        <FormattedMessage {...messages.sidebar.buttons.patients} />{ this.renderAnimatedChevron(selected) }
      </React.Fragment>
    );
    return [
      this.renderMenuButtonItem(
        name, 0, patientsIcon, () => this.onSelectMenuItem(constants.HCP_MENU_SELECTED_OPTIONS.PATIENTS), selected, null, true,
      ),
      this.renderMenuButtonItem(name, 1, patientsText, () => this.onSelectMenuItem(constants.HCP_MENU_SELECTED_OPTIONS.PATIENTS), selected, null),
    ];
  }


  renderMenuActivePatient() {
    const { activePatient } = this.props;
    if (!activePatient) return null;
    const name = `${activePatient.firstName} ${activePatient.lastName}`;
    const activePatientIcon = (
      <div className="sidebar__avatarContainer">
        <Avatar
          avatarImg={activePatient.avatar}
          name={[activePatient.firstName, activePatient.lastName]}
          className="sidebar__avatar"
          imgClassName="sidebar__avatar"
          key={`item-menu-${name}`}
        />
      </div>
    );
    const activePatientText = (
      <React.Fragment key={`item-menu-expanded-${name}`}>
        <span className="sidebar__elementName sidebar__elementName">
          { `${activePatient.firstName} ${activePatient.lastName}` }
        </span>
      </React.Fragment>
    );
    return this.renderMenuButtonItems(
      name,
      [activePatientIcon, activePatientText],
    );
  }


  renderMenuAddPatient() {
    if (!this.isEntityActive) {
      return null;
    }
    const name = this.props.intl.formatMessage(messages.sidebar.buttons.addPatient);
    const { openModalId } = this.props;
    const addPatientIcon = <AddPatientIcon key={`item-menu-${name}`} />;
    const addPatientText = (
      <FormattedMessage key={`item-menu-expanded-${name}`} {...messages.sidebar.buttons.addPatient} />
    );
    return this.renderMenuButtonItems(
      name,
      [addPatientIcon, addPatientText],
      () => {
        this.onHideAllMenu();
        this.props.onAddPatient();
      },
      openModalId === Hcp.constants.INVITE_PATIENT_MODAL,
    );
  }


  renderHcpSidebarHr() {
    if (!this.isEntityActive) {
      return null;
    }
    return this.renderHr('hcpSidebar');
  }


  renderActiveClinicBadge() {
    const { activeClinicMembership, activeOrganizationMembership } = this.props;
    const status = get(activeOrganizationMembership, 'status') || get(activeClinicMembership, 'membershipStatus');
    const isPending = status === 'Pending';
    if (!isPending) return null;
    return <div className={styles.sidebar__clinicLogoBadge} />;
  }


  renderActiveClinicIcon() {
    const { activeClinicMembership, activeOrganizationMembership } = this.props;
    const clinic = get(activeOrganizationMembership, 'organization') || get(activeClinicMembership, 'clinic', {});
    const isDeleted = clinic.clinicStatus === 'Deleted' || clinic.clinicStatus === 'Cleared';
    return (
      <div className="sidebar__avatarContainer">
        <Avatar
          avatarImg={clinic.logo}
          name={clinic.name}
          className={
            cn('sidebar__clinicLogo', {
              'sidebar__clinicLogo--deleted': isDeleted,
            })
          }
          imgClassName="sidebar__clinicLogo"
          key={`item-menu-${clinic.name}`}
        />
        { this.renderActiveClinicBadge() }
      </div>
    );
  }


  renderActiveClinicText() {
    const { activeClinicMembership, activeOrganizationMembership } = this.props;
    const clinic = get(activeOrganizationMembership, 'organization') || get(activeClinicMembership, 'clinic', {});
    return (
      <span className="sidebar__elementName" key={`item-menu-expanded-${clinic.name}`}>
        { clinic.name }
      </span>
    );
  }


  renderMenuActiveClinic() {
    const { activeClinicMembership, activeOrganizationMembership, route } = this.props;
    if (!activeClinicMembership && !activeOrganizationMembership) return null;
    const clinic = get(activeOrganizationMembership, 'organization') || get(activeClinicMembership, 'clinic', {});
    const { organizationUID } = clinic;
    const clinicSlug = getSlug(clinic.name);
    const routeName = 'general-population';
    const selected = route.name === routeName;

    return this.renderMenuButtonItems(
      clinic.name,
      [this.renderActiveClinicIcon(), this.renderActiveClinicText()],
      undefined,
      selected,
      this.isEntityActive ? this.context.getUrl(routeName, { clinicSlug, organizationUID }) : null,
    );
  }


  renderMenuClinicSettings() {
    if (!this.props.isClinicAdmin) {
      return null;
    }
    const name = this.props.intl.formatMessage(messages.sidebar.buttons.clinicSettings);
    const clinic = get(this.props, 'activeClinicMembership.clinic', {});
    const { organizationUID } = clinic;
    const clinicSlug = getSlug(clinic.name);
    const clinicSettingHref = this.context.getUrl('clinic-settings', { clinicSlug, organizationUID });
    const clinicSettingIcon = <ClinicSettings key={`item-menu-${name}`} />;
    const clinicSettingText = (
      <React.Fragment key={`item-menu-expanded-${name}`}>
        <FormattedMessage {...messages.sidebar.buttons.clinicSettings} />
      </React.Fragment>
    );
    return this.renderMenuButtonItems(
      name,
      [clinicSettingIcon, clinicSettingText],
      undefined,
      false,
      clinicSettingHref,
    );
  }


  renderMenuMoreClinic() {
    const {
      activeClinicMembershipId, activeOrganizationUID,
      hcpClinicMemberships, organizationMemberships,
    } = this.props;
    if (!hcpClinicMemberships && !organizationMemberships) return null;

    const moreClinicsCount = filter(hcpClinicMemberships, (clinicMembership) => (
      clinicMembership.clinicHcpMembershipId !== activeClinicMembershipId
    )).length
      + filter(organizationMemberships, (organizationMembership) => (
        organizationMembership.organization.organizationUID !== activeOrganizationUID
      )).length;
    if (moreClinicsCount < 1) return null;

    const name = this.props.intl.formatMessage(messages.sidebar.buttons.otherClinics);
    const clinicSettingIcon = this.renderMoreCell(moreClinicsCount, `item-menu-${name}`);
    const isSelected = this.isSelectedMenuItem(constants.HCP_MENU_SELECTED_OPTIONS.OTHER_CLINICS);
    const clinicSettingText = (
      <React.Fragment key={`item-menu-expanded-${name}`}>
        <FormattedMessage {...messages.sidebar.buttons.otherClinics} />
        { this.renderAnimatedChevron(isSelected) }
      </React.Fragment>
    );
    return this.renderMenuButtonItems(
      name,
      [clinicSettingIcon, clinicSettingText],
      () => this.onSelectMenuItem(constants.HCP_MENU_SELECTED_OPTIONS.OTHER_CLINICS),
      isSelected,
    );
  }


  renderMenuJoinOrCreateClinic() {
    const name = this.props.intl.formatMessage(messages.sidebar.buttons.addClinic);
    const joinClinicIcon = <ClinicAdd key={`item-menu-${name}`} />;
    const fullPatientText = (
      <FormattedMessage {...messages.sidebar.buttons.addClinic} key={`item-menu-expanded-${name}`} />
    );
    return this.renderMenuButtonItems(
      name,
      [joinClinicIcon, fullPatientText],
      this.props.onAddMembership,
      false,
    );
  }


  renderPatients() {
    const { activeClinicMembership } = this.props;
    const name = 'patients';

    return (
      <>
        { this.renderSelectedMenuHeader(name) }
        <Hcp.partials.SearchPatients forId="sidebarList" activeClinicMembership={this.props.activeClinicMembership} />
        <Hcp.partials.PatientsList
          id="sidebarList"
          activeClinicMembership={activeClinicMembership}
          isWithoutLoad
          showMoreButton
          onShowMoreButtonClick={() => this.onHideAllMenu()}
          showNoFindedResults
        />
      </>
    );
  }


  renderMoreClinics() {
    const { activeOrganizationUID } = this.props;
    const name = 'otherClinics';
    return (
      <>
        { this.renderSelectedMenuHeader(name) }
        <Hcp.partials.SearchClinics forId="sidebarList" activeClinicMembership={this.props.activeClinicMembership} />
        {
          this.activeClinics
            .filter((membership) => membership.organizationUID !== activeOrganizationUID)
            .map((membership) => (
              <MoreClinicItem
                key={`menu-clinic-${membership.organizationUID}`}
                membership={membership}
                onClick={() => this.onHideAllMenu()}
              />
            ))
        }
      </>
    );
  }


  render() {
    return (
      <div className={styles.container}>
        { this.renderContent() }
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  activePatient               : Hcp.selectors.activePatient(state),
  search                      : Hcp.selectors.searchClinicsSelector('sidebarList')(state),
  hcpClinicMemberships        : Account.selectors.hcpClinicMemberships(state),
  organizationMemberships     : Account.selectors.organizationMembershipsPending(state),
  activeClinicMembershipId    : Account.selectors.activeClinicMembershipId(state),
  activeOrganizationUID       : Account.selectors.activeOrganizationUID(state),
  activeClinicMembership      : Account.selectors.activeClinicMembership(state),
  activeOrganizationMembership: Account.selectors.activeOrganizationMembership(state),
  isClinicAdmin               : Account.selectors.isClinicAdmin(state),
  passphrase                  : Account.selectors.passphrase(state),
  isConnectToClinicInProgress : Account.selectors.isConnectToClinicInProgress(state),
  hasConnectToClinicErrors    : Account.selectors.hasConnectToClinicErrors(state),
  isClientInitialized         : App.selectors.isClientInitialized(state),
  openModalId                 : App.selectors.modal(state),
  route                       : App.selectors.route(state),
});


const mapDispatchToProps = (dispatch) => ({
  onAddPatient      : () => dispatch(App.actions.openModal(Hcp.constants.INVITE_PATIENT_MODAL)),
  onAddMembership   : () => dispatch(Account.actions.addAidOrganizationMembership()),
  onFetchMemberships: () => dispatch(Account.actions.fetchMemberships()),
  onConnect         : (clinicMembership) => {
    dispatch(Account.actions.disconnectClinic());
    if (
      clinicMembership && clinicMembership.membershipStatus === 'Active'
      && (clinicMembership.clinic.storageAccount || clinicMembership.isAdmin)
    ) {
      dispatch(Account.actions.connectToClinic(clinicMembership));
    }
  },
  onFetchPatients: (clinicMembership) => Promise.all([
    dispatch(Hcp.actions.fetchPatients(clinicMembership)),
    dispatch(Hcp.actions.fetchEnrollingSharingRequests(clinicMembership)),
  ]),
});


const ConnectedHcpSidebar = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(HcpSidebar));


export default withStyles(styles)(ConnectedHcpSidebar);
