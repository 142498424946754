import PropTypes from 'prop-types';
import { SHARING_REQUEST_STATUSES } from './constants';


export const clinic = PropTypes.shape({
  id  : PropTypes.number,
  name: PropTypes.string,
  logo: PropTypes.string,
});

export const sharingRequest = PropTypes.shape({
  sharingRequestId                  : PropTypes.number.isRequired,
  invitationCode                    : PropTypes.string,
  createdTimestamp                  : PropTypes.number,
  clinicHcpMembershipId             : PropTypes.number,
  encryptedPatientPhiSetReferenceKey: PropTypes.string,
  encryptedPatientExchangeToken     : PropTypes.string,
  encryptedPatientRefreshToken      : PropTypes.string, // @deprecated
  storageProvider                   : PropTypes.string,
  sharingStatus                     : PropTypes.oneOf(SHARING_REQUEST_STATUSES).isRequired,
  patient                           : PropTypes.shape({
    firstName      : PropTypes.string.isRequired,
    lastName       : PropTypes.string.isRequired,
    countryId      : PropTypes.number.isRequired,
    gender         : PropTypes.oneOf(['Male', 'Female']).isRequired,
    dateOfBirth    : PropTypes.string.isRequired,
    avatar         : PropTypes.string,
    email          : PropTypes.string.isRequired,
    telephoneNumber: PropTypes.string,
    additionalData : PropTypes.string,
  }),
});
