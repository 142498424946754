// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._29F7V{position:relative;color:#6f7ea3}._29F7V hr{margin:16px 0}._29F7V .page{width:8in;margin:.5in .25in;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-ms-flex-pack:justify;justify-content:space-between;color:#6f7ea3}@media print{._29F7V .page{margin:0 .25in}}._29F7V .page .page{width:100%}._29F7V .page *{font-family:Arial,sans-serif}._29F7V .pagination-pages{width:100%}._29F7V ._1UGzD{padding:4px}.UiL5X{position:fixed;left:30px;left:3rem;top:30px;top:3rem}", ""]);
// Exports
exports.locals = {
	"patientsBgDataReport": "_29F7V",
	"table__cell": "_1UGzD",
	"exportToCsv": "UiL5X"
};
module.exports = exports;
