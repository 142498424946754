import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import FormGroup from 'components/Form/FormGroup';
import Select from 'components/Form/Select';
import App from 'modules/App';


class CountryIdFormPartial extends React.PureComponent {

  static propTypes = {
    // Explicit props
    countries            : PropTypes.arrayOf(App.shapes.country),
    formValues           : PropTypes.object,
    isDisabled           : PropTypes.bool,
    // Explicit actions
    onSetFormValue       : PropTypes.func,
    // Implicit props
    region               : App.shapes.region,
    localizationResources: PropTypes.object,
  };


  render() {
    const countries = this.props.countries
      .filter((country) => country.region === this.props.region.name)
      .map((country) => {
        country.label = get(this.props.localizationResources, [country.nameKey, 'value']) || country.englishName;
        return country;
      })
      .sort((country, lastCountry) => country.label.localeCompare(lastCountry.label));

    return (
      <FormGroup
        id="countryId"
        labelMessage={App.messages.labels.country}
        formValues={this.props.formValues}
      >
        <Select
          optionsFrom={countries}
          valueKey="countryId"
          labelKey="label"
          noValueMessage={App.messages.placeholders.country}
          isDisabled={this.props.isDisabled}
          onChange={this.props.onSetFormValue}
        />
      </FormGroup>
    );
  }

}


const mapStateToProps = (state) => ({
  region               : App.selectors.region(state),
  localizationResources: App.selectors.localizationResources(state),
});

const ConnectedCountryIdFormPartial = connect(
  mapStateToProps,
)(CountryIdFormPartial);

export default ConnectedCountryIdFormPartial;
