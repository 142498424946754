import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import Button from 'components/Form/Button';
import Pagination from 'components/Pagination';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from './ImportPatientDatabase.pcss';


class ImportErrorsList extends React.PureComponent {

  static propTypes = {
    // Explicit actions
    onSetComponent: PropTypes.func,
    // Implicit props
    errorsList    : PropTypes.arrayOf(PropTypes.shape({
      id       : PropTypes.string,
      firstName: PropTypes.string,
      lastName : PropTypes.string,
    })),
  };


  constructor(props) {
    super(props);
    this.perPage = 10;
    this.state = {
      page: 0,
    };
  }


  onPageChange(page) {
    this.setState({ page });
  }


  renderError(error) {
    return (
      <div key={error.id} className={styles.errorsList__item}>
        <div className="row">
          <div className="col-7">{error.firstName} {error.lastName}</div>
          <div className="col-5">{error.id}</div>
        </div>
      </div>
    );
  }


  renderPagination() {
    const { errorsList } = this.props;
    const { page } = this.state;
    const totalPages = Math.ceil(errorsList.length / this.perPage);
    return (
      <Pagination
        perPage={this.perPage}
        onPageChange={(p) => this.onPageChange(p)}
        page={page}
        totalPages={totalPages}
        isPerPageOff
      />
    );
  }


  render() {
    const { page } = this.state;
    const errorsListPage = this.props.errorsList.slice(this.perPage * page, this.perPage * (page + 1));
    return (
      <div>
        <div>
          <div className={styles.errorsList__item}>
            <div className="row text--bold">
              <div className="col-7"><FormattedMessage {...messages.labels.patient} /></div>
              <div className="col-5"><FormattedMessage {...messages.labels.patientId} /></div>
            </div>
          </div>
          { errorsListPage.map((error) => this.renderError(error)) }
        </div>
        { this.renderPagination() }
        <div className="row">
          <div className="col-6">
            <Button
              styleModifier="primary"
              labelMessage={messages.buttons.back}
              className="btn--block btn--filled mt-4"
              onClick={() => this.props.onSetComponent('ImportPatientDatabase')}
            />
          </div>
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  errorsList: selectors.importPatientDatabaseErrorsList(state),
});


const ConnectedImportErrorsList = connect(
  mapStateToProps,
)(ImportErrorsList);

export default withStyles(styles)(ConnectedImportErrorsList);
