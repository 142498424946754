import React from 'react';
import PropTypes from 'prop-types';
import Form from 'components/Form';
import FormGroup from 'components/Form/FormGroup';
import InputLikeCheckboxRadio from 'components/Form/InputLikeCheckboxRadio';
import App from 'modules/App';
import Button from 'components/Form/Button';
import messages from '../../messages';


class ChooseDatabase extends React.PureComponent {

  static propTypes = {
    database      : PropTypes.oneOf(Object.keys(App.constants.PATIENT_DATABASES)),
    onCancel      : PropTypes.func,
    onSetComponent: PropTypes.func,
    onSetDatabase : PropTypes.func,
  };


  render() {
    const { database } = this.props;
    const formValues = { values: { database } };
    return (
      <Form>
        <FormGroup
          id="database"
          formValues={formValues}
        >
          <InputLikeCheckboxRadio
            labelMessage={App.constants.PATIENT_DATABASES.GlucoControDesktop.label}
            inputValue="GlucoControDesktop"
            containerClassName="col-12"
            isRadio
            onChange={(input) => this.props.onSetDatabase(input.value)}
          />
        </FormGroup>
        <FormGroup
          id="database"
          formValues={formValues}
        >
          <InputLikeCheckboxRadio
            labelMessage={App.constants.PATIENT_DATABASES.GlucoFactsDelux.label}
            inputValue="GlucoFactsDelux"
            containerClassName="col-12"
            isRadio
            onChange={(input) => this.props.onSetDatabase(input.value)}
          />
        </FormGroup>
        <div className="row">
          <div className="col-6">
            <Button
              styleModifier="quaternary"
              labelMessage={messages.buttons.close}
              className="btn--block mt-4"
              onClick={() => this.props.onCancel()}
            />
          </div>
          <div className="col-6">
            <Button
              styleModifier="primary"
              labelMessage={messages.buttons.next}
              className="btn--block btn--filled mt-4"
              onClick={() => this.props.onSetComponent('ImportPatientDatabase')}
            />
          </div>
        </div>
      </Form>
    );
  }

}


export default ChooseDatabase;
