import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { AppContext } from 'context';
import { regionName } from 'modules/App/selectors';


interface Props {
  src: string,
  alt?: string,
  className?: string,
}


const Asset: FC<Props> = ({ src, alt, className, ...props }) => {
  const { assetUrl } = useContext(AppContext);
  const region = useSelector(regionName);
  const imgSrc = src.includes('data:image') ? src : `${assetUrl}/${region}/${src}`;


  return (
    <img
      alt={alt}
      className={className}
      src={imgSrc}
      {...props}
    />
  );
};

export default Asset;
