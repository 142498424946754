import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';
import * as shapes from './shapes';
import reducer from './reducer';
import sagas from './sagas';
import messages from './messages';

import NotificationsPopover from './partials/NotificationsPopover';
import NotificationsSettingsModal from './partials/NotificationsSettingsModal';
import NotificationsHistoryModal from './partials/NotificationsHistoryModal';


const partials = {
  NotificationsPopover,
  NotificationsSettingsModal,
  NotificationsHistoryModal,
};


export default {
  actions,
  partials,
  constants,
  reducer,
  sagas,
  selectors,
  shapes,
  messages,
};
