import * as actionTypes from './actionTypes';
import * as constants from './constants';


export const checkStatus = () => ({
  type: actionTypes.CHECK_STATUS,
});

export const checkStatusSuccess = (downloader) => ({
  type   : actionTypes.CHECK_STATUS_SUCCESS,
  payload: {
    downloader,
  },
});

export const checkStatusError = (error, downloader) => ({
  type   : actionTypes.CHECK_STATUS_ERROR,
  payload: {
    downloader,
  },
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const installDownloader = () => ({
  type: actionTypes.INSTALL_DOWNLOADER,
});

export const installDownloaderSuccess = (downloader) => ({
  type   : actionTypes.INSTALL_DOWNLOADER_SUCCESS,
  payload: {
    downloader,
  },
});

export const installDownloaderCancel = () => ({
  type   : actionTypes.INSTALL_DOWNLOADER_CANCEL,
  payload: {
    status: 'Canceled',
  },
});

export const installDownloaderTimeout = () => ({
  type   : actionTypes.INSTALL_DOWNLOADER_TIMEOUT,
  payload: {
    status: 'Timeout',
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const updateDownloader = () => ({
  type: actionTypes.UPDATE_DOWNLOADER,
});

export const updateDownloaderSuccess = () => ({
  type: actionTypes.UPDATE_DOWNLOADER_SUCCESS,
});

export const updateDownloaderError = () => ({
  type   : actionTypes.UPDATE_DOWNLOADER_ERROR,
  payload: {
    status: 'UpdateError',
  },
});

export const updateDownloaderEndPointNotFoundError = () => ({
  type   : actionTypes.UPDATE_DOWNLOADER_ENDPOINT_NOT_FOUND_ERROR,
  payload: {
    status: 'UpdateEndPointNotFound',
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const startCheckingConnection = (connectionId, connectorType = 'MDA') => ({
  type   : actionTypes.CHECKING_CONNECTION_START,
  payload: {
    connectorType,
    connectionId,
  },
});

export const setCheckingConnection = (connectionId, connectionStatus = null) => ({
  type   : actionTypes.CHECKING_CONNECTION_SET,
  payload: {
    connectionId,
    connectionStatus,
  },
});

export const stopCheckingConnection = (connectionId = null, connectionStatus = null, clearConnection = false) => ({
  type   : actionTypes.CHECKING_CONNECTION_STOP,
  payload: {
    connectionId,
    connectionStatus,
    clearConnection,
  },
});

export const checkingConnectionError = (error) => ({
  type: actionTypes.CHECKING_CONNECTION_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const clearConnection = () => ({
  type: actionTypes.CLEAR_CONNECTION,
});

//----------------------------------------------------------------------------------------------------------------------

export const getDeviceData = (connectorType, connectionId, visit = null) => ({
  type   : actionTypes.GET_DEVICE_DATA,
  payload: {
    connectorType,
    connectionId,
    visit,
  },
});

export const getDeviceDataSuccess = (deviceData) => ({
  type   : actionTypes.GET_DEVICE_DATA_SUCCESS,
  payload: {
    deviceData,
  },
});

export const getDeviceDataError = (error) => ({
  type: actionTypes.GET_DEVICE_DATA_ERROR,
  error,
});

export const setDeviceDataForPreview = (deviceData) => ({
  type   : actionTypes.SET_DEVICE_DATA_FOR_PREVIEW,
  payload: {
    deviceData,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const checkBlueCableDriver = () => ({
  type: actionTypes.CHECK_BLUE_CABLE_DRIVER,
});

export const checkBlueCableDriverSuccess = (driver) => ({
  type   : actionTypes.CHECK_BLUE_CABLE_DRIVER_SUCCESS,
  payload: {
    driver,
  },
});

export const checkBlueCableDriverError = (error) => ({
  type: actionTypes.CHECK_BLUE_CABLE_DRIVER_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const installBlueCableDriver = (installationState) => ({
  type   : actionTypes.INSTALL_BLUE_CABLE_DRIVER,
  payload: {
    installationState,
  },
});

export const stopInstallBlueCableDriver = () => ({
  type: actionTypes.INSTALL_BLUE_CABLE_DRIVER_STOP,
});

//----------------------------------------------------------------------------------------------------------------------

export const startListeningBluetooth = () => ({
  type: actionTypes.LISTENING_BLUETOOTH_START,
});

export const stopListeningBluetooth = () => ({
  type: actionTypes.LISTENING_BLUETOOTH_STOP,
});

export const listeningBluetoothError = () => ({
  type: actionTypes.LISTENING_BLUETOOTH_ERROR,
});

//----------------------------------------------------------------------------------------------------------------------

export const setAvailableDevices = (devices) => ({
  type   : actionTypes.SET_AVAILABLE_DEVICES,
  payload: {
    devices,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const sendBluetoothPin = ({ deviceId, pin } = {}) => ({
  type   : actionTypes.SEND_BLUETOOTH_PIN,
  payload: {
    deviceId,
    pin,
  },
});

export const sendBluetoothPinSuccess = (connectionId, connectionStatus) => ({
  type   : actionTypes.SEND_BLUETOOTH_PIN_SUCCESS,
  payload: {
    connectionId,
    connectionStatus,
  },
});

export const sendBluetoothPinError = (error) => ({
  type: actionTypes.SEND_BLUETOOTH_PIN_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const connectBlueCable = () => ({
  type: actionTypes.CONNECT_BLUE_CABLE,
});

export const connectBluetooth = (discoveredDevice) => ({
  type   : actionTypes.CONNECT_BLUETOOTH,
  payload: {
    discoveredDevice,
  },
});

export const connectUsb = () => ({
  type: actionTypes.CONNECT_USB,
});

export const connectUsbC = () => ({
  type   : actionTypes.CONNECT_USB_C,
  payload: {
    deviceDataType: constants.DEVICE_DATA_TYPES.CGM,
  },
});

export const connectScc = (organizationUID) => ({
  type   : actionTypes.CONNECT_SCC,
  payload: {
    deviceDataType: constants.DEVICE_DATA_TYPES.BGM,
    organizationUID,
  },
});

export const connectSuccess = (connectorType, connectionId, connectionStatus) => ({
  type   : actionTypes.CONNECT_SUCCESS,
  payload: {
    connectorType,
    connectionId,
    connectionStatus,
  },
});

export const connectError = (error) => ({
  type: actionTypes.CONNECT_ERROR,
  error,
});

// SCC -----------------------------------------------------------------------------------------------------------------

export const checkSccStatus = () => ({
  type: actionTypes.CHECK_SCC_STATUS,
});

export const checkSccStatusSuccess = (status, machineId) => ({
  type   : actionTypes.CHECK_SCC_STATUS_SUCCESS,
  payload: {
    status,
    machineId,
  },
});

export const checkSccStatusError = (error) => ({
  type: actionTypes.CHECK_SCC_STATUS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const pairScc = (organizationUID) => ({
  type   : actionTypes.PAIR_SCC,
  payload: {
    organizationUID,
  },
});

export const pairSccSetCode = (pairingCode) => ({
  type   : actionTypes.PAIR_SCC_SET_CODE,
  payload: {
    pairingCode,
  },
});

export const pairSccSuccess = (machineId) => ({
  type   : actionTypes.PAIR_SCC_SUCCESS,
  payload: {
    machineId,
  },
});

export const pairSccCancel = () => ({
  type: actionTypes.PAIR_SCC_CANCEL,
});

export const pairSccError = (error) => ({
  type: actionTypes.PAIR_SCC_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const resetScc = () => ({
  type: actionTypes.RESET_SCC,
});

//----------------------------------------------------------------------------------------------------------------------

export const getSerialNumberToken = ({ serialNumber }) => ({
  type   : actionTypes.GET_SERIAL_NUMBER_TOKEN,
  payload: {
    serialNumber,
  },
});

export const getSerialNumberTokenSuccess = (serialNumber, serialNumberToken) => ({
  type   : actionTypes.GET_SERIAL_NUMBER_TOKEN_SUCCESS,
  payload: {
    serialNumber,
    serialNumberToken,
  },
});

export const getSerialNumberTokenError = (error) => ({
  type: actionTypes.GET_SERIAL_NUMBER_TOKEN_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const logEvent = (eventType) => ({
  type   : actionTypes.LOG_EVENT,
  payload: {
    eventType,
  },
});
