import PropTypes from 'prop-types';


export const capability = PropTypes.shape({
  externalDataSourceCapabilityId: PropTypes.number.isRequired,
  nameKey                       : PropTypes.string.isRequired,
  type                          : PropTypes.oneOf(['Bgm', 'Cgm', 'Targets', 'Dividers', 'Profile', 'Login']).isRequired,
  defaultMode                   : PropTypes.oneOf(['Read', 'Write', 'ReadWrite', 'Allowed']).isRequired,
  isDefault                     : PropTypes.bool.isRequired,
});


export const dataSource = PropTypes.shape({
  externalDataSourceId: PropTypes.number.isRequired,
  nameKey             : PropTypes.string.isRequired,
  dataSourceProvider  : PropTypes.string.isRequired,
  capabilities        : PropTypes.arrayOf(capability).isRequired,
});
