// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2laJI{padding:1rem;height:auto;font-size:16px;font-size:1.6rem;text-align:left}._2laJI .btn-inner{-ms-flex-pack:left;justify-content:left}._3GlYg{position:relative;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;width:40px;width:4rem;height:40px;height:4rem;background-color:#fff;border:2px solid #fff;border-radius:50%;overflow:hidden}._1ejLT{width:20px;width:2rem;height:20px;height:2rem;fill:#30a8ff}._3xDjG{color:#30a8ff}", ""]);
// Exports
exports.locals = {
	"root": "_2laJI",
	"avatar": "_3GlYg",
	"avatar__icon": "_1ejLT",
	"label": "_3xDjG"
};
module.exports = exports;
