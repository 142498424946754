import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import PencilIcon from 'svg/pencil.svg';
import styles from './ActiveNotingLabel.pcss';


class ActiveNotingLabel extends React.PureComponent {

  static propTypes = {
    labelMessage: PropTypes.object,
  };


  renderLabel() {
    if (!this.props.labelMessage) {
      return null;
    }
    return <span className={styles.activeNotingLabel__label}><FormattedMessage {...this.props.labelMessage} /></span>;
  }


  render() {
    return (
      <div className={styles.activeNotingLabel}>
        <PencilIcon className={styles.activeNotingLabel__icon} />
        { this.renderLabel() }
      </div>
    );
  }

}


export default withStyles(styles)(ActiveNotingLabel);
