import * as constants from './constants';
import * as selectors from './selectors';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import reducer from './reducer';
import sagas from './sagas';


import LogoLayout from './components/LogoLayout';
import HcpMainLayout from './components/MainLayout/HcpMainLayout';
import PwdMainLayout from './components/MainLayout/PwdMainLayout';
import PlainLayout from './components/PlainLayout';
import PreviewResultsLayout from './components/PreviewResultsLayout';
import ErrorLayout from './components/ErrorLayout';
import LoaderLayout from './components/LoaderLayout';
import ReportsLayout from './components/ReportsLayout';


import messages from './messages';


const components = {
  LogoLayout,
  HcpMainLayout,
  PwdMainLayout,
  PlainLayout,
  ErrorLayout,
  LoaderLayout,
  PreviewResultsLayout,
  ReportsLayout,
};


export default {
  actionTypes,
  components,
  constants,
  messages,
  selectors,
  actions,
  reducer,
  sagas,
};
