import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import get from 'lodash/get';
import Form from 'components/Form';
import FormGroup from 'components/Form/FormGroup';
import InputLikeCheckboxRadio from 'components/Form/InputLikeCheckboxRadio';
import Button from 'components/Form/Button';
import keyPairShape from 'shapes/keyPairShape';
import App from 'modules/App';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import styles from './SetCloudDrive.pcss';


class SetCloudDrive extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    if (
      props.route.name !== 'setup'
      && props.route.name !== 'add-drive'
    ) {
      return {
        isInProgress: true,
      };
    }
    if (props.isInProgress !== state.isInProgress) {
      if (!props.isInProgress && !props.hasErrors) {
        return null;
      }
      return {
        isInProgress: props.isInProgress,
      };
    }
    return null;
  }


  static propTypes = {
    // Explicit props
    type                 : PropTypes.string.isRequired,
    keyPair              : keyPairShape.isRequired,
    formValues           : PropTypes.object,
    isInProgress         : PropTypes.bool,
    isDisabled           : PropTypes.bool,
    hasErrors            : PropTypes.bool,
    // Explicit actions
    onSetFormValue       : PropTypes.func.isRequired,
    onSetFormValues      : PropTypes.func.isRequired,
    // Implicit props
    authorizationCode    : shapes.authorizationCode,
    route                : App.shapes.route,
    // Implicit actions
    onAuthorizeCloudDrive: PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      isInProgress: false,
    };
    if (!props.formValues || !props.formValues.storageProvider) {
      props.onSetFormValues({ storageProvider: 'CentralStorage' });
    }
  }


  get isInProgress() {
    return this.state.isInProgress || !!this.props.authorizationCode;
  }


  renderProgress() {
    return <p className="text--h3 mb-5"><FormattedMessage {...messages.infos.configuringCloudDrive} /></p>;
  }


  renderActions() {
    const { type } = this.props;
    const cloudDrive = get(this.props.formValues, 'values.storageProvider');
    return (
      <div className="row">
        <div className="col-12 col-md-6">
          <Button
            id="set-storage-type"
            type="button"
            styleModifier="primary"
            labelMessage={messages.buttons.setCloudDrive}
            className="btn--block btn--filled"
            isInProgress={this.isInProgress}
            isDisabled={!this.isInProgress && this.props.isDisabled}
            onClick={() => this.props.onAuthorizeCloudDrive(cloudDrive, type)}
          />
        </div>
      </div>
    );
  }


  renderForm() {
    return (
      <Form>
        <FormGroup
          id="storageProvider"
          formValues={this.props.formValues}
        >
          <InputLikeCheckboxRadio
            labelMessage={App.constants.CLOUD_DRIVES_LABELS.CentralStorage}
            inputValue="CentralStorage"
            containerClassName="col-12"
            isRadio
            isDisabled={this.props.isDisabled}
            onChange={this.props.onSetFormValue}
          />
        </FormGroup>
        <FormGroup
          id="storageProvider"
          formValues={this.props.formValues}
        >
          <InputLikeCheckboxRadio
            labelMessage={App.constants.CLOUD_DRIVES_LABELS.GoogleDrive}
            inputValue="GoogleDrive"
            containerClassName="col-12"
            isRadio
            isDisabled={this.props.isDisabled}
            onChange={this.props.onSetFormValue}
          />
        </FormGroup>
      </Form>
    );
  }


  render() {
    return (
      <div data-testid='set-up-cloud-drive-section'>
        { this.isInProgress ? this.renderProgress() : this.renderForm() }
        { this.renderActions() }
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  authorizationCode: selectors.authorizationCode(state),
  route            : App.selectors.route(state),
});


const mapDispatchToProps = (dispatch) => ({
  onAuthorizeCloudDrive: (provider, type) => dispatch(actions.authorize(provider, type)),
});


const ConnectedSetCloudDrive = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetCloudDrive);


export default withStyles(styles)(ConnectedSetCloudDrive);
