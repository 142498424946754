// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IrWVG{height:auto;color:#0a385a;font-size:14px;font-size:1.4rem;white-space:normal;margin:0}.IrWVG .btn-inner{-ms-flex-pack:left;justify-content:left}._38Tzn{color:#95a1bd;font-weight:500;line-height:16px;line-height:1.6rem;font-size:12px;font-size:1.2rem;white-space:nowrap;display:block;text-align:left}._7UAfl{margin-top:6px;color:#6f7ea2;font-weight:700;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}._7UAfl svg{margin-left:12px;fill:#30a8ff;width:16px;height:16px}", ""]);
// Exports
exports.locals = {
	"root": "IrWVG",
	"label": "_38Tzn",
	"enrollCode": "_7UAfl"
};
module.exports = exports;
