import React from 'react';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import informationFieldShape from 'shapes/informationFieldShape';


// TODO: General TemplateFormPartial
class PersonalIdentifierPartial extends React.PureComponent {

  static propTypes = {
    // Explicit props
    informationField     : informationFieldShape,
    localizationResources: PropTypes.object,
    messages             : PropTypes.object,
    formValues           : PropTypes.object,
    // Explicit actions
    onAddValidatorRules  : PropTypes.func,
    onSetFormValue       : PropTypes.func,
  };


  onAddValidatorRules(fieldTemplate, groupTemplate = null) {
    const rules = [];

    if (fieldTemplate.isMandatory || (groupTemplate && groupTemplate.isMandatory)) rules.push('required');

    if (includes(['integer', 'decimal', 'bool', 'date', 'email'], fieldTemplate.type)) {
      rules.push(fieldTemplate.type);
    } else if (!rules.length) {
      rules.push('trim');
    }

    if (fieldTemplate.maxLength) rules.push(`limit ${fieldTemplate.maxLength}`);
    if (fieldTemplate.minLength) rules.push(`minimum ${fieldTemplate.minLength}`);
    if (fieldTemplate.maxValue) rules.push(`maxValue ${fieldTemplate.maxValue}`);
    if (fieldTemplate.minValue) rules.push(`minValue ${fieldTemplate.minValue}`);
    if (fieldTemplate.regExp) rules.push(`regExp (${fieldTemplate.regExp})`);

    if (groupTemplate) {
      if (fieldTemplate.masterFor) {
        rules.push(`masterFor ${groupTemplate.name}.${fieldTemplate.masterFor}`);
      } else {
        const masterField = find(groupTemplate.fields, { masterFor: fieldTemplate.name });
        if (masterField) rules.push(`slaveFor ${groupTemplate.name}.${masterField.name}`);
      }
    }

    const name = groupTemplate ? `${groupTemplate.name}.${fieldTemplate.name}` : fieldTemplate.name;
    this.props.onAddValidatorRules(
      {
        [name]: rules.join(', '),
      }
    );
  }


  onSetValue(input) {
    this.props.onSetFormValue(input);
  }


  onSetSlaveValidator(value, fieldTemplate, groupTemplate) {
    const { masterFor, options } = fieldTemplate;
    if (isArray(options)) {
      const activeOption = find(options, { value }) || {};
      const { slaveRegExp } = activeOption;
      if (slaveRegExp) {
        const slaveFieldTemplate = find(groupTemplate.fields, { name: masterFor });
        slaveFieldTemplate.regExp = slaveRegExp;
        this.onAddValidatorRules(slaveFieldTemplate, groupTemplate);
      }
    }
  }


  onSetMasterValue(input, fieldTemplate, groupTemplate) {
    const { value } = input;
    this.onSetSlaveValidator(value, fieldTemplate, groupTemplate);
    this.onSetValue(input);
  }


  get instruction() {
    if (!this.props.informationField) {
      return null;
    }
    const personalIdentifierTypeTemplate = find(this.props.informationField.fields, { name: 'personalIdentifierType' });
    if (!personalIdentifierTypeTemplate) {
      return null;
    }
    const value = get(this.props.formValues, ['values', 'personalIdentifier.personalIdentifierType']);
    const option = find(personalIdentifierTypeTemplate.options, { value });
    if (!option) {
      return null;
    }
    return get(this.props.localizationResources, [option.instructionsKey, 'value'], option.instructionsKey);
  }


  renderControl(fieldTemplate, groupTemplate = null) {
    const placeholderMessage = get(this.props.messages.placeholders, fieldTemplate.name);
    if (fieldTemplate.options) {
      const options = map(fieldTemplate.options, (option) => {
        const label = get(this.props.localizationResources, [option.labelKey, 'value'], option.labelKey);
        return { value: option.value, label };
      });
      return (
        <Select
          optionsFrom={options}
          valueKey="value"
          labelKey="label"
          isDisabled={fieldTemplate.options.length === 1}
          onChange={(input) => (
            fieldTemplate.masterFor
              ? this.onSetMasterValue(input, fieldTemplate, groupTemplate)
              : this.onSetValue(input)
          )}
        />
      );
    }
    return (
      <Input
        placeholder={placeholderMessage}
        onChange={(input) => (
          fieldTemplate.masterFor
            ? this.onSetMasterValue(input, fieldTemplate, groupTemplate)
            : this.onSetValue(input)
        )}
      />
    );
  }


  renderField(fieldTemplate, groupTemplate = null) {
    if (fieldTemplate.type === 'group') {
      return this.renderGroup(fieldTemplate);
    }
    const { messages } = this.props;
    const labelMessage = fieldTemplate.labelMessage || get(messages.labels, fieldTemplate.name, fieldTemplate.name);
    this.onAddValidatorRules(fieldTemplate, groupTemplate);
    return (
      <FormGroup
        key={fieldTemplate.name}
        id={groupTemplate ? `${groupTemplate.name}.${fieldTemplate.name}` : fieldTemplate.name}
        labelMessage={labelMessage}
        infoMessage={this.instruction}
        validatorRules={this.validatorRules}
        formValues={this.props.formValues}
      >
        { this.renderControl(fieldTemplate, groupTemplate) }
      </FormGroup>
    );
  }


  renderGroup(fieldTemplate) {
    if (!isArray(fieldTemplate.fields) || !fieldTemplate.fields.length) {
      return null;
    }

    const masterFields = filter(fieldTemplate.fields, (f) => f.masterFor);
    let hiddenFields = 0;
    forEach(masterFields, (mf) => {
      if (isArray(mf.options) && mf.options.length === 1) {
        mf.isHidden = true;
        hiddenFields++;
        const option = mf.options[0];
        const slaveFieldTemplate = find(fieldTemplate.fields, { name: mf.masterFor });
        slaveFieldTemplate.labelMessage = get(
          this.props.localizationResources,
          [option.labelKey, 'value'],
          option.labelKey,
        );
      }
    });

    const col = Math.round(12 / (fieldTemplate.fields.length - hiddenFields));
    return (
      <div key={fieldTemplate.name} className="row">
        {
          map(fieldTemplate.fields, (ft) => {
            if (ft.isHidden) {
              this.onAddValidatorRules(ft, fieldTemplate);
              return null;
            }
            return <div key={ft.name} className={`col-${col}`}>{this.renderField(ft, fieldTemplate)}</div>;
          })
        }
      </div>
    );
  }


  render() {
    if (!this.props.informationField) {
      return null;
    }
    return this.renderGroup(this.props.informationField);
  }

}


export default PersonalIdentifierPartial;
