// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1lHtf{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;padding:20px}._1lHtf .perPageSelect{width:200px}", ""]);
// Exports
exports.locals = {
	"pagination": "_1lHtf"
};
module.exports = exports;
