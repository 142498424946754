// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2DlwO{border:none;background:none;color:#0a385a;font-size:15px;padding:0;height:auto;margin-bottom:3rem}._2DlwO .btn__labelWrapper{padding:0}._3WsPV{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}._3M8wZ{fill:#30a8ff;height:20px;width:20px}", ""]);
// Exports
exports.locals = {
	"root": "_2DlwO",
	"icon": "_3WsPV",
	"icon__icon": "_3M8wZ"
};
module.exports = exports;
