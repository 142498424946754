import React from 'react';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import cn from 'classnames';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import Account from 'modules/Account';
import { createKPIData } from 'helpers/kpi';
import { getKpiSettingsByType } from 'helpers/settings';
import * as constants from '../../constants';
import CellsLoader from './Cells/CellsLoader';
import Cell from './Cells/Cell';
import SummaryCell from './Cells/SummaryCell';
import styles from './Header.pcss';


class KPIs extends React.Component {


  static getDerivedStateFromProps(props, state) {
    const {
      readings, preMealReadings, postMealReadings, strictPreMealReadings, strictPostMealReadings, standards, conversion, startDate, endDate,
    } = props;

    if (isEqual(startDate, state.startDate) && isEqual(endDate, state.endDate) && isEqual(readings, state.readings)) {
      return null;
    }
    return {
      ...createKPIData({
        strictPreMealReadings,
        strictPostMealReadings,
        readings,
        preMealReadings,
        postMealReadings,
        standards,
        conversion,
      }),
      startDate,
      endDate,
      readings,
    };
  }

  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    clinicSettings     : PropTypes.object,
    readings           : PropTypes.array,
    preMealReadings    : PropTypes.array,
    postMealReadings   : PropTypes.array,
    daysWithReadings   : PropTypes.number,
    daysWithoutReadings: PropTypes.number,
    isInProgress       : PropTypes.bool,
    readingsWithoutFlag: PropTypes.number,
    className          : PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    phiSet   : PropTypes.object,
    // Explicit actions
    onAddNote: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      readingsAverage     : 0,
      standardDeviation   : 0,
      preMealAverage      : 0,
      postMealAverage     : 0,
      criticalLowReadings : 0,
      criticalHighReadings: 0,
      GMI                 : 0,
      startDate           : null,
      endDate             : null,
      readings            : [],
    };
  }


  getKpi(type) {
    const { phiSet, clinicSettings } = this.props;

    return getKpiSettingsByType(type, phiSet, clinicSettings);
  }


  renderAverage() {
    const {
      standards,
      preMealReadings,
      postMealReadings,
      readingsWithoutFlag,
    } = this.props;
    const {
      preMealAverage,
      postMealAverage,
      readingsAverage,
      readings,
    } = this.state;

    if (readingsWithoutFlag > readings.length / 2) {
      return (
        <Cell
          value={readingsAverage}
          type={constants.KPI_TYPES.AVERAGE}
          conversion={this.props.conversion}
          standards={standards}
          onAddNote={this.props.onAddNote}
        />
      );
    }

    return (
      <>
        {
          preMealReadings.length > 0
            && (
              <Cell
                value={preMealAverage}
                type={constants.KPI_TYPES.AVERAGE_PRE_MEAL}
                conversion={this.props.conversion}
                standards={standards}
                onAddNote={this.props.onAddNote}
              />
            )
          }
        {
            postMealReadings.length > 0
            && (
              <Cell
                value={postMealAverage}
                type={constants.KPI_TYPES.AVERAGE_POST_MEAL}
                conversion={this.props.conversion}
                standards={standards}
                onAddNote={this.props.onAddNote}
              />
            )
          }
      </>
    );
  }


  render() {
    const {
      isInProgress,
      readings,
      daysWithReadings,
      daysWithoutReadings,
      className,
    } = this.props;
    const {
      readingsAverage,
      standardDeviation,
      criticalLowReadings,
      criticalHighReadings,
      GMI,
      CV,
    } = this.state;

    if (isInProgress) {
      return (
        <div className={cn(styles.KPIs, className)}>
          <CellsLoader />
        </div>
      );
    }
    return (
      <div className={cn(styles.KPIs, className)}>
        <SummaryCell
          readingsCount={readings.length}
          daysWithReadings={daysWithReadings}
          daysWithoutReadings={daysWithoutReadings}
          onAddNote={this.props.onAddNote}
          kpi={this.getKpi('readingsPerDay')}
        />
        <Cell
          value={GMI}
          type={constants.KPI_TYPES.GMI}
          conversion={this.props.conversion}
          onAddNote={this.props.onAddNote}
          kpi={this.getKpi('GMI')}
        />
        <Cell
          value={criticalHighReadings}
          type={constants.KPI_TYPES.VERY_HIGH}
          conversion={this.props.conversion}
          onAddNote={this.props.onAddNote}
          kpi={this.getKpi('veryHigh')}
        />
        <Cell
          value={criticalLowReadings}
          type={constants.KPI_TYPES.VERY_LOW}
          conversion={this.props.conversion}
          onAddNote={this.props.onAddNote}
          kpi={this.getKpi('veryLow')}
        />
        <Cell
          value={standardDeviation}
          type={constants.KPI_TYPES.STANDARD_DEVIATION}
          conversion={this.props.conversion}
          readingsAverage={readingsAverage}
          onAddNote={this.props.onAddNote}
          kpi={this.getKpi('standardDeviation')}
        />
        {this.renderAverage()}
        <Cell
          value={CV}
          type={constants.KPI_TYPES.CV}
          conversion={this.props.conversion}
          onAddNote={this.props.onAddNote}
          kpi={this.getKpi('CV')}
        />
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  activeClinicMembership: Account.selectors.activeClinicMembership(state),
});

const ConnectedKPIs = connect(
  mapStateToProps,
)(KPIs);


export default withStyles(styles)(ConnectedKPIs);
