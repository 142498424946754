import PropTypes from 'prop-types';
import glucoseRangesShape from './glucoseRangesShape';

/**
 * 'Cleared' status is only FE status
 */
export default PropTypes.shape({
  clinicId                     : PropTypes.number.isRequired,
  clinicStatus                 : PropTypes.oneOf(['Pending', 'Active', 'Deleted', 'Cleared', 'WaitingForLicence']).isRequired,
  city                         : PropTypes.string.isRequired,
  countryId                    : PropTypes.number.isRequired,
  encryptedProfilesReferenceKey: PropTypes.string,
  logo                         : PropTypes.string,
  name                         : PropTypes.string.isRequired,
  publicKey                    : PropTypes.string,
  storageProvider              : PropTypes.string,
  street                       : PropTypes.string,
  zipCode                      : PropTypes.string,
  customClinicIdentifiersValues: PropTypes.arrayOf(PropTypes.shape({
    customIdentifierId: PropTypes.number,
    value             : PropTypes.string,
  })),
  settings: PropTypes.shape({
    Default: glucoseRangesShape,
  }),
});
