import forEach from 'lodash/forEach';
import find from 'lodash/find';
import get from 'lodash/get';
import { all, call, put, takeLatest, take, select } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { decrypt, encrypt, getKeyFromPem } from 'helpers/crypto';
import { LocalError } from 'helpers/errorTypes';
import { getNowAsUtc } from 'helpers/datetime';
import ApiService from 'services/ApiService';
import App from 'modules/App';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import Information from 'modules/Information';
import messages from 'modules/ClinicManagement/messages';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';


function* addVisitMetadata(visitValues, clinicMembership) {
  const { clinic } = clinicMembership;
  const { clinicId, publicKey } = clinic;
  const visitDate = moment.unix(visitValues.timestamp).utc().locale('en').format();
  const visitMetadataId = uuidv4();
  const visitType = visitValues.visitType || constants.VISIT_TYPES.ONSITE;

  let { createdBy } = visitValues;
  if (!createdBy) {
    const { firstName, lastName } = yield select(Information.selectors.information);
    const { hcpProfileId } = yield select(Account.selectors.hcpProfile);
    createdBy = { hcpProfileId, firstName, lastName };
  }

  const visitMetadata = yield call(ApiService.regionalRequest, '/api/Visit', {
    method: 'POST',
    body  : {
      clinicId,
      visitMetadataId,
      visitDate,
      visitType,
      createdBy,
    },
  });

  const pubKeyObj = yield call(getKeyFromPem, publicKey);
  const encryptedVisitMetadataId = yield call(encrypt, visitMetadataId, pubKeyObj);

  return { encryptedVisitMetadataId, visitMetadata };
}


function* getVisitMetadata(visit, prvKeyPem, passphrase) {
  const { encryptedVisitMetadataId } = visit;
  let { visitMetadataId } = visit;
  if (!visitMetadataId) {
    const prvKeyObj = getKeyFromPem(prvKeyPem, passphrase);
    visitMetadataId = decrypt(encryptedVisitMetadataId, prvKeyObj);
  }
  const metadata = yield call(ApiService.regionalRequest, `/api/Visit/${visitMetadataId}`);
  return { ...visit, metadata };
}


//----------------------------------------------------------------------------------------------------------------------


function* addVisit({ payload }) {
  try {
    const {
      patientId,
      phiSet,
      phiSetDocumentId,
      phiSetReferenceKey,
      storageProvider,
      accessToken,
      clinicMembership,
      visitValues,
      successAction,
    } = payload;

    visitValues.timestamp = visitValues.timestamp || +getNowAsUtc().locale('en').format('X');
    const { encryptedVisitMetadataId, visitMetadata } = yield call(addVisitMetadata, visitValues, clinicMembership);
    const phisetVisitId = visitValues.id || visitValues.phisetVisitId || uuidv4();
    const isInactive = visitValues.isInactive || false;

    const visitValuesToStore = {
      phisetVisitId,
      encryptedVisitMetadataId,
      timestamp   : visitValues.timestamp,
      notes       : get(visitValues, 'notes', []),
      measurements: get(visitValues, 'measurements', []),
    };

    yield put(CloudDrive.actions.storeVisit(
      visitValuesToStore,
      phiSet,
      phiSetDocumentId,
      { phiSetReferenceKey, storageProvider, accessToken, id: patientId },
      successAction,
    ));

    const storeVisitResult = yield take([
      CloudDrive.actionTypes.STORE_VISIT_SUCCESS,
      CloudDrive.actionTypes.STORE_VISIT_ERROR,
    ]);

    if (storeVisitResult.type === CloudDrive.actionTypes.STORE_VISIT_ERROR) {
      const error = new LocalError({ code: 'StoreVisitError' });
      yield put(actions.addVisitError(error));
      return;
    }

    const { updatedPhiSet, phiSetDocumentId: updatedPhiSetDocumentId } = storeVisitResult.payload;
    const visit = find(get(updatedPhiSet, 'visits', []), { phisetVisitId });
    const enhancedVisit = visit && { ...visit, metadata: visitMetadata };

    yield put(actions.addVisitSuccess(enhancedVisit, isInactive, patientId, updatedPhiSet, updatedPhiSetDocumentId));
  } catch (err) {
    yield put(actions.addVisitError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* fetchVisitMetadata({ payload }) {
  try {
    const { visit, clinicMembership } = payload;
    const { encryptedPrivateKey, passphrase } = clinicMembership;
    const visitWithMetadata = yield call(getVisitMetadata, visit, encryptedPrivateKey, passphrase);
    yield put(actions.fetchVisitMetadataSuccess(visitWithMetadata));
  } catch (err) {
    yield put(actions.fetchVisitMetadataError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* fetchVisitsMetadata({ payload }) {
  try {
    const { visits, prvKeyPem, passphrase } = payload;
    const currentVisits = yield select(selectors.visits);
    const calls = [];
    forEach(visits, (visit) => {
      const { phisetVisitId } = visit;
      const currentVisit = find(currentVisits, { phisetVisitId });
      if (!currentVisit || !currentVisit.metadata) {
        calls.push(call(getVisitMetadata, visit, prvKeyPem, passphrase));
      }
    });
    // const t0 = performance.now();
    const fullVisits = yield all(calls);
    // const t1 = performance.now();
    // console.log(`Calls ${(t1 - t0)} milliseconds.`);
    // console.log('fullVisits', fullVisits);
    yield put(actions.fetchVisitsMetadataSuccess(fullVisits));
  } catch (err) {
    yield put(actions.fetchVisitsMetadataError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* sagas() {
  yield takeLatest(actionTypes.ADD_VISIT, addVisit);
  yield takeLatest(actionTypes.FETCH_VISIT_METADATA, fetchVisitMetadata);
  yield takeLatest(actionTypes.FETCH_VISITS_METADATA, fetchVisitsMetadata);
}


export default [
  sagas,
];
