import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import unionBy from 'lodash/unionBy';
import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import { registerAction, unregisterAction } from 'helpers/reducerTools';
import { SIGN_IN_SUCCESS, SIGN_OUT_SUCCESS } from 'modules/Account/actionTypes';
import * as actionTypes from './actionTypes';


const initialState = {
  phiSet                       : null,
  phiSetDocumentId             : null,
  batchesIndex                 : [],
  cgmBatchesIndex              : [],
  notesBatchesIndex            : [],
  readings                     : [],
  highlightedReadings          : [],
  relatedData                  : [],
  timeSeriesResources          : [],
  cgmReadings                  : [],
  notes                        : [],
  sharingRequests              : [],
  beingApprovedSharingRequestId: null,
  familyLinkInvitations        : [],
  familyLinkRequests           : [],
  activeFamilyLink             : null,
  fetching                     : [],
  sending                      : [],
  errors                       : [],
  measurements                 : [],
  measurementsBatchesIndex     : [],
};


const readingUnion = (reading) => `${reading.deviceSerialNumberToken}_${reading.timestamp}`;
const measurementUnion = (measurement) => (
  `${measurement.deviceSerialNumberToken}_${measurement.timestamp}_${measurement.type}`
);


export default function reducer(state = { ...initialState }, action = {}) {

  switch (action.type) {

    case actionTypes.SET_PHI_SET: {
      const { phiSet, phiSetDocumentId } = action.payload;
      return {
        ...state,
        phiSet,
        phiSetDocumentId,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_READINGS: {
      const {
        phiSet,
        readings, batchesIndex,
        cgmReadings, cgmBatchesIndex,
        relatedData, timeSeriesResources,
        measurements, measurementsBatchesIndex,
      } = action.payload;

      return {
        ...state,
        phiSet,
        batchesIndex            : uniq([...state.batchesIndex, ...batchesIndex]),
        cgmBatchesIndex         : uniq([...state.cgmBatchesIndex, ...cgmBatchesIndex]),
        measurementsBatchesIndex: uniq([...state.measurementsBatchesIndex, ...measurementsBatchesIndex]),
        readings                : sortBy(unionBy(state.readings, readings, readingUnion), ['timestamp']),
        cgmReadings             : sortBy(unionBy(state.cgmReadings, cgmReadings, readingUnion), ['timestamp']),
        measurements            : sortBy(unionBy(state.measurements, measurements, measurementUnion), ['timestamp']),
        relatedData             : unionBy(relatedData, state.relatedData, 'readingId'),
        timeSeriesResources     : map(
          unionBy(timeSeriesResources, state.timeSeriesResources, 'id'),
          (timeSeriesResource) => {
            const { resources } = timeSeriesResource;
            const validatedResources = filter(resources, { isDeleted: false });
            return {
              ...timeSeriesResource,
              resources: validatedResources,
            };
          },
        ),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_NOTES: {
      const { phiSet, phiSetDocumentId, notes, notesBatchesIndex } = action.payload;
      const stateUpdate = {
        notes            : sortBy(unionBy(notes, state.notes, 'noteId'), ['timestamp']),
        notesBatchesIndex: uniq([...state.notesBatchesIndex, ...notesBatchesIndex]),
      };
      if (phiSetDocumentId !== state.phiSetDocumentId) {
        stateUpdate.phiSet = phiSet;
        stateUpdate.phiSetDocumentId = phiSetDocumentId;
      }
      return {
        ...state,
        ...stateUpdate,
      };
    }

    case actionTypes.CLEAR_NOTES_BATCHES: {
      return {
        ...state,
        notesBatchesIndex: [],
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_IMPORTED_READINGS: {
      const { phiSet, phiSetDocumentId, readings, cgmReadings } = action.payload;

      const { summaryData: { totalReadingsCount = 0 } = {} } = phiSet;
      const { summaryData: { totalReadingsCount: oldTotalReadingsCount = 0 } = {} } = state.phiSet;
      const areNewReadings = oldTotalReadingsCount !== totalReadingsCount;
      const newReadings = readings.filter((payloadReading) => !state.readings.some((reading) => isEqual(
        `${reading.deviceSerialNumberToken}_${reading.timestamp}`,
        `${payloadReading.deviceSerialNumberToken}_${payloadReading.timestamp}`,
      )));

      return {
        ...state,
        phiSet,
        phiSetDocumentId,
        readings           : sortBy(unionBy(state.readings, newReadings, readingUnion), ['timestamp']),
        cgmReadings        : sortBy(unionBy(state.cgmReadings, cgmReadings, readingUnion), ['timestamp']),
        highlightedReadings: areNewReadings
          ? sortBy(unionBy(state.highlightedReadings, newReadings, readingUnion), ['timestamp'])
          : state.highlightedReadings,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_IMPORTED_MEASUREMENTS: {
      const { phiSet, phiSetDocumentId, measurements } = action.payload;
      return {
        ...state,
        phiSet,
        phiSetDocumentId,
        measurements,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_SHARING_REQUEST: {
      const { sharingRequest } = action.payload;
      const sharingRequests = [...state.sharingRequests];
      const idx = findIndex(sharingRequests, { sharingRequestId: sharingRequest.sharingRequestId });
      if (idx >= 0) {
        sharingRequests[idx] = sharingRequest;
      } else {
        sharingRequests.push(sharingRequest);
      }
      return {
        ...state,
        sharingRequests,
      };
    }


    case actionTypes.UNSET_SHARING_REQUEST: {
      const { sharingRequest } = action.payload;
      const sharingRequests = filter(
        state.sharingRequests,
        (sr) => sr.sharingRequestId !== sharingRequest.sharingRequestId,
      );
      return {
        ...state,
        sharingRequests,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_BEING_APPROVED_SHARING_REQUEST_ID: {
      const { beingApprovedSharingRequestId } = action.payload;
      return {
        ...state,
        beingApprovedSharingRequestId,
      };
    }

    case actionTypes.UNSET_BEING_APPROVED_SHARING_REQUEST_ID: {
      return {
        ...state,
        beingApprovedSharingRequestId: null,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_SHARING_REQUESTS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_SHARING_REQUESTS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_SHARING_REQUESTS),
      };
    }

    case actionTypes.FETCH_SHARING_REQUESTS_SUCCESS: {
      const { sharingRequests } = action.payload;
      return {
        ...state,
        sharingRequests,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_SHARING_REQUESTS),
      };
    }

    case actionTypes.FETCH_SHARING_REQUESTS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_SHARING_REQUESTS),
        errors  : registerAction(state.errors, actionTypes.FETCH_SHARING_REQUESTS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.ENROLL_IN_CLINIC: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.ENROLL_IN_CLINIC),
        errors : unregisterAction(state.errors, actionTypes.ENROLL_IN_CLINIC),
      };
    }

    case actionTypes.ENROLL_IN_CLINIC_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.ENROLL_IN_CLINIC),
      };
    }

    case actionTypes.ENROLL_IN_CLINIC_ERROR: {
      const { sharingRequest } = action.payload;
      const sharingRequests = sharingRequest ? [...state.sharingRequests, sharingRequest] : state.sharingRequests;
      return {
        ...state,
        sharingRequests,
        sending: unregisterAction(state.sending, actionTypes.ENROLL_IN_CLINIC),
        errors : registerAction(state.errors, actionTypes.ENROLL_IN_CLINIC),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.APPROVE_SHARING_REQUEST: {
      const sharingRequestId = get(action.payload, 'sharingRequest.sharingRequestId');
      return {
        ...state,
        sending: registerAction(state.sending, `${actionTypes.APPROVE_SHARING_REQUEST}-${sharingRequestId}`),
        errors : unregisterAction(state.errors, `${actionTypes.APPROVE_SHARING_REQUEST}-${sharingRequestId}`),
      };
    }

    case actionTypes.APPROVE_SHARING_REQUEST_SUCCESS: {
      const { sharingRequest } = action.payload;
      const { sharingRequestId } = sharingRequest;
      const sharingRequests = [...state.sharingRequests];
      const idx = findIndex(sharingRequests, { sharingRequestId });
      if (idx >= 0) {
        sharingRequests[idx] = sharingRequest;
      } else {
        sharingRequests.push(sharingRequest);
      }
      return {
        ...state,
        sharingRequests,
        sending: unregisterAction(state.sending, `${actionTypes.APPROVE_SHARING_REQUEST}-${sharingRequestId}`),
      };
    }

    case actionTypes.APPROVE_SHARING_REQUEST_ERROR: {
      const sharingRequestId = get(action.payload, 'sharingRequest.sharingRequestId');
      return {
        ...state,
        sending: unregisterAction(state.sending, `${actionTypes.APPROVE_SHARING_REQUEST}-${sharingRequestId}`),
        errors : registerAction(state.errors, `${actionTypes.APPROVE_SHARING_REQUEST}-${sharingRequestId}`),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.REVOKE_SHARING_REQUEST: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.REVOKE_SHARING_REQUEST),
        errors : unregisterAction(state.errors, actionTypes.REVOKE_SHARING_REQUEST),
      };
    }

    case actionTypes.REVOKE_SHARING_REQUEST_SUCCESS: {
      const { sharingRequest } = action.payload;
      const sharingRequests = filter(
        state.sharingRequests,
        (sr) => sr.sharingRequestId !== sharingRequest.sharingRequestId,
      );
      return {
        ...state,
        sharingRequests,
        sending: unregisterAction(state.sending, actionTypes.REVOKE_SHARING_REQUEST),
      };
    }

    case actionTypes.REVOKE_SHARING_REQUEST_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.REVOKE_SHARING_REQUEST),
        errors : registerAction(state.errors, actionTypes.REVOKE_SHARING_REQUEST),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.INVITE_FAMILY_MEMBERS: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.INVITE_FAMILY_MEMBERS),
        errors : unregisterAction(state.errors, actionTypes.INVITE_FAMILY_MEMBERS),
      };
    }

    case actionTypes.INVITE_FAMILY_MEMBERS_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.INVITE_FAMILY_MEMBERS),
      };
    }

    case actionTypes.INVITE_FAMILY_MEMBERS_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.INVITE_FAMILY_MEMBERS),
        errors : registerAction(state.errors, actionTypes.INVITE_FAMILY_MEMBERS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_FAMILY_SHARING_LINKS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_FAMILY_SHARING_LINKS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_FAMILY_SHARING_LINKS),
      };
    }

    case actionTypes.FETCH_FAMILY_SHARING_LINKS_SUCCESS: {
      const { familyLinkInvitations, familyLinkRequests } = action.payload;
      return {
        ...state,
        familyLinkInvitations,
        familyLinkRequests,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_FAMILY_SHARING_LINKS),
      };
    }

    case actionTypes.FETCH_FAMILY_SHARING_LINKS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_FAMILY_SHARING_LINKS),
        errors  : registerAction(state.errors, actionTypes.FETCH_FAMILY_SHARING_LINKS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_FAMILY_SHARING_LINK_REQUESTS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_FAMILY_SHARING_LINK_REQUESTS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_FAMILY_SHARING_LINK_REQUESTS),
      };
    }

    case actionTypes.FETCH_FAMILY_SHARING_LINK_REQUESTS_SUCCESS: {
      const { familyLinkRequests } = action.payload;
      return {
        ...state,
        familyLinkRequests,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_FAMILY_SHARING_LINK_REQUESTS),
      };
    }

    case actionTypes.FETCH_FAMILY_SHARING_LINK_REQUESTS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_FAMILY_SHARING_LINK_REQUESTS),
        errors  : registerAction(state.errors, actionTypes.FETCH_FAMILY_SHARING_LINK_REQUESTS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.REVOKE_FAMILY_ACCESS: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.REVOKE_FAMILY_ACCESS),
        errors : unregisterAction(state.errors, actionTypes.REVOKE_FAMILY_ACCESS),
      };
    }

    case actionTypes.REVOKE_FAMILY_ACCESS_SUCCESS: {
      const { familyLink } = action.payload;
      const { familyLinkId } = familyLink;
      const familyLinkInvitations = [...state.familyLinkInvitations];
      const idx = findIndex(familyLinkInvitations, { familyLinkId });
      if (idx < 0) {
        return {
          ...state,
          sending: unregisterAction(state.sending, actionTypes.REVOKE_FAMILY_ACCESS),
        };
      }
      familyLinkInvitations.splice(idx, 1);
      return {
        ...state,
        familyLinkInvitations,
        sending: unregisterAction(state.sending, actionTypes.REVOKE_FAMILY_ACCESS),
      };
    }

    case actionTypes.REVOKE_FAMILY_ACCESS_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.REVOKE_FAMILY_ACCESS),
        errors : registerAction(state.errors, actionTypes.REVOKE_FAMILY_ACCESS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.ACTIVATE_FAMILY_MEMBER: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.ACTIVATE_FAMILY_MEMBER),
        errors : unregisterAction(state.errors, actionTypes.ACTIVATE_FAMILY_MEMBER),
      };
    }

    case actionTypes.ACTIVATE_FAMILY_MEMBER_SUCCESS: {
      const { activeFamilyLink } = action.payload;
      if (
        state.activeFamilyLink
         && activeFamilyLink.familyLinkId === state.activeFamilyLink.familyLinkId
      ) {
        return {
          ...state,
          fetching: unregisterAction(state.fetching, actionTypes.ACTIVATE_FAMILY_MEMBER),
        };
      }
      return {
        ...state,
        activeFamilyLink,
        phiSet          : null,
        phiSetDocumentId: null,
        batchesIndex    : [],
        cgmBatchesIndex : [],
        readings        : [],
        cgmReadings     : [],
        relatedData     : [],
        fetching        : unregisterAction(state.fetching, actionTypes.ACTIVATE_FAMILY_MEMBER),
      };
    }

    case actionTypes.ACTIVATE_FAMILY_MEMBER_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.ACTIVATE_FAMILY_MEMBER),
        errors  : registerAction(state.errors, actionTypes.ACTIVATE_FAMILY_MEMBER),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.DEACTIVATE_FAMILY_MEMBER: {
      return {
        ...state,
        activeFamilyLink: null,
        phiSet          : null,
        phiSetDocumentId: null,
        phiSetPatientId : null,
        sharingRequest  : null,
        batchesIndex    : [],
        cgmBatchesIndex : [],
        readings        : [],
        cgmReadings     : [],
        relatedData     : [],
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SYNC: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.SYNC),
        errors  : unregisterAction(state.errors, actionTypes.SYNC),
      };
    }

    case actionTypes.SYNC_SUCCESS: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.SYNC),
      };
    }

    case actionTypes.SYNC_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.SYNC),
        errors  : registerAction(state.errors, actionTypes.SYNC),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case SIGN_IN_SUCCESS:
    case SIGN_OUT_SUCCESS: {
      return {
        ...initialState,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.ON_STORE_READINGS_SUCCESS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.ON_STORE_READINGS_SUCCESS),
        errors  : unregisterAction(state.errors, actionTypes.ON_STORE_READINGS_SUCCESS),
      };
    }

    case actionTypes.ON_STORE_READINGS_SUCCESS_SUCCESS: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.ON_STORE_READINGS_SUCCESS),
      };
    }

    case actionTypes.ON_STORE_READINGS_SUCCESS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.ON_STORE_READINGS_SUCCESS),
        errors  : registerAction(state.errors, actionTypes.ON_STORE_READINGS_SUCCESS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_IMPORTED_RELATED_DATA: {
      const { phiSet, phiSetDocumentId, relatedData } = action.payload;
      relatedData.map((item) => {
        const oldRelatedData = state.relatedData.find(
          (stateItem) => stateItem.readingId === item.readingId,
        );
        if (oldRelatedData) {
          item.activities = unionBy(
            item.activities, oldRelatedData.activities, (activity) => activity.id,
          ).filter((activities) => !activities.isDelete);
          item.foods = unionBy(
            item.foods, oldRelatedData.foods, (food) => `${food.id}_${food.name}`,
          ).filter((foods) => !foods.isDelete);
          item.medications = unionBy(
            item.medications, oldRelatedData.medications, (medication) => `${medication.id}_${medication.name}`,
          ).filter((medications) => !medications.isDelete);
        }
        return item;
      });

      return {
        ...state,
        phiSet,
        phiSetDocumentId,
        relatedData: unionBy(relatedData, state.relatedData, 'readingId'),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.REAUTH_CONTOUR_CLOUD: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.REAUTH_CONTOUR_CLOUD),
        errors  : unregisterAction(state.errors, actionTypes.REAUTH_CONTOUR_CLOUD),
      };
    }
    case actionTypes.REAUTH_CONTOUR_CLOUD_SUCCESS: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.REAUTH_CONTOUR_CLOUD),
      };
    }
    case actionTypes.REAUTH_CONTOUR_CLOUD_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.REAUTH_CONTOUR_CLOUD),
        errors  : registerAction(state.errors, actionTypes.REAUTH_CONTOUR_CLOUD),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    default: {
      return state;
    }

  }
}
