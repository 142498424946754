// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3vCzl{font-size:15px;font-size:1.5rem;color:rgba(0,0,0,.25)}.AusoS{font-size:13px;font-size:1.3rem}", ""]);
// Exports
exports.locals = {
	"placeholder": "_3vCzl",
	"placeholder__info": "AusoS"
};
module.exports = exports;
