import { call, delay, getContext } from 'redux-saga/effects';
import { LocalError } from 'helpers/errorTypes';
import ApiService from 'services/ApiService';


function* fetchExchangeToken(authorizationCode, scope, controlId) {
  if (!process.env.BROWSER) {
    return null;
  }

  const { code, storageProvider } = authorizationCode;
  const domain = yield getContext('domain');
  const getUrl = yield getContext('getUrl');
  let redirectUrl = '';

  switch (storageProvider) {
    case 'CentralStorage': {
      yield delay(150);
      break;
    }

    case 'GoogleDrive': {
      redirectUrl = `${domain}${getUrl('google-drive')}`;
      break;
    }

    default: {
      throw new LocalError({ code: 'UnknownStorageProvider' });
    }
  }

  const requestUrl = '/api/StorageTokensNew/ExchangeToken';
  const tokens = yield call(ApiService.regionalRequest, requestUrl, {
    method: 'POST',
    body  : {
      storageProvider,
      authorizationCode: code,
      redirectUrl,
      scope,
      controlId,
    },
  });
  return { ...tokens, storageProvider };
}


function* assignExchangeToken(exchangeToken, scope, controlId) {
  if (!process.env.BROWSER) {
    return null;
  }
  const requestUrl = '/api/StorageTokensNew/AssignExchangeToken';
  return yield call(ApiService.regionalRequest, requestUrl, {
    method: 'POST',
    body  : {
      exchangeToken,
      scope,
      controlId,
    },
  });
}


function* fetchAccessToken(exchangeToken) {
  const requestUrl = '/api/StorageTokensNew/AccessToken';
  return yield call(ApiService.regionalRequest, requestUrl, {
    method: 'POST',
    body  : {
      exchangeToken,
    },
  });
}


function* revokeExchangeToken(exchangeToken) {
  yield call(ApiService.regionalRequest, '/api/StorageTokensNew/ExchangeToken', {
    method: 'DELETE',
    body  : {
      exchangeToken,
    },
  });
}


export default {
  fetchExchangeToken,
  assignExchangeToken,
  fetchAccessToken,
  revokeExchangeToken,
};
