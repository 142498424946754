import { MODULE_ID } from './constants';


export const AUTHORIZE = `${MODULE_ID}/AUTHORIZE`;
export const AUTHORIZE_CHECK = `${MODULE_ID}/AUTHORIZE_CHECK`;
export const AUTHORIZE_SUCCESS = `${MODULE_ID}/AUTHORIZE_SUCCESS`;
export const AUTHORIZE_ERROR = `${MODULE_ID}/AUTHORIZE_ERROR`;

export const CLEAR_AUTHORIZATION_CODE = `${MODULE_ID}/CLEAR_AUTHORIZATION_CODE`;

export const GET_PROFILE = `${MODULE_ID}/GET_PROFILE`;
export const GET_PROFILE_SUCCESS = `${MODULE_ID}/GET_PROFILE_SUCCESS`;
export const GET_PROFILE_ERROR = `${MODULE_ID}/GET_PROFILE_ERROR`;

export const FETCH_PHI_SET = `${MODULE_ID}/FETCH_PHI_SET`;
export const FETCH_PHI_SET_SUCCESS = `${MODULE_ID}/FETCH_PHI_SET_SUCCESS`;
export const FETCH_PHI_SET_ERROR = `${MODULE_ID}/FETCH_PHI_SET_ERROR`;

export const FETCH_READINGS = `${MODULE_ID}/FETCH_READINGS`;
export const FETCH_READINGS_SUCCESS = `${MODULE_ID}/FETCH_READINGS_SUCCESS`;
export const FETCH_READINGS_ERROR = `${MODULE_ID}/FETCH_READINGS_ERROR`;

export const FETCH_AGGREGATES = `${MODULE_ID}/FETCH_AGGREGATES`;
export const FETCH_AGGREGATES_SUCCESS = `${MODULE_ID}/FETCH_AGGREGATES_SUCCESS`;
export const FETCH_AGGREGATES_ERROR = `${MODULE_ID}/FETCH_AGGREGATES_ERROR`;

export const STORE_READINGS = `${MODULE_ID}/STORE_READINGS`;
export const STORE_READINGS_SUCCESS = `${MODULE_ID}/STORE_READINGS_SUCCESS`;
export const STORE_READINGS_ERROR = `${MODULE_ID}/STORE_READINGS_ERROR`;

export const STORE_RELATED_DATA = `${MODULE_ID}/STORE_RELATED_DATA`;
export const STORE_RELATED_DATA_SUCCESS = `${MODULE_ID}/STORE_RELATED_DATA_SUCCESS`;
export const STORE_RELATED_DATA_ERROR = `${MODULE_ID}/STORE_RELATED_DATA_ERROR`;

export const STORE_TIME_SERIES_RESOURCES = `${MODULE_ID}/STORE_TIME_SERIES_RESOURCES`;
export const STORE_TIME_SERIES_RESOURCES_SUCCESS = `${MODULE_ID}/STORE_TIME_SERIES_RESOURCES_SUCCESS`;
export const STORE_TIME_SERIES_RESOURCES_ERROR = `${MODULE_ID}/STORE_TIME_SERIES_RESOURCES_ERROR`;

export const STORE_CGM_READINGS = `${MODULE_ID}/STORE_CGM_READINGS`;
export const STORE_CGM_READINGS_SUCCESS = `${MODULE_ID}/STORE_CGM_READINGS_SUCCESS`;
export const STORE_CGM_READINGS_ERROR = `${MODULE_ID}/STORE_CGM_READINGS_ERROR`;

export const STORE_MEASUREMENTS = `${MODULE_ID}/STORE_MEASUREMENTS`;
export const STORE_MEASUREMENTS_SUCCESS = `${MODULE_ID}/STORE_MEASUREMENTS_SUCCESS`;
export const STORE_MEASUREMENTS_ERROR = `${MODULE_ID}/STORE_MEASUREMENTS_ERROR`;

export const FETCH_NOTES = `${MODULE_ID}/FETCH_NOTES`;
export const FETCH_NOTES_SUCCESS = `${MODULE_ID}/FETCH_NOTES_SUCCESS`;
export const FETCH_NOTES_ERROR = `${MODULE_ID}/FETCH_NOTES_ERROR`;

export const STORE_NOTES = `${MODULE_ID}/STORE_NOTES`;
export const STORE_NOTES_SUCCESS = `${MODULE_ID}/STORE_NOTES_SUCCESS`;
export const STORE_NOTES_ERROR = `${MODULE_ID}/STORE_NOTES_ERROR`;

export const START_REMOVE_NOTES_QUEUE = `${MODULE_ID}/START_NOTES_QUEUE`;
export const STOP_REMOVE_NOTES_QUEUE = `${MODULE_ID}/STOP_NOTES_QUEUE`;
export const REMOVE_NOTES_QUEUE_FINISHED = `${MODULE_ID}/NOTES_QUEUE_FINISHED`;

export const REMOVE_NOTE = `${MODULE_ID}/REMOVE_NOTE`;
export const REMOVE_NOTE_SUCCESS = `${MODULE_ID}/REMOVE_NOTE_SUCCESS`;
export const REMOVE_NOTE_ERROR = `${MODULE_ID}/REMOVE_NOTE_ERROR`;

export const STORE_VISIT = `${MODULE_ID}/STORE_VISIT`;
export const STORE_VISIT_SUCCESS = `${MODULE_ID}/STORE_VISIT_SUCCESS`;
export const STORE_VISIT_ERROR = `${MODULE_ID}/STORE_VISIT_ERROR`;

export const UPDATE_PHI_SET = `${MODULE_ID}/UPDATE_PHI_SET`;
export const UPDATE_PHI_SET_SUCCESS = `${MODULE_ID}/UPDATE_PHI_SET_SUCCESS`;
export const UPDATE_PHI_SET_ERROR = `${MODULE_ID}/UPDATE_PHI_SET_ERROR`;

export const SYNC = `${MODULE_ID}/SYNC`;
export const SYNC_SUCCESS = `${MODULE_ID}/SYNC_SUCCESS`;
export const SYNC_ERROR = `${MODULE_ID}/SYNC_ERROR`;

export const PUSH_NOTES = `${MODULE_ID}/PUSH_NOTES`;
export const PUSH_NOTES_SUCCESS = `${MODULE_ID}/PUSH_NOTES_SUCCESS`;
export const PUSH_NOTES_ERROR = `${MODULE_ID}/PUSH_NOTES_ERROR`;

export const PUSH_VISIT = `${MODULE_ID}/PUSH_VISIT`;
export const PUSH_VISIT_SUCCESS = `${MODULE_ID}/PUSH_VISIT_SUCCESS`;
export const PUSH_VISIT_ERROR = `${MODULE_ID}/PUSH_VISIT_ERROR`;

export const FETCH_DUMP = `${MODULE_ID}/FETCH_DUMP`;
export const FETCH_DUMP_SUCCESS = `${MODULE_ID}/FETCH_DUMP_SUCCESS`;
export const FETCH_DUMP_ERROR = `${MODULE_ID}/FETCH_DUMP_DUMP_ERROR`;

export const CANCEL_FETCH_DUMP = `${MODULE_ID}/CANCEL_FETCH_DUMP`;
