import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { mean, median, std, max, min, round } from 'mathjs';
import { motion, AnimatePresence } from 'framer-motion';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import flatten from 'lodash/flatten';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import set from 'lodash/set';
import map from 'lodash/map';
import times from 'lodash/times';
import valuesIn from 'lodash/valuesIn';
import { isAggregatedPostMeal } from 'libs/StatsCalculations';
import ReadingFlagIcon from 'components/ReadingFlagIcon';
import intlShape from 'shapes/intlShape';
import App from 'modules/App';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from './SummaryTable.pcss';


class SummaryTableReport extends React.PureComponent {


  static propTypes = {
    // Explicit props
    readings    : PropTypes.array, // @TODO: shape
    standards   : PropTypes.object,
    conversion  : PropTypes.object.isRequired,
    customRanges: PropTypes.array,
    isInProgress: PropTypes.bool,
    // Implicit props
    mode        : PropTypes.oneOf(constants.MODES),
    groupBy     : PropTypes.oneOf([...constants.GROUP_BY, ...constants.GROUP_BY_CGM]),
    intl        : intlShape,
    printMode   : PropTypes.bool,
  };


  constructor(props) {
    super(props);
    this.data = this.calculateData();
  }


  calculateData() {
    const { readings, standards, customRanges, mode, groupBy } = this.props;

    const getGroup = (reading) => {
      if (mode !== 'GROUPED') {
        return 0;
      }
      const momentDate = moment.unix(reading.timestamp);
      switch (groupBy) {
        case 'WEEKDAY': {
          return momentDate.weekday();
        }
        case 'MONTH': {
          return momentDate.month();
        }
        case 'QUARTER': {
          return momentDate.quarter();
        }
        case 'HOURS_RANGE': {
          const breakpoints = customRanges;
          const hour = momentDate.hour();
          const minutes = momentDate.minutes();
          let breakpointIdx = 0;
          forEach(breakpoints, (bp, idx) => {
            if (bp[0] < hour || (bp[0] === hour && bp[1] < minutes)) {
              breakpointIdx = idx;
            }
          });
          return breakpointIdx;
        }
        default: {
          break;
        }
      }
      return null;
    };

    const getStats = ({ values, count, lowCount, targetCount, highCount }) => ({
      count,
      mean  : mean(values),
      median: median(values),
      std   : std(values),
      max   : max(values),
      min   : min(values),
      low   : round((lowCount / count) * 100, 2),
      high  : round((highCount / count) * 100, 2),
      target: round((targetCount / count) * 100, 2),
    });

    //------------------------------------------------------------------------------------------------------------------

    const groupedValues = {};

    forEach(readings, (reading) => {
      const group = getGroup(reading);
      const { flags, value } = reading;
      const flagStandards = isAggregatedPostMeal(reading.flags) ? standards.postMeal : standards.preMeal;
      const { highThreshold, lowThreshold } = flagStandards;
      let level = 'target';
      if (value > highThreshold) {
        level = 'high';
      } else if (value < lowThreshold) {
        level = 'low';
      }
      const allValues = get(groupedValues, ['All', group, level], []);
      const levelValues = get(groupedValues, [flags, group, level], []);
      allValues.push(value);
      levelValues.push(value);
      set(groupedValues, ['All', group, level], allValues);
      set(groupedValues, [flags, group, level], levelValues);
    });

    const data = {};

    forEach(groupedValues, (flagValues, flag) => {
      let allFlagsValues = [];
      let lowTotalCount = 0;
      let highTotalCount = 0;
      let targetTotalCount = 0;
      const groupsStats = {};

      forEach(flagValues, (groupValues, group) => {
        if (!groupValues) {
          return;
        }
        const allLevelsValues = flatten(valuesIn(groupValues));
        allFlagsValues = [...allFlagsValues, ...allLevelsValues];

        const lowCount = get(groupValues, 'low', []).length;
        const highCount = get(groupValues, 'high', []).length;
        const targetCount = get(groupValues, 'target', []).length;
        lowTotalCount += lowCount;
        highTotalCount += highCount;
        targetTotalCount += targetCount;

        if (mode === 'GROUPED') {
          const allLevelsValuesCount = allLevelsValues.length;
          groupsStats[group] = getStats({
            values: allLevelsValues,
            count : allLevelsValuesCount,
            lowCount,
            targetCount,
            highCount,
          });
        }
      });

      const allFlagsValuesCount = allFlagsValues.length;

      data[flag] = getStats({
        values     : allFlagsValues,
        count      : allFlagsValuesCount,
        lowCount   : lowTotalCount,
        targetCount: targetTotalCount,
        highCount  : highTotalCount,
      });
      if (mode === 'GROUPED') {
        data[flag].groups = groupsStats;
      }
    });
    return data;
  }


  renderGroupLabel(group) {
    const { groupBy, intl, customRanges } = this.props;
    {
      const momentDate = moment();
      switch (groupBy) {
        case 'WEEKDAY': {
          return momentDate.weekday(group).format('dddd');
        }
        case 'MONTH': {
          return momentDate.month(group).format('MMMM');
        }
        case 'QUARTER': {
          return momentDate.quarter(group).format(intl.formatMessage(messages.momentFormats.quarter, { format: 'Qo' }));
        }
        case 'HOURS_RANGE': {
          const breakpoints = [...customRanges, [24, 0]];
          const bp = breakpoints[+group];
          const nextBp = breakpoints[+group + 1];
          return (
            <>
              { moment().hour(bp[0]).minutes(bp[1]).format('LT')}
              { ' - ' }
              { moment().hour(nextBp[0]).minutes(nextBp[1]).format('LT')}
            </>
          );
        }
        default: {
          break;
        }
      }
      return null;
    }
  }


  renderStats(stats) {
    if (!stats) {
      return times(9, (idx) => (
        <div key={idx} className={styles.valueCol}><span className={styles.value} /></div>
      ));
    }
    const { conversion } = this.props;
    return (
      <>
        <div className={styles.valueCol}><span className={styles.value}>{stats.count}</span></div>
        <div className={styles.valueCol}>
          <span className={styles.value}>{conversion.toDisplay(stats.mean)} {conversion.unitSymbol}</span>
        </div>
        <div className={styles.valueCol}>
          <span className={styles.value}>{conversion.toDisplay(stats.median)} {conversion.unitSymbol}</span>
        </div>
        <div className={styles.valueCol}>
          <span className={styles.value}>{conversion.toDisplay(stats.std)} {conversion.unitSymbol}</span>
        </div>
        <div className={styles.valueCol}>
          <span className={styles.value}>{conversion.toDisplay(stats.max)} {conversion.unitSymbol}</span>
        </div>
        <div className={styles.valueCol}>
          <span className={styles.value}>{conversion.toDisplay(stats.min)} {conversion.unitSymbol}</span>
        </div>
        <div className={styles.valueCol}><span className={styles.value}>{ stats.high }%</span></div>
        <div className={styles.valueCol}><span className={styles.value}>{ stats.target }%</span></div>
        <div className={styles.valueCol}><span className={styles.value}>{ stats.low }%</span></div>
      </>
    );
  }


  renderGroup(group, stats) {
    return (
      <div key={group} className={cn('row', styles.groupRow)}>
        <div className="col text--right">
          <h5 className={styles.groupRowHeader}>{ this.renderGroupLabel(group) }</h5>
        </div>
        { this.renderStats(stats)}
      </div>
    );
  }


  renderGroups(flag, groups) {
    return (
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: 'auto' }}
        exit={{ height: 0 }}
        transition={{
          ease    : 'easeOut',
          duration: 0.15,
        }}
        className={`col-12 ${styles.groups}`}
      >
        {map(groups, (stats, group) => this.renderGroup(group, stats))}
      </motion.div>
    );
  }


  renderIcon(flag) {
    return (
      <div className={styles.rowHeader__iconContainer}>
        <ReadingFlagIcon flag={flag} />
      </div>
    );
  }


  renderRow(flag) {
    const stats = get(this.data, flag);
    const groups = get(stats, 'groups', null);
    return (
      <div key={flag}>
        <div className={cn('row m-0', styles.row)}>
          <div className="col">
            <h5 className={styles.rowHeader}>
              { this.renderIcon(flag) }
              <FormattedMessage {...App.messages.flags[flag]} />
            </h5>
          </div>
          { this.renderStats(stats)}
        </div>
        <AnimatePresence>
          { this.renderGroups(flag, groups) }
        </AnimatePresence>
      </div>
    );
  }


  render() {
    return (
      <div className={styles.results}>
        <div className={cn('row m-0', styles.header)}>
          <div className="col"><div className={styles.rowHeader} /></div>
          <div className={styles.valueCol}>
            <span className={styles.colHeader}><FormattedMessage {...messages.tableLabels.total} /></span>
          </div>
          <div className={styles.valueCol}>
            <span className={styles.colHeader}><FormattedMessage {...messages.formulas.mean} /></span>
          </div>
          <div className={styles.valueCol}>
            <span className={styles.colHeader}><FormattedMessage {...messages.tableLabels.median} /></span>
          </div>
          <div className={styles.valueCol}>
            <span className={styles.colHeader}><FormattedMessage {...messages.tableLabels.stdDev} /></span>
          </div>
          <div className={styles.valueCol}>
            <span className={styles.colHeader}><FormattedMessage {...messages.tableLabels.highest} /></span>
          </div>
          <div className={styles.valueCol}>
            <span className={styles.colHeader}><FormattedMessage {...messages.tableLabels.lowest} /></span>
          </div>
          <div className={styles.valueCol}>
            <span className={styles.colHeader}><FormattedMessage {...App.messages.levels.high} /></span>
          </div>
          <div className={styles.valueCol}>
            <span className={styles.colHeader}><FormattedMessage {...App.messages.levels.target} /></span>
          </div>
          <div className={styles.valueCol}>
            <span className={styles.colHeader}><FormattedMessage {...App.messages.levels.low} /></span>
          </div>
        </div>
        { map(constants.FLAGS_ORDER, (flag) => this.renderRow(flag)) }
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  mode   : selectors.mode(state),
  groupBy: selectors.groupBy(state),
});


const ConnectedSummaryTableReport = connect(
  mapStateToProps,
)(injectIntl(SummaryTableReport));


export default withStyles(styles)(ConnectedSummaryTableReport);
