// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3oZyf{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex:1 1 auto;flex:1 1 auto;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;height:220px;height:22rem}._2wSkG{-ms-flex-positive:1;flex-grow:1;width:100%;line-height:1.33}", ""]);
// Exports
exports.locals = {
	"content": "_3oZyf",
	"innerContent": "_2wSkG"
};
module.exports = exports;
