import filter from 'lodash/filter';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import unionBy from 'lodash/unionBy';
import unionWith from 'lodash/unionWith';
import uniq from 'lodash/uniq';
import moment from 'moment';
import { registerAction, unregisterAction } from 'helpers/reducerTools';
import { WILDCARD_DEVICE_SERIAL_NUMBER_TOKENS } from 'modules/App/constants';
import { SET_NEW_CONFIGURATION_VERSION } from 'modules/App/actionTypes';
import { SIGN_IN_SUCCESS, SIGN_OUT, DISCONNECT_CLINIC, CONNECT_TO_CLINIC_SUCCESS } from 'modules/Account/actionTypes';
import * as actionTypes from './actionTypes';


const initialState = {
  patients                   : [],
  enrollingSharingRequests   : [],
  profilesDocumentId         : null,
  activePatient              : null,
  countrySettings            : null,
  informationTemplate        : null,
  patientCustomIdentifiers   : [],
  clinicPatientTemplate      : [],
  payers                     : [],
  updatingPatient            : null,
  phiSet                     : null,
  phiSetDocumentId           : null,
  phiSetPatientId            : null,
  notes                      : [],
  sharingRequest             : null,
  receivedSharingRequest     : null,
  patientDataComparison      : { personalDataDifferences: {}, healthDataDifferences: {} },
  isPatientDataFullComparison: false,
  batchesIndex               : [],
  notesBatchesIndex          : [],
  readings                   : [],
  highlightedReadings        : [],
  relatedData                : [],
  timeSeriesResources        : [],
  cgmReadings                : [],
  search                     : '',
  searchClinics              : '',
  fetching                   : [],
  sending                    : [],
  errors                     : [],
  measurements               : [],
  measurementsBatchesIndex   : [],
};


export default function reducer(state = { ...initialState }, action) {

  switch (action.type) {

    case actionTypes.FETCH_PATIENTS: {
      return {
        ...state,
        patients          : [],
        profilesDocumentId: null,
        fetching          : registerAction(state.fetching, actionTypes.FETCH_PATIENTS),
      };
    }
    case actionTypes.FETCH_PATIENTS_SUCCESS: {
      const { patients, profilesDocumentId } = action.payload;
      return {
        ...state,
        patients,
        profilesDocumentId,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_PATIENTS),
      };
    }
    case actionTypes.FETCH_PATIENTS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_PATIENTS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_ENROLLING_SHARING_REQUESTS: {
      return {
        ...state,
        enrollingSharingRequests: [],
        fetching                : registerAction(state.fetching, actionTypes.FETCH_ENROLLING_SHARING_REQUESTS),
      };
    }
    case actionTypes.FETCH_ENROLLING_SHARING_REQUESTS_SUCCESS: {
      const { enrollingSharingRequests } = action.payload;
      return {
        ...state,
        enrollingSharingRequests,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_ENROLLING_SHARING_REQUESTS),
      };
    }
    case actionTypes.FETCH_ENROLLING_SHARING_REQUESTS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_ENROLLING_SHARING_REQUESTS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.ADD_PATIENT: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.ADD_PATIENT),
        errors : unregisterAction(state.errors, actionTypes.ADD_PATIENT),
      };
    }
    case actionTypes.ADD_PATIENT_SUCCESS: {
      const { updatedPatients, profilesDocumentId } = action.payload;
      if (!updatedPatients) {
        return {
          ...state,
          sending: unregisterAction(state.sending, actionTypes.ADD_PATIENT),
        };
      }
      return {
        ...state,
        patients: updatedPatients,
        profilesDocumentId,
        sending : unregisterAction(state.sending, actionTypes.ADD_PATIENT),
      };
    }
    case actionTypes.ADD_PATIENT_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.ADD_PATIENT),
        errors : registerAction(state.errors, actionTypes.ADD_PATIENT),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.ENROLL_PATIENT: {
      const { patientValues, sharingRequest } = action.payload;
      const activePatient = { ...patientValues };
      return {
        ...state,
        activePatient,
        sharingRequest,
        sending: registerAction(state.sending, actionTypes.ENROLL_PATIENT),
        errors : unregisterAction(state.errors, actionTypes.ENROLL_PATIENT),
      };
    }
    case actionTypes.ENROLL_PATIENT_TRANSITION: {
      const { sharingRequest } = action.payload;
      const enrollingSharingRequests = filter(state.enrollingSharingRequests, (esr) => esr !== sharingRequest);
      return {
        ...state,
        enrollingSharingRequests,
      };
    }
    case actionTypes.ENROLL_PATIENT_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.ENROLL_PATIENT),
      };
    }
    case actionTypes.ENROLL_PATIENT_ERROR: {
      const { sharingRequest } = action.payload;
      const enrollingSharingRequests = [...state.enrollingSharingRequests];
      if (sharingRequest) {
        const enrollingSharingRequest = find(state.enrollingSharingRequests, (esr) => esr === sharingRequest);
        if (!enrollingSharingRequest) {
          enrollingSharingRequests.push(sharingRequest);
        }
      }
      return {
        ...state,
        enrollingSharingRequests,
        sending: unregisterAction(state.sending, actionTypes.ENROLL_PATIENT),
        errors : registerAction(state.errors, actionTypes.ENROLL_PATIENT),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.UPDATE_PATIENT: {
      const { patient, newPatientValues } = action.payload;
      const updatedPatient = { ...patient, ...newPatientValues };
      return {
        ...state,
        activePatient  : state.activePatient && patient.id === state.activePatient.id ? updatedPatient : state.activePatient,
        updatingPatient: patient,
        sending        : registerAction(state.sending, actionTypes.UPDATE_PATIENT),
        errors         : unregisterAction(state.errors, actionTypes.UPDATE_PATIENT),
      };
    }
    case actionTypes.UPDATE_PATIENT_SUCCESS: {
      const { updatedPatients, profilesDocumentId } = action.payload;
      if (!updatedPatients) {
        return {
          ...state,
          updatingPatient: null,
          sending        : unregisterAction(state.sending, actionTypes.UPDATE_PATIENT),
        };
      }
      return {
        ...state,
        patients       : updatedPatients,
        profilesDocumentId,
        updatingPatient: null,
        sending        : unregisterAction(state.sending, actionTypes.UPDATE_PATIENT),
      };
    }
    case actionTypes.UPDATE_PATIENT_ERROR: {
      return {
        ...state,
        updatingPatient: null,
        activePatient  : state.updatingPatient.id === state.activePatient.id ? state.updatingPatient : state.activePatient,
        sending        : unregisterAction(state.sending, actionTypes.UPDATE_PATIENT),
        errors         : registerAction(state.errors, actionTypes.UPDATE_PATIENT),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.PREVIEW_PATIENT_OPEN_TAB: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.PREVIEW_PATIENT_OPEN_TAB),
        errors : unregisterAction(state.errors, actionTypes.PREVIEW_PATIENT_OPEN_TAB),
      };
    }
    case actionTypes.PREVIEW_PATIENT_OPEN_TAB_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.PREVIEW_PATIENT_OPEN_TAB),
      };
    }
    case actionTypes.PREVIEW_PATIENT_OPEN_TAB_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.PREVIEW_PATIENT_OPEN_TAB),
        errors : registerAction(state.errors, actionTypes.PREVIEW_PATIENT_OPEN_TAB),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.START_PATIENT_VISIT: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.START_PATIENT_VISIT),
        errors : unregisterAction(state.errors, actionTypes.START_PATIENT_VISIT),
      };
    }
    case actionTypes.START_PATIENT_VISIT_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.START_PATIENT_VISIT),
      };
    }
    case actionTypes.START_PATIENT_VISIT_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.START_PATIENT_VISIT),
        errors : registerAction(state.errors, actionTypes.START_PATIENT_VISIT),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.END_PATIENT_VISIT: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.END_PATIENT_VISIT),
        errors : unregisterAction(state.errors, actionTypes.END_PATIENT_VISIT),
      };
    }
    case actionTypes.END_PATIENT_VISIT_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.END_PATIENT_VISIT),
      };
    }
    case actionTypes.END_PATIENT_VISIT_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.END_PATIENT_VISIT),
        errors : registerAction(state.errors, actionTypes.END_PATIENT_VISIT),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.MERGE_PATIENT: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.MERGE_PATIENT),
        errors : unregisterAction(state.errors, actionTypes.MERGE_PATIENT),
      };
    }
    case actionTypes.MERGE_PATIENT_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.MERGE_PATIENT),
      };
    }
    case actionTypes.MERGE_PATIENT_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.MERGE_PATIENT),
        errors : registerAction(state.errors, actionTypes.MERGE_PATIENT),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.REMOVE_PATIENT: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.REMOVE_PATIENT),
        errors : unregisterAction(state.errors, actionTypes.REMOVE_PATIENT),
      };
    }
    case actionTypes.REMOVE_PATIENT_SUCCESS: {
      const { updatedPatients, profilesDocumentId } = action.payload;
      if (!updatedPatients) {
        return {
          ...state,
          sending: unregisterAction(state.sending, actionTypes.REMOVE_PATIENT),
        };
      }
      return {
        ...state,
        patients: updatedPatients,
        profilesDocumentId,
        sending : unregisterAction(state.sending, actionTypes.REMOVE_PATIENT),
      };
    }
    case actionTypes.REMOVE_PATIENT_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.REMOVE_PATIENT),
        errors : registerAction(state.errors, actionTypes.REMOVE_PATIENT),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.REASSIGN_HCP: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.REASSIGN_HCP),
        errors : unregisterAction(state.errors, actionTypes.REASSIGN_HCP),
      };
    }
    case actionTypes.REASSIGN_HCP_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.REASSIGN_HCP),
      };
    }
    case actionTypes.REASSIGN_HCP_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.REASSIGN_HCP),
        errors : registerAction(state.errors, actionTypes.REASSIGN_HCP),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.ACTIVATE_PATIENT: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.ACTIVATE_PATIENT),
        errors  : unregisterAction(state.errors, actionTypes.ACTIVATE_PATIENT),
      };
    }
    case actionTypes.ACTIVATE_PATIENT_SUCCESS: {
      const { activePatient, sharingRequest } = action.payload;
      if (
        state.activePatient && activePatient.id === state.activePatient.id
        && get(sharingRequest, 'sharingStatus') === get(state.sharingRequest, 'sharingStatus')
      ) {
        return {
          ...state,
          fetching: unregisterAction(state.fetching, actionTypes.ACTIVATE_PATIENT),
        };
      }
      return {
        ...state,
        activePatient,
        phiSet                  : null,
        phiSetDocumentId        : null,
        batchesIndex            : [],
        cgmBatchesIndex         : [],
        notesBatchesIndex       : [],
        readings                : [],
        relatedData             : [],
        timeSeriesResources     : [],
        cgmReadings             : [],
        notes                   : [],
        measurements            : [],
        measurementsBatchesIndex: [],
        sharingRequest,
        fetching                : unregisterAction(state.fetching, actionTypes.ACTIVATE_PATIENT),
      };
    }
    case actionTypes.ACTIVATE_PATIENT_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.ACTIVATE_PATIENT),
        errors  : registerAction(state.errors, actionTypes.ACTIVATE_PATIENT),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case CONNECT_TO_CLINIC_SUCCESS: {
      if (!state.activePatient) {
        return state;
      }
      const { accessToken } = action.payload;
      const activePatient = { ...state.activePatient, accessToken };
      return {
        ...state,
        activePatient,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.DEACTIVATE_PATIENT: {
      return {
        ...state,
        activePatient   : null,
        phiSet          : null,
        phiSetDocumentId: null,
        phiSetPatientId : null,
        sharingRequest  : null,
        batchesIndex    : [],
        cgmBatchesIndex : [],
        readings        : [],
        cgmReadings     : [],
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_PATIENTS_SEARCH: {
      const { forId, phrase } = action.payload;

      const search = {
        ...state.search,
        [forId]: phrase,
      };

      return {
        ...state,
        search,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_CLINICS_SEARCH: {
      const { forId, phrase } = action.payload;

      const searchClinics = {
        ...state.searchClinics,
        [forId]: phrase,
      };

      return {
        ...state,
        searchClinics,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_PHI_SET: {
      const { phiSet, phiSetDocumentId, phiSetPatientId } = action.payload;
      return {
        ...state,
        phiSet,
        phiSetDocumentId,
        phiSetPatientId,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_READINGS: {
      const {
        phiSet,
        readings, batchesIndex,
        cgmReadings, cgmBatchesIndex,
        relatedData, timeSeriesResources,
        measurements, measurementsBatchesIndex,
      } = action.payload;
      return {
        ...state,
        phiSet,
        batchesIndex            : uniq([...state.batchesIndex, ...batchesIndex]),
        cgmBatchesIndex         : uniq([...(state.cgmBatchesIndex || []), ...cgmBatchesIndex]),
        measurementsBatchesIndex: uniq([...state.measurementsBatchesIndex, ...measurementsBatchesIndex]),
        readings                : sortBy(
          unionBy(state.readings, readings, (reading) => `${reading.deviceSerialNumberToken}_${reading.timestamp}`),
          ['timestamp'],
        ),
        cgmReadings: sortBy(
          unionBy(state.cgmReadings, cgmReadings, (reading) => `${reading.deviceSerialNumberToken}_${reading.timestamp}`),
          ['timestamp'],
        ),
        measurements: sortBy(
          unionBy(state.measurements, measurements,
            (measurement) => `${measurement.deviceSerialNumberToken}_${measurement.timestamp}_${measurement.type}`),
          ['timestamp'],
        ),
        relatedData        : unionBy(relatedData, state.relatedData, 'readingId'),
        timeSeriesResources: unionBy(timeSeriesResources, state.timeSeriesResources, 'id'),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_MEASUREMENTS: {
      const { measurements, measurementsBatchesIndex } = action.payload;
      return {
        ...state,
        measurementsBatchesIndex: uniq([...state.measurementsBatchesIndex, ...measurementsBatchesIndex]),
        measurements            : sortBy(
          unionBy(state.measurements, measurements,
            (measurement) => `${measurement.deviceSerialNumberToken}_${measurement.timestamp}_${measurement.type}`),
          ['timestamp'],
        ),
      };
    }

    //------------------------------------------------------------------------------------------------------------------


    case actionTypes.SET_IMPORTED_READINGS: {
      const { phiSet, phiSetDocumentId, phiSetReferenceKey, readings, cgmReadings } = action.payload;
      if (!state.activePatient || phiSetReferenceKey !== state.activePatient.phiSetReferenceKey) {
        return state;
      }
      const { summaryData: { totalReadingsCount = 0 } = {} } = phiSet;
      const { summaryData: { totalReadingsCount: oldTotalReadingsCount = 0 } = {} } = state.phiSet;
      const areNewReadings = oldTotalReadingsCount !== totalReadingsCount;
      const newReadings = readings.filter((payloadReading) => !state.readings.some((reading) =>
        isEqual(
          `${reading.deviceSerialNumberToken}_${reading.timestamp}`,
          `${payloadReading.deviceSerialNumberToken}_${payloadReading.timestamp}`
        )
      ));

      const compareReadings = (a, b) => isEqual(
        `${a.deviceSerialNumberToken}_${a.timestamp}`,
        `${b.deviceSerialNumberToken}_${b.timestamp}`,
      )
      || (
        a.timestamp === b.timestamp
        && (
          includes(WILDCARD_DEVICE_SERIAL_NUMBER_TOKENS, a.deviceSerialNumberToken)
          || includes(WILDCARD_DEVICE_SERIAL_NUMBER_TOKENS, b.deviceSerialNumberToken)
        )
      );

      return {
        ...state,
        phiSet,
        phiSetDocumentId,
        readings: sortBy(
          unionWith(newReadings, state.readings, compareReadings),
          ['timestamp'],
        ),
        cgmReadings: sortBy(
          unionBy(cgmReadings, state.cgmReadings, (reading) => `${reading.deviceSerialNumberToken}_${reading.timestamp}`),
          ['timestamp'],
        ),
        highlightedReadings: areNewReadings ? sortBy(
          unionBy(newReadings, state.highlightedReadings,
            (reading) => `${reading.deviceSerialNumberToken}_${reading.timestamp}`),
          ['timestamp'],
        ) : state.highlightedReadings,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_TIME_SERIES_RESOURCES: {
      const { phiSetReferenceKey, timeSeriesResources } = action.payload;
      if (!state.activePatient || phiSetReferenceKey !== state.activePatient.phiSetReferenceKey) {
        return state;
      }
      return {
        ...state,
        timeSeriesResources: unionBy(timeSeriesResources, state.timeSeriesResources, 'id'),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_NOTES: {
      const { phiSet, phiSetDocumentId, notes, notesBatchesIndex } = action.payload;

      let startTs;
      let endTs;
      forEach(notesBatchesIndex, (bi) => {
        const [year, month] = bi.split('-').map((v) => +v);
        const m = moment.utc({ year, month: month - 1 }).locale('en');
        const start = m.clone().startOf('month').format('X');
        const end = m.clone().endOf('month').format('X');
        if (!startTs || startTs > start) startTs = start;
        if (!endTs || endTs < end) endTs = end;
      });

      const currentNotes = startTs && endTs
        ? filter({ ...state.notes }, (note) => note.timestamp < startTs || note.timestamp > endTs)
        : { ...state.notes };

      const stateUpdate = {
        notes            : sortBy([...currentNotes, ...notes], ['timestamp']),
        notesBatchesIndex: uniq([...state.notesBatchesIndex, ...notesBatchesIndex]),
      };
      if (phiSetDocumentId !== state.phiSetDocumentId) {
        stateUpdate.phiSet = phiSet;
        stateUpdate.phiSetDocumentId = phiSetDocumentId;
      }
      return {
        ...state,
        ...stateUpdate,
      };
    }

    case actionTypes.CLEAR_NOTES_BATCHES: {
      return {
        ...state,
        notesBatchesIndex: [],
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_SHARING_REQUEST: {
      const { sharingRequest } = action.payload;
      return {
        ...state,
        sharingRequest: sharingRequest || null,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.CHECK_SHARING_REQUEST_APPROVE: {
      return {
        ...state,
        receivedSharingRequest: null,
      };
    }

    case actionTypes.SET_RECEIVED_SHARING_REQUEST: {
      const { sharingRequest } = action.payload;
      const enrollingSharingRequests = [...state.enrollingSharingRequests, sharingRequest];
      return {
        ...state,
        enrollingSharingRequests,
        receivedSharingRequest: sharingRequest,
      };
    }

    case actionTypes.UNSET_RECEIVED_SHARING_REQUEST: {
      return {
        ...state,
        receivedSharingRequest: null,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.CREATE_SHARING_REQUEST: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.CREATE_SHARING_REQUEST),
        errors : unregisterAction(state.errors, actionTypes.CREATE_SHARING_REQUEST),
      };
    }

    case actionTypes.CREATE_SHARING_REQUEST_SUCCESS: {
      const { sharingRequest } = action.payload;
      return {
        ...state,
        sharingRequest,
        sending: unregisterAction(state.sending, actionTypes.CREATE_SHARING_REQUEST),
      };
    }

    case actionTypes.CREATE_SHARING_REQUEST_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.CREATE_SHARING_REQUEST),
        errors : registerAction(state.errors, actionTypes.CREATE_SHARING_REQUEST),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.REMOVE_SHARING_REQUEST: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.REMOVE_SHARING_REQUEST),
        errors : unregisterAction(state.errors, actionTypes.REMOVE_SHARING_REQUEST),
      };
    }

    case actionTypes.REMOVE_SHARING_REQUEST_SUCCESS: {
      const { sharingRequest } = action.payload;
      const enrollingSharingRequests = filter(
        state.enrollingSharingRequests,
        (enrollingSharingRequest) => enrollingSharingRequest.sharingRequestId !== sharingRequest.sharingRequestId,
      );
      return {
        ...state,
        enrollingSharingRequests,
        sending: unregisterAction(state.sending, actionTypes.REMOVE_SHARING_REQUEST),
      };
    }

    case actionTypes.REMOVE_SHARING_REQUEST_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.REMOVE_SHARING_REQUEST),
        errors : registerAction(state.errors, actionTypes.REMOVE_SHARING_REQUEST),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.RESEND_SHARING_REQUEST: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.RESEND_SHARING_REQUEST),
        errors : unregisterAction(state.errors, actionTypes.RESEND_SHARING_REQUEST),
      };
    }

    case actionTypes.RESEND_SHARING_REQUEST_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.RESEND_SHARING_REQUEST),
      };
    }

    case actionTypes.RESEND_SHARING_REQUEST_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.RESEND_SHARING_REQUEST),
        errors : registerAction(state.errors, actionTypes.RESEND_SHARING_REQUEST),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT),
        errors : unregisterAction(state.errors, actionTypes.BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT),
      };
    }

    case actionTypes.BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT),
      };
    }

    case actionTypes.BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT),
        errors : registerAction(state.errors, actionTypes.BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_PATIENT_DATA_COMPARISON: {
      const { personalDataDifferences = {}, healthDataDifferences = {} } = action.payload;
      return {
        ...state,
        patientDataComparison: { personalDataDifferences, healthDataDifferences },
      };
    }

    case actionTypes.SET_IS_PATIENT_DATA_FULL_COMPARISON: {
      const { isPatientDataFullComparison } = action.payload;
      return {
        ...state,
        isPatientDataFullComparison,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SYNC: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.SYNC),
      };
    }

    case actionTypes.SYNC_FINISH: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.SYNC),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.STORE_PATIENT_INFO: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.STORE_PATIENT_INFO),
        errors  : unregisterAction(state.errors, actionTypes.STORE_PATIENT_INFO),
      };
    }

    case actionTypes.STORE_PATIENT_INFO_SUCCESS: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.STORE_PATIENT_INFO),
      };
    }

    case actionTypes.STORE_PATIENT_INFO_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.STORE_PATIENT_INFO),
        errors  : registerAction(state.errors, actionTypes.STORE_PATIENT_INFO),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_IMPORTED_RELATED_DATA: {
      const { phiSet, phiSetDocumentId, phiSetReferenceKey, relatedData } = action.payload;
      if (!state.activePatient || phiSetReferenceKey !== state.activePatient.phiSetReferenceKey) {
        return state;
      }
      return {
        ...state,
        phiSet,
        phiSetDocumentId,
        relatedData: unionBy(relatedData, state.relatedData, 'readingId'),
      };
    }

    //------------------------------------------------------------------------------------------------------------------
    case actionTypes.FETCH_PATIENT_COUNTRY_LOCALIZATION: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_PATIENT_COUNTRY_LOCALIZATION),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_PATIENT_COUNTRY_LOCALIZATION),
      };
    }
    case actionTypes.FETCH_PATIENT_COUNTRY_LOCALIZATION_SUCCESS: {
      const { countrySettings, informationTemplate, patientCustomIdentifiers, clinicPatientTemplate } = action.payload;
      return {
        ...state,
        countrySettings,
        informationTemplate,
        patientCustomIdentifiers,
        clinicPatientTemplate,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_PATIENT_COUNTRY_LOCALIZATION),
      };
    }
    case actionTypes.FETCH_PATIENT_COUNTRY_LOCALIZATION_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_PATIENT_COUNTRY_LOCALIZATION),
        errors  : registerAction(state.errors, actionTypes.FETCH_PATIENT_COUNTRY_LOCALIZATION),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_PAYERS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_PAYERS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_PAYERS),
      };
    }
    case actionTypes.FETCH_PAYERS_SUCCESS: {
      const { payers } = action.payload;
      return {
        ...state,
        payers,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_PAYERS),
      };
    }
    case actionTypes.FETCH_PAYERS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_PAYERS),
        errors  : registerAction(state.errors, actionTypes.FETCH_PAYERS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.ADD_PATIENT_TO_FAVORITE: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.ADD_PATIENT_TO_FAVORITE),
        errors  : unregisterAction(state.errors, actionTypes.ADD_PATIENT_TO_FAVORITE),
      };
    }

    case actionTypes.ADD_PATIENT_TO_FAVORITE_SUCCESS: {
      const { activePatient } = state;
      const updatedPatients = state.patients.map((patient) => {
        if (patient.id === action.payload.patient.id) {
          patient.isFavorite = action.payload.patient.isFavorite;
          if (activePatient && activePatient.id === action.payload.patient.id) {
            activePatient.isFavorite = action.payload.patient.isFavorite;
          }
        }
        return patient;
      });
      return {
        ...state,
        activePatient,
        patients: updatedPatients,
        fetching: unregisterAction(state.fetching, actionTypes.ADD_PATIENT_TO_FAVORITE),
      };
    }

    case actionTypes.ADD_PATIENT_TO_FAVORITE_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.ADD_PATIENT_TO_FAVORITE),
        errors  : registerAction(state.errors, actionTypes.ADD_PATIENT_TO_FAVORITE),
      };
    }

    // BackOffice UPDATE -----------------------------------------------------------------------------------------------

    case SET_NEW_CONFIGURATION_VERSION: {
      const { countrySettings, clinicPatientTemplate, payers } = action.payload;
      return {
        ...state,
        clinicPatientTemplate,
        countrySettings,
        payers,
      };
    }

    case SIGN_IN_SUCCESS:
    case SIGN_OUT:
    case DISCONNECT_CLINIC: {
      return {
        ...initialState,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    default: {
      return state;
    }

  }
}
