import * as actionTypes from './actionTypes';
import { DAYS_SELECTOR_ZOOM } from './constants';


const initialState = {
  showMedian             : true,
  showIQR                : true,
  showP10P90             : true,
  dailyRecords           : [],
  highlightedDates       : [],
  daysSelectorZoom       : DAYS_SELECTOR_ZOOM.day,
  daysSelectorPage       : 0,
  agpMonthSelectorPage   : 0,
  aggregations           : [],
  aggregationDocumentsIds: [],
  preview                : {
    days  : [],
    weeks : [],
    months: [],
  },
  agpPreview: {
    days  : [],
    months: [],
  },
  list    : [],
  fetching: [],
  sending : [],
};


export default function reducer(state = { ...initialState }, action) {

  switch (action.type) {

    case actionTypes.SET_AGP_DAILY_RECORDS: {
      return {
        ...state,
        dailyRecords: action.payload.dailyRecords,
      };
    }

    case actionTypes.SET_HIGHLIGHTED_DATE: {
      if (state.highlightedDates.includes(action.payload)
      ) {
        return state;
      }

      return {
        ...state,
        highlightedDates: [...state.highlightedDates, action.payload].sort(),
      };
    }

    case actionTypes.SET_HIGHLIGHTED_DATES: {
      let newHighlightedDates = action.payload;
      newHighlightedDates = newHighlightedDates.filter((date) => !state.highlightedDates.includes(date));
      return {
        ...state,
        highlightedDates: [...state.highlightedDates, ...newHighlightedDates].sort(),
      };
    }

    case actionTypes.SELECT_HIGHLIGHTED_DATES: {
      const range = action.payload;
      const availableDates = state.preview.days.map(({ date }) => date);
      const startDate = range.start < range.end ? range.start : range.end;
      const endDate = range.start < range.end ? range.end : range.start;
      const newHighlightedDates = availableDates.filter((availableDate) => availableDate >= startDate && availableDate <= endDate);
      return {
        ...state,
        highlightedDates: newHighlightedDates,
      };
    }

    case actionTypes.RESET_HIGHLIGHTED_DATE: {
      return {
        ...state,
        highlightedDates: state.highlightedDates.filter((date) => date !== action.payload),
      };
    }

    case actionTypes.CLEAR_HIGHLIGHTED_DATES: {
      return {
        ...state,
        highlightedDates: [],
      };
    }

    case actionTypes.SET_DAYS_SELECTOR_ZOOM: {
      return {
        ...state,
        daysSelectorZoom: action.payload.zoom,
      };
    }

    case actionTypes.SET_DAYS_SELECTOR_PAGE: {
      return {
        ...state,
        daysSelectorPage: action.payload.page,
      };
    }

    case actionTypes.SET_AGP_MONTH_SELECTOR_PAGE: {
      return {
        ...state,
        agpMonthSelectorPage: action.payload.page,
      };
    }

    case actionTypes.SET_PREVIEW: {
      return {
        ...state,
        preview: action.payload.preview,
      };
    }

    case actionTypes.SET_AGP_PREVIEW: {
      return {
        ...state,
        agpPreview: action.payload.preview,
      };
    }

    case actionTypes.APPEND_AGGREGATIONS: {
      return {
        ...state,
        aggregations           : [...state.aggregations, ...action.payload.aggregations],
        aggregationDocumentsIds: [...state.aggregationDocumentsIds, ...action.payload.aggregationDocumentsIds],
      };
    }

    case actionTypes.RESET_AGGREGATIONS: {
      return {
        ...state,
        aggregations           : [],
        aggregationDocumentsIds: [],
      };
    }

    case actionTypes.SET_SHOW_MEDIAN: {
      return {
        ...state,
        showMedian: action.payload.showMedian,
      };
    }

    case actionTypes.SET_SHOW_IQR: {
      return {
        ...state,
        showIQR: action.payload.showIQR,
      };
    }

    case actionTypes.SET_SHOW_P10P90: {
      return {
        ...state,
        showP10P90: action.payload.showP10P90,
      };
    }

    default: {
      return state;
    }

  }
}
