import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import delay from 'lodash/delay';
import get from 'lodash/get';
import Avatar from 'components/Avatar';
import Button from 'components/Form/Button';
import Modal from 'components/Modal';
import App from 'modules/App';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from '../ModalCommon.pcss';


class RevokeAccessModal extends React.PureComponent {

  // static getDerivedStateFromProps(props, state) {
  //   const { isInProgress, hasErrors, onCloseModal } = props;
  //   if (state.isInProgress && !isInProgress && !hasErrors) {
  //     onCloseModal();
  //     return null;
  //   }
  //   return null;
  // }

  static propTypes = {
    // Implicit props
    isInProgress: PropTypes.bool,
    hasErrors   : PropTypes.bool,
    formValues  : PropTypes.object,
    openModalId : PropTypes.string,
    // Implicit actions
    onRevoke    : PropTypes.func,
    onCloseModal: PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      // isInProgress: false,
    };
  }


  componentDidUpdate(prevProp) {
    const { isInProgress, hasErrors } = this.props;
    if (prevProp.isInProgress !== isInProgress && !isInProgress && !hasErrors) {
      delay(() => this.props.onCloseModal(), 150);
    }
  }


  onRevokeAccess() {
    const sharingRequest = get(this.props.formValues, 'contextData');
    this.props.onRevoke(sharingRequest);
  }


  renderActions() {
    return (
      <div className="modal__actions row">
        <div className="col-6">
          <Button
            styleModifier="quaternary"
            labelMessage={messages.buttons.noKeepAccess}
            className="btn--block"
            onClick={this.props.onCloseModal}
          />
        </div>
        <div className="col-6">
          <Button
            styleModifier="primary"
            labelMessage={messages.buttons.yesRevokeAccess}
            className="btn--block btn--filled"
            isInProgress={this.props.isInProgress}
            onClick={() => this.onRevokeAccess()}
          />
        </div>
      </div>
    );
  }


  renderPerson() {
    const clinic = get(this.props.formValues, 'contextData.clinic', {});
    const hcp = get(this.props.formValues, 'contextData.hcp', {});
    const name = hcp.lastName ? [hcp.firstName, hcp.lastName] : clinic.name;
    return (
      <div className={cn('row align-items-center', styles.person)}>
        <div className="col-auto pr-0">
          <Avatar
            avatarImg={hcp.avatar}
            name={name}
            className={styles.item__avatar}
            imgClassName={styles.item__avatar__img}
            initialsClassName={styles.item__avatar__initials}
          />
        </div>
        <div className="col">
          <p className={styles.person__name}>{ hcp.firstName } { hcp.lastName }</p>
          <p>{ clinic.name }</p>
        </div>
      </div>
    );
  }


  render() {
    return (
      <Modal
        modalId={constants.REVOKE_ACCESS_MODAL}
        openModalId={this.props.openModalId}
        headerMessage={messages.headers.revokeAccess}
        styleModifier="md"
        onClose={this.props.onCloseModal}
      >
        <div className={styles.container}>
          <FormattedMessage {...messages.infos.areYouSureToRevokeAccess} values={{ person: this.renderPerson() }} />
        </div>
        { this.renderActions() }
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  formValues  : App.selectors.formSelector(constants.REVOKE_ACCESS_FORM)(state),
  openModalId : App.selectors.modal(state),
  isInProgress: selectors.isRevokeSharingRequestInProgress(state),
  hasErrors   : selectors.hasRevokeSharingRequestErrors(state),
});


const mapDispatchToProps = (dispatch) => ({
  onRevoke    : (sharingRequest) => dispatch(actions.revokeSharingRequest(sharingRequest)),
  onCloseModal: () => dispatch(App.actions.closeModal()),
});


const ConnectedRevokeAccessModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RevokeAccessModal);


export default withStyles(styles)(ConnectedRevokeAccessModal);
