export const MAX_DAYS_TO_SHOW_RELATED_DATA = 3;

export const BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS = {
  PLACEHOLDER: '#E0E8F2',
  MEDIAN     : '#2b4d8b',
  IQR        : '#0094ff',
  P10P90     : '#32bf00',
  RECORDS    : '#6d6e71',
};

export const RELATED_DATA_CHART_OPTIONS = [
  'activities', 'medications', 'carbs', 'weight', 'height',
];
