import get from 'lodash/get';
import map from 'lodash/map';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuid } from 'uuid';
import { call, getContext, select } from 'redux-saga/effects';
import { compactObject } from 'helpers/transformers';
import { closeTransaction, commitTransaction, openTransaction } from 'services/MdtcService';
import ApiService from 'services/ApiService';
import { imageUrlToBase64 } from 'helpers/convertToBase64';
import App from 'modules/App';

// eslint-disable-next-line consistent-return
function* fetchAvatarImg({ avatarSrc }) {
  const fetch = yield getContext('fetch');
  try {
    const avatarImg = yield call(fetch, avatarSrc);
    const convertedAvatar = yield imageUrlToBase64(avatarImg);
    return yield convertedAvatar;
  } catch (err) {
    yield call(App.dispatchError, err, App.messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------


function* fetchPatients(documentId, accessToken) {
  const requestURL = `/api/Storage/profile/${documentId}`;
  const patients = yield call(ApiService.regionalRequest, requestURL, {
    headers: {
      Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
    },
  });
  return patients;
}


function* addPatient(accessToken, profilesDocumentId, patientValues) {
  const assetUrl = yield getContext('assetUrl');
  const code = yield select(App.selectors.regionName);

  if (!profilesDocumentId) {
    profilesDocumentId = uuid();
  }
  let baseAvatar = patientValues.avatar;
  if (patientValues.avatar && !patientValues.avatar.includes('data:image')) {
    baseAvatar = yield call(fetchAvatarImg, { avatarSrc: `${assetUrl}/${code}/${patientValues.avatar}` });
  }

  const requestURL = `/api/Storage/profile/${profilesDocumentId}`;
  const { documentId, auditDocumentId } = yield call(ApiService.regionalRequest, requestURL, {
    method : 'POST',
    headers: {
      Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
    },
    body: { ...patientValues, avatar: baseAvatar },
  });
  return { documentId, auditDocumentId };
}


function* updatePatient(accessToken, profilesDocumentId, patient) {
  const assetUrl = yield getContext('assetUrl');
  const code = yield select(App.selectors.regionName);
  const { id } = patient;
  let baseAvatar = patient.avatar;
  if (patient.avatar && !patient.avatar.includes('data:image')) {
    baseAvatar = yield call(fetchAvatarImg, { avatarSrc: `${assetUrl}/${code}/${patient.avatar}` });
  }
  const requestURL = `/api/Storage/profile/${profilesDocumentId}/${id}`;
  const { documentId, auditDocumentId } = yield call(ApiService.regionalRequest, requestURL, {
    method : 'PUT',
    headers: {
      Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
    },
    body: { ...patient, avatar: baseAvatar },
  });
  return { documentId, auditDocumentId };
}


function* removePatient(accessToken, profilesDocumentId, patient) {
  const { id } = patient;
  const requestURL = `/api/Storage/profile/${profilesDocumentId}/${id}`;
  const { documentId, auditDocumentId } = yield call(ApiService.regionalRequest, requestURL, {
    method : 'DELETE',
    headers: {
      Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
    },
  });
  return { documentId, auditDocumentId };
}


function* enableFullLinkingPatient(accessToken, profilesDocumentId, profileId) {
  const requestURL = `/api/Storage/profile/${profilesDocumentId}/${profileId}/EnableFullLinking`;
  const { documentId, auditDocumentId } = yield call(ApiService.regionalRequest, requestURL, {
    method : 'PUT',
    headers: {
      Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
    },
  });
  return { documentId, auditDocumentId };
}

//----------------------------------------------------------------------------------------------------------------------

function transformPhiSet(phiSet) {
  const transformedPhiSet = { ...phiSet };
  if (transformedPhiSet.customRanges && transformedPhiSet.customRanges.length) {
    transformedPhiSet.customRanges = map(transformedPhiSet.customRanges, (cr) => {
      const ncr = [cr.hour, cr.minutes];
      if (cr.label) {
        ncr.push(cr.label);
      }
      return ncr;
    });
  } else {
    transformedPhiSet.customRanges = null;
  }
  return transformedPhiSet;
}


function* fetchPhiSet(documentId, accessToken) {
  const phiSet = yield call(ApiService.regionalRequest, `/api/Storage/phiSet/${documentId}`, {
    headers: {
      Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
    },
  });
  return transformPhiSet(phiSet);
}


function* fetchReadings(documentId, accessToken) {
  return yield call(ApiService.regionalRequest, `/api/Storage/readings/${documentId}`, {
    headers: {
      Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
    },
  });
}


function* fetchCgmReadings(documentId, accessToken) {
  return yield call(ApiService.regionalRequest, `/api/Storage/cgm/readings/${documentId}`, {
    headers: {
      Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
    },
  });
}


function* fetchRelatedData(documentId, accessToken) {
  return yield call(ApiService.regionalRequest, `/api/Storage/readingsRelatedData/${documentId}`, {
    headers: {
      Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
    },
  });
}


function* fetchTimeSeriesResources(documentId, accessToken) {
  return yield call(ApiService.regionalRequest, `/api/Storage/timeSeriesResources/${documentId}`, {
    headers: {
      Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
    },
  });
}


function* fetchAggregations({ documentId, level, accessToken }) {
  return yield call(ApiService.regionalRequest, `/api/Storage/aggregations/hourlyBins/${level}/${documentId}`, {
    headers: {
      Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
    },
  });
}


function* fetchNotes(documentId, accessToken) {
  return yield call(ApiService.regionalRequest, `/api/Storage/notes/${documentId}`, {
    headers: {
      Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
    },
  });
}


function* fetchMeasurements(documentId, accessToken) {
  return yield call(ApiService.regionalRequest, `/api/Storage/measurements/${documentId}`, {
    headers: {
      Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
    },
  });
}


function* storeReadings(phiSetReferenceKey, importData, accessToken) {
  const transaction = yield call(openTransaction, [{
    lockType    : 'Exclusive',
    referenceKey: phiSetReferenceKey,
  }]);
  const { transactionId, revisions } = transaction;
  let revisionDocumentId = get(revisions, [0, 'documentId']);
  if (!revisionDocumentId) {
    revisionDocumentId = uuid();
  }

  // Transaction try
  try {
    const requestUrl = `/api/Storage/phiSet/${revisionDocumentId}/deviceData`;
    const { phiSetDocumentId, auditDocumentId, phiSet, importId } = yield call(ApiService.regionalRequest, requestUrl, {
      method : 'POST',
      headers: {
        Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
      },
      body: importData,
    });

    const updatedRevisions = [{
      auditDocumentId,
      previousDocumentId: revisionDocumentId,
      newDocumentId     : phiSetDocumentId,
      referenceKey      : phiSetReferenceKey,
    }];
    const forksHistory = yield call(commitTransaction, transactionId, updatedRevisions);

    const updatedPhiSet = transformPhiSet(phiSet);
    return { newPhiSetDocumentId: phiSetDocumentId, updatedPhiSet, importId, forksHistory };
  } catch (err) {
    yield call(closeTransaction, transactionId);
    throw err;
  }
}


function* storeCgmReadings(currentPhiSetDocumentId, importData, accessToken) {
  if (!currentPhiSetDocumentId) {
    currentPhiSetDocumentId = uuid();
  }
  const requestUrl = `/api/Storage/phiSet/${currentPhiSetDocumentId}/cgm/deviceData`;
  const { phiSetDocumentId, phiSet, auditDocumentId } = yield call(ApiService.regionalRequest, requestUrl, {
    method : 'POST',
    headers: {
      Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
    },
    body: importData,
  });
  const updatedPhiSet = transformPhiSet(phiSet);
  return { newPhiSetDocumentId: phiSetDocumentId, updatedPhiSet, auditDocumentId };
}


function* storeRelatedData(phiSetReferenceKey, importData, accessToken) {
  const transaction = yield call(openTransaction, [{
    lockType    : 'Exclusive',
    referenceKey: phiSetReferenceKey,
  }]);
  const { transactionId, revisions } = transaction;
  let revisionDocumentId = get(revisions, [0, 'documentId']);
  if (!revisionDocumentId) {
    revisionDocumentId = uuid();
  }

  // Transaction try
  try {
    const requestUrl = `/api/Storage/phiSet/${revisionDocumentId}/ReadingsRelatedData`;
    const { phiSetDocumentId, phiSet, importId, auditDocumentId } = yield call(ApiService.regionalRequest, requestUrl, {
      method : 'POST',
      headers: {
        Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
      },
      body: importData,
    });

    const updatedRevisions = [{
      auditDocumentId,
      previousDocumentId: revisionDocumentId,
      newDocumentId     : phiSetDocumentId,
      referenceKey      : phiSetReferenceKey,
    }];
    const forksHistory = yield call(commitTransaction, transactionId, updatedRevisions);

    const updatedPhiSet = transformPhiSet(phiSet);
    return { newPhiSetDocumentId: phiSetDocumentId, updatedPhiSet, importId, forksHistory };
  } catch (err) {
    yield call(closeTransaction, transactionId);
    throw err;
  }
}


function* storeTimeSeriesResources(phiSetReferenceKey, importData, accessToken) {
  const transaction = yield call(openTransaction, [{
    lockType    : 'Exclusive',
    referenceKey: phiSetReferenceKey,
  }]);
  const { transactionId, revisions } = transaction;
  let revisionDocumentId = get(revisions, [0, 'documentId']);
  if (!revisionDocumentId) {
    revisionDocumentId = uuid();
  }

  // Transaction try
  try {
    const requestUrl = `/api/Storage/phiSet/${revisionDocumentId}/TimeSeriesResources`;
    const { phiSetDocumentId, phiSet, importId, auditDocumentId } = yield call(ApiService.regionalRequest, requestUrl, {
      method : 'POST',
      headers: {
        Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
      },
      body: importData,
    });

    const updatedRevisions = [{
      auditDocumentId,
      previousDocumentId: revisionDocumentId,
      newDocumentId     : phiSetDocumentId,
      referenceKey      : phiSetReferenceKey,
    }];
    const forksHistory = yield call(commitTransaction, transactionId, updatedRevisions);

    const updatedPhiSet = transformPhiSet(phiSet);
    return { newPhiSetDocumentId: phiSetDocumentId, updatedPhiSet, importId, forksHistory };
  } catch (err) {
    yield call(closeTransaction, transactionId);
    throw err;
  }
}


function* storeDetails(currentPhiSetDocumentId, details, accessToken) {
  const requestUrl = `/api/Storage/phiSet/${currentPhiSetDocumentId}/details`;
  const body = compactObject(pick(
    details,
    [
      'diabetesType', 'treatmentType',
      'encryptedStatisticalPersonalityId', 'customRanges',
      'glucoseLevelTargets', 'syncSnapshot',
      'kpi',
    ],
  ));
  if (!body.kpi && details.kpi) {
    body.kpi = details.kpi;
  }
  if (body.customRanges) {
    body.customRanges = map(body.customRanges, (cr) => ({
      hour   : cr[0],
      minutes: cr[1],
      label  : cr[2] || null,
    }));
  }

  const { phiSetDocumentId, phiSet, auditDocumentId } = yield call(ApiService.regionalRequest, requestUrl, {
    method : 'PUT',
    headers: {
      Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
    },
    body,
  });
  const updatedPhiSet = transformPhiSet(phiSet);
  return { newPhiSetDocumentId: phiSetDocumentId, updatedPhiSet, auditDocumentId };
}


function* storeMeasurements(currentPhiSetDocumentId, currentPhiSet, measurements, phisetVisitId, accessToken) {
  if (!currentPhiSetDocumentId) {
    currentPhiSetDocumentId = uuid();
  }
  if (!isEmpty(measurements)) {
    const requestUrl = `/api/Storage/phiSet/${currentPhiSetDocumentId}/measurement`;
    const { phiSetDocumentId } = yield call(ApiService.regionalRequest, requestUrl, {
      method : 'POST',
      headers: {
        Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
      },
      body: { measurements, phisetVisitId },
    });
    currentPhiSetDocumentId = phiSetDocumentId;
  }

  return yield call(storeDetails, currentPhiSetDocumentId, currentPhiSet, accessToken);
}


function* storeNotes(currentPhiSetDocumentId, notes, phisetVisitId, accessToken) {
  const requestUrl = `/api/Storage/phiSet/${currentPhiSetDocumentId}/notes`;
  const { phiSetDocumentId, phiSet, auditDocumentId } = yield call(ApiService.regionalRequest, requestUrl, {
    method : 'POST',
    headers: {
      Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
    },
    body: {
      notes,
      phisetVisitId,
    },
  });
  const updatedPhiSet = transformPhiSet(phiSet);
  return { newPhiSetDocumentId: phiSetDocumentId, updatedPhiSet, auditDocumentId };
}


function* removeNote(currentPhiSetDocumentId, notesDocumentId, note, accessToken) {
  const { noteId } = note;
  const requestUrl = `/api/Storage/phiSet/${currentPhiSetDocumentId}/notes/${notesDocumentId}`;
  const { phiSetDocumentId, phiSet, auditDocumentId } = yield call(ApiService.regionalRequest, requestUrl, {
    method : 'DELETE',
    headers: {
      Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
    },
    body: {
      noteId,
    },
  });
  const updatedPhiSet = transformPhiSet(phiSet);
  return { newPhiSetDocumentId: phiSetDocumentId, updatedPhiSet, auditDocumentId };
}


function* storeVisit(currentPhiSetDocumentId, visit, accessToken) {
  if (!currentPhiSetDocumentId) {
    currentPhiSetDocumentId = uuid();
  }
  const visitValues = pick(visit, ['phisetVisitId', 'encryptedVisitMetadataId', 'measurements', 'notes']);
  const requestUrl = `/api/Storage/phiSet/${currentPhiSetDocumentId}/visit`;
  const { phiSetDocumentId, phiSet } = yield call(ApiService.regionalRequest, requestUrl, {
    method : 'POST',
    headers: {
      Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
    },
    body: visitValues,
  });
  const updatedPhiSet = transformPhiSet(phiSet);
  return { newPhiSetDocumentId: phiSetDocumentId, updatedPhiSet };
}


function* removePhiData(documentId, accessToken) {
  if (!documentId) {
    return;
  }
  const requestURL = `/api/Storage/phiSet/${documentId}/data`;
  yield call(ApiService.regionalRequest, requestURL, {
    method : 'DELETE',
    headers: {
      Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
    },
  });
}

//----------------------------------------------------------------------------------------------------------------------

function* clearForks(documentsIdentifiers, accessToken) {
  const requestURL = '/api/Storage/forks';
  yield call(ApiService.regionalRequest, requestURL, {
    method : 'DELETE',
    headers: {
      Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`,
    },
    body: {
      documentsIdentifiers,
    },
  });
}

//----------------------------------------------------------------------------------------------------------------------


export default {
  fetchPatients,
  addPatient,
  updatePatient,
  removePatient,
  enableFullLinkingPatient,

  fetchPhiSet,
  fetchReadings,
  fetchCgmReadings,
  fetchRelatedData,
  fetchTimeSeriesResources,
  fetchAggregations,
  fetchNotes,
  fetchMeasurements,
  storeReadings,
  storeCgmReadings,
  storeRelatedData,
  storeTimeSeriesResources,
  storeDetails,
  storeMeasurements,
  storeNotes,
  removeNote,
  storeVisit,
  removePhiData,

  clearForks,
  fetchAvatarImg,
};
