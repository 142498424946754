import { MODULE_ID } from './constants';


export const FETCH_PATIENTS = `${MODULE_ID}/FETCH_PATIENTS`;
export const FETCH_PATIENTS_SUCCESS = `${MODULE_ID}/FETCH_PATIENTS_SUCCESS`;
export const FETCH_PATIENTS_ERROR = `${MODULE_ID}/FETCH_PATIENTS_ERROR`;

export const FETCH_ENROLLING_SHARING_REQUESTS = `${MODULE_ID}/FETCH_ENROLLING_SHARING_REQUESTS`;
export const FETCH_ENROLLING_SHARING_REQUESTS_SUCCESS = `${MODULE_ID}/FETCH_ENROLLING_SHARING_REQUESTS_SUCCESS`;
export const FETCH_ENROLLING_SHARING_REQUESTS_ERROR = `${MODULE_ID}/FETCH_ENROLLING_SHARING_REQUESTS_ERROR`;

export const ADD_PATIENT = `${MODULE_ID}/ADD_PATIENT`;
export const ADD_PATIENT_SUCCESS = `${MODULE_ID}/ADD_PATIENT_SUCCESS`;
export const ADD_PATIENT_ERROR = `${MODULE_ID}/ADD_PATIENT_ERROR`;

export const ENROLL_PATIENT = `${MODULE_ID}/ENROLL_PATIENT`;
export const ENROLL_PATIENT_TRANSITION = `${MODULE_ID}/ENROLL_PATIENT_TRANSITION`;
export const ENROLL_PATIENT_SUCCESS = `${MODULE_ID}/ENROLL_PATIENT_SUCCESS`;
export const ENROLL_PATIENT_ERROR = `${MODULE_ID}/ENROLL_PATIENT_ERROR`;

export const MERGE_PATIENT = `${MODULE_ID}/MERGE_PATIENT`;
export const MERGE_PATIENT_SUCCESS = `${MODULE_ID}/MERGE_PATIENT_SUCCESS`;
export const MERGE_PATIENT_ERROR = `${MODULE_ID}/MERGE_PATIENT_ERROR`;

export const UPDATE_PATIENT = `${MODULE_ID}/UPDATE_PATIENT`;
export const UPDATE_PATIENT_SUCCESS = `${MODULE_ID}/UPDATE_PATIENT_SUCCESS`;
export const UPDATE_PATIENT_ERROR = `${MODULE_ID}/UPDATE_PATIENT_ERROR`;

export const PREVIEW_PATIENT_OPEN_TAB = `${MODULE_ID}/PREVIEW_PATIENT_OPEN_TAB`;
export const PREVIEW_PATIENT_OPEN_TAB_SUCCESS = `${MODULE_ID}/PREVIEW_PATIENT_OPEN_TAB_SUCCESS`;
export const PREVIEW_PATIENT_OPEN_TAB_ERROR = `${MODULE_ID}/PREVIEW_PATIENT_OPEN_TAB_ERROR`;

export const START_PATIENT_VISIT = `${MODULE_ID}/START_PATIENT_VISIT`;
export const START_PATIENT_VISIT_SUCCESS = `${MODULE_ID}/START_PATIENT_VISIT_SUCCESS`;
export const START_PATIENT_VISIT_ERROR = `${MODULE_ID}/START_PATIENT_VISIT_ERROR`;

export const END_PATIENT_VISIT = `${MODULE_ID}/END_PATIENT_VISIT`;
export const END_PATIENT_VISIT_SUCCESS = `${MODULE_ID}/END_PATIENT_VISIT_SUCCESS`;
export const END_PATIENT_VISIT_ERROR = `${MODULE_ID}/END_PATIENT_VISIT_ERROR`;

export const REMOVE_PATIENT = `${MODULE_ID}/REMOVE_PATIENT`;
export const REMOVE_PATIENT_SUCCESS = `${MODULE_ID}/REMOVE_PATIENT_SUCCESS`;
export const REMOVE_PATIENT_ERROR = `${MODULE_ID}/REMOVE_PATIENT_ERROR`;

export const REASSIGN_HCP = `${MODULE_ID}/REASSIGN_HCP`;
export const REASSIGN_HCP_SUCCESS = `${MODULE_ID}/REASSIGN_HCP_SUCCESS`;
export const REASSIGN_HCP_ERROR = `${MODULE_ID}/REASSIGN_HCP_ERROR`;

export const ACTIVATE_PATIENT = `${MODULE_ID}/ACTIVATE_PATIENT`;
export const ACTIVATE_PATIENT_SUCCESS = `${MODULE_ID}/ACTIVATE_PATIENT_SUCCESS`;
export const ACTIVATE_PATIENT_ERROR = `${MODULE_ID}/ACTIVATE_PATIENT_ERROR`;

export const DEACTIVATE_PATIENT = `${MODULE_ID}/DEACTIVATE_PATIENT`;

export const SET_PATIENTS_SEARCH = `${MODULE_ID}/SET_PATIENTS_SEARCH`;
export const SET_CLINICS_SEARCH = `${MODULE_ID}/SET_CLINICS_SEARCH`;

export const SET_PHI_SET = `${MODULE_ID}/SET_PHI_SET`;
export const SET_READINGS = `${MODULE_ID}/SET_READINGS`;
export const SET_MEASUREMENTS = `${MODULE_ID}/SET_MEASUREMENTS`;
export const SET_TIME_SERIES_RESOURCES = `${MODULE_ID}/SET_TIME_SERIES_RESOURCES`;
export const SET_IMPORTED_READINGS = `${MODULE_ID}/SET_IMPORTED_READINGS`;
export const SET_NOTES = `${MODULE_ID}/SET_NOTES`;
export const CLEAR_NOTES_BATCHES = `${MODULE_ID}/CLEAR_NOTES_BATCHES`;
export const SET_SHARING_REQUEST = `${MODULE_ID}/SET_SHARING_REQUEST`;

export const SET_RECEIVED_SHARING_REQUEST = `${MODULE_ID}/SET_RECEIVED_SHARING_REQUEST`;
export const UNSET_RECEIVED_SHARING_REQUEST = `${MODULE_ID}/UNSET_RECEIVED_SHARING_REQUEST`;

export const CREATE_SHARING_REQUEST = `${MODULE_ID}/CREATE_SHARING_REQUEST`;
export const CREATE_SHARING_REQUEST_SUCCESS = `${MODULE_ID}/CREATE_SHARING_REQUEST_SUCCESS`;
export const CREATE_SHARING_REQUEST_ERROR = `${MODULE_ID}/CREATE_SHARING_REQUEST_ERROR`;

export const REMOVE_SHARING_REQUEST = `${MODULE_ID}/REMOVE_SHARING_REQUEST`;
export const REMOVE_SHARING_REQUEST_SUCCESS = `${MODULE_ID}/REMOVE_SHARING_REQUEST_SUCCESS`;
export const REMOVE_SHARING_REQUEST_ERROR = `${MODULE_ID}/REMOVE_SHARING_REQUEST_ERROR`;

export const RESEND_SHARING_REQUEST = `${MODULE_ID}/RESEND_SHARING_REQUEST`;
export const RESEND_SHARING_REQUEST_SUCCESS = `${MODULE_ID}/RESEND_SHARING_REQUEST_SUCCESS`;
export const RESEND_SHARING_REQUEST_ERROR = `${MODULE_ID}/RESEND_SHARING_REQUEST_ERROR`;

export const CHECK_SHARING_REQUEST_APPROVE = `${MODULE_ID}/CHECK_SHARING_REQUEST_APPROVE`;
export const CHECK_SHARING_REQUEST_APPROVE_SUCCESS = `${MODULE_ID}/CHECK_SHARING_REQUEST_APPROVE_SUCCESS`;
export const CHECK_SHARING_REQUEST_APPROVE_ERROR = `${MODULE_ID}/CHECK_SHARING_REQUEST_APPROVE_ERROR`;
export const STOP_CHECK_SHARING_REQUEST_APPROVE = `${MODULE_ID}/STOP_CHECK_SHARING_REQUEST_APPROVE`;

export const BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT = `${MODULE_ID}/BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT`;
export const BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT_SUCCESS = `${MODULE_ID}/BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT_SUCCESS`;
export const BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT_ERROR = `${MODULE_ID}/BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT_ERROR`;

export const SET_PATIENT_DATA_COMPARISON = `${MODULE_ID}/SET_PATIENT_DATA_COMPARISON`;
export const SET_IS_PATIENT_DATA_FULL_COMPARISON = `${MODULE_ID}/SET_IS_PATIENT_DATA_FULL_COMPARISON`;

export const START_SYNC_QUEUE = `${MODULE_ID}/START_SYNC_QUEUE`;
export const STOP_SYNC_QUEUE = `${MODULE_ID}/STOP_SYNC_QUEUE`;

export const SYNC = `${MODULE_ID}/SYNC`;
export const SYNC_FINISH = `${MODULE_ID}/SYNC_FINISH`;

export const STORE_PATIENT_INFO = `${MODULE_ID}/STORE_PATIENT_INFO`;
export const STORE_PATIENT_INFO_SUCCESS = `${MODULE_ID}/STORE_PATIENT_INFO_SUCCESS`;
export const STORE_PATIENT_INFO_ERROR = `${MODULE_ID}/STORE_PATIENT_INFO_ERROR`;

export const STORE_AND_PUSH_NOTE = `${MODULE_ID}/STORE_AND_PUSH_NOTE`;
export const STORE_AND_PUSH_NOTE_SUCCESS = `${MODULE_ID}/STORE_AND_PUSH_NOTE_SUCCESS`;
export const STORE_AND_PUSH_NOTE_ERROR = `${MODULE_ID}/STORE_AND_PUSH_NOTE_ERROR`;

export const SET_IMPORTED_RELATED_DATA = `${MODULE_ID}/SET_IMPORTED_RELATED_DATA`;

export const FETCH_PATIENT_COUNTRY_LOCALIZATION = `${MODULE_ID}/FETCH_PATIENT_COUNTRY_LOCALIZATION`;
export const FETCH_PATIENT_COUNTRY_LOCALIZATION_SUCCESS = `${MODULE_ID}/FETCH_PATIENT_COUNTRY_LOCALIZATION_SUCCESS`;
export const FETCH_PATIENT_COUNTRY_LOCALIZATION_ERROR = `${MODULE_ID}/FETCH_PATIENT_COUNTRY_LOCALIZATION_ERROR`;

export const FETCH_PAYERS = `${MODULE_ID}/FETCH_PAYERS`;
export const FETCH_PAYERS_SUCCESS = `${MODULE_ID}/FETCH_PAYERS_SUCCESS`;
export const FETCH_PAYERS_ERROR = `${MODULE_ID}/FETCH_PAYERS_ERROR`;

export const ADD_PATIENT_TO_FAVORITE = `${MODULE_ID}/ADD_PATIENT_TO_FAVORITE`;
export const ADD_PATIENT_TO_FAVORITE_SUCCESS = `${MODULE_ID}/ADD_PATIENT_TO_FAVORITE_SUCCESS`;
export const ADD_PATIENT_TO_FAVORITE_ERROR = `${MODULE_ID}/ADD_PATIENT_TO_FAVORITE_ERROR`;
