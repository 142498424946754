import forEach from 'lodash/forEach';
import forOwn from 'lodash/forIn';
import get from 'lodash/get';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { mean, median, std, round } from 'mathjs';


export const isAggregatedPreMeal = (flag) => flag === 'PreMeal' || flag === 'Fasting';
export const isAggregatedPostMeal = (flag) => !isAggregatedPreMeal(flag);


const calculate = (aggregatedData, calculationFormula, comparisonData) => {
  const calculatedData = [];
  forOwn(aggregatedData, (values, ts) => {
    const timestamp = +ts;
    switch (calculationFormula) {
      case 'MEAN': {
        calculatedData.push({ timestamp, value: mean(values) });
        break;
      }
      case 'MEDIAN+STD': {
        calculatedData.push({ timestamp, value: median(values), stdDev: std(values) });
        break;
      }
      case 'HYPERGLYCEMIA':
      case 'HYPOGLYCEMIA': {
        const all = values.length;
        const comparison = comparisonData[timestamp] ? comparisonData[timestamp].length : 0;
        calculatedData.push({ timestamp, value: round((comparison / all) * 100, 2) });
        break;
      }
      case 'COUNT': {
        calculatedData.push({ timestamp, value: values.length });
        break;
      }
      default: {
        break;
      }
    }
  });
  return calculatedData;
};

const getIsComparisonValue = (calculationFormula, standards, value, flags) => {
  if (calculationFormula === 'HYPERGLYCEMIA' || calculationFormula === 'HYPOGLYCEMIA') {
    const isHyper = calculationFormula === 'HYPERGLYCEMIA';
    const comparisonStandards = standards[isAggregatedPostMeal(flags) ? 'postMeal' : 'preMeal'];
    const threshold = isHyper ? comparisonStandards.highThreshold : comparisonStandards.lowThreshold;
    if ((isHyper && value > threshold) || (!isHyper && value < threshold)) {
      return true;
    }
  }
  return false;
};


const hoursRangeAggregate = (that, data, calculationFormula) => {
  const breakPoints = that.hoursBreakpoints;
  const comparisonData = {};
  const aggregatedData = reduce(data, (acc, d) => {
    const value = d[that.valueKey];
    const momentDate = moment.unix(d[that.timestampKey]).utc().locale('en--account');
    const hour = momentDate.hour();
    const minutes = momentDate.minutes();
    let breakPointIdx = breakPoints.length - 1;

    forEach(breakPoints, (breakPoint, idx) => {
      if (breakPoint[0] < hour || (breakPoint[0] === hour && breakPoint[1] < minutes)) {
        breakPointIdx = idx;
      }
    });

    if (that.startHour > 0 && momentDate.hours() < that.startHour) {
      momentDate.add(-1, 'days');
    }

    let aggregatedStart = +momentDate
      .hour(breakPoints[breakPointIdx][0])
      .minutes(breakPoints[breakPointIdx][1])
      .seconds(0)
      .format('X');

    if (breakPointIdx === breakPoints.length - 1) {
      if (that.startHour > 0) {
        aggregatedStart = +momentDate
          .hour(breakPoints[breakPointIdx][0])
          .minutes(breakPoints[breakPointIdx][1])
          .seconds(0)
          .format('X');
        momentDate
          .hour(breakPoints[0][0])
          .minutes(breakPoints[0][1])
          .add(1, 'days');
      } else {
        momentDate.endOf('day');
      }
    } else {
      momentDate
        .hour(breakPoints[breakPointIdx + 1][0])
        .minutes(breakPoints[breakPointIdx + 1][1]);
    }
    const aggregatedEnd = +momentDate.format('X');
    const aggregatedTimestamp = Math.round(aggregatedStart + (aggregatedEnd - aggregatedStart) / 2);
    const values = get(acc, aggregatedTimestamp, []);
    values.push(value);
    acc[aggregatedTimestamp] = values;
    if (getIsComparisonValue(calculationFormula, that.standards, value, d.flags)) {
      const comparisonValues = get(comparisonData, aggregatedTimestamp, []);
      comparisonValues.push(value);
      comparisonData[aggregatedTimestamp] = comparisonValues;
    }
    return acc;
  }, {});

  return calculate(aggregatedData, calculationFormula, comparisonData);
};


export default class StatsCalculations {

  constructor(data, standards, hoursBreakpoints, valueKey = 'value', timestampKey = 'timestamp', startHour = 0) {
    this.data = [...data];
    this.tmp = null;
    this.standards = standards;
    this.hoursBreakpoints = hoursBreakpoints;
    this.valueKey = valueKey;
    this.timestampKey = timestampKey;
    this.startHour = startHour;
  }


  aggregate(aggregateBy, calculationFormula) {
    if (calculationFormula === 'NONE') {
      return this.tmp || this.data;
    }
    const unit = aggregateBy.toLowerCase();
    const comparisonData = {};
    const aggregatedData = reduce(this.tmp || this.data, (acc, d) => {
      const value = d[this.valueKey];
      const momentDate = moment.unix(d[this.timestampKey]).utc().locale('en--account');
      const aggregatedStart = +momentDate.startOf(unit).format('X');
      const aggregatedEnd = +momentDate.endOf(unit).format('X');
      const aggregatedTimestamp = Math.round(aggregatedStart + (aggregatedEnd - aggregatedStart) / 2);
      const values = get(acc, aggregatedTimestamp, []);
      values.push(value);
      acc[aggregatedTimestamp] = values;
      if (getIsComparisonValue(calculationFormula, this.standards, value, d.flags)) {
        const comparisonValues = get(comparisonData, aggregatedTimestamp, []);
        comparisonValues.push(value);
        comparisonData[aggregatedTimestamp] = comparisonValues;
      }
      // if (calculationFormula === 'HYPERGLYCEMIA' || calculationFormula === 'HYPOGLYCEMIA') {
      //   const isHyper = calculationFormula === 'HYPERGLYCEMIA';
      //   const isPostMeal = d.flags === 'PostMeal';
      //   const standards = this.standards.bloodGlucoseConcentration[isPostMeal ? 'postMeal' : 'preMeal'];
      //   const threshold = isHyper ? standards.highThreshold : standards.lowThreshold;
      //   if ((isHyper && value > threshold) || (!isHyper && value < threshold)) {
      //     const comparisonValues = get(comparisonData, aggregatedTimestamp, []);
      //     comparisonValues.push(value);
      //     comparisonData[aggregatedTimestamp] = comparisonValues;
      //   }
      // }
      return acc;
    }, {});
    this.tmp = null;
    return calculate(aggregatedData, calculationFormula, comparisonData);
  }


  group(groupBy, calculationFormula) {
    const now = moment().utc().locale('en--account');
    const yestarday = moment().add(1, 'days').utc().locale('en--account');
    const groupedData = sortBy(map(this.data, (d) => {
      const momentDate = moment.unix(d[this.timestampKey]).utc().locale('en--account');
      if (groupBy === 'WEEKDAY') {
        const weekday = momentDate.weekday();
        momentDate.year(now.year()).week(now.week()).date(now.date()).weekday(weekday);
      } else if (groupBy === 'HOURS_RANGE') {
        const dayBehind = momentDate.hours() < this.startHour;
        const date = dayBehind ? yestarday : now;
        momentDate.year(date.year()).month(date.month()).date(date.date());
      } else {
        momentDate.year(now.year());
      }
      return { ...d, timestamp: +momentDate.format('X'), originalTimestamp: d[this.timestampKey] };
    }), ['timestamp']);

    if (calculationFormula === 'NONE') {
      return groupedData;
    }

    if (groupBy === 'HOURS_RANGE') {
      return hoursRangeAggregate(this, groupedData, calculationFormula);
    }

    this.tmp = groupedData;
    const aggregateBy = groupBy === 'WEEKDAY' ? 'DAY' : groupBy;
    return this.aggregate(aggregateBy, calculationFormula);
  }


}
