import { FormattedMessage } from 'react-intl';
import React, { FC, memo, useContext, useEffect, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { AppContext } from 'context';
import App from 'modules/App';
import messages from '../../messages';
import styles from './CurrentVersion.pcss';


const CurrentVersion: FC = () => {
  useStyles(styles);
  const { currentVersion, releaseDate, changelogUrl } = useContext(AppContext);
  const [relDate, setRelDate] = useState('');
  const currentLocale = useSelector(App.selectors.locale);
  const momentDate = moment.unix(new Date(releaseDate).getTime() / 1000).utc();

  useEffect(() => {
    setRelDate(momentDate.format('L'));
  }, [currentLocale]);

  return (
    <div className={styles.currentVersion}>
      <div className={styles.currentVersion__versionName}>
        <FormattedMessage {...messages.sidebar.labels.currentVersion} />: { currentVersion }
      </div>
      <div className={styles.currentVersion__releaseDate}>
        <FormattedMessage {...messages.sidebar.labels.releaseDate} />: { relDate }
      </div>
      <a
        href={changelogUrl}
        target="_blank"
        className={styles.currentVersion__changeLogUrl}
      ><FormattedMessage {...messages.sidebar.labels.seeChangeLog} />
      </a>
    </div>
  );
};

export default memo(CurrentVersion);
