import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import get from 'lodash/get';
import pick from 'lodash/pick';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import FormContainerAbstract from 'components/FormContainerAbstract';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import App from 'modules/App';
import CloudDrive from 'modules/CloudDrive';
import Visit from 'modules/Visit';
import accessTokenShape from 'shapes/accessTokenShape';
import Button from 'components/Form/Button';
import Form from 'components/Form';
import intlShape from 'shapes/intlShape';
import * as constants from '../../../../constants';
import messages from '../../../../messages';
import * as styles from '../Forms.pcss';
import DateTimePicker from '../../../../partials/DateTimePicker';
import { getTimestamp } from '../../../../helpers';
import validatorRules from './validatorRules.json';


class BloodPressureForm extends FormContainerAbstract {

  static propTypes = {
    ...FormContainerAbstract.propTypes,
    // Explicit props
    locale          : PropTypes.string,
    formValues      : PropTypes.object,
    phiSet          : PropTypes.object,
    phiSetDocumentId: PropTypes.string,
    patient         : PropTypes.shape({
      id                : PropTypes.string.isRequired,
      countryId         : PropTypes.number.isRequired,
      phiSetReferenceKey: PropTypes.string,
      storageProvider   : PropTypes.string,
      accessToken       : accessTokenShape,
      firstName         : PropTypes.string,
      lastName          : PropTypes.string,
    }),
    metricsConversions : PropTypes.object,
    // Implicit props
    isInProgress       : PropTypes.bool,
    intl               : intlShape,
    // Explicit actions
    onSubmit           : PropTypes.func,
    onSuccess          : PropTypes.func,
    // Implicit actions
    onSetFormValue     : PropTypes.func,
    onSetFormProcessing: PropTypes.func,
    onSetFormValues    : PropTypes.func,
    onFormErrors       : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.validatorRules = { ...validatorRules };
  }


  onSubmit() {
    this.props.onFormProcessing();
    const { validatedValues, errors } = this.onValidate(this.validatorRules);
    this.props.onFormErrors(errors);
    if (errors) {
      return;
    }

    const { patient, phiSet, phiSetDocumentId, activeVisit, onSuccess } = this.props;
    const { dateTimestamp, time } = validatedValues;
    const visitData = {
      phisetVisitId: get(activeVisit, 'phisetVisitId'),
      timestamp    : getTimestamp(dateTimestamp, time),
    };
    const measurements = pick(validatedValues, ['systolicBloodPressure', 'diastolicBloodPressure']);
    this.props.onSubmit(measurements, phiSet, phiSetDocumentId, patient, visitData, onSuccess);
  }


  renderBloodPressureRow() {
    const { isInProgress, metricsConversions } = this.props;
    const { unitSymbol } = metricsConversions.pressure;

    return (
      <div className={styles.root__row}>
        <div className={styles.root__cell}>
          <FormGroup
            id="systolicBloodPressure"
            labelMessage={messages.labels.systolicPressure}
            formValues={this.props.formValues}
            labelData={{
              'data-for': 'additionalMeasurementsBloodPressure',
              'data-tip': this.props.intl.formatMessage(messages.labels.systolicPressure),
            }}
          >
            <Input
              inputValue="true"
              type="number"
              attributes={{ maxLength: 3 }}
              labelMessage={messages.labels.systolicPressure}
              isDisabled={this.props.isInProgress}
              onChange={this.props.onSetFormValue}
            />
          </FormGroup>
          <div
            className={
              cn(styles.root__unit, {
                [styles['root__unit--disabled']]: isInProgress,
              })
            }
          >
            { unitSymbol }
          </div>
        </div>
        <div className={styles.root__cell}>
          <FormGroup
            id="diastolicBloodPressure"
            labelMessage={messages.labels.diastolicPressure}
            formValues={this.props.formValues}
            labelData={{
              'data-for': 'additionalMeasurementsBloodPressure',
              'data-tip': this.props.intl.formatMessage(messages.labels.diastolicPressure),
            }}
          >
            <Input
              inputValue="true"
              type="number"
              attributes={{ maxLength: 3 }}
              labelMessage={messages.labels.diastolicPressure}
              isDisabled={this.props.isInProgress}
              onChange={this.props.onSetFormValue}
            />
          </FormGroup>
          <div
            className={
              cn(styles.root__unit, {
                [styles['root_unit--disabled']]: isInProgress,
              })
            }
          >
            { unitSymbol }
          </div>
        </div>
      </div>
    );
  }


  render() {
    return (
      <Form className={styles.root} onSubmit={() => this.onSubmit()}>
        { this.renderBloodPressureRow() }
        <DateTimePicker
          locale={this.props.locale}
          formValues={this.props.formValues}
          isDisabled={this.props.isInProgress}
          onSetFormValue={this.props.onSetFormValue}
          onSetFormValues={this.props.onSetFormValues}
        />
        <Button
          styleModifier="primary"
          type="submit"
          labelMessage={messages.buttons.saveResult}
          className="btn--filled"
          isInProgress={this.props.isInProgress}
        />
        <ReactTooltip
          id="additionalMeasurementsBloodPressure"
          place="bottom"
          type="dark"
          effect="solid"
        />
      </Form>
    );
  }

}


const mapStateToProps = () => (state) => ({
  locale      : App.selectors.locale(state),
  formValues  : App.selectors.formSelector(constants.BLOOD_PRESSURE_FORM)(state),
  isInProgress: CloudDrive.selectors.isStoreMeasurementsInProgress(state),
  activeVisit : Visit.selectors.activeVisit(state),
});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.BLOOD_PRESSURE_FORM;

  return {
    onFormProcessing: () => dispatch(App.actions.startFormProcessing(formName)),
    onSetFormValue  : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onSetFormValues : (values) => dispatch(App.actions.setFormValues(formName, values)),
    onFormErrors    : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onClearForm     : () => dispatch(App.actions.clearForm(formName)),
  };
};


const ConnectedBloodPressureForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(BloodPressureForm));


export default withStyles(styles)(ConnectedBloodPressureForm);
