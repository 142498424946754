import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/Modal';
import Button from 'components/Form/Button';
import accessTokenShape from 'shapes/accessTokenShape';
import App from 'modules/App';
import CloudDrive from 'modules/CloudDrive';
import * as actions from '../../../actions';
import * as constants from '../../../constants';
import * as selectors from '../../../selectors';
import messages from '../../../messages';
import styles from './GdprExportModal.pcss';


class GdprExportModal extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const {
      patientProfile,
      isFetchAccountDumpInProgress, isFetchDumpInProgress,
      hasFetchAccountDumpErrors, hasFetchPhiSetErrors, hasFetchDumpErrors,
    } = props;
    const hasErrors = hasFetchAccountDumpErrors || hasFetchPhiSetErrors || hasFetchDumpErrors;

    if (hasErrors) {
      return {
        isInProgress         : false,
        isFetchDumpInProgress: false,
      };
    }

    if (isFetchDumpInProgress !== state.isFetchDumpInProgress && patientProfile) {
      if (isFetchDumpInProgress) {
        return { isFetchDumpInProgress: true };
      }
      return {
        isInProgress         : false,
        isFetchDumpInProgress: false,
        isDataCollected      : true,
      };
    }

    if (isFetchAccountDumpInProgress !== state.isInProgress && !isFetchAccountDumpInProgress && !patientProfile) {
      return {
        isInProgress   : false,
        isDataCollected: true,
      };
    }
    return null;
  }


  static propTypes = {
    // Implicit props
    gdprData      : PropTypes.object,
    openModalId   : PropTypes.string,
    patientProfile: PropTypes.shape({
      accessToken    : accessTokenShape,
      storageProvider: PropTypes.string,
    }),
    // Implicit actions
    onConnect                      : PropTypes.func,
    onFetchPhiSet                  : PropTypes.func,
    onGdprFetchAccountDump         : PropTypes.func,
    onFetchCentralStorageDump      : PropTypes.func,
    onCancelFetchCentralStorageDump: PropTypes.func,
    onGdprSaveToFile               : PropTypes.func,
    onGdprClearData                : PropTypes.func,
    onCloseModal                   : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      isInProgress         : false,
      isFetchDumpInProgress: false,
      isDataCollected      : false,
      isDataSaved          : false,
    };
  }


  componentDidUpdate(prevProps) {
    if (!this.isOpen || !this.props.patientProfile) {
      return;
    }

    const { openModalId, patientProfile, gdprData } = this.props;
    const accessToken = get(patientProfile, 'accessToken');

    if (prevProps.openModalId !== openModalId && !accessToken) {
      this.props.onConnect(patientProfile);
      return;
    }

    const prevPhiSet = get(prevProps.gdprData, 'phiSet');
    const phiSet = get(gdprData, 'phiSet');
    if (prevPhiSet !== phiSet && phiSet) {
      this.props.onFetchCentralStorageDump(gdprData.phiSet, patientProfile);
    }
  }


  onCollectData() {
    this.setState({ isInProgress: true });
    this.props.onGdprFetchAccountDump();
    if (this.props.patientProfile) {
      this.props.onFetchPhiSet(this.props.patientProfile);
    }
  }


  onSaveToFile() {
    this.props.onGdprSaveToFile();
    this.setState({ isDataSaved: true });
  }


  async onClose() {
    this.props.onCancelFetchCentralStorageDump();
    await this.props.onCloseModal();
    this.props.onGdprClearData();
    this.setState({
      isInProgress         : false,
      isFetchDumpInProgress: false,
      isDataCollected      : false,
      isDataSaved          : false,
    });
  }


  onButtonAction() {
    const { isDataCollected, isDataSaved } = this.state;
    if (isDataSaved) return this.onClose();
    if (isDataCollected) return this.onSaveToFile();
    return this.onCollectData();
  }


  get isOpen() {
    return this.props.openModalId === constants.GDPR_EXPORT_MODAL;
  }


  get modalHeaderMessage() {
    return messages.headers.exportData;
  }


  get buttonMessage() {
    const { isDataCollected, isDataSaved } = this.state;
    if (isDataSaved) return messages.buttons.close;
    if (isDataCollected) return messages.buttons.save;
    return messages.buttons.collectData;
  }


  renderCancelButton(isDataSaved) {
    if (isDataSaved) {
      return null;
    }
    return (
      <div className="col-6">
        <Button
          styleModifier="quaternary"
          labelMessage={messages.buttons.cancel}
          className="btn--block mb-0"
          onClick={() => this.onClose()}
        />
      </div>
    );
  }


  renderActions() {
    const { isInProgress, isDataSaved } = this.state;
    if (isInProgress) {
      return (
        <p className="text--center pt-5">
          <FormattedMessage {...messages.infos.waitingForDataCollected} />
        </p>
      );
    }
    return (
      <div className="row w-100">
        { this.renderCancelButton(isDataSaved) }
        <div className={`col-${isDataSaved ? 12 : 6}`}>
          <Button
            styleModifier="primary"
            labelMessage={this.buttonMessage}
            className="btn--block btn--filled mb-0"
            onClick={() => this.onButtonAction()}
          />
        </div>
      </div>
    );
  }


  renderInnerContent() {
    const { isInProgress, isDataCollected, isDataSaved } = this.state;
    if (isInProgress) {
      return <div className="text--center"><img src="/assets/svg/loader.svg" className="rotatingLoader" alt="" /></div>;
    }
    if (isDataSaved) return <FormattedMessage {...messages.infos.dataSaved} />;
    if (isDataCollected) return <FormattedMessage {...messages.infos.dataCollected} />;
    return <FormattedMessage {...messages.infos.collectData} />;
  }


  renderModalContent() {
    return (
      <div className={styles.content}>
        <div className={styles.innerContent}>
          { this.renderInnerContent() }
        </div>
        { this.renderActions() }
      </div>
    );
  }


  render() {
    return (
      <Modal
        modalId={constants.GDPR_EXPORT_MODAL}
        openModalId={this.props.openModalId}
        headerMessage={this.modalHeaderMessage}
        onClose={() => this.onClose()}
      >
        { this.renderModalContent() }
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  gdprData                    : selectors.gdprData(state),
  patientProfile              : selectors.patientProfile(state),
  isFetchAccountDumpInProgress: selectors.isFetchAccountDumpInProgress(state),
  hasFetchAccountDumpErrors   : selectors.hasFetchAccountDumpErrors(state),
  hasFetchPhiSetErrors        : CloudDrive.selectors.hasFetchPhiSetErrors(state),
  isFetchDumpInProgress       : CloudDrive.selectors.isFetchDumpInProgress(state),
  hasFetchDumpErrors          : CloudDrive.selectors.hasFetchDumpErrors(state),
  openModalId                 : App.selectors.modal(state),
});

const mapDispatchToProps = (dispatch) => ({
  onConnect    : (patientProfile) => dispatch(actions.connectToPatientStorage(patientProfile)),
  onFetchPhiSet: (patientProfile) => dispatch(CloudDrive.actions.fetchPhiSet(
    patientProfile,
    actions.gdprSetPhiSet,
  )),
  onGdprFetchAccountDump   : () => dispatch(actions.gdprFetchAccountDump()),
  onFetchCentralStorageDump: (phiSet, patient) => dispatch(
    CloudDrive.actions.fetchDump(phiSet, patient, actions.gdprSetCloudDriveDump)
  ),
  onCancelFetchCentralStorageDump: () => dispatch(CloudDrive.actions.cancelFetchDump()),
  onGdprSaveToFile               : () => dispatch(actions.gdprSaveToFile()),
  onGdprClearData                : () => dispatch(actions.gdprClearData()),
  onCloseModal                   : () => dispatch(App.actions.closeModal()),
});

const ConnectedGdprExportModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GdprExportModal);


export default withStyles(styles)(ConnectedGdprExportModal);
