import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import get from 'lodash/get';
import map from 'lodash/map';
import Avatar from 'components/Avatar';
import Button from 'components/Form/Button';
import App from 'modules/App';
import Account from 'modules/Account';
import * as actions from '../../../actions';
import * as selectors from '../../../selectors';
import * as shapes from '../../../shapes';
import messages from '../../../messages';
import OrganizationMemberships from './OrganizationMemberships';
import styles from './ClinicMemberships.pcss';


class ClinicMemberships extends React.PureComponent {

  static propTypes = {
    // Explicit props
    activeClinicMembership        : Account.shapes.clinicMembership.isRequired,
    clinicMemberships             : PropTypes.arrayOf(shapes.clinicMembership),
    // Implicit props
    isClientInitialized           : PropTypes.bool,
    // Implicit actions
    onFetchClinicMemberships      : PropTypes.func,
    onFetchOrganizationMemberships: PropTypes.func,
    onManageOrganization          : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.onFetchOrganizationMemberships();
  }


  onFetchOrganizationMemberships() {
    if (process.env.BROWSER && !this.props.isClientInitialized) {
      return;
    }
    const organizationUID = get(this.props.activeClinicMembership, 'clinic.organizationUID');
    this.props.onFetchOrganizationMemberships(organizationUID);
  }


  renderManageOrganizationBtn() {
    const organizationUID = get(this.props.activeClinicMembership, 'clinic.organizationUID');
    return (
      <Button
        styleModifier="primary"
        labelMessage={messages.buttons.manageOrganization}
        className="btn--filled"
        onClick={() => this.props.onManageOrganization(organizationUID)}
      />
    );
  }


  renderHcpAvatar(profile) {
    if (!profile) {
      return null;
    }
    return (
      <Avatar
        avatarImg={profile.avatar}
        name={[profile.firstName, profile.lastName]}
        className={styles.avatar}
        imgClassName={styles.avatar__img}
        initialsClassName={styles.avatar__initials}
      />
    );
  }


  renderLastSuccessfulLogin(clinicMembership) {
    const isClinicAdmin = get(this.props.activeClinicMembership, 'isAdmin');
    if (!isClinicAdmin) {
      return null;
    }
    const lastSuccessfulLoginTimestamp = get(clinicMembership, 'profile.lastSuccessfulLoginTimestamp');
    return (
      <td>{ moment.unix(lastSuccessfulLoginTimestamp).local().format('L LT') }</td>
    );
  }


  renderLastFailedLogin(clinicMembership) {
    const isClinicAdmin = get(this.props.activeClinicMembership, 'isAdmin');
    if (!isClinicAdmin) {
      return null;
    }
    const lastFailedLoginAttemptTimestamp = get(clinicMembership, 'profile.lastFailedLoginAttemptTimestamp');
    return (
      <td>
        {
          lastFailedLoginAttemptTimestamp
            ? moment.unix(lastFailedLoginAttemptTimestamp).local().format('L LT')
            : null
        }
      </td>
    );
  }


  renderMembership(clinicMembership) {
    const { clinicHcpMembershipId, profile, hcpBusinessEmail, isAdmin } = clinicMembership;

    return (
      <tr key={clinicHcpMembershipId} className="section__table__row">
        <td className="cell--avatar">{ this.renderHcpAvatar(profile) }</td>
        <td className="w-25" data-hj-suppress>{ profile.firstName } { profile.lastName }</td>
        <td>{ hcpBusinessEmail }</td>
        { this.renderLastSuccessfulLogin(clinicMembership) }
        { this.renderLastFailedLogin(clinicMembership) }
        <td>{ isAdmin && 'Admin' }</td>
      </tr>
    );
  }


  renderTableBody() {
    return (
      <tbody className="section__table__body">
        { map(this.props.clinicMemberships, (clinicMembership) => this.renderMembership(clinicMembership)) }
      </tbody>
    );
  }


  renderLastSuccessfulLoginHead() {
    const isClinicAdmin = get(this.props.activeClinicMembership, 'isAdmin');
    if (!isClinicAdmin) {
      return null;
    }
    return (
      <th><FormattedMessage {...messages.labels.lastLogin} /></th>
    );
  }


  renderLastFailedLoginHead() {
    const isClinicAdmin = get(this.props.activeClinicMembership, 'isAdmin');
    if (!isClinicAdmin) {
      return null;
    }
    return (
      <th><FormattedMessage {...messages.labels.lastLoginFailed} /></th>
    );
  }


  renderTableHead() {
    return (
      <thead className="section__table__head">
        <tr>
          <th colSpan={2}><FormattedMessage {...messages.labels.hcp} /></th>
          <th><FormattedMessage {...messages.labels.email} /></th>
          { this.renderLastSuccessfulLoginHead() }
          { this.renderLastFailedLoginHead() }
          <th aria-label="Admin" />
        </tr>
      </thead>
    );
  }


  renderMemberships() {
    return (
      <div className="section__table-container">
        <table className="section__table">
          { this.renderTableHead() }
          { this.renderTableBody() }
        </table>
      </div>
    );
  }


  render() {
    return (
      <div>
        <h2 className="section__header d-flex align-items-end justify-content-between">
          <span className="d-block"><FormattedMessage {...messages.headers.clinicHcps} /></span>
          { this.renderManageOrganizationBtn() }
        </h2>
        { this.renderMemberships() }
        <OrganizationMemberships status="Pending" headerMessage={messages.headers.clinicHcpPending} />
        <OrganizationMemberships status="Invitation" headerMessage={messages.headers.clinicHcpInvitations} />
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  clinicMemberships  : selectors.clinicMemberships(state),
  isClientInitialized: App.selectors.isClientInitialized(state),
});


const mapDispatchToProps = (dispatch) => ({
  onFetchOrganizationMemberships: (organizationUID) => dispatch(
    actions.fetchOrganizationMemberships(organizationUID, ['Invitation', 'Pending']),
  ),
  onManageOrganization: (organizationUID) => dispatch(actions.manageOrganization(organizationUID)),
});


const ConnectedClinicMemberships = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClinicMemberships);


export default withStyles(styles)(ConnectedClinicMemberships);
