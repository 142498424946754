import moment from 'moment';
import findKey from 'lodash/findKey';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import MetricConversions from 'libs/MetricConversions';
import App from 'modules/App';


const getUnit = (unit) => {
  switch (unit) {
    case 0:
      return App.constants.UNITS_SYMBOLS.MG_DL;
    case 1:
      return App.constants.UNITS_SYMBOLS.MMOL_L;
    default:
      return null;
  }
};


const CC_LITERAL_VALUE = {
  LO: [
    { type: App.constants.UNITS_SYMBOLS.MG_DL, value: 10 },
    { type: App.constants.UNITS_SYMBOLS.MMOL_L, value: 0.6 },
  ],
  HI: [
    { type: App.constants.UNITS_SYMBOLS.MG_DL, value: 600 },
    { type: App.constants.UNITS_SYMBOLS.MMOL_L, value: 33.3 },
  ],
};


const getValue = (value, unit) => {
  if (/^\d+$/.test(value)) return parseFloat(value);
  const literalValue = CC_LITERAL_VALUE[value];
  if (!literalValue) return parseFloat(value);
  const estimatedValue = literalValue.find((item) => item.type === getUnit(unit));
  if (!estimatedValue) return null;
  return estimatedValue.value;
};

// const CC_TYPES = {
//   0: 'Meter Reading',
//   1: 'Manual Entry',
//   2: 'External Source',
// };

// const getType = (type) => CC_TYPES[type];

const isManual = (type) => type === 1;

export const isManualReading = (reading) => reading.isManual || reading.deviceSerialNumberToken === 'default1111';

// const CC_MEAL_TYPE = {
//   0: 'None',
//   1: 'Breakfast',
//   2: 'Lunch',
//   3: 'Dinner',
//   4: 'Snack',
// };

// const getMealType = (mealType) => CC_MEAL_TYPE[mealType];

const CC_MEAL_CATEGORY = {
  0: 'None',
  1: 'PreMeal',
  2: 'PostMeal',
  3: 'Fasting',
};

export const getMealCategory = (mealCategory) => CC_MEAL_CATEGORY[mealCategory];

export const mapContourCloudData = (result) => {
  const allResults = [];
  if (!result.bgDataGroupedByDeviceSerialNumber) return allResults;
  const meterIdsGroup = Object.keys(result.bgDataGroupedByDeviceSerialNumber);

  meterIdsGroup.forEach((meterId) => {
    allResults.push(
      ...result.bgDataGroupedByDeviceSerialNumber[meterId].map((meter) => {
        const newMeter = {};
        newMeter.externalId = meter.id;
        newMeter.deviceUnit = getUnit(meter.unit);
        const lowerDeviceUnit = toLower(newMeter.deviceUnit);
        const readingUnit = findKey(App.constants.UNITS_SYMBOLS, (symbol) => toLower(symbol) === lowerDeviceUnit);
        const conversions = new MetricConversions({ bloodGlucoseConcentration: readingUnit });
        newMeter.deviceValue = getValue(meter.value, meter.unit);
        newMeter.flags = getMealCategory(meter.mealCategory);
        newMeter.timestamp = meter.dateTime;
        newMeter.value = conversions.bloodGlucoseConcentration.toStorage(newMeter.deviceValue);
        newMeter.deviceSerialNumberToken = meterId;
        newMeter.isManual = isManual(meter.type);
        newMeter.mealType = meter.mealType;
        return newMeter;
      }),
    );
  });

  return allResults;
};


export const mapContourCloudRelatedData = (result) => {
  if (!result.bgRelatedData) return [];
  return result.bgRelatedData;
};


export const prepareContourCloudDataForImport = (data) => {
  if (isEmpty(data)) return {};
  const deviceDate = data.reduce((prev, current) =>
    (prev.timestamp > current.timestamp ? prev : current),
  );
  const importData = {
    connectionType: 'Sync',
    deviceDate    : moment
      .unix(deviceDate.timestamp)
      .locale('en')
      .format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    deviceMode             : 'None',
    deviceName             : 'ContourCloud',
    deviceType             : 'None',
    readings               : data,
    readingsCount          : data.length,
    deviceSerialNumberToken: 'contourcloud',
  };
  return importData;
};

export const isNotDisconnectableDataSource = (dataSource) => includes(App.constants.NOT_DISCONNECTABLE_DATA_SOURCES, dataSource.dataSourceProvider);
