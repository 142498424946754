import {
  registerAction,
  unregisterAction,
} from 'helpers/reducerTools';
import * as actionTypes from './actionTypes';


const initialState = {
  fetching           : [],
  sending            : [],
  errors             : [],
  clinicKpiStatistics: [],
  kpiSkip            : 0,
  totalKpi           : 0,
};


export default function reducer(state = { ...initialState }, action) {

  switch (action.type) {

    case actionTypes.SEND_STATISTICS:
    case actionTypes.SEND_STATISTICS_FOR_CLINIC: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.SEND_STATISTICS),
        errors : unregisterAction(state.errors, actionTypes.SEND_STATISTICS),
      };
    }

    case actionTypes.SEND_STATISTICS_SUCCESS:
    case actionTypes.SEND_STATISTICS_FOR_CLINIC_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.SEND_STATISTICS),
      };
    }

    case actionTypes.SEND_STATISTICS_ERROR:
    case actionTypes.SEND_STATISTICS_FOR_CLINIC_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.SEND_STATISTICS),
        errors : registerAction(state.errors, actionTypes.SEND_STATISTICS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_CLINIC_KPI_STATISTICS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_CLINIC_KPI_STATISTICS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_CLINIC_KPI_STATISTICS),
      };
    }

    case actionTypes.FETCH_CLINIC_KPI_STATISTICS_SUCCESS: {
      const { clinicKpiStatistics, kpiSkip, totalKpi } = action.payload;

      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_CLINIC_KPI_STATISTICS),
        clinicKpiStatistics,
        kpiSkip,
        totalKpi,
      };
    }

    case actionTypes.FETCH_CLINIC_KPI_STATISTICS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_CLINIC_KPI_STATISTICS),
        errors  : registerAction(state.errors, actionTypes.FETCH_CLINIC_KPI_STATISTICS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    default: {
      return state;
    }

  }
}
