// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2L1zb{cursor:pointer;color:#95a1bd;font-size:14px;font-size:1.4rem;font-weight:700}._2L1zb svg{width:20px;height:20px;fill:#95a1bd;margin-right:10px}", ""]);
// Exports
exports.locals = {
	"locked": "_2L1zb"
};
module.exports = exports;
