import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import AmbulatoryGlucoseProfile from 'modules/AmbulatoryGlucoseProfile';
import HeaderSection from './HeaderSection';
import StatisticsSection from './StatisticsSection';
import TimeInRangesSection from './TimeInRangesSection';
import styles from './PrintableAgpReport.pcss';
import AgpSection from './AgpSection';
import DailyGlucoseProfilesSection from './DailyGlucoseProfilesSection';


class PrintableAgpReport extends React.Component {

  static propTypes = {
    // Explicit props
    firstName : PropTypes.string,
    lastName  : PropTypes.string,
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
  };


  render() {
    const { conversion, standards, firstName, lastName } = this.props;
    return (
      <div className={styles.printableAgpReport}>
        <div className="page">
          <HeaderSection
            firstName={firstName}
            lastName={lastName}
          />
          <StatisticsSection conversion={conversion} standards={standards} />
          <TimeInRangesSection conversion={conversion} standards={standards} />
          <AgpSection conversion={conversion} standards={standards} />
          <DailyGlucoseProfilesSection conversion={conversion} standards={standards} />
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  isDisplayedAgpProfileChart  : AmbulatoryGlucoseProfile.selectors.isDisplayedAgpProfileChart(state),
  isDisplayedTimeInRanges     : AmbulatoryGlucoseProfile.selectors.isDisplayedTimeInRanges(state),
  isDisplayedGlucoseStatistics: AmbulatoryGlucoseProfile.selectors.isDisplayedGlucoseStatistics(state),
});


const ConnectedPrintableAgpReport = connect(
  mapStateToProps,
)(PrintableAgpReport);

export default withStyles(styles)(ConnectedPrintableAgpReport);
