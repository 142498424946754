export default {
  levels: {
    high  : { id: 'levels.high' },
    target: { id: 'levels.target' },
    low   : { id: 'levels.low' },
  },

  flags: {
    All     : { id: 'flags.All' },
    Fasting : { id: 'flags.Fasting' },
    PreMeal : { id: 'flags.PreMeal' },
    PostMeal: { id: 'flags.PostMeal' },
    None    : { id: 'flags.None' },
  },

  diabetesTypes: {
    Type1      : { id: 'diabetesTypes.Type1' },
    Type2      : { id: 'diabetesTypes.Type2' },
    Type3      : { id: 'diabetesTypes.Type3' },
    PreDiabetes: { id: 'diabetesTypes.PreDiabetes' },
    NotSet     : { id: 'diabetesTypes.NotSet' },
    NotSure    : { id: 'diabetesTypes.NotSure' },
    NoResponse : { id: 'diabetesTypes.NoResponse' },
    GDM        : { id: 'diabetesTypes.GDM' },
  },

  treatmentTypes: {
    Lifestyle  : { id: 'treatmentTypes.Lifestyle' },
    Oral       : { id: 'treatmentTypes.Oral' },
    Combination: { id: 'treatmentTypes.Combination' },
    Insulin    : { id: 'treatmentTypes.Insulin' },
    NotSet     : { id: 'treatmentTypes.NotSet' },
    NotSure    : { id: 'treatmentTypes.NotSure' },
    NoResponse : { id: 'treatmentTypes.NoResponse' },
  },

  alerts: {
    error5xx             : { id: 'alerts.error5xx' },
    error5xxWithRequestId: { id: 'alerts.error5xxWithRequestId' },
    formError            : { id: 'alerts.formError' },
    genericError         : { id: 'alerts.genericError' },
    websocketError       : { id: 'alerts.websocketError' },
  },

  buttons: {
    acceptAllCookies: { id: 'buttons.acceptAllCookies' },
    back            : { id: 'buttons.back' },
    cancel          : { id: 'buttons.cancel' },
    clickToCopy     : { id: 'buttons.clickToCopy' },
    discardChanges  : { id: 'buttons.discardChanges' },
    saveChanges     : { id: 'buttons.saveChanges' },
  },

  infos: {
    cookieInfo               : { id: 'infos.cookieInfo' },
    menuDisabledIfActiveVisit: { id: 'infos.menuDisabledIfActiveVisit' },
  },

  labels: {
    country           : { id: 'labels.country' },
    dateOfBirth       : { id: 'labels.dateOfBirth' },
    diabetesType      : { id: 'labels.diabetesType' },
    treatmentType     : { id: 'labels.treatmentType' },
    height            : { id: 'labels.height' },
    weight            : { id: 'labels.weight' },
    patients          : { id: 'labels.patients' },
    payer             : { id: 'labels.payer' },
    personalIdentifier: { id: 'labels.personalIdentifier' },
    rowsPerPage       : { id: 'labels.rowsPerPage' },
    yes               : { id: 'labels.yes' },
    no                : { id: 'labels.no' },
    language          : { id: 'labels.language' },
    locked            : { id: 'labels.locked' },
    unlocked          : { id: 'labels.unlocked' },
    preMeal           : { id: 'labels.preMeal' },
    postMeal          : { id: 'labels.postMeal' },
    legend            : { id: 'labels.legend' },
    step              : { id: 'labels.step' },
  },

  links: {
    privacyPolicy: { id: 'links.privacyPolicy' },
  },

  placeholders: {
    country      : { id: 'placeholders.country' },
    language     : { id: 'placeholders.language' },
    diabetesType : { id: 'placeholders.diabetesType' },
    treatmentType: { id: 'placeholders.treatmentType' },
    height       : { id: 'placeholders.height' },
    weight       : { id: 'placeholders.weight' },
    day          : { id: 'placeholders.day' },
    month        : { id: 'placeholders.month' },
    year         : { id: 'placeholders.year' },
    empty        : { id: 'placeholders.empty' },
    payer        : { id: 'placeholders.payer' },
  },

  errorPage: {
    errorCode   : { id: 'errorPage.errorCode' },
    goToHomePage: { id: 'errorPage.goToHomePage' },
    general     : {
      title: { id: 'errorPage.general.title' },
      info : { id: 'errorPage.general.info' },
      info2: { id: 'errorPage.general.info2' },
    },
    notFound: {
      title: { id: 'errorPage.notFound.title' },
      info : { id: 'errorPage.notFound.info' },
    },
  },

  errors: {
    businessErrors: {
      GoogleDriveError       : { id: 'errors.businessErrors.GoogleDriveError' },
      NotFound               : { id: 'errors.businessErrors.NotFound' },
      StorageAccountMismatch : { id: 'errors.businessErrors.StorageAccountMismatch' },
      StorageProviderMismatch: { id: 'errors.businessErrors.StorageProviderMismatch' },
      UnknownStorageProvider : { id: 'errors.businessErrors.UnknownStorageProvider' },
      SignalRError           : { id: 'errors.businessErrors.SignalRError' },
    },
  },

  toast: {
    header: {
      success    : { id: 'toast.header.success' },
      information: { id: 'toast.header.information' },
      warning    : { id: 'toast.header.warning' },
      error      : { id: 'toast.header.error' },
    },
  },
};
