import Cookies from 'universal-cookie';
import SessionStorage from 'libs/SessionStorage';
import LocalStorage from 'libs/LocalStorage';


export default function (preserveStorage) {
  const cookies = new Cookies();
  cookies.remove('token', { path: '/' });
  cookies.remove('hash', { path: '/' });
  cookies.remove('rememberMe', { path: '/' });
  cookies.remove('isAddProfile', { path: '/' });
  if (!preserveStorage) {
    SessionStorage.clear();
  }
  LocalStorage.removeItem('signUpDateOfBirth');
}
