export const MODULE_ID = 'patient';

export const APPROVED_SHARING_REQUEST_DROPDOWN = `${MODULE_ID}/approvedSharingRequestDropdown`;
export const FAMILY_MEMBER_DROPDOWN = `${MODULE_ID}/familyMemberDropdown`;

export const APPROVE_DATA_SHARING_MODAL = `${MODULE_ID}/approveDataSharingModal`;
export const REVOKE_ACCESS_MODAL = `${MODULE_ID}/revokeAccessModal`;
export const ENROLL_IN_CLINIC_MODAL = `${MODULE_ID}/enrollInClinicModal`;
export const INVITE_FAMILY_MEMBERS_MODAL = `${MODULE_ID}/inviteFamilyMembersModal`;
export const REVOKE_FAMILY_ACCESS_MODAL = `${MODULE_ID}/revokeFamilyAccessModal`;

export const INVITE_FAMILY_MEMBERS_FORM = `${MODULE_ID}.inviteFamilyMembersForm`;
export const ENROLL_IN_CLINIC_FORM = `${MODULE_ID}.enrollInClinicForm`;
export const REVOKE_FAMILY_ACCESS_FORM = `${MODULE_ID}.revokeFamilyAccessForm`;
export const REVOKE_ACCESS_FORM = `${MODULE_ID}.revokeAccessForm`;
export const PATIENT_GLUCOSE_SETTINGS_FORM = `${MODULE_ID}.patientGlucoseSettingsForm`;
export const PATIENT_KPI_SETTINGS_FORM = `${MODULE_ID}.patientKpiSettingsForm`;
