import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import ReadingFlagIcon from 'components/ReadingFlagIcon';
import { getMealCategory } from 'helpers/externalDataSources';
import { standardsSelector } from 'modules/PatientResults/selectors';
import styles from './ReadingValue.pcss';


interface Props {
  reading: Reading,
  conversion: Conversion
}


const ReadingValue: FC<Props> = ({ reading, conversion }) => {
  const { value, isManual, flags } = reading;
  const { preMeal, postMeal } = useSelector(standardsSelector);
  const targetObj = flags === 'PostMeal' ? postMeal : preMeal;
  const acceptableFlags = [getMealCategory(1), getMealCategory(2), getMealCategory(3)];

  const screenValue = (val: number) => conversion.toDisplay(val);


  const readingValue = conversion.toDisplay(value);
  const isReadingLow = readingValue < screenValue(targetObj.lowThreshold);
  const isReadingHigh = readingValue > screenValue(targetObj.highThreshold);


  const valueClasses = {
    [styles['reading--low']]   : isReadingLow,
    [styles['reading--high']]  : isReadingHigh,
    [styles['reading--manual']]: isManual,
  };

  const icon = acceptableFlags.includes(flags) && <ReadingFlagIcon flag={flags} className="btn__icon" />;

  return <span className={cn(styles.reading, valueClasses)}>{ icon }{ readingValue }</span>;
};

export default withStyles(styles)(ReadingValue);
