import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/withStyles';
// import filter from 'lodash/filter';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import forOwn from 'lodash/forOwn';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import set from 'lodash/set';
import has from 'lodash/has';
import union from 'lodash/union';
import unset from 'lodash/unset';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import { formatISODate } from 'helpers/datetime';
import MetricConversions from 'libs/MetricConversions';
import Modal from 'components/Modal';
import Button from 'components/Form/Button';
import CheckboxRadio from 'components/Form/CheckboxRadio';
import accessTokenShape from 'shapes/accessTokenShape';
import customIdentifierShape from 'shapes/customIdentifierShape';
import informationFieldShape from 'shapes/informationFieldShape';
import intlShape from 'shapes/intlShape';
import Arrow from 'svg/arrow-down.svg';
import App from 'modules/App';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import Statistics from 'modules/Statistics';
import * as constants from '../../constants';
import messages from '../../messages';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import styles from './SyncDataMismatchModal.pcss';


class SyncDataMismatchModal extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const {
      openModalId,
      isUpdatePatientInProgress, hasUpdatePatientErrors,
      isStoreMeasurementsInProgress, hasStoreMeasurementsErrors,
    } = props;
    if (openModalId !== constants.SYNC_DATA_MISMATCH_MODAL) {
      return null;
    }
    if (
      (isUpdatePatientInProgress !== state.isInProgress && isUpdatePatientInProgress)
      || (isStoreMeasurementsInProgress !== state.isInProgress && isStoreMeasurementsInProgress)
    ) {
      return { isInProgress: true };
    }
    if ((hasUpdatePatientErrors || hasStoreMeasurementsErrors) && state.isInProgress) {
      return { isInProgress: false };
    }
    return null;
  }


  static propTypes = {
    // Explicit props
    activeClinicMembership: PropTypes.shape({ clinicHcpMembershipId: PropTypes.number.isRequired }),
    activePatient         : PropTypes.shape({
      id                : PropTypes.string,
      dateOfBirth       : PropTypes.string,
      phiSetReferenceKey: PropTypes.string,
      storageProvider   : PropTypes.string,
      accessToken       : accessTokenShape,
      syncSnapshot      : PropTypes.object,
    }),
    sharingRequest       : PropTypes.object,
    phiSet               : PropTypes.object,
    phiSetDocumentId     : PropTypes.string,
    phiSetPatientId      : PropTypes.string,
    isSyncInProgress     : PropTypes.bool,
    standards            : PropTypes.object,
    // Implicit props
    patientDataComparison: PropTypes.shape({
      personalDataDifferences: PropTypes.object,
      healthDataDifferences  : PropTypes.object,
    }),
    isPatientDataFullComparison     : PropTypes.bool,
    syncHealthData                  : PropTypes.object,
    hasSyncErrors                   : PropTypes.bool,
    localizationResources           : PropTypes.object,
    metricsUnits                    : PropTypes.object,
    patientCustomIdentifiers        : PropTypes.arrayOf(customIdentifierShape).isRequired,
    informationTemplate             : PropTypes.arrayOf(informationFieldShape),
    openModalId                     : PropTypes.string,
    isUpdatePatientInProgress       : PropTypes.bool,
    hasUpdatePatientErrors          : PropTypes.bool,
    isStoreMeasurementsInProgress   : PropTypes.bool,
    hasStoreMeasurementsErrors      : PropTypes.bool,
    intl                            : intlShape,
    // Implicit action
    onSetPatientDataComparison      : PropTypes.func,
    onSetIsPatientDataFullComparison: PropTypes.func,
    onUpdateProfile                 : PropTypes.func,
    onStoreMeasurements             : PropTypes.func,
    onSendStatsForClinic            : PropTypes.func,
    onOpenModal                     : PropTypes.func,
    onCloseModal                    : PropTypes.func,
  }


  constructor(props) {
    super(props);
    this.state = {
      isInProgress: false,
    };
    this.metricConversions = new MetricConversions(props.metricsUnits);
    this.activeAction = null;
  }


  componentDidUpdate(prevProps) {
    const {
      activeClinicMembership, activePatient, sharingRequest, openModalId, phiSetPatientId, phiSet,
      patientDataComparison,
      isSyncInProgress, hasSyncErrors,
      isUpdatePatientInProgress, hasUpdatePatientErrors,
      isStoreMeasurementsInProgress, hasStoreMeasurementsErrors,
      standards,
    } = this.props;

    if (activePatient && prevProps.activePatient && activePatient.id !== prevProps.activePatient.id) {
      this.onClearDifferences();
      return;
    }

    if (
      sharingRequest && sharingRequest.sharingStatus === 'Approved'
      && (
        (
          isSyncInProgress !== prevProps.isSyncInProgress
          && !isSyncInProgress && !hasSyncErrors
          && phiSetPatientId === activePatient.id
        )
        || (prevProps.openModalId !== openModalId && openModalId === constants.SYNC_DATA_MISMATCH_MODAL)
      )
    ) {
      this.onCompareData(activePatient, sharingRequest);
    }

    if (
      !openModalId
      && (
        prevProps.patientDataComparison !== patientDataComparison
        || (prevProps.openModalId !== openModalId && prevProps.openModalId !== constants.SYNC_DATA_MISMATCH_MODAL)
      )
      && (
        !isEmpty(patientDataComparison.personalDataDifferences)
        || !isEmpty(patientDataComparison.healthDataDifferences)
      )
    ) {
      this.props.onOpenModal();
    }

    if (
      openModalId === constants.SYNC_DATA_MISMATCH_MODAL
      && (
        prevProps.isUpdatePatientInProgress !== isUpdatePatientInProgress
        || prevProps.isStoreMeasurementsInProgress !== isStoreMeasurementsInProgress
      )
      && !isUpdatePatientInProgress
      && !isStoreMeasurementsInProgress
      && !hasUpdatePatientErrors
      && !hasStoreMeasurementsErrors
    ) {
      this.props.onSendStatsForClinic(activePatient, phiSet, {}, standards, activeClinicMembership);
      this.onClose();
    }
  }


  onClearDifferences() {
    const { personalDataDifferences, healthDataDifferences } = this.props.patientDataComparison;
    if (!(isEmpty(personalDataDifferences) && isEmpty(healthDataDifferences))) {
      this.props.onSetPatientDataComparison({
        personalDataDifferences: {},
        healthDataDifferences  : {},
      });
    }
  }


  onComparePersonalData(activePatient, sharingRequest) {
    const { isPatientDataFullComparison } = this.props;
    const activePatientPersonalData = pick(activePatient, constants.SYNC_PERSONAL_DATA);
    const syncSnapshot = get(activePatient, 'syncSnapshot', null);
    const sharingRequestPatientPersonalData = pick(sharingRequest.patient, constants.SYNC_PERSONAL_DATA);
    const personalDataDifferences = {};
    forOwn(sharingRequestPatientPersonalData, (srValue, key) => {
      let value = get(activePatientPersonalData, key) || null;
      let ssValue = get(syncSnapshot, key);

      if (key === 'dateOfBirth') {
        value = formatISODate(value, true);
        srValue = formatISODate(srValue, true);
        ssValue = formatISODate(ssValue, true);
      }

      if (key === 'customPatientIdentifiersValues') {
        const clinicCustomIdentifierIds = map(value, (v) => v.customIdentifierId);
        const patientCustomIdentifierIds = map(srValue, (srv) => srv.customIdentifierId);
        const unionCustomIdentifierIds = union(patientCustomIdentifierIds, clinicCustomIdentifierIds);
        const customIdentifiersDifferences = [];
        forEach(unionCustomIdentifierIds, (customIdentifierId) => {
          const clinicValue = get(find(value, { customIdentifierId }), 'value', '');
          const patientValue = get(find(srValue, { customIdentifierId }), 'value', '');
          const snapshotValue = get(find(ssValue, { customIdentifierId }), 'value', '');
          if (clinicValue === patientValue || (snapshotValue === patientValue && !isPatientDataFullComparison)) return;
          const customIdentifierDifference = {
            customIdentifierId,
            clinic : clinicValue,
            patient: patientValue,
            toSync : '1',
          };
          customIdentifiersDifferences.push(customIdentifierDifference);
          if (!isEmpty(customIdentifiersDifferences)) {
            personalDataDifferences[key] = customIdentifiersDifferences;
          }
        });
      } else if (
        !isEqual(value, srValue)
        && (isPatientDataFullComparison || isUndefined(ssValue) || !isEqual(srValue, ssValue))
      ) {
        personalDataDifferences[key] = {
          clinic : value,
          patient: srValue,
          toSync : '1',
        };
      }
    });
    return personalDataDifferences;
  }


  onCompareHealthData() {
    const { phiSet, syncHealthData } = this.props;
    const syncSnapshot = get(phiSet, 'syncSnapshot', null);
    const healthData = pick(phiSet, CloudDrive.constants.SYNC_HEALTH_DATA);
    const healthDataDifferences = {};
    this.healthDataDiff(healthDataDifferences, healthData, syncHealthData, syncSnapshot);
    return healthDataDifferences;
  }


  onCompareData(activePatient, sharingRequest) {
    const personalDataDifferences = this.onComparePersonalData(activePatient, sharingRequest);
    const healthDataDifferences = this.onCompareHealthData();
    this.props.onSetPatientDataComparison({ personalDataDifferences, healthDataDifferences });
  }


  onSyncPersonalData(action) {
    const { personalDataDifferences } = this.props.patientDataComparison;
    if (isEmpty(personalDataDifferences)) {
      return;
    }
    const { activePatient, activeClinicMembership, sharingRequest } = this.props;
    const newPersonalData = {};
    if (action === 'accept') {
      forOwn(personalDataDifferences, (value, key) => {
        let patientValue;
        // if (key === 'customPatientIdentifiersValues') {
        //   patientValue = map(filter(value, { toSync: '1' }), (v) => ({
        //     customIdentifierId: v.customIdentifierId,
        //     value             : v.patient,
        //   }));
        // } else
        if (value.toSync === '1') {
          patientValue = value.patient;
        }
        if (patientValue && !isEmpty(patientValue)) {
          set(newPersonalData, key, patientValue);
        }
      });
    }
    const syncSnapshot = pick(sharingRequest.patient, constants.SYNC_PERSONAL_DATA);
    if (!activePatient.dateOfBirth && !newPersonalData.dateOfBirth) {
      unset(syncSnapshot, 'dateOfBirth');
    }
    this.props.onUpdateProfile(activePatient, { ...newPersonalData, syncSnapshot }, activeClinicMembership);
  }


  onSyncHealthData(action) {
    const { healthDataDifferences } = this.props.patientDataComparison;
    if (isEmpty(healthDataDifferences)) {
      return;
    }
    const { activePatient, phiSet, phiSetDocumentId, syncHealthData } = this.props;
    const measurements = {};
    const newHealthData = {};
    if (action === 'accept') {
      const summaryDataDifferences = get(healthDataDifferences, 'summaryData') || {};
      forOwn(summaryDataDifferences, (value, key) => {
        if (value.toSync === '1') set(measurements, key, value.patient);
      });
      forOwn(omit(healthDataDifferences, ['summaryData']), (value, key) => {
        if (value.toSync === '1') set(newHealthData, key, value.patient);
      });
    }
    const syncSnapshot = syncHealthData;
    const updatedPhiSet = { ...phiSet, ...newHealthData, syncSnapshot };
    this.props.onStoreMeasurements(measurements, updatedPhiSet, phiSetDocumentId, activePatient, actions.setPhiSet);
  }


  onSelectChange(propertyPath) {
    const patientDataComparison = { ...this.props.patientDataComparison };
    const diff = get(patientDataComparison, propertyPath);
    if (!diff) return;
    diff.toSync = diff.toSync === '0' ? '1' : '0';
    set(patientDataComparison, propertyPath, diff);
    this.props.onSetPatientDataComparison(patientDataComparison);
  }


  onAction(action) {
    const { personalDataDifferences, healthDataDifferences } = this.props.patientDataComparison;
    this.activeAction = action;

    if (isEmpty(healthDataDifferences) && isEmpty(personalDataDifferences)) {
      this.onClose();
      return;
    }
    this.onSyncPersonalData(action);
    this.onSyncHealthData(action);
  }


  async onClose() {
    await this.props.onCloseModal();
    this.props.onSetIsPatientDataFullComparison(false);
    this.onClearDifferences();
    this.activeAction = null;
  }


  healthDataDiff(healthDataDifferences, healthData, syncHealthData, syncSnapshot, parent = '') {
    const { isPatientDataFullComparison } = this.props;
    forOwn(syncHealthData, (shValue, key) => {
      const value = get(healthData, key);
      const ssValue = get(syncSnapshot, key);
      if (isObject(shValue)) {
        this.healthDataDiff(healthDataDifferences, value, shValue, ssValue, key);
        return;
      }
      if (
        !isEqual(value, shValue)
          && (isPatientDataFullComparison || isUndefined(ssValue) || !isEqual(shValue, ssValue))
      ) {
        const diff = {
          clinic : value,
          patient: shValue,
          toSync : '1',
        };
        set(healthDataDifferences, `${parent}${parent && '.'}${key}`, diff);
      }
    });
  }


  renderRow(diff, property, propertyPath, clinicValue, patientValue, label, isDisabled) {
    return (
      <div key={property} className={styles.dataRow}>
        <div className="row align-items-center">
          <div className="col-4 text--bold">
            <CheckboxRadio
              id={property}
              inputValue="1"
              value={diff.toSync}
              labelMessage={label || property}
              isDisabled={isDisabled}
              onChange={() => this.onSelectChange(propertyPath)}
            />
          </div>
          <div className="col-3">{clinicValue}</div>
          <div className="col-2"><Arrow className={styles.dataRow__arrow} /></div>
          <div className="col-3">{patientValue}</div>
        </div>
      </div>
    );
  }


  renderCustomIdentifiers(customIdentifiersDifferences, dataDifferencesName) {
    const { patientCustomIdentifiers } = this.props;
    return map(customIdentifiersDifferences, (customIdentifierDifference, idx) => {
      const { customIdentifierId, clinic, patient } = customIdentifierDifference;
      const customIdentifier = find(patientCustomIdentifiers, { customIdentifierId });
      if (!customIdentifier) return null;
      const { nameKey } = customIdentifier;
      const property = get(this.props.localizationResources, [nameKey, 'value'], nameKey);
      const propertyPath = `${dataDifferencesName}.customPatientIdentifiersValues[${idx}]`;
      return this.renderRow(customIdentifierDifference, property, propertyPath, clinic, patient);
    });
  }


  renderPersonalIdentifier(property, diff, dataDifferencesName) {
    const personalIdentifierTemplate = find(this.props.informationTemplate, { name: 'personalIdentifier' });
    if (!personalIdentifierTemplate) {
      return null;
    }
    const personalIdentifierTypeOptions = map(
      get(find(personalIdentifierTemplate.fields, { name: 'personalIdentifierType' }), 'options'),
      (o) => o.value,
    );

    const { clinic, patient } = diff;

    if (!patient || !includes(personalIdentifierTypeOptions, patient.personalIdentifierType)) {
      return null;
    }
    const message = App.messages.labels[property];
    const label = this.props.intl.formatMessage(message);
    const propertyPath = `${dataDifferencesName}.${property}`;

    const clinicPIType = get(clinic, 'personalIdentifierType', '');
    const clinicPIValue = get(clinic, 'personalIdentifierValue', '');
    const clinicPITypeLabel = clinicPIType
      && get(this.props.localizationResources, [clinicPIType, 'value'], clinicPIType);
    const clinicValue = `${clinicPITypeLabel}${clinicPITypeLabel && ': '}${clinicPIValue}`;

    const patientPIType = get(patient, 'personalIdentifierType');
    const patientPIValue = get(patient, 'personalIdentifierValue');
    const patientPITypeLabel = patientPIType
      && get(this.props.localizationResources, [patientPIType, 'value'], patientPIType);
    const patientValue = `${patientPITypeLabel}${patientPITypeLabel && ': '}${patientPIValue}`;

    return this.renderRow(diff, property, propertyPath, clinicValue, patientValue, label);
  }


  renderDate(property, diff, dataDifferencesName) {
    const message = App.messages.labels[property];
    const label = this.props.intl.formatMessage(message);
    const { clinic, patient } = diff;
    const clinicValue = clinic && moment.utc(clinic).format('L');
    const patientValue = moment.utc(patient).format('L');
    const propertyPath = `${dataDifferencesName}.${property}`;
    const isDisabled = !this.props.activePatient.dateOfBirth;
    return this.renderRow(diff, label, propertyPath, clinicValue, patientValue, null, isDisabled);
  }


  renderMeasurement(property, diff, dataDifferencesName) {
    const key = property === 'lastHeight' ? 'height' : 'weight';
    const message = App.messages.labels[key];
    const units = this.metricConversions[key].unitSymbol;
    const label = this.props.intl.formatMessage(message, { units });
    const { clinic, patient } = diff;
    const clinicValue = clinic ? this.metricConversions[key].toDisplay(clinic) : '';
    const patientValue = patient ? this.metricConversions[key].toDisplay(patient) : '';
    const propertyPath = `${dataDifferencesName}.summaryData.${property}`;
    return this.renderRow(diff, property, propertyPath, clinicValue, patientValue, label);
  }


  renderTreatmentDiabetesType(property, diff, dataDifferencesName) {
    const label = this.props.intl.formatMessage(App.messages.labels[property]);
    const { clinic, patient } = diff;
    const clinicValue = !isEmpty(clinic) ? this.props.intl.formatMessage(App.messages[`${property}s`][clinic]) : '';
    const patientValue = !isEmpty(patient) ? this.props.intl.formatMessage(App.messages[`${property}s`][patient]) : '';
    const propertyPath = `${dataDifferencesName}.${property}`;
    return this.renderRow(diff, property, propertyPath, clinicValue, patientValue, label);
  }


  renderSummaryData(summaryData, dataDifferencesName) {
    const rows = [];
    forOwn(summaryData, (diff, property) => {
      if (property === 'lastHeight' || property === 'lastWeight') {
        rows.push(this.renderMeasurement(property, diff, dataDifferencesName));
      }
    });
    return rows;
  }


  renderDataDiff(dataDifferencesName) {
    const dataDifferences = this.props.patientDataComparison[dataDifferencesName];
    return map(dataDifferences, (diff, property) => {
      const propertyPath = `${dataDifferencesName}.${property}`;
      if (property === 'customPatientIdentifiersValues') {
        return this.renderCustomIdentifiers(diff, dataDifferencesName);
      }
      if (property === 'personalIdentifier') {
        return this.renderPersonalIdentifier(property, diff, dataDifferencesName);
      }
      if (property === 'dateOfBirth') {
        return this.renderDate(property, diff, dataDifferencesName);
      }
      if (property === 'summaryData') {
        return this.renderSummaryData(diff, dataDifferencesName);
      }
      if (!has(diff, 'clinic')) {
        return this.renderDataDiff(propertyPath);
      }
      if (property === 'treatmentType' || property === 'diabetesType') {
        return this.renderTreatmentDiabetesType(property, diff, dataDifferencesName);
      }
      const message = messages.labels[property] || App.messages.labels[property];
      const label = message ? this.props.intl.formatMessage(message) : property;
      return this.renderRow(diff, label, propertyPath, diff.clinic, diff.patient);
    });
  }


  renderActions() {
    const { personalDataDifferences, healthDataDifferences } = this.props.patientDataComparison;
    if (isEmpty(personalDataDifferences) && isEmpty(healthDataDifferences)) {
      return (
        <div className="modal__actions">
          <Button
            labelMessage={messages.buttons.close}
            styleModifier="quaternary"
            className="btn--block"
            onClick={() => this.onClose()}
          />
        </div>
      );
    }
    const { isInProgress } = this.state;
    return (
      <div className="modal__actions row">
        <div className="col-6 pr-4">
          <Button
            labelMessage={messages.buttons.rejectChanges}
            styleModifier="secondary"
            className="btn--block"
            isDisabled={isInProgress && this.activeAction === 'accept'}
            isInProgress={isInProgress && this.activeAction === 'reject'}
            onClick={() => this.onAction('reject')}
          />
        </div>
        <div className="col-6 pl-4">
          <Button
            labelMessage={messages.buttons.acceptChanges}
            styleModifier="primary"
            className="btn--block btn--filled"
            isDisabled={isInProgress && this.activeAction === 'reject'}
            isInProgress={isInProgress && this.activeAction === 'accept'}
            onClick={() => this.onAction('accept')}
          />
        </div>
      </div>
    );
  }


  renderSection(section, dataDifferencesName) {
    if (isEmpty(this.props.patientDataComparison[dataDifferencesName])) {
      return null;
    }
    return (
      <div className={styles.section}>
        <div className={`${styles.dataRow} ${styles['dataRow--head']}`}>
          <div className="row align-items-end">
            <div className="col-4">
              <h3 className={styles.section__header}>{ this.props.intl.formatMessage(messages.headers[section])}</h3>
            </div>
            <div className="col-3 text--bold">{this.props.intl.formatMessage(messages.headers.clinicalProfile)}</div>
            <div className="col-2" />
            <div className="col-3 text--bold">{this.props.intl.formatMessage(messages.headers.personalProfile)}</div>
          </div>
        </div>
        { this.renderDataDiff(dataDifferencesName) }
      </div>
    );
  }


  renderAllInformationSync() {
    const { personalDataDifferences, healthDataDifferences } = this.props.patientDataComparison;
    if (!(isEmpty(personalDataDifferences) && isEmpty(healthDataDifferences))) {
      return null;
    }
    return (
      <h3 className={styles.section__header}>
        {this.props.intl.formatMessage(messages.infos.patientDataMatches)}
      </h3>
    );
  }


  render() {
    return (
      <Modal
        modalId={constants.SYNC_DATA_MISMATCH_MODAL}
        openModalId={this.props.openModalId}
        styleModifier="lg"
        headerMessage={messages.headers.patientDataComparison}
        onClose={() => this.onClose()}
      >
        { this.renderAllInformationSync() }
        { this.renderSection('personalInformation', 'personalDataDifferences') }
        { this.renderSection('healthInformation', 'healthDataDifferences') }
        { this.renderActions() }
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  patientDataComparison        : selectors.patientDataComparison(state),
  informationTemplate          : selectors.informationTemplate(state),
  patientCustomIdentifiers     : selectors.patientCustomIdentifiers(state),
  isPatientDataFullComparison  : selectors.isPatientDataFullComparison(state),
  isUpdatePatientInProgress    : selectors.isUpdatePatientInProgress(state),
  hasUpdatePatientErrors       : selectors.hasUpdatePatientErrors(state),
  localizationResources        : App.selectors.localizationResources(state),
  openModalId                  : App.selectors.modal(state),
  metricsUnits                 : Account.selectors.metricsUnits(state),
  syncHealthData               : CloudDrive.selectors.syncHealthData(state),
  hasSyncErrors                : CloudDrive.selectors.hasSyncErrors(state),
  isStoreMeasurementsInProgress: CloudDrive.selectors.isStoreMeasurementsInProgress(state),
  hasStoreMeasurementsErrors   : CloudDrive.selectors.hasStoreMeasurementsErrors(state),
});


const mapDispatchToProps = (dispatch) => ({
  onSetPatientDataComparison: ({ personalDataDifferences, healthDataDifferences }) => dispatch(
    actions.setPatientDataComparison({ personalDataDifferences, healthDataDifferences }),
  ),
  onSetIsPatientDataFullComparison: (isPatientDataFullComparison) => dispatch(
    actions.setIsPatientDataFullComparison(isPatientDataFullComparison),
  ),
  onUpdateProfile: (patient, newPatientValues, clinicMembership) => dispatch(
    actions.updatePatient(patient, newPatientValues, clinicMembership),
  ),
  onStoreMeasurements: (measurements, phiSet, phiSetDocumentId, patient, successAction) => dispatch(
    CloudDrive.actions.storeMeasurements(measurements, phiSet, phiSetDocumentId, patient, undefined, successAction),
  ),
  onSendStatsForClinic: (patientProfile, phiSet, importData, standards, clinicMembership) => dispatch(
    Statistics.actions.sendStatisticsForClinic(patientProfile, phiSet, importData, standards, clinicMembership),
  ),
  onOpenModal : () => dispatch(App.actions.openModal(constants.SYNC_DATA_MISMATCH_MODAL)),
  onCloseModal: () => dispatch(App.actions.closeModal()),
});


const ConnectedSyncDataMismatchModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(SyncDataMismatchModal));


export default withStyles(styles)(ConnectedSyncDataMismatchModal);
