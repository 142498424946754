import { useSelector } from 'react-redux';
import App from 'modules/App';
import { VISIT_NOTES_FM } from 'modules/Visit/constants';
import * as constants from './constants';
import * as selectors from './selectors';


export const useIsPinnedMenu = (activeVisit?: Visit) => {
  const menuOpen = useSelector(selectors.menuOpen);
  const windowWidth = useSelector(selectors.windowWidth);
  const visitNotesFloatingModal = useSelector(App.selectors.floatingModalSelector(VISIT_NOTES_FM));
  const breakpoint = visitNotesFloatingModal
    ? constants.PINNED_MENU_NOTES_BREAKPOINT
    : constants.PINNED_MENU_BREAKPOINT;
  return (windowWidth >= breakpoint && menuOpen && !activeVisit);
};
