// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@-webkit-keyframes _28WSx{5%{opacity:1}50%{opacity:.35}95%{opacity:1}}@keyframes _28WSx{5%{opacity:1}50%{opacity:.35}95%{opacity:1}}._1plyS{color:#0a385a;font-size:14px;font-size:1.4rem}._1plyS .btn__labelWrapper{padding:0;font-weight:400}._1plyS svg{fill:#30a8ff;width:16px;height:16px;margin-left:1rem;margin-right:1rem}._1plyS:hover{color:#30a8ff}html[dir=ltr] ._1plyS{margin-left:1.6rem}html[dir=rtl] ._1plyS{margin-right:1.6rem}.XN0LN{width:14px;height:14px;-webkit-transform:rotate(90deg);-ms-transform:rotate(90deg);transform:rotate(90deg);fill:#6f7ea2!important}._3WfP9{position:relative}._3nxBq{position:absolute;top:100%;right:0;background:#fff;padding:0 6px;border-radius:10px;-webkit-box-shadow:0 4px 20px rgba(50,66,121,.16);box-shadow:0 4px 20px rgba(50,66,121,.16);z-index:1}._236_c{display:-ms-flexbox;display:flex;width:300px;border-radius:5px;padding:4px;margin:6px 0;border:none;background-color:rgba(0,0,0,0);-webkit-transition:background-color .15s;-o-transition:background-color .15s;transition:background-color .15s;cursor:pointer;-ms-flex-align:center;align-items:center}._236_c:hover{background-color:#f4f7fa}._1IHX1{background-color:#f5f9ff;padding:.8rem;border-radius:5px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}._1IHX1 svg{fill:#30a8ff;width:32px;height:32px;padding:4px}._1IMHQ{margin:0 8px;color:#0a385a;font-size:13px;-webkit-transition:color .15s;-o-transition:color .15s;transition:color .15s;font-weight:500;text-align:left}._236_c:hover ._1IHX1{background-color:#30a8ff}._236_c:hover ._1IHX1 svg{fill:#fff}", ""]);
// Exports
exports.locals = {
	"topBar__button": "_1plyS",
	"chevronIcon": "XN0LN",
	"topBar__generateReport": "_3WfP9",
	"topBar__generateReportDropdown": "_3nxBq",
	"reportSelector__button": "_236_c",
	"reportSelector__icon": "_1IHX1",
	"reportSelector__label": "_1IMHQ",
	"fading": "_28WSx"
};
module.exports = exports;
