// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Dgqga{height:420px;width:100%}.C9XC0{top:0;bottom:0;left:0;right:0}.targetReportAgpLabel{-webkit-transform:translateY(36px) translateX(-32px) rotate(-90deg);-ms-transform:translateY(36px) translateX(-32px) rotate(-90deg);transform:translateY(36px) translateX(-32px) rotate(-90deg);position:relative;font-size:8pt;font-weight:600}.Dgqga svg text{dominant-baseline:auto!important}.Dgqga svg text[text-anchor=middle]{-webkit-transform:translateY(18px) rotate(0)!important;-ms-transform:translateY(18px) rotate(0)!important;transform:translateY(18px) rotate(0)!important}.Dgqga svg text[text-anchor=end]{-webkit-transform:translate(-10px,4px) rotate(0)!important;-ms-transform:translate(-10px,4px) rotate(0)!important;transform:translate(-10px,4px) rotate(0)!important}.Dgqga svg text[text-anchor=start]{-webkit-transform:translate(10px,4px) rotate(0)!important;-ms-transform:translate(10px,4px) rotate(0)!important;transform:translate(10px,4px) rotate(0)!important}._3DJbn{font-size:14px;font-size:1.4rem;line-height:1;background:#fff;padding:9px 12px;border-radius:8px;-webkit-box-shadow:0 4px 16px rgba(50,66,121,.1);box-shadow:0 4px 16px rgba(50,66,121,.1);white-space:nowrap}._3DJbn p{margin-top:.4em;margin-bottom:.4em}", ""]);
// Exports
exports.locals = {
	"root": "Dgqga",
	"root__inner": "C9XC0",
	"tooltip": "_3DJbn"
};
module.exports = exports;
