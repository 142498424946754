import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import { formatTimestamp } from 'helpers/datetime';
import PolygonIcon from 'svg/polygon.svg';
import MealIcon from 'svg/meal.svg';
import messages from '../messages';
import * as shapes from '../shapes';
import styles from './MealTooltip.pcss';


class MealTooltip extends React.PureComponent {

  static propTypes = {
    // Explicit props
    meal: shapes.meal.isRequired,
  };


  get nutrientsLabel() {
    return {
      carbs  : <FormattedMessage {...messages.meal.labels.carbs} />,
      protein: <FormattedMessage {...messages.meal.labels.protein} />,
      energy : <FormattedMessage {...messages.meal.labels.energy} />,
      fat    : <FormattedMessage {...messages.meal.labels.fat} />,
    };
  }


  getUnit(type) {
    switch (type) {
      case 'energy':
        return <FormattedMessage {...messages.meal.labels.energyUnit} />;
      default:
        return <FormattedMessage {...messages.meal.labels.nutrientUnit} />;
    }
  }


  renderTitle() {
    const { meal } = this.props;
    return (
      <div className={styles.mealTooltip__title}>
        { meal.isSnaqImport
          ? <img src="/assets/svg/snaq.svg" alt="snaq.io" className={styles.mealTooltip__snaqIcon} />
          : <MealIcon className={styles.mealTooltip__mealIcon} />
        }
        <div className={styles.mealTooltip__titleText}>
          { meal.name }
        </div>
      </div>
    );
  }


  renderDatetime() {
    const { meal } = this.props;
    const date = formatTimestamp(meal.timestamp, 'DD/MM/YYYY');
    const time = formatTimestamp(meal.timestamp, 'HH:mm');
    return (
      <div className={styles.mealTooltip__datetime}>
        <div>{ date }</div>
        <div>{ time }</div>
      </div>
    );
  }


  renderNutrient(name) {
    const value = this.props.meal[name];
    if (!value) {
      return null;
    }

    return (
      <div className={styles.mealTooltip__nutrient}>
        <div>
          { this.nutrientsLabel[name] } ({ this.getUnit(name) })
        </div>
        <div className={styles.mealTooltip__nutrientValue}>
          { this.props.meal[name] }
        </div>
      </div>
    );
  }


  renderNutrients() {
    return (
      <div className={styles.mealTooltip__nutrients}>
        { this.renderNutrient('carbs') }
        { this.renderNutrient('protein') }
        { this.renderNutrient('energy') }
        { this.renderNutrient('fat') }
      </div>
    );
  }


  renderDetails() {
    return (
      <div className={styles.mealTooltip__details}>
        {this.renderDatetime()}
        {this.renderNutrients()}
      </div>
    );
  }


  render() {
    return (
      <div className={styles.mealTooltip}>
        <div className={styles.mealTooltip__content}>
          { this.renderTitle() }
          <div className={styles.mealTooltip__separator} />
          { this.renderDetails() }
          <PolygonIcon width="17" height="12" className={styles.mealTooltip__polygon} />
        </div>
      </div>
    );
  }

}


export default withStyles(styles)(MealTooltip);
