// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3VjW5{top:0;left:0;position:fixed;background:#fff;-webkit-box-shadow:0 4px 20px rgba(50,66,121,.16);box-shadow:0 4px 20px rgba(50,66,121,.16);display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;padding:16px;z-index:1030}._3VjW5,._1ya0s{height:60px;width:100%}", ""]);
// Exports
exports.locals = {
	"topBar": "_3VjW5",
	"topBarHolder": "_1ya0s"
};
module.exports = exports;
