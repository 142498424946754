export const getNoteKey = (note) => {
  let noteKey = note.noteType;
  if (note.payload) {
    const { start, end } = note.payload;
    if (start) noteKey += `-${start}`;
    if (end) noteKey += `-${end}`;
  }
  return noteKey;
};


export const getNoteTypeFromNoteKey = (noteKey) => {
  if (!noteKey) return null;
  const [noteType] = noteKey.split('-');
  return noteType;
};
