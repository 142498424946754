import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment';
import cn from 'classnames';
import range from 'lodash/range';
import * as patientResultsConstants from 'modules/PatientResults/constants';
import styles from './BloodGlucoseProfileReportAgpMiniChart.pcss';


const ResponsiveLine = lazy(() => import('@nivo/line').then((m) => ({ default: m.ResponsiveLine })));


class BloodGlucoseProfileReportAgpMiniChart extends React.PureComponent {

  static propTypes = {
    // Explicit props
    conversion  : PropTypes.object.isRequired,
    records     : PropTypes.array,
    direction   : PropTypes.string,
    isInProgress: PropTypes.bool,
    maxValue    : PropTypes.number,
  };


  constructor(props) {
    super(props);
    this.colors = {
      stroke      : '#A4A6A8',
      target      : '#CBEBE5',
      targetZone  : '#dbddde',
      isInProgress: '#e0e8f2',
      high        : '#f7ef20',
      low         : '#ec1f28',
    };
  }


  get chartData() {
    const { records, conversion } = this.props;
    if (!records) {
      return [{
        id  : 'Glucose Profile',
        data: [],
      }];
    }

    const data = [];

    records.forEach((item) => {
      item.records.forEach((record) => {
        const recordMoment = moment.unix(record.timestamp).utc();
        data.push({
          x: recordMoment.hours() + (recordMoment.minutes() / 60),
          y: conversion.toDisplay(record.value),
        });
      });
    });
    return [
      {
        id: 'Glucose Profile',
        data,
      },
    ];
  }


  renderCircles({ series, xScale, yScale }) {
    const { conversion, isInProgress } = this.props;
    const lowThreshold = patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].target;
    const highThreshold = patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].high;
    return (
      <g>
        {
          series[0].data.map((d) => {
            let fill = isInProgress ? this.colors.isInProgress : this.colors.stroke;
            if (d.data.y > highThreshold) {
              fill = isInProgress ? this.colors.isInProgress : this.colors.high;
            } else if (d.data.y < lowThreshold) {
              fill = isInProgress ? this.colors.isInProgress : this.colors.low;
            }
            return (
              <circle
                key={`${d.data.x}-${d.data.y}`}
                fill={fill}
                cx={xScale(d.data.x)}
                cy={yScale(d.data.y)}
                stroke="black"
                strokeWidth={1}
                r="4px"
              />
            );
          })
        }
      </g>
    );
  }


  renderTargetZone({ innerWidth, yScale }) {
    const { conversion } = this.props;
    const lowThreshold = patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].target;
    const highThreshold = patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].high;
    const yTop = yScale(highThreshold);
    const yBottom = yScale(lowThreshold);

    return (
      <g>
        <polygon
          points={`1,${yTop} ${innerWidth},${yTop} ${innerWidth},${yBottom} 1,${yBottom}`}
          fill={this.colors.targetZone}
        />
      </g>
    );
  }


  render() {
    const { direction, conversion, isInProgress, maxValue } = this.props;
    const margin = {
      top   : 0,
      right : 0,
      bottom: 0,
      left  : 0,
    };
    /*
      IMPORTANT: Because Edge doesn't support `dominant-baseline` and RTL support is poor
      hardcoded styles overwrite ticks labels transform for current tickPadding and tickRotation values and
      must be correlated if change
     */
    return (
      <div className={cn(styles.root, { fadingLoader: isInProgress })}>
        <Suspense fallback={null}>
          <ResponsiveLine
            data={this.chartData}
            margin={margin}
            xScale={{ type: 'linear', min: 0, max: 24, reverse: direction === 'rtl' }}
            yScale={
              {
                type: 'linear',
                min : conversion.toDisplay(0),
                max : Math.max(conversion.toDisplay(350), maxValue),
              }
            }
            axisTop={null}
            axisRight={null}
            axisBottom={
              {
                tickSize    : 5,
                tickPadding : 5,
                tickRotation: 0,
                tickValues  : range(24),
              }
            }
            axisLeft={null}
            colors={[isInProgress ? this.colors.isInProgress : this.colors.stroke]}
            lineWidth={2}
            enableCrosshair={false}
            curve="monotoneX"
            layers={
              [
                this.renderTargetZone.bind(this),
                'markers',
                'axes',
                'areas',
                'slices',
                'legends',
                this.renderCircles.bind(this),
              ]
            }
          />
        </Suspense>
      </div>
    );
  }

}


export default withStyles(styles)(BloodGlucoseProfileReportAgpMiniChart);
