// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3V2d-{width:100%;background:#000;color:#fff;font-size:8pt;line-height:.25in;letter-spacing:.08em;text-transform:uppercase;padding:0 .1in}", ""]);
// Exports
exports.locals = {
	"sectionTitle": "_3V2d-"
};
module.exports = exports;
