// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._34tGx{margin-bottom:24px;color:#0f3858;font-size:12px}._34tGx table{font-family:arial,sans-serif;border-collapse:collapse;width:100%}._34tGx th{font-weight:400;color:#6f7ea3}._34tGx td,._34tGx th{border:0;text-align:center;padding:8px}._34tGx td:last-child,._34tGx td:nth-child(7),._34tGx th:last-child,._34tGx th:nth-child(7){font-weight:700}._34tGx tr:nth-child(2n){background-color:#f7fafe}._34tGx tr:nth-child(2n) td:first-child{border-top-left-radius:4px;border-bottom-left-radius:4px}._34tGx tr:nth-child(2n) td:last-child{border-top-right-radius:4px;border-bottom-right-radius:4px}", ""]);
// Exports
exports.locals = {
	"bloodGlucoseConcenctration__wrapper": "_34tGx"
};
module.exports = exports;
