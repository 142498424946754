import { defineMessages } from 'react-intl';


export default defineMessages({

  headers: {
    confirmDataSharing    : { id: 'patient.headers.confirmDataSharing' },
    inviteFamilyMembers   : { id: 'patient.headers.inviteFamilyMembers' },
    familyLinkInvitations : { id: 'patient.headers.familyLinkInvitations' },
    enrollInClinic        : { id: 'patient.headers.enrollInClinic' },
    person                : { id: 'patient.headers.person' },
    reauthorizeDataSharing: { id: 'patient.headers.reauthorizeDataSharing' },
    revokeAccess          : { id: 'patient.headers.revokeAccess' },
    removeFamilyMember    : { id: 'patient.headers.removeFamilyMember' },
    fasting               : { id: 'patient.headers.fasting' },
    preMeal               : { id: 'patient.headers.preMeal' },
    postMeal              : { id: 'patient.headers.postMeal' },
    withoutMark           : { id: 'patient.headers.withoutMark' },
    waitForRequestAccepted: { id: 'patient.headers.waitForRequestAccepted' },
    critical              : { id: 'patient.headers.critical' },
    targetRanges          : { id: 'patient.headers.targetRanges' },
  },

  buttons: {
    accept             : { id: 'patient.buttons.accept' },
    close              : { id: 'patient.buttons.close' },
    decline            : { id: 'patient.buttons.decline' },
    addAnotherPerson   : { id: 'patient.buttons.addAnotherPerson' },
    inviteFamilyMembers: { id: 'patient.buttons.inviteFamilyMembers' },
    skipForNow         : { id: 'patient.buttons.skipForNow' },
    yesRevokeAccess    : { id: 'patient.buttons.yesRevokeAccess' },
    noKeepAccess       : { id: 'patient.buttons.noKeepAccess' },
    yesShareData       : { id: 'patient.buttons.yesShareData' },
    yesRemove          : { id: 'patient.buttons.yesRemove' },
    noDeclineInvitation: { id: 'patient.buttons.noDeclineInvitation' },
    signIn             : { id: 'patient.buttons.signIn' },
    submit             : { id: 'patient.buttons.submit' },
  },

  labels: {
    email            : { id: 'patient.labels.email' },
    enrollCode       : { id: 'patient.labels.enrollCode' },
    firstName        : { id: 'patient.labels.firstName' },
    lastName         : { id: 'patient.labels.lastName' },
    reauthorizeAccess: { id: 'patient.labels.reauthorizeAccess' },
    relationshipType : { id: 'patient.labels.relationshipType' },
    remove           : { id: 'patient.labels.remove' },
    revokeAccess     : { id: 'patient.labels.revokeAccess' },
    shareData        : { id: 'patient.labels.shareData' },
    glucoseHigh      : { id: 'patient.labels.glucoseHigh' },
    glucoseLow       : { id: 'patient.labels.glucoseLow' },
    password         : { id: 'patient.labels.password' },
    high             : { id: 'patient.labels.high' },
    low              : { id: 'patient.labels.low' },
    unassigned       : { id: 'patient.labels.unassigned' },
  },

  placeholders: {
    email           : { id: 'patient.placeholders.email' },
    enrollCode      : { id: 'patient.placeholders.enrollCode' },
    firstName       : { id: 'patient.placeholders.firstName' },
    lastName        : { id: 'patient.placeholders.lastName' },
    relationshipType: { id: 'patient.placeholders.relationshipType' },
    password        : { id: 'patient.placeholders.password' },
  },

  infos: {
    areYouSureToRevokeAccess       : { id: 'patient.infos.areYouSureToRevokeAccess' },
    areYouSureToShareData          : { id: 'patient.infos.areYouSureToShareData' },
    areYouSureToRemoveFamilySharing: { id: 'patient.infos.areYouSureToRemoveFamilySharing' },
    noSharingRequests              : { id: 'patient.infos.noSharingRequests' },
    noFamilyMembers                : { id: 'patient.infos.noFamilyMembers' },
    provideEnrollCode              : { id: 'patient.infos.provideEnrollCode' },
    requestIsPending               : { id: 'patient.infos.requestIsPending' },
    requestIsInProgress            : { id: 'patient.infos.requestIsInProgress' },
    waitForRequestAccepted         : { id: 'patient.infos.waitForRequestAccepted' },
  },

  errors: {
    businessErrors: {
      CannotAssignEnrollCodeToClinic : { id: 'patient.errors.businessErrors.CannotAssignEnrollCodeToClinic' },
      RequestorMustHavePatientProfile: { id: 'patient.errors.businessErrors.RequestorMustHavePatientProfile' },
      SharingRequestAlreadyExists    : { id: 'patient.errors.businessErrors.SharingRequestAlreadyExists' },
    },
  },

});
