import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { mean, std, round, max, min } from 'mathjs';
import { isManualReading } from 'helpers/externalDataSources';
import { isAggregatedPreMeal } from 'libs/StatsCalculations';


export function calculateData(readings) {
  const preMealReadings = [];
  const postMealReadings = [];
  const strictPreMealReadings = [];
  const strictPostMealReadings = [];
  let manualReadingsCount = 0;
  let readingsWithoutFlag = 0;
  forEach(readings, (item) => {
    if (isManualReading(item)) {
      manualReadingsCount += 1;
    }
    if (item.flags === 'None') {
      readingsWithoutFlag += 1;
    }
    if (isAggregatedPreMeal(item.flags)) {
      preMealReadings.push(item);
    } else {
      postMealReadings.push(item);
    }
    if (item.flags === 'PreMeal') {
      strictPreMealReadings.push(item);
    } else if (item.flags === 'PostMeal') {
      strictPostMealReadings.push(item);
    }
  });
  const allReadingsCount = readings.length;
  return {
    preMealReadings,
    postMealReadings,
    strictPreMealReadings,
    strictPostMealReadings,
    manualReadingsCount,
    allReadingsCount,
    readingsWithoutFlag,
  };
}


export function createKPIData(data) {
  const {
    readings = [],
    preMealReadings = [], postMealReadings = [],
    strictPreMealReadings = [], strictPostMealReadings = [],
    standards, conversion,
  } = data;
  const results = {
    lowReadings         : 0,
    highReadings        : 0,
    criticalLowReadings : 0,
    criticalHighReadings: 0,
  };

  const readingsValues = readings.map((reading) => reading.value);
  const preMealReadingsValues = preMealReadings.map((reading) => reading.value);
  const postMealReadingsValues = postMealReadings.map((reading) => reading.value);
  const strictPreMealReadingsValues = strictPreMealReadings.map((reading) => reading.value);
  const strictPostMealReadingsValues = strictPostMealReadings.map((reading) => reading.value);

  if (readings && readings.length > 0) {
    results.readingsAverage = mean(readingsValues);
    results.standardDeviation = std(readingsValues);
  } else {
    results.readingsAverage = 0;
    results.standardDeviation = 0;
  }

  if (preMealReadings && preMealReadings.length > 0) {
    preMealReadings.forEach((reading) => {
      if (standards) {
        if (standards.preMeal.lowThreshold > reading.value) {
          results.lowReadings += 1;
        } else if (standards.preMeal.highThreshold < reading.value) {
          results.highReadings += 1;
        }

        if (standards.critical.lowThreshold > reading.value) {
          results.criticalLowReadings += 1;
        } else if (standards.critical.highThreshold < reading.value) {
          results.criticalHighReadings += 1;
        }
      }
    });
    results.preMealAverage = mean(preMealReadingsValues);
  } else {
    results.preMealAverage = 0;
  }

  if (postMealReadings && postMealReadings.length > 0) {
    postMealReadings.forEach((reading) => {
      if (standards) {
        if (standards.postMeal.lowThreshold > reading.value) {
          results.lowReadings += 1;
        } else if (standards.postMeal.highThreshold < reading.value) {
          results.highReadings += 1;
        }

        if (standards.critical.lowThreshold > reading.value) {
          results.criticalLowReadings += 1;
        } else if (standards.critical.highThreshold < reading.value) {
          results.criticalHighReadings += 1;
        }
      }
    });
    results.postMealAverage = mean(postMealReadingsValues);
  } else {
    results.postMealAverage = 0;
  }

  if (!isEmpty(strictPreMealReadingsValues)) {
    results.strictPreMealAverage = conversion.toDisplay(mean(strictPreMealReadingsValues));
  } else {
    results.strictPreMealAverage = 0;
  }

  if (!isEmpty(strictPostMealReadingsValues)) {
    results.strictPostMealAverage = conversion.toDisplay(mean(strictPostMealReadingsValues));
  } else {
    results.strictPostMealAverage = 0;
  }

  // https://care.diabetesjournals.org/content/41/11/2275.figures-only
  results.GMI = round(3.31 + 0.02392 * results.readingsAverage, 2);
  results.CV = round((results.standardDeviation / results.readingsAverage) * 100 || 0, 1);
  results.readingsAverage = conversion.toDisplay(results.readingsAverage);
  results.postMealAverage = conversion.toDisplay(results.postMealAverage);
  results.readingsCount = readingsValues.length;
  results.postMealCount = postMealReadingsValues.length;
  results.preMealCount = preMealReadingsValues.length;
  results.preMealAverage = conversion.toDisplay(results.preMealAverage);
  results.strictPreMealCount = strictPreMealReadings.length;
  results.strictPostMealCount = strictPostMealReadings.length;
  results.standardDeviation = conversion.toDisplay(results.standardDeviation);
  results.maxValue = isEmpty(readingsValues) ? null : conversion.toDisplay(max(readingsValues));
  results.minValue = isEmpty(readingsValues) ? null : conversion.toDisplay(min(readingsValues));

  return results;
}


export function calculateGlucoseConcentrationLevels(standards, conversion) {
  const criticalHigh = get(standards, 'critical.highThreshold');
  const targetHigh = get(standards, 'postMeal.highThreshold');
  const targetLow = get(standards, 'postMeal.lowThreshold');
  const criticalLow = get(standards, 'critical.lowThreshold');

  if (conversion.unit === 'MG_DL') {
    return [
      criticalHigh,
      targetHigh,
      targetLow,
      criticalLow,
      0,
    ];
  }

  return [
    conversion.toDisplay(criticalHigh),
    conversion.toDisplay(targetHigh),
    conversion.toDisplay(targetLow),
    conversion.toDisplay(criticalLow),
    0,
  ];

}


export function calculateGlucoseLevels(readings, conversion, levels) {
  const glucoseLevels = {
    veryHigh: { value: 0, percentage: 20 },
    high    : { value: 0, percentage: 20 },
    target  : { value: 0, percentage: 20 },
    low     : { value: 0, percentage: 20 },
    veryLow : { value: 0, percentage: 20 },
  };

  if (!readings || readings.length === 0) {
    return glucoseLevels;
  }

  readings.forEach((reading) => {
    const lvlIndex = levels.findIndex((lvl, idx) =>
      (idx !== 1 ? conversion.toDisplay(reading.value) >= lvl : conversion.toDisplay(reading.value) > lvl)
    );
    const glucoseConcentrationLevel = Object.keys(glucoseLevels)[lvlIndex];
    glucoseLevels[glucoseConcentrationLevel].value += 1;
  });

  Object.keys(glucoseLevels).forEach((level) => {
    const count = glucoseLevels[level].value;
    glucoseLevels[level].percentage = round(((count / readings.length) * 100), 0);
  });

  return glucoseLevels;
}
