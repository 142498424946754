export const MODULE_ID = 'cloudDrive';


export const REQUIRED_SCOPES = {
  GoogleDrive: [
    'https://www.googleapis.com/auth/drive.appdata',
    'https://www.googleapis.com/auth/drive.file',
  ],
};

export const SYNC_HEALTH_DATA = [
  'diabetesType',
  'treatmentType',
  'summaryData.lastWeight',
  'summaryData.lastHeight',
];
