import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Form/Button';
import OnOffIcon from 'svg/on-off.svg';
import accessTokenShape from 'shapes/accessTokenShape';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import Visit from 'modules/Visit';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';


class StartStopVisitBtn extends React.PureComponent {

  static propTypes = {
    // Explicit props
    activePatient: PropTypes.shape({
      storageProvider: PropTypes.string,
      accessToken    : accessTokenShape,
    }),
    phiSet                : PropTypes.object,
    phiSetDocumentId      : PropTypes.string,
    // Implicit props
    activeVisit           : Visit.shapes.visit,
    activeClinicMembership: Account.shapes.clinicMembership,
    sharingRequest        : shapes.sharingRequest,
    isInProgress          : PropTypes.bool,
    isNotesInProgress     : PropTypes.bool,
    // Implicit actions
    onStartVisit          : PropTypes.func.isRequired,
    onEndVisit            : PropTypes.func.isRequired,
    onFetchVisitMetadata  : PropTypes.func.isRequired,
  };


  componentDidUpdate(prevProps) {
    const { activeVisit, activeClinicMembership } = this.props;
    if (prevProps.activeVisit !== activeVisit && activeVisit && !activeVisit.metadata) {
      this.props.onFetchVisitMetadata(activeVisit, activeClinicMembership);
    }
  }


  onAction(activeVisit) {
    const { activeClinicMembership, activePatient, phiSet, phiSetDocumentId, sharingRequest } = this.props;
    if (activeVisit) {
      this.props.onEndVisit(activePatient, phiSet, activeClinicMembership, activeVisit, sharingRequest);
      return;
    }
    this.props.onStartVisit(activePatient, phiSet, phiSetDocumentId, activeClinicMembership);
  }


  get isDisabled() {
    return !this.props.activePatient
      || !this.props.phiSet
      || !this.props.phiSetDocumentId
      || this.props.isNotesInProgress;
  }


  render() {
    const { activeVisit, isInProgress } = this.props;
    return (
      <Button
        styleModifier={activeVisit ? 'secondary' : 'primary'}
        className="btn--filled ml-4"
        isDisabled={this.isDisabled}
        isInProgress={isInProgress}
        onClick={() => this.onAction(activeVisit)}
      >
        <OnOffIcon className="btn__icon mr-3" />
        <FormattedMessage {...messages.buttons[activeVisit ? 'endVisit' : 'startVisit']} />
      </Button>
    );
  }

}


const mapStateToProps = (state) => ({
  sharingRequest: selectors.sharingRequest(state),
  isInProgress  : selectors.isStartPatientVisitInProgress(state)
    || selectors.isEndPatientVisitInProgress(state)
    || CloudDrive.selectors.isSendNotesInProgress(state),
  activeClinicMembership: Account.selectors.activeClinicMembership(state),
  activeVisit           : Visit.selectors.activeVisit(state),
  isNotesInProgress     : CloudDrive.selectors.isSendNotesInProgress(state),
});


const mapDispatchToProps = (dispatch) => ({
  onStartVisit: (clinicPatient, phiSet, phiSetDocumentId, clinicMembership) => dispatch(
    actions.startPatientVisit(clinicPatient, phiSet, phiSetDocumentId, clinicMembership),
  ),
  onEndVisit: (clinicPatient, phiSet, clinicMembership, visit, sharingRequest) => dispatch(
    actions.endPatientVisit(clinicPatient, phiSet, clinicMembership, visit, sharingRequest),
  ),
  onFetchVisitMetadata: (activeVisit, clinicMembership) => dispatch(
    Visit.actions.fetchVisitMetadata(activeVisit, clinicMembership),
  ),
});


const ConnectedStartStopVisitBtn = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StartStopVisitBtn);


export default ConnectedStartStopVisitBtn;
