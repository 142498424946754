import { MODULE_ID } from './constants';


export const SET_AGP_DAILY_RECORDS = `${MODULE_ID}/SET_AGP_DAILY_RECORDS`;

export const SET_HIGHLIGHTED_DATE = `${MODULE_ID}/SET_HIGHLIGHTED_DATE`;
export const SET_HIGHLIGHTED_DATES = `${MODULE_ID}/SET_HIGHLIGHTED_DATES`;
export const SELECT_HIGHLIGHTED_DATES = `${MODULE_ID}/SELECT_HIGHLIGHTED_DATES`;
export const RESET_HIGHLIGHTED_DATE = `${MODULE_ID}/RESET_HIGHLIGHTED_DATE`;
export const CLEAR_HIGHLIGHTED_DATES = `${MODULE_ID}/CLEAR_HIGHLIGHTED_DATES`;

export const SET_DAYS_SELECTOR_ZOOM = `${MODULE_ID}/SET_DAYS_SELECTOR_ZOOM`;
export const SET_DAYS_SELECTOR_ZOOM_SMART_PAGE = `${MODULE_ID}/SET_DAYS_SELECTOR_ZOOM_SMART_PAGE`;
export const SET_DAYS_SELECTOR_PAGE = `${MODULE_ID}/SET_DAYS_SELECTOR_PAGE`;

export const SET_AGP_MONTH_SELECTOR_PAGE = `${MODULE_ID}/SET_AGP_MONTH_SELECTOR_PAGE`;

export const SET_PREVIEW = `${MODULE_ID}/SET_PREVIEW`;
export const SET_AGP_PREVIEW = `${MODULE_ID}/SET_AGP_PREVIEW`;
export const PREPARE_AGP_PREVIEW = `${MODULE_ID}/PREPARE_AGP_PREVIEW`;

export const RESET_AGGREGATIONS = `${MODULE_ID}/RESET_AGGREGATIONS`;
export const APPEND_AGGREGATIONS = `${MODULE_ID}/APPEND_AGGREGATIONS`;

export const SET_SHOW_MEDIAN = `${MODULE_ID}/SET_SHOW_MEDIAN`;
export const SET_SHOW_IQR = `${MODULE_ID}/SET_SHOW_IQR`;
export const SET_SHOW_P10P90 = `${MODULE_ID}/SET_SHOW_P10P90`;
