import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import range from 'lodash/range';
import round from 'lodash/round';
import get from 'lodash/get';
import withStyles from 'isomorphic-style-loader/withStyles';
import Draggable from 'react-draggable';
import AutoSizer from 'react-virtualized-auto-sizer';
import slidersStyles from '../Sliders.pcss';
import messages from '../../../modules/Patient/messages';
import styles from './GlucoseLevelsSlider.pcss';


class GlucoseLevelsSlider extends React.PureComponent {

  static propTypes = {
    // Explicit props
    valueMin     : PropTypes.number,
    valueMax     : PropTypes.number,
    valueStep    : PropTypes.number,
    valueDragStep: PropTypes.number,
    formValues   : PropTypes.object,
    conversion   : PropTypes.object,
    locked       : PropTypes.bool,
    targetType   : PropTypes.string,
    precision    : PropTypes.number,
    phiSet       : PropTypes.object,
    // Explicit actions
    onRangeChange: PropTypes.func,
    // Implicit props
  };

  static defaultProps = {
    valueMin     : 0,
    valueMax     : 300,
    valueStep    : 50,
    valueDragStep: 1,
    locked       : false,
    targetType   : '',
    precision    : 0,
  }

  constructor(props) {
    super(props);
    this.levels = {
      LOW   : 'low',
      TARGET: 'target',
      HIGH  : 'high',
    };
    this.barWidth = 64;
    this.state = {};
  }


  countValue(data, height) {
    const { valueMax, valueMin, valueDragStep, precision } = this.props;
    const offset = (height - data.y) / height * (valueMax - valueMin);
    return (round(offset, precision) - (round(offset, precision) % valueDragStep));
  }


  renderScaleValue() {
    const { valueMax, valueMin, valueStep } = this.props;
    return range(valueMin, valueMax + 1, valueStep).map(
      (value) => (
        <div className="scaleValue__value brand__caption" key={value}>
          {value}
        </div>
      )
    );
  }


  renderScaleGrid() {
    const { valueMax, valueMin, valueStep } = this.props;
    return range(valueMin, valueMax + 1, valueStep).map(
      (value) => (<div className="scaleGrid__row" key={value} />)
    );
  }


  renderScales() {
    return (
      <>
        <div className="scaleValue scaleValue--left">
          {this.renderScaleValue()}
        </div>
      </>
    );
  }


  renderRangeSliderHandler({
    level,
    height,
    top,
  }) {
    return (
      <div
        className={
          cn(
            'rangeSlider',
            {
              'rangeSlider--top'   : level === this.levels.HIGH,
              'rangeSlider--bottom': level === this.levels.LOW,
            }
          )
        }
      >
        <div className="rangeSlider__area" style={{ height: `${height}px`, top: top && `${top}px` }} />
        <button
          className={cn('rangeSlider__dragButton', 'rangeSlider__dragButton--end')}
          type="button"
        />
      </div>
    );
  }


  renderSlider(top, value, type) {
    return (
      <>
        <div className="brand__caption rangeSlider__overlay" style={{ top }} />
        <div
          className={cn(slidersStyles.rengeSlider__value, {
            [slidersStyles.rangeSlider__high]: type === 'high',
            [slidersStyles.rangeSlider__low] : type === 'low',
          })}
          style={{ top }}
        >
          {value}
        </div>
        <div className={cn('brand__caption', slidersStyles.rengeSlider__label)} style={{ top }}>
          <FormattedMessage {...messages.labels[type]} />
        </div>
      </>
    );
  }


  renderRangeSliders(height) {
    const { valueMax, valueMin, formValues, locked, targetType } = this.props;
    const countOffset = (value = 0) => height - (value / (valueMax - valueMin) * height);

    const values = {
      glucoseHigh: get(formValues, `values.${targetType}_highThreshold`),
      glucoseLow : get(formValues, `values.${targetType}_lowThreshold`),
    };
    const offsets = {
      glucoseHigh: countOffset(values.glucoseHigh),
      glucoseLow : countOffset(values.glucoseLow),
    };

    return (
      <>
        <div
          className="rangeSegment"
          style={{
            right: 0,
          }}
        >
          <div className="rangeSegmentBorder">
            <Draggable
              axis="y"
              disabled={locked}
              position={{
                y: offsets.glucoseHigh,
                x: 0,
              }}
              onDrag={(e, data) => {
                this.props.onRangeChange({ id: `${targetType}_highThreshold`, value: this.countValue(data, height) });
              }}
              bounds={{
                top   : 20,
                bottom: offsets.glucoseLow,
              }}
            >
              {
              this.renderRangeSliderHandler({
                level : this.levels.HIGH,
                height: offsets.glucoseHigh,
                value : values.glucoseHigh,
              })
            }
            </Draggable>
            <Draggable
              axis="y"
              disabled={locked}
              position={{
                y: offsets.glucoseLow,
                x: 0,
              }}
              onDrag={(e, data) => {
                this.props.onRangeChange({ id: `${targetType}_lowThreshold`, value: this.countValue(data, height) });
              }}
              bounds={{
                top   : offsets.glucoseHigh,
                bottom: height - 20,
              }}
            >
              {
              this.renderRangeSliderHandler({
                level : this.levels.LOW,
                height: height - offsets.glucoseLow,
                value : values.glucoseLow,
              })
            }
            </Draggable>
            <div
              className="targetArea"
              style={{ top: `${offsets.glucoseHigh}px`, bottom: `${height - offsets.glucoseLow}px` }}
            />
          </div>
        </div>
        { this.renderSlider(offsets.glucoseHigh, values.glucoseHigh, 'high') }
        { this.renderSlider(offsets.glucoseLow, values.glucoseLow, 'low') }
      </>
    );
  }


  renderSlidersLayer() {
    return (
      <div
        className={cn(
          'sliders',
          {
            'sliders--locked': this.props.locked,
          }
        )}
      >
        <AutoSizer>
          {
            ({ height, width }) => (
              <div style={{
                width : `${width}px`,
                height: `${height}px`,
              }}
              >
                {this.renderRangeSliders(height)}
              </div>
            )
          }
        </AutoSizer>
      </div>
    );
  }


  render() {
    return (
      <div className={cn(slidersStyles.rangesSlider, styles.patientRangesSlider)}>
        {this.renderScales()}
        {this.renderSlidersLayer()}
      </div>
    );
  }

}

export default withStyles(styles, slidersStyles)(GlucoseLevelsSlider);
