import React, { FC, useEffect } from 'react';
import { useAction } from 'hooks';
import Account from 'modules/Account';

import DownloadDataModal from './DownloadDataModal';

import Initializing from './DownloadDataModal/Initializing';

import Connect from './DownloadDataModal/Connect';
import ConnectionFailed from './DownloadDataModal/ConnectionFailed';

import Downloading from './DownloadDataModal/Downloading';
import Timeout from './DownloadDataModal/Timeout';
import DownloadSummary from './DownloadDataModal/DownloadSummary';


const components = {
  Initializing,

  Connect,
  ConnectionFailed,

  Downloading,
  Timeout,

  DownloadSummary,
};


const PwdDownloadDataModal: FC = () => {
  const connectorType: ConnectorType = 'MDA';
  const defaultComponentName = 'Initializing';

  const fetchSupportedDevices = useAction(Account.actions.fetchPatientProfileSupportedDevices);

  useEffect(() => {
    fetchSupportedDevices();
  }, []);

  return <DownloadDataModal {...{ connectorType, components, defaultComponentName }} />;
};

export default PwdDownloadDataModal;
