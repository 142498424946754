import {
  registerAction,
  unregisterAction,
} from 'helpers/reducerTools';
import { SIGN_OUT_SUCCESS } from 'modules/Account/actionTypes';
import { SET_NEW_CONFIGURATION_VERSION } from 'modules/App/actionTypes';
import * as actionTypes from './actionTypes';


const initialState = {
  organizationMemberships          : [],
  clinicMemberships                : [],
  clinicCustomIdentifiers          : [],
  clinicCustomIdentifiersCountryId : null,
  patientsCount                    : null,
  importPatientDatabaseProgress    : 0,
  importPatientDatabaseSuccessCount: 0,
  importPatientDatabaseErrorCount  : 0,
  importPatientDatabaseErrorsList  : [],
  importPatientDatabaseIsWorking   : false,
  fetching                         : [],
  sending                          : [],
  errors                           : [],
  countrySettings                  : null,
  clinicSettings                   : null,
};


export default function reducer(state = { ...initialState }, action) {

  switch (action.type) {

    case actionTypes.FETCH_CLINIC_CUSTOM_IDENTIFIERS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_CLINIC_CUSTOM_IDENTIFIERS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_CLINIC_CUSTOM_IDENTIFIERS),
      };
    }
    case actionTypes.FETCH_CLINIC_CUSTOM_IDENTIFIERS_SUCCESS: {
      const { clinicCustomIdentifiersCountryId, clinicCustomIdentifiers } = action.payload;
      if (clinicCustomIdentifiersCountryId === state.clinicCustomIdentifiersCountryId) {
        return {
          ...state,
          fetching: unregisterAction(state.fetching, actionTypes.FETCH_CLINIC_CUSTOM_IDENTIFIERS),
        };
      }
      return {
        ...state,
        clinicCustomIdentifiers,
        clinicCustomIdentifiersCountryId,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_CLINIC_CUSTOM_IDENTIFIERS),
      };
    }
    case actionTypes.FETCH_CLINIC_CUSTOM_IDENTIFIERS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_CLINIC_CUSTOM_IDENTIFIERS),
        errors  : registerAction(state.errors, actionTypes.FETCH_CLINIC_CUSTOM_IDENTIFIERS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.STORE_CLINIC: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.STORE_CLINIC),
        errors : unregisterAction(state.errors, actionTypes.STORE_CLINIC),
      };
    }
    case actionTypes.STORE_CLINIC_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.STORE_CLINIC),
      };
    }
    case actionTypes.STORE_CLINIC_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.STORE_CLINIC),
        errors : registerAction(state.errors, actionTypes.STORE_CLINIC),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.GET_STORED_CLINIC: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.GET_STORED_CLINIC),
        errors : unregisterAction(state.errors, actionTypes.GET_STORED_CLINIC),
      };
    }
    case actionTypes.GET_STORED_CLINIC_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.GET_STORED_CLINIC),
      };
    }
    case actionTypes.GET_STORED_CLINIC_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.GET_STORED_CLINIC),
        errors : registerAction(state.errors, actionTypes.GET_STORED_CLINIC),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_CLINIC_CLOUD_DRIVE: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.SET_CLINIC_CLOUD_DRIVE),
        errors : unregisterAction(state.errors, actionTypes.SET_CLINIC_CLOUD_DRIVE),
      };
    }
    case actionTypes.SET_CLINIC_CLOUD_DRIVE_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.SET_CLINIC_CLOUD_DRIVE),
      };
    }
    case actionTypes.SET_CLINIC_CLOUD_DRIVE_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.SET_CLINIC_CLOUD_DRIVE),
        errors : registerAction(state.errors, actionTypes.SET_CLINIC_CLOUD_DRIVE),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.UPDATE_CLINIC_GLUCOSE_SETTINGS: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.UPDATE_CLINIC_GLUCOSE_SETTINGS),
        errors : unregisterAction(state.errors, actionTypes.UPDATE_CLINIC_GLUCOSE_SETTINGS),
      };
    }
    case actionTypes.UPDATE_CLINIC_GLUCOSE_SETTINGS_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_CLINIC_GLUCOSE_SETTINGS),
      };
    }
    case actionTypes.UPDATE_CLINIC_GLUCOSE_SETTINGS_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_CLINIC_GLUCOSE_SETTINGS),
        errors : registerAction(state.errors, actionTypes.UPDATE_CLINIC_GLUCOSE_SETTINGS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.UPDATE_CLINIC_KPI_SETTINGS: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.UPDATE_CLINIC_KPI_SETTINGS),
        errors : unregisterAction(state.errors, actionTypes.UPDATE_CLINIC_KPI_SETTINGS),
      };
    }
    case actionTypes.UPDATE_CLINIC_KPI_SETTINGS_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_CLINIC_KPI_SETTINGS),
      };
    }
    case actionTypes.UPDATE_CLINIC_KPI_SETTINGS_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_CLINIC_KPI_SETTINGS),
        errors : registerAction(state.errors, actionTypes.UPDATE_CLINIC_KPI_SETTINGS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.REAUTHORIZE_CLINIC_CLOUD_DRIVE: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.REAUTHORIZE_CLINIC_CLOUD_DRIVE),
        errors : unregisterAction(state.errors, actionTypes.REAUTHORIZE_CLINIC_CLOUD_DRIVE),
      };
    }
    case actionTypes.REAUTHORIZE_CLINIC_CLOUD_DRIVE_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.REAUTHORIZE_CLINIC_CLOUD_DRIVE),
      };
    }
    case actionTypes.REAUTHORIZE_CLINIC_CLOUD_DRIVE_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.REAUTHORIZE_CLINIC_CLOUD_DRIVE),
        errors : registerAction(state.errors, actionTypes.REAUTHORIZE_CLINIC_CLOUD_DRIVE),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS),
      };
    }
    case actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS_SUCCESS: {
      const { organizationMemberships } = action.payload;
      return {
        ...state,
        organizationMemberships,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS),
      };
    }
    case actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS),
        errors  : registerAction(state.errors, actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_CLINIC_MEMBERSHIPS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_CLINIC_MEMBERSHIPS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_CLINIC_MEMBERSHIPS),
      };
    }
    case actionTypes.FETCH_CLINIC_MEMBERSHIPS_SUCCESS: {
      const { clinicMemberships } = action.payload;
      return {
        ...state,
        clinicMemberships,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_CLINIC_MEMBERSHIPS),
      };
    }
    case actionTypes.FETCH_CLINIC_MEMBERSHIPS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_CLINIC_MEMBERSHIPS),
        errors  : registerAction(state.errors, actionTypes.FETCH_CLINIC_MEMBERSHIPS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_PATIENTS_COUNT: {
      const { patientsCount } = action.payload;
      return {
        ...state,
        patientsCount,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.REMOVE_CLINIC_DATA: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.REMOVE_CLINIC_DATA),
        errors : unregisterAction(state.errors, actionTypes.REMOVE_CLINIC_DATA),
      };
    }
    case actionTypes.REMOVE_CLINIC_DATA_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.REMOVE_CLINIC_DATA),
      };
    }
    case actionTypes.REMOVE_CLINIC_DATA_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.REMOVE_CLINIC_DATA),
        errors : registerAction(state.errors, actionTypes.REMOVE_CLINIC_DATA),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.IMPORT_PATIENT_DATABASE: {
      const { isCheckOnly } = action.payload;
      return {
        ...state,
        // importPatientDatabaseProgress : 0,
        importPatientDatabaseIsWorking: !isCheckOnly,
        sending                       : registerAction(state.sending, actionTypes.IMPORT_PATIENT_DATABASE),
      };
    }
    case actionTypes.IMPORT_PATIENT_DATABASE_PATIENT_STORED: {
      const { successCount, errorCount, errorsList } = action.payload;
      return {
        ...state,
        importPatientDatabaseSuccessCount: successCount,
        importPatientDatabaseErrorCount  : errorCount,
        importPatientDatabaseErrorsList  : errorsList,
      };
    }
    case actionTypes.IMPORT_PATIENT_DATABASE_SUCCESS: {
      const { progress } = action.payload;
      return {
        ...state,
        importPatientDatabaseProgress: progress,
      };
    }
    case actionTypes.IMPORT_PATIENT_DATABASE_END:
    case actionTypes.IMPORT_PATIENT_DATABASE_ERROR: {
      const { noReset } = action.payload || {};
      if (noReset) {
        return {
          ...state,
          importPatientDatabaseIsWorking: false,
          sending                       : unregisterAction(state.sending, actionTypes.IMPORT_PATIENT_DATABASE),
        };
      }
      return {
        ...state,
        importPatientDatabaseProgress    : 0,
        importPatientDatabaseSuccessCount: 0,
        importPatientDatabaseErrorCount  : 0,
        importPatientDatabaseErrorsList  : [],
        importPatientDatabaseIsWorking   : false,
        sending                          : unregisterAction(state.sending, actionTypes.IMPORT_PATIENT_DATABASE),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_CLINIC_LICENCE: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.SET_CLINIC_LICENCE),
        errors  : unregisterAction(state.errors, actionTypes.SET_CLINIC_LICENCE),
      };
    }
    case actionTypes.SET_CLINIC_LICENCE_SUCCESS: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.SET_CLINIC_LICENCE),
      };
    }
    case actionTypes.SET_CLINIC_LICENCE_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.SET_CLINIC_LICENCE),
        errors  : registerAction(state.errors, actionTypes.SET_CLINIC_LICENCE),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_CLINIC_COUNTRY_SETTINGS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_CLINIC_COUNTRY_SETTINGS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_CLINIC_COUNTRY_SETTINGS),
      };
    }
    case actionTypes.FETCH_CLINIC_COUNTRY_SETTINGS_SUCCESS: {
      const { countrySettings } = action.payload;
      return {
        ...state,
        countrySettings,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_CLINIC_COUNTRY_SETTINGS),
      };
    }
    case actionTypes.FETCH_CLINIC_COUNTRY_SETTINGS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_CLINIC_COUNTRY_SETTINGS),
        errors  : registerAction(state.errors, actionTypes.FETCH_CLINIC_COUNTRY_SETTINGS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_CLINIC_SETTINGS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_CLINIC_SETTINGS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_CLINIC_SETTINGS),
      };
    }
    case actionTypes.FETCH_CLINIC_SETTINGS_SUCCESS: {
      const { clinicSettings } = action.payload;
      return {
        ...state,
        clinicSettings,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_CLINIC_SETTINGS),
      };
    }
    case actionTypes.FETCH_CLINIC_SETTINGS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_CLINIC_SETTINGS),
        errors  : registerAction(state.errors, actionTypes.FETCH_CLINIC_SETTINGS),
      };
    }

    // BackOffice UPDATE -----------------------------------------------------------------------------------------------

    case SET_NEW_CONFIGURATION_VERSION: {
      const { countrySettings } = action.payload;
      return {
        ...state,
        countrySettings,
      };
    }


    //------------------------------------------------------------------------------------------------------------------

    case SIGN_OUT_SUCCESS: {
      return {
        ...initialState,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    default: {
      return state;
    }

  }
}
