export const MODULE_ID = 'hcpExports';

export const EXPORT_DATA_MODAL = `${MODULE_ID}/exportDataModal`;

export const EXPORT_DEFINITIONS = {
  patientsBloodGlucoseExport: [
    'patient.id',
    'patient.lastName',
    'patient.firstName',
    'patient.dateOfBirth',
    'reading.datetime',
    'reading.value',
    'readingRelatedData.insulinLong',
    'readingRelatedData.insulinFast',
    'readingRelatedData.carbs',
    'reading.mealMarker',
  ],
};
