import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import messages from '../../messages';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import styles from './NoSharingRequests.pcss';


class NoSharingRequests extends React.PureComponent {

  static propTypes = {
    // Implicit props
    sharingRequests: PropTypes.arrayOf(shapes.sharingRequest),
    isInProgress   : PropTypes.bool,
  };

  render() {
    if (this.props.sharingRequests.length || this.props.isInProgress) {
      return null;
    }
    return (
      <div className={styles.root}>
        <div className="w-100">
          <img src="/assets/svg/no-people.svg" className={styles.icon} alt="" />
        </div>
        <div>
          <FormattedMessage {...messages.infos.noSharingRequests} />
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  sharingRequests: selectors.sharingRequests(state),
  isInProgress   : selectors.isFetchSharingRequestsInProgress(state),
});


const ConnectedNoSharingRequests = connect(
  mapStateToProps,
)(NoSharingRequests);


export default withStyles(styles)(ConnectedNoSharingRequests);
