import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import withStyles from 'isomorphic-style-loader/withStyles';
import map from 'lodash/map';
import { AppContext } from 'context';
import { getSlug } from 'helpers/urlTools';
import Avatar from 'components/Avatar';
import Link from 'components/Link';
import SidebarPersonsLoader from 'components/Loaders/SidebarPersonsLoader';
import Dots from 'svg/dots.svg';
import App from 'modules/App';
import Account from 'modules/Account';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import styles from './FamilyList.pcss';


class FamilyList extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Implicit props
    familyLinkInvitations: PropTypes.arrayOf(shapes.familyLinkInvitation),
    familyLinkRequests   : PropTypes.arrayOf(shapes.familyLinkRequest),
    patientProfile       : PropTypes.shape({ patientProfileId: PropTypes.number.isRequired }),
    cgProfile            : PropTypes.shape({ cgProfileId: PropTypes.number.isRequired }),
    isInProgress         : PropTypes.bool,
    isClientInitialized  : PropTypes.bool,
    openDropdownId       : PropTypes.string,
    route                : App.shapes.route,
    // Implicit actions
    onFetchFamilyLinks   : PropTypes.func,
    onOpenDropdown       : PropTypes.func,
    onCloseDropdown      : PropTypes.func,
    onOpenModal          : PropTypes.func,
    onSetFormContext     : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.onFetchFamilyLinks();
  }


  onFetchFamilyLinks() {
    if (process.env.BROWSER && !this.props.isClientInitialized) {
      return;
    }
    this.props.onFetchFamilyLinks();
  }


  async onOpenMenu(evt, familyLinkId) {
    evt.preventDefault();
    evt.stopPropagation();
    await this.props.onCloseDropdown();
    this.props.onOpenDropdown(`${constants.FAMILY_MEMBER_DROPDOWN}-${familyLinkId}`);
  }


  onRevokeAccess(evt, familyLinkInvitation) {
    evt.preventDefault();
    this.props.onSetFormContext(constants.REVOKE_FAMILY_ACCESS_FORM, familyLinkInvitation);
    this.props.onOpenModal(constants.REVOKE_FAMILY_ACCESS_MODAL);
  }


  get approvedFamilyLinkInvitations() {
    return this.props.familyLinkInvitations.filter((fl) => fl.status === 'Approved');
  }


  renderAvatar(person) {
    return (
      <Avatar
        avatarImg={person.avatar}
        name={[person.firstName, person.lastName]}
        className={styles.item__avatar}
        imgClassName={styles.item__avatar__img}
        initialsClassName={styles.item__avatar__initials}
      />
    );
  }


  renderFamilyLinkInvitationMenu(familyLinkInvitation) {
    const { familyLinkId } = familyLinkInvitation;
    if (this.props.openDropdownId !== `${constants.FAMILY_MEMBER_DROPDOWN}-${familyLinkId}`) {
      return null;
    }

    return (
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: 'auto' }}
        exit={{ width: 0 }}
        transition={{ ease: 'easeOut', duration: 0.15 }}
        className="dropdownMenu__container dropdownMenu__container--oposite ml-3"
      >
        <ul className="dropdownMenu__actions">
          <li className="dropdownMenu__action">
            <Link to="" onClick={(evt) => this.onRevokeAccess(evt, familyLinkInvitation)}>
              <FormattedMessage {...messages.labels.revokeAccess} />
            </Link>
          </li>
        </ul>
      </motion.div>
    );
  }


  renderFamilyLinkInvitation(familyLinkInvitation) {
    const { familyLinkId, requestor } = familyLinkInvitation;
    return (
      <li
        key={familyLinkId}
        className={cn(styles.item__sidebarList, styles['item__sidebarList--noHover'])}
      >
        <div className="row align-items-center">
          <div className="col">
            <div className="d-block">
              <div className="row align-items-center">
                <div className="col-auto pr-2">
                  { this.renderAvatar(requestor) }
                </div>
                <div className="col">
                  <p data-hj-suppress className={styles.item__name}>{ requestor.firstName } { requestor.lastName }</p>
                </div>
              </div>
            </div>
          </div>
          <div className={cn('col-auto', styles.item__menu)}>
            <Link to="" onClick={(evt) => this.onOpenMenu(evt, familyLinkId)}>
              <Dots className="dropdownMenu__icon" />
            </Link>
            <AnimatePresence>
              { this.renderFamilyLinkInvitationMenu(familyLinkInvitation) }
            </AnimatePresence>
          </div>
        </div>
      </li>
    );
  }


  renderFamilyLinkRequest(familyLinkRequest) {
    const { familyLinkId: routeFamilyLinkId } = this.props.route.params;
    const { familyLinkId, encryptedExchangeToken, invitee = {} } = familyLinkRequest;
    const patientSlug = getSlug(`${invitee.firstName} ${invitee.lastName}`);
    const linkUrl = encryptedExchangeToken
      ? this.context.getUrl('family-member-results', { patientSlug, familyLinkId })
      : '';
    const isActive = this.props.route.name === 'family-member-results' && +routeFamilyLinkId === familyLinkId;

    return (
      <li key={familyLinkId} className={cn(styles.item__sidebarList, { [styles['item--active']]: isActive })}>
        <div className="row align-items-center">
          <div className="col">
            <Link to={linkUrl} className={cn('d-block', { isDisabled: !encryptedExchangeToken })}>
              <div className="row align-items-center">
                <div className="col-auto pr-2">
                  { this.renderAvatar(invitee) }
                </div>
                <div className="col">
                  <p data-hj-suppress className={styles.item__name}>{ invitee.firstName } { invitee.lastName }</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </li>
    );
  }


  renderInvitations(approvedFamilyLinkInvitations) {
    if (!approvedFamilyLinkInvitations.length) {
      return null;
    }
    return (
      <div>
        <hr />
        <h3 className="text--small text--bold px-3 py-2">
          <FormattedMessage {...messages.headers.familyLinkInvitations} />
        </h3>
        <ul className={styles.items}>
          { map(approvedFamilyLinkInvitations, (familyLink) => this.renderFamilyLinkInvitation(familyLink)) }
        </ul>
      </div>
    );
  }


  renderRequests(familyLinkRequests) {
    if (!familyLinkRequests.length) {
      return null;
    }
    return (
      <div>
        <ul className={styles.items}>
          { map(familyLinkRequests, (familyLink) => this.renderFamilyLinkRequest(familyLink)) }
        </ul>
      </div>
    );
  }


  render() {
    if (this.props.isInProgress) {
      return <SidebarPersonsLoader itemsNumber={2} />;
    }
    const { familyLinkRequests } = this.props;
    const { approvedFamilyLinkInvitations } = this;
    if (!approvedFamilyLinkInvitations.length && !familyLinkRequests.length) {
      return null;
    }
    return (
      <div>
        { this.renderRequests(familyLinkRequests) }
        { this.renderInvitations(approvedFamilyLinkInvitations) }
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  familyLinkInvitations: selectors.familyLinkInvitations(state),
  familyLinkRequests   : selectors.familyLinkRequests(state),
  isInProgress         : selectors.isFetchFamilyLinksInProgress(state),
  patientProfile       : Account.selectors.patientProfile(state),
  cgProfile            : Account.selectors.cgProfile(state),
  isClientInitialized  : App.selectors.isClientInitialized(state),
  openDropdownId       : App.selectors.dropdown(state),
  route                : App.selectors.route(state),
});


const mapDispatchToProps = (dispatch) => ({
  onFetchFamilyLinks: () => dispatch(actions.fetchFamilyLinkRequests()),
  onOpenDropdown    : (dropdownId) => dispatch(App.actions.openDropdown(dropdownId)),
  onCloseDropdown   : () => dispatch(App.actions.closeDropdown()),
  onOpenModal       : (modalId) => dispatch(App.actions.openModal(modalId)),
  onSetFormContext  : (formName, contextData) => dispatch(App.actions.setFormContext(formName, contextData)),
});


const ConnectedFamilyList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FamilyList);


export default withStyles(styles)(ConnectedFamilyList);
