import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Form/Button';
import messages from '../../../messages';
import styles from '../DownloadDataModal.pcss';


interface Props {
  onSetComponent: (componentName: string) => void,
}


const InstalledBlueCableDrivers: FC<Props> = ({ onSetComponent }) => {
  const onConnect = () => onSetComponent('ConnectBlueCable');

  return (
    <div className={styles.modal__content}>
      <h3 className="modal__subheader "><FormattedMessage {...messages.installedBlueCableDrivers.header} /></h3>
      <div className={styles.illustration}>
        <img src="/assets/svg/tick.svg" alt="" />
      </div>
      <div className="modal__actions">
        <Button
          styleModifier="primary"
          labelMessage={messages.buttons.continueToDownload}
          className="btn--block btn--filled mb-0"
          onClick={onConnect}
        />
      </div>
    </div>
  );
};

export default InstalledBlueCableDrivers;
