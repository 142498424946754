import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { MessageDescriptor, useIntl } from 'react-intl';
import * as selectors from '../../selectors';


interface Props {
  titleMessage?: MessageDescriptor,
  descriptionMessage?: MessageDescriptor,
}


const IntlHelmet: FC<Props> = ({ titleMessage, descriptionMessage, children }) => {
  const direction = useSelector(selectors.direction);
  const locale = useSelector(selectors.locale);
  const { formatMessage } = useIntl();

  return (
    <Helmet
      titleTemplate="%s"
      htmlAttributes={{ lang: locale, dir: direction }}
    >
      { titleMessage && <title>{ formatMessage(titleMessage) }</title> }
      { descriptionMessage && <meta name="description" content={formatMessage(descriptionMessage)} /> }
      { children }
    </Helmet>
  );
};


export default IntlHelmet;


