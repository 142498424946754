// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._18qBm{color:#000;display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;font-size:14px;font-size:1.4rem;line-height:21px;margin-bottom:32px}._2Zwrc{font-size:16px;font-size:1.6rem;font-weight:700}._1ucWl{font-weight:400}._3RvKb{text-align:right;font-weight:700}._2Wy22{text-align:center;font-weight:400}._2qkgn{background-color:#f7d261}._2qkgn,._1qSmM{display:inline-block;height:100%;padding:0 3px}._1qSmM{background-color:#f9707e}._18qBm td,._18qBm th{padding:3px}", ""]);
// Exports
exports.locals = {
	"header": "_18qBm",
	"header__title": "_2Zwrc",
	"header__daterange": "_1ucWl",
	"header__right-col": "_3RvKb",
	"header__center-col": "_2Wy22",
	"header__high": "_2qkgn",
	"header__low": "_1qSmM"
};
module.exports = exports;
