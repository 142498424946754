// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3ei4A{font-size:14px;font-size:1.4rem;color:#000;width:100%}.ONmxH{background-color:#c9cfdc;text-align:center;font-weight:700;padding:4px 8px;line-height:21px}.ONmxH span{font-weight:400}._2UoYD{text-align:left}._1zrMz{background-color:#c9cfdc;padding:4px 8px}._14HwQ{padding:4px 8px;text-align:center}", ""]);
// Exports
exports.locals = {
	"table": "_3ei4A",
	"table__heading-col": "ONmxH",
	"table__heading-col--left": "_2UoYD",
	"table__heading-body-col": "_1zrMz",
	"table__col": "_14HwQ"
};
module.exports = exports;
