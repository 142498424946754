import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import AppLoader from 'components/AppLoader';
import App from 'modules/App';
import messages from '../../messages';
import styles from './LoaderLayout.pcss';


class LoaderLayout extends React.Component {

  static propTypes = {
    // Explicit props
    metaTitleMessage      : PropTypes.object,
    metaDescriptionMessage: PropTypes.object,
  };


  render() {
    const titleMessage = this.props.metaTitleMessage
      ? this.props.metaTitleMessage
      : { ...messages.meta.title };

    const descriptionMessage = this.props.metaDescriptionMessage
      ? this.props.metaDescriptionMessage
      : { ...messages.meta.description };

    return (
      <App.components.LanguageProvider>
        <div className={styles.root}>
          <App.components.IntlHelmet
            titleMessage={titleMessage}
            descriptionMessage={descriptionMessage}
          />
          <div className={styles.root__container}>
            <AppLoader />
          </div>
        </div>
      </App.components.LanguageProvider>
    );
  }

}


export default withStyles(styles)(LoaderLayout);
