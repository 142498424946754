// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3QNPO{height:.5in;margin:.25in 0;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;width:100%}._3QNPO .reportName{font-size:20pt;-ms-flex:1 1;flex:1 1}._3QNPO .reportName__title{border:1px solid #000;padding:.2em;display:inline-block;text-transform:capitalize}._3QNPO .reportInfo{width:3.75in;height:100%;font-size:9pt;font-weight:600;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:distribute;justify-content:space-around}._3QNPO .reportInfo__row{border-bottom:2px solid #000;display:-ms-flexbox;display:flex;padding-bottom:1pt}._3QNPO .reportInfo__rowTitle{width:20%;text-transform:uppercase}._3QNPO .reportInfo__rowContent{-ms-flex:1 1;flex:1 1}", ""]);
// Exports
exports.locals = {
	"headerSection": "_3QNPO"
};
module.exports = exports;
