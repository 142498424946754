// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".I8PmT{width:36px}html[dir=ltr] .XlqRl{margin-right:24px}html[dir=rtl] .XlqRl{margin-left:24px}.XlqRl form{width:100%}.XlqRl .form-group{margin-bottom:0}._3AEiY{opacity:.3}._3AEiY>*{pointer-events:none}._2GTf3{margin:20px;width:1px;height:100%;background:#e0e8f2}._1m0yM{display:none}._2ggR2{-ms-flex:1 1;flex:1 1;position:relative}._1ljuH{position:absolute;top:50px;width:100%;max-width:430px;background:#fff;padding:16px;border-radius:8px;z-index:1030;overflow:hidden}", ""]);
// Exports
exports.locals = {
	"logo": "I8PmT",
	"element": "XlqRl",
	"element--isDisabled": "_3AEiY",
	"separate": "_2GTf3",
	"hide": "_1m0yM",
	"search": "_2ggR2",
	"searchModal": "_1ljuH"
};
module.exports = exports;
