import React, { FC, useContext, useState } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { AppContext } from 'context';
import { useAction } from 'hooks';
// @ts-ignore
import XIcon from 'svg/x.svg';
import Button from 'components/Form/Button';
import * as actions from '../../actions';
import messages from '../../messages';
import { ALLOW_COOKIES } from './constants';
import styles from './Cookies.pcss';


const Cookies: FC = () => {
  const { cookies } = useContext(AppContext);
  const isCookiesAllowed = cookies.get(ALLOW_COOKIES);
  const allowCookies = useAction(actions.setAllowCookies);
  const [showCookiesInfo, setShowCookiesInfo] = useState<boolean>(!isCookiesAllowed);


  const privacyLink = (
    <a target="_blank" href="https://support.glucocontro.online/hc/en-us/articles/360012770119-Privacy-Policy">
      <FormattedMessage {...messages.links.privacyPolicy} />
    </a>
  );


  const onCookiesAccept = () => {
    allowCookies();
    setShowCookiesInfo(false);
  };


  const onCloseCookiesWidget = () => {
    setShowCookiesInfo(false);
  };


  if (!showCookiesInfo) {
    return null;
  }


  return (
    <div className={styles.cookiesBox}>
      { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */ }
      <div className={styles.cookiesBox__closeWrapper} onClick={onCloseCookiesWidget}>
        <XIcon className={styles.cookiesBox__closeIcon} />
      </div>
      <p className={styles.cookiesBox__content}>
        <FormattedMessage {...messages.infos.cookieInfo} values={{ privacyLink }} />
      </p>
      <Button
        styleModifier="primary"
        labelMessage={messages.buttons.acceptAllCookies}
        className="btn--block btn--filled"
        onClick={onCookiesAccept}
      />
    </div>
  );
};

export default withStyles(styles)(injectIntl(Cookies));
