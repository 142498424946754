import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import OutsideClickHandler from 'react-outside-click-handler';
import includes from 'lodash/includes';
import pull from 'lodash/pull';
import isEmpty from 'lodash/isEmpty';
import Button from 'components/Form/Button';
import PatientResults from 'modules/PatientResults';
import Download from 'svg/download.svg';
import PdfFileIcon from 'svg/pdf-file.svg';
import CsvFileIcon from 'svg/csv-file.svg';
import ChevronRight from 'svg/chevron-right.svg';
import OpenInNewIcon from 'svg/open-in-new.svg';
import CheckboxRadio from 'components/Form/CheckboxRadio';
import styles from './ReportButton.pcss';


class ReportButton extends React.Component {


  static getDerivedStateFromProps(props, state) {
    const { isInProgress } = props;
    if (isInProgress && state.isOpen) {
      return {
        isOpen         : false,
        selectedReports: [],
      };
    }

    return null;
  }


  static propTypes = {
    // Explicit props
    featureToggles          : PropTypes.arrayOf(PropTypes.string),
    storeSelectedReportTypes: PropTypes.func,
    goToReportsView         : PropTypes.func,
    isInProgress            : PropTypes.bool,
  };


  constructor(props) {
    super(props);
    this.state = {
      isOpen         : false,
      selectedReports: [],
    };
  }


  onReportClick(report) {
    const { selectedReports } = this.state;
    if (includes(selectedReports, report)) {
      pull(selectedReports, report);
    } else {
      selectedReports.push(report);
    }
    this.setState({ selectedReports });
  }


  onViewSelected() {
    this.props.storeSelectedReportTypes(this.state.selectedReports);
    this.props.goToReportsView();
    this.setState({ isOpen: false, selectedReports: [] });
  }


  isReportSelected(report) {
    const { selectedReports } = this.state;
    return includes(selectedReports, report);
  }


  renderReportDropdown() {
    const { isOpen } = this.state;
    if (!isOpen) {
      return null;
    }

    return (
      <OutsideClickHandler
        onOutsideClick={
          () => {
            this.setState({ isOpen: false, selectedReports: [] });
          }
        }
      >
        <div className={styles.topBar__generateReportDropdown}>
          <div className={styles.reportSelector__dropDownContent}>
            <button
              className={
                cn(styles.reportSelector__button, {
                  [styles['reportSelector__button--isActive']]: this.isReportSelected('basicPrint'),
                })
              }
              type="button"
              onClick={() => this.onReportClick('basicPrint')}
            >
              <div className={styles.reportSelector__checkbox}>
                <CheckboxRadio
                  id="basicPrint"
                  inputValue="basicPrint"
                  value={
                    this.isReportSelected('basicPrint')
                      ? 'basicPrint'
                      : ''
                  }
                  onChange={() => null}
                />
              </div>
              <div className={styles.reportSelector__icon}>
                <PdfFileIcon />
              </div>
              <div className={styles.reportSelector__label}>
                <FormattedMessage {...PatientResults.messages.buttons.standardDashboardReport} />
              </div>
            </button>
            <button
              className={
                cn(styles.reportSelector__button, {
                  [styles['reportSelector__button--isActive']]: this.isReportSelected('bloodGlucoseConcentrationPrint'),
                })
              }
              type="button"
              onClick={() => this.onReportClick('bloodGlucoseConcentrationPrint')}
            >
              <div className={styles.reportSelector__checkbox}>
                <CheckboxRadio
                  id="bloodGlucoseConcentrationPrint"
                  inputValue="bloodGlucoseConcentrationPrint"
                  value={
                    this.isReportSelected('bloodGlucoseConcentrationPrint')
                      ? 'bloodGlucoseConcentrationPrint'
                      : ''
                  }
                  onChange={() => null}
                />
              </div>
              <div className={styles.reportSelector__icon}>
                <PdfFileIcon />
              </div>
              <div className={styles.reportSelector__label}>
                <FormattedMessage {...PatientResults.messages.buttons.bloodSugarReport} />
              </div>
            </button>
            <button
              className={
                cn(styles.reportSelector__button, {
                  [styles['reportSelector__button--isActive']]: this.isReportSelected('dailyLogPrint'),
                })
              }
              type="button"
              onClick={() => this.onReportClick('dailyLogPrint')}
            >
              <div className={styles.reportSelector__checkbox}>
                <CheckboxRadio
                  id="dailyLogPrint"
                  inputValue="dailyLogPrint"
                  value={
                    this.isReportSelected('dailyLogPrint')
                      ? 'dailyLogPrint'
                      : ''
                  }
                  onChange={() => null}
                />
              </div>
              <div className={styles.reportSelector__icon}>
                <PdfFileIcon />
              </div>
              <div className={styles.reportSelector__label}>
                <FormattedMessage {...PatientResults.messages.buttons.bloodSugarDiaryReport} />
              </div>
            </button>
            <button
              className={
                cn(styles.reportSelector__button, {
                  [styles['reportSelector__button--isActive']]: this.isReportSelected('agpBgmPrint'),
                })
              }
              type="button"
              onClick={() => this.onReportClick('agpBgmPrint')}
            >
              <div className={styles.reportSelector__checkbox}>
                <CheckboxRadio
                  id="agpBgmPrint"
                  inputValue="agpBgmPrint"
                  value={
                    this.isReportSelected('agpBgmPrint')
                      ? 'agpBgmPrint'
                      : ''
                  }
                  onChange={() => null}
                />
              </div>
              <div className={styles.reportSelector__icon}>
                <PdfFileIcon />
              </div>
              <div className={styles.reportSelector__label}>
                <FormattedMessage {...PatientResults.messages.buttons.AgpBgmDashboardReport} />
              </div>
            </button>
            <button
              className={
                cn(styles.reportSelector__button, {
                  [styles['reportSelector__button--isActive']]: this.isReportSelected('patientsBgDataReportPrint'),
                })
              }
              type="button"
              onClick={() => this.onReportClick('patientsBgDataReportPrint')}
            >
              <div className={styles.reportSelector__checkbox}>
                <CheckboxRadio
                  id="patientsBgDataReportPrint"
                  inputValue="patientsBgDataReportPrint"
                  value={
                    this.isReportSelected('patientsBgDataReportPrint')
                      ? 'patientsBgDataReportPrint'
                      : ''
                  }
                  onChange={() => null}
                />
              </div>
              <div className={styles.reportSelector__icon}>
                <CsvFileIcon />
              </div>
              <div className={styles.reportSelector__label}>
                <FormattedMessage {...PatientResults.messages.buttons.PatientsBgData} />
              </div>
            </button>
            <button
              className={
                cn(styles.reportSelector__button, {
                  [styles['reportSelector__button--isActive']]: this.isReportSelected('patientsGestationalReportPrint'),
                })
              }
              type="button"
              onClick={() => this.onReportClick('patientsGestationalReportPrint')}
            >
              <div className={styles.reportSelector__checkbox}>
                <CheckboxRadio
                  id="patientsGestationalReportPrint"
                  inputValue="patientsGestationalReportPrint"
                  value={
                    this.isReportSelected('patientsGestationalReportPrint')
                      ? 'patientsGestationalReportPrint'
                      : ''
                  }
                  onChange={() => null}
                />
              </div>
              <div className={styles.reportSelector__icon}>
                <PdfFileIcon />
              </div>
              <div className={styles.reportSelector__label}>
                <FormattedMessage {...PatientResults.messages.headers.patientsGestationalReport} />
              </div>
            </button>
            <button
              className={
                cn(styles.reportSelector__viewSelectedButton, {
                  [styles['reportSelector__viewSelectedButton--disabled']]: isEmpty(this.state.selectedReports),
                })
              }
              type="button"
              onClick={() => this.onViewSelected()}
            >
              <FormattedMessage {...PatientResults.messages.buttons.viewSelected} />
              <OpenInNewIcon />
            </button>
          </div>
        </div>
      </OutsideClickHandler>
    );
  }


  render() {
    const { isInProgress } = this.props;
    return (
      <div className={styles.topBar__generateReport}>
        <Button
          styleModifier="transparent"
          className={
            cn(
              styles.topBar__button,
              styles['topBar__button--right'],
              styles['topBar__button--active'],
            )
          }
          isDisabled={isInProgress}
          onClick={() => this.setState({ isOpen: true })}
        >
          <Download />
          <FormattedMessage {...PatientResults.messages.buttons.generateReport} />
          <ChevronRight className={styles.chevronIcon} />
        </Button>
        { this.renderReportDropdown() }
      </div>
    );
  }

}

export default withStyles(styles)(ReportButton);
