import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cn from 'classnames';
import map from 'lodash/map';
import get from 'lodash/get';
import DropdownIcon from 'svg/dropdown.svg';
import App from 'modules/App';
import Modal from 'components/Modal';
import Button from 'components/Form/Button';
import Account from 'modules/Account';
import MetricConversions from 'libs/MetricConversions';
import * as constants from '../../constants';
import messages from '../../messages';
import { getEmptyResult, getLabelForResultType, getResultsOfType } from '../../helpers';
import Hcp from '../../../Hcp';
import styles from './AdditionalMeasurementsModal.pcss';


class AdditionalMeasurementsModal extends React.PureComponent {

  static propTypes = {
    // Explicit props
    // Implicit props
    measurements: PropTypes.array,
    openModalId : PropTypes.string,
    metricsUnits: PropTypes.object,
    // Implicit actions
    onCloseModal: PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.metricsConversions = new MetricConversions(props.metricsUnits);
    this.state = {
      [constants.RESULT_TYPE.SYSTOLIC_BLOOD_PRESSURE] : { isExpanded: false },
      [constants.RESULT_TYPE.DIASTOLIC_BLOOD_PRESSURE]: { isExpanded: false },
      [constants.RESULT_TYPE.LDL]                     : { isExpanded: false },
      [constants.RESULT_TYPE.TRIGLYCERIDES]           : { isExpanded: false },
      [constants.RESULT_TYPE.BMI]                     : { isExpanded: false },
    };
  }


  onDropdownClick(type) {
    const isExpanded = get(this.state, `${type}.isExpanded`);
    this.setState({ [type]: { isExpanded: !isExpanded } });
  }


  renderTableHeaders() {
    return (
      <thead>
        <tr className={styles.additionalMeasurementsModal__tableHead}>
          <th><FormattedMessage {...messages.labels.name} /></th>
          <th><FormattedMessage {...messages.labels.latestValue} /></th>
          <th><FormattedMessage {...messages.labels.date} /></th>
          <th><FormattedMessage {...messages.labels.time} /></th>
          <th />
        </tr>
      </thead>
    );
  }


  renderSecondaryTableRow(result, type) {
    const { value, unit, date, time } = result;
    const isExpanded = get(this.state, `${type}.isExpanded`);

    return (
      <tr
        key={`${date}-${time}-${value}-${unit}`}
        className={cn(
          styles.additionalMeasurementsModal__secondaryTableRow,
          { [styles['additionalMeasurementsModal__secondaryTableRow--expanded']]: isExpanded },
        )}
      >
        <td />
        <td>{ value } { unit }</td>
        <td>{ date }</td>
        <td>{ time }</td>
        <td />
      </tr>
    );
  }


  renderSecondaryTableRows(results, type) {
    return map(results, (result) => this.renderSecondaryTableRow(result, type));
  }


  renderPrimaryTableRow(type) {
    const results = getResultsOfType(this.props.measurements, type, this.metricsConversions);
    const [latestResult, ...moreResults] = results;
    const { value, unit, date, time } = latestResult || getEmptyResult();
    const isExpanded = get(this.state, `${type}.isExpanded`);

    return (
      <>
        <tr
          key={type}
          className={cn(
            styles.additionalMeasurementsModal__primaryTableRow,
            { [styles['additionalMeasurementsModal__primaryTableRow--expanded']]: isExpanded }
          )}
        >
          <td>{ getLabelForResultType(type) }</td>
          <td className={styles.additionalMeasurementsModal__latestValueWrapper}>
            <div className={styles.additionalMeasurementsModal__latestValue}>
              { value } { unit }
            </div>
          </td>
          <td>{ date }</td>
          <td>{ time }</td>
          <td>
            <DropdownIcon
              className={cn(
                styles.additionalMeasurementsModal__dropdown,
                {
                  [styles['additionalMeasurementsModal__dropdown--expanded']]: isExpanded,
                  [styles['additionalMeasurementsModal__dropdown--hidden']]  : !moreResults.length,
                }
              )}
              onClick={() => this.onDropdownClick(type)}
            />
          </td>
        </tr>
        { this.renderSecondaryTableRows(moreResults, type) }
      </>
    );
  }


  renderTable() {
    return (
      <div className={styles.additionalMeasurementsModal__tableWrapper}>
        <table className={styles.additionalMeasurementsModal__table}>
          { this.renderTableHeaders() }
          <tbody className={styles.additionalMeasurementsModal__tableBody}>
            { this.renderPrimaryTableRow(constants.RESULT_TYPE.SYSTOLIC_BLOOD_PRESSURE) }
            { this.renderPrimaryTableRow(constants.RESULT_TYPE.DIASTOLIC_BLOOD_PRESSURE) }
            { this.renderPrimaryTableRow(constants.RESULT_TYPE.LDL) }
            { this.renderPrimaryTableRow(constants.RESULT_TYPE.TRIGLYCERIDES) }
            { this.renderPrimaryTableRow(constants.RESULT_TYPE.BMI) }
          </tbody>
        </table>
      </div>
    );
  }


  render() {
    return (
      <Modal
        modalId={constants.ADDITIONAL_MEASUREMENTS_MODAL}
        openModalId={this.props.openModalId}
        styleModifier="lg"
        onClose={() => this.props.onCloseModal()}
        className={styles.additionalMeasurementsModal}
      >
        <h1 className="modal__header">
          <FormattedMessage {...messages.headers.additionalMeasurements} />
        </h1>
        <div className="">
          <FormattedMessage {...messages.infos.viewModeOnly} />
        </div>
        { this.renderTable() }
        <Button
          className={cn(
            'btn--primary',
            'btn--filled',
            styles.additionalMeasurementsModal__closeButton,
          )}
          labelMessage={messages.buttons.close}
          onClick={this.props.onCloseModal}
        />
      </Modal>
    );
  }

}


const mapStateToProps = () => (state, props) => ({
  openModalId : App.selectors.modal(state, props),
  measurements: Hcp.selectors.measurements(state),
  metricsUnits: Account.selectors.metricsUnits(state),
});


const mapDispatchToProps = (dispatch) => ({
  onOpenModal : () => dispatch(App.actions.openModal(constants.ADDITIONAL_MEASUREMENTS_MODAL)),
  onCloseModal: () => dispatch(App.actions.closeModal()),
});


const ConnectedAdditionalMeasurementsModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdditionalMeasurementsModal);


export default withStyles(styles)(ConnectedAdditionalMeasurementsModal);
