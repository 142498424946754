import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import messages from 'modules/PatientResults/messages';
import styles from './TimeInRanges.pcss';


class TimeInRangesChart extends React.PureComponent {

  static propTypes = {
    // Explicit props
    conversion  : PropTypes.object.isRequired,
    timeInRanges: PropTypes.arrayOf(PropTypes.shape({
      name   : PropTypes.string.isRequired,
      minutes: PropTypes.number.isRequired,
      percent: PropTypes.number.isRequired,
      range  : PropTypes.string.isRequired,
    })),
    isCgmView: PropTypes.bool,
  };

  renderNameLabel(timeInRange) {
    if (this.props.isCgmView) {
      return <>{timeInRange.range} {this.props.conversion.unitSymbol}</>;

    }
    return (
      messages.labels[timeInRange.name] && <FormattedMessage {...messages.labels[timeInRange.name]} />
    );
  }

  renderTimeLabel(minutes) {
    return (
      <>
        {!!Math.floor(minutes / 60)
          && (
            <>
              {Math.floor(minutes / 60)}
              {
                this.isCgmView
                  ? <FormattedMessage {...messages.labels.hours} />
                  : <FormattedMessage {...messages.labels.h} />
              }
            </>
          )
        }
        &nbsp;
        {!!(minutes % 60)
          && (
            <>
              {Math.round(minutes % 60)}
              {
                this.isCgmView
                  ? <FormattedMessage {...messages.labels.minutes} />
                  : <FormattedMessage {...messages.labels.min} />
              }
            </>
          )
        }
      </>
    );
  }

  render() {
    return (
      <div className={styles.timeInRangesChart}>
        <div className="wrapper">
          <div className="nameLegend">
            {
              this.props.timeInRanges.map((timeInRange) => (
                <div
                  className="nameLegend__section"
                  style={{ height: `${timeInRange.percent || 0}%` }}
                  key={timeInRange.name}
                >
                  <div className="nameLegend__sectionLabels">
                    <span className="nameLegend__sectionLabelName">
                      {
                        this.renderNameLabel(timeInRange)
                      }
                    </span>
                    <span className="nameLegend__sectionLabelRange">
                      {
                        this.props.isCgmView
                          ? this.renderTimeLabel(timeInRange.minutes)
                          : <>{timeInRange.range} {this.props.conversion.unitSymbol}</>
                      }
                    </span>
                  </div>
                  <div className="nameLegend__divider" />
                </div>
              ))
            }
          </div>
          <div className="barChart">
            {
            this.props.timeInRanges.map((timeInRange) => (
              <div
                className={
                    cn(
                      'barChart__section',
                      `barChart__section--${timeInRange.name}`,
                      { 'barChart__section--transparent': !timeInRange.percent },
                    )}
                style={{ height: `${timeInRange.percent || 0}%` }}
                key={timeInRange.name}
              />
            ))
          }
          </div>
          <div className="percentLegend">
            {
            this.props.timeInRanges.map((timeInRange) => (
              <div
                className="percentLegend__section"
                style={{ height: `${timeInRange.percent || 0}%` }}
                key={timeInRange.name}
              >
                <div className="percentLegend__sectionPercentValue">
                  {timeInRange.percent ? `${timeInRange.percent}%` : ''}
                </div>
                <div className="percentLegend__sectionPercentTime">
                  {!this.props.isCgmView && this.renderTimeLabel(timeInRange.minutes)}
                </div>
              </div>
            ))
          }
          </div>
        </div>
      </div>
    );
  }

}


export default withStyles(styles)(TimeInRangesChart);
