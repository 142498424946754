import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import Modal from 'components/Modal';
import countrySettingsShape from 'shapes/countrySettingsShape';
import App from 'modules/App';
import Account from 'modules/Account';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import AddPatientForm from '../../partials/AddPatientForm';
import messages from '../../messages';


class InvitePatientModal extends React.PureComponent {

  static propTypes = {
    // Implicit props
    activePatient: PropTypes.shape({
      countryId: PropTypes.number.isRequired,
    }),
    countrySettings                  : countrySettingsShape,
    isHcpAccount                     : PropTypes.bool,
    openModalId                      : PropTypes.string,
    // Implicit actions
    onFetchPatientCountryLocalization: PropTypes.func,
    onFetchPayers                    : PropTypes.func,
    onCloseModal                     : PropTypes.func,
  };


  onCancel() {
    const countryId = get(this.props.activePatient, 'countryId', '');
    const countrySettingsCountryId = get(this.props.countrySettings, 'countryId', '');
    if (countryId && countryId !== countrySettingsCountryId) {
      this.props.onFetchPatientCountryLocalization(countryId);
      this.props.onFetchPayers(countryId);
    }
    this.props.onCloseModal();
  }


  render() {
    if (!this.props.isHcpAccount) {
      return null;
    }

    return (
      <Modal
        modalId={constants.INVITE_PATIENT_MODAL}
        openModalId={this.props.openModalId}
        styleModifier="md"
        onClose={() => this.onCancel()}
      >
        <h2 className="modal__header"><FormattedMessage {...messages.headers.addNewPatient} /></h2>
        <AddPatientForm
          modalId={constants.INVITE_PATIENT_MODAL}
          onClose={this.props.onCloseModal}
        />
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  activePatient  : selectors.activePatient(state),
  countrySettings: selectors.countrySettings(state),
  isHcpAccount   : Account.selectors.isHcpAccount(state),
  openModalId    : App.selectors.modal(state),
});


const mapDispatchToProps = (dispatch) => ({
  onFetchPatientCountryLocalization: (countryId) => dispatch(actions.fetchPatientCountryLocalization(countryId)),
  onFetchPayers                    : (countryId) => dispatch(actions.fetchPayers(countryId)),
  onCloseModal                     : () => dispatch(App.actions.closeModal()),
});


const ConnectedInvitePatientModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvitePatientModal);


export default ConnectedInvitePatientModal;
