import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Button from 'components/Form/Button';
import Plus from 'svg/plus.svg';
import App from 'modules/App';
import * as constants from '../../constants';
import messages from '../../messages';
import styles from './AddPatientBtn.pcss';


class AddPatientBtn extends React.PureComponent {

  static propTypes = {
    // Explicit props
    className  : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    // Implicit actions
    onOpenModal: PropTypes.func,
  };


  renderButtonContent() {
    const plus = <Plus className={styles.icon} />;
    return <FormattedMessage {...messages.buttons.addPatient} values={{ plus }} />;
  }


  render() {
    return (
      <Button
        styleModifier="transparent"
        className={cn(styles.root, this.props.className)}
        onClick={() => this.props.onOpenModal()}
      >
        { this.renderButtonContent() }
      </Button>
    );
  }

}


const mapDispatchToProps = (dispatch) => ({
  onOpenModal: () => dispatch(App.actions.openModal(constants.INVITE_PATIENT_MODAL)),
});


const ConnectedAddPatientBtn = connect(
  null,
  mapDispatchToProps,
)(AddPatientBtn);


export default withStyles(styles)(ConnectedAddPatientBtn);
