import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import App from 'modules/App';
import Select from 'components/Form/Select';
import messages from 'modules/App/messages';
import styles from './PerPageSelect.pcss';


class PerPageSelect extends React.PureComponent {

  static propTypes = {
    value   : PropTypes.number,
    onChange: PropTypes.func,
  };


  static defaultProps = {
    onChange: () => {},
    value   : null,
  };


  constructor() {
    super();
    this.options = [
      { label: '10', value: 10 },
      { label: '25', value: 25 },
      { label: '50', value: 50 },
    ];
  }


  render() {
    const { onChange, value } = this.props;
    return (
      <div className={styles.perPageSelect}>
        <label className="label" htmlFor="perPageSelect">
          <FormattedMessage {...messages.labels.rowsPerPage} />:
        </label>
        <Select
          className="perPageSelect"
          id="perPageSelect"
          noValueMessage={App.messages.placeholders.empty}
          optionsFrom={this.options}
          valueKey="value"
          labelKey="label"
          onChange={({ value: val }) => onChange(val)}
          value={value}
        />
      </div>
    );

  }

}

export default withStyles(styles)(PerPageSelect);
