import PropTypes from 'prop-types';


export const previewShape = PropTypes.shape({
  days: PropTypes.arrayOf(PropTypes.shape({
    date     : PropTypes.string,
    day      : PropTypes.string,
    month    : PropTypes.string,
    year     : PropTypes.string,
    monthName: PropTypes.string,
    weekKey  : PropTypes.string,
    weekday  : PropTypes.string,
  })),
  weeks: PropTypes.arrayOf(PropTypes.shape({
    weekKey: PropTypes.string,
    weekNo : PropTypes.string,
    year   : PropTypes.string,
    months : PropTypes.arrayOf(PropTypes.string),
    month  : PropTypes.string,
    dates  : PropTypes.arrayOf(PropTypes.object),
  })),
  months: PropTypes.arrayOf(PropTypes.shape({
    monthKey : PropTypes.string,
    month    : PropTypes.string,
    monthName: PropTypes.string,
    year     : PropTypes.string,
    dates    : PropTypes.arrayOf(PropTypes.object),
  })),
});


export const binShape = PropTypes.shape({
  percentileStatistics: PropTypes.shape({
    5 : PropTypes.number,
    25: PropTypes.number,
    50: PropTypes.number,
    75: PropTypes.number,
    95: PropTypes.number,
  }),
  hour   : PropTypes.string,
  records: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
  })),
});

export const highlightedRecordsShape = PropTypes.arrayOf(PropTypes.shape({
  value    : PropTypes.number,
  timestamp: PropTypes.number,
  date     : PropTypes.object,
}));

export const highlightedHourlyRecordsShape = PropTypes.shape({
  records: PropTypes.arrayOf(binShape),
});


export const dailyRecordsShape = PropTypes.arrayOf(
  PropTypes.shape({
    date   : PropTypes.string,
    weekday: PropTypes.string,
  })
);

export const weeklyRecordsShape = PropTypes.arrayOf(
  PropTypes.shape({
    week   : PropTypes.string,
    weekNo : PropTypes.string,
    weekKey: PropTypes.string,
    year   : PropTypes.string,
    months : PropTypes.arrayOf(PropTypes.string),
    dates  : PropTypes.arrayOf(PropTypes.object),
  })
);


export const monthlyRecordsShape = PropTypes.arrayOf(
  PropTypes.shape({
    month   : PropTypes.string,
    monthKey: PropTypes.string,
    monthNo : PropTypes.string,
    year    : PropTypes.string,
    dates   : PropTypes.arrayOf(PropTypes.object),
  })
);

