import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import App from 'modules/App';

import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import Dropdown from 'svg/dropdown.svg';
import * as patientResultsConstants from 'modules/PatientResults/constants';
import messages from '../../messages';
import styles from './KpiSetting.pcss';


class KpiSetting extends React.PureComponent {

  static propTypes = {
    // Explicit props
    formValues     : PropTypes.object,
    isDisabled     : PropTypes.bool,
    type           : PropTypes.string,
    conversion     : PropTypes.object,
    possibleOptions: PropTypes.arrayOf(
      PropTypes.oneOf(['allowedMeasurements', 'valueHigh', 'valueLow'])
    ),
    allowConigurationPerDiabetesType: PropTypes.bool,
    labelMessage                    : PropTypes.object,
    // Explicit actions
    onSetValue                      : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }


  get diabetesTypes() {
    return App.constants.DIABETES_TYPES.filter((diabetesType) =>
      !App.constants.DIABETES_TYPES_HIDE_FOR_HCP.includes(diabetesType)
    );
  }


  renderOption(id, option) {
    const { isDisabled } = this.props;

    return (
      <div key={`container-${id}`}>
        <FormGroup
          id={id}
          labelMessage={messages.labels[option]}
          formValues={this.props.formValues}
        >
          <Input
            placeholder={messages.placeholders[option]}
            onChange={(input) => this.props.onSetValue(input)}
            isDisabled={isDisabled}
          />
        </FormGroup>
      </div>
    );
  }


  renderInfo() {
    const { type, conversion, labelMessage } = this.props;

    if (!labelMessage) return null;

    let target = '';
    if (type === 'veryHigh') {
      target = `${patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].veryHigh} 
      ${conversion.unitSymbol}`;
    }

    if (type === 'veryLow') {
      target = `${patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].low} 
      ${conversion.unitSymbol}`;
    }

    return (
      <FormattedMessage
        {...labelMessage}
        values={{
          target,
        }}
      />
    );
  }


  renderOptions() {
    const { possibleOptions, type, allowConigurationPerDiabetesType } = this.props;
    if (!allowConigurationPerDiabetesType) {
      return (
        <div className="row">
          <div className="col-4">
            {
              possibleOptions.map(
                (option) => (
                  <div key={`kpi-${type}-${option}-wrapper`}>
                    {this.renderOption(`kpi-${type}-${option}`, option)}
                  </div>
                )
              )
            }
          </div>
          <div className={cn('col-8', styles.itemInfo, styles['itemInfo--single'])}>
            { this.renderInfo() }
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        <div className={cn('col-12', styles.itemInfo)}>
          { this.renderInfo() }
        </div>
        <div className={`col-${possibleOptions.length === 1 ? 6 : 12}`}>
          <h2 className={cn('caption', styles.optionHeader)}>
            <FormattedMessage {...messages.headers.kpiDefault} />
          </h2>
          <div className="row">
            {
              possibleOptions.map(
                (option) => (
                  <div key={`kpi-${type}-${option}-wrapper`} className={`col-${possibleOptions.length === 1 ? 12 : 6}`}>
                    {this.renderOption(`kpi-${type}-${option}`, option)}
                  </div>
                )
              )
            }
          </div>
        </div>
        {
          this.diabetesTypes.map((diabetesType) => (
            <div
              className={`col-${possibleOptions.length === 1 ? 6 : 12}`}
              key={`option-${diabetesType}-container`}
            >
              <h2 className={cn('caption', styles.optionHeader)}>
                <FormattedMessage {...App.messages.diabetesTypes[diabetesType]} />
              </h2>
              <div className="row">
                {
                  possibleOptions.map(
                    (option) => (
                      <div
                        key={`kpi-${type}-diabetesType-${diabetesType}-${option}-wrapper`}
                        className={`col-${possibleOptions.length === 1 ? 12 : 6}`}
                      >
                        {this.renderOption(`kpi-${type}-diabetesType-${diabetesType}-${option}`, option)}
                      </div>
                    )
                  )
                }
              </div>
            </div>
          ))
        }
      </div>
    );
  }


  render() {
    const { type } = this.props;
    const { isOpen } = this.state;
    return (
      <div
        className={styles.settingItem_container}
      >
        <div
          className={styles.settingItem_header}
          onClick={() => this.setState({ isOpen: !isOpen })}
          role="presentation"
        >
          <h2
            className={cn('title', styles.title_header)}
          >
            <FormattedMessage {...messages.headers[`kpi${type}`]} />
            <motion.div
              initial={{ rotate: '180deg' }}
              animate={isOpen ? 'open' : 'close'}
              variants={{
                open : { rotate: '180deg' },
                close: { rotate: '0deg' },
              }}
            >
              <Dropdown />
            </motion.div>
          </h2>
        </div>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: isOpen ? 'auto' : 0, opacity: isOpen ? 1 : 0 }}
            transition={{ ease: 'easeOut', duration: 0.15 }}
          >
            { this.renderOptions() }
          </motion.div>
        )}
      </div>
    );
  }

}

export default withStyles(styles)(KpiSetting);
