import { registerAction, unregisterAction } from 'helpers/reducerTools';
import * as actionTypes from './actionTypes';


const initialState = {
  exportName: null,
  progress  : 0,
  fetching  : [],
  sending   : [],
  errors    : [],
};


export default function reducer(state = { ...initialState }, action) {

  switch (action.type) {

    case actionTypes.SET_PROGRESS: {
      const { progress } = action.payload;
      return {
        ...state,
        progress,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.EXPORT_DATA: {
      const { exportName } = action.payload;
      return {
        ...state,
        exportName,
        progress: 0,
        fetching: registerAction(state.fetching, actionTypes.EXPORT_DATA),
        errors  : unregisterAction(state.errors, actionTypes.EXPORT_DATA),
      };
    }
    case actionTypes.EXPORT_DATA_CANCEL: {
      return {
        ...state,
        exportName: null,
        progress  : 0,
        fetching  : unregisterAction(state.fetching, actionTypes.EXPORT_DATA),
      };
    }
    case actionTypes.EXPORT_DATA_SUCCESS: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.EXPORT_DATA),
      };
    }
    case actionTypes.EXPORT_DATA_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.EXPORT_DATA),
        errors  : registerAction(state.errors, actionTypes.EXPORT_DATA),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    default: {
      return state;
    }

  }
}
