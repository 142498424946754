import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/Modal';
import ProgressRing from 'components/ProgressRing';
import ImportIcon from 'svg/import.svg';
import App from 'modules/App';
import Button from 'components/Form/Button';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';


class ExportDataModal extends React.PureComponent {

  static propTypes = {
    // Implicit props
    exportName  : PropTypes.string,
    progress    : PropTypes.number,
    openModalId : PropTypes.string,
    isInProgress: PropTypes.bool,
    hasErrors   : PropTypes.bool,
    // Implicit actions
    onCloseModal: PropTypes.func,
  };


  componentDidUpdate(prevProps) {
    const { isInProgress, hasErrors } = this.props;
    if (prevProps.isInProgress !== isInProgress && !isInProgress && !hasErrors) {
      this.onClose();
    }
  }


  onClose() {
    this.props.onCloseModal();
  }


  renderActions() {
    return (
      <Button
        styleModifier="quaternary"
        labelMessage={App.messages.buttons.cancel}
        className="btn--block mt-7"
        onClick={() => this.onClose()}
      />
    );
  }


  renderProgress() {
    return (
      <div className="d-flex justify-content-center w-100 mb-5">
        <ProgressRing
          radius={120}
          stroke={15}
          progress={this.props.progress}
          isInProgress={this.props.isInProgress}
          StartIcon={ImportIcon}
        />
      </div>
    );
  }


  render() {
    return (
      <Modal
        modalId={constants.EXPORT_DATA_MODAL}
        openModalId={this.props.openModalId}
        headerMessage={messages.buttons[this.props.exportName]}
        isInProgress={this.props.isInProgress}
        onClose={() => this.onClose()}
      >
        <h3 className="modal__info mb-5">
          <FormattedMessage {...messages.infos.exportDataModal} />
        </h3>
        {this.renderProgress()}
        {this.renderActions()}
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  exportName  : selectors.exportName(state),
  progress    : selectors.progress(state),
  isInProgress: selectors.isExportDataInProgress(state),
  hasErrors   : selectors.hasExportDataErrors(state),
  openModalId : App.selectors.modal(state),
});


const mapDispatchToProps = (dispatch) => ({
  onCloseModal: () => Promise.all([
    dispatch(App.actions.closeModal()),
    dispatch(actions.exportDataCancel()),
  ]),
});


const ConnectedExportDataModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExportDataModal);


export default ConnectedExportDataModal;

