import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';
import * as shapes from './shapes';
import reducer from './reducer';
import sagas from './sagas';
import messages from './messages';

import ClinicClearData from './components/ClinicClearData';
import ClinicSettings from './components/ClinicSettings';
import AttachCloudDrive from './components/AttachCloudDrive';
import KpiSetting from './components/KpiSetting';

import ClinicSettingsIconLink from './partials/ClinicSettingsIconLink';


const components = {
  ClinicClearData,
  ClinicSettings,
  AttachCloudDrive,
  KpiSetting,
};


const partials = {
  ClinicSettingsIconLink,
};


export default {
  actions,
  components,
  constants,
  partials,
  reducer,
  sagas,
  selectors,
  shapes,
  messages,
};
