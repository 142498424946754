import { put, takeLatest, call, select, race, take } from 'redux-saga/effects';
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import flatMap from 'lodash/flatMap';
import moment from 'moment';
import AgpAggregator from 'libs/AgpAggregator';
import App from 'modules/App';
import Patient from 'modules/Patient';
import Hcp from 'modules/Hcp';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import {
  SET_READINGS as PATIENT_SET_READINGS,
  SET_IMPORTED_READINGS as PATIENT_SET_IMPORTED_READINGS,
  SET_PHI_SET as PATIENT_SET_PHI_SET,
} from 'modules/Patient/actionTypes';
import {
  SET_READINGS as HCP_SET_READINGS,
  SET_IMPORTED_READINGS as HCP_SET_IMPORTED_READINGS,
  SET_PHI_SET as HCP_SET_PHI_SET,
} from 'modules/Hcp/actionTypes';
import { FETCH_AGGREGATES_ERROR, FETCH_AGGREGATES_SUCCESS } from 'modules/CloudDrive/actionTypes';
import * as actions from './actions';
import * as selectors from './selectors';
import * as constants from './constants';
import {
  SET_DAYS_SELECTOR_ZOOM_SMART_PAGE,
  SET_DAYS_SELECTOR_ZOOM,
  SET_DAYS_SELECTOR_PAGE,
  SET_AGP_MONTH_SELECTOR_PAGE,
  PREPARE_AGP_PREVIEW,
} from './actionTypes';


function* aggregateAgpData() {
  try {
    const isHcpAccount = yield select(Account.selectors.isHcpAccount);
    const readings = isHcpAccount
      ? yield select(Hcp.selectors.readings)
      : yield select(Patient.selectors.readings);
    const dailyRecords = AgpAggregator.aggregateRawReadings(readings);
    yield put(actions.setAgpDailyRecords(dailyRecords));
  } catch (err) {
    yield call(App.dispatchError, err);
  }
}


function* setDaysSelectorZoomSmartPage({ payload }) {
  const cachedDaysSelectorTotalPages = yield select(selectors.daysSelectorTotalPages);
  const cachedDaysSelectorPage = yield select(selectors.daysSelectorPage);
  const progress = (cachedDaysSelectorPage + 0.25) / cachedDaysSelectorTotalPages;
  yield put(actions.setDaysSelectorZoom(payload.zoom));
  const newDaysSelectorTotalPages = yield select(selectors.daysSelectorTotalPages);
  const newDaysSelectorPage = Math.floor(newDaysSelectorTotalPages * progress);
  if (newDaysSelectorPage < 0) {
    yield put(actions.setDaysSelectorPage(0));
  } else if (newDaysSelectorPage > newDaysSelectorTotalPages) {
    yield put(actions.setDaysSelectorPage(newDaysSelectorTotalPages));
  } else {
    yield put(actions.setDaysSelectorPage(newDaysSelectorPage));
  }
  const daysSelectorTotalPages = yield select(selectors.daysSelectorTotalPages);
  yield put(actions.setDaysSelectorPage(daysSelectorTotalPages - 1));
}


function* setAggregatedPreview({ payload }) {
  const minReadingsTimestamp = get(payload, 'phiSet.summaryData.minReadingsTimestamp');
  const maxReadingsTimestamp = get(payload, 'phiSet.summaryData.maxReadingsTimestamp');
  if (!minReadingsTimestamp) {
    return;
  }
  const current = moment.unix(minReadingsTimestamp);
  const end = moment.unix(maxReadingsTimestamp).add(1, 'day');
  const days = [];
  while (current.weekday() !== AgpAggregator.FIRST_WEEKDAY) {
    current.subtract(1, 'days');
  }
  while (end.weekday() !== AgpAggregator.FIRST_WEEKDAY) {
    end.add(1, 'days');
  }
  if (end.diff(current, 'days') % 14 === 6) {
    end.add(1, 'week');
  }
  while (current <= end) {
    days.push({
      date     : current.format('YYYY-MM-DD'),
      day      : current.format('DD'),
      month    : current.format('MM'),
      year     : current.format('YYYY'),
      monthName: current.format('MMMM'),
      weekday  : current.format('dddd'),
      weekKey  : `${current.format('YYYY')}-w${String(current.format('WW')).padStart(2, '0')}`,
      monthKey : `${current.format('YYYY')}-${String(current.format('MM')).padStart(2, '0')}`,
    });
    current.add(1, 'days');
  }
  const weeks = Object.entries(groupBy(days, 'weekKey')).map((([weekKey, daysList]) => ({
    weekKey,
    weekNo: weekKey.split('-w')[1],
    year  : weekKey.split('-w')[0],
    months: uniq(daysList.map(({ monthKey }) => monthKey)),
    month : daysList[0] ? daysList[0].month : null,
    dates : daysList,
  })));
  const months = Object.entries(groupBy(days, 'monthKey')).map((([monthKey, daysList]) => ({
    monthKey,
    month    : monthKey.split('-')[1],
    monthName: daysList[0] ? daysList[0].monthName : '',
    year     : monthKey.split('-')[0],
    dates    : daysList,
  })));
  yield put(actions.setPreview({
    days,
    weeks,
    months,
  }));
  yield put(actions.setDaysSelectorZoom(constants.DAYS_SELECTOR_ZOOM.day));
  const daysSelectorTotalPages = yield select(selectors.daysSelectorTotalPages);
  yield put(actions.setDaysSelectorPage(daysSelectorTotalPages - 1));
}


function* prepareAgpPreview({ payload }) {
  const { activePatient, phiSet, phiSetDocumentId, successAction } = payload;
  const minReadingsTimestamp = get(phiSet, 'summaryData.minReadingsTimestamp');
  const maxReadingsTimestamp = get(phiSet, 'summaryData.maxReadingsTimestamp');
  if (!minReadingsTimestamp) {
    return;
  }
  const current = moment.unix(minReadingsTimestamp).startOf('month');
  const end = moment.unix(maxReadingsTimestamp).endOf('month');

  const days = [];

  while (current <= end) {
    days.push({
      date     : current.format('YYYY-MM-DD'),
      day      : current.format('DD'),
      month    : current.format('MM'),
      year     : current.format('YYYY'),
      monthName: current.format('MMMM'),
      weekday  : current.format('dddd'),
      weekKey  : `${current.format('YYYY')}-w${String(current.format('WW')).padStart(2, '0')}`,
      monthKey : `${current.format('YYYY')}-${String(current.format('MM')).padStart(2, '0')}`,
    });
    current.add(1, 'days');
  }
  const months = Object.entries(groupBy(days, 'monthKey')).map((([monthKey, daysList]) => ({
    monthKey,
    month    : monthKey.split('-')[1],
    monthName: daysList[0] ? daysList[0].monthName : '',
    year     : monthKey.split('-')[0],
    dates    : daysList,
  })));
  yield put(actions.setAgpPreview({
    days,
    months,
  }));
  yield put(actions.setAgpMonthSelectorPage(months.length - 1, activePatient, phiSet, phiSetDocumentId, successAction));
}


function* getAggregations() {
  const fetchedAggregationDocumentsIds = yield select(selectors.aggregationDocumentsIds);
  const activeProfileType = yield select(Account.selectors.activeProfileType);
  let props;
  switch (activeProfileType) {
    case Account.constants.PROFILE_TYPES.HCP: {
      const activePatient = yield select(Hcp.selectors.activePatient);
      const phiSet = yield select(Hcp.selectors.phiSet);
      const { accessToken, storageProvider } = activePatient;
      props = {
        phiSet,
        accessToken,
        storageProvider,
      };
      break;
    }
    case Account.constants.PROFILE_TYPES.PWD: {
      const activePatient = yield select(Account.selectors.patientProfile);
      const phiSet = yield select(Patient.selectors.phiSet);
      const { accessToken, storageProvider } = activePatient;
      props = {
        phiSet,
        accessToken,
        storageProvider,
      };
      break;
    }
    default:
      return;
  }
  const level = yield select(selectors.daysSelectorZoom);
  switch (level) {
    case constants.DAYS_SELECTOR_ZOOM.day: {
      const dailyRecordsPaginated = yield select(selectors.dailyRecordsPaginated);
      if (!dailyRecordsPaginated) return;
      props.months = uniq(dailyRecordsPaginated.map(({ monthKey }) => monthKey));
      break;
    }
    case constants.DAYS_SELECTOR_ZOOM.week: {
      const weeklyRecordsPaginated = yield select(selectors.weeklyRecordsPaginated);
      if (!weeklyRecordsPaginated) return;
      props.months = uniq(flatMap(weeklyRecordsPaginated, ({ months }) => months));
      break;
    }
    case constants.DAYS_SELECTOR_ZOOM.month: {
      const monthlyRecordsPaginated = yield select(selectors.monthlyRecordsPaginated);
      if (!monthlyRecordsPaginated) return;
      props.months = monthlyRecordsPaginated.map(({ monthKey }) => monthKey);
      break;
    }
    default: return;
  }
  props.level = level;
  props.fetchedAggregationDocumentsIds = fetchedAggregationDocumentsIds;
  yield put(CloudDrive.actions.fetchAggregates(props));
  const [
    { payload: { aggregationsBatches, aggregationDocumentsIds } } = {},
    err,
  ] = yield race([
    take(FETCH_AGGREGATES_SUCCESS),
    take(FETCH_AGGREGATES_ERROR),
  ]);
  if (err || !aggregationDocumentsIds.length || !aggregationsBatches) return;
  yield put(actions.appendAggregations({ aggregations: Object.values(aggregationsBatches), aggregationDocumentsIds }));
}


function* getReadingsForDgp({ payload }) {
  const { page, activePatient, phiSet, phiSetDocumentId, successAction } = payload;
  const dailyRecordsPaginatedByMonth = yield select(selectors.dailyRecordsPaginatedByMonth);
  const agpPreview = yield select(selectors.agpPreview);
  if (!dailyRecordsPaginatedByMonth) return;

  const { accessToken, storageProvider, phiSetReferenceKey } = activePatient;

  const batchesIndex = yield select(Patient.selectors.batchesIndex);
  const cgmBatchesIndex = yield select(Patient.selectors.cgmBatchesIndex);

  const data = {
    batchesIndex,
    cgmBatchesIndex,
    accessToken,
    storageProvider,
    phiSetReferenceKey,
    phiSet,
    phiSetDocumentId,
    successAction,
  };

  if (page >= agpPreview.months.length) {
    return;
  }

  if (data.batchesIndex.includes(agpPreview.months[page].monthKey)) {
    return;
  }

  data.startDate = moment(agpPreview.months[page].dates[0].date).startOf('day');
  data.endDate = moment(agpPreview.months[page].dates[agpPreview.months[page].dates.length - 1].date).endOf('day');

  yield put(CloudDrive.actions.fetchReadings(data));
}


function* sagas() {
  yield takeLatest(PATIENT_SET_READINGS, aggregateAgpData);
  yield takeLatest(PATIENT_SET_IMPORTED_READINGS, aggregateAgpData);
  yield takeLatest(HCP_SET_READINGS, aggregateAgpData);
  yield takeLatest([HCP_SET_PHI_SET, PATIENT_SET_PHI_SET, HCP_SET_IMPORTED_READINGS, PATIENT_SET_IMPORTED_READINGS], setAggregatedPreview);
  yield takeLatest(PREPARE_AGP_PREVIEW, prepareAgpPreview);
  yield takeLatest(HCP_SET_IMPORTED_READINGS, aggregateAgpData);
  yield takeLatest(SET_DAYS_SELECTOR_ZOOM_SMART_PAGE, setDaysSelectorZoomSmartPage);
  yield takeLatest(SET_DAYS_SELECTOR_ZOOM_SMART_PAGE, setDaysSelectorZoomSmartPage);
  yield takeLatest([SET_DAYS_SELECTOR_ZOOM, SET_DAYS_SELECTOR_PAGE], getAggregations);
  yield takeLatest([SET_AGP_MONTH_SELECTOR_PAGE], getReadingsForDgp);
}


export default [
  sagas,
];
