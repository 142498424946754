import get from 'lodash/get';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import Cookies from 'universal-cookie';
import { ApiError } from 'helpers/errorTypes';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 * @param  {object} options  The fetch options
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response, options) {
  const json = response.text().then((text) => (text ? JSON.parse(text) : {}));
  if (response.status >= 200 && response.status < 300) {
    return json;
  }
  return json.then((resp) => {
    throw new ApiError({
      url             : get(response, 'url'),
      status          : get(response, 'status'),
      validationErrors: get(resp, 'validationErrors'),
      businessError   : get(resp, 'businessError') || get(resp, 'message'),
      requestId       : get(resp, 'requestId'),
      options         : omit(pick(options, ['method', 'headers']), 'headers.Authorization'),
    });
  });
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {function} fetch    Fetch function
 * @param  {string} url        The URL we want to request
 * @param  {object} [options]  The options we want to pass to "fetch"
 * @param  {string} [jwtToken] Token we want to use to authorize request instead of the one from cookie
 *
 * @return {object}           The response data
 */
export default function request(fetch, url, options, jwtToken) {

  const cookies = new Cookies();
  const token = jwtToken || cookies.get('token');

  options = {
    ...options,
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : null),
      ...get(options, 'headers', {}),
    },
  };

  return fetch(url, options)
  // .then(checkStatus)
    .then(
      (response) => parseJSON(response, options),
      (err) => {
        throw new ApiError({
          url,
          status          : 503,
          error           : `${err}`,
          validationErrors: {},
          options         : omit(pick(options, ['method', 'headers']), 'headers.Authorization'),
        });
      }
    );
}
