import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Account from 'modules/Account';
// eslint-disable-next-line import/no-cycle
import Header from './Header';
// eslint-disable-next-line import/no-cycle
import Table from './Table';
import styles from './PatientGestationalReport.pcss';


class PatientGestationalReport extends React.PureComponent {

  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    readings             : PropTypes.array,
    phiSet               : PropTypes.object, // @TODO: shape
    accountPatientProfile: PropTypes.object, // @TODO: Shape
    reportsDates         : PropTypes.object,
  };


  render() {
    return (
      <div className={styles.patientsGestationalReport} id="gestationalReportPrint">
        <Header
          phiSet={this.props.phiSet}
          accountPatientProfile={this.props.accountPatientProfile}
          standards={this.props.standards}
          conversion={this.props.conversion}
          reportsDates={this.props.reportsDates}
        />
        <Table
          readings={this.props.readings}
          reportsDates={this.props.reportsDates}
          conversion={this.props.conversion}
        />
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  activeProfileType: Account.selectors.activeProfileType(state),
});


const ConnectedPatientGestationalReport = connect(
  mapStateToProps,
)(PatientGestationalReport);


export default withStyles(styles)(injectIntl(ConnectedPatientGestationalReport));
