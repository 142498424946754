// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2VoJ7{display:grid;grid-template-columns:50fr 50fr;grid-gap:2rem;min-height:500px}._2VoJ7 .widget{display:-ms-flexbox;display:flex;-ms-flex-direction:column!important;flex-direction:column!important}._2VoJ7 .widget .widget__content{-ms-flex:1 1;flex:1 1;position:relative}._1a5Cl{height:100%;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;padding:10px}._1a5Cl .statisticsSection{border-top:1px solid #e0e8f2;padding:5px 0}._1a5Cl .statisticsSection:first-child{border-top:none}._1a5Cl .statisticsSection__row{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;margin:10px}._1a5Cl .statisticsSection__element{font-weight:500;font-size:13px;line-height:24px;color:#6f7ea2}._1a5Cl .statisticsSection__elementCaption{font-weight:500;font-size:13px;line-height:18px;color:#6f7ea2;margin:10px 0}._1a5Cl .statisticsSection__elementHighlight{font-weight:600;font-size:15px;line-height:24px;color:#0a385a}", ""]);
// Exports
exports.locals = {
	"cgmDistributionWidget": "_2VoJ7",
	"cgmStatistics": "_1a5Cl"
};
module.exports = exports;
