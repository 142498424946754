import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import withStyles from 'isomorphic-style-loader/withStyles';
import isObject from 'lodash/isObject';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import App from 'modules/App';
import Link from 'components/Link';
import XIcon from 'svg/x.svg';
import styles from './Toast.pcss';


class Toast extends React.PureComponent {

    static propTypes = {
      type     : PropTypes.string,
      toastItem: PropTypes.object,
      content  : PropTypes.oneOfType([
        PropTypes.shape({
          id: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      header: PropTypes.oneOfType([
        PropTypes.shape({
          id: PropTypes.string,
        }),
        PropTypes.string,
      ]),
    }

    static defaultProps = {
      type: 'information',
    }


    getText(text) {
      if (!text) {
        return null;
      }

      if (isObject(text)) {
        return <FormattedMessage {...text} />;
      }

      return text;
    }


    getHeader(text) {
      const { type } = this.props;
      return this.getText(text) || <FormattedMessage {...App.messages.toast.header[type]} />;
    }


    render() {
      const { type, toastItem, content, header } = this.props;
      return (
        <div className={cn(styles.toast__wrapper, styles[`toast__wrapper--${type}`])}>
          <div className={styles.toast__contentWrapper}>
            <div className={styles.toast__header}>
              {this.getHeader(header)}
            </div>
            <div className={styles.toast__content}>
              {this.getText(content)}
            </div>
          </div>
          <Link to="" onClick={() => toast.dismiss(toastItem.id)}>
            <XIcon />
          </Link>
        </div>
      );
    }

}

export default withStyles(styles)(Toast);
