import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { encrypt, getKeyFromPem } from 'helpers/crypto';
import FormContainerAbstract from 'components/FormContainerAbstract';
import Form from 'components/Form';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import Button from 'components/Form/Button';
import Modal from 'components/Modal';
import App from 'modules/App';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import validatorRules from './validatorRules.json';


class SetEmailAndSendSharingRequestModal extends FormContainerAbstract {

  static getDerivedStateFromProps(props, state) {
    const { isSharingRequestInProgress, hasSharingRequestErrors, hasUpdatePatientErrors } = props;
    if (isSharingRequestInProgress !== state.isInProgress && isSharingRequestInProgress) {
      return { isInProgress: true };
    }
    if ((hasSharingRequestErrors || hasUpdatePatientErrors) && state.isInProgress) {
      return { isInProgress: false };
    }
    return null;
  }


  static propTypes = {
    ...FormContainerAbstract.propTypes,
    // Explicit props
    activeClinicMembership: PropTypes.shape({
      clinicHcpMembershipId: PropTypes.number.isRequired,
    }),
    activePatient             : PropTypes.object,
    phiSet                    : PropTypes.object,
    // Implicit props
    isSharingRequestInProgress: PropTypes.bool,
    hasSharingRequestErrors   : PropTypes.bool,
    isUpdatePatientInProgress : PropTypes.bool,
    hasUpdatePatientErrors    : PropTypes.bool,
    // Implicit actions
    onUpdateProfile           : PropTypes.func,
    onCloseModal              : PropTypes.func,
    onClearForm               : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      isInProgress: false,
    };
    this.validatorRules = validatorRules;
    this.invitationCode = null;
  }


  componentDidUpdate(prevProps) {
    const {
      activePatient, activeClinicMembership, openModalId,
      isSharingRequestInProgress, hasSharingRequestErrors,
      isUpdatePatientInProgress, hasUpdatePatientErrors,
    } = this.props;
    const { invitationCode } = this;
    const email = get(this.props.formValues, 'values.email');
    if (
      prevProps.isSharingRequestInProgress !== isSharingRequestInProgress
        && !isSharingRequestInProgress
        && !hasSharingRequestErrors
        && invitationCode
    ) {
      this.props.onUpdateProfile(activePatient, { email, invitationCode }, activeClinicMembership);
    }

    if (
      openModalId === constants.SET_EMAIL_AND_SEND_SHARING_REQUEST_MODAL
      && prevProps.isUpdatePatientInProgress !== isUpdatePatientInProgress
        && !isUpdatePatientInProgress
        && !hasUpdatePatientErrors
    ) {
      this.onClose();
    }
  }


  async onClose() {
    this.invitationCode = null;
    await this.props.onCloseModal();
    this.props.onClearForm();
    this.setState({ isInProgress: false });
  }


  onSubmit() {
    this.props.onFormProcessing();
    const { validatedValues, errors } = this.onValidate(this.validatorRules);
    this.props.onFormErrors(errors);
    if (errors) return;
    this.invitationCode = uuid();
    const { id } = this.props.activePatient;
    const pubKey = get(this.props.activeClinicMembership, 'clinic.publicKey');
    const pubKeyObj = getKeyFromPem(pubKey);
    const encryptedClinicPatientProfileId = encrypt(id, pubKeyObj);
    const { email } = validatedValues;
    const { clinicHcpMembershipId } = this.props.activeClinicMembership;

    const clinicPatientHealthData = pick(this.props.phiSet, ['diabetesType', 'treatmentType']);
    clinicPatientHealthData.weight = get(this.props.phiSet, 'summaryData.lastWeight');
    clinicPatientHealthData.height = get(this.props.phiSet, 'summaryData.lastHeight');

    this.props.onSubmit(
      clinicHcpMembershipId,
      this.invitationCode,
      email,
      encryptedClinicPatientProfileId,
      this.props.activePatient,
      clinicPatientHealthData,
    );
  }


  render() {
    return (
      <Modal
        modalId={constants.SET_EMAIL_AND_SEND_SHARING_REQUEST_MODAL}
        openModalId={this.props.openModalId}
        styleModifier="md"
        headerMessage={messages.headers.invitePatient}
        onClose={() => this.onClose()}
      >
        <Form onSubmit={() => this.onSubmit()}>
          <FormGroup
            id="email"
            labelMessage={messages.labels.email}
            formValues={this.props.formValues}
          >
            <Input
              placeholder={messages.placeholders.email}
              onChange={this.props.onSetFormValue}
            />
          </FormGroup>
          <div className="modal__actions">
            <Button
              styleModifier="primary"
              type="submit"
              labelMessage={messages.buttons.invitePatient}
              className="btn--block btn--filled mt-5"
              isInProgress={this.state.isInProgress}
            />
          </div>
        </Form>
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  isSharingRequestInProgress: selectors.isCreateSharingRequestInProgress(state),
  hasSharingRequestErrors   : selectors.hasCreateSharingRequestErrors(state),
  isUpdatePatientInProgress : selectors.isUpdatePatientInProgress(state),
  hasUpdatePatientErrors    : selectors.hasUpdatePatientErrors(state),
  openModalId               : App.selectors.modal(state),
  formValues                : App.selectors.formSelector(constants.SET_PATIENT_EMAIL_FORM)(state),
});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.SET_PATIENT_EMAIL_FORM;
  return {
    onSubmit: (
      clinicHcpMembershipId,
      invitationCode,
      patientEmailAddress,
      encryptedClinicPatientProfileId,
      clinicPatient,
      clinicPatientHealthData,
    ) => dispatch(
      actions.createSharingRequest(
        clinicHcpMembershipId,
        invitationCode,
        patientEmailAddress,
        encryptedClinicPatientProfileId,
        clinicPatient,
        clinicPatientHealthData,
      ),
    ),
    onUpdateProfile: (patient, newPatientValues, clinicMembership) => dispatch(
      actions.updatePatient(patient, newPatientValues, clinicMembership),
    ),
    onCloseModal    : () => dispatch(App.actions.closeModal()),
    onFormProcessing: () => dispatch(App.actions.startFormProcessing(formName)),
    onSetFormValue  : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onSetFormValues : (values) => dispatch(App.actions.setFormValues(formName, values)),
    onFormErrors    : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onClearForm     : () => dispatch(App.actions.clearForm(formName)),
  };
};


const ConnectedSetEmailAndSendSharingRequestModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(SetEmailAndSendSharingRequestModal));


export default ConnectedSetEmailAndSendSharingRequestModal;
