import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import mean from 'lodash/mean';
import round from 'lodash/round';
import cn from 'classnames';
import StackIcon from 'svg/stack.svg';
import ReadingFlagIcon from 'components/ReadingFlagIcon';
import Polygon from 'svg/polygon.svg';
import * as shapes from '../../shapes';
import messages from '../../messages';
import styles from './StackedReadingsTooltip.pcss';


class StackedReadingsTooltip extends React.PureComponent {

  static propTypes = {
    // Explicit props
    readings  : PropTypes.arrayOf(shapes.reading).isRequired,
    conversion: PropTypes.object.isRequired,
  };


  renderTitle() {
    return (
      <div className={styles.stackedReadingsTooltip__title}>
        <StackIcon className={styles.stackedReadingsTooltip__titleIcon} />
        <div className={styles.stackedReadingsTooltip__resultText}>
          <FormattedMessage {...messages.headers.seriesOfMeasurements} />
        </div>
      </div>
    );
  }


  renderReading(reading) {
    const { conversion } = this.props;
    const { displayValue, timestamp, flag, type } = reading;
    const measurementTypeClass = styles[`stackedReadingsTooltip__resultIcon--${type}`];
    const formattedDatetime = moment.unix(timestamp).utc().format('LT');

    return (
      <div className={styles.stackedReadingsTooltip__result} key={`${timestamp}-${flag}-${type}-${displayValue}`}>
        <div className="d-flex">
          <ReadingFlagIcon className={`${styles.stackedReadingsTooltip__resultIcon} ${measurementTypeClass}`} flag={flag} />
          <div className={styles.stackedReadingsTooltip__resultText}>
            { `${displayValue} ${conversion.unitSymbol}` }
          </div>
        </div>
        <div className={styles.stackedReadingsTooltip__datetime}>
          { formattedDatetime }
        </div>
      </div>
    );
  }


  renderReadings() {
    const { readings } = this.props;
    return map(readings, (reading) => this.renderReading(reading));
  }


  renderAverage() {
    const { readings, conversion } = this.props;
    const average = round(mean(map(readings, 'displayValue')), 1);

    return (
      <div className={cn(styles.stackedReadingsTooltip__result, styles.stackedReadingsTooltip__resultText)}>
        <div>
          { `${average} ${conversion.unitSymbol}` }
        </div>
        <div>
          <FormattedMessage {...messages.kpi.average} />
        </div>
      </div>
    );
  }


  render() {
    return (
      <div className={styles.stackedReadingsTooltip}>
        { this.renderTitle() }
        <div className={styles.stackedReadingsTooltip__separator} />
        <div className={styles.stackedReadingsTooltip__content}>
          { this.renderReadings() }
          { this.renderAverage() }
          <Polygon width="17" height="12" className={styles.stackedReadingsTooltip__polygon} />
        </div>
      </div>
    );
  }

}


export default withStyles(styles)(StackedReadingsTooltip);
