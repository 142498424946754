import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import forEach from 'lodash/forEach';
import forOwn from 'lodash/forOwn';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import slice from 'lodash/slice';
import sortBy from 'lodash/sortBy';
import startsWith from 'lodash/startsWith';
import toLower from 'lodash/toLower';
import { formatDateAsObject, formatTimestamp } from 'helpers/datetime';
import Avatar from 'components/Avatar';
import CheckboxRadio from 'components/Form/CheckboxRadio';
import Pagination from 'components/Pagination';
import SyncIcon from 'svg/sync.svg';
import App from 'modules/App';
import DataSources from 'modules/DataSources';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from './ImportsHistory.pcss';


class ImportsHistory extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    // eslint-disable-next-line react/prop-types
    const { phiSet, devices } = props;
    const imports = get(phiSet, 'imports');
    if (state.imports !== imports) {
      if (!imports) {
        return {
          imports,
          importDocuments: [],
          matchedDevices : {},
          totalPages     : 1,
          page           : 0,
        };
      }

      const matchedDevices = {};

      const setDevice = (serialNumberToken) => {
        const foundDevices = filter(devices, (device) => startsWith(serialNumberToken, device.sku));
        matchedDevices[serialNumberToken] = reduce(foundDevices, (acc, matchedDevice) => (
          acc && acc.sku.length > matchedDevice.sku.length ? acc : matchedDevice
        ), null);
      };

      const flattenImportDocuments = [];
      forOwn(imports, (deviceImports, serialNumberToken) => {
        const { deviceName, documents } = deviceImports;
        setDevice(serialNumberToken);
        forEach(documents, (document) => {
          flattenImportDocuments.push({ ...document, deviceName, serialNumberToken });
        });
      });
      const importDocuments = sortBy(flattenImportDocuments, (d) => -d.timestamp);
      const totalPages = Math.ceil(importDocuments.length / 10);

      return {
        imports,
        importDocuments,
        matchedDevices,
        totalPages,
        page: 0,
      };
    }
    return null;
  }


  static propTypes = {
    // Implicit props
    fromImports          : PropTypes.arrayOf(PropTypes.string),
    devices              : PropTypes.arrayOf(App.shapes.device),
    caseTypes            : PropTypes.arrayOf(App.shapes.caseType),
    dataSources          : PropTypes.arrayOf(DataSources.shapes.dataSource),
    localizationResources: PropTypes.object,
    // Implicit actions
    onChangeFromImports  : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      imports        : null,
      importDocuments: [],
      matchedDevices : {},
      totalPages     : 1,
      page           : 0,
    };
  }


  componentDidUpdate() {
    ReactTooltip.rebuild();
  }


  onChangePage(page) {
    this.setState({ page });
  }


  onChangeFromImports(importDocumentId) {
    const { imports } = this.state;
    this.props.onChangeFromImports(importDocumentId, imports);
  }


  get importDocumentsPage() {
    const { page } = this.state;
    const perPage = 10;
    const start = page * 10;
    const end = start + perPage;
    return slice(this.state.importDocuments, start, end);
  }


  get importDocumentsByMonths() {
    const monthlyImportDocuments = {
      index : [],
      months: {},
    };
    const { importDocumentsPage } = this;
    forEach(importDocumentsPage, (importDocument, idx) => {
      const { month, year } = formatDateAsObject(importDocument.timestamp * 1000); // Date needs ms
      const key = `${year}-${month}`;
      if (!monthlyImportDocuments.months[key]) {
        monthlyImportDocuments.index.push(key);
        monthlyImportDocuments.months[key] = {
          importDocuments: [],
          month          : moment().month(month - 1).format('MMMM'),
          year,
        };
      }
      monthlyImportDocuments.months[key].importDocuments.push({ ...importDocument, idx });
    });
    return monthlyImportDocuments;
  }


  renderColHeaders() {
    return (
      <div className={styles.table__headers}>
        <div className={styles.table__col}>
          <FormattedMessage {...messages.tableLabels.timeOfImport} />
        </div>
        <div className={styles.table__col}>
          <FormattedMessage {...messages.tableLabels.dateRange} />
        </div>
        <div className={cn(styles.table__col, styles['table__col--small'])}>
          <FormattedMessage {...messages.tableLabels.readings} />
        </div>
        <div className={cn(styles.table__col, styles['table__col--small'])}>
          <FormattedMessage {...messages.tableLabels.source} />
        </div>
        <div className={cn(styles.table__col, styles['table__col--small'])}>
          <FormattedMessage {...messages.tableLabels.filterChart} />
        </div>
      </div>
    );
  }


  renderSourceFigure(importDocument) {
    const { serialNumberToken, deviceName } = importDocument;
    if (serialNumberToken === 'sync') {
      return (
        <figure
          className={styles.deviceFigure}
          data-for="importsHistoryTooltip"
          data-tip="Sync"
        >
          <SyncIcon className={styles.deviceFigure__icon} />
        </figure>
      );
    }
    if (serialNumberToken === 'glucocontrodesktop') {
      return (
        <figure
          className={styles.deviceFigure}
          data-for="importsHistoryTooltip"
          data-tip="GlucoControDesktop"
        >
          <SyncIcon className={styles.deviceFigure__icon} />
        </figure>
      );
    }
    if (serialNumberToken === 'snaq') {
      return (
        <figure
          className={styles.deviceFigure}
          data-for="importsHistoryTooltip"
          data-tip="Snaq"
        >
          <img src="/assets/svg/snaq.svg" alt="snaq.io" />
        </figure>
      );
    }
    const device = get(this.state.matchedDevices, serialNumberToken);
    if (device) {
      const caseTypeId = get(device, 'caseTypeId');
      const caseType = find(this.props.caseTypes, { caseTypeId });
      const icon = get(caseType, 'icon', null);
      return (
        <figure
          className={styles.deviceFigure}
          data-for="importsHistoryTooltip"
          data-tip={device.name}
          dangerouslySetInnerHTML={{ __html: icon }} // eslint-disable-line react/no-danger
        />
      );
    }
    const dataSource = find(this.props.dataSources, (ds) => toLower(ds.dataSourceProvider) === serialNumberToken);
    if (dataSource) {
      const dataSourceProvider = get(
        this.props.localizationResources,
        [dataSource.nameKey, 'value'],
        dataSource.dataSourceProvider,
      );
      return (
        <figure
          className={styles.dataSource}
          data-for="importsHistoryTooltip"
          data-tip={dataSourceProvider}
        >
          <Avatar
            avatarImg={dataSource.logo}
            name={dataSourceProvider}
            className={styles.dataSource__logo}
            imgClassName={styles.dataSource__logo__img}
            initialsClassName={styles.dataSource__logo__initials}
          />
        </figure>
      );
    }
    return (
      <figure
        className={styles.deviceFigure}
        data-for="importsHistoryTooltip"
        data-tip={deviceName}
      >
        <img src="/assets/svg/generic-glucometer.svg" alt={deviceName} />
      </figure>
    );
  }


  renderImport(importDocument) {
    const {
      importDocumentId, timestamp, maxResultDate, minResultDate, readingsCount, idx,
    } = importDocument;

    return (
      <div key={importDocumentId} className={cn(styles.table__row, { [styles.odd]: !(idx % 2) })}>
        <div className={cn(styles.table__col, 'text--bold')}>
          { formatTimestamp(timestamp, 'L, LT') }
        </div>
        <div className={styles.table__col}>
          { formatTimestamp(minResultDate, 'L') } - { formatTimestamp(maxResultDate, 'L') }
        </div>
        <div className={cn(styles.table__col, styles['table__col--small'])}>
          <span className={styles.readingsCount}>{ readingsCount }</span>
        </div>
        <div className={cn(styles.table__col, styles['table__col--small'])}>
          { this.renderSourceFigure(importDocument) }
        </div>
        <div className={cn(styles.table__col, styles['table__col--small'])}>
          <CheckboxRadio
            id={importDocumentId}
            inputValue="1"
            value={includes(this.props.fromImports, importDocumentId) ? '1' : ''}
            onChange={() => this.onChangeFromImports(importDocumentId)}
          />
        </div>
      </div>
    );
  }


  renderMonth(monthId, months) {
    const month = get(months, monthId);
    if (!month) {
      return null;
    }
    return (
      <div key={monthId} className={styles.month}>
        <h3 className={styles.month__name}>
          <p>{ month.month }</p>
          <p className="mt-2">{ month.year }</p>
        </h3>
        <div className={styles.month__imports}>
          { map(month.importDocuments, (importDocument) => this.renderImport(importDocument)) }
        </div>
      </div>
    );
  }


  render() {
    const { importDocumentsByMonths } = this;
    return (
      <div className={styles.root}>
        { this.renderColHeaders() }
        { map(importDocumentsByMonths.index, (monthId) => this.renderMonth(monthId, importDocumentsByMonths.months)) }
        <Pagination
          perPage={10}
          onPageChange={(page) => this.onChangePage(page)}
          page={this.state.page}
          totalPages={this.state.totalPages}
          isPerPageOff
        />
        <ReactTooltip
          id="importsHistoryTooltip"
          place="top"
          type="dark"
          effect="solid"
        />
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  fromImports          : selectors.fromImports(state),
  devices              : App.selectors.devices(state),
  caseTypes            : App.selectors.caseTypes(state),
  localizationResources: App.selectors.localizationResources(state),
  dataSources          : DataSources.selectors.dataSources(state),
});


const mapDispatchToProps = (dispatch) => ({
  onChangeFromImports: (importDocumentId, imports) => dispatch(actions.changeFromImports(importDocumentId, imports)),
});


const ConnectedImportsHistory = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImportsHistory);


export default withStyles(styles)(ConnectedImportsHistory);
