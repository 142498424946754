import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Toaster } from 'react-hot-toast';
import App from 'modules/App';
import messages from '../../messages';
import styles from './LogoLayout.pcss';


class LogoLayout extends React.Component {

  static propTypes = {
    // Explicit props
    metaTitleMessage      : PropTypes.object,
    metaDescriptionMessage: PropTypes.object,
    // Implicit props
    children              : PropTypes.node,
    // Implicit actions
    onCloseDropdown       : PropTypes.func,
  };


  onKeyDown(evt) {
    // If ESC
    if (evt.keyCode === 27) {
      this.props.onCloseDropdown();
    }
  }


  render() {
    const titleMessage = this.props.metaTitleMessage
      ? this.props.metaTitleMessage
      : { ...messages.meta.title };

    const descriptionMessage = this.props.metaDescriptionMessage
      ? this.props.metaDescriptionMessage
      : { ...messages.meta.description };


    return (
      <App.components.LanguageProvider>
        <div
          className={cn(styles.root, 'no-gutters')}
          onClick={this.props.onCloseDropdown}
          onKeyDown={(evt) => this.onKeyDown(evt)}
        >
          <App.components.IntlHelmet
            titleMessage={titleMessage}
            descriptionMessage={descriptionMessage}
          />
          <div className="col-auto d-lg-none">
            <div className={styles.mobileLogo}>
              <img src="/assets/svg/logo.svg" className={styles.mobileLogo__logo} alt="Ascensia Diabetes Care" />
            </div>
          </div>
          <div className={cn('col col-lg-8', styles.container)}>
            <div className={styles.containerInner}>
              <div className="content">
                { this.props.children }
              </div>
            </div>
            <App.components.LanguageSelector />
          </div>
          <div className="d-none d-lg-block col-4">
            <div className={styles.logoSidebar}>
              <img src="/assets/svg/logo.svg" className={styles.logoSidebar__logo} alt="Ascensia Diabetes Care" />
            </div>
          </div>
          {
            process.env.BROWSER
            && (
              <Toaster position="top-right" />
            )
          }
        </div>
      </App.components.LanguageProvider>
    );
  }

}


const mapDispatchToProps = (dispatch) => ({
  onCloseDropdown: () => dispatch(App.actions.closeDropdown()),
});

const ConnectedLogoLayout = connect(
  null,
  mapDispatchToProps,
)(LogoLayout);


export default withStyles(styles)(ConnectedLogoLayout);
