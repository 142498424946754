import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import get from 'lodash/get';
import map from 'lodash/map';
import isObject from 'lodash/isObject';
import times from 'lodash/times';
import Pagination from 'components/Pagination';
import intlShape from 'shapes/intlShape';
import * as shapes from './shapes';


class Table extends React.PureComponent {

  static propTypes = {
    // Explicit props
    idKey          : PropTypes.string.isRequired,
    className      : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    schema         : shapes.schema.isRequired,
    entities       : PropTypes.array.isRequired,
    page           : PropTypes.number,
    perPage        : PropTypes.number,
    isInProgress   : PropTypes.bool,
    isPerPageOff   : PropTypes.bool,
    // Explicit actions
    onPageChange   : PropTypes.func,
    onPerPageChange: PropTypes.func,
    // Implicit props
    intl           : intlShape,
  };


  static defaultProps = {
    page   : 0,
    perPage: 10,
  }


  constructor(props) {
    super(props);
    const { perPage } = props;
    this.state = {
      page: props.page,
      perPage,
    };
  }


  async onPageChange(page) {
    const { perPage } = this.state;
    if (this.props.onPageChange) await this.props.onPageChange({ page, perPage });
    this.setState({ page });
  }


  async onPerPageChange(perPage) {
    const { page } = this.state;
    if (this.props.onPerPageChange) await this.props.onPerPageChange({ page, perPage });
    this.setState({ perPage });
  }


  get pagedEntities() {
    const { entities, idKey, isInProgress } = this.props;
    const { page, perPage } = this.state;
    if (isInProgress) {
      return times(perPage, (n) => ({ [idKey]: n }));
    }
    return entities.slice(perPage * page, perPage * (page + 1));
  }


  get totalPages() {
    const { entities, perPage } = this.props;
    return Math.ceil(entities.length / perPage);
  }


  renderHeaderCell(schemaItem, idx) {
    const { labelMessage = '' } = schemaItem;
    const message = isObject(labelMessage) ? this.props.intl.formatMessage(labelMessage) : labelMessage;
    return <th key={idx} className="table__cell">{ message }</th>;
  }


  renderHeader() {
    const { schema } = this.props;
    return (
      <thead className="table__header">
        <tr className="table__row">
          { map(schema, (schemaItem, idx) => this.renderHeaderCell(schemaItem, idx)) }
        </tr>
      </thead>
    );
  }


  renderRowCell(entity, entityKey, schemaItem) {
    const { key, renderer } = schemaItem;
    const value = renderer ? renderer(entity) : get(entity, key, '');
    return <td key={`${entityKey}-${key}`} className="table__cell">{ value }</td>;
  }


  renderRow(entity) {
    const { schema, idKey, isInProgress } = this.props;
    const entityKey = get(entity, idKey);
    return (
      <tr key={entityKey} className={cn('table__row', { fadingLoader: isInProgress })}>
        { map(schema, (schemaItem) => this.renderRowCell(entity, entityKey, schemaItem)) }
      </tr>
    );
  }


  renderBody() {
    return (
      <tbody>
        { map(this.pagedEntities, (entity) => this.renderRow(entity)) }
      </tbody>
    );
  }


  render() {
    return (
      <div>
        <table className={cn('table', this.props.className)}>
          { this.renderHeader() }
          { this.renderBody() }
        </table>
        <Pagination
          perPage={this.state.perPage}
          onPerPageChange={(perPage) => this.onPerPageChange(perPage)}
          onPageChange={(page) => this.onPageChange(page)}
          page={this.state.page}
          totalPages={this.totalPages}
          isPerPageOff={this.props.isPerPageOff}
        />
      </div>
    );
  }

}


export default injectIntl(Table);
