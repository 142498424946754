/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
// import AutoSizer from 'react-virtualized-auto-sizer';
// import { motionConfigContext, SmartMotion } from '@nivo/core';
// import { Line } from '@nivo/line';
// import { curveMonotoneX, line } from 'd3-shape';
// import { timeDays, timeDay } from 'd3-time';
import moment from 'moment';
import { highlightedRecordsShape } from 'shapes/bins';
import styles from './BloodGlucoseProfileTimeChart.pcss';


/**
 * @deprecated
 */
class BloodGlucoseProfileTimeChart extends React.PureComponent {

  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      minValue: PropTypes.number,
      maxValue: PropTypes.number,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    records  : highlightedRecordsShape,
    direction: PropTypes.string,
  };


  constructor(props) {
    super(props);
    this.colors = {
      stroke: '#1EA98C',
      target: '#CBEBE5',
      high  : '#F4C32C',
      low   : '#F74053',
    };
  }


  // get chartData() {
  //   const { records = [], conversion } = this.props;
  //   return [
  //     {
  //       id  : 'Glucose Profile Time',
  //       data: records.map((record) => ({
  //         x: record.date,
  //         y: conversion.toDisplay(record.value),
  //       })),
  //     },
  //   ];
  // }
  //
  // get tickValues() {
  //   const { records } = this.props;
  //   try {
  //     const start = timeDay.floor(records[0].date);
  //     const end = timeDay.ceil(moment(records[records.length - 1].date).add(1, 'day').toDate());
  //     return timeDays(start, end, 1);
  //   } catch (e) {
  //     return [];
  //   }
  // }
  //
  //
  // line({ series, xScale, yScale, ...props }) {
  //   const { conversion, standards } = this.props;
  //   const lowThreshold = conversion.toDisplay(standards.postMeal.lowThreshold);
  //   const highThreshold = conversion.toDisplay(standards.postMeal.highThreshold);
  //   const width = props.width - props.margin.right - props.margin.left;
  //   const maxY = yScale(999);
  //   const minY = yScale(0);
  //   const highY = yScale(highThreshold);
  //   const lowY = yScale(lowThreshold);
  //
  //   const lineGenerator = line()
  //     .x((d) => xScale(d.data.x))
  //     .y((d) => yScale(d.data.y))
  //     .curve(curveMonotoneX);
  //
  //   const pathProps = {
  //     d              : lineGenerator(series[0].data),
  //     fill           : 'none',
  //     strokeWidth    : 2,
  //     strokeDasharray: '4,2',
  //   };
  //   return (
  //     <g>
  //       <clipPath id="clipPathHigh" clipPathUnits="userSpaceOnUse">
  //         <polygon
  //           points={`0,${maxY} ${width},${maxY} ${width},${highY} 0,${highY}`}
  //           fill="#000000"
  //         />
  //       </clipPath>
  //       <clipPath id="clipPathTarget" clipPathUnits="userSpaceOnUse">
  //         <polygon
  //           points={`0,${highY} ${width},${highY} ${width},${lowY} 0,${lowY}`}
  //           fill="#000000"
  //         />
  //       </clipPath>
  //       <clipPath id="clipPathLow" clipPathUnits="userSpaceOnUse">
  //         <polygon
  //           points={`0,${lowY} ${width},${lowY} ${width},${minY} 0,${minY}`}
  //           fill="#000000"
  //         />
  //       </clipPath>
  //       <path
  //         stroke={this.colors.high}
  //         style={{
  //           clipPath: 'url(#clipPathHigh)',
  //         }}
  //         {...pathProps}
  //       />
  //       <path
  //         stroke={this.colors.stroke}
  //         style={{
  //           clipPath: 'url(#clipPathTarget)',
  //         }}
  //         {...pathProps}
  //       />
  //       <path
  //         stroke={this.colors.low}
  //         style={{
  //           clipPath: 'url(#clipPathLow)',
  //         }}
  //         {...pathProps}
  //       />
  //     </g>
  //   );
  // }

  circles({ series, xScale, yScale }) {
    const { conversion, standards } = this.props;
    const lowThreshold = conversion.toDisplay(standards.postMeal.lowThreshold);
    const highThreshold = conversion.toDisplay(standards.postMeal.highThreshold);
    return (
      <g>
        {series[0].data.map((d) => {
          let fill = this.colors.stroke;
          if (d.data.y > highThreshold) {
            fill = this.colors.high;
          } else if (d.data.y < lowThreshold) {
            fill = this.colors.low;

          }
          return (
            <circle
              key={d.data.x}
              fill={fill}
              cx={xScale(d.data.x)}
              cy={yScale(d.data.y)}
              r="2px"
            />
          );
        })}
      </g>
    );
  }

  renderTargetZone(props) {
    const { conversion, standards } = this.props;
    const lowThreshold = conversion.toDisplay(standards.postMeal.lowThreshold);
    const highThreshold = conversion.toDisplay(standards.postMeal.highThreshold);
    const width = props.width - props.margin.right - props.margin.left;
    const yTop = props.yScale(highThreshold);
    const yBottom = props.yScale(lowThreshold);

    return null;

    // return (
    //   <motionConfigContext.Consumer>
    //     { (springConfig) => (
    //       <SmartMotion
    //         key="bloodGlucoseConcentrationTargetZone"
    //         style={(spring) => ({
    //           width: spring(width, springConfig),
    //         })}
    //       >
    //         {(style) => (
    //           <g opacity={0.5}>
    //             <polygon
    //               points={`0,${yTop} ${style.width},${yTop} ${style.width},${yBottom} 0,${yBottom}`}
    //               fill={this.colors.target}
    //             />
    //           </g>
    //         )}
    //       </SmartMotion>
    //     )}
    //   </motionConfigContext.Consumer>
    // );
  }


  render() {
    return null;

    // const { direction, conversion, standards } = this.props;
    // const margin = {
    //   top   : 30,
    //   right : direction === 'rtl' ? 30 : 20,
    //   bottom: 30,
    //   left  : direction === 'ltr' ? 30 : 20,
    // };
    // const axisY = {
    //   tickSize    : 5,
    //   tickPadding : 5,
    //   tickRotation: 0,
    //   tickValues  : 5,
    // };
    /*
      IMPORTANT: Because Edge doesn't support `dominant-baseline` and RTL support is poor
      hardcoded styles overwrite ticks labels transform for current tickPadding and tickRotation values and
      must be correlated if change
     */
    // return (
    //   <div className={styles.root}>
    //     <AutoSizer>
    //       {
    //           ({ height, width }) => (
    //             <Line
    //               data={this.chartData}
    //               height={height}
    //               width={width}
    //               margin={margin}
    //               xScale={{
    //                 type   : 'time',
    //                 reverse: direction === 'rtl',
    //               }}
    //               yScale={{ type: 'linear',
    //                 min : conversion.toDisplay(standards.minValue),
    //                 max : conversion.toDisplay(standards.maxValue) }}
    //               axisTop={null}
    //               axisRight={direction === 'ltr' ? null : axisY}
    //               axisLeft={direction === 'rtl' ? null : axisY}
    //               axisBottom={{
    //                 tickSize  : 0,
    //                 tickValues: this.tickValues,
    //                 format    : (value) => moment(value).format('DD/MM/YYYY'),
    //
    //               }}
    //               gridXValues={this.tickValues}
    //               colors={[this.colors.stroke]}
    //               lineWidth={2}
    //               enableCrosshair={false}
    //               curve="monotoneX"
    //               layers={[
    //                 'grid',
    //                 'markers',
    //                 'axes',
    //                 'areas',
    //                 this.renderTargetZone.bind(this),
    //                 this.line.bind(this),
    //                 // this.circles.bind(this),
    //                 'slices',
    //                 'legends',
    //               ]}
    //             />
    //           )
    //         }
    //     </AutoSizer>
    //   </div>
    // );
  }

}


export default withStyles(styles)(BloodGlucoseProfileTimeChart);
