export const MODULE_ID = 'hcp';

export const PATIENTS_LIST_LENGTH = 12;

export const ACCEPT_ENROLL_MODAL_DEFAULT_COMPONENT = 'EnrollingRequest';

export const SYNC_PERSONAL_DATA = [
  'firstName',
  'lastName',
  // 'customPatientIdentifiersValues',
  'personalIdentifier',
  'dateOfBirth',
  'gender',
  'payer',
];

export const CANCELABLE_SHARING_REQUEST_STATUSES = [
  'Pending',
  'Received',
  'Enrolling',
  'Approved',
];

export const SHARING_REQUEST_STATUSES = [
  ...CANCELABLE_SHARING_REQUEST_STATUSES, 'Cancelled', 'Rejected', 'Revoked', 'Expired',
];

export const ENROLLING_SHARING_REQUEST_DROPDOWN = `${MODULE_ID}/enrollingSharingRequestDropdown`;

export const DECLINE_ENROLL_MODAL = `${MODULE_ID}/declineEnrollModal`;
export const MERGE_PATIENT_MODAL = `${MODULE_ID}/mergePatientModal`;
export const INVITE_PATIENT_MODAL = `${MODULE_ID}/invitePatientModal`;
export const REMOVE_PATIENT_MODAL = `${MODULE_ID}/removePatientModal`;
export const RESEND_INVITATION_PATIENT_MODAL = `${MODULE_ID}/resendInvitationPatientModal`;
export const SET_EMAIL_AND_SEND_SHARING_REQUEST_MODAL = `${MODULE_ID}/setEmailAndSendSharingRequestModal`;
export const SYNC_DATA_MISMATCH_MODAL = `${MODULE_ID}/syncDataMismatchModal`;

export const INVITE_PATIENT_FORM = `${MODULE_ID}.invitePatientForm`;
export const MERGE_PATIENT_FORM = `${MODULE_ID}.mergePatientForm`;
export const REMOVE_PATIENT_FORM = `${MODULE_ID}.removePatientForm`;
export const SEARCH_PATIENTS_FORM = `${MODULE_ID}.searchPatientsForm`;
export const SET_PATIENT_EMAIL_FORM = `${MODULE_ID}.setPatientEmailForm`;
export const SEARCH_CLINICS_FORM = `${MODULE_ID}.searchClinicsForm`;
