export const DEFAULT_LOCALE = 'en';
// If you're adding locale see webpack.config.js MomentLocalesPlugin
export const APP_LOCALES = [
  'en-gb',
  'en',
  'en-au',
  'en-ie',
  'en-hk',
  'en-sg',
  'ar',
  'bg',
  'cs',
  'de',
  'de-ch',
  'el',
  'es',
  'et',
  'fi',
  'fr',
  'fr-ca',
  'fr-ch',
  'he',
  'hr',
  'hy-am',
  'id',
  'it',
  'it-ch',
  'lt',
  'lv',
  'nl',
  'pl',
  'pt',
  'pt-br',
  'ro',
  'sk',
  'sl',
  'sr',
  'sv',
  'th',
  'tr',
  'zh-hk',
  'zh-tw',
];

export const APP_LOCALE_LANGUAGES_MAP = {
  'hy-am': 'hy',
  'zh-hk': 'zh-Hant',
};
