// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._28QtV{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;min-height:100vh;width:100%;overflow-x:hidden}@media (min-width:1024px){._28QtV{-ms-flex-direction:row;flex-direction:row}}._28QtV svg path{vector-effect:non-scaling-stroke}._3JQjN{height:70px;height:7rem;width:100%;background:#872c93;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:start;justify-content:flex-start}._1nncJ{width:130px;margin:0 2rem}.oPa06{-ms-flex:1 1 auto;flex:1 1 auto;height:100%}._1tH4G{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;height:100%}@media (min-width:1024px){._1tH4G{-ms-flex-align:center;align-items:center}}._1tH4G a{font-weight:700}._1tH4G .content{padding:3.5rem 2rem 10rem;font-size:14px;font-size:1.4rem;width:100%;max-width:640px;max-width:64rem}@media (min-width:600px){._1tH4G .content{padding:3.5rem 0 10rem;width:80%}}@media (min-width:1024px){._1tH4G .content{padding:5rem 0 10rem;width:62%}}@media (min-width:1280px){._1tH4G .content{width:50%}}._1tH4G .content--fixed{max-width:none!important}@media (min-width:1280px){._1tH4G .content--fixed{width:115.6rem!important}}._1tH4G .pageHeader{margin-bottom:6rem}._1tH4G .pageHeader__intro{font-size:16px;font-size:1.6rem;color:#6f7ea2;line-height:1.2;padding-top:1rem}div:first-child>.alert{border-radius:8px}._1j4lA{height:100%;background:#872c93;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}._3SDoD{width:200px;width:20rem}", ""]);
// Exports
exports.locals = {
	"root": "_28QtV",
	"mobileLogo": "_3JQjN",
	"mobileLogo__logo": "_1nncJ",
	"container": "oPa06",
	"containerInner": "_1tH4G",
	"logoSidebar": "_1j4lA",
	"logoSidebar__logo": "_3SDoD"
};
module.exports = exports;
