import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import App from 'modules/App';
import Account from 'modules/Account';
import messages from '../../messages';
import styles from './PreviewResultsLayout.pcss';


class PreviewResultsLayout extends React.Component {

  static propTypes = {
    // Explicit props
    children              : PropTypes.node,
    metaTitleMessage      : PropTypes.object,
    metaDescriptionMessage: PropTypes.object,
    // Implicity props
  };


  renderAlertsBus() {
    return (
      <App.components.AlertsBus
        className="px-6"
        isGlobal
        activeClinicMembership={null}
      />
    );
  }


  renderSideBar() {
    return (
      <div className={cn(styles.sidebarHolder)}>
        <App.components.LanguageSelector
          hideLabel
          className="sidebar__languageSelector"
          withTooltip
        />
      </div>
    );
  }


  renderContent() {
    return (
      <div className="content">
        { this.props.children }
      </div>
    );
  }

  render() {
    const titleMessage = this.props.metaTitleMessage
      ? this.props.metaTitleMessage
      : { ...messages.meta.title };

    const descriptionMessage = this.props.metaDescriptionMessage
      ? this.props.metaDescriptionMessage
      : { ...messages.meta.description };

    return (
      <App.components.LanguageProvider>
        <div className={cn(styles.root)}>
          <App.components.IntlHelmet
            titleMessage={titleMessage}
            descriptionMessage={descriptionMessage}
          />
          <div className={cn(styles.container, styles.layoutContainer)}>
            {/* //TopBar ? */}
            <div className={styles.layout}>
              {this.renderSideBar()}
              {this.renderContent()}
            </div>
          </div>

        </div>
      </App.components.LanguageProvider>
    );
  }

}


const mapStateToProps = (state) => ({
  activeClinicMembership: Account.selectors.activeClinicMembership(state),
  direction             : App.selectors.direction(state),

});


const mapDispatchToProps = (dispatch) => ({
  onCloseDropdown: () => dispatch(App.actions.closeDropdown()),
});


const ConnectedPreviewResultsLayout = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreviewResultsLayout);


export default withStyles(styles)(ConnectedPreviewResultsLayout);
