import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import AmbulatoryGlucoseProfile from 'modules/AmbulatoryGlucoseProfile';
import messages from 'modules/AmbulatoryGlucoseProfile/messages';
import BgmValuesInRangesReportChart from 'components/Charts/BgmValuesInRangesChart/BgmValuesInRangesReportChart';
import SectionTitle from '../components/SectionTitle';
import * as constants from '../../../constants';
import styles from './TimeInRangesSection.pcss';


class TimeInRangesSection extends React.Component {

  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    // Implicit props
    statistics: PropTypes.shape({
      veryHigh     : PropTypes.number,
      high         : PropTypes.number,
      target       : PropTypes.number,
      low          : PropTypes.number,
      veryLow      : PropTypes.number,
      veryHighCount: PropTypes.number,
      highCount    : PropTypes.number,
      targetCount  : PropTypes.number,
      lowCount     : PropTypes.number,
      veryLowCount : PropTypes.number,
    }),
    isDisplayedGlucoseStatistics: PropTypes.bool,
    isDisplayedTimeInRanges     : PropTypes.bool,
  };


  render() {
    const { conversion, statistics } = this.props;
    return (
      <div className={styles.timeInRangesSectionWrapper}>
        <SectionTitle>
          <FormattedMessage {...messages.headers.bgmValuesInRanges} />
        </SectionTitle>
        <BgmValuesInRangesReportChart
          conversion={this.props.conversion}
          standards={this.props.standards}
          timeInRanges={[
            {
              name   : 'veryHigh',
              count  : statistics.veryHighCount,
              percent: Math.round(statistics.veryHigh * 100),
              range  : `>${constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].veryHigh}`,
            },
            {
              name   : 'high',
              count  : statistics.highCount,
              percent: Math.round(statistics.high * 100),
              range  : `${constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].high
                + constants.GLUCOSE_CONCENTRATION_LEVEL_STEP[conversion.unit]} - ${
                constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].veryHigh}`,
            },
            {
              name   : 'normal',
              count  : statistics.targetCount,
              percent: Math.round(statistics.target * 100),
              range  : `${constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].target} - ${
                constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].high}`,
            },
            {
              name   : 'low',
              count  : statistics.lowCount,
              percent: Math.round(statistics.low * 100),
              range  : `${constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].low} - ${
                constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].target
                - constants.GLUCOSE_CONCENTRATION_LEVEL_STEP[conversion.unit]}`,
            },
            {
              name   : 'veryLow',
              count  : statistics.veryLowCount,
              percent: Math.round(statistics.veryLow * 100),
              range  : `<${constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].low}`,
            },
          ]}
        />
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  statistics: AmbulatoryGlucoseProfile.selectors.highlightedStatisticsFactory(
    constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES
  )(state),
  isDisplayedGlucoseStatistics: AmbulatoryGlucoseProfile.selectors.isDisplayedGlucoseStatistics(state),
  isDisplayedTimeInRanges     : AmbulatoryGlucoseProfile.selectors.isDisplayedTimeInRanges(state),
});


const ConnectedTimeInRangesSection = connect(
  mapStateToProps,
)(TimeInRangesSection);

export default withStyles(styles)(ConnectedTimeInRangesSection);
