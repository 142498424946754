import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import Settings from '../Settings';


class SettingsSection extends React.PureComponent {

  render() {
    return (
      <section>
        <h2 className="section__header"><FormattedMessage {...messages.headers.dataSourcesSettings} /></h2>
        <Settings authType="settings" />
      </section>
    );
  }

}


export default SettingsSection;
