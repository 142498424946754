export const MODULE_ID = 'visit';

export const VISIT_TYPES = {
  ONSITE: 'Onsite',
  ONLINE: 'Online',
};

export const VISIT_HISTORY_MODAL = `${MODULE_ID}/visitHistoryModal`;

export const VISIT_NOTES_FM = `${MODULE_ID}/visitNotesFM`;

export const VISIT_NOTE_FORM = `${MODULE_ID}.visitNoteForm`;
