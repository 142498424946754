import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';
import reducer from './reducer';
import messages from './messages';

import MyResults from './components/MyResults';
import CgResults from './components/CgResults';
import FamilyMemberResults from './components/FamilyMemberResults';
import HcpResults from './components/HcpResults';
import Results from './components/Results';
import PreviewHcpResults from './components/PreviewHcpResults';
// eslint-disable-next-line import/no-cycle
import Report from './components/Report';


const components = {
  MyResults,
  CgResults,
  FamilyMemberResults,
  HcpResults,
  Results,
  Report,
  PreviewHcpResults,
};


export default {
  actions,
  components,
  constants,
  messages,
  reducer,
  selectors,
};
