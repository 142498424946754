import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { AppContext, ProfileContext } from 'context';
import history from 'helpers/history';
import { getSlug } from 'helpers/urlTools';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import DataSources from 'modules/DataSources';
import Hcp from 'modules/Hcp';
import * as selectors from '../../../selectors';


export const useDevicePatientSetup = () => {
  const profileContext = useContext(ProfileContext);
  const activeProfileType: ProfileType = useSelector(Account.selectors.activeProfileType);
  const isHcp = activeProfileType === Account.constants.PROFILE_TYPES.HCP;
  const activeVisit = useSelector(profileContext.selectors.activeVisit);
  if (!isHcp || activeVisit) {
    return;
  }
  const deviceData = useSelector(selectors.deviceData);
  const patients = useSelector(Hcp.selectors.patients);
  const patientProfile = useSelector(Hcp.selectors.activePatient);
  const activeClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const { getUrl } = useContext(AppContext);

  useEffect(() => {
    const currentPatientId = get(patientProfile, 'id');
    const deviceSerialNumberToken = get(deviceData, 'deviceSerialNumberToken');
    const patientsFilter = (patient) => includes(patient.devicesSerialNumberTokens, deviceSerialNumberToken);
    const deviceUser = find(patients, patientsFilter);
    if (deviceUser && deviceUser.id !== currentPatientId) {
      const { organizationUID, name } = get(activeClinicMembership, 'clinic', {});
      const clinicSlug = getSlug(name);
      const patientSlug = getSlug(`${deviceUser.firstName} ${deviceUser.lastName}`);
      const url = getUrl(
        'hcp-results',
        { clinicSlug, organizationUID, patientSlug, patientId: deviceUser.id },
      );
      history.push(url);
    }
  }, []);
};


export const useIsProfileActivationInProgress = () => {
  const isActivatePatientInProgress = useSelector(Hcp.selectors.isActivatePatientInProgress);
  const isFetchPhiSetInProgress = useSelector(CloudDrive.selectors.isFetchPhiSetInProgress);
  const isFetchReadingsInProgress = useSelector(CloudDrive.selectors.isFetchReadingsInProgress);
  const isSyncInProgress = useSelector(CloudDrive.selectors.isSyncInProgress);
  const isDataSourcesSyncInProgress = useSelector(DataSources.selectors.isSyncInProgress);

  return isActivatePatientInProgress || isFetchPhiSetInProgress || isFetchReadingsInProgress
    || isSyncInProgress || isDataSourcesSyncInProgress;
};
