// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1UTqx{min-height:450px;font-weight:500}._37XVs{position:relative;display:-ms-inline-flexbox;display:inline-flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;background-color:#fff;border-radius:50%;width:32px;width:3.2rem;height:32px;height:3.2rem;overflow:hidden}html[dir=ltr] ._37XVs{margin-right:1.6rem}html[dir=rtl] ._37XVs{margin-left:1.6rem}._1PaRy{width:auto;max-height:100%}._3H1QB{display:block;color:#fff;font-size:15px;font-size:1.5rem;font-weight:700}._3Al9y{-ms-flex:1 1;flex:1 1}._3e91e td:first-child{width:100%}.t-VCL{white-space:nowrap;text-align:center;padding:17px;color:#95a1bd}.FMTdy{min-width:150px}", ""]);
// Exports
exports.locals = {
	"modalViewContainer": "_1UTqx",
	"avatar": "_37XVs",
	"avatar__img": "_1PaRy",
	"avatar__initials": "_3H1QB",
	"avatar__name": "_3Al9y",
	"table": "_3e91e",
	"table__notesCellEmpty": "t-VCL",
	"table__clinicCell": "FMTdy"
};
module.exports = exports;
