import { registerAction, unregisterAction } from 'helpers/reducerTools';
import { SIGN_OUT_SUCCESS } from 'modules/Account/actionTypes';
import * as actionTypes from './actionTypes';


const initialState = {
  syncPatientId    : null,
  syncHealthData   : null,
  authorizationCode: null,
  profile          : null,
  fetching         : [],
  sending          : [],
  errors           : [],
};


export default function reducer(state = { ...initialState }, action) {

  switch (action.type) {

    case actionTypes.AUTHORIZE: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.AUTHORIZE),
        errors : unregisterAction(state.errors, actionTypes.AUTHORIZE),
      };
    }
    case actionTypes.AUTHORIZE_SUCCESS: {
      const { code, storageProvider } = action.payload;
      return {
        ...state,
        authorizationCode: { code, storageProvider },
        sending          : unregisterAction(state.sending, actionTypes.AUTHORIZE),
        errors           : registerAction(state.errors, actionTypes.AUTHORIZE),
      };
    }
    case actionTypes.AUTHORIZE_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.AUTHORIZE),
        errors : registerAction(state.errors, actionTypes.AUTHORIZE),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.GET_PROFILE_SUCCESS: {
      const { profile } = action.payload;
      return {
        ...state,
        profile,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_PHI_SET: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_PHI_SET),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_PHI_SET),
      };
    }
    case actionTypes.FETCH_PHI_SET_SUCCESS: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_PHI_SET),
      };
    }
    case actionTypes.FETCH_PHI_SET_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_PHI_SET),
        errors  : registerAction(state.errors, actionTypes.FETCH_PHI_SET),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_READINGS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_READINGS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_READINGS),
      };
    }
    case actionTypes.FETCH_READINGS_SUCCESS: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_READINGS),
      };
    }
    case actionTypes.FETCH_READINGS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_READINGS),
        errors  : registerAction(state.errors, actionTypes.FETCH_READINGS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.STORE_READINGS: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.STORE_READINGS),
        errors : unregisterAction(state.errors, actionTypes.STORE_READINGS),
      };
    }
    case actionTypes.STORE_READINGS_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.STORE_READINGS),
      };
    }
    case actionTypes.STORE_READINGS_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.STORE_READINGS),
        errors : registerAction(state.errors, actionTypes.STORE_READINGS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.STORE_CGM_READINGS: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.STORE_CGM_READINGS),
        errors : unregisterAction(state.errors, actionTypes.STORE_CGM_READINGS),
      };
    }
    case actionTypes.STORE_CGM_READINGS_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.STORE_CGM_READINGS),
      };
    }
    case actionTypes.STORE_CGM_READINGS_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.STORE_CGM_READINGS),
        errors : registerAction(state.errors, actionTypes.STORE_CGM_READINGS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.STORE_MEASUREMENTS: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.STORE_MEASUREMENTS),
        errors : unregisterAction(state.errors, actionTypes.STORE_MEASUREMENTS),
      };
    }
    case actionTypes.STORE_MEASUREMENTS_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.STORE_MEASUREMENTS),
      };
    }
    case actionTypes.STORE_MEASUREMENTS_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.STORE_MEASUREMENTS),
        errors : registerAction(state.errors, actionTypes.STORE_MEASUREMENTS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.STORE_NOTES: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.STORE_NOTES),
        errors : unregisterAction(state.errors, actionTypes.STORE_NOTES),
      };
    }
    case actionTypes.STORE_NOTES_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.STORE_NOTES),
      };
    }
    case actionTypes.STORE_NOTES_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.STORE_NOTES),
        errors : registerAction(state.errors, actionTypes.STORE_NOTES),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.REMOVE_NOTE: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.REMOVE_NOTE),
        errors : unregisterAction(state.errors, actionTypes.REMOVE_NOTE),
      };
    }
    case actionTypes.REMOVE_NOTE_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.REMOVE_NOTE),
        errors : registerAction(state.errors, actionTypes.REMOVE_NOTE),
      };
    }

    case actionTypes.REMOVE_NOTES_QUEUE_FINISHED: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.REMOVE_NOTE),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.UPDATE_PHI_SET: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.UPDATE_PHI_SET),
        errors : unregisterAction(state.errors, actionTypes.UPDATE_PHI_SET),
      };
    }
    case actionTypes.UPDATE_PHI_SET_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_PHI_SET),
      };
    }
    case actionTypes.UPDATE_PHI_SET_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_PHI_SET),
        errors : registerAction(state.errors, actionTypes.UPDATE_PHI_SET),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SYNC: {
      return {
        ...state,
        syncHealthData: null,
        fetching      : registerAction(state.fetching, actionTypes.SYNC),
        errors        : unregisterAction(state.errors, actionTypes.SYNC),
      };
    }
    case actionTypes.SYNC_SUCCESS: {
      const { syncPatientId, syncHealthData } = action.payload;
      return {
        ...state,
        syncPatientId,
        syncHealthData,
        fetching: unregisterAction(state.fetching, actionTypes.SYNC),
      };
    }
    case actionTypes.SYNC_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.SYNC),
        errors  : registerAction(state.errors, actionTypes.SYNC),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_DUMP: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_DUMP),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_DUMP),
      };
    }
    case actionTypes.FETCH_DUMP_SUCCESS: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_DUMP),
      };
    }
    case actionTypes.FETCH_DUMP_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_DUMP),
        errors  : registerAction(state.errors, actionTypes.FETCH_DUMP),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.CLEAR_AUTHORIZATION_CODE: {
      return {
        ...state,
        authorizationCode: null,
        profile          : null,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case SIGN_OUT_SUCCESS: {
      return {
        ...initialState,
      };
    }


    default:
      return state;

  }
}
