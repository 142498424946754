import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import get from 'lodash/get';
import map from 'lodash/map';
import { AppContext } from 'context';
import { getSlug } from 'helpers/urlTools';
import Star from 'svg/star-filled.svg';
import Avatar from 'components/Avatar';
import Link from 'components/Link';
import SidebarPersonsLoader from 'components/Loaders/SidebarPersonsLoader';
import App from 'modules/App';
import Account from 'modules/Account';
import filterBySearch from 'modules/Hcp/partials/PatientsList/helpers/filterBySearch';
import * as selectors from '../../selectors';
import styles from './PatientsList.pcss';


class ActivePatients extends React.PureComponent {

  static contextType = AppContext;


  static getDerivedStateFromProps(props, state) {
    // eslint-disable-next-line react/prop-types
    const { patients, search, maxPatientLength } = props;
    return filterBySearch(patients, 'id', search, state, maxPatientLength, '', ['isFavorite'], ['desc']);
  }


  static propTypes = {
    // Explicit props
    id                     : PropTypes.string.isRequired,
    omitPatient            : PropTypes.object,
    activeClinicMembership : Account.shapes.clinicMembership,
    styleModifier          : PropTypes.oneOf(['halfSpace']),
    // Explicit actions
    onClick                : PropTypes.func,
    setActivePatientsLength: PropTypes.func,
    // Implicit props
    isInProgress           : PropTypes.bool,
    route                  : App.shapes.route,
  };

  static defaultProps = {
    setActivePatientsLength: () => {},
  };


  constructor(props) {
    super(props);
    this.state = {
      filteredCollection   : [],
      filteredCollectionIds: [], // eslint-disable-line react/no-unused-state
      search               : '', // eslint-disable-line react/no-unused-state
    };
  }


  componentDidMount() {
    const { filteredCollectionIds } = this.state;
    this.props.setActivePatientsLength(filteredCollectionIds.length);
  }


  componentDidUpdate(prevProps, prevState) {
    const { filteredCollectionIds } = this.state;
    if (prevState.filteredCollectionIds.length !== filteredCollectionIds.length) {
      this.props.setActivePatientsLength(filteredCollectionIds.length);
    }
  }


  renderAvatar(patient) {
    const { avatar, firstName, lastName } = patient;
    return (
      <Avatar
        avatarImg={avatar}
        name={[firstName, lastName]}
        className={styles.item__avatar}
        imgClassName={styles.item__avatar__img}
        initialsClassName={styles.item__avatar__initials}
      />
    );
  }


  renderPatient(patient) {
    if (!patient || (this.props.omitPatient && patient.id === this.props.omitPatient.id)) {
      return null;
    }
    const { patientId } = this.props.route.params;
    const { organizationUID, name } = get(this.props.activeClinicMembership, 'clinic', {});
    const clinicSlug = getSlug(name);
    const patientSlug = getSlug(`${patient.firstName} ${patient.lastName}`);

    return (
      <li
        key={patient.id}
        className={
          cn(
            styles.item,
            {
              [styles['item--active']]         : this.props.route.name === 'hcp-results' && patientId === patient.id,
              [styles['item--halfSpace']]      : this.props.styleModifier === 'halfSpace',
              [styles['item--halfSpaceActive']]: this.props.styleModifier === 'halfSpace'
            && this.props.route.name === 'hcp-results'
            && patientId === patient.id,
              [styles[`item__${this.props.id}`]]        : styles[`item__${this.props.id}`],
              [styles[`item__${this.props.id}--active`]]: styles[`item__${this.props.id}`] && patientId === patient.id,
            },
          )
        }
      >
        <Link
          to={
            this.context.getUrl('hcp-results', {
              clinicSlug,
              organizationUID,
              patientSlug,
              patientId: patient.id,
            })
          }
          onClick={
            (evt) => {
              if (this.props.onClick) {
                this.props.onClick(evt, patient);
              }
            }
          }
        >
          <div className="row flex-nowrap align-items-center">
            <div className="col-auto pr-2">
              { this.renderAvatar(patient) }
              { /* <span className={styles.item__notificationsBadge} /> */ }
            </div>
            <div className="col px-3">
              <p
                data-hj-suppress
                className={
                  cn(styles.item__name, {
                    [styles['item__name--withMenu']]        : patient.isFavorite,
                    [styles[`item__name--${this.props.id}`]]: styles[`item__name--${this.props.id}`],
                  })
                }
              >
                { patient.firstName } { patient.lastName }
              </p>
            </div>
            <div className="col-auto pl-2">
              <Star className={cn(styles.star, { [styles.star_active]: patient.isFavorite })} />
            </div>
          </div>
        </Link>
      </li>
    );
  }


  render() {
    if (this.props.isInProgress) {
      return <SidebarPersonsLoader itemsNumber={5} />;
    }
    return (
      <ul id={this.props.id} className={styles.items}>
        { map(this.state.filteredCollection, (patient) => this.renderPatient(patient)) }
      </ul>
    );
  }

}


const mapStateToProps = (state, props) => ({
  patients    : selectors.patients(state),
  search      : selectors.searchSelector(props.id)(state),
  isInProgress: selectors.isFetchPatientsInProgress(state),
  route       : App.selectors.route(state),
});


const ConnectedActivePatients = connect(mapStateToProps)(ActivePatients);


export default withStyles(styles)(ConnectedActivePatients);
