import { defineMessages } from 'react-intl';


export default defineMessages({

  activity: {
    label: { id: 'relatedData.activity.label' },

    labels: {
      duration: { id: 'relatedData.activity.labels.duration' },
      time    : { id: 'relatedData.activity.labels.time' },
      timeUnit: { id: 'relatedData.activity.labels.timeUnit' },
      type    : { id: 'relatedData.activity.labels.type' },
    },
  },

  meal: {
    labels: {
      carbs       : { id: 'relatedData.meal.labels.carbs' },
      protein     : { id: 'relatedData.meal.labels.protein' },
      fat         : { id: 'relatedData.meal.labels.fat' },
      nutrientUnit: { id: 'relatedData.meal.labels.nutrientUnit' },
      energy      : { id: 'relatedData.meal.labels.energy' },
      energyUnit  : { id: 'relatedData.meal.labels.energyUnit' },
    },
  },

  pill: {
    label: { id: 'relatedData.pill.label' },

    labels: {
      units: {
        injections: { id: 'relatedData.pill.labels.units.injections' },
        pills     : { id: 'relatedData.pill.labels.units.pills' },
        milligrams: { id: 'relatedData.pill.labels.units.milligrams' },
        units     : { id: 'relatedData.pill.labels.units.units' },
        none      : { id: 'relatedData.pill.labels.units.none' },
      },
    },
  },

  injection: {
    label: { id: 'relatedData.injection.label' },

    labels: {
      factActing   : { id: 'relatedData.injection.labels.fastActing' },
      injectionUnit: { id: 'relatedData.injection.labels.injectionUnit' },
      longActing   : { id: 'relatedData.injection.labels.longActing' },
    },
  },

});
