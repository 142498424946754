import PropTypes from 'prop-types';


export const direction = PropTypes.oneOf(['ltr', 'rtl']);

export const floatingModal = PropTypes.shape({
  floatingModalId: PropTypes.string.isRequired,
  relatedTo      : PropTypes.string.isRequired,
  place          : PropTypes.oneOf(['left', 'right', 'leftTop', 'rightTop']),
});

export const route = PropTypes.shape({
  action        : PropTypes.oneOf(['PUSH', 'POP', 'REPLACE']),
  name          : PropTypes.string,
  params        : PropTypes.object,
  previousName  : PropTypes.string,
  previousParams: PropTypes.object,
});


export const region = PropTypes.shape({
  name     : PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(PropTypes.string).isRequired,
});


export const country = PropTypes.shape({
  countryId          : PropTypes.number.isRequired,
  alpha2Code         : PropTypes.string.isRequired,
  defaultLanguageCode: PropTypes.string.isRequired,
  englishName        : PropTypes.string.isRequired,
  nameKey            : PropTypes.string,
});


export const device = PropTypes.shape({
  deviceId  : PropTypes.number.isRequired,
  name      : PropTypes.string.isRequired,
  type      : PropTypes.string.isRequired,
  sku       : PropTypes.string.isRequired,
  caseTypeId: PropTypes.number.isRequired,
  channels  : PropTypes.arrayOf(PropTypes.string).isRequired,
  protocol  : PropTypes.string.isRequired,
});


export const caseType = PropTypes.shape({
  caseTypeId: PropTypes.number.isRequired,
  name      : PropTypes.string.isRequired,
  icon      : PropTypes.string.isRequired,
});


export const language = PropTypes.shape({
  code       : PropTypes.string.isRequired,
  isRtl      : PropTypes.bool.isRequired,
  englishName: PropTypes.string.isRequired,
  key        : PropTypes.string,
});
