import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import App from 'modules/App';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from './BgmStatistics.pcss';


class BgmStatistics extends React.Component {

  static propTypes = {
    // Explicit props
    conversion      : PropTypes.object.isRequired,
    readingsCount   : PropTypes.number,
    readingsPerDay  : PropTypes.string,
    readingsAverage : PropTypes.number,
    preMealAverage  : PropTypes.number,
    postMealAverage : PropTypes.number,
    preMealCount    : PropTypes.number,
    postMealCount   : PropTypes.number,
    maxValue        : PropTypes.number,
    minValue        : PropTypes.number,
    CV              : PropTypes.number,
    // Implicit props
    highlightedDates: PropTypes.arrayOf(PropTypes.string),
  };


  renderDateRange() {
    const { highlightedDates } = this.props;
    const startTime = moment(highlightedDates[0], 'YYYY-MM-DD');
    const endTime = moment(highlightedDates[highlightedDates.length - 1], 'YYYY-MM-DD');
    return (
      <div className="statisticsSection">
        <div className="statisticsSection__row">
          <div className="statisticsSection__label">
            <p className="statisticsSection__elementHighlight">
              {startTime.format('DD  MMM YYYY')}
              {' - '}
              {endTime.format('DD  MMM YYYY')}
            </p>
          </div>
          <div className="statisticsSection__value">
            <p className="statisticsSection__elementHighlight">
              {endTime.diff(startTime, 'days') + 1}
              &nbsp;
              <FormattedMessage {...messages.labels.days} />
            </p>
          </div>
        </div>
      </div>
    );
  }


  renderAllReadingsCount() {
    return (
      <div className="statisticsSection__row">
        <div className="statisticsSection__label">
          <p className="statisticsSection__element">
            <FormattedMessage {...messages.labels.readingsCount} />
          </p>
        </div>
        <div className="statisticsSection__divider" />
        <div className="statisticsSection__value">
          <p className="statisticsSection__element">
            {this.props.readingsCount}
          </p>
        </div>
      </div>
    );
  }


  renderReadingsPerDay() {
    return (
      <div className="statisticsSection__row">
        <div className="statisticsSection__label">
          <p className="statisticsSection__element">
            <FormattedMessage {...messages.labels.readingsPerDay} />
          </p>
        </div>
        <div className="statisticsSection__divider" />
        <div className="statisticsSection__value">
          <p className="statisticsSection__element">
            {this.props.readingsPerDay}
          </p>
        </div>
      </div>
    );
  }


  renderAverageGlucose() {
    const {
      conversion,
    } = this.props;
    return (
      <div className="statisticsSection__row">
        <div className="statisticsSection__label">
          <p className="statisticsSection__element">
            <FormattedMessage {...messages.labels.averageGlucose} />
          </p>
        </div>
        <div className="statisticsSection__divider" />
        <div className="statisticsSection__value">
          <p className="statisticsSection__element">
            {`${this.props.readingsAverage} ${conversion.unitSymbol}`}
          </p>
        </div>
      </div>
    );
  }


  renderAverageBeforeMealGlucose() {
    const {
      conversion,
      preMealAverage,
      preMealCount,
    } = this.props;
    return (
      <div className="statisticsSection__row">
        <div className="statisticsSection__label">
          <p className="statisticsSection__element">
            <FormattedMessage {...messages.labels.averagePreMeal} />
            {preMealCount > 0 && (
              <>
                {' '}
                (<FormattedMessage {...messages.labels.checks} values={{ count: preMealCount }} />)
              </>
            )}
          </p>
        </div>
        <div className="statisticsSection__divider" />
        <div className="statisticsSection__value">
          <p className="statisticsSection__element">
            {
              preMealAverage
                ? `${preMealAverage} ${conversion.unitSymbol}`
                : <FormattedMessage {...messages.labels.unableToCalculate} />
            }
          </p>
        </div>
      </div>
    );
  }


  renderAveragePostMealGlucose() {
    const {
      conversion,
      postMealAverage,
      postMealCount,
    } = this.props;
    return (
      <div className="statisticsSection__row">
        <div className="statisticsSection__label">
          <p className="statisticsSection__element">
            <FormattedMessage {...messages.labels.averagePostMeal} />
            {
              postMealCount > 0
                && (
                  <>
                    {' '}
                    (<FormattedMessage {...messages.labels.checks} values={{ count: postMealCount }} />)
                  </>
                )
            }
          </p>
        </div>
        <div className="statisticsSection__divider" />
        <div className="statisticsSection__value">
          <p className="statisticsSection__element">
            {
              postMealAverage
                ? `${postMealAverage} ${conversion.unitSymbol}`
                : <FormattedMessage {...messages.labels.unableToCalculate} />
            }
          </p>
        </div>
      </div>
    );
  }


  renderLowestHighestReadingResult() {
    const {
      conversion,
    } = this.props;
    return (
      <div className="statisticsSection__row">
        <div className="statisticsSection__label">
          <p className="statisticsSection__element">
            <FormattedMessage {...messages.labels.lowestHighestReading} />
          </p>
        </div>
        <div className="statisticsSection__divider" />
        <div className="statisticsSection__value">
          <p className="statisticsSection__element">
            {`${this.props.minValue}/${this.props.maxValue} ${conversion.unitSymbol}`}
          </p>
        </div>
      </div>
    );
  }


  renderCV() {
    return (
      <div className="statisticsSection__row">
        <div className="statisticsSection__label">
          <p className="statisticsSection__element">
            <FormattedMessage {...messages.labels.CV} />
          </p>
        </div>
        <div className="statisticsSection__divider" />
        <div className="statisticsSection__value">
          <p className="statisticsSection__element">
            {`${this.props.CV}%`}
          </p>
        </div>
      </div>
    );
  }


  render() {
    const {
      highlightedDates,
    } = this.props;
    if (!highlightedDates.length) return null;
    return (
      <div className={styles.statisticsSection}>
        {this.renderDateRange()}
        <div className="statisticsSection">
          {this.renderAllReadingsCount()}
          {this.renderReadingsPerDay()}
          {this.renderAverageGlucose()}
          {this.renderAverageBeforeMealGlucose()}
          {this.renderAveragePostMealGlucose()}
          {this.renderLowestHighestReadingResult()}
          {this.renderCV()}
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  highlightedDates: selectors.highlightedDates(state),
  direction       : App.selectors.direction(state),
});


const ConnectedBgmStatistics = connect(
  mapStateToProps,
)(BgmStatistics);


export default withStyles(styles)(ConnectedBgmStatistics);
