import PropTypes from 'prop-types';


export const thresholdsShape = PropTypes.shape({
  lowThreshold : PropTypes.number.isRequired,
  highThreshold: PropTypes.number.isRequired,
});


export default PropTypes.shape({
  preMeal : thresholdsShape.isRequired,
  postMeal: thresholdsShape.isRequired,
  critical: thresholdsShape.isRequired,
});
