import chunk from 'lodash/chunk';
import flatten from 'lodash/flatten';
import forIn from 'lodash/forIn';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import snakeCase from 'lodash/snakeCase';
import { all, call, put, race, take, takeLatest, spawn, select } from 'redux-saga/effects';
import { createIntl, createIntlCache } from 'react-intl';
import MetricConversions from 'libs/MetricConversions';
import { createCSV, processCSVRow } from 'helpers/csv';
import { formatDate, formatTimestamp } from 'helpers/datetime';
import { calculateCarbs, calculateMedicationFastValue, calculateMedicationLongValue } from 'helpers/relatedData';
import CentralStorageService from 'services/CentralStorageService';
import CloudDriveService from 'services/CloudDriveService';
import { closeTransaction, openTransaction } from 'services/MdtcService';
import { decrypt, getKeyFromPem } from 'helpers/crypto';
import App from 'modules/App';
import Account from 'modules/Account';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as constants from './constants';
import messages from './messages';


export function* fetchDocumentId(referenceKey) {
  if (!referenceKey) {
    return null;
  }

  const transaction = yield call(openTransaction, [
    {
      lockType: 'Shared',
      referenceKey,
    },
  ]);

  const { transactionId } = transaction;
  const documentId = get(transaction, ['revisions', 0, 'documentId'], null);
  yield spawn(closeTransaction, transactionId);

  return documentId;
}


function initReadingsDownload(storageProvider) {
  if (storageProvider === 'CentralStorage') {
    return CentralStorageService.fetchReadings;
  }
  const driveService = CloudDriveService.getDriveService(storageProvider);
  return driveService.downloadFile;
}


function initRelatedDataDownload(storageProvider) {
  if (storageProvider === 'CentralStorage') {
    return CentralStorageService.fetchRelatedData;
  }
  const driveService = CloudDriveService.getDriveService(storageProvider);
  return driveService.downloadFile;
}


function* executeCalls(calls) {
  const chunkSize = 5;
  let data = [];
  const callsChunks = chunk(calls, chunkSize);
  for (let i = 0; i < callsChunks.length; i++) {
    const dataChunks = yield all(callsChunks[i]);
    data = [...data, ...flatten(dataChunks)];
  }
  return data;
}


function* getConversion() {
  const metricsUnits = yield select(Account.selectors.metricsUnits);
  const metricConversions = new MetricConversions(metricsUnits);
  return metricConversions.bloodGlucoseConcentration;
}


function* getIntl() {
  const locale = yield select(App.selectors.langCode);
  const translations = yield select(App.selectors.translations);
  const cache = createIntlCache();
  return createIntl({ locale, messages: translations }, cache);
}


function getFileName(clinic, exportName) {
  const { name } = clinic;
  return `${snakeCase(name)}-${snakeCase(exportName)}`;
}


function getHeadRow(schema, intl, conversion) {
  const labelGetters = {
    patient: {
      id         : () => intl.formatMessage(messages.labels.id),
      lastName   : () => intl.formatMessage(messages.labels.lastName),
      firstName  : () => intl.formatMessage(messages.labels.firstName),
      dateOfBirth: () => intl.formatMessage(messages.labels.dateOfBirth),
    },
    reading: {
      datetime  : () => intl.formatMessage(messages.labels.datetime),
      value     : () => `${intl.formatMessage(messages.labels.readingValue)} [${conversion.unitSymbol}]`,
      mealMarker: () => intl.formatMessage(messages.labels.mealMarker),
    },
    readingRelatedData: {
      carbs      : () => `${intl.formatMessage(messages.labels.carbs)} [g]`,
      insulinFast: () => intl.formatMessage(messages.labels.insulinFast),
      insulinLong: () => intl.formatMessage(messages.labels.insulinLong),
    },
  };

  return schema.map((schemaItem) => get(labelGetters, schemaItem, () => '')());
}


function getPatientProp(patient, key) {
  if (!patient) {
    return '';
  }
  switch (key) {
    case 'dateOfBirth': return patient.dateOfBirth ? formatDate(patient.dateOfBirth) : '';
    default: return patient[key] || '';
  }
}


function getReadingProp(reading, key, conversion, intl) {
  if (!reading) {
    return '';
  }
  switch (key) {
    case 'datetime': return reading.timestamp ? formatTimestamp(reading.timestamp, 'L LT') : '';
    case 'mealMarker': return reading.flags ? intl.formatMessage(App.messages.flags[reading.flags]) : '';
    case 'value': return conversion.toDisplay(reading.value);
    default: return reading[key] || '';
  }
}


function getReadingRelatedDataProp(readingRelatedData, key) {
  if (!readingRelatedData) {
    return '';
  }
  switch (key) {
    case 'carbs': return readingRelatedData.foods && readingRelatedData.foods.length
      ? calculateCarbs(readingRelatedData.foods)
      : '';
    case 'insulinFast': return readingRelatedData.medications
      ? (calculateMedicationFastValue(readingRelatedData.medications) || '')
      : '';
    case 'insulinLong': return readingRelatedData.medications
      ? (calculateMedicationLongValue(readingRelatedData.medications) || '')
      : '';
    default: return readingRelatedData[key] || '';
  }
}


function getRow(patient, reading, readingRelatedData, schema, conversion, intl) {
  return schema.map((schemaItem) => {
    const schemaItemParts = schemaItem.split('.');
    if (schemaItemParts[0] === 'patient') {
      return getPatientProp(patient, schemaItemParts[1]);
    }
    if (schemaItemParts[0] === 'reading') {
      return getReadingProp(reading, schemaItemParts[1], conversion, intl);
    }
    if (schemaItemParts[0] === 'readingRelatedData') {
      return getReadingRelatedDataProp(readingRelatedData, schemaItemParts[1], intl);
    }
    return '';
  });
}


function addRows(data, csvFileContent, schema, conversion, intl) {
  const { patient, readings, relatedData } = data || {};
  readings.forEach((reading) => {
    const readingRelatedData = relatedData && relatedData.find((item) => item.readingId === reading.externalId);
    const row = getRow(patient, reading, readingRelatedData, schema, conversion, intl);
    csvFileContent += processCSVRow(row);
  });
  return csvFileContent;
}


function* exportPatient(patient, schema, csvFileContent, prvKeyObj, storageProvider, accessToken, conversion, intl) {
  const { encryptedPhiSetReferenceKey } = patient;
  const phiSetReferenceKey = decrypt(encryptedPhiSetReferenceKey, prvKeyObj);
  const phiSetDocumentId = yield call(fetchDocumentId, phiSetReferenceKey, storageProvider, accessToken);
  const phiSet = yield call(CloudDriveService.fetchPhiSet, phiSetDocumentId, storageProvider, accessToken);
  if (isEmpty(phiSet.dataIndex)) {
    return csvFileContent;
  }
  const readingsDownload = initReadingsDownload(storageProvider);
  const relatedDataDownload = initRelatedDataDownload(storageProvider);
  const readingsCalls = [];
  const relatedDataCalls = [];
  const schemaSet = schema.reduce((acc, schemaItem) => acc.add(schemaItem.split('.')[0]), new Set());
  forIn(phiSet.dataIndex, (batch) => {
    const { readingsDocumentId, relatedDataDocumentId } = batch;
    if (readingsDocumentId && schemaSet.has('reading')) {
      readingsCalls.push(call(readingsDownload, readingsDocumentId, accessToken));
    }
    if (relatedDataDocumentId && schemaSet.has('readingRelatedData')) {
      relatedDataCalls.push(call(relatedDataDownload, relatedDataDocumentId, accessToken));
    }
  });

  let readings = yield call(executeCalls, readingsCalls);
  readings = readings.sort((a, b) => a.timestamp - b.timestamp);
  const relatedData = yield call(executeCalls, relatedDataCalls);
  csvFileContent = addRows({ patient, readings, relatedData }, csvFileContent, schema, conversion, intl);
  return csvFileContent;
}


function* exportData({ payload }) {
  try {
    const { exportName, patients, clinicMembership } = payload;
    const schema = constants.EXPORT_DEFINITIONS[exportName];
    const sortedPatients = patients.sort((a, b) =>
      `${a.lastName} ${a.firstName}`.localeCompare(`${b.lastName} ${b.firstName}`)
    );
    const { clinic = {}, encryptedPrivateKey, passphrase, accessToken } = clinicMembership;
    const { storageProvider } = clinic;
    const prvKeyObj = getKeyFromPem(encryptedPrivateKey, passphrase);
    const conversion = yield call(getConversion);
    const intl = yield call(getIntl);
    let csvFileContent = '';
    const headRow = getHeadRow(schema, intl, conversion);
    const patientsNumber = sortedPatients.length;
    csvFileContent += processCSVRow(headRow);
    for (let i = 0; i < sortedPatients.length; i++) {
      const [updatedCsvFileContent, exportCancel] = yield race([
        call(
          exportPatient,
          sortedPatients[i], schema, csvFileContent,
          prvKeyObj, storageProvider, accessToken,
          conversion, intl,
        ),
        take(actionTypes.EXPORT_DATA_CANCEL),
      ]);
      if (exportCancel) {
        return;
      }
      csvFileContent = updatedCsvFileContent;
      const progress = Math.floor(((i + 1) * 100) / patientsNumber);
      yield put(actions.setProgress(progress));
    }
    const title = intl.formatMessage(messages.buttons[exportName]);
    const fileName = getFileName(clinic, title);
    createCSV(fileName, csvFileContent);
    yield put(actions.exportDataSuccess());
  } catch (err) {
    yield put(actions.exportDataError(err));
    yield call(App.dispatchError, err);
  }
}


function* sagas() {
  yield takeLatest(actionTypes.EXPORT_DATA, exportData);
}


export default [
  sagas,
];
