export const timeIntervalsMap = [
  {
    mealName  : 'breakfast',
    valueIndex: 1,
  },
  {
    mealName  : 'lunch',
    valueIndex: 2,
  },
  {
    mealName  : 'dinner',
    valueIndex: 3,
  },
  {
    mealName  : 'night_pm',
    valueIndex: 4,
  },
];
