export function getCaretPosition(editableDiv) {
  let caretPos = 0;
  let sel; let range;
  if (window.getSelection) {
    sel = window.getSelection();
    if (sel.rangeCount) {
      range = sel.getRangeAt(0);
      if (range.commonAncestorContainer.parentNode === editableDiv) {
        caretPos = range.endOffset;
      }
    }
  } else if (document.selection && document.selection.createRange) {
    range = document.selection.createRange();
    if (range.parentElement() === editableDiv) {
      const tempEl = document.createElement('span');
      editableDiv.insertBefore(tempEl, editableDiv.firstChild);
      const tempRange = range.duplicate();
      tempRange.moveToElementText(tempEl);
      tempRange.setEndPoint('EndToEnd', range);
      caretPos = tempRange.text.length;
    }
  }
  return caretPos;
}

export function setCaretPosition(editableDiv, caretPos) {
  const range = document.createRange();
  const sel = window.getSelection();
  if (editableDiv.childNodes[0] instanceof Node) {
    range.setStart(editableDiv.childNodes[0], caretPos);
  }
  range.collapse(true);
  sel.removeAllRanges();
  sel.addRange(range);
  editableDiv.focus();
}


export const placeCaretAtEnd = (el) => {
  el.focus();
  if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
    const range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof document.body.createTextRange !== 'undefined') {
    const textRange = document.body.createTextRange();
    textRange.moveToElementText(el);
    textRange.collapse(false);
    textRange.select();
  }
};


export const trim = (text) => text.replace(/&nbsp;/g, '').trim();
