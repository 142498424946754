// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._5QMl_{border-bottom:1px solid #e0e8f2;padding-bottom:2.4rem;margin-bottom:4.8rem;text-align:right}", ""]);
// Exports
exports.locals = {
	"unlockBtnContainer": "_5QMl_"
};
module.exports = exports;
