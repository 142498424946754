import take from 'lodash/take';
import { getNoteTypeFromNoteKey } from 'helpers/notes';
import { NOTE_TYPES } from 'modules/App/constants';


const findTypeIndex = (note) => NOTE_TYPES.findIndex((t) => t === getNoteTypeFromNoteKey(note.noteType));


export const sortByNoteType = (a, b) => {
  const aIdx = findTypeIndex(a);
  const bIdx = findTypeIndex(b);
  if (aIdx < bIdx) return -1;
  if (bIdx > aIdx) return 1;
  return 0;
};


export const combineVisits = (props) => {
  const { activeVisit } = props;
  let visits = take(props.visits, 3).reverse();
  if (activeVisit) {
    visits = [activeVisit, ...visits];
  }
  return visits;
};
