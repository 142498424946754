import { buffers, END, eventChannel } from 'redux-saga';
import { call, take } from 'redux-saga/effects';


function arrayBufferToBase64(buffer) {
  const base64Flag = 'data:image/jpeg;base64,';
  const bytes = [].slice.call(new Uint8Array(buffer));
  let binary = '';
  bytes.forEach((b) => { binary += String.fromCharCode(b); });
  return `${base64Flag}${window.btoa(binary)}`;
}


const downloadImageChannel = (url) => eventChannel((emitter) => {
  const method = 'get';
  const responseType = 'arraybuffer';

  const xhr = new XMLHttpRequest();

  const onSuccess = (json) => {
    emitter({ success: true, response: json });
    emitter(END);
  };

  const onFailure = (response) => {
    emitter({ success: false, response });
    emitter(END);
  };

  xhr.addEventListener('error', onFailure);
  xhr.addEventListener('abort', onFailure);
  xhr.onreadystatechange = () => {
    const { readyState, status, response } = xhr;
    if (readyState === 4) {
      if (status === 200) {
        onSuccess(arrayBufferToBase64(response));
      } else {
        onFailure(response);
      }
    }
  };

  xhr.open(method, url, true);
  xhr.responseType = responseType;
  xhr.send();
  return () => {
    xhr.removeEventListener('error', onFailure);
    xhr.removeEventListener('abort', onFailure);
    xhr.onreadystatechange = null;
    xhr.abort();
  };

}, buffers.sliding(2));


export default function* fetchImage(url) {
  const downloadFileChannel = yield call(downloadImageChannel, url);

  while (true) {
    const { success, response, error } = yield take(downloadFileChannel);
    if (error) {
      throw new Error(error);
    }
    if (success) {
      return response;
    }
  }
}
