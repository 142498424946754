import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import times from 'lodash/times';
import styles from './SidebarPersonsLoader.pcss';


class SidebarPersonsLoader extends React.PureComponent {

  static propTypes = {
    itemsNumber: PropTypes.number,
  };


  static defaultProps = {
    itemsNumber: 3,
  };


  renderPerson(idx) {
    return (
      <li key={idx} className={`${styles.item} fadingLoader`}>
        <div className="row align-items-center">
          <div className="col-auto pr-0">
            <div className={styles.item__avatar} />
          </div>
          <div className="col">
            <div className={styles.item__textPlaceholder} />
          </div>
        </div>
      </li>
    );
  }


  render() {
    const { itemsNumber } = this.props;
    return <ul className={styles.items}>{ times(itemsNumber, (idx) => this.renderPerson(idx)) }</ul>;
  }

}

export default withStyles(styles)(SidebarPersonsLoader);
