import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import compact from 'lodash/compact';
import cn from 'classnames';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import toUpper from 'lodash/toUpper';
import Asset from '../Asset';
import styles from './Avatar.pcss';


class Avatar extends React.PureComponent {

  static propTypes = {
    // Explicit props
    avatarImg        : PropTypes.string,
    name             : PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    className        : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    imgClassName     : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    initialsClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };


  get plainName() {
    const { name } = this.props;
    if (!name) {
      return '';
    }
    if (isArray(name)) {
      return name.join(' ').trim();
    }
    return name.trim();
  }


  get hslColor() {
    const name = this.plainName;
    if (!name) {
      return null;
    }

    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return `hsl(${h}, 70%, 80%)`;
  }


  renderInitials() {
    let { name } = this.props;
    if (!name || isEmpty(name)) {
      return null;
    }

    const initials = [];

    if (!isArray(name)) {
      name = name.split(' ');
    }

    const cname = compact(name);

    for (let i = 0; i < Math.min(2, cname.length); i++) {
      initials.push(toUpper(cname[i][0]));
    }

    return <div className={cn(this.props.initialsClassName)}>{ initials.join('') }</div>;
  }


  render() {
    const { avatarImg, className, imgClassName } = this.props;
    const classNames = cn(styles.avatar__initials_container, imgClassName);

    const avatar = avatarImg
      ? <Asset className={cn(imgClassName)} src={avatarImg} alt={this.plainName} />
      : <div className={classNames} style={{ backgroundColor: this.hslColor }}>{ this.renderInitials() }</div>;

    return <div className={cn(className)}>{ avatar }</div>;
  }

}

export default withStyles(styles)(Avatar);
