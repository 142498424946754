import { createSelector } from 'reselect';
import includes from 'lodash/includes';
import * as constants from './constants';
import * as actionTypes from './actionTypes';


/**
 * Input selectors
 */
const mainSelector = (state) => state[constants.MODULE_ID];
const sccSelector = (state) => state[constants.MODULE_ID].scc;
const fetchingSelector = (state) => state[constants.MODULE_ID].fetching;
const sendingSelector = (state) => state[constants.MODULE_ID].sending;
const errorsSelector = (state) => state[constants.MODULE_ID].errors;


/**
 * Imported device data
 */
export const deviceData = createSelector(
  mainSelector,
  (state) => state && state.deviceData,
);

export const deviceDataType = createSelector(
  mainSelector,
  (state) => state && state.deviceDataType,
);

export const status = createSelector(
  mainSelector,
  (state) => state && state.status,
);

export const connectorType = createSelector(
  mainSelector,
  (state) => state && state.connectorType,
);

export const connectionId = createSelector(
  mainSelector,
  (state) => state && state.connectionId,
);

export const connectionStatus = createSelector(
  mainSelector,
  (state) => state && state.connectionStatus,
);

export const connectionError = createSelector(
  mainSelector,
  (state) => state && state.connectionError,
);

export const serialNumber = createSelector(
  mainSelector,
  (state) => state && state.deviceData && state.deviceData.serialNumber,
);

/**
 * Tray downloader
 */
export const downloader = createSelector(
  mainSelector,
  (state) => state && state.downloader,
);

/**
 * Sinovo Connection Center
 */
export const scc = createSelector(
  mainSelector,
  (state) => state && state.scc,
);

// SCC -----------------------------------------------------------------------------------------------------------------

/**
 * Sinovo Connection Center machine ID that specifies physical device on which downloader app is running.
 */
export const sccMachineId = createSelector(
  sccSelector,
  (state) => state.machineId,
);

/**
 * Sinovo Connection Center pairing code that is used to connection between browser and tray application
 */
export const sccPairingCode = createSelector(
  sccSelector,
  (state) => state.pairingCode,
);

/**
 * Sinovo Connection Center status that specifies if application is waiting for a job, processing or not responding
 */
export const sccStatus = createSelector(
  sccSelector,
  (state) => state.status,
);

/**
 * Sinovo Connection Center job status that specifies on what stage is requested job
 */
export const sccJobStatus = createSelector(
  sccSelector,
  (state) => state.jobStatus,
);

//----------------------------------------------------------------------------------------------------------------------

export const isCheckDownloaderInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.CHECK_STATUS) || includes(state, actionTypes.CHECK_BLUE_CABLE_DRIVER),
);

//----------------------------------------------------------------------------------------------------------------------

export const isInstallDownloaderInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.INSTALL_DOWNLOADER),
);

//----------------------------------------------------------------------------------------------------------------------

export const isSendBluetoothPinInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.SEND_BLUETOOTH_PIN),
);

export const hasSendBluetoothPinErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.SEND_BLUETOOTH_PIN),
);

//----------------------------------------------------------------------------------------------------------------------

export const isCheckSccInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.CHECK_SCC_STATUS),
);

//----------------------------------------------------------------------------------------------------------------------
