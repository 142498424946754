// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BHmP9{min-height:100vh;width:100%}.BHmP9,.IG3x9{display:-ms-flexbox;display:flex}.IG3x9{margin:auto;-ms-flex-direction:column;flex-direction:column}", ""]);
// Exports
exports.locals = {
	"root": "BHmP9",
	"root__container": "IG3x9"
};
module.exports = exports;
