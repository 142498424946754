/* eslint-disable */

import { FormattedMessage } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/withStyles';
// import AutoSizer from 'react-virtualized-auto-sizer';
// import { motionConfigContext, SmartMotion } from '@nivo/core';
// import { Line } from '@nivo/line';
// import { area } from 'd3-shape';
import range from 'lodash/range';
import { highlightedHourlyRecordsShape } from 'shapes/bins';
import messages from 'modules/AmbulatoryGlucoseProfile/messages';
import styles from './BloodGlucoseProfileReportChart.pcss';


/**
 * @deprecated
 */
class BloodGlucoseProfileReportChart extends React.PureComponent {

  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      minValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    highlightedHourlyRecords: highlightedHourlyRecordsShape,
    direction               : PropTypes.string,
  };


  constructor(props) {
    super(props);
    this.colors = {
      line           : '#1EA98C',
      median         : '#000',
      percentile     : '#CAD7EA',
      percentileInner: '#83A5CF',
      stroke         : '#4289C0',
    };
  }


  get chartData() {
    const { highlightedHourlyRecords, conversion } = this.props;
    return [
      {
        id  : 'Glucose Profile',
        data: highlightedHourlyRecords.records.map((hourlyRecord) => ({
          x   : Number(hourlyRecord.hour),
          y   : conversion.toDisplay(hourlyRecord.percentileStatistics[50]),
          p5  : conversion.toDisplay(hourlyRecord.percentileStatistics[5]),
          p25 : conversion.toDisplay(hourlyRecord.percentileStatistics[25]),
          p75 : conversion.toDisplay(hourlyRecord.percentileStatistics[75]),
          p95 : conversion.toDisplay(hourlyRecord.percentileStatistics[95]),
          hour: hourlyRecord.hour,
        })),
      },
    ];
  }


  // percentiles({ series, xScale, yScale }) {
  //   const outerPercentileGenerator = area()
  //     .x((d) => xScale(d.data.x))
  //     .y0((d) => yScale(d.data.p95))
  //     .y1((d) => yScale(d.data.p5));
  //   const innerPercentileGenerator = area()
  //     .x((d) => xScale(d.data.x))
  //     .y0((d) => yScale(d.data.p75))
  //     .y1((d) => yScale(d.data.p25));
  //   const lastPoint = series[0].data[series[0].data.length - 1];
  //   if (!lastPoint) return null;
  //
  //   return (
  //     <g opacity={1}>
  //       {
  //         lastPoint && (
  //           <>
  //             <text
  //               x={xScale(lastPoint.data.x) + 8}
  //               y={yScale(lastPoint.data.p5) + 4}
  //             >5%
  //             </text>
  //             <text
  //               x={xScale(lastPoint.data.x) + 8}
  //               y={yScale(lastPoint.data.p25) + 4}
  //             >25%
  //             </text>
  //             <text
  //               x={xScale(lastPoint.data.x) + 8}
  //               y={yScale(lastPoint.data.y) + 4}
  //             >50%
  //             </text>
  //             <text
  //               x={xScale(lastPoint.data.x) + 8}
  //               y={yScale(lastPoint.data.p75) + 4}
  //             >75%
  //             </text>
  //             <text
  //               x={xScale(lastPoint.data.x) + 8}
  //               y={yScale(lastPoint.data.p95) + 4}
  //             >95%
  //             </text>
  //           </>
  //         )
  //       }
  //
  //       <path
  //         d={outerPercentileGenerator(series[0].data)}
  //         fill={this.colors.percentile}
  //         fillOpacity={1}
  //         stroke={this.colors.stroke}
  //         strokeWidth={1}
  //         strokeDasharray="5,5"
  //       />
  //       <path
  //         d={innerPercentileGenerator(series[0].data)}
  //         fill={this.colors.percentileInner}
  //         fillOpacity={1}
  //       />
  //     </g>
  //   );
  // }


  renderTargetZone(props) {
    const { conversion, standards } = this.props;
    const lowThreshold = conversion.toDisplay(standards.postMeal.lowThreshold);
    const highThreshold = conversion.toDisplay(standards.postMeal.highThreshold);
    const width = props.width - props.margin.right - props.margin.left;
    const yTop = props.yScale(highThreshold);
    const yBottom = props.yScale(lowThreshold);

    return null;

    // return (
    //   <motionConfigContext.Consumer>
    //     { (springConfig) => (
    //       <SmartMotion
    //         key="bloodGlucoseConcentrationTargetZone"
    //         style={(spring) => ({
    //           width: spring(width, springConfig),
    //         })}
    //       >
    //         {(style) => (
    //           <g>
    //             <defs>
    //               <filter x="0" y="0" width="1" height="1" id="solid">
    //                 <feFlood floodColor="white" />
    //                 <feComposite in="SourceGraphic" operator="or" />
    //               </filter>
    //             </defs>
    //             <line
    //               x1="-35"
    //               y1={yTop}
    //               x2={style.width}
    //               y2={yTop}
    //               style={{ stroke: this.colors.line }}
    //             />
    //             <line
    //               x1="-35"
    //               y1={yTop}
    //               x2="-35"
    //               y2={yBottom}
    //               style={{ stroke: this.colors.line }}
    //             />
    //             <text
    //               x="0"
    //               y={yTop + (yBottom - yTop) / 2}
    //               style={{ transformOrigin: `0 ${yTop + (yBottom - yTop) / 2}px` }}
    //               className="targetReportAgpLabel"
    //               filter="url(#solid)"
    //             >
    //               <FormattedMessage {...messages.labels.targetRange} />
    //             </text>
    //             <line
    //               x1="-35"
    //               y1={yBottom}
    //               x2={style.width}
    //               y2={yBottom}
    //               style={{ stroke: this.colors.line }}
    //             />
    //           </g>
    //         )}
    //       </SmartMotion>
    //     )}
    //   </motionConfigContext.Consumer>
    // );
  }


  render() {
    return null;

    // const { direction, conversion, standards } = this.props;
    // const margin = {
    //   top   : 30,
    //   right : 40,
    //   bottom: 50,
    //   left  : 40,
    // };
    // const axisY = {
    //   tickSize    : 0,
    //   tickPadding : 0,
    //   tickRotation: 0,
    //   tickValues  : [0, 54, 70, 180, 250, 350].map(conversion.toDisplay),
    // };
    /*
      IMPORTANT: Because Edge doesn't support `dominant-baseline` and RTL support is poor
      hardcoded styles overwrite ticks labels transform for current tickPadding and tickRotation values and
      must be correlated if change
     */
    // return (
    //   <div className={`nivoChart ${styles.root}`}>
    //     <div
    //       className={`nivoChart__inner ${styles.root__inner}`}
    //     >
    //       <AutoSizer>
    //         {
    //           ({ height, width }) => (
    //             <Line
    //               data={this.chartData}
    //               height={height}
    //               width={width}
    //               margin={margin}
    //               stacked={false}
    //               xScale={{ type: 'linear', min: 0, max: 23, reverse: direction === 'rtl' }}
    //               yScale={{
    //                 type: 'linear',
    //                 min : 0,
    //                 max : conversion.toDisplay(standards.maxValue),
    //               }}
    //               gridYValues={[0, 54, 70, 180, 250, 350].map(conversion.toDisplay)}
    //               axisTop={null}
    //               axisRight={direction === 'ltr' ? null : axisY}
    //               axisLeft={direction === 'rtl' ? null : axisY}
    //               axisBottom={{
    //                 tickSize  : 5,
    //                 tickValues: range(24),
    //                 format    : (value) => (value % 3 ? null : moment(value, 'hh').format('ha')),
    //               }}
    //               gridXValues={[0, 23]}
    //               colors={[this.colors.median]}
    //               lineWidth={2}
    //               pointSize={0}
    //               tooltip={() => null}
    //               curve="linear"
    //               useMesh
    //               layers={[
    //                 'markers',
    //                 this.percentiles.bind(this),
    //                 this.renderTargetZone.bind(this),
    //                 'grid',
    //                 'axes',
    //                 'areas',
    //                 'lines',
    //                 'slices',
    //                 'mesh',
    //                 'legends',
    //               ]}
    //               theme={{
    //                 grid: {
    //                   line: {
    //                     stroke     : 'black',
    //                     strokeWidth: 0.5,
    //                   },
    //                 },
    //               }}
    //             />
    //           )
    //         }
    //       </AutoSizer>
    //     </div>
    //   </div>
    // );
  }

}


export default withStyles(styles)(BloodGlucoseProfileReportChart);
