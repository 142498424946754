import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import Button from 'components/Form/Button';
import PencilIcon from 'svg/pencil.svg';
import noteShape from 'shapes/noteShape';
import App from 'modules/App';
import Visit from 'modules/Visit';
import * as selectors from '../../selectors';
import messages from '../../messages';


class VisitsNotesBtn extends React.PureComponent {

  static propTypes = {
    // Explicit props
    phiSet: PropTypes.shape({
      visits: PropTypes.arrayOf(Visit.shapes.visit),
    }),
    // Implicit props
    floatingModal       : App.shapes.floatingModal,
    notes               : PropTypes.arrayOf(noteShape),
    activeVisit         : Visit.shapes.visit,
    isInProgress        : PropTypes.bool,
    // Implicit actions
    onOpenFloatingModal : PropTypes.func.isRequired,
    onCloseFloatingModal: PropTypes.func.isRequired,
  };


  onClick(evt) {
    evt.stopPropagation();
    if (this.props.floatingModal) {
      this.props.onCloseFloatingModal();
      return;
    }
    this.props.onOpenFloatingModal();
  }


  render() {
    const { activeVisit, notes, phiSet } = this.props;
    const visits = get(phiSet, 'visits', []);
    if (!activeVisit && (!visits.length || !notes.length)) {
      return null;
    }
    return (
      <Button
        id="VisitsNotesBtn"
        styleModifier="transparent-primary"
        onClick={(evt) => this.onClick(evt)}
      >
        <PencilIcon className="btn__icon mr-2" />
        <FormattedMessage {...messages.buttons.visitsNotes} />
      </Button>
    );
  }

}


const mapStateToProps = (state) => ({
  notes        : selectors.notes(state),
  activeVisit  : Visit.selectors.activeVisit(state),
  floatingModal: App.selectors.floatingModalSelector(Visit.constants.VISIT_NOTES_FM)(state),
});


const mapDispatchToProps = (dispatch) => ({
  onOpenFloatingModal: () => dispatch(
    App.actions.openFloatingModal(Visit.constants.VISIT_NOTES_FM, 'notesContainer', 'leftTop'),
  ),
  onCloseFloatingModal: () => dispatch(App.actions.closeFloatingModal(Visit.constants.VISIT_NOTES_FM)),
});


const ConnectedVisitsNotesBtn = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VisitsNotesBtn);


export default ConnectedVisitsNotesBtn;
