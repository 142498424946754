
import { HubConnectionBuilder, JsonHubProtocol, LogLevel } from '@microsoft/signalr'; // HttpTransportType
import { select, getContext } from 'redux-saga/effects';
import * as selectors from 'modules/App/selectors';
import * as constants from 'modules/App/constants';


let hubConnection = null;

function* getHubConnection() {
  if (hubConnection) {
    return hubConnection;
  }

  const signalRUrl = yield getContext('signalRUrl');
  const region = yield select(selectors.regionName);
  const url = `${signalRUrl}/${region}/${constants.SIGNALR_HUB_NAME}`;
  const cookies = yield getContext('cookies');

  const options = {
    accessTokenFactory: () => new Promise((resolve) => {
      const token = cookies.get('token');
      resolve(token);
    }),
  };

  hubConnection = new HubConnectionBuilder()
    .withUrl(url, options)
    .withHubProtocol(new JsonHubProtocol())
    .withAutomaticReconnect([10000, 30000, 120000, 600000])
    .configureLogging(__DEV__ ? LogLevel.Debug : LogLevel.Information)
    .build();
  return hubConnection;
}


function removeHubConnection() {
  hubConnection = null;
}

export default {
  getHubConnection,
  removeHubConnection,
};
