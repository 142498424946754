import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import cn from 'classnames';
import camelCase from 'lodash/camelCase';
import map from 'lodash/map';
import App from 'modules/App';
import FormGroup from 'components/Form/FormGroup';
import Select from 'components/Form/Select';
import Button from 'components/Form/Button';
import intlShape from 'shapes/intlShape';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from '../Results/Results.pcss';


class ChartControls extends React.PureComponent {

  static propTypes = {
    // Explicit props
    start             : PropTypes.number,
    end               : PropTypes.number,
    renderDatePresets : PropTypes.func,
    renderFlagFilters : PropTypes.func,
    isInProgress      : PropTypes.bool,
    // Implicit props
    mode              : PropTypes.oneOf(constants.MODES),
    deviceMode        : PropTypes.oneOf(constants.DEVICES_MODES),
    calculationFormula: PropTypes.oneOf(
      [...constants.CALCULATION_FORMULAS, ...constants.CALCULATION_FORMULAS_CGM]
    ),
    aggregateBy            : PropTypes.oneOf(constants.AGGREGATE_BY),
    groupBy                : PropTypes.oneOf([...constants.GROUP_BY, ...constants.GROUP_BY_CGM]),
    intl                   : intlShape,
    printMode              : PropTypes.bool,
    featureToggles         : PropTypes.arrayOf(PropTypes.string),
    // Implicit actions
    onSetMode              : PropTypes.func,
    onSetDeviceMode        : PropTypes.func,
    onSetCalculationFormula: PropTypes.func,
    onSetAggregateBy       : PropTypes.func,
    onSetGroupBy           : PropTypes.func,
  };


  renderButtonOptions(mode) {
    if (this.props.mode !== mode) {
      return null;
    }
    switch (mode) {
      case 'AGGREGATED':
        return (
          <>
            {this.renderCalculationFormulas()}
            {this.renderAggregateBy()}
          </>
        );
      case 'GROUPED':
        return (
          <>
            {this.renderCalculationFormulas()}
            {this.renderGroupBy()}
          </>
        );
      default:
        return null;
    }
  }


  renderPresentationTypes() {
    const { isInProgress } = this.props;
    return (
      <div>
        <p className="form-label"><FormattedMessage {...messages.labels.presentationTypes} /></p>
        <div className="row">
          <div className={cn('btn-group col-auto', styles.chartControls__wrapper)}>
            {
              map(constants.MODES, (mode, index) => (
                <Fragment key={`${mode}-presentation-button`}>
                  <Button
                    key={mode}
                    styleModifier="quinary"
                    className={cn('btn--filled', { 'text--primary': mode === this.props.mode })}
                    labelMessage={messages.buttons[mode.toLowerCase()]}
                    isDisabled={isInProgress}
                    onClick={() => this.props.onSetMode(mode)}
                  />
                  {this.renderButtonOptions(mode)}
                  {index < constants.MODES.length - 1 && (
                  <div className={styles.buttonsSeparator}>
                    <div />
                  </div>
                  )}
                </Fragment>
              ))
            }
          </div>
        </div>
      </div>
    );
  }


  renderCalculationFormulas() {
    const { mode, calculationFormula, isInProgress, intl } = this.props;
    const calculationFormulas = [...constants.CALCULATION_FORMULAS];
    if (mode === 'AGGREGATED') {
      calculationFormulas.shift();
    }
    return (
      <FormGroup
        id="calculationFormula"
        className={styles.sortOptions__option}
        formValues={{ values: { calculationFormula } }}
      >
        <Select
          optionsFrom={map(calculationFormulas, (formula) => ({
            value: formula,
            label: intl.formatMessage(messages.formulas[camelCase(formula)]),
          }))}
          selectedFieldClasses={styles.selectedFieldClasses}
          valueKey="value"
          labelKey="label"
          isDisabled={isInProgress}
          onChange={(input) => this.props.onSetCalculationFormula(input.value)}
        />
      </FormGroup>
    );
  }


  renderAggregateBy() {
    const { mode, aggregateBy, isInProgress, intl } = this.props;
    if (mode !== 'AGGREGATED') {
      return null;
    }

    return (
      <FormGroup
        id="aggregateBy"
        className={styles.sortOptions__option}
        formValues={{ values: { aggregateBy } }}
      >
        <Select
          optionsFrom={map(constants.AGGREGATE_BY, (unit) => ({
            value: unit,
            label: intl.formatMessage(messages.aggregateBy[unit.toLowerCase()]),
          }))}
          selectedFieldClasses={styles.selectedFieldClasses}
          valueKey="value"
          labelKey="label"
          isDisabled={isInProgress}
          onChange={(input) => this.props.onSetAggregateBy(input.value)}
        />
      </FormGroup>
    );
  }


  renderGroupBy() {
    const { mode, groupBy, isInProgress, intl } = this.props;
    if (mode !== 'GROUPED') {
      return null;
    }
    return (
      <FormGroup
        id="groupBy"
        className={styles.sortOptions__option}
        formValues={{ values: { groupBy } }}
      >
        <Select
          optionsFrom={map(constants.GROUP_BY, (type) => ({
            value: type,
            label: intl.formatMessage(messages.groupBy[type.toLowerCase()]),
          }))}
          selectedFieldClasses={styles.selectedFieldClasses}
          valueKey="value"
          labelKey="label"
          isDisabled={isInProgress}
          onChange={(input) => this.props.onSetGroupBy(input.value)}
        />
      </FormGroup>
    );
  }


  renderFilters() {
    return (
      <>
        <div>
          <p className="form-label"><FormattedMessage {...messages.labels.flagsFilters} /></p>
          { this.props.renderFlagFilters() }
        </div>
        <div>
          <p className="form-label"><FormattedMessage {...messages.labels.periodWithResults} /></p>
          { this.props.renderDatePresets() }
        </div>
      </>
    );
  }


  render() {
    const {
      printMode,
      isInProgress,
    } = this.props;

    if (printMode) return null;

    return (
      <div className={cn(styles.chartControls, { fadingLoader: isInProgress })}>
        <div className="row justify-content-between">
          { this.props.deviceMode !== 'AGP' && (
            <>
              { this.renderPresentationTypes() }
            </>
          )}
          { this.renderFilters() }
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  mode              : selectors.mode(state),
  deviceMode        : selectors.deviceMode(state),
  calculationFormula: selectors.calculationFormula(state),
  aggregateBy       : selectors.aggregateBy(state),
  groupBy           : selectors.groupBy(state),
  printMode         : App.selectors.printMode(state),
  featureToggles    : App.selectors.featureToggles(state),
});


const mapDispatchToProps = (dispatch) => ({
  onSetMode              : (mode) => dispatch(actions.setMode(mode)),
  onSetDeviceMode        : (deviceMode) => dispatch(actions.setDeviceMode(deviceMode)),
  onSetCalculationFormula: (calculationFormula) => dispatch(actions.setCalculationFormula(calculationFormula)),
  onSetAggregateBy       : (aggregateBy) => dispatch(actions.setAggregateBy(aggregateBy)),
  onSetGroupBy           : (groupBy) => dispatch(actions.setGroupBy(groupBy)),
});


const ConnectedChartControls = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ChartControls));


export default ConnectedChartControls;
