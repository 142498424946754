import argon2 from 'argon2-browser';
import { AES, enc } from 'crypto-js';
import Utf8 from 'crypto-js/enc-utf8';
import { KEYUTIL, KJUR } from 'jsrsasign';
import csprng from 'csprng';
import get from 'lodash/get';
import { all } from 'redux-saga/effects';


export const getPrvKeyPem = (prvKeyObj, passphrase) => KEYUTIL.getPEM(prvKeyObj, 'PKCS8PRV', passphrase, 'AES-256-CBC');

export const getPubKeyPem = (pubKeyObj) => KEYUTIL.getPEM(pubKeyObj);

export const getKeyFromPem = (keyPem, passphrase) => {
  try {
    return KEYUTIL.getKey(keyPem, passphrase);
  } catch (err) {
    return null;
  }
};


export function* generateKeyPair() {
  const KEY_LENGTH = 2048;
  const crypto = window.crypto || window.msCrypto;
  const SubtleCrypto = get(crypto, 'subtle');

  if (!SubtleCrypto) {
    return KEYUTIL.generateKeypair('RSA', KEY_LENGTH);
  }

  const { publicKey, privateKey } = yield SubtleCrypto.generateKey(
    {
      name          : 'RSA-OAEP',
      modulusLength : KEY_LENGTH,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash          : 'SHA-256',
    },
    true,
    ['encrypt', 'decrypt'],
  );

  const [privateKeyJwk, publicKeyJwk] = yield all([
    SubtleCrypto.exportKey('jwk', privateKey),
    SubtleCrypto.exportKey('jwk', publicKey),
  ]);

  const prvKeyObj = getKeyFromPem(privateKeyJwk);
  const pubKeyObj = getKeyFromPem(publicKeyJwk);

  return { prvKeyObj, pubKeyObj };
}


export const generateSecret = (bits = 512, radix = 36) => csprng(bits, radix);


export const generateHash = async (pass, salt, options = null) => {
  const defaultOptions = {
    time   : 6,
    mem    : 16384,
    hashLen: 32,
  };
  const hash = await argon2.hash({
    pass,
    salt,
    ...defaultOptions,
    ...options,
  });
  return hash.hashHex;
};

export const decodeToHex = (base64Text) => enc.Hex.stringify(enc.Base64.parse(base64Text));

export const encrypt = (text, pubKeyObj) => KJUR.crypto.Cipher.encrypt(text, pubKeyObj);

export const decrypt = (hex, prvKeyObj, RSAalgName = 'RSA') => KJUR.crypto.Cipher.decrypt(hex, prvKeyObj, RSAalgName);


export const symmetricEncrypt = (text, secret) => AES.encrypt(text, secret).toString();

export const symmetricDecrypt = (hex, secret) => {
  try {
    return AES.decrypt(hex, secret).toString(Utf8);
  } catch (err) {
    return null;
  }
};


export const hybridEncrypt = (text, pubKeyObj) => {
  const secret = generateSecret();
  const encryptedSecret = encrypt(secret, pubKeyObj);
  const hex = symmetricEncrypt(text, secret);
  return `${hex}||${encryptedSecret}`;
};

export const hybridDecrypt = (hybridHex, prvKeyObj) => {
  const [hex, encryptedSecret] = hybridHex.split('||');
  const secret = decrypt(encryptedSecret, prvKeyObj);
  return symmetricDecrypt(hex, secret);
};


