import PropTypes from 'prop-types';
import glucoseRangesShape from './glucoseRangesShape';


export const settings = PropTypes.shape({
  androidStoreURL: PropTypes.string.isRequired,
  iosStoreURL    : PropTypes.string.isRequired,
});


export default PropTypes.shape({
  countryId    : PropTypes.number.isRequired,
  glucoseRanges: PropTypes.shape({
    default: glucoseRangesShape,
  }),
  isPayerMandatory         : PropTypes.bool.isRequired,
  weight                   : PropTypes.string.isRequired,
  height                   : PropTypes.string.isRequired,
  pressure                 : PropTypes.string.isRequired,
  bloodGlucoseConcentration: PropTypes.string.isRequired,
  calcium                  : PropTypes.string.isRequired,
  cholesterol              : PropTypes.string.isRequired,
  creatinine               : PropTypes.string.isRequired,
  phosphorus               : PropTypes.string.isRequired,
  potassium                : PropTypes.string.isRequired,
  sodium                   : PropTypes.string.isRequired,
  triglycerides            : PropTypes.string.isRequired,
  filtration               : PropTypes.string.isRequired,
  CockcroftGaultFiltration : PropTypes.string.isRequired,
  HbA1C                    : PropTypes.string.isRequired,
  isLicenceMandatory       : PropTypes.bool.isRequired,
  settings,
});
