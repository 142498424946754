import forIn from 'lodash/forIn';
import reduce from 'lodash/reduce';
import values from 'lodash/values';
import { isAggregatedPostMeal } from 'libs/StatsCalculations';


export const getFromGlucoseLevelDistribution = (glucoseLevelDistribution) => {
  const levels = {
    preMeal: {
      high  : glucoseLevelDistribution.highPreMeal,
      target: glucoseLevelDistribution.targetPreMeal,
      low   : glucoseLevelDistribution.lowPreMeal,
    },
    postMeal: {
      high  : glucoseLevelDistribution.highPostMeal,
      target: glucoseLevelDistribution.targetPostMeal,
      low   : glucoseLevelDistribution.lowPostMeal,
    },
  };

  const preMeal = [
    {
      id   : 'high',
      label: 'high',
      value: glucoseLevelDistribution.highPreMeal,
    },
    {
      id   : 'target',
      label: 'target',
      value: glucoseLevelDistribution.targetPreMeal,
    },
    {
      id   : 'low',
      label: 'low',
      value: glucoseLevelDistribution.lowPreMeal,
    },
  ];

  const postMeal = [
    {
      id   : 'high',
      label: 'high',
      value: glucoseLevelDistribution.highPostMeal,
    },
    {
      id   : 'target',
      label: 'target',
      value: glucoseLevelDistribution.targetPostMeal,
    },
    {
      id   : 'low',
      label: 'low',
      value: glucoseLevelDistribution.lowPostMeal,
    },
  ];

  const preMealSum = reduce(values(levels.preMeal), (acc, value) => {
    acc += value;
    return acc;
  }, 0);

  const postMealSum = reduce(values(levels.postMeal), (acc, value) => {
    acc += value;
    return acc;
  }, 0);

  return {
    levels,
    glucoseLevelDistribution,
    preMeal,
    postMeal,
    preMealSum,
    postMealSum,
  };
};


export const getFromReadings = (readings, valueKey, standards) => {
  const levels = reduce(readings, (acc, reading) => {
    const flag = isAggregatedPostMeal(reading.flags) ? 'postMeal' : 'preMeal';
    const value = reading[valueKey];
    if (value > standards[flag].highThreshold) {
      acc[flag].high += 1;
    } else if (value < standards[flag].lowThreshold) {
      acc[flag].low += 1;
    } else {
      acc[flag].target += 1;
    }
    return acc;
  }, {
    preMeal: {
      high  : 0,
      target: 0,
      low   : 0,
    },
    postMeal: {
      high  : 0,
      target: 0,
      low   : 0,
    },
  });

  const preMeal = [];
  const postMeal = [];
  forIn(levels.preMeal, (value, id) => preMeal.push({ id, value, label: id }));
  forIn(levels.postMeal, (value, id) => postMeal.push({ id, value, label: id }));

  const preMealSum = reduce(preMeal, (acc, d) => { acc += d.value; return acc; }, 0);
  const postMealSum = reduce(postMeal, (acc, d) => { acc += d.value; return acc; }, 0);

  return {
    preMeal,
    postMeal,
    levels,
    readings,
    preMealSum,
    postMealSum,
  };
};
