import App from 'modules/App';
import Account from 'modules/Account';
import Information from 'modules/Information';
import Notifications from 'modules/Notifications';
import CloudDrive from 'modules/CloudDrive';
import DataSources from 'modules/DataSources';
import Hcp from 'modules/Hcp';
import Patient from 'modules/Patient';
import Statistics from 'modules/Statistics';
import ContourCloud from 'modules/ContourCloud';
import Layout from 'modules/Layout';


export function getCoreSagas() {
  return {
    [App.constants.MODULE_ID]          : App.sagas,
    [Account.constants.MODULE_ID]      : Account.sagas,
    [Information.constants.MODULE_ID]  : Information.sagas,
    [Notifications.constants.MODULE_ID]: Notifications.sagas,
    [CloudDrive.constants.MODULE_ID]   : CloudDrive.sagas,
    [DataSources.constants.MODULE_ID]  : DataSources.sagas,
    [Hcp.constants.MODULE_ID]          : Hcp.sagas,
    [Patient.constants.MODULE_ID]      : Patient.sagas,
    [Statistics.constants.MODULE_ID]   : Statistics.sagas,
    [ContourCloud.constants.MODULE_ID] : ContourCloud.sagas,
    [Layout.constants.MODULE_ID]       : Layout.sagas,
  };
}
