import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import map from 'lodash/map';
import get from 'lodash/get';
import first from 'lodash/first';
import FloatingModal from 'components/FloatingModal';
import App from 'modules/App';
import CloudDrive from 'modules/CloudDrive';
import Visit from 'modules/Visit';
import accessTokenShape from 'shapes/accessTokenShape';
import DropdownIcon from 'svg/dropdown.svg';
import EllipseIcon from 'svg/ellipse.svg';
import Hcp from 'modules/Hcp';
import Account from 'modules/Account';
import MetricConversions from 'libs/MetricConversions';
import { getEmptyResult, getLabelForResultType, getResultsOfType } from '../../helpers';
import * as constants from '../../constants';
import messages from '../../messages';
import BloodPressureForm from './Forms/BloodPressureForm';
import LdlForm from './Forms/LdlForm';
import TriglyceridesForm from './Forms/TriglyceridesForm';
import BmiForm from './Forms/BmiForm';
import * as styles from './AdditionalMeasurements.pcss';


class AdditionalMeasurements extends React.PureComponent {

  static propTypes = {
    // Explicit props
    activePatient: PropTypes.shape({
      id                : PropTypes.string,
      phiSetReferenceKey: PropTypes.string,
      storageProvider   : PropTypes.string,
      accessToken       : accessTokenShape,
    }),
    phiSet: PropTypes.shape({
      visits: PropTypes.arrayOf(Visit.shapes.visit),
    }),
    phiSetDocumentId    : PropTypes.string,
    // Implicit props
    floatingModal       : App.shapes.floatingModal,
    activeVisit         : Visit.shapes.visit,
    measurements        : PropTypes.array,
    metricsUnits        : PropTypes.object,
    // Implicit actions
    onSubmit            : PropTypes.func,
    onSuccess           : PropTypes.func,
    onCloseFloatingModal: PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.metricsConversions = new MetricConversions(props.metricsUnits);
    this.state = {
      [constants.RESULT_TYPE.BLOOD_PRESSURE]: { isExpanded: false },
      [constants.RESULT_TYPE.LDL]           : { isExpanded: false },
      [constants.RESULT_TYPE.TRIGLYCERIDES] : { isExpanded: false },
      [constants.RESULT_TYPE.BMI]           : { isExpanded: false },
    };
  }


  componentDidUpdate(prevProps) {
    const { activePatient, activeVisit, floatingModal } = this.props;
    if (
      floatingModal
      && (
        (prevProps.activeVisit !== activeVisit && !activeVisit)
        || (get(prevProps.activePatient, 'id') !== get(activePatient, 'id'))
      )
    ) {
      this.props.onCloseFloatingModal();
    }
  }


  onDropdownClick(type) {
    const isExpanded = get(this.state, `${type}.isExpanded`);
    this.setState({ [type]: { isExpanded: !isExpanded } });
  }


  renderHeading() {
    return (
      <div className={styles.additionalMeasurements__heading}>
        <h2 className="brand__h2"><FormattedMessage {...messages.headers.additionalMeasurements} /></h2>
        <div><FormattedMessage {...messages.infos.provideOtherResults} /></div>
      </div>
    );
  }


  renderRecentResult(recentResult) {
    const { value, unit, date, time } = recentResult;
    return (
      <div className={styles.additionalMeasurements__recentResult} key={`${date}-${time}-${value}-${unit}`}>
        <div>
          <div>
            <EllipseIcon />
          </div>
          <div>
            { value } { unit }
          </div>
        </div>
        <div className={styles.additionalMeasurements__recentResultDateTime}>
          <div>{ date }</div>
          <div>{ time }</div>
        </div>
      </div>
    );
  }


  renderForm(type) {
    const isExpanded = get(this.state, `${type}.isExpanded`);
    if (!isExpanded) {
      return null;
    }

    let Form;
    switch (type) {
      case constants.RESULT_TYPE.BLOOD_PRESSURE:
        Form = BloodPressureForm;
        break;
      case constants.RESULT_TYPE.LDL:
        Form = LdlForm;
        break;
      case constants.RESULT_TYPE.TRIGLYCERIDES:
        Form = TriglyceridesForm;
        break;
      case constants.RESULT_TYPE.BMI:
        Form = BmiForm;
        break;
      default:
        Form = null;
    }

    return (
      <Form
        patient={this.props.activePatient}
        phiSet={this.props.phiSet}
        phiSetDocumentId={this.props.phiSetDocumentId}
        metricsConversions={this.metricsConversions}
        onSubmit={this.props.onSubmit}
        onSuccess={this.props.onSuccess}
      />
    );
  }


  renderRecentResults(recentResults, type) {
    const isExpanded = get(this.state, `${type}.isExpanded`);
    if (!isExpanded || !recentResults.length) {
      return null;
    }

    return (
      <div className={styles.additionalMeasurements__recentResults}>
        <div className={styles.additionalMeasurements__recentResultsHeader}><FormattedMessage {...messages.labels.recentResults} /></div>
        { map(recentResults, (recentResult) => this.renderRecentResult(recentResult, type)) }
      </div>
    );
  }


  renderSection(type) {
    const results = getResultsOfType(this.props.measurements, type, this.metricsConversions);
    const { value, unit } = first(results) || getEmptyResult();
    const isExpanded = get(this.state, `${type}.isExpanded`);

    return (
      <div key={type} className={styles.additionalMeasurements__section}>
        <div className={styles.additionalMeasurements__sectionHeading}>
          <div className={styles.additionalMeasurements__sectionHeader}>
            <div>{ getLabelForResultType(type) }</div>
            <div className={styles.additionalMeasurements__latestValue}>
              { value } { unit }
            </div>
          </div>
          <div>
            <DropdownIcon
              className={cn(
                styles.additionalMeasurements__dropdown,
                { [styles['additionalMeasurements__dropdown--expanded']]: isExpanded },
              )}
              onClick={() => this.onDropdownClick(type)}
            />
          </div>
        </div>
        { this.renderForm(type) }
        { this.renderRecentResults(results, type) }
      </div>
    );
  }


  renderContent() {
    return (
      <div className={styles.additionalMeasurements}>
        { this.renderHeading() }
        <div className={styles.additionalMeasurements__content}>
          { this.renderSection(constants.RESULT_TYPE.BLOOD_PRESSURE) }
          <div className={styles.additionalMeasurements__separator} />
          { this.renderSection(constants.RESULT_TYPE.LDL) }
          <div className={styles.additionalMeasurements__separator} />
          { this.renderSection(constants.RESULT_TYPE.TRIGLYCERIDES) }
          <div className={styles.additionalMeasurements__separator} />
          { this.renderSection(constants.RESULT_TYPE.BMI) }
        </div>
      </div>
    );
  }


  render() {
    return (
      <FloatingModal
        floatingModal={this.props.floatingModal}
        className={cn(styles.floatingModal, {
          [styles['floatingModal--activeVisit']]: this.props.activeVisit && this.props.floatingModal,
        })}
        headerMessage={messages.headers.visitNotes}
        isDocked
        isFixed
        onClose={this.props.onCloseFloatingModal}
      >
        { this.renderContent() }
      </FloatingModal>
    );
  }

}


const mapStateToProps = (state) => ({
  metricsUnits : Account.selectors.metricsUnits(state),
  measurements : Hcp.selectors.measurements(state),
  activeVisit  : Visit.selectors.activeVisit(state),
  floatingModal: App.selectors.floatingModalSelector(constants.ADDITIONAL_MEASUREMENTS_FM)(state),
});


const mapDispatchToProps = (dispatch) => ({
  onSubmit: (measurements, phiSet, phiSetDocumentId, patient, visitData, successAction) => dispatch(
    CloudDrive.actions.storeMeasurements(measurements, phiSet, phiSetDocumentId, patient, visitData, successAction),
  ),
  onCloseFloatingModal: () => dispatch(App.actions.closeFloatingModal(constants.ADDITIONAL_MEASUREMENTS_FM)),
});


const ConnectedAdditionalMeasurements = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AdditionalMeasurements));


export default withStyles(styles)(ConnectedAdditionalMeasurements);
