import React, { FC, useEffect } from 'react';
import { MessageDescriptor } from 'react-intl';
import Hcp from 'modules/Hcp';
import messages from '../../messages';
import * as constants from '../../constants';


interface Props {
  onSetComponent: (componentName: string) => void,
  onSetHeaderMessage: (headerMessage?: MessageDescriptor) => void,
}


const AddPatient: FC<Props> = ({ onSetComponent, onSetHeaderMessage }) => {
  useEffect(() => {
    onSetHeaderMessage(messages.addPatient.header);
    return () => onSetHeaderMessage();
  }, []);

  const onClose = (newPatient) => {
    if (newPatient) {
      onSetComponent('DownloadSummary');
    }
  };

  return (
    <Hcp.partials.AddPatientForm
      modalId={constants.DOWNLOAD_DATA_MODAL}
      isAutoActivation
      onClose={onClose}
    />
  );
};


export default AddPatient;
