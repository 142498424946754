import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import get from 'lodash/get';
import PolygonIcon from 'svg/polygon.svg';
import PillsIcon from 'svg/pills.svg';
import messages from '../messages';
import * as shapes from '../shapes';
import styles from './PillTooltip.pcss';


class PillTooltip extends React.PureComponent {

  static propTypes = {
    // Explicit props
    pill: shapes.pill.isRequired,
  };


  get unitLabel() {
    const unit = get(this.props, 'pill.unit');
    switch (unit) {
      case 1:
        return <FormattedMessage {...messages.pill.labels.units.injections} />;
      case 2:
        return <FormattedMessage {...messages.pill.labels.units.pills} />;
      case 3:
        return <FormattedMessage {...messages.pill.labels.units.milligrams} />;
      case 4:
        return <FormattedMessage {...messages.pill.labels.units.units} />;
      default:
        return <FormattedMessage {...messages.pill.labels.units.none} />;
    }
  }


  renderTitle() {
    return (
      <div className={styles.pillTooltip__title}>
        <PillsIcon className={styles.pillTooltip__titleIcon} />
        <div className={styles.pillTooltip__titleText}>
          <FormattedMessage {...messages.pill.label} />
        </div>
      </div>
    );
  }


  renderPill() {
    const { pill } = this.props;
    return (
      <div className={styles.pillTooltip__detail}>
        <div className={styles.pillTooltip__detailType}>
          { pill.name }
        </div>
        <div className={styles.pillTooltip__detailValue}>
          { pill.value } { this.unitLabel }
        </div>
      </div>
    );
  }


  renderTime() {
    const { pill } = this.props;
    const time = moment.unix(pill.timestamp).utc().format('LT');

    return (
      <div className={styles.pillTooltip__detail}>
        <div className={styles.pillTooltip__detailType}>
          <FormattedMessage {...messages.activity.labels.time} />
        </div>
        <div className={styles.pillTooltip__detailValue}>
          { time }
        </div>
      </div>
    );
  }


  renderDetails() {
    return (
      <div className={styles.pillTooltip__details}>
        { this.renderPill() }
        { this.renderTime() }
      </div>
    );
  }


  render() {
    return (
      <div className={styles.pillTooltip}>
        <div className={styles.pillTooltip__content}>
          { this.renderTitle() }
          <div className={styles.pillTooltip__separator} />
          { this.renderDetails() }
          <PolygonIcon width="17" height="12" className={styles.pillTooltip__polygon} />
        </div>
      </div>
    );
  }

}


export default withStyles(styles)(PillTooltip);
