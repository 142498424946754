// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MSth8{height:80px;width:100%;position:relative}.MSth8:after,.MSth8:before{content:\"\";position:absolute;left:50%;height:5px;border-right:1px solid #000}.MSth8:before{top:0}.MSth8:after{bottom:0}._1FPq4{top:20px;top:2rem;bottom:20px;bottom:2rem;left:20px;left:2rem;right:20px;right:2rem}.print .nivoChart{height:300px}.MSth8 svg text{dominant-baseline:auto!important}.MSth8 svg text[text-anchor=middle]{-webkit-transform:translateY(18px) rotate(0)!important;-ms-transform:translateY(18px) rotate(0)!important;transform:translateY(18px) rotate(0)!important}.MSth8 svg text[text-anchor=end]{-webkit-transform:translate(-10px,4px) rotate(0)!important;-ms-transform:translate(-10px,4px) rotate(0)!important;transform:translate(-10px,4px) rotate(0)!important}.MSth8 svg text[text-anchor=start]{-webkit-transform:translate(10px,4px) rotate(0)!important;-ms-transform:translate(10px,4px) rotate(0)!important;transform:translate(10px,4px) rotate(0)!important}", ""]);
// Exports
exports.locals = {
	"root": "MSth8",
	"root__inner": "_1FPq4"
};
module.exports = exports;
