import isEqual from 'lodash/isEqual';


export const returnDifferentInHealthData = (phiSet, patientCC) => {
  const healthData = {};
  if (!patientCC) return healthData;
  if (patientCC.glucoseLevelTargets && !isEqual(phiSet.glucoseLevelTargets, patientCC.glucoseLevelTargets)) {
    healthData.glucoseLevelTargets = patientCC.glucoseLevelTargets;
  }

  if (patientCC.diabetesType && !isEqual(phiSet.diabetesType, patientCC.diabetesType)) {
    healthData.diabetesType = patientCC.diabetesType;
  }

  if (patientCC.treatmentType && !isEqual(phiSet.treatmentType, patientCC.treatmentType)) {
    healthData.treatmentType = patientCC.treatmentType;
  }

  return healthData;
};

export const returnDifferentInProfile = (patient, patientCC) => {
  const patientProfile = {};
  if (!patientCC) return patientProfile;
  if (patientCC.birthdate && !isEqual(patient.dateOfBirth, patientCC.birthdate)) {
    patientProfile.dateOfBirth = patientCC.birthdate;
  }

  return patientProfile;
};

// export const returnDifferentInAccount = (account, patientCC) => {
//   const accountPatient = {};
//   if (!patientCC) return accountPatient;
//   if (patientCC.gender && !isEqual(account.gender, patientCC.gender)) {
//     accountPatient.gender = patientCC.gender;
//   }
//
//   return accountPatient;
// };
