import { UserManager, Log } from 'oidc-client';
import jwtDecode from 'jwt-decode';
import clearStorages from 'helpers/clearStorages';


const manager = process.env.BROWSER ? new UserManager(window.App.oidc) : null;

if (__DEV__) {
  Log.logger = console;
  // Log.level = Log.DEBUG;
}

if (manager) {
  manager.events.addUserSignedOut(() => {
    manager.removeUser();
    clearStorages();
    manager.signinRedirect();
  });
}


// async function init() {
//   const user = await manager.getUser();
//   console.log('User', user);
//   if (user) {
//     console.log('User logged in', user.profile);
//   } else {
//     console.log('User not logged in');
//   }
//   return user;
// }


async function login() {
  await manager.signinRedirect();
}


async function loginCallback() {
  await manager.signinRedirectCallback();
}


async function loginSilentCallback() {
  await manager.signinSilentCallback();
}


async function logout() {
  await manager.signoutRedirect();
}


// async function logoutCallback() {
//   const manager = new UserManager({ response_mode: 'query' });
//   await manager.signoutRedirectCallback();
//   const user = await manager.getUser();
//   if (user) {
//     console.log('User logged in', user.profile);
//   } else {
//     console.log('User not logged in');
//   }
// }


async function clearStaleState() {
  await manager.clearStaleState();
}


async function getUser() {
  return manager && manager.getUser();
}


async function getToken() {
  const user = await getUser();
  if (!user) return null;
  return user.access_token;
}


async function getTokenData() {
  const token = await getToken();
  if (!token) return null;
  return jwtDecode(token);
}


const exporter = (func) => (
  process.env.BROWSER
    ? func
    : Promise.resolve(true)
);


export default {
  login              : exporter(login),
  loginCallback      : exporter(loginCallback),
  loginSilentCallback: exporter(loginSilentCallback),
  logout             : exporter(logout),
  clearStaleState    : exporter(clearStaleState),
  getUser            : exporter(getUser),
  getToken,
  getTokenData       : exporter(getTokenData),
};
