export const MODULE_ID = 'notifications';

export const NOTIFICATIONS_LIST_LENGTH = 10;

export const HIDDEN_NOTIFICATIONS = [
  'ClinicPatientProfileNewStatisticalDataAdded',
];

export const NOTIFICATIONS_DROPDOWN = `${MODULE_ID}/NOTIFICATIONS_DROPDOWN`;

export const NOTIFICATIONS_SETTINGS_MODAL = `${MODULE_ID}/NOTIFICATIONS_SETTINGS_MODAL`;
export const NOTIFICATIONS_HISTORY_MODAL = `${MODULE_ID}/NOTIFICATIONS_HISTORY_MODAL`;

export const NOTIFICATIONS_SETTINGS_FORM = `${MODULE_ID}/NOTIFICATIONS_SETTINGS_FORM`;

export const NOTIFICATIONS_TRIGGERS = {
  CLINIC_PATIENT_PROFILE_NEW_STATISTICAL_DATA_ADDED  : 'ClinicPatientProfileNewStatisticalDataAdded',
  CLINIC_PATIENT_PROFILE_CREATED                     : 'ClinicPatientProfileCreated',
  CLINIC_PATIENT_PROFILE_NEW_DATA_SYNCHRONIZED       : 'ClinicPatientProfileNewDataSynchronized',
  CLINIC_PATIENT_PROFILE_NEW_DATA_ADDED              : 'ClinicPatientProfileNewDataAdded',
  CLINIC_HCP_MEMBERSHIP_CREATED                      : 'ClinicHcpMembershipCreated',
  CLINIC_MEMBERSHIP_APPROVED                         : 'ClinicMembershipApproved',
  CLINIC_MEMBERSHIP_REJECTED                         : 'ClinicMembershipRejected',
  INVALID_CLINIC_CLOUD_DRIVE_ACCESS                  : 'InvalidClinicCloudDriveAccess',
  SHARING_REQUEST_ACCESS_REVOKED                     : 'SharingRequestAccessRevoked',
  SHARING_REQUEST_ACCEPTED                           : 'SharingRequestAccepted',
  SHARING_REQUEST_ACCEPTED_BY_HCP                    : 'SharingRequestAcceptedByHcp',
  SHARING_REQUEST_REJECTED                           : 'SharingRequestRejected',
  SHARING_REQUEST_EXTERNAL_DATA_SOURCE_ACCESS_REVOKED: 'SharingRequestExternalDataSourceAccessRevoked',
  RECEIVED_HCP_INVITATION                            : 'ReceivedHcpInvitation',
  HCP_INVITATION_APPROVED                            : 'HcpInvitationApproved',
  HCP_INVITATION_REJECTED                            : 'HcpInvitationRejected',
  PWD_INVITATION_CREATED                             : 'PwdInvitationCreated',
  PWD_INVITATION_FOUNDED                             : 'PwdInvitationFounded',
  PWD_INVITATION_REVOKED                             : 'PwdInvitationRevoked',
  NEW_PWD_DATA_FROM_SHARE_LINK_DOWNLOADED            : 'NewPwdDataFromShareLinkDownloaded',
  NOTE_WRITTEN_BY_HCP                                : 'NoteWrittenByHcp',
  NOTE_WRITTEN_BY_PWD                                : 'NoteWrittenByPwd',
};
