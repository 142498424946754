import React, { FC } from 'react';
import Capability from './Capability';


interface Props {
  capabilities: ExternalDataSourceCapability[],
}


const Capabilities: FC<Props> = ({ capabilities }) => (
  <table className="section__table mt-4">
    <tbody className="section__table__body">
      {
        capabilities.map((capability) => (
          <Capability
            key={capability.externalDataSourceCapabilityId}
            capability={capability}
          />
        ))
      }
    </tbody>
  </table>
);

export default Capabilities;
