import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import { AppContext } from 'context';
import Avatar from 'components/Avatar';
import Account from 'modules/Account';
import messages from '../../../messages';
import layoutStyles from '../MainLayout.pcss';
import styles from './ClinicInfo.pcss';


class ClinicInfo extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    clinic                         : PropTypes.object.isRequired,
    isClinicNotConfigured          : PropTypes.bool,
    isClinicPending                : PropTypes.bool,
    isMembershipPending            : PropTypes.bool,
    // Implicit actions
    onManageOrganizationMemberships: PropTypes.func,
  };


  onManageOrganizationMemberships(evt) {
    evt.preventDefault();
    this.props.onManageOrganizationMemberships();
  }


  get infoMessage() {
    const { isClinicNotConfigured, isClinicPending, isMembershipPending } = this.props;

    if (isClinicNotConfigured) {
      return messages.infos.clinicNotConfigured;
    }
    if (isClinicPending) {
      return messages.infos.waitForApprovalCreate;
    }
    if (isMembershipPending) {
      return messages.infos.waitForApprovalJoin;
    }
    return null;
  }


  renderIntro() {
    const { isClinicPending, isMembershipPending } = this.props;
    let message;

    if (isClinicPending) {
      message = messages.infos.wantToCreate;
    }

    if (isMembershipPending) {
      message = messages.infos.wantToJoin;
    }

    if (!message) {
      return null;
    }

    return <p><FormattedMessage {...message} /></p>;
  }


  renderInfo() {
    const { infoMessage } = this;
    if (!infoMessage) {
      return null;
    }

    const apLink = (chunk) => (
      <a href="" onClick={(evt) => this.onManageOrganizationMemberships(evt)}>
        { chunk }
      </a>
    );

    return (
      <p className={styles.info}>
        <FormattedMessage {...infoMessage} values={{ apLink }} />
      </p>
    );
  }


  renderLogo(clinic) {
    const { logo, name } = clinic;
    return (
      <div className={styles.avatar}>
        <Avatar
          avatarImg={logo}
          name={name}
          className={styles.avatar__imgWrapper}
          imgClassName={styles.avatar__img}
          initialsClassName={styles.avatar__initials}
        />
      </div>
    );
  }


  render() {
    const { clinic } = this.props;
    return (
      <div className={layoutStyles.container}>
        <div className={layoutStyles.containerInner}>
          <div className="content">
            <div className={styles.root}>
              { this.renderLogo(clinic) }
              { this.renderIntro() }
              <p className={styles.clinicName}>{ clinic.name }</p>
              { this.renderInfo() }
            </div>
          </div>
        </div>
      </div>
    );
  }

}


const mapDispatchToProps = (dispatch) => ({
  onManageOrganizationMemberships: () => dispatch(Account.actions.manageAidOrganizationMemberships()),
});

const ConnectedClinicInfo = connect(
  null,
  mapDispatchToProps,
)(ClinicInfo);

export default withStyles(styles)(ConnectedClinicInfo);
