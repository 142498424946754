import React, { FC, useEffect } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import Button from 'components/Form/Button';
import messages from '../../messages';
import styles from './DownloadDataModal.pcss';


interface Props {
  onSetComponent: (componentName: string) => void,
  onSetHeaderMessage: (headerMessage?: MessageDescriptor) => void,
}


const NoLicense: FC<Props> = ({ onSetComponent, onSetHeaderMessage }) => {
  const tryAgainLabel = <FormattedMessage {...messages.buttons.tryAgain} />;
  const onTryAgain = () => onSetComponent('Initializing');

  useEffect(() => {
    onSetHeaderMessage(messages.headers.noLicense);
    return () => onSetHeaderMessage();
  }, []);

  return (
    <div className={styles.modal__content}>
      <h3 className="modal__subheader "><FormattedMessage {...messages.sccNoLicense.header} /></h3>
      <p className="modal__info">
        <FormattedMessage {...messages.sccNoLicense.info} values={{ tryAgainLabel }} />
      </p>
      <div className={styles.illustration}>
        <img className={styles.centralImage} src="/assets/svg/lock.svg" alt="" />
      </div>
      <div className="modal__actions">
        <Button
          styleModifier="primary"
          labelMessage={messages.buttons.tryAgain}
          className="btn--block btn--filled"
          onClick={onTryAgain}
        />
      </div>
    </div>
  );
};

export default NoLicense;
