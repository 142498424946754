import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import AmbulatoryGlucoseProfile from 'modules/AmbulatoryGlucoseProfile';
import messages from 'modules/AmbulatoryGlucoseProfile/messages';
import * as patientResultsConstants from 'modules/PatientResults/constants';
import SectionTitle from '../components/SectionTitle';
import styles from './StatisticsSection.pcss';


class StatisticsSection extends React.Component {

  static propTypes = {
    // Explicit props
    conversion     : PropTypes.object.isRequired,
    readingsCount  : PropTypes.number,
    readingsPerDay : PropTypes.string,
    readingsAverage: PropTypes.number,
    preMealAverage : PropTypes.number,
    postMealAverage: PropTypes.number,
    preMealCount   : PropTypes.number,
    postMealCount  : PropTypes.number,
    maxValue       : PropTypes.number,
    minValue       : PropTypes.number,
    CV             : PropTypes.number,
  };


  renderGlucoseTargetRanges() {
    const {
      conversion,
    } = this.props;

    return (
      <div className={styles.statisticsSection}>
        <div className="statisticsSection">
          <div className="statisticsSection__row">
            <div className="statisticsSection__label">
              <p className="statisticsSection__element">
                <FormattedMessage {...messages.labels.preMealTarget} />
              </p>
            </div>
            <div className="statisticsSection__divider" />
            <div className="statisticsSection__value">
              <p className="statisticsSection__element">
                {`${patientResultsConstants.BEFORE_MEAL_TARGET[conversion.unit].min}
                -
                ${patientResultsConstants.BEFORE_MEAL_TARGET[conversion.unit].max}
                 ${conversion.unitSymbol}`}
              </p>
            </div>
          </div>
          <div className="statisticsSection__row">
            <div className="statisticsSection__label">
              <p className="statisticsSection__element">
                <FormattedMessage {...messages.labels.postMealTarget} />
              </p>
            </div>
            <div className="statisticsSection__divider" />
            <div className="statisticsSection__value">
              <p className="statisticsSection__element">
                <FormattedMessage {...messages.labels.lessThan} />
                {` ${patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].high}
                 ${conversion.unitSymbol}`}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }


  render() {
    return (
      <div className={styles.statisticsSectionWrapper}>
        <SectionTitle>
          <FormattedMessage {...messages.headers.bgmStatistics} />
        </SectionTitle>
        <AmbulatoryGlucoseProfile.components.BgmStatistics
          conversion={this.props.conversion}
          readingsCount={this.props.readingsCount}
          readingsPerDay={this.props.readingsPerDay}
          readingsAverage={this.props.readingsAverage}
          preMealAverage={this.props.preMealAverage}
          postMealAverage={this.props.postMealAverage}
          preMealCount={this.props.preMealCount}
          postMealCount={this.props.postMealCount}
          maxValue={this.props.maxValue}
          minValue={this.props.minValue}
          CV={this.props.CV}
        />
        <SectionTitle>
          <FormattedMessage {...messages.headers.bgmTargets} />
        </SectionTitle>
        {this.renderGlucoseTargetRanges()}
      </div>
    );
  }

}

export default withStyles(styles)(StatisticsSection);
