import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import ActivityIcon from 'svg/activity.svg';
import MealIcon from 'svg/meal.svg';
import InjectionIcon from 'svg/injection.svg';
import PillsIcon from 'svg/pills.svg';
import styles from './RelatedData.pcss';


class RelatedData extends React.PureComponent {

  static propTypes = {
    // Explicit props
    data: PropTypes.shape({
      id  : PropTypes.string.isRequired,
      type: PropTypes.oneOf([
        'activity',
        'food',
        'injection',
        'pill',
      ]).isRequired,
      name       : PropTypes.string,
      description: PropTypes.string,

    }),
  };


  get icon() {
    const type = get(this.props, 'data.type');
    switch (type) {
      case 'activity':
        return <ActivityIcon />;
      case 'food':
        return <MealIcon />;
      case 'injection':
        return <InjectionIcon />;
      case 'pill':
        return <PillsIcon />;
      default:
        return null;
    }
  }


  render() {
    const { icon } = this;
    return (
      <div className={styles.relatedData}>
        { icon }
      </div>
    );
  }

}


export default withStyles(styles)(RelatedData);
