import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Modal from 'components/Modal';
import App from 'modules/App';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import InviteFamilyMembersForm from '../../partials/InviteFamilyMembersForm';
import styles from './InviteFamilyMembersModal.pcss';


class InviteFamilyMembersModal extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const { isInProgress, hasErrors } = props;

    if (isInProgress !== state.isInProgress && (isInProgress || hasErrors)) {
      return {
        isInProgress,
      };
    }
    return null;
  }


  static propTypes = {
    // Implicit props
    formValues  : PropTypes.object,
    isInProgress: PropTypes.bool,
    hasErrors   : PropTypes.bool,
    openModalId : PropTypes.string,
    // Implicit actions
    onClearForm : PropTypes.func,
    onCloseModal: PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      isInProgress: false,
    };
  }


  componentDidUpdate(prevProps) {
    const { isInProgress, hasErrors } = this.props;
    if (prevProps.isInProgress !== isInProgress && !isInProgress && !hasErrors) {
      this.onClose();
    }
  }


  async onClose() {
    await this.props.onCloseModal();
    this.props.onClearForm();
    this.setState({ isInProgress: false });
  }


  renderActions() {
    return (
      <div className="modal__actions mt-0">
        { this.renderSubmitBtn() }
      </div>
    );
  }


  render() {
    return (
      <Modal
        modalId={constants.INVITE_FAMILY_MEMBERS_MODAL}
        openModalId={this.props.openModalId}
        styleModifier="md"
        onClose={() => this.onClose()}
      >
        <h2 className={cn('modal__header', styles.header)}>
          <FormattedMessage {...messages.headers.inviteFamilyMembers} />
        </h2>
        <App.components.AlertsBus className="mb-4" />
        <InviteFamilyMembersForm
          persons={1}
          isInProgress={this.state.isInProgress}
          renderActions={this.renderActions} // inject
        />
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  isInProgress: selectors.isInviteFamilyMembersInProgress(state),
  hasErrors   : selectors.hasInviteFamilyMembersErrors(state),
  formValues  : App.selectors.formSelector(constants.INVITE_FAMILY_MEMBERS_FORM)(state),
  openModalId : App.selectors.modal(state),
});


const mapDispatchToProps = (dispatch) => ({
  onClearForm : () => dispatch(App.actions.clearForm(constants.INVITE_FAMILY_MEMBERS_FORM)),
  onCloseModal: () => dispatch(App.actions.closeModal()),
});


const ConnectedInviteFamilyMembersModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InviteFamilyMembersModal);


export default withStyles(styles)(ConnectedInviteFamilyMembersModal);
