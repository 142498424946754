import includes from 'lodash/includes';
import { createSelector } from 'reselect';
import * as actionTypes from './actionTypes';
import * as constants from './constants';

/**
 * Input selectors
 */
const mainSelector = (state) => state[constants.MODULE_ID];
const sendingSelector = (state) => state[constants.MODULE_ID].sending;
const errorsSelector = (state) => state[constants.MODULE_ID].errors;


/**
 * Selects user's RED / GED information
 */
export const information = createSelector(
  mainSelector,
  (state) => state.information,
);


/**
 * Selects user's information template
 */
export const informationTemplate = createSelector(
  mainSelector,
  (state) => state.informationTemplate,
);


/**
 * Selects consentsTemplate template
 */
export const consentsTemplate = createSelector(
  mainSelector,
  (state) => state.consentsTemplate,
);

/**
 * Selects user's organization memberships
 */
export const organizationMemberships = createSelector(
  mainSelector,
  (state) => state.organizationMemberships,
);

//----------------------------------------------------------------------------------------------------------------------

export const isUpdateInformationInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.UPDATE_INFORMATION),
);

export const hasUpdateInformationErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.UPDATE_INFORMATION),
);
