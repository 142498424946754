import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import PolygonIcon from 'svg/polygon.svg';
import ActivityIcon from 'svg/activity.svg';
import messages from '../messages';
import * as shapes from '../shapes';
import styles from './ActivityTooltip.pcss';


class ActivityTooltip extends React.PureComponent {

  static propTypes = {
    // Explicit props
    activity: shapes.activity.isRequired,
  };


  renderTitle() {
    return (
      <div className={styles.activityTooltip__title}>
        <ActivityIcon className={styles.activityTooltip__titleIcon} />
        <div className={styles.activityTooltip__titleText}>
          <FormattedMessage {...messages.activity.label} />
        </div>
      </div>
    );
  }


  renderType() {
    const { activity } = this.props;
    return (
      <div className={styles.activityTooltip__detail}>
        <div className={styles.activityTooltip__detailType}>
          <FormattedMessage {...messages.activity.labels.type} />
        </div>
        <div className={styles.activityTooltip__detailValue}>
          { activity.name }
        </div>
      </div>
    );
  }


  renderTime() {
    const { activity } = this.props;
    const time = moment.unix(activity.timestamp).utc().format('LT');

    return (
      <div className={styles.activityTooltip__detail}>
        <div className={styles.activityTooltip__detailType}>
          <FormattedMessage {...messages.activity.labels.time} />
        </div>
        <div className={styles.activityTooltip__detailValue}>
          { time }
        </div>
      </div>
    );
  }


  renderDuration() {
    const { activity } = this.props;

    return (
      <div className={styles.activityTooltip__detail}>
        <div className={styles.activityTooltip__detailType}>
          <FormattedMessage {...messages.activity.labels.duration} />
        </div>
        <div className={styles.activityTooltip__detailValue}>
          { activity.duration } <FormattedMessage {...messages.activity.labels.timeUnit} />.
        </div>
      </div>
    );
  }


  renderDetails() {
    return (
      <div className={styles.activityTooltip__details}>
        { this.renderType() }
        { this.renderTime() }
        { this.renderDuration() }
      </div>
    );
  }


  render() {
    return (
      <div className={styles.activityTooltip}>
        <div className={styles.activityTooltip__content}>
          { this.renderTitle() }
          <div className={styles.activityTooltip__separator} />
          { this.renderDetails() }
          <PolygonIcon width="17" height="12" className={styles.activityTooltip__polygon} />
        </div>
      </div>
    );
  }

}


export default withStyles(styles)(ActivityTooltip);
