import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { AppContext } from 'context';
import Button from 'components/Form/Button';
import Results from 'svg/results.svg';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from './MyResultsBtn.pcss';


class MyResultsBtn extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    styleModifier: PropTypes.string,
    className    : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    // Implicit props
    activeProfile: PropTypes.shape({
      avatar: PropTypes.string,
    }),
    // Implicit actions
    onOpenModal: PropTypes.func,
  };


  static defaultProps = {
    styleModifier: 'quinary',
    className    : 'btn--block btn--filled m-0',
  };


  renderAvatar() {
    return (
      <div className={styles.avatar}>
        <Results className={styles.avatar__icon} />
      </div>
    );
  }


  renderButtonContent() {
    return (
      <div className="row align-items-center w-100">
        <div className="col-auto pr-0">
          { this.renderAvatar() }
        </div>
        <div className="col">
          <p className={styles.label}><FormattedMessage {...messages.buttons.myResults} /></p>
        </div>
      </div>
    );
  }


  render() {
    return (
      <Button
        type="link"
        to={this.context.getUrl('my-results')}
        styleModifier={this.props.styleModifier}
        className={cn(styles.root, this.props.className)}
        onClick={this.props.onOpenModal}
      >
        { this.renderButtonContent() }
      </Button>
    );
  }

}


const mapStateToProps = (state) => ({
  activeProfile: selectors.activeProfile(state),
});


const ConnectedMyResultsBtn = connect(
  mapStateToProps,
)(MyResultsBtn);


export default withStyles(styles)(ConnectedMyResultsBtn);
