import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import filter from 'lodash/filter';
import find from 'lodash/find';
import findLast from 'lodash/findLast';
import forEach from 'lodash/forEach';
import forIn from 'lodash/forIn';
import get from 'lodash/get';
import hasIn from 'lodash/hasIn';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import unset from 'lodash/unset';
import ContentEditable from 'react-contenteditable';
import partition from 'lodash/partition';
import FloatingModal from 'components/FloatingModal';
import noteShape from 'shapes/noteShape';
import PencilIcon from 'svg/pencil.svg';
import TrashBinIcon from 'svg/trash-bin.svg';
import App from 'modules/App';
import CloudDrive from 'modules/CloudDrive';
import accessTokenShape from 'shapes/accessTokenShape';
import Select from 'components/Form/Select';
import { formatDate, getNowAsUtc } from 'helpers/datetime';
import intlShape from 'shapes/intlShape';
import Button from 'components/Form/Button';
import Tabs from 'components/Tabs';
import { getNoteKey, getNoteTypeFromNoteKey } from 'helpers/notes';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import PrivateNoteInfo from '../PrivateNoteInfo';
import VisitNoteHeader from '../VisitNoteHeader';
import ReplyBtn from '../ReplyBtn';
import ReplyNote from '../ReplyNote';
import ReplyNoteForm from '../ReplyNoteForm';
import styles from '../VisitNotes.pcss';
import { combineVisits, sortByNoteType } from './helpers';


class VisitNotes extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const { floatingModal, activeVisit, formValues, notes } = props;
    const { notesRefs, replyNote } = state;
    const visits = combineVisits(props);

    if (replyNote && notes.length) {
      const note = find(
        notes,
        (n) => n.noteType === 'Note'
          && n.payload
          && n.payload.replyTo === replyNote.payload.replyTo,
      );
      if (note) {
        return { replyNote: null };
      }
    }

    if (!floatingModal && state.selectedVisit) {
      return { activeView: 'shared', activeVisit: null, selectedVisit: null };
    }

    if (
      floatingModal && visits.length
        && (!state.selectedVisit || (activeVisit && activeVisit !== state.activeVisit))
    ) {
      notesRefs.set('Comment', React.createRef());
      notesRefs.set('Recommendation', React.createRef());
      return { selectedVisit: visits[0], activeVisit };
    }
    if (formValues !== state.formValues) {
      const selectedNoteType = get(formValues, 'contextData.selectedNoteType');
      const stateSelectedNoteType = get(state.formValues, 'contextData.selectedNoteType');
      const values = get(formValues, 'values');
      const activeView = selectedNoteType !== stateSelectedNoteType ? 'shared' : state.activeView;
      forIn(values, (content, noteKey) => {
        if (!notesRefs.has(noteKey)) {
          notesRefs.set(noteKey, React.createRef());
        }
      });
      return { activeView, formValues };
    }
    return null;
  }


  static propTypes = {
    // Explicit props
    activePatient: PropTypes.shape({
      id                : PropTypes.string,
      phiSetReferenceKey: PropTypes.string,
      storageProvider   : PropTypes.string,
      accessToken       : accessTokenShape,
    }),
    phiSet: PropTypes.shape({
      visits: PropTypes.arrayOf(shapes.visit),
    }),
    phiSetDocumentId    : PropTypes.string,
    notes               : PropTypes.arrayOf(noteShape),
    // Explicit actions
    successAction       : PropTypes.func,
    // Implicit props
    floatingModal       : App.shapes.floatingModal,
    activeVisit         : shapes.visit,
    visits              : PropTypes.arrayOf(shapes.visit), // eslint-disable-line react/no-unused-prop-types
    formValues          : PropTypes.object,
    intl                : intlShape.isRequired,
    isInProgress        : PropTypes.bool,
    // Implicit props
    onSubmit            : PropTypes.func,
    onRemove            : PropTypes.func,
    onStoreNotes        : PropTypes.func,
    onCloseFloatingModal: PropTypes.func,
    onSetFormValue      : PropTypes.func,
    onSetFormValues     : PropTypes.func,
    onSetFormContext    : PropTypes.func,
    onClearForm         : PropTypes.func,
  };


  constructor(props) {
    super(props);
    const notesRefs = new Map();
    if (props.activeVisit) {
      notesRefs.set('Comment', React.createRef());
      notesRefs.set('Recommendation', React.createRef());
      this.onPopulateActiveVisitNotes();
    }
    this.isChanged = false;
    this.state = {
      activeView   : 'shared',
      activeVisit  : null,
      selectedVisit: null,
      notesRefs,
      replyingTo   : null,
      replyNote    : null,
    };
  }


  componentDidUpdate(prevProps) {
    const { activePatient, activeVisit, floatingModal, formValues, notes } = this.props;

    if (activeVisit && isEmpty(formValues) && prevProps.notes !== notes) {
      this.onPopulateActiveVisitNotes();
    }

    if (
      floatingModal
      && (
        (prevProps.activeVisit !== activeVisit && !activeVisit)
        || (get(prevProps.activePatient, 'id') !== get(activePatient, 'id'))
      )
    ) {
      this.props.onCloseFloatingModal();
      this.state.notesRefs.clear();
      this.props.onClearForm();
      return;
    }

    if (prevProps.formValues !== formValues) {
      const prevSelectedNoteType = get(prevProps.formValues, 'contextData.selectedNoteType');
      const selectedNoteType = get(formValues, 'contextData.selectedNoteType');
      const selectedRef = this.state.notesRefs.get(selectedNoteType);
      if (prevSelectedNoteType !== selectedNoteType && selectedRef && selectedRef.current) {
        const className = styles.notes;
        const noteElement = selectedRef.current;
        noteElement.focus();
        const hadValue = hasIn(prevProps.formValues, ['values', selectedNoteType]);
        if (!includes(App.constants.GENERAL_NOTE_TYPES, selectedNoteType) && !hadValue) {
          this.isChanged = true;
        }
        const notesContainer = noteElement.parentElement.parentElement;
        if (className === notesContainer.className) {
          const { offsetTop } = noteElement.parentElement;
          notesContainer.scrollTop = offsetTop;
        }
      }
    }
  }


  onPopulateActiveVisitNotes() {
    const { activeVisit } = this.props;
    if (!activeVisit) {
      return;
    }
    const { phisetVisitId } = activeVisit;
    const notes = filter(this.props.notes, { phisetVisitId }).sort(sortByNoteType);
    const values = {};
    const payloads = {};
    forEach(notes, (note) => {
      const noteKey = getNoteKey(note);
      values[noteKey] = note.content;
      if (note.payload) {
        payloads[noteKey] = note.payload;
      }
    });
    if (isEmpty(values)) {
      return;
    }
    const contextData = { ...get(this.props.formValues, 'contextData', {}), payloads };
    this.props.onSetFormValues(values);
    this.props.onSetFormContext(contextData);
  }


  onChange(evt, noteKey) {
    if (!this.isChanged) {
      this.isChanged = true;
    }
    this.props.onSetFormValue({
      id   : noteKey,
      value: evt.currentTarget.textContent,
    });
  }


  onCancelReply() {
    if (this.state.replyingTo) {
      this.setState({
        replyingTo: null,
        replyNote : null,
      });
    }
  }


  onChangeReply(replyContent) {
    this.setState((state) => {
      const currentNote = findLast(
        this.props.notes,
        (n) => n.noteType === 'Note' && n.payload && n.replyTo === state.replyingTo.noteId,
      );
      const replyNote = {
        noteId   : get(currentNote, 'noteId', 'replyNote'),
        noteType : 'Note',
        content  : replyContent,
        timestamp: get(currentNote, 'timestamp', +getNowAsUtc().locale('en').format('X')),
        payload  : {
          replyTo: state.replyingTo.noteId,
          byPwd  : false,
        },
      };
      return { replyNote };
    });
  }


  onReply(note) {
    this.setState({ replyingTo: note });
  }


  onSubmitReply(replyContent) {
    if (!replyContent) {
      this.setState({
        replyingTo: null,
        replyNote : null,
      });
      return;
    }
    const { phiSet, phiSetDocumentId } = this.props;
    const { selectedVisit } = this.state;
    const { phisetVisitId } = selectedVisit || {};
    const currentNote = findLast(
      this.props.notes,
      (n) => n.noteType === 'Note' && n.payload && n.payload.replyTo === this.state.replyingTo.noteId,
    );
    const updatedNote = {
      ...currentNote,
      noteType: 'Note',
      content : replyContent,
      payload : {
        replyTo: this.state.replyingTo.noteId,
        byPwd  : false,
      },
    };
    if (!updatedNote.timestamp) updatedNote.timestamp = +getNowAsUtc().locale('en').format('X');
    this.props.onStoreNotes([updatedNote], phiSet, phiSetDocumentId, phisetVisitId);
    this.setState({
      replyingTo: null,
    });
  }


  onKeyDown(evt) {
    switch (evt.keyCode) {
      // If Enter
      case 13:
        evt.stopPropagation();
        break;
      default:
        break;
    }
  }


  onSetSelectedVisit(input) {
    const visits = combineVisits(this.props);
    const { value: phisetVisitId } = input;
    const selectedVisit = find(visits, { phisetVisitId });
    this.setState({ selectedVisit });
  }


  onFocus(ref) {
    ref.current.parentElement.classList.add('pseudoEditorWrapper--active');
  }


  onBlur(ref) {
    this.onSubmit();
    ref.current.parentElement.classList.remove('pseudoEditorWrapper--active');
    const contextData = get(this.props.formValues, 'contextData', {});
    contextData.selectedNoteType = undefined;
    this.props.onSetFormContext(contextData);
  }


  onSubmit() {
    if (!this.isChanged) {
      return;
    }
    const { selectedVisit } = this.state;
    const { activePatient, activeVisit, phiSet, phiSetDocumentId, successAction } = this.props;
    if (activeVisit !== selectedVisit) {
      return;
    }
    const { phisetVisitId } = activeVisit;
    const payloads = get(this.props.formValues, 'contextData.payloads', {});
    const updatedNotes = [];
    forEach(
      this.selectedVisitNotes,
      (note) => {
        // For notes that are from form a noteType is the same as a noteKey. We need to extract a real noteType.
        const noteType = getNoteTypeFromNoteKey(note.noteType);
        if (includes(App.constants.GENERAL_NOTE_TYPES, noteType) && !note.content) {
          this.onRemove(note);
          return;
        }
        const currentNote = this.findCurrentNote(note) || {};
        const updatedNote = { ...currentNote, ...note, noteType };
        if (!updatedNote.timestamp) updatedNote.timestamp = +getNowAsUtc().locale('en').format('X');
        if (!updatedNote.payload) {
          const payload = payloads[note.noteType];
          if (payload) updatedNote.payload = payload;
        }
        updatedNotes.push(updatedNote);
      },
    );
    if (updatedNotes.length) {
      this.props.onSubmit(updatedNotes, phiSet, phiSetDocumentId, phisetVisitId, activePatient, successAction);
    }
    this.isChanged = false;
  }


  onRemove(note) {
    const { activePatient, phiSet, phiSetDocumentId, successAction } = this.props;
    const currentNote = this.findCurrentNote(note);
    const contextData = get(this.props.formValues, 'contextData', {});
    unset(contextData, ['payloads', note.noteType]);
    this.props.onSetFormValue({ id: note.noteType, value: undefined });
    this.props.onSetFormContext(contextData);
    if (currentNote) {
      this.props.onRemove(currentNote, phiSet, phiSetDocumentId, activePatient, successAction);
    }
  }


  get selectedVisitNotes() {
    const { selectedVisit } = this.state;
    const { activeVisit } = this.props;
    if (activeVisit === selectedVisit) {
      const activeVisitNotes = [];
      // For notes that are from form a noteType is the same as a noteKey. It's not a real noteType!
      forIn(get(this.props.formValues, 'values'), (content, noteType) => {
        if (noteType === 'Comment' || noteType === 'Recommendation') {
          return;
        }
        activeVisitNotes.push({
          noteType,
          content,
        });
      });
      activeVisitNotes.push({
        noteType: 'Comment',
        content : this.getNoteValue('Comment'),
      });
      activeVisitNotes.push({
        noteType: 'Recommendation',
        content : this.getNoteValue('Recommendation'),
      });
      return activeVisitNotes.sort(sortByNoteType);
    }
    const { phisetVisitId } = this.state.selectedVisit || {};
    return filter(this.props.notes, { phisetVisitId }).sort(sortByNoteType);
  }


  getNoteValue(noteKey) {
    return get(this.props.formValues, ['values', noteKey], '');
  }


  findCurrentNote(note) {
    const { activeVisit, notes } = this.props;
    const { phisetVisitId } = activeVisit;
    const currentNotes = filter(notes, { phisetVisitId });
    const noteType = getNoteTypeFromNoteKey(note.noteType);
    const payload = get(this.props.formValues, ['contextData', 'payloads', note.noteType]);
    if (payload) {
      const { start, end } = payload;
      if (start && end) {
        return find(
          currentNotes,
          (n) => n.noteType === noteType && n.payload && n.payload.start === start && n.payload.end === end,
        );
      }
      if (start) {
        return find(currentNotes, (n) => n.noteType === noteType && n.payload && n.payload.start === start);
      }
    }
    return find(currentNotes, (n) => n.noteType === noteType);
  }


  renderMainNote(note, replyNotes) {
    if (!note) {
      return null;
    }
    const { selectedVisit } = this.state;
    const { activeVisit } = this.props;
    if (activeVisit === selectedVisit) {
      const placeholder = this.props.intl.formatMessage(messages.placeholders[`write${note.noteType}`]);
      const ref = this.state.notesRefs.get(note.noteType);
      return (
        <div
          className={cn('pseudoEditorWrapper', styles.mainNoteEditorWrapper)}
        >
          <ContentEditable
            className={cn('pseudoEditor', styles.mainNoteEditor)}
            innerRef={ref}
            html={this.getNoteValue(note.noteType)}
            onKeyDown={(evt) => this.onKeyDown(evt)}
            onChange={(evt) => this.onChange(evt, note.noteType)}
            onFocus={() => this.onFocus(ref)}
            onBlur={() => this.onBlur(ref)}
            placeholder={placeholder}
          />
        </div>
      );
    }
    return (
      <React.Fragment key={note.noteId}>
        <div className={styles.note}>
          { note.content }
        </div>
        { this.renderReplySection(note, replyNotes) }
      </React.Fragment>
    );
  }


  renderRemoveNote(note) {
    return (
      <Button
        className={styles.removeNoteBtn}
        isDisabled={this.props.isInProgress}
        onClick={() => this.onRemove(note)}
      >
        <TrashBinIcon className="btn__icon" />
      </Button>
    );
  }


  renderReplyNoteForm(note) {
    if (note !== this.state.replyingTo) {
      return null;
    }
    return (
      <ReplyNoteForm
        note={note}
        onCancel={() => this.onCancel()}
        onChange={(replyContent) => this.onChangeReply(replyContent)}
        onSubmit={(replyContent) => this.onSubmitReply(replyContent)}
      />
    );
  }


  renderReplySection(note, replyNotes) {
    const replies = filter(replyNotes, (rn) => rn.payload && rn.payload.replyTo === note.noteId);
    const repliesLast = replies.length - 1;
    const canReply = !replies.length && note !== this.state.replyingTo;
    return (
      <>
        {
          canReply
          && (
            <div className="mt-2 text--small">
              <ReplyBtn
                note={note}
                onReply={(n) => this.onReply(n)}
              />
            </div>
          )
        }
        { this.renderReplyNoteForm(note) }
        {
          map(replies, (replyNote, idx) => (
            <ReplyNote
              key={replyNote.noteId}
              mode="HCP"
              note={replyNote}
              replyNotes={replyNotes}
              replyingTo={this.state.replyingTo}
              isLast={idx === repliesLast}
              onCancel={() => this.onCancelReply()}
              onChange={(replyContent) => this.onChangeReply(replyContent)}
              onReply={(n) => this.onReply(n)}
              onSubmit={(replyContent) => this.onSubmitReply(replyContent)}
            />
          ))
        }
      </>
    );
  }


  renderNote(note, replyNotes) {
    const { selectedVisit } = this.state;
    const { activeVisit } = this.props;
    if (activeVisit !== selectedVisit) {
      const { payload } = note;
      return (
        <React.Fragment key={note.noteId}>
          <div
            className={
              cn('pseudoEditorWrapper', {
                [styles.noteWithPayload]  : payload,
                [styles.noteWithDateRange]: payload && (payload.start || payload.end),
              })
            }
          >
            <VisitNoteHeader note={note} />
            <div className={`pseudoEditor ${styles.note}`}>
              { note.content }
            </div>
          </div>
          { this.renderReplySection(note, replyNotes) }
        </React.Fragment>
      );
    }
    const payload = get(this.props.formValues, ['contextData', 'payloads', note.noteType]);
    const ref = this.state.notesRefs.get(note.noteType);
    return (
      <div
        key={note.noteType}
        className={
          cn('pseudoEditorWrapper', {
            [styles.noteWithPayload]  : payload,
            [styles.noteWithDateRange]: payload && (payload.start || payload.end),
          })
        }
      >
        <VisitNoteHeader note={{ ...note, payload, noteId: note.noteId || note.noteType }} />
        { this.renderRemoveNote(note) }
        <ContentEditable
          className="pseudoEditor"
          innerRef={ref}
          html={this.getNoteValue(note.noteType, payload)}
          onKeyDown={(evt) => this.onKeyDown(evt)}
          onChange={(evt) => this.onChange(evt, note.noteType)}
          onFocus={() => this.onFocus(ref)}
          onBlur={() => this.onBlur(ref)}
        />
      </div>
    );
  }


  renderNotes(primaryNotes, replyNotes) {
    if (!primaryNotes.length) {
      return null;
    }
    return map(primaryNotes, (note) => this.renderNote(note, replyNotes));
  }


  renderNoDataNotes(visitNotes) {
    const { selectedVisit } = this.state;
    const { activeVisit } = this.props;
    if (activeVisit !== selectedVisit) {
      return null;
    }
    const dataNotes = filter(
      visitNotes,
      (note) => includes(App.constants.DATA_NOTE_TYPES, getNoteTypeFromNoteKey(note.noteType)),
    );
    if (dataNotes.length) {
      return null;
    }
    return (
      <div className={styles.noDataNotes}>
        <div className={styles.noDataNotes__icon}>
          <PencilIcon />
        </div>
        <div className={styles.noDataNotes__text}>
          <FormattedMessage {...messages.infos.addDataNote} />
        </div>
      </div>
    );
  }


  renderNotesSection() {
    const { selectedVisitNotes } = this;
    const { replyingTo, replyNote } = this.state;
    const commentNote = findLast(selectedVisitNotes, { noteType: 'Comment' });
    if (commentNote) selectedVisitNotes.pop();
    if (this.state.activeView === 'private') {
      const [primaryNotes, replyNotes] = partition(selectedVisitNotes, (note) => note.noteType === 'Comment');
      if (!replyingTo && replyNote) replyNotes.push(replyNote);
      return (
        <div className={styles.notesSection}>
          <div className={styles.notes}>
            <PrivateNoteInfo />
            { this.renderNoDataNotes(selectedVisitNotes) }
            { this.renderNotes(primaryNotes, commentNote) }
          </div>
          { commentNote && <hr className="form-divider" /> }
          { commentNote && this.renderMainNote(commentNote, replyNotes) }
        </div>
      );
    }
    const recommendationNote = findLast(selectedVisitNotes, { noteType: 'Recommendation' });
    if (recommendationNote) selectedVisitNotes.pop();
    const [replyNotes, primaryNotes] = partition(selectedVisitNotes, (note) => note.noteType === 'Note');
    if (!replyingTo && replyNote) replyNotes.push(replyNote);
    return (
      <div className={styles.notesSection}>
        <div className={styles.notes}>
          { this.renderNoDataNotes(selectedVisitNotes) }
          { this.renderNotes(primaryNotes, replyNotes) }
        </div>
        { recommendationNote && <hr className="form-divider" /> }
        { recommendationNote && this.renderMainNote(recommendationNote, replyNotes) }
      </div>
    );
  }


  render() {
    const visits = combineVisits(this.props);
    const options = [];
    forEach(visits, (visit) => {
      const label = visit === this.props.activeVisit
        ? this.props.intl.formatMessage(messages.labels.activeVisit)
        : visit.metadata && formatDate(visit.metadata.visitDate);
      if (label) {
        options.push({ value: visit.phisetVisitId, label });
      }
    });

    return (
      <FloatingModal
        floatingModal={this.props.floatingModal}
        className={
          cn(styles.floatingModal, {
            [styles['floatingModal--activeVisit']]: this.props.activeVisit && this.props.floatingModal,
          })
        }
        headerMessage={messages.headers.visitNotes}
        isDocked
        isFixed
        onClose={this.props.onCloseFloatingModal}
      >
        <Select
          id="selectedVisit"
          className={styles.visitSelector}
          optionsFrom={options}
          value={get(this.state.selectedVisit, 'phisetVisitId')}
          valueKey="value"
          labelKey="label"
          onChange={(input) => this.onSetSelectedVisit(input)}
        />
        <Tabs
          activeItem={this.state.activeView}
          items={['shared', 'private']}
          messages={messages.tabs}
          action={(activeView) => this.setState({ activeView })}
        />
        { this.renderNotesSection() }
      </FloatingModal>
    );
  }

}


const mapStateToProps = (state) => ({
  activeVisit  : selectors.activeVisit(state),
  visits       : selectors.visits(state),
  isInProgress : CloudDrive.selectors.isSendNotesInProgress(state),
  floatingModal: App.selectors.floatingModalSelector(constants.VISIT_NOTES_FM)(state),
  formValues   : App.selectors.formSelector(constants.VISIT_NOTE_FORM)(state),
});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.VISIT_NOTE_FORM;
  return {
    onSubmit: (notes, phiSet, phiSetDocumentId, phisetVisitId, patientProfile, successAction) => dispatch(
      CloudDrive.actions.storeNotes(notes, phiSet, phiSetDocumentId, phisetVisitId, patientProfile, successAction),
    ),
    onRemove: (note, phiSet, phiSetDocumentId, patientProfile, successAction) => dispatch(
      CloudDrive.actions.removeNote(note, phiSet, phiSetDocumentId, patientProfile, successAction),
    ),
    onCloseFloatingModal: () => dispatch(App.actions.closeFloatingModal(constants.VISIT_NOTES_FM)),
    onSetFormValue      : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onSetFormValues     : (values) => dispatch(App.actions.setFormValues(formName, values)),
    onSetFormContext    : (contextData) => dispatch(App.actions.setFormContext(formName, contextData)),
    onClearForm         : () => dispatch(App.actions.clearForm(formName)),
  };
};


const ConnectedVisitNotes = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(VisitNotes));


export default withStyles(styles)(ConnectedVisitNotes);
