import { MODULE_ID } from './constants';


export const ADD_VISIT = `${MODULE_ID}/ADD_VISIT`;
export const ADD_VISIT_SUCCESS = `${MODULE_ID}/ADD_VISIT_SUCCESS`;
export const ADD_VISIT_ERROR = `${MODULE_ID}/ADD_VISIT_ERROR`;

export const RESUME_VISIT = `${MODULE_ID}/RESUME_VISIT`;

export const END_VISIT = `${MODULE_ID}/END_VISIT`;

export const FETCH_VISIT_METADATA = `${MODULE_ID}/FETCH_VISIT_METADATA`;
export const FETCH_VISIT_METADATA_SUCCESS = `${MODULE_ID}/FETCH_VISIT_METADATA_SUCCESS`;
export const FETCH_VISIT_METADATA_ERROR = `${MODULE_ID}/FETCH_VISIT_METADATA_ERROR`;

export const FETCH_VISITS_METADATA = `${MODULE_ID}/FETCH_VISITS_METADATA`;
export const FETCH_VISITS_METADATA_SUCCESS = `${MODULE_ID}/FETCH_VISITS_METADATA_SUCCESS`;
export const FETCH_VISITS_METADATA_ERROR = `${MODULE_ID}/FETCH_VISITS_METADATA_ERROR`;

export const CLEAR_VISITS = `${MODULE_ID}/CLEAR_VISITS`;
